import React, { Component } from "react";
import { injectIntl } from "react-intl";

import { withAuth } from "../withAuth.js";
import withStyles from "@material-ui/core/styles/withStyles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { List, ListItem, ListItemText } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import { palette } from "../../styles/palette.js";

const styles = theme => ({
  button: {
    display: "flex",
    justifyContent: "space-between",
    textTransform: "none",
    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
    fontFamily: "Coolvetica Book",
    letterSpacing: 0.5,
    borderRadius: 5,
    backgroundColor: "#ffffff",
    color: "#878787",
    fontSize: 12,
    height: 40,
    width: 205,
    marginTop: 20,
    "&:focus": {
      border: "1px solid " + palette.purple
    },
  },
  paper: {
    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
    borderRadius: 5,
    marginTop: 5,
  },
  paperListItem: {
    color: "#878787",
    height: 30,
    lineHeight: 1,
    paddingRight: 0,
    "&:hover": {
      cursor: "pointer"
    }
  },
  formControlLabel: {
    margin: "0 16px 0 0",
  },
  itemText: {
    fontFamily: "Coolvetica Book",
    fontSize: 12,
    marginLeft: 15,
    minWidth: 150
  },
  displayedStatus: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: 5,
  }
});

export const StatusPicker = withAuth(
  withStyles(styles)(
    injectIntl(
      class StatusPicker extends Component {
        constructor(props) {
          super(props);
          this.handleClick = this.handleClick.bind(this);
          this.handleClickAway = this.handleClickAway.bind(this);
          this.renderStatus = this.renderStatus.bind(this);
          this.handleToggle = this.handleToggle.bind(this);
          this.isSelected = this.isSelected.bind(this);
          this.setDisplayedStatus = this.setDisplayedStatus.bind(this);
          this.setCheckedStatusList = this.setCheckedStatusList.bind(this);
          this.handleNullToggle = this.handleNullToggle.bind(this);

          this.state = {
            open: false,
            anchorEl: null,
            displayedStatus: "",
            statusesState: {},
            isNullChecked: true
          };
        }

        async componentDidMount() {
          const { statusCheckedList, statusList } = this.props;

          const statusesState = {};
          for (const status of statusList) {
            statusesState[status] = statusCheckedList.includes(status);
          }

          const isNullChecked = this.props.statusCheckedList.length === 0 || this.props.statusCheckedList.includes("NA");
          if (isNullChecked && !this.props.statusCheckedList.includes("NA")) this.props.statusCheckedList.push("NA");

          this.setState({ statusesState, isNullChecked }, () => {
            this.setDisplayedStatus();
          });
        }

        handleClick(event) {
          this.setState({ anchorEl: event.currentTarget, open: !this.state.open });
        }

        handleClickAway() {
          this.setState({ open: false });
        }

        handleToggle(e) {
          const isChecked = e.target.checked;
          const value = e.target.value;

          const statusesState = { ...this.state.statusesState, [value]: isChecked };

          this.setState({ statusesState }, () => {
            this.setCheckedStatusList();
            this.setDisplayedStatus();
          });
        }

        handleNullToggle(e) {
          const isChecked = e.target.checked;

          this.setState({ isNullChecked: isChecked }, () => {
            this.setCheckedStatusList();
            this.setDisplayedStatus();
          });
        }

        setCheckedStatusList() {
          const { setStatusList } = this.props;
          const { statusesState, isNullChecked } = this.state;

          const checkedStatuses = Object.keys(statusesState).filter(status => statusesState[status]);
          if (isNullChecked) checkedStatuses.push("NA");
          setStatusList(checkedStatuses);
        }

        isSelected(item) {
          const { statusCheckedList } = this.props;

          return statusCheckedList.length === 0 || statusCheckedList.some(status => status === item);
        }

        setDisplayedStatus() {
          const { intl } = this.props;
          const { statusesState, isNullChecked } = this.state;

          let displayedStatus = "";
          const statuses = Object.keys(statusesState);
          const checkedStatuses = statuses.filter(status => statusesState[status]);
          
          statuses.push(null);
          if (isNullChecked) checkedStatuses.push(null);

          if (statuses.length === checkedStatuses.length)
            displayedStatus = intl.messages["statusPicker.ordersStatus"];
          else if (checkedStatuses.length === 1)
            displayedStatus = checkedStatuses[0] === null ? intl.messages["statusPicker.noStatus"] : checkedStatuses[0];
          else
            displayedStatus = checkedStatuses.length === 0 ? intl.messages["statusPicker.noStatus"] : intl.messages["statusPicker.someStatuses"];

          this.setState({ displayedStatus });
        }

        renderStatus() {
          const { intl, classes } = this.props;

          return <List>
            <ListItem
              className={classes.paperListItem}
            >
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Checkbox
                    checked={this.state.isNullChecked}
                    onChange={this.handleNullToggle}
                    value={null}
                  />
                }
                label={
                  <ListItemText
                    disableTypography
                    primary={intl.messages["statusPicker.noStatus"]}
                    className={classes.itemText}
                  />
                }
              />
            </ListItem>
            {Object.entries(this.state.statusesState).map(([status, checked]) =>
              <ListItem
                key={status}
                className={classes.paperListItem}
              >
                <FormControlLabel
                  className={classes.formControlLabel}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={this.handleToggle}
                      value={status}
                    />
                  }
                  label={
                    <ListItemText
                      disableTypography
                      primary={status}
                      className={classes.itemText}
                    />
                  }
                />
              </ListItem>
            )}
          </List>;
        }

        render() {
          const { classes } = this.props;
          return (
            <div>
              <Button
                variant="contained"
                className={classes.button}
                onClick={this.handleClick}
              >
                <i className='fal fa-shopping-cart'></i>
                <div className={classes.displayedStatus}>
                  {this.state.displayedStatus}
                </div>
                <i className='fal fa-angle-down'></i>
              </Button>
              <Popper
                anchorEl={this.state.anchorEl}
                open={this.state.open}
                modifiers={{ flip: { enabled: false } }}
                placement={this.props.placement ? this.props.placement : "bottom-start"}
                style={this.props.zIndex ? { zIndex: 9999 } : {}}
              >
                <ClickAwayListener onClickAway={this.handleClickAway}>
                  <Paper
                    className={classes.paper}
                  >
                    {this.renderStatus()}
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </div>
          );
        }
      }
    )
  )
);
