import React, {  useContext, useState } from "react";
import { Translation } from "../common/Translation";
import { useIntl } from "react-intl";
import { useAuth } from "../../hooks/useAuth";
import { AccountContext } from "../../contexts/context";
import { EventSystem } from "../../eventsystem/EventSystem.js";
import { KiDropzone } from "../common/Input/KiDropzone.jsx";
import { KiFileInput } from "../common/Input/KiFileInput.jsx";
import { CustomButton } from "../common/Button/CustomButton";
import imgTooltip from "../../public/blacklist_tooltip.png";
import { PopupTO } from "../PopupTO.js";

import classes from "./WhiteList.module.scss";

export const WhiteList = () => {

  const intl = useIntl();
  const auth = useAuth();
  const { account } = useContext(AccountContext);
  
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState("");
  const [isHidden, setIsHidden] = useState("");


  const uploadFile = async () => {
    const whitelistType  = (window.location.search).replace("?whitelistType=", "");
    const fileContent = await file.text();
    const lines = fileContent.split(/(\r\n|\r|\n)/);
    setLoading(true);
    
    
    if (whitelistType === "customers") {

      const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const emails = lines.filter(line => line.trim().match(emailRegex));
      if (!emails?.length) {
        setLoading(false);
        return;
      }
      auth.fetch(`/api/data/whitelistCustomers?accountId=${account._id}`, { method: "POST", body: JSON.stringify({ emails : emails })}).then((result) => {
        setLoading(false);

        if(result.affectedRows === 0) {
          EventSystem.newNotification("notification.action", <Translation id={"whitelist.save.warning"} variables={{ targetedRows: result.targetedRows, invalidRows: result.targetedRows }} />);
        } else { 
          EventSystem.newNotification("notification.action", <Translation id={"whitelist.save.success"} variables={{ affectedRows: result.affectedRows, targetedRows: result.targetedRows, invalidRows: result.targetedRows-result.affectedRows }} />);
        }
        EventSystem.publish("url_switch", "/customers");
      }).catch((error) => {
        setLoading(false);
        console.error(error);
      });
    }
  };

  const closeExampleImage = () => {
    setIsHidden(false);
  };


  return (
    <div className={classes.root}>

      <div className={`display1 ${classes.title}`}>
        <Translation id="whitelist.title" />
      </div>

      <div className={`h1 ${classes.subtitle}`}>
        <Translation id="whitelist.customers.text" defaultMessage="" />
        <Translation id="whitelist.customers.text1" defaultMessage="" />

        <i onClick={() => setIsHidden(true)} style={{ fontSize: "20px", marginLeft: "5px", cursor: "pointer" }} className="fal fa-info-circle"></i>
        <PopupTO
          open={isHidden}
          handleClose={closeExampleImage}
          width="400px"
          align="center"
          title="popup.title_csv_example"
          form={<img src={imgTooltip} style={{ borderRadius: "5px" }} alt="CSV file example"></img>}
          borderRadius='5px'
          close
        />

      </div>

      <div className={classes.content}>
        <KiFileInput file={file} setFile={setFile} />
        <Translation id={"or"} defaultMessage="Email" />
        <KiDropzone setFile={setFile} />
        <CustomButton loading={loading} style={{ marginTop: "20px" }} size="sm" onClick={uploadFile} disabled={file === ""}>
          <Translation id="button.import_whitelist"/>
        </CustomButton>
      </div>
       
    </div>
  );
};

