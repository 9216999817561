import moment from "moment-timezone";
import { CustomButton } from "../common/Button/CustomButton";
import { Dialog } from "../common/Dialog";
import { Translation } from "../common/Translation";

import classes from "./ConfirmDeleteNewsletterPopup.module.scss";

export const ConfirmRescheduleNewsletterPopup = ({ isOpen, close, rescheduleNewsletter, suspendedDate }) => {

  return (
    <Dialog
      isOpen={isOpen}
      close={close}
      style={{ padding: "40px 60px" }}
      PaperProps={{ style: { borderRadius: 15 } }}
    >
      <div className={classes.container}>
        <h4 className={classes.title}><Translation id="newsletter.reschedulePopup.title" /></h4>
        <div className={`info ${classes.content}`}>
          {new Date(suspendedDate)?.getTime() > Date.now() ? 
            <Translation id="newsletter.reschedulePopup.content1" variables={{ date: moment(suspendedDate).format("DD/MM/YYYY") }} /> :
            <Translation id="newsletter.reschedulePopup.content2"  />
          }
        </div>
        <div className={classes.buttonsContainer}>
          <CustomButton
            type="secondary"
            size="sm"
            onClick={close}
          >
            <Translation id="button.anulation" />
          </CustomButton>
          <CustomButton
            type="primary"
            size="sm"
            onClick={rescheduleNewsletter}
          >
            <Translation id="newsletter.editPopup.reschedule" />
          </CustomButton>
        </div>
      </div>
    </Dialog>
  );
};