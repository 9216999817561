import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styles } from "./KiDatePicker.styles";
import moment from "moment-timezone";
import PropTypes from "prop-types";

const useStyles = makeStyles(styles);

const isToday = (someDate) => {
  const today = new Date();
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear();
};

export function KiDatePicker(props) {
  const classes = useStyles();
  const [key, setKey] = useState(0);

  const onChange = momentDate => {
    if (momentDate._isValid) {

      const date = momentDate.toDate();

      if (props.disableToday === true && isToday(date)) {
        return setKey(prev => prev + 1);
      }

      if (props.disablePast === true && date.getTime() < Date.now()) {
        return setKey(prev => prev + 1);
      }

      if (!props.minDate || date.getTime() >= props.minDate.getTime()) {
        props.onChange(date);
      } else {
        setKey(prev => prev + 1);
      }

    }
  };

  return (
    <div className={classes.datePicker} key={key}>
      <DatePicker
        disablePast={props.disablePast === true}
        shouldDisableDate={props.disableToday === true ? date => isToday(new Date(date)) : null}
        value={props.value ? moment(props.value) : null}
        onChange={onChange}
        minDate={props.minDate && moment(props.minDate)}
        slots={props.slots}
        slotProps={props.slotProps}
      />
    </div>
  );
}

KiDatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  onChange: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

KiDatePicker.defaultProps = {
  value: null,
};
