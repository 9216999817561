import { useCallback, useEffect, useMemo, useState } from "react";
import { SketchPicker } from "react-color";

import { useAuth } from "../../hooks/useAuth";
import { AccountContextBehaviorSubject$ } from "../../contexts/context.js";


import classes from "./RteToolbar.module.scss";

const FONT_SIZES = [
  8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 38, 46, 54, 62, 72,
];

const KEYWORDS = [
  { key: "firstname", value: "#FIRSTNAME#" },
  { key: "lastname", value: "#LASTNAME#" },
  { key: "shopName", value: "#SHOP_NAME#" },
  { key: "year", value: "#YEAR#" },
];

export const RteToolbar = ({ editor, intl }) => {

  const auth = useAuth();

  const [fontsize, setFontsize] = useState(16);
  const [fontcolor, setFontColor] = useState("");
  const [textAlign, setTextAlign] = useState("");


  const [{ account }] = useState(AccountContextBehaviorSubject$.getValue());
  const [presetColors, setPresetColors] = useState([]);

  const [fontsizeOpened, setFontsizeOpened] = useState(false);
  const [keywordsOpened, setKeywordsOpened] = useState(false);
  const [colorPickerOpened, setColorPickerOpened] = useState(false);

  const rte = useMemo(() => editor?.RichTextEditor.globalRte, [editor]);

  useEffect(() => {
    if (account && !presetColors.length) {
      const colors = [];
      const regex = /#?[a-f0-9]{6}/;
      account.template_parameters?.forEach(parameter => {
        if (parameter.value.toLowerCase().match(regex)) {
          colors.push(parameter.value);
        }
      });
      setPresetColors([...new Set(colors), ...(account.newsletterSavedColors || [])]);
    }
    
  }, [account, presetColors]);

  const onPosUpdate = useCallback(pos => {
    const parent = rte.el.closest(".gjs-selected");    
    if (parent) {
      const parentRect = parent.getBoundingClientRect();
      const rect = rte.el.getBoundingClientRect();
      const rteElems = document.getElementsByClassName("gjs-rte-toolbar");

      if (rteElems.length) {
        const rteRect = rteElems[0].getBoundingClientRect();
        pos.left = rect.left - (rteRect.width - rect.width) / 2 - parentRect.left;
      } else {
        pos.left = rect.left;
      }
      
      pos.top = rect.top - parentRect.top - 44;
    }
  }, [rte]);

  useEffect(() => {
    editor.on("rteToolbarPosUpdate", onPosUpdate);
    return () => editor.off("rteToolbarPosUpdate", onPosUpdate);
  }, [editor, onPosUpdate]);

  const onRteEnable = useCallback(() => {
    setColorPickerOpened(false);
    setFontsizeOpened(false);
    setKeywordsOpened(false);
    const node = rte.doc.getSelection()?.anchorNode;
    if (!node || node?.nodeName === "TD") {
      const range = rte.doc.createRange();
      const sel = rte.doc.getSelection();
      range.setStart(rte.el.childNodes[0], 0);
      range.collapse(true);

      sel.removeAllRanges();
      sel.addRange(range);
      rte.el.focus();
    }
  }, [rte]);

  useEffect(() => {
    editor.on("rte:enable", onRteEnable);
    return () => {
      editor.off("rte:enable", onRteEnable);
    };
  }, [editor, onRteEnable]);

  useEffect(() => {
    onRteEnable();
  }, [onRteEnable]);

  const onSelectionChange = useCallback(() => {
    if (!rte.doc.getSelection()?.anchorNode) {
      return ;
    }
    const text = rte.doc.getSelection().toString();
    if (text.length <= 1) {
      const sizeValue = window.getComputedStyle(rte.doc.getSelection()?.anchorNode.parentElement).getPropertyValue("font-size");
      const colorValue = window.getComputedStyle(rte.doc.getSelection()?.anchorNode.parentElement).getPropertyValue("color");
      setFontsize(parseInt(sizeValue));
      setFontColor(colorValue);  
      setTextAlign(null);
    }

    setFontsizeOpened(false);
    setKeywordsOpened(false);
    setColorPickerOpened(false);
  }, [rte]);

  useEffect(onSelectionChange, [onSelectionChange]);
  useEffect(() => {
    rte.doc.addEventListener("selectionchange", onSelectionChange);
    return () => rte.doc.removeEventListener("selectionchange", onSelectionChange);
  }, [rte, onSelectionChange]);

  const onClick = (event, func) => {
    event.stopPropagation();
    return func();
  };

  const addSubTextWithFontsize = (value) => {
    const selection = rte.doc.getSelection();
    let text = selection.toString();
    let needUnselect = false;

    if (!text) {
      rte.doc.getSelection().selectAllChildren(rte.el);
      text = selection.toString();
      needUnselect = true;
    }

    if (selection.rangeCount && text.length) {
      setFontsize(value);
      const range = selection.getRangeAt(0);
      const span = document.createElement("span");
      span.appendChild(document.createTextNode(text));
      span.style.fontSize = `${value}px`;
      span.style.display = "block";
      span.style.textAlign = textAlign;
      if (fontcolor) {
        span.style.color = fontcolor;
      }
      range.deleteContents();
      range.insertNode(span);
    }

    if (needUnselect) {
      rte.doc.getSelection().removeAllRanges();
    }

    setFontsizeOpened(false);
  };

  const addSubTextWithFontcolor = (color,event) => {
    setFontColor(color.hex);
    const selection = rte.doc.getSelection();
    let text = selection.toString();
    let needUnselect = false;

    if (!text) {
      rte.doc.getSelection().selectAllChildren(rte.el);
      text = selection.toString();
      needUnselect = true;
    }
    
    if (selection.rangeCount && text.length) {
      auth.fetch("/account_management/addNewsletterColors", {
        method: "POST",
        body: JSON.stringify({
          colors: [color.hex],
        })
      });
      
      if (!presetColors.includes(color.hex)) {
        setPresetColors(prev => [...prev, color.hex].slice(-26));
      }
      const range = selection.getRangeAt(0);
      const span = document.createElement("span");
      span.appendChild(document.createTextNode(text));
      span.style.color = color.hex;
      span.style.fontSize = fontsize+"px";
      span.style.display = "block";
      span.style.textAlign = textAlign;
      range.deleteContents();
      range.insertNode(span);
    }

    if (needUnselect) {
      rte.doc.getSelection().removeAllRanges();
    }

    if(event.target.classList.value === "saturation-black"){
      setColorPickerOpened(false);
    }
    rte.el.dispatchEvent(new Event("input", { bubbles: true }));
  };

  const insertKeyword = (value) => {
    rte.doc.execCommand("insertText", false, value.value);
    setKeywordsOpened(false);
  };

  const alignSelection = (value) => {
    setTextAlign(value);
    const selection = rte.doc.getSelection();
    let text = selection.toString();
    let needUnselect = false;

    if (!text) {
      rte.doc.getSelection().selectAllChildren(rte.el);
      text = selection.toString();
      needUnselect = true;
    }

    if (selection.rangeCount && text.length) {
      const range = selection.getRangeAt(0);
      const span = document.createElement("span");
      span.appendChild(document.createTextNode(text));
      span.style.width = "100%";
      span.style.textAlign = value;
      span.style.display = "block";
      span.style.fontSize = fontsize+"px";
      if (fontcolor) {
        span.style.color = fontcolor;
      }
      range.deleteContents();
      range.insertNode(span);
    }

    if (needUnselect) {
      rte.doc.getSelection().removeAllRanges();
    }
  };

  const exec = (command) => {
    const selection = rte.doc.getSelection();
    const text = selection.toString();
    if (!text) {
      rte.doc.getSelection().selectAllChildren(rte.el);
      rte.exec(command);
      rte.doc.getSelection().removeAllRanges();
    } else {
      rte.exec(command);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.textTransformOptions}>
        
        <div className={classes.textTransformOption} onClick={event => onClick(event, () => exec("bold"))}>
          <h2>B</h2>
        </div>
        <div className={classes.textTransformOption} onClick={event => onClick(event, () => exec("italic"))}>
          <i>i</i>
        </div>
        <div className={classes.textTransformOption} onClick={event => onClick(event, () => exec("underline"))}>
          <u>U</u>
        </div>
        <div className={classes.textTransformOption} onClick={event => onClick(event, () => exec("strikeThrough"))}>
          <s>S</s>
        </div>
        <div className={classes.textTransformOption} onClick={event => onClick(event, () => editor.trigger("rteLink", { rte }))}>
          <i className="fa-regular fa-link" />
        </div>
      </div>
      <div className={classes.separator} />
      <div className={classes.textTransformOptions}>
        <div className={classes.textTransformOption} onClick={event => onClick(event, () => alignSelection("left"))}>
          <i className="fa-regular fa-align-left" />
        </div>
        <div className={classes.textTransformOption} onClick={event => onClick(event, () => alignSelection("center"))}>
          <i className="fa-regular fa-align-center" />
        </div>
        <div className={classes.textTransformOption} onClick={event => onClick(event, () => alignSelection("right"))}>
          <i className="fa-regular fa-align-right" />
        </div>
      </div>
      <div className={classes.separator} />
      <div className={classes.rightOptions}>
        <div className={classes.dropdownOption} onClick={event => onClick(event, () => setFontsizeOpened(prev => !prev))}>
          <span className="info">{fontsize}px</span>
          <i className="fa-solid fa-chevron-down"/>
          {fontsizeOpened &&
            <div className={classes.dropdownOptionMenu}>
              {FONT_SIZES.map(value => (
                <div key={value} className={classes.dropdownOptionMenuItem} onClick={event => onClick(event, () => addSubTextWithFontsize(value))}>
                  <span className="info">{value}px</span>
                </div>
              ))}
            </div>
          }
        </div>
        <div className={classes.dropdownOption} onClick={event => onClick(event, () => setKeywordsOpened(prev => !prev))}>
          <span className="info">{intl.messages["rteToolbar.custom"]}</span>
          <i className="fa-solid fa-chevron-down"/>
          {keywordsOpened &&
            <div className={classes.dropdownOptionMenu}>
              {KEYWORDS.map(value => (
                <div key={value.key} className={classes.dropdownOptionMenuItem} onClick={event => onClick(event, () => insertKeyword(value))}>
                  <span className="info">{intl.messages[`email.keywords.${value.key}`]}</span>
                </div>
              ))}
            </div>
          }
        </div>
        <div>
          <div className={classes.colorSection} style={{ backgroundColor: fontcolor || "black" }}  onClick={event => onClick(event, () => setColorPickerOpened(prev => !prev))}></div>

          { colorPickerOpened && 
            <div className={classes.dropdownOptionColorMenu}>
              <SketchPicker
                color={fontcolor || "black"}
                onChangeComplete={(color,event) =>  addSubTextWithFontcolor(color,event)}
                presetColors={presetColors}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};