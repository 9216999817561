export const usecasesChronologyConfig = {
  birthday: {
    option3: false,
  },
  anniversaryRegistration: {
    optionText2: "usecaseConfigPopup.anniversaryRegistration.option2",
    option3: false,
  },
  welcome: {
    optionText1: "usecaseConfigPopup.welcome.option1",
    option1: "onlyAfter",
    option2: false,
    option3: false,
    defaultValue: [3],
  },
  welcomeAi: {
    optionText1: "usecaseConfigPopup.welcome.option1",
    option1: "onlyAfter",
    option2: false,
    option3: false,
    defaultValue: [3],
  },
  crossSelling: {
    optionText1: "usecaseConfigPopup.crossSelling.option1",
    option1: "onlyAfter",
    option2: false,
    option3: false,
    defaultValue: [14],
  },
  ephemeris: {
    optionText2: "usecaseConfigPopup.ephemeris.option2",
    option3: false,
  },
  bestClients: {
    optionText1: "usecaseConfigPopup.crossSelling.option1",
    option1: "onlyAfter",
    option2: false,
    option3: false,
    defaultValue: [10],
  },
  abandonedCarts: {
    optionText1: "usecaseConfigPopup.abandonedCarts.option1",
    option1: "onlyAfter",
    option2: false,
    option3: false,
    defaultValue: [1],
  },
  mothersDay: {
    optionText2: "usecaseConfigPopup.mothersDay.option2",
  },
  fathersDay: {
    optionText2: "usecaseConfigPopup.fathersDay.option2",
  },
  blackFriday: {
    listName:"blackFridayChronology",
    optionText2: "usecaseConfigPopup.blackFriday.option2",
  },
  halloween: {
    listName:"halloweenChronology",
    optionText2: "usecaseConfigPopup.halloween.option2",
  },
  christmas: {
    listName:"christmasChronology",
    optionText2: "usecaseConfigPopup.christmas.option2",
  },
  newYear: {
    listName:"newYearChronology",
    optionText2: "usecaseConfigPopup.newYear.option2",
  },
  backToSchool: {
    listName:"backToSchoolChronology",
    optionText2: "usecaseConfigPopup.backToSchool.option2",
  },
  valentinesDay: {
    listName:"valentinesDayChronology",
    optionText2: "usecaseConfigPopup.valentinesDay.option2",
  },
  /* summerSales: {},
  winterSales: {}, */
};
