import { useState } from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

import { Dialog } from "../../../../common/Dialog.js";
import { FormButtonTO } from "../../../../FormButtonTO.jsx";
import { useAuth } from "../../../../../hooks/useAuth";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 45px",
    maxWidth: 360,
    textAlign: "center",
  },
  title: {
    fontFamily: "Coolvetica",
    lineHeight: "25px",
    marginBottom: 25,
  },
  buttonContainer: {
    marginTop: 40,
    display: "flex",
    gridGap: 40,
  }
});

const useStyles = makeStyles(styles);

export const TranslateWordingPopup = injectIntl(({
  isOpen,
  close,
  intl,
  language,
  wordings,
  usecaseName,
  setTranslatedWordings,
  onTranslate,
  onDontTranslate,
  showSubtitle = true,
}) => {

  const auth = useAuth();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const translateWording = () => {
    if (loading) {
      return ;
    }
    setLoading(true);
    auth.fetch("/api/account_management/translateWordings", {
      method: "POST",
      body: JSON.stringify({
        language,
        wordings,
        usecase: usecaseName,
      })
    }).then(response => {
      setLoading(false);
      setTranslatedWordings(response.translations);
      close();
    });
  };

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 20 } }}
      isOpen={isOpen}
      close={close}
    >
      <div className={classes.container}>
        <h2 className={classes.title}>{intl.messages["translateWordingPopup.title"]}</h2>
        {showSubtitle && <span>{intl.messages["translateWordingPopup.subtitle"]}</span>}
        <div className={classes.buttonContainer}>
          <FormButtonTO 
            label={intl.messages["translateWordingPopup.no"]}
            action={onDontTranslate || close}
            size="sm"
            type="secondary"
          />
          <FormButtonTO 
            label={intl.messages["translateWordingPopup.yes"]}
            action={onTranslate || translateWording}
            size="sm"
          />
        </div>
      </div>
    </Dialog>
  );
});