import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import { Dialog } from "@material-ui/core";
import { CustomButton } from "./Button/CustomButton";
import { palette } from "../../styles/palette.js";

const styles = (theme) => ({
  customScrollBar: {
    overflow: "hidden",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 0,
    paddingBottom: 0,
    "@global": {
      "*::-webkit-scrollbar": {
        width: 9,
      },
      "*::-webkit-scrollbar-track": {
        margin: 60,
        borderRadius: 9,
      },
      "*::-webkit-scrollbar-thumb": {
        background: palette.neutralMediumGrey,
        borderRadius: 9,
      }
    }
  },
  scrollingContent: {
    padding: "60px 20px",
    maxHeight: "calc(100vh - 184px)",
    overflowY: "scroll",
  },
  dialog: {
    backgroundColor: "white",
    padding: props => !props.customScrollBar && !props?.style?.padding ? 80 : null,
    position: "relative",
    borderRadius:"30px !important",
  },
  close: {
    position: "absolute",
    top: 32,
    right: 32,
    cursor: "pointer",
    fontSize: 20,
    width: "1em",
    height: "1em",
    display: "flex",
    justifyContent: "center",
    transform: "translate(50%, -50%)",
    color: palette.neutralDarkGrey,
    zIndex: 2,
    "&:hover": {
      color: palette.primaryBlack,
    },
  },
  closeString: {
    position: "absolute",
    top: 40,
    left: 40,
    gridGap: 10,
    display: "flex",
    alignItems: "center",
    color: palette.neutralDarkGrey,
    cursor: "pointer",
    "& i": {
      fontSize: "14px",
    }
  },
  title: {
    fontFamily: "Coolvetica",
    fontSize: "28px",
    letterSpacing: "-0.9px",
    color: palette.black,
    marginBottom: 32,
  },
  buttons: {
    display: "grid",
    justifyContent: "center",
    gridGap: 16,
    gridAutoFlow: "column",
    marginTop: 32,
  },
  container: {
    backgroundColor: "rgba(12, 12, 14, 0.4)"
  }
});

const CustomDialog = withStyles(styles)(
  injectIntl(
    class CustomDialog extends Component {
      render() {
        const {
          classes,
          isOpen,
          close,
          title,
          children,
          buttonsProps,
          PaperProps,
          BackdropProps,
          dialogProps,
          closeStyle = "cross", // cross | string | null
          intl,
          style,
          customScrollBar = false,
        } = this.props;

        return (
          <Dialog
            PaperProps={PaperProps || { style: { borderRadius: 40 } }}
            style={dialogProps}
            onClose={close}
            open={isOpen}
            maxWidth={false}
            scroll={"paper"}
            BackdropProps={BackdropProps}
            classes={{
              container: classes.container,
            }}
          >
            <div className={`${customScrollBar ? classes.customScrollBar : ""} ${classes.dialog}`}>
              <div className={customScrollBar ? classes.scrollingContent : null} style={style}>
                {closeStyle === "cross" &&
                  <i
                    className={`fa-solid fa-times ${classes.close}`}
                    onClick={close}
                    data-cy={"close-cross"}
                  ></i>
                }
                {closeStyle === "string" &&
                  <div
                    className={classes.closeString}
                    onClick={close}
                    data-cy={"close-string"}
                  >
                    <i className="fa-solid fa-chevron-left"></i>
                    <h4>{intl.messages["dialog.back"]}</h4>
                  </div>
                }

                {
                  title
                    ? <h2 className={classes.title}>{title}</h2>
                    : null
                }
                {children}

                {
                  buttonsProps
                    ? (
                      <div className={classes.buttons}>
                        {
                          buttonsProps.map((buttonProps, key) => (
                            <CustomButton
                              id={buttonProps.id}
                              type={buttonProps.type}
                              onClick={buttonProps.onClick}
                              loading={buttonProps.loading}
                              key={key}
                              dataCy={buttonProps.dataCy}
                              disabled={buttonProps.disabled}
                            >
                              {buttonProps.label}
                            </CustomButton>
                          ))
                        }
                      </div>
                    )
                    : null
                }
              </div>
            </div>
          </Dialog>
        );
      }
    }
  )
);

export { CustomDialog as Dialog };
