import { styles as stepperStyles } from "../../../../common/Stepper/Stepper.styles";

export const styles = {
  pageBodyWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    padding: "45px 110px",
    maxWidth: "calc(100vw - 220px)",
    maxHeight: "calc(100vh - 90px)",
    height: "100vh",
    overflow: "hidden",
  },
  withStepper: {
    maxHeight: `calc(100vh - 90px - ${stepperStyles.stepper.height}px)`,
  },
};
