import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Fragment } from "react";
import { injectIntl } from "react-intl";

import { FormButtonTO } from "../../FormButtonTO.jsx";
import { UsecaseConfigPopup } from "./UsecasePopups/UsecaseConfigPopup.js";

import one from "../../../public/one.png";
import basket from "../../../public/basket.png";
import email from "../../../public/details_email.png";
import target from "../../../public/details_target.png";
import senddate from "../../../public/details_senddate.png";
import algo from "../../../public/details_algo.png";
import clients from "../../../public/details_clients.png";
import gift from "../../../public/details_gift.png";
import mothersdayletter_fr from "../../../public/mothersday_letter_fr.png";
import mothersdayletter_en from "../../../public/mothersday_letter_en.png";
import mothersdayletter_es from "../../../public/mothersday_letter_es.png";
import dadtshirt from "../../../public/dad_tshirt.png";
import letter1 from "../../../public/letter1.png";
import letter2 from "../../../public/letter2.png";
import letter3 from "../../../public/letter3.png";
import partyhorn from "../../../public/party-horn.png";
import backpack from "../../../public/backpack.png";
import valentinesheart from "../../../public/valentinesHeart.png";
import computer from "../../../public/computer.png";
import cupid from "../../../public/cupid.png";
import santaclaus from "../../../public/santaClaus.png";
import gifts from "../../../public/gifts.png";
import witchshat from "../../../public/witchshat.png";
import pumpkin from "../../../public/pumpkin.png";
import calendar from "../../../public/calendar.png";
import sponsorship from "../../../public/sponsorship.png";
import groupgifts0 from "../../../public/groupgifts0.png";
import groupgifts from "../../../public/groupgifts.png";
import { palette } from "../../../styles/palette.js";
import { Translation } from "../../common/Translation.js";
import { usecasesChronologyConfig } from "../../../ressources/usecasesChronologyConfig.js";
import { UsecaseNewProductsConfigPopup } from "./UsecasePopups/UsecaseNewProductsConfigPopup.jsx";
import { UsecaseSponsorshipConfigPopup } from "./UsecasePopups/UsecaseSponsorshipConfigPopup.jsx";
import { UsecaseCrossSellingConfigPopup } from "./UsecasePopups/UsecaseCrossSellingConfigPopup.jsx";
import { AccountContextBehaviorSubject$ } from "../../../contexts/context";
import { withAuth } from "../../withAuth";

const images = {
  one,
  basket,
  email,
  target,
  senddate,
  algo,
  clients,
  gift,
  mothersdayletter_fr,
  mothersdayletter_en,
  mothersdayletter_es,
  dadtshirt,
  letter1,
  letter2,
  letter3,
  partyhorn,
  backpack,
  valentinesheart,
  computer,
  cupid,
  santaclaus,
  gifts,
  witchshat,
  pumpkin,
  calendar,
  sponsorship,
  groupgifts,
  groupgifts0,
};

const styles = (theme) => ({
  explanations: {
    backgroundColor: palette.white,
    width: "100%",
    height: "fit-content",
    padding: "70px 0",
    alignItems: "center",
    overflow: "hidden",
  },
  explanationsTitle: {
    fontFamily: "Coolvetica Book",
    fontSize: "40px",
    fontWeight: "600",
    textAlign: "center",
    color: palette.black,
  },
  explanationsDescription: {
    fontFamily: "Coolvetica Book",
    fontSize: "16px",
    fontWeight: "normal",
    color: palette.darkergray,
    textAlign: "center",
    margin: "70px 30px 40px",
  },
  showPromoCodeTemplateButton: {
    marginTop: 16,
    color: palette.primaryBlack,
    borderBottom: "1px solid " + palette.primaryBlack,
    lineHeight: 0.9,
    cursor: "pointer",
  },

  separator: {
    width: "1px",
    height: "130px",
    backgroundColor: "#e1e4e7",
    marginRight: "-1px",
  },

  explanationsBody: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  explanation: {
    width: "400px",
    padding: "50px",
    flexGrow: "1",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  explanationContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
    minWidth: 350,
    minHeight: 300,
    border: `1px solid ${palette.neutralMediumGrey}`,
    borderRadius: 20,
    position: "relative",
  },
  emailConfigButton: {
    position: "absolute",
    cursor: "pointer",
    color: palette.neutralDarkGrey,
    top: 10,
    right: 15,
    fontSize: 20,
  },
  explanationButtons: {
    marginTop: 32,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  explanationImage: {
    height: "auto",
    width: "70px",
  },
  explanationDescription: {
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    color: palette.darkergray,
    textAlign: "center",
    maxWidth: "325px",
    marginTop: "26px",
    letterSpacing: 1,
  },
  showCustomizer: {
    position: "relative",
  },
  usecaseNotification: {
    position: "absolute",
    top: -7,
    right: -10,
    marginLeft: 20,

    "& .triangle": {
      position: "absolute",
      top: 21,
      left: 0,
      width: 0,
      height: 0,
      borderTop: "14px solid transparent",
      borderBottom: "14px solid transparent",
      borderLeft: "14px solid transparent",
      borderRight: `14px solid ${palette.black}`,
    },

    "& .notification": {
      position: "absolute",
      top: 5,
      left: 28,
      minWidth: 220,
      height: 40,
      padding: 10,
      borderRadius: 3,
      backgroundColor: palette.black,
      color: palette.white,
      fontSize: 14,
    }
  }
});

export const UsecaseExplanations = withStyles(styles)(
  injectIntl(
    class UsecaseExplanations extends Component {

      constructor(props) {
        super(props);
        this.state = {};
      }
      
      static getDerivedStateFromProps(props, state) {
        if (props.usecaseConfig && !state?.usecaseConfig) {
          return {
            usecaseConfig: props.usecaseConfig,
          };
        }

        return null;
      }
      
      renderUsecaseMailExplanations() {
        const {
          classes,
          intl,
          emailExamples,
          usecase,
          showEmailEditor,
          showPromoCodeTemplateEditor,
          usecaseNotif,
          usecaseData,
          betaFeatures,
        } = this.props;

        return usecase.explanations.map((explanation, key) => {
          const buttons =
            explanation.templateDataKey !== undefined
              ? [
                <div className={classes.showCustomizer} data-cy="show-customizer">
                  <FormButtonTO
                    type={"secondary"}
                    label={intl.messages["details.explanations.editEmail"]}
                    action={() =>
                      showEmailEditor(
                        explanation.templateDataKey,
                        "customizer"
                      )
                    }
                  ></FormButtonTO>
                  {usecaseNotif && <div className={classes.usecaseNotification}>
                    <div className="triangle"></div>
                    <div className="notification">{usecaseNotif}</div>
                  </div>}
                </div>,
                explanation.mustDisablePromoCodeTemplateOpener ? null : (
                  <small
                    className={classes.showPromoCodeTemplateButton}
                    onClick={() =>
                      showPromoCodeTemplateEditor(explanation.templateDataKey)
                    }
                    data-cy="showPromoCodeTemplateEditor"
                  >
                    {emailExamples &&
                      emailExamples[explanation.templateDataKey]
                        .promoCodeTemplate
                      ? intl.messages["details.explanations.promoCode.edit"]
                      : intl.messages["details.explanations.promoCode.add"]}
                  </small>
                ),
              ]
              : [];

          return (
            <Fragment key={key}>
              <Explanation
                idx={key}
                imageSrc={images[explanation.key + (usecase.name === "mothersDay" ? `_${intl.locale}` : "")]}
                description={<Translation id={`details.${usecase.name}.content.explanations.${explanation.key}`} />}
                buttons={buttons}
                templateDataKey={explanation.templateDataKey}
                usecaseName={usecase.name}
                usecaseConfig={this.state.usecaseConfig}
                setUsecaseConfig={newConfig => this.setState({ usecaseConfig: newConfig })}
                usecaseData={usecaseData}
                betaFeatures={betaFeatures}
              />

              <div className={classes.separator}></div>
            </Fragment>
          );
        });
      }

      renderPromoCodeTemplateExplanation() {
        const { intl, usecase, showPromoCodeTemplateEditor, emailExamples } = this.props;

        const promoCodeTemplate = usecase.name === "sponsorship" ? emailExamples[0]?.promoCodeTemplate : null;

        const buttons = [
          <div>
            <FormButtonTO
              type={promoCodeTemplate ? "secondary" : "primary"}
              label={
                promoCodeTemplate
                  ? intl.messages["details.explanations.promoCode.edit"]
                  : intl.messages["details.explanations.showPromoCodeTemplateEditor"]
              }
              action={() => showPromoCodeTemplateEditor(0)}
              dataCy="showPromoCodeTemplateEditor"
            ></FormButtonTO>
          </div>,
        ];

        return (
          <Explanation
            imageSrc={images["one"]}
            description={
              intl.messages[
                `details.${usecase.name}.content.explanations.promoCodeTemplate`
              ]
            }
            buttons={buttons}
          />
        );
      }

      renderUsecaseLandingPageExplanation() {
        const { intl, usecase, showLandingPageExample } = this.props;

        const buttons = [
          <div>
            <FormButtonTO
              type={"primary"}
              label={intl.messages["details.explanations.showLandingPage"]}
              action={showLandingPageExample}
            ></FormButtonTO>
          </div>,
        ];

        return (
          <Explanation
            imageSrc={images["computer"]}
            description={<Translation id={`details.${usecase.name}.content.explanations.computer`} />}
            buttons={buttons}
          />
        );
      }

      renderUsecaseExplanations() {
        const { classes, usecase } = this.props;

        let usecaseMailExplanations = this.renderUsecaseMailExplanations();

        if (usecase.name === "sponsorship") {
          const promoCodeTemplateExplanation = this.renderPromoCodeTemplateExplanation();
          const usecaseLandingPageExplanation = this.renderUsecaseLandingPageExplanation();

          usecaseMailExplanations = [
            promoCodeTemplateExplanation,
            usecaseMailExplanations[0],
            usecaseLandingPageExplanation,
            usecaseMailExplanations[1],
            usecaseMailExplanations[2],
            usecaseMailExplanations[3],
          ];
        }

        return usecaseMailExplanations.map((usecaseExplanation, key) => (
          <Fragment key={key}>
            {usecaseExplanation}

            <div className={classes.separator}></div>
          </Fragment>
        ));
      }

      render() {
        const { classes, usecase } = this.props;

        return (
          <div id="explanations" className={classes.explanations}>
            <div className={classes.explanationsTitle}>
              <Translation id={`details.${usecase.name}.content.explanations.title`} />
            </div>

            <div className={classes.explanationsDescription} id="explanations">
              <Translation id={`details.${usecase.name}.content.explanations.description`} />
            </div>

            <div className={classes.explanationsBody}>
              {this.renderUsecaseExplanations()}
            </div>
          </div>
        );
      }
    }
  )
);

const Explanation = withAuth(withStyles(styles)(
  injectIntl(
    class Explanation extends Component {

      constructor(props) {
        super(props);

        this.state = {
          configIsOpen: false,
          configNewProductIsOpen: false,
          configCrossSellingIsOpen: false,
          configSponsorshipIsOpen: false,
          distinctOrderStatus: [],
        };
      }

      componentDidMount() {
        const { account } = AccountContextBehaviorSubject$.getValue();

        this.props.auth.fetch(`/data/getDistinctOrderStatuses`, {
          method: "GET"
        }).then(orderStatuses => {
          this.setState({
            distinctOrderStatus: orderStatuses,
          });
        });
      }

      render() {
        const {
          classes,
          imageSrc,
          description,
          buttons,
          usecaseName,
          templateDataKey,
          usecaseConfig,
          usecaseData,
          setUsecaseConfig,
          idx
        } = this.props;

        const showUsecaseConfig = 
          !!usecaseName && 
          !!usecasesChronologyConfig[usecaseName] && 
          !!buttons.length;

        let onConfigClick;

        if (showUsecaseConfig) {
          onConfigClick = () => this.setState({ configIsOpen: true });
        }

        if (usecaseName === "newProducts" && idx === 0) {
          onConfigClick = () => this.setState({ configNewProductIsOpen: true });
        }
  
        if (usecaseName === "crossSelling" && !!buttons.length) {
          onConfigClick = () => this.setState({ configCrossSellingIsOpen: true });
        }

        if (usecaseName === "sponsorship" && !!buttons.length && templateDataKey === 0 && this.state.distinctOrderStatus.length) {
          onConfigClick = () => this.setState({ configSponsorshipIsOpen: true });
        }

        return (
          <>
            <div className={classes.explanation}>
              <div className={classes.explanationContent}>
                {!!onConfigClick &&
                  <div className={classes.emailConfigButton}
                    onClick={onConfigClick}
                  >
                    <i className="fa-solid fa-sliders" />
                  </div>
                }
                <img
                  className={classes.explanationImage}
                  src={imageSrc}
                  alt={"explanation"}
                />
                <p className={classes.explanationDescription}>
                  {description}
                </p>
                <div className={classes.explanationButtons}>
                  {buttons.map((button, key) => (
                    <Fragment key={key}>{button}</Fragment>
                  ))}
                </div>
              </div>
            </div>
            {showUsecaseConfig && <UsecaseConfigPopup 
              isOpen={this.state.configIsOpen}
              close={() => this.setState({ configIsOpen: false })}
              usecaseName={usecaseName}
              templateDataKey={templateDataKey}
              usecaseConfig={usecaseConfig}
              usecase={usecaseData}
              setUsecaseConfig={setUsecaseConfig}
            />}
            <UsecaseNewProductsConfigPopup 
              isOpen={this.state.configNewProductIsOpen}
              close={() => this.setState({ configNewProductIsOpen: false })}
            />
            <UsecaseCrossSellingConfigPopup 
              isOpen={this.state.configCrossSellingIsOpen}
              close={() => this.setState({ configCrossSellingIsOpen: false })}
              usecaseConfig={usecaseConfig}
              templateDataKey={templateDataKey}
              setUsecaseConfig={setUsecaseConfig}
              usecaseName={usecaseName}
            />
            <UsecaseSponsorshipConfigPopup 
              isOpen={this.state.configSponsorshipIsOpen}
              close={() => this.setState({ configSponsorshipIsOpen: false })}
              usecaseConfig={usecaseConfig}
              templateDataKey={templateDataKey}
            />
          </>
        );
      }
    }
  )
));
