export function formatHtmlIntoShortText(text) {
  if (!text) {
    return "";
  }

  const encodedHTMLtext = document.createElement("textarea");
  encodedHTMLtext.innerHTML = text;

  const brutText = encodedHTMLtext.value.replace(/<[^>]*\/?>/g, "");
  const splittedText = brutText.split(" ");

  const spaceNumber = splittedText.length;
  let formattedShortText = "";
  if (spaceNumber >= 20) {
    for(let i = 0; i < 20; i++) {
      formattedShortText += splittedText[i] + " ";
    }
    formattedShortText += "...";
  } else {
    formattedShortText = brutText;
  }
  return formattedShortText;
}
