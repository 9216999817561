import { useState, useEffect, useRef, useMemo, useContext, useCallback } from "react";
import { useIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";

import { Translation } from "./../../../common/Translation";
import { useAuth } from "./../../../../hooks/useAuth";
import { EventSystem } from "../../../../eventsystem/EventSystem";
import { categories } from "./imageMenuConfig";
import { ConfirmDeleteImagePopup } from "../../popups/ConfirmDeleteImagePopup";
import { parseFileInput } from "../../../../services/file.service";
import { AccountContext } from "../../../../contexts/context.js";

import classes from "./imageMenu.module.scss";

export const checkImage = (file, image) => file.size < 3 * 1024 * 1024 && image.width <= 800 && image.height <= 800 && !file.type.startsWith("image/svg");

export const ImageOptionalMenu = ({
  component,
  editor,
  onChange,
  selectedUrl,
  allowCrop,
  type = "images",
  goBack, 
  scrollableContainerClass,
  onConfigure,
  fixedHeader = false,
  onUpload,
  imagesToAdd = [],
  templateId
}) => {
  const intl = useIntl();
  const auth = useAuth();
  const { account } = useContext(AccountContext);

  const [selectedCategory, setSelectedCategory] = useState("all");
  const [unsplashPhotos, setUnsplashPhotos] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [dalleImages, setDalleImages] = useState([]);
  const [outpaintingImages, setOutpaintingImages] = useState([]);
  const [isLoading, setIsLoading] = useState({
    unsplash: true,
    images: true,
    dalle: true,
    outpainting: true
  });
  const [imageToDelete, setImageToDelete] = useState(null);
  const imageInputRef = useRef();
  const [needScrool, setNeedScroll] = useState(true);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (type !== "images") {
      setIsLoading(prev => ({ ...prev, unsplash: false }));
      return ;
    }

    if (sessionStorage.getItem("unsplashPhotos")) {
      setUnsplashPhotos(JSON.parse(sessionStorage.getItem("unsplashPhotos")));
      setIsLoading(prev => ({ ...prev, unsplash: false }));
      return;
    }

    auth.fetch(`/account_management/getUnsplashPhotos?templateId=${templateId}`)
      .then((response) => {
        sessionStorage.setItem("unsplashPhotos", JSON.stringify(response.pictures));
        setUnsplashPhotos(response.pictures);
        setIsLoading(prev => ({ ...prev, unsplash: false }));
      });
  }, [auth, type]);

  useEffect(() => {
    if (uploadedImages.length) { return; }
    auth.fetch(`/account_management/imageAssets?type=${type}`).then(response => {
      
      let result;
      if (type === "logos") {
        result = [account.url_logo, ...response.reverse()];
      } else {
        result = [...imagesToAdd, ...response.reverse()];
      }
      
      setUploadedImages(result);
      setIsLoading(prev => ({ ...prev, images: false }));
    });
  }, [auth, type, account, imagesToAdd, uploadedImages]);

  const intervalRef = useRef();
  const fetchDalleAssets = useCallback(() => {
    auth.fetch(`/dalle/dalleAssets?templateId=${templateId}`, {}, { endpoint: "llm-hub"}).then(response => {
      if (response.length) {
        setDalleImages(response);
      }
      if (response.length === 4) {
        setIsLoading(prev => ({ ...prev, dalle: false }));
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }).catch(() => {});
  }, [auth, templateId]);

  const outpaintingIntervalRef = useRef();
  let retries = 0;

  const fetchOutpaintedAssets = useCallback(() => {
    auth.fetch(`/account_management/getOutpaintedImages?templateId=${templateId}`).then(response => {

      setOutpaintingImages(response.map(img => img.url));

      retries++;

      if (response.length === 2 || retries > 7) {
        setIsLoading(prev => ({ ...prev, outpainting: false }));
        clearInterval(outpaintingIntervalRef.current);
        outpaintingIntervalRef.current = null;
      }
    });
  }, [auth, templateId, retries]);

  useEffect(() => {
    if (!intervalRef.current) {
      const timing = dalleImages.length ? 1000 : 5000;
      intervalRef.current = setInterval(fetchDalleAssets, timing);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [auth, templateId, type, dalleImages, fetchDalleAssets]);

  useEffect(() => {
    if (!outpaintingIntervalRef.current) {
      const timing = outpaintingImages.length ? 1000 : 5000;
      outpaintingIntervalRef.current = setInterval(fetchOutpaintedAssets, timing);
    }

    return () => {
      if (outpaintingIntervalRef.current) {
        clearInterval(outpaintingIntervalRef.current);
        outpaintingIntervalRef.current = null;
      }
    };
  }, [auth, templateId, type]);

  const changeSrc = (url, download = null) => {
    if (download) {
      auth.fetch("/account_management/triggerUnsplashDownloadEvents", {
        method: "POST",
        body: JSON.stringify({
          download
        })
      });
    }

    onChange(url);
  };

  const onUploadImage = event => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.readyState === 2) {
        const image = new Image();
        image.src = reader.result;
        const file = imageInputRef.current.files[0];
        image.onload = () => {
          if (checkImage(file, image)) {
            parseFileInput(file).then(fileData => {
              auth.fetch("/account_management/uploadImageAsset", {
                method: "POST",
                body: JSON.stringify({
                  file: {
                    filename: fileData.name,
                    type: fileData.type,
                    content: fileData.content,
                  },
                  folder: type,
                })
              }).then(url => {
                setUploadedImages(prev => [url, ...prev]);
                onUpload && onUpload(url);
              });
            });
          } else {
            EventSystem.newNotification(
              "notification.error",
              intl.messages["optionalMenu.image.error"]
            );
          }
        };
      }
    };

    // "if" condition fix crash if instead of choose an attachement, clicking cancel
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const deleteImage = () => {

    auth.fetch(`/account_management/deleteImageAsset?imageUrl=${encodeURIComponent(imageToDelete)}`, {
      method: "DELETE",
    });

    setUploadedImages(prev => [...prev].filter(url => url !== imageToDelete));
    setImageToDelete(null);
  };

  useEffect(() => {
    if (selectedUrl && needScrool && scrollableContainerClass && Object.values(isLoading).every(value => !value) ) {
      const htmlElement = document.getElementsByClassName(classes.photoSelected);
      if (htmlElement.length) {
        const container = document.getElementsByClassName(scrollableContainerClass);
        container[0].scrollTo({
          top: htmlElement[0].getBoundingClientRect().top - container[0].getBoundingClientRect().top - 300,
          behavior:  "smooth",
        });
        setNeedScroll(false);
      }
    }
  }, [selectedUrl, needScrool, isLoading, scrollableContainerClass]);

  const categoriesToShow = useMemo(() => categories.filter(category => category.name === "all" || category.type === type), [type]);

  const uploadZone = (
    <div className={selectedCategory !== "all" ? classes.fullWidthContainer : classes.columnContainer}>
      <div
        className={classes.uploadContainer}
        onClick={() => imageInputRef?.current.click()}
      >
        <i className="fa-solid fa-cloud-arrow-up" />
        <div className="infoMedium"><Translation id={"optionalMenu.category.upload"} /></div>
        <div className={`infoSmall ${classes.uploadConstraints}`}><Translation id="optionalMenu.category.uploadConstraints" /></div>
        <input
          ref={imageInputRef}
          type="file"
          accept="image/*"
          className={classes.imageInput}
          onChange={onUploadImage}
        />
      </div>
    </div>
  );

  const uploadedImagesToShow = useMemo(() => {
    const images = [selectedUrl, ...uploadedImages.filter(url => url !== selectedUrl)];
    if (showAll) {
      return images;
    } else {
      return images.slice(0, Math.min(3, uploadedImages.length));
    }
  }, [showAll, uploadedImages, selectedUrl]);

  return (
    <>
      <div className={classes.container}>        
        <div className={classes.categoryBlocksContainer}>
          {type === "images" && 
            <div className={fixedHeader ? classes.fixedHeader : classes.header}>
              {!!goBack &&
                <div
                  className={classes.closeString}
                  onClick={goBack}
                  data-cy={"close-string"}
                >
                  <i className="fa-solid fa-angle-left"></i>
                  <h4><Translation id="dialog.back" /></h4>
                </div>
              }
              <div className={classes.secondaryCategories}>
                {
                  [...categoriesToShow].filter(category => !category.parent).map(category => (
                    <span
                      key={category.name}
                      className={`infoSmall2 ${classes.categoryTag} ${selectedCategory === category.name ? classes.categoryTagSelected : ""}`}
                      onClick={() => setSelectedCategory(category.name)}
                    >
                      <Translation id={`optionalMenu.category.${category.name}`} />
                    </span>
                  ))
                }
              </div>
              {!fixedHeader && <div className={classes.separator} />}
            </div>
          }

          <div className={classes.body}>
            {[...(selectedCategory === "all" ? categoriesToShow.filter(category => category.parent) : [categoriesToShow.find(category => category.name === selectedCategory)])].map(category => (
              <div key={category.name}>
                {categoriesToShow.filter(({ parent }) => parent === category.name).map(subCategory => (
                  <div className={classes.subCategories} key={subCategory.name}>
                    {category.showName !== false &&
                      <div className={classes.rowContainer}>

                        { category.logo ? <img className={classes.logo} alt={category.logo} src={category.logo} /> : null }
                        { category.icon ? <i className={category.icon}></i> : null }

                        <div className={`textMedium ${classes.tertiaryCategoryName}`}><Translation id={`optionalMenu.category.${subCategory.name}`} /></div>
                        {category.beta ? <div className={classes.betaContainer}>
                          <Translation id={"editor.beta"} />
                        </div> : null}
                      </div>
                    }
                    {
                      subCategory.name === "unsplash" ?
                        !isLoading.unsplash ?
                          <div className={selectedCategory !== "all" ? classes.photosColumnContainer : classes.photosContainer}>
                            {
                              unsplashPhotos.map((photo, idx) => (
                                <div key={idx} className={selectedCategory !== "all" ? classes.unsplashColumnContainer : classes.columnContainer}>
                                  <div className={classes.imageContainer}>
                                    <img alt={photo.name} className={`${classes.photo} ${selectedUrl === photo.url ? classes.photoSelected : ""}`} src={photo.url} onClick={() => changeSrc(photo.url, photo.download)} />
                                    {allowCrop && selectedUrl === photo.url && 
                                      <div className={classes.editImage} onClick={() => onConfigure ? onConfigure() : editor.trigger("cropImage", { component, type })}>
                                        <h4><Translation id="button.configure" /></h4>
                                      </div>
                                    }
                                  </div>
                                  <a className={classes.artist} href={`${photo.portfolio}?utm_source=Kiliba&utm_medium=referral `} target="_blank" rel="noreferrer" >{photo.name}</a>
                                </div>
                              ))
                            }
                          </div>
                          : (
                            <div className={classes.loadingContainer}>
                              <CircularProgress className={classes.loader} size="30px" />
                            </div>
                          ) : null

                    }
                    {
                      subCategory.name === "myImages" || subCategory.name === type ?
                        <div className={selectedCategory !== "all" ? classes.photosColumnContainer : classes.myImagesContainer}>
                          {uploadZone}
                          {uploadedImagesToShow.map((img, idx) => (
                            <div key={idx} className={selectedCategory !== "all" ? classes.fullWidthContainer : classes.columnContainer}>
                              <div className={classes.imageContainer}>
                                <img alt="" className={`${classes.photo} ${selectedUrl === img ? classes.photoSelected : ""}`} src={img} onClick={() => changeSrc(img)} />
                                {selectedUrl !== img && 
                                  <div onClick={() => setImageToDelete(img)} className={classes.iconDeleteImageContainer}>
                                    <i className={`fa-solid fa-trash-can ${classes.iconDeleteImage}`} />
                                  </div>
                                }
                                {allowCrop && selectedUrl === img && 
                                  <div className={classes.editImage} onClick={() => onConfigure ? onConfigure() : editor.trigger("cropImage", { component, type })}>
                                    <h4><Translation id="optionalMenu.images.crop" /></h4>
                                  </div>
                                }
                              </div>
                            </div>
                          ))}
                          {!showAll && uploadedImages.length > 3 && 
                            <div className={classes.showAllButton}>
                              <span className="link" onClick={() => setShowAll(true)}><Translation id="template.imageMenu.seeMore" /></span>
                            </div>
                          }
                        </div> : null

                    }
                    {
                      subCategory.name === "openAI"  &&
                      <>
                        <div className={selectedCategory !== "all" ? classes.photosColumnContainer : classes.myImagesContainer}>
                          {dalleImages.map((img, idx) => (

                            <div key={idx} className={selectedCategory !== "all" ? classes.fullWidthContainer : classes.columnContainer}>
                              <div className={classes.imageContainer}>
                                <img alt="" className={`${classes.photo} ${selectedUrl === img ? classes.photoSelected : ""}`} src={img} onClick={() => changeSrc(img)} />
                                {allowCrop && selectedUrl === img && 
                                    <div className={classes.editImage} onClick={() => onConfigure ? onConfigure() : editor.trigger("cropImage", { component, type })}>
                                      <h4><Translation id="button.configure" /></h4>
                                    </div>
                                }
                              </div>
                            </div>
                            
                          ))}
                          
                          {Array.from({ length: 4 - dalleImages.length }).map((_, index) => (
                            <div className={`${classes.card} ${classes.loading}`}></div>  
                          ))}

                          {outpaintingImages.map((img, idx) => (
                            <div key={idx} className={selectedCategory !== "all" ? classes.fullWidthContainer : classes.columnContainer}>
                              <div className={classes.imageContainer}>
                                <img alt="" className={`${classes.photo} ${selectedUrl === img ? classes.photoSelected : ""}`} src={img} onClick={() => changeSrc(img)} />
                                {allowCrop && selectedUrl === img && 
                                    <div className={classes.editImage} onClick={() => onConfigure ? onConfigure() : editor.trigger("cropImage", { component, type })}>
                                      <h4><Translation id="button.configure" /></h4>
                                    </div>
                                }
                              </div>
                            </div>
                          ))
                          }

                          {
                            isLoading.outpainting ? Array.from({ length: 2 - outpaintingImages.length }).map((_, index) => (
                              <div className={`${classes.card} ${classes.loading}`}></div>  
                            )) : null
                          }

                        </div>
                      </>
                    }
                   
                   
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <ConfirmDeleteImagePopup 
        isOpen={!!imageToDelete}
        close={() => setImageToDelete(null)}
        deleteImage={deleteImage}
      />
    </>
  );
};