import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Stepper } from "../common/Stepper/Stepper";
import { OnBoardingPages } from "./OnBoardingPages/OnBoardingPages";
import { VerifyEmail } from "./OnBoardingPages/VerifyEmail/VerifyEmail";

import { styles } from "./OnBoarding.styles";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useIntl } from "react-intl";

import { AccountContextBehaviorSubject$ } from "../../contexts/context";

const useStyles = makeStyles(styles);

export function OnBoarding(props) {
  const classes = useStyles();
  const history = useHistory();
  const auth = useAuth();
  const intl = useIntl();

  const [account, setAccount] = useState(undefined);
  const [pageDatas, setPageDatas] = useState(undefined);
  const [pageKey, setPageKey] = useState(undefined);

  const organizePages = useCallback(
    async () => {
      const pageDatas = [];
      let newPageKey;

      const { account } = AccountContextBehaviorSubject$.getValue();
      const user = await auth.getUser();

      const shopifyModuleIsSetup = !!account?.shopify;
      if (account?.CMS === "shopify" && account?.createdFrom === "kiliba" && shopifyModuleIsSetup) {
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.CmsCongrats, stepKey: 0 };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.TemplateCustomization, stepKey: 1 };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.UsecaseSelection, stepKey: 2 };

        if (account?.step_register === 0) newPageKey = 0;
        if (account?.step_register === 1) newPageKey = 0;
        if (account?.step_register === 2) newPageKey = 0;
        if (account?.step_register === 3) newPageKey = 1;
        if (account?.step_register === 4) newPageKey = 2;
      } else if (account?.CMS === "shopify" && account?.createdFrom === "shopify") {
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.VerifyEmail };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.Welcome, stepKey: 0 };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.JobsToBeDone, stepKey: 0 };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.ShopInfo, stepKey: 0 };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.TemplateCustomization, stepKey: 1 };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.UsecaseSelection, stepKey: 2 };

        if (account?.step_register === 0) { newPageKey = user.attributes["email_verified"] ? 1 : 0; };
        if (account?.step_register === 1) newPageKey = 3;
        if (account?.step_register === 2) newPageKey = 4;
        if (account?.step_register === 3) newPageKey = 4;
        if (account?.step_register === 4) newPageKey = 5;
      } else {
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.VerifyEmail };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.Welcome };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.JobsToBeDone, };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.ShopInfo };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.ConnectYourShop, };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.ChooseCms, stepKey: 0, };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.CmsCongrats, stepKey: 0, };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.TemplateCustomization, stepKey: 1 };
        pageDatas[pageDatas.length] = { Page: OnBoardingPages.UsecaseSelection, stepKey: 2 };

        if (account?.step_register === 0) { newPageKey = user.attributes["email_verified"] ? 1 : 0; };
        if (account?.step_register === 1) newPageKey = 3;
        if (account?.step_register === 2) newPageKey = 4;
        if (account?.step_register === 3) newPageKey = 7;
        if (account?.step_register === 4) newPageKey = 8;
      }

      if (account?.step_register === 5) {
        history.replace("/");
      }

      pageDatas.forEach((pageData) => {
        pageData.Page = (
          <pageData.Page
            onSubmit={() => setPageKey(prevPageKey => prevPageKey + 1)}
          />
        );
      });

      setAccount(account);
      setPageDatas(pageDatas);
      setPageKey(newPageKey);
    },
    [auth, history]
  );

  useEffect(() => {
    organizePages();
  }, [organizePages]);

  const steps = useMemo(
    () => {
      const NB_STEPS = 3;
      const steps = [];

      const stepperType = account?.CMS === "shopify" ? "shopify" : "generic";

      for (let i = 0; i < NB_STEPS; i++) {
        let variant;
        if (stepperType === "shopify" && i === 0) {
          if (account.createdFrom === "shopify") {
            variant = "fromShopify";
          } else if (account.createdFrom === "kiliba") {
            variant = "fromKiliba";
          } else {
            variant = "fromUnknown";
          }
        }

        steps.push({
          title: intl.messages[`onBoarding.stepper.${stepperType}.step${i}${variant ? `.${variant}` : ""}`],
          key: i,
          isLast: i === NB_STEPS - 1,
        });
      }

      return steps;
    },
    [account, intl.messages]
  );

  const currentPageData = useMemo(
    () => {
      if (pageDatas === undefined || pageKey === undefined)
        return null;

      const currentPageData = pageDatas[pageKey];

      return currentPageData;
    },
    [pageDatas, pageKey]
  );

  return (
    <div className={classes.onBoarding}>
      {currentPageData?.stepKey !== undefined ? (
        <Stepper
          steps={steps}
          activeStepKey={currentPageData.stepKey}
        />
      ) : null}
      {currentPageData?.Page}
    </div>
  );
}
