import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

import { styles } from "../styles/unsubscribe.js";

const useStyles = makeStyles(styles);

export function Unsubscribe() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.topbar}></div>
      <div className={classes.wording}>
        <FormattedMessage
          className={classes.wording}
          id="unsubscribe.title"
          defaultMessage="Welcome"
        />
      </div>
      <div className={classes.subWording}>
        <FormattedMessage
          id="unsubscribe.subtitle"
          defaultMessage="Welcome"
        />
      </div>
    </div>
  );
}