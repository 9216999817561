import React from "react";
import { createRoot } from "react-dom/client";
import * as amplitude from "@amplitude/analytics-browser";
import { Amplify, Hub } from "aws-amplify";
import { MainApp } from "./components/MainApp.js";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "@mui/material";
import { themeV4 } from "./themeV4.js";
import { theme } from "./theme.js";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/fr";
import { frFR } from "@mui/x-date-pickers/locales";
import { datadogRum } from "@datadog/browser-rum";
import { AccountContextProvider, emptyAccountContext } from "./../src/contexts/context.js";

amplitude.init("b8ebbbc089fe75e7f3f91132f80d1ed7");

if(process.env.REACT_APP_ENV === "production" && window.location.pathname !== "/unsubscribe") {
  datadogRum.init({
    applicationId: "bc8d91c1-83c1-443b-972f-a0e99fced993",
    clientToken: "pube3f9b7b4331ea4c59a6dbc08eaa0d8b3",
    site: "datadoghq.eu",
    service: "production-kiliba",
    version: process.env.REACT_APP_VERSION,
    env: process.env.REACT_APP_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input"
  });

  datadogRum.startSessionReplayRecording();
}

const federatedSignInUrl =  window.location.origin + "/callback";
const signOutUrl =  window.location.origin + "/login";

Hub.listen("auth", ({ payload: { event, data } }) => {
  switch(event) {
    case "oAuthSignOut":
      emptyAccountContext();
      break;
    case "signOut":
      emptyAccountContext();
      window.location = signOutUrl;
      break;
  }
});

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION || "eu-central-1",
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || "eu-central-1_fxavu7xqy",
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || "7c984podhg8h1lsoia9uqmjle3",
    mandatorySignIn: true,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    oauth: {
      domain: process.env.REACT_APP_ENV === "production"
        ? "kiliba-production-api.auth.eu-central-1.amazoncognito.com"
        : "kiliba-preprod-api.auth.eu-central-1.amazoncognito.com",
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
        "backend-api/webapp-access"
      ],
      redirectSignIn: federatedSignInUrl,
      redirectSignOut: signOutUrl,
      responseType: "code",
      urlOpener: async (url) => {
        window.location.replace(url);
        return window;
      }
    }
  }
});

const domNode = document.getElementById("root");
const root = createRoot(domNode);
root.render(
  <div>
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={themeV4}>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          <AccountContextProvider>
            <MainApp />
          </AccountContextProvider>
        </LocalizationProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  </div>
);