export function processSocialNetworkBlocks(socialNetworkIcons, columnWidth, iconSize = 25) {
  let mjmlContent = "";

  if (socialNetworkIcons.length <= 5) {
    return `
      <mj-column vertical-align="middle" width="${columnWidth}%" padding="0px">
        <mj-social icon-size="25px" mode="horizontal">
          ${socialNetworkIcons.join("")}
        </mj-social>
      </mj-column>
    `;
  }

  if (socialNetworkIcons.length === 6 || socialNetworkIcons.length === 8) {
    const halfwayPoint = Math.ceil(socialNetworkIcons.length / 2);
    const firstLineIcons = socialNetworkIcons.slice(0, halfwayPoint);
    const secondLineIcons = socialNetworkIcons.slice(halfwayPoint);

    mjmlContent += `
      <mj-column vertical-align="middle" width="${columnWidth}%" padding="0px">
        <mj-social icon-size="${iconSize}px" mode="horizontal" padding="0px">
          ${firstLineIcons.join("")}
        </mj-social>`;
    if (secondLineIcons.length > 0) {
      mjmlContent += `
        <mj-social icon-size="${iconSize}px" mode="horizontal" padding="0px">
          ${secondLineIcons.join("")}
        </mj-social>`;
    }
    mjmlContent += "</mj-column>";
  }

  if (socialNetworkIcons.length === 7) {
    const firstLineIcons = socialNetworkIcons.slice(0, 4);
    const secondLineIcons = socialNetworkIcons.slice(4);

    mjmlContent += `
      <mj-column vertical-align="middle" width="${columnWidth}%" padding="0px">
        <mj-social icon-size="${iconSize}px" mode="horizontal" padding="0px">
          ${firstLineIcons.join("")}
        </mj-social>
      </mj-column>`;
    mjmlContent += `
      <mj-column vertical-align="middle" width="${columnWidth}%" padding="0px">
        <mj-social icon-size="${iconSize}px" mode="horizontal">
          ${secondLineIcons.join("")}
        </mj-social>
      </mj-column>`;
  }

  return mjmlContent;
}