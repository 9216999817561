import mjml2html from "mjml-browser";

import { getLogoBlocks, extractContentFromLogoComponent } from "./logo";
import { getImageBlocks, extractContentFromImageComponent } from "./images";
import { getButtonBlocks, extractContentFromButtonComponent } from "./button";
import { getProductBlocks, extractContentFromProductComponent } from "./products";
import { getTextBlocks, extractContentFromTextComponent } from "./texts";
import { getPreheaderBlocks, extractContentFromPreheaderComponent } from "./preheader";
import { getReviewBlocks } from "./review";
import { getPromoCodeBlocks, extractContentFromPromoCodeComponent } from "./promoCode";
import { getColumnBlocks, extractContentFromColumnsComponent, extractContentFromColumnComponent, extractContentFromColumnSubComponent } from "./columns";

import { footer1 } from "./footer/footer1";
import { footer2 } from "./footer/footer2";
import { footer3 } from "./footer/footer3";
import { footer4 } from "./footer/footer4";
import { footer5 } from "./footer/footer5";

import { header1 } from "./header/header1";
import { header2 } from "./header/header2";
import { header3 } from "./header/header3";

import { body1 } from "./body/body1";
import { body2 } from "./body/body2";
import { body3 } from "./body/body3";

import { whiteSeparator } from "./components/separators/whiteSeparator";
import { lineSeparator } from "./components/separators/lineSeparator";

import { social1 } from "./components/social/social1";
import { social2 } from "./components/social/social2";
import { social3 } from "./components/social/social3";
import { social4 } from "./components/social/social4";

import { productPreset } from "./presets/productPreset";
import { promotionPreset } from "./presets/promotionPreset";
import { monoProductPreset } from "./presets/monoProductPreset";

import { AuthService } from "./../../../../components/AuthService";

export const extractContentFromComponent = (category, component, wordings, language, variables) => {
  switch (category) {
    case "image": return extractContentFromImageComponent(component, wordings, language, variables);
    case "logo": return extractContentFromLogoComponent(component, wordings, language, variables);
    case "button": return extractContentFromButtonComponent(component, wordings, language, variables);
    case "product": return extractContentFromProductComponent(component, wordings, language, variables);
    case "text": return extractContentFromTextComponent(component, wordings, language, variables);
    case "promoCode": return extractContentFromPromoCodeComponent(component, wordings, language, variables);
    case "preheader": return extractContentFromPreheaderComponent(component, wordings, language, variables);
    case "columns": return extractContentFromColumnsComponent(component, wordings, language, variables);
    case "column": return extractContentFromColumnComponent(component, wordings, language, variables);
    case "columnComponent": return extractContentFromColumnSubComponent(component, wordings, language, variables);
    default: return {};
  };
};

const getPreviewFromContent = (content, {
  style = "",
  mediaQueryStyle = "",
  wordings,
  variables,
  language = "fr",
  previewStyle = "",
  category
}) => {

  let finalContent = content;

  if (wordings) {
    Object.entries(wordings).forEach(([key, wordingByLang]) => {
      finalContent = finalContent.replaceAll(`{{${key}}}`, wordingByLang[language]);
    });
  }

  if (variables) {
    Object.entries(variables).forEach(([key, variableByLang]) => {
      finalContent = finalContent.replaceAll(`{{${key}}}`, variableByLang[language]);
    });
  }

  if (category.startsWith("columnComponent")) {
    finalContent = `
      <mj-section padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" full-width="full-width">
        <mj-column padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px">
        ${finalContent}
        </mj-column>
      </mj-section>
    `;
  }

  return mjml2html(`
  <mjml>
    <mj-head>
      ${style ? `
        <mj-style>
          ${style}
        </mj-style>
      ` : ""}
      ${previewStyle ? `
        <mj-style>
          ${previewStyle}
        </mj-style>
      ` : ""}
      ${mediaQueryStyle ? `
        <mj-style>
          ${mediaQueryStyle}
        </mj-style>
      ` : ""}
    </mj-head>
    <mj-body>
      ${finalContent}
    </mj-body>
  </mjml>
  `, { validationLevel: "skip" }).html;
};

const getTemplateInfo = async (options) => {
  const Auth = new AuthService();
  try {
    return await Auth.fetch(`/api/account_management/mjmlTemplate?templateId=${options.templateId}`);
  } catch {}
};

const getProducts = async (options) => {
  const Auth = new AuthService();
  try {
    const products = await Auth.fetch(`/data/newsletterProducts?templateId=${options.templateId}`);
    await products.map((product) => {
      product.price = parseFloat(product.price).toFixed(2);
    });
    return products;
  } catch {}
};

const loadHeadBandImage = async (options) => {
  const Auth = new AuthService();
  try {
    return await Auth.fetch(`/turbo/scrapHeadBandImages?templateId=${options.templateId}`);
  } catch {}
};

const loadTrustPilotReview = async (options) => {
  const Auth = new AuthService();
  try {
    const result = await Auth.fetch(`/account_management/getReviewsFromTrustpilot?templateId=${options.templateId}`);
    if (!result) {
      return null;
    }
    return { globalRating: result?.globalRating, reviews: result?.reviews.filter(review => review.customerNickname) };
  } catch {}
};

const loadAvisVerifiesReviews = async (options) => {
  const Auth = new AuthService();
  try {
    const result = await Auth.fetch(`/account_management/getReviewsFromAvisVerifies?templateId=${options.templateId}`);
    if (!result) {
      return null;
    }
    return { globalRating: result?.globalRating, reviews: result?.reviews.filter(review => review.customerNickname) };
  } catch {};
};

const loadUnsplashPhotos = async (options) => {
  const Auth = new AuthService();
  try {
    if (sessionStorage.getItem("unsplashPhotos")) {
      return JSON.parse(sessionStorage.getItem("unsplashPhotos"));
    }
    const response = await Auth.fetch(`/account_management/getUnsplashPhotos?templateId=${options.templateId}`);
    sessionStorage.setItem("unsplashPhotos", JSON.stringify(response.pictures));
    return response.pictures;
  } catch {}
};

const getInitialDalleImage = async (options) => {
  try {
    const Auth = new AuthService();
    const response = await Auth.fetch(`/dalle/getMinimalDalleImage?templateId=${options.templateId}`, {}, { endpoint: "llm-hub"});
    return response;
  } catch {}
};

const generateWordings = async (options) => {
  const Auth = new AuthService();
  try {
    const response = await Auth.fetch(`/account_management/generateNewsletterWordings?templateId=${options.templateId}`, {
      method: "POST",
      body: JSON.stringify({
        language: options.language
      })
    });
    const response2 = await Auth.fetch(`/account_management/generateNewsletterSubject?templateId=${options.templateId}`, { method: "POST" });
    return {
      ...response,
      ...response2,
    };
  } catch {}
};

const getPromoCode = async (options) => {
  const mjmlTemplate = options.account?.mjmlTemplates.find(template => template.id === options.templateId);
  if (!mjmlTemplate?.promoCodeId) {
    return null;
  }

  const Auth = new AuthService();
  return Auth.fetch(
    `/account_management/getPromoCode?promoCodeId=${mjmlTemplate.promoCodeId}`,
    {
      method: "GET"
    }
  );
};

const getOutpaintedImage = async (options) => {
  const Auth = new AuthService();
  try {
    return await Auth.fetch(`/outpainting/getInitialOutpaintedImages?templateId=${options.templateId}&shouldWaitResult=true`, {}, { endpoint: "llm-hub"});
  } catch(e) {}
};

const getMinimizedName = name => {
  let minimizedName = "";

  if (name.includes[" "]) {
    const splitted = name.split(" ");
    minimizedName = splitted[0][0] + " " + splitted[splitted.length - 1][0];
  } else {
    minimizedName = name[0] + " " + name[1];
  }

  return minimizedName.toUpperCase();
};

const getColorLuminosity = async (hexColor) => {
  const r = parseInt(hexColor.slice(1, 3), 16) / 255;
  const g = parseInt(hexColor.slice(3, 5), 16) / 255;
  const b = parseInt(hexColor.slice(5, 7), 16) / 255;

  const luminosity = 0.299 * r + 0.587 * g + 0.114 * b;
  const threshold = 0.5;
  return luminosity > threshold;
};

const getUrlShop = (account) => {
  let result = "";

  if (account.CMS === "prestashop" && account.shops.length) {
    const shop = account.shops[0];
    result = shop.protocol + "://" + shop.domain;
  } else {
    result = account.url_shop;
  }

  if (!result?.startsWith("http")) {
    result = `https://${result}`;
  }
  return result;
};

const loadData = async (options) => {
  const mjmlTemplate = options.account?.mjmlTemplates.find(template => template.id === options.templateId);

  const dataToLoad = {
    imageUrl: loadHeadBandImage,
    trustPilotReviews: loadTrustPilotReview,
    avisVerifiesReviews: loadAvisVerifiesReviews,
    products: getProducts,
    unsplashPhotos: loadUnsplashPhotos,
    initialDalleImage: getInitialDalleImage,
    generatedWordings: generateWordings,
    templateInfo: getTemplateInfo,
    promoCode: getPromoCode
  };

  if (mjmlTemplate && mjmlTemplate.objective === "monoProduct") {
    dataToLoad["outpaintedImage"] = getOutpaintedImage;
  }

  const result = await Promise.all(Object.entries(dataToLoad).map(([key, fn]) => new Promise(resolve => fn(options).then(data => resolve({ [key]: data })))));
  return Object.assign({}, ...result);
};

export const setUpBlocks = async (editor, options) => {
  const {
    imageUrl,
    trustPilotReviews,
    avisVerifiesReviews,
    products,
    unsplashPhotos,
    generatedWordings,
    initialDalleImage,
    templateInfo,
    promoCode,
    outpaintedImage
  } = await loadData(options);

  const imagePreview = "https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/imagePreview.png";

  if (trustPilotReviews?.reviews?.length) {
    trustPilotReviews.reviews = trustPilotReviews?.reviews.sort((a, b) => b.rating - a.rating).map(review => ({
      ...review,
      minimizedName: getMinimizedName(review.customerNickname),
    }));
  }

  if (avisVerifiesReviews?.reviews?.length) {
    avisVerifiesReviews.reviews = avisVerifiesReviews?.reviews.sort((a, b) => b.rating - a.rating).map(review => ({
      ...review,
      minimizedName: getMinimizedName(review.customerNickname),
    }));
  }

  const shopUrl = getUrlShop(options.account);
  const product = products?.length && products[0];

  const templateParameters = options.account.template_parameters;
  const tone = templateParameters.find(({ name }) => name === "tone")?.value || "sobre";
  const fontFamily = templateParameters.find(({ name }) => name === "fontFamily")?.value || "verdana";
  const buttonTextColor = templateParameters.find(({ name }) => name === "buttonTextColor")?.value || "#FFFFFF";
  const secondaryColor = templateParameters.find(({ name }) => name === "secondaryColor")?.value || "#FFFFFF";
  const accentColor = templateParameters.find(({ name }) => name === "accentColor")?.value || "#323232";
  const textColor = templateParameters.find(({ name }) => name === "textColor")?.value || "#323232";
  const headerColor = templateParameters.find(({ name }) => name === "headerColor")?.value || "#B1D1D9";
  const footerColor = templateParameters.find(({ name }) => name === "footerColor")?.value || "#B1D1D9";
  const dominantColor = templateParameters.find(({ name }) => name === "dominantColor")?.value || "#FFFFFF";
  const buttonRadius = tone === "serieux" ? "0px" : "7px";
  const currency = options.account.currency || "€";
  const showPromoCodes = options.account.betaFeatures?.includes("nl-promo-code");
  const priceIsVisible = options.account.priceIsVisible != null ? options.account.priceIsVisible : true;
  const currencyBefore = options.account.email_html_options?.currencyBefore;


  const accountSocialNetwork = options.account.social_medias;
  const user = await options.auth.getUser();
  const senderEmail = user.attributes.email;
  const replyEmail = options.account.replyToEmail ? options.account.replyToEmail : senderEmail;
  const isLight = await getColorLuminosity(footerColor);
  const isDominantColorLight = await getColorLuminosity(dominantColor);
  const isTextColorLight = await getColorLuminosity(textColor);

  let blockRsInstagram = "";
  let blockRsFacebook = "";
  let blockRsTwitter = "";
  let blockRsYoutube = "";
  let blockRsDiscord = "";
  let blockRsLinkedin = "";
  let blockRsPinterest = "";
  let blockRsTiktok = "";
  const socialsNetworkTab = [];

  if(accountSocialNetwork && accountSocialNetwork.instagram && accountSocialNetwork.instagram.length > 1){
    blockRsInstagram = `<mj-social-element padding-right="3px" href="${accountSocialNetwork.instagram}" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/square-instagram-2-${isLight ? "black" : "white"}.png"></mj-social-element>`;
    socialsNetworkTab.push(blockRsInstagram);
  }
  if(accountSocialNetwork && accountSocialNetwork.facebook && accountSocialNetwork.facebook.length > 1){
    blockRsFacebook = `<mj-social-element padding-right="3px" href="${accountSocialNetwork.facebook}" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/square-facebook-2-${isLight ? "black" : "white"}.png"></mj-social-element>`;
    socialsNetworkTab.push(blockRsFacebook);
  }
  if(accountSocialNetwork && accountSocialNetwork.twitter && accountSocialNetwork.twitter.length > 1){
    blockRsTwitter = `<mj-social-element padding-right="3px" href="${accountSocialNetwork.twitter}" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/square-x-twitter-2-${isLight ? "black" : "white"}.png"></mj-social-element>`;
    socialsNetworkTab.push(blockRsTwitter);
  }
  if(accountSocialNetwork && accountSocialNetwork.youtube && accountSocialNetwork.youtube.length > 1){
    blockRsYoutube = `<mj-social-element padding-right="3px" href="${accountSocialNetwork.youtube}" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/square-youtube-2-${isLight ? "black" : "white"}.png"></mj-social-element>`;
    socialsNetworkTab.push(blockRsYoutube);
  }
  if(accountSocialNetwork && accountSocialNetwork.discord && accountSocialNetwork.discord.length > 1){
    blockRsDiscord = `<mj-social-element padding-right="3px" href="${accountSocialNetwork.discord}" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/square-discord-2-${isLight ? "black" : "white"}.png"></mj-social-element>`;
    socialsNetworkTab.push(blockRsDiscord);
  }
  if(accountSocialNetwork && accountSocialNetwork.linkedin && accountSocialNetwork.linkedin.length > 1){
    blockRsLinkedin = `<mj-social-element padding-right="3px" href="${accountSocialNetwork.linkedin}" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/square-linkedin-2-${isLight ? "black" : "white"}.png"></mj-social-element>`;
    socialsNetworkTab.push(blockRsLinkedin);
  }
  if(accountSocialNetwork && accountSocialNetwork.pinterest && accountSocialNetwork.pinterest.length > 1){
    blockRsPinterest = `<mj-social-element padding-right="3px" href="${accountSocialNetwork.pinterest}" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/square-pinterest-2-${isLight ? "black" : "white"}.png"></mj-social-element>`;
    socialsNetworkTab.push(blockRsPinterest);
  }
  if(accountSocialNetwork && accountSocialNetwork.tiktok && accountSocialNetwork.tiktok.length > 1){
    blockRsTiktok = `<mj-social-element padding-right="3px" href="${accountSocialNetwork.tiktok}" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/square-tiktok-2-${isLight ? "black" : "white"}.png"></mj-social-element>`;
    socialsNetworkTab.push(blockRsTiktok);
  }

  let blocks = [
    footer1(options.account.url_logo, blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, isLight, { fontFamily, footerColor, senderEmail }),
    footer2(options.account.url_logo, blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, isLight, { fontFamily, footerColor }),
    footer3(options.account.url_logo, shopUrl, isLight, { fontFamily, footerColor }),
    footer4(options.account.url_logo, blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, isLight, replyEmail, { fontFamily, footerColor }, socialsNetworkTab),
    footer5(options.account.url_logo, blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, replyEmail, isLight, { fontFamily, footerColor }, socialsNetworkTab),    
    whiteSeparator({ dominantColor }),
    lineSeparator({ dominantColor, isDominantColorLight }),
    social1(blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, footerColor),
    social2(blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, footerColor),
    social3(blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, footerColor),
    social4(blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, footerColor, isLight, fontFamily),
    body1({ fontFamily, textColor, accentColor, generatedWordings, dominantColor, buttonTextColor, product, trustPilotReviews, avisVerifiesReviews, isDominantColorLight, currency, priceIsVisible, currencyBefore }),
    body2({ fontFamily, textColor, accentColor, generatedWordings, dominantColor, buttonTextColor, product, trustPilotReviews, avisVerifiesReviews, currency, priceIsVisible, currencyBefore }),
    body3({ fontFamily, textColor, accentColor, generatedWordings, dominantColor, buttonTextColor, product, currency, priceIsVisible, currencyBefore }),   
    ...getPromoCodeBlocks({ promoCode, dominantColor, fontFamily, textColor, isTextColorLight }), 
    ...getImageBlocks({ imageUrl: imagePreview, shopUrl, textColor, fontFamily, generatedWordings, headerColor }),
    ...getLogoBlocks({ logoUrl: options.account.url_logo, shopUrl, headerColor, fontFamily, textColor }),
    ...getButtonBlocks({ fontFamily, buttonTextColor, accentColor, urlShop: shopUrl, dominantColor }),
    ...getProductBlocks({ products, fontFamily, buttonTextColor, accentColor, textColor, dominantColor, templateInfo, buttonRadius, currency, secondaryColor, priceIsVisible, currencyBefore }),
    ...getTextBlocks({ fontFamily, textColor, dominantColor, buttonTextColor, accentColor, unsplashPhotos }),
    ...getReviewBlocks({ trustPilotReviews, avisVerifiesReviews, fontFamily, textColor, buttonTextColor, accentColor, dominantColor }),
    ...getPreheaderBlocks({ fontFamily, textColor, dominantColor }),
    ...getColumnBlocks({ dominantColor, fontFamily, textColor, imageUrl: imagePreview, shopUrl, logoUrl: options.account.url_logo, accentColor, buttonTextColor, isDominantColorLight }),
  ];

  if (imageUrl) {
    blocks = blocks.concat([      
      header1({ logoUrl: options.account.url_logo, shopUrl, fontFamily, textColor, imageUrl, headerColor, dominantColor }),
      header2({ logoUrl: options.account.url_logo, shopUrl, fontFamily, textColor, imageUrl, buttonTextColor, accentColor, generatedWordings, headerColor }),
      header2({ logoUrl: options.account.url_logo, shopUrl, fontFamily, textColor, imageUrl, buttonTextColor: textColor, accentColor: dominantColor, generatedWordings, headerColor }),
      header3({ logoUrl: options.account.url_logo, shopUrl, fontFamily, textColor, imageUrl, buttonTextColor, accentColor, generatedWordings, headerColor }),
    ]);
  }

  //presets
  blocks = blocks.concat([
    productPreset({
      unsplashPhotos,
      imageUrl,
      shopUrl,
      fontFamily,
      textColor,
      generatedWordings,
      dominantColor,
      accentColor,
      products: products?.slice(0, 6),
      buttonTextColor,
      logoUrl: options.account.url_logo,
      headerColor, 
      initialDalleImage,
      isDominantColorLight,
      trustPilotReviews,
      avisVerifiesReviews,
      blockRsInstagram,
      blockRsFacebook,
      blockRsTwitter,
      blockRsYoutube,
      blockRsDiscord,
      blockRsLinkedin,
      blockRsPinterest,
      blockRsTiktok,
      isLight,
      footerColor,
      tone,
      buttonRadius,
      currency,
      secondaryColor,
      priceIsVisible,
      currencyBefore,
    }),
    monoProductPreset({
      unsplashPhotos,
      imageUrl,
      shopUrl,
      fontFamily,
      textColor,
      generatedWordings,
      dominantColor,
      accentColor,
      products: products?.slice(0, 6),
      buttonTextColor,
      logoUrl: options.account.url_logo,
      headerColor, 
      initialDalleImage,
      isDominantColorLight,
      trustPilotReviews,
      avisVerifiesReviews,
      blockRsInstagram,
      blockRsFacebook,
      blockRsTwitter,
      blockRsYoutube,
      blockRsDiscord,
      blockRsLinkedin,
      blockRsPinterest,
      blockRsTiktok,
      isLight,
      footerColor,
      tone,
      buttonRadius,
      currency,
      secondaryColor,
      priceIsVisible,
      currencyBefore,
      outpaintedImage
    }),
    promotionPreset({
      unsplashPhotos,
      imageUrl,
      shopUrl,
      fontFamily,
      textColor,
      generatedWordings,
      dominantColor,
      accentColor,
      products: products?.slice(0, 6),
      buttonTextColor,
      logoUrl: options.account.url_logo,
      headerColor, 
      initialDalleImage,
      isDominantColorLight,
      trustPilotReviews,
      avisVerifiesReviews,
      blockRsInstagram,
      blockRsFacebook,
      blockRsTwitter,
      blockRsYoutube,
      blockRsDiscord,
      blockRsLinkedin,
      blockRsPinterest,
      blockRsTiktok,
      isLight,
      footerColor,
      tone,
      buttonRadius,
      currency,
      priceIsVisible,
      currencyBefore,
    }),
  ]);

  //add styles
  for (const elem of blocks) {
    if (elem.style) {
      options.addStyle({
        id: elem.label,
        style: `
          <mj-style inline="inline" data-gjs="${elem.label}" >
            ${elem.style}
          </mj-style>          
        `
      });      
    }

    if (elem.mediaQueryStyle) {
      options.addStyle({
        id: `media-${elem.label}`,
        style: `
          <mj-style data-gjs="media-${elem.label}">
            ${elem.mediaQueryStyle}
          </mj-style>
        `
      });
    }
  }

  //Global styles
  options.addStyle({
    id: "global",
    style: `
      <mj-style inline="inline" data-gjs="global">
        body {
          margin: 0 auto;
          max-width: 800px;
        }

        .preview-wrapper > tbody > tr > td > div {
          max-width: 800px !important;
        }

      </mj-style> 
    `
  });

  const getMediaAndContent = ({ elem, block, language }) => {
    return {
      media: getPreviewFromContent(block.content, {style: elem.style, mediaQueryStyle: elem.mediaQueryStyle, wordings: block.attributes?.wordings, variables: block.attributes?.variables, language, previewStyle: elem.previewStyle, category: block.category }),
      content: block.content.includes("data-blocklabel=") ? block.content : block.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${block.label}"`)
    };
  };


  for (const [idx, elem] of blocks.entries()) {
    const block = typeof elem.block === "function" ? elem.block() : elem.block;
    editor.BlockManager.add(`${block.id}-${idx}`, {
      ...block,
      attributes: {
        ...block.attributes,
        style: elem.style,
      },
      ...getMediaAndContent({ elem, block, language: options.language }),
    });
  };

  editor.Commands.add("kiliba:blocks:updateContent", (editor, sender, commandOptions = {}) => {

    blocks.map((elem, idx) => ({ ...elem, idx })).filter(block => block.category?.startsWith(`${commandOptions.category}:`)).forEach(elem => {

      const currentBlock = editor.BlockManager.getAll().find(gjsBlock => gjsBlock.getId() === `${elem.label}-${elem.idx}`);

      const newBlock = elem.block(commandOptions.content);

      currentBlock.set({
        ...getMediaAndContent({ elem, block: newBlock, language: commandOptions.language }),
        activate: newBlock.activate,
        attributes: {
          ...currentBlock.attributes.attributes,
          ...newBlock.attributes,
        }
      });
    });
  });

  editor.Commands.add("kiliba:blocks:updateReviewBlocks", async ( editor, sender, commandOptions = {} ) => {
    const newReviewBlock = getReviewBlocks({ 
      trustPilotReviews: commandOptions.trustpilotReviews, 
      avisVerifiesReviews: commandOptions.avisverifiesReviews, 
      fontFamily, 
      textColor, 
      buttonTextColor, 
      accentColor, 
      dominantColor 
    });

    blocks.map((elem, idx) => ({ ...elem, idx })).filter(block => block.category?.startsWith("review")).forEach(elem => {
      const currentBlock = editor.BlockManager.getAll().find(gjsBlock => gjsBlock.getId() === `${elem.label}-${elem.idx}`);
      const newBlock = newReviewBlock.find(block => block.label === elem.label).block();

      if (newBlock.activate) {
        currentBlock.set({
          ...getMediaAndContent({ elem, block: newBlock, language: commandOptions.language }),
          activate: newBlock.activate,
          attributes: {
            ...currentBlock.attributes.attributes,
            ...newBlock.attributes,
          }
        });
      }
    });
  });
};