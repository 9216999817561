import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import { Translation } from "../../../common/Translation";
import { useAuth } from "../../../../hooks/useAuth";
import { PageBody } from "../common/PageBody/PageBody";
import { PageBodyWrapper } from "../common/PageBodyWrapper/PageBodyWrapper";
import { CustomButton } from "../../../common/Button/CustomButton";

import { styles } from "./ConnectYourShop.styles";

import picture from "../../../../public/connectYourShop.png";

const useStyles = makeStyles(styles);

export function ConnectYourShop(props) {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const auth = useAuth();

  async function submit() {
    auth.sendEventToAmplitude("connect.click_connect_my_shop");

    props.onSubmit();
  }

  useEffect(() => {
    auth.sendEventToAmplitude("connect.see_page");
  }, [auth]);

  return (
    <PageBodyWrapper>
      <PageBody
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          minWidth: 900,
          width: "90vw",
          maxWidth: 985,
          padding: "90px 0 40px 0",
        }}
      >
        <p className="display2">
          {intl.messages["onBoarding.connectYourShop.title"]}
        </p>
        <div className={classes.body}>
          <div className={classes.text}>
            <p>
              <Translation id={"onBoarding.connectYourShop.text1"} />
            </p>
            <p className={classes.content}>
              {intl.messages["onBoarding.connectYourShop.text2"]}
            </p>
            <div className={classes.buttonAndCta}>
              <CustomButton onClick={submit}>
                {intl.messages["button.connectMyShop"]}
              </CustomButton>
              <a
                href="https://support.kiliba.com/hc/fr/articles/10677711225628-Pourquoi-connecter-le-module-Kiliba-sur-sa-boutique-"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  auth.sendEventToAmplitude("connect.click_why_install_kiliba");
                  return true; // Fire href
                }}
                className={`link ${classes.whyInstallKiliba}`}
              >
                {intl.messages["onBoarding.connectYourShop.cta1"]}
              </a>
            </div>
          </div>
          <img
            style={{
              width: 265,
            }}
            src={picture}
            alt="decoration"
          />
        </div>
        <a
          href
          onClick={() => {
            auth.sendEventToAmplitude("connect.click_i_just_want_to_see_the_app");
            history.push("/");
          }}
          className={"link"}
        >
          {intl.messages["onBoarding.connectYourShop.cta2"]}
        </a>
      </PageBody>
    </PageBodyWrapper >

  );
};
