import { useContext, useEffect, useState } from "react";

import { Dialog } from "../../../common/Dialog";
import { RadioGroup } from "../../../common/RadioGroup";
import { Translation } from "../../../common/Translation";
import { FormButtonTO } from "../../../FormButtonTO";
import { Radio } from "../../../common/Input/Radio/Radio";

import { useAuth } from "../../../../hooks/useAuth";
import { EventSystem } from "../../../../eventsystem/EventSystem.js";
import { AccountContext, reloadAccountContext } from "../../../../contexts/context";

import classes from "./UsecaseNewProductsConfigPopup.module.scss";

const newProductsConfigType = {
  BOTH_DATES: "both_dates",
  ACTIVATION_DATE: "activation_date",
  DATE_ADD: "date_add",
};

export const UsecaseNewProductsConfigPopup = ({ isOpen, close }) => {

  const auth = useAuth();
  const { account } = useContext(AccountContext);
  const [value, setValue] = useState(account?.mw_custom_options?.new_product_selection || newProductsConfigType.BOTH_DATES);

  const onSubmit = () => {
    auth.fetch("/api/account_management/updateMwOptions", {
      method: "PUT",
      body: JSON.stringify({ 
        new_product_selection: value,
      }),
    }).then(() => {
      reloadAccountContext();
      EventSystem.newNotification("notification.action", <Translation id="usecase.configPopup.save.confirm" />);
      close();
    });
  };

  useEffect(() => {
    setValue(account?.mw_custom_options?.new_product_selection || newProductsConfigType.BOTH_DATES);
  }, [isOpen, account]);

  return (
    <Dialog 
      PaperProps={{ style: { borderRadius: 20 } }}
      isOpen={isOpen}
      close={close}
      padding="64px 64px 40px 64px"
    >
      <div className={classes.container}>
        <h2 className={classes.title}><Translation id="usecaseNewProductsConfigPopup.title" /></h2>
        <span className={classes.subtitle}><Translation id="usecaseNewProductsConfigPopup.subtitle" /></span>
        <div className={classes.radioContainer}>
          <RadioGroup 
            value={value} 
            onChange={event => setValue(event.target.value)}
          >
            <Radio value={newProductsConfigType.BOTH_DATES} label={<Translation id="usecaseNewProductsConfigPopup.option.both_dates" />} />
            <Radio value={newProductsConfigType.ACTIVATION_DATE} label={<Translation id="usecaseNewProductsConfigPopup.option.activation_date" />} />
            <Radio value={newProductsConfigType.DATE_ADD} label={<Translation id="usecaseNewProductsConfigPopup.option.date_add" />} />
          </RadioGroup>
        </div>
        <div className={classes.buttonsContainer}>
          <FormButtonTO 
            label={<Translation id="usecaseConfigPopup.form.button.save" />}
            action={onSubmit}
          />
          <small
            className={classes.buttonReset}
            onClick={() => setValue(newProductsConfigType.BOTH_DATES)}
          >
            <Translation id="usecaseConfigPopup.form.button.reset" />
          </small>
        </div>
      </div>
    </Dialog>
  );
};