import { palette } from "../../../../styles/palette";

export const styles = {
  body: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "50px 0 70px 0",
    gap: 100
  },
  text: {
    width: 395,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 30
  },
  content: {
    color: palette.neutralDarkGrey,
    fontSize: 14,
  },
  whyInstallKiliba: {
    color: palette.neutralDarkGrey,
  },
  buttonAndCta: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 18
  }
};
