import { palette } from "../../../../../styles/palette";

const size = 35;

export const styles = {
  stepKey: {
    width: size,
    height: size,
    borderRadius: size,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: palette.primaryYellow200,
    color: palette.black,
  },
  done: {
    backgroundColor: palette.primaryYellow200,
    color: palette.white,
  },
  inactive: {
    backgroundColor: palette.neutralLightGrey,
    color: palette.neutralDarkGrey,
  },
};
