import { useState, useEffect, useContext } from "react";

import { Translation } from "../../../common/Translation";
import { useAuth } from "../../../../hooks/useAuth";
import { TemplateEditorContext } from "../../TemplateEditorContext";

import image1 from "../../../../public/iaImageMenuImage1.png";
import image2 from "../../../../public/iaImageMenuImage2.png";
import image3 from "../../../../public/iaImageMenuImage3.png";

import defaultDalleImage from "../../../../public/iaImageMenuDefaultDalle.png";
import defaultOutpaintingImage from "../../../../public/iaImageMenuDefaultOutpainting.png";
import defaultProductShowcaseImage from "../../../../public/iaImageMenuDefaultProductShowcase.png";

import classes from "./IAImageMenu.module.scss";

export const IAImageMenu = ({ openGeneratedImageLibrary }) => {

  const auth = useAuth();
  const [dalleImages, setDalleImages] = useState([]);
  const [outpaintingImages, setOutpaintingImages] = useState([]);
  const [productShowcaseImages, setProductShowcaseImages] = useState([]);
  const [unsplashPhotos, setUnsplashPhotos] = useState([]);

  const { templateId } = useContext(TemplateEditorContext);

  useEffect(() => {
    auth.fetch(`/account_management/getOutpaintedImages?templateId=${templateId}`).then(response => {
      setOutpaintingImages(response.filter(({ type }) => type === "outpainting").map(({ url }) => url));
      setProductShowcaseImages(response.filter(({ type }) => type === "uniform_background").map(({ url }) => url));
    });
  }, [auth, templateId]);

  useEffect(() => {
    auth.fetch(`/account_management/getUnsplashPhotos?templateId=${templateId}`)
      .then((response) => {
        sessionStorage.setItem("unsplashPhotos", JSON.stringify(response.pictures));
        setUnsplashPhotos(response.pictures);
      });
  }, [auth]);

  useEffect(() => {
    auth.fetch(`/dalle/dalleAssets?templateId=${templateId}`, {}, {endpoint: "llm-hub"}).then(response => {
      if (response.length) {
        setDalleImages(response);
      }
    }).catch(() => {});
  }, [templateId, auth]);

  return (
    <div className={classes.container}>
      <div className={`display3 ${classes.title}`}><Translation id="iaImageMenu.title" /></div>
      <img alt="" src={image1} width={138} />

      
      <div className={classes.ambientImagesCard} onClick={() => openGeneratedImageLibrary("ambientImages")}>
        <h2 className={classes.cardTitle}><Translation id="iaImageMenu.ambientImages" /></h2>
        <div className={`text ${classes.cardSubtitle}`}><Translation id="iaImageMenu.ambientImages.subtitle" /></div>
        <div className={classes.ambientImagesCardContent}>

          <div className={`${classes.imageContainer} ${classes.ambientImagesFirstImage}`} style={{ backgroundImage: `url(${dalleImages[0] || defaultDalleImage})` }} />

          <div className={classes.ambientImagesContentRight}>

            <div className={classes.ambientImagesNextImages}>
              <div className={classes.imageContainer} style={{ backgroundImage: `url(${dalleImages[1]})` }} />
              <div className={classes.imageContainer} style={{ backgroundImage: `url(${dalleImages[2]})` }} />
            </div>

            <div className={`${classes.ambientImagesCta}`}>
              <div>
                <Translation id="iaImageMenu.ambientImages.cta" />
              </div>
            </div>

          </div>

        </div>
      </div>        

      <div className={classes.bottomPartContainer}>
        
        <div className={classes.bottomLeftPartContainer}>
          <div className={classes.productShowcaseCard} onClick={() => openGeneratedImageLibrary("productShowcaseImages")}>
            <h2><Translation id="iaImageMenu.productShowcase.title" /></h2>
            <div className={`text ${classes.productShowcaseCardSubtitle}`}><Translation id="iaImageMenu.productShowcase.subtitle" /></div>
            <div className={classes.productShowcaseCardImage} style={{ backgroundImage: `url(${productShowcaseImages[0] || defaultProductShowcaseImage})` }}>
              <img alt="" src={image2} className={classes.image2} />
            </div>
          </div>

          <img alt="" src={image3} className={classes.image3}/>
        </div>

        <div className={classes.bottomRightPartContainer}>
          
          <div className={classes.outpaintingCard} onClick={() => openGeneratedImageLibrary("outpaintingImages")}>
            <div className={classes.outpaintingCardImageContainer}>
              <div className={classes.outpaintingCardImage} style={{ backgroundImage: `url(${outpaintingImages[0] || defaultOutpaintingImage})` }} />
            </div>
            <div className={classes.outpaintingCardTexts}>
              <h2><Translation id="iaImageMenu.outpainting.title" /></h2>
              <div className={`text ${classes.outpaintingCardSubtitle}`}><Translation id="iaImageMenu.outpainting.subtitle" /></div>
            </div>
          </div>

          <div className={classes.unsplashCard} onClick={() => openGeneratedImageLibrary("unsplashImages")}>
            <h2><Translation id="optionalMenu.category.unsplash" /></h2>
            <div className={`text ${classes.outpaintingCardSubtitle}`}><Translation id="iaImageMenu.unsplash.subtitle" /></div>
            <div className={classes.unsplashCardImages}>
              <div className={classes.unsplashCardImage1} style={{ backgroundImage: `url(${unsplashPhotos[0]?.url})` }} />
              <div className={classes.unsplashCardImage2} style={{ backgroundImage: `url(${unsplashPhotos[1]?.url})` }} />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};