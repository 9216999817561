import React, { Component } from "react";

import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

import { DayPicker } from "../../../../../../pickers/DayPicker.js";
import { Radio } from "../../../../../../common/Input/Radio/Radio.js";

import { styles } from "./styles.js";

export const FixedInput = withStyles(styles)(
  injectIntl(
    class FixedInput extends Component {
      render() {
        const {
          classes,
          intl,
          promoCodeTemplateWrapper,
          setPromoCodeTemplateWrapperProp,
        } = this.props;

        return (
          <Radio
            legacy
            value="fixed"
            label={
              <span
                className={`${classes.temporalityLabel} ${classes.temporalityLabelWithDatePicker}`}
              >
                <span>
                  {
                    intl.messages[
                      "usecase.promoCodeTemplateEditor.temporality.fixed.0"
                    ]
                  }
                </span>
                {
                  <DayPicker
                    active={
                      promoCodeTemplateWrapper.temporalityChoices.fixed.dateFrom
                    }
                    onDayClick={(value) =>
                      setPromoCodeTemplateWrapperProp(
                        "temporalityChoices.fixed.dateFrom",
                        value.toISOString()
                      )
                    }
                    idStartDate="promoCodeTemplateEditorFixed"
                  />
                }
                <span>
                  {
                    intl.messages[
                      "usecase.promoCodeTemplateEditor.temporality.fixed.1"
                    ]
                  }
                </span>
                {
                  <DayPicker
                    active={
                      promoCodeTemplateWrapper.temporalityChoices.fixed.dateTo
                    }
                    onDayClick={(value) =>
                      setPromoCodeTemplateWrapperProp(
                        "temporalityChoices.fixed.dateTo",
                        value.toISOString()
                      )
                    }
                    idStartDate="promoCodeTemplateEditorFixed"
                    endDate
                  />
                }
              </span>
            }
          />
        );
      }
    }
  )
);
