import React, { Component } from "react";

import { injectIntl } from "react-intl";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export const TypeInput = injectIntl(
  class TypeInput extends Component {
    onChange(value) {
      const { promoCodeTemplateWrapper, setPromoCodeTemplateWrapperProp } =
        this.props;

      setPromoCodeTemplateWrapperProp("promoCodeTemplate.type", value);
      if (
        value === "%" &&
        promoCodeTemplateWrapper.promoCodeTemplate.value > 100
      )
        setPromoCodeTemplateWrapperProp("promoCodeTemplate.value", 100);
      if (value === "%") {
        setPromoCodeTemplateWrapperProp(
          "promoCodeTemplate.includesFreeShipping",
          false
        );
        setPromoCodeTemplateWrapperProp(
          "promoCodeTemplate.includesTaxes",
          null
        );
      } else if (
        promoCodeTemplateWrapper.promoCodeTemplate.includesTaxes === null
      ) {
        setPromoCodeTemplateWrapperProp(
          "promoCodeTemplate.includesTaxes",
          true
        );
      }
    }

    render() {
      const { types, promoCodeTemplateWrapper } = this.props;

      return (
        <Select
          value={promoCodeTemplateWrapper.promoCodeTemplate.type}
          onChange={(event) => this.onChange(event.target.value)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          variant="outlined"
        >
          {types.map((type, key) => (
            <MenuItem value={type} key={key}>
              {type}
            </MenuItem>
          ))}
        </Select>
      );
    }
  }
);
