
import classes from "./ImageAlignInput.module.scss";

export const ImageAlignInput = ({ value, onChange }) => {

  return (
    <div>
      <div className={classes.imageAlignInput}>
        <div onClick={() => onChange("left")} className={value === "left" ? classes.alignSelected : ""}>
          <i className="fa-solid fa-objects-align-left" />
        </div>
        <div onClick={() => onChange("center")} className={value === "center" ? classes.alignSelected : ""}>
          <i className="fa-solid fa-objects-align-center-horizontal" />
        </div>
        <div onClick={() => onChange("right")} className={value === "right" ? classes.alignSelected : ""}>
          <i className="fa-solid fa-objects-align-right" />
        </div>
      </div>
    </div>
  );
};