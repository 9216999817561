import unsplashLogo from "./../../../../public/unsplash.png";

export const categories = [
  // main
  {
    name: "all",
  },
  {
    name: "myImages",
    type: "images"
  },
  {
    name: "openAI",
    type: "images"
  },
  {
    name: "unsplash",
    type: "images"
  },
  {
    name: "logos",
    type: "logos"
  },
  {
    name: "products",
    type: "products"
  },
  // secondary
  {
    parent: "myImages",
    name: "myImages",
    type: "images"
  },
  {
    parent: "openAI",
    name: "openAI",
    logo: "",
    icon: "fa-regular fa-sparkles",
    beta: true,
    type: "images"
  },
  { 
    parent: "unsplash",
    name: "unsplash",
    logo: unsplashLogo,
    beta: true,
    type: "images",
  },
  {
    parent: "logos",
    name: "logos",
    type: "logos",
    showName: false,
  },
  {
    parent: "products",
    name: "products",
    type: "products",
    showName: false,
  },
];