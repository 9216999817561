import { useEffect, useCallback, useState, useContext } from "react";
import { isEqual, cloneDeep } from "lodash";

import { removeUselessWordings, getUpdatedWordings } from "./TranslationManager";
import { TemplateEditorContext } from "./TemplateEditorContext";
import { AuthService } from "../AuthService";

class SaveQueue {
  constructor() {
    this.queue = [];
    this.running = false;
    this.callback = () => {};
  }

  async runNext() {
    this.running = true;
    const func = this.queue.shift();
    try {
      await func();
    } catch {}
    if (this.queue.length) {
      this.runNext();
    } else {
      this.running = false;
      this.callback();
    }
  };

  push(func) {
    this.queue.push(func);
    if (!this.running) {
      this.runNext();
    }
  }

  setCallback(cb) {
    this.callback = cb;
  }
}

const saveQueue = new SaveQueue();

export const SaveManager = ({ editor, wordings, setSaving, templateId }) => {

  const [lastSavedWordings, setLastSavedWordings] = useState(null);
  const [lastSavedTemplate, setLastSavedTemplate] = useState(null);
  const [lastSavedVariables, setLastSavedVariables] = useState(null);
  const [lastIsDragging, setLastIsDragging] = useState(false);
  const [lastWordings, setLastWordings] = useState(null);
  const [lastVariables, setLastVariables] = useState(null);

  const { language, variables, setProductsInTemplate, isDragging } = useContext(TemplateEditorContext);

  const onUpdate = useCallback(() => {

    setLastIsDragging(isDragging);

    if (!editor.getComponents().length || isDragging) {
      return ;
    }

    let finalWordings = getUpdatedWordings(editor, cloneDeep(wordings), language); 
    const template = editor.getHtml();
    finalWordings = removeUselessWordings(template, finalWordings);
    
    const finalVariables = removeUselessWordings(template, variables);

    if (!lastSavedTemplate && !lastSavedWordings && !lastSavedVariables) {
      setLastSavedTemplate(template);
      setLastSavedWordings(cloneDeep(finalWordings));
      setLastSavedVariables(cloneDeep(finalVariables));
    }

    if (lastSavedTemplate === template && isEqual(lastSavedWordings, finalWordings) && isEqual(lastSavedVariables, finalVariables)) {
      return;
    }

    setLastSavedTemplate(template);
    setLastSavedWordings(cloneDeep(finalWordings));
    setLastSavedVariables(cloneDeep(finalVariables));

    setSaving(true);

    const regex = /<mj-[^>]*data-id-product="([^"]*)[^>]*>([^<]*)/g;
    let m;
    const productIds = [];
    do {
      m = regex.exec(template);
      if (m) {
        productIds.push(m[1]);
      }
    } while (m);

    setProductsInTemplate([...new Set(productIds)]);

    saveQueue.push(async () => {
      const auth = new AuthService();
      await auth.fetch("/api/account_management/saveMjmlTemplate", {
        method: "POST",
        body: JSON.stringify({
          template,
          templateId: templateId,
          wordings: finalWordings,
          variables: finalVariables,
          isFirstGenerationDone: true,
        })
      });
    });

  }, [editor, wordings, language, lastSavedTemplate, lastSavedWordings, templateId, lastSavedVariables, variables, setSaving, setProductsInTemplate, isDragging]);

  useEffect(() => {
    saveQueue.setCallback(() => setSaving(false));
  }, [setSaving]);

  useEffect(() => {
    if (editor) {
      //trigger save when wordings changed
      if (!isEqual(wordings, lastWordings)) {
        onUpdate();
        setLastWordings(wordings);
      }

      if (!isEqual(variables, lastVariables)) {
        onUpdate();
        setLastVariables(variables);
      }

      if (!isDragging && lastIsDragging) {
        onUpdate();
        setLastIsDragging(false) ;
      }

      editor.on("update", onUpdate);
      return () => editor.off("update", onUpdate);
    }
  }, [editor, onUpdate, wordings, lastWordings, variables, lastVariables, lastIsDragging, isDragging]);

  return null;
};