import React, { Component } from "react";

import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

import { Checkbox } from "../../../../../../common/Input/Checkbox.js";
import { TextField } from "../../../../../../common/Input/TextField.js";

import { styles } from "./styles.js";

export const QuantityInput = withStyles(styles)(
  injectIntl(
    class QuantityInput extends Component {
      getQuantityLabel() {
        const {
          classes,
          intl,
          promoCodeTemplateWrapper,
          setPromoCodeTemplateWrapperProp,
          getError,
        } = this.props;

        return (
          <span className={classes.inputGroupContainer}>
            <span>
              {
                intl.messages[
                  "usecase.promoCodeTemplateEditor.options.quantity.0"
                ]
              }
            </span>
            <TextField
              value={promoCodeTemplateWrapper.quantity}
              errorMessage={
                getError("quantity") && getError("quantity").message
              }
              disabled={
                promoCodeTemplateWrapper.promoCodeTemplate.isGeneric ===
                  false || !promoCodeTemplateWrapper.mustUseQuantity
              }
              placeholder={
                intl.messages[
                  "usecase.promoCodeTemplateEditor.options.quantity.placeholder"
                ]
              }
              onChange={(event) => {
                let value =
                  event.target.value === "" ? null : Number(event.target.value);
                if (value < 0) value = 0;
                if (
                  promoCodeTemplateWrapper.promoCodeTemplate.isGeneric !== false
                ) {
                  setPromoCodeTemplateWrapperProp("quantity", value);
                  setPromoCodeTemplateWrapperProp("mustUseQuantity", value > 0);
                }
              }}
              type="number"
              min={0}
              style={{ width: 192 }}
              marginIsEnabled={false}
            />
          </span>
        );
      }

      render() {
        const {
          intl,
          disabled,
          promoCodeTemplateWrapper,
          setPromoCodeTemplateWrapperProp,
        } = this.props;

        return (
          <Checkbox
            checked={promoCodeTemplateWrapper.mustUseQuantity}
            disabled={disabled}
            disabledExplanation={
              intl.messages[
                "usecase.promoCodeTemplateEditor.options.quantity.disabled.explanation"
              ]
            }
            onChange={(event) =>
              setPromoCodeTemplateWrapperProp(
                "mustUseQuantity",
                event.target.checked
              )
            }
            label={this.getQuantityLabel()}
          />
        );
      }
    }
  )
);
