import { palette } from "../../../../../styles/palette.js";

export const htmlCustomizerTooltipStyleValues = {
  tooltipArrowHeight: 8,
  inputVerticalPadding: 20,
  inputVerticalPaddingImage: 12,
  inputHorizontalPadding: 24,
  tooltipBackgroundColor: palette.black,
  tooltipPadding: 4,
};
