import React, { Component } from "react";
import { withAuth } from "../../../withAuth";
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

import { styles } from "./Step.styles.js";
import { StepKey } from "./StepKey/StepKey";

class Step extends Component {
  render() {
    const { classes, title, stepKey, status, isLast } = this.props;

    const statusClass = classes[status];

    return (
      <div className={`${classes.step} ${statusClass}`}>
        <StepKey value={stepKey} status={status} />
        <p className={`text ${classes.title}`} data-content={title}>
          {title}
        </p>
        {isLast ? null : <div className={classes.separator}></div>}
      </div>
    );
  }
}

const _Step = withAuth(withStyles(styles)(injectIntl(Step)));
export { _Step as Step };
