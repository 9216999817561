import { useState } from "react";
import { makeStyles } from "@material-ui/core";

import { Translation } from "../Translation";
import { PageSelectorArrow, PageSelectorKeys } from "./KiPagination";
import { palette } from "../../../styles/palette";


const styles = () => ({
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 15,
    fontFamily: "Poppins",
    fontSize: 12,
  },
  dropdownLabel: {
    marginRight: 10,
  },
  dropdown: {
    position: "relative",
    display: "flex",
    gridGap: 3,
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  dropdownIcon: {
    fontSize: 8,
  },
  dropdownContent: {
    background: palette.primaryWhite,
    position: "absolute",
    left: -5,
    bottom: 22,
    border: `1px solid ${palette.neutralLightGrey}`,
    borderRadius: 10,
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  dropdownItem: {
    padding: 8,
    textAlign: "center",
    color: palette.neutralDarkGrey,
  },
  dropdownItemSteparator: {
    height: 1,
    background: palette.neutralLightGrey,
    margin: "0 4px"
  },
  selected: {
    color: palette.primaryBlack,
  },
  info: {
    marginLeft: 20,
  },
  pageSelectors: {
    marginLeft: 10,
    display: "grid",
    gridGap: 7,
    gridAutoFlow: "column",
  },
});

const useStyles = makeStyles(styles);

const DROPDOWN_VALUES = [10, 20, 50, 100];

export const KiPaginationItem = ({ rowsPerPage, onRowsPerPageChange, pageKey, count, onPageChange }) => {
  
  const classes = useStyles();
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const maxPageKey = count ? (Math.ceil(count / rowsPerPage) - 1) : undefined;

  return (
    <div className={`infoMedium ${classes.pagination}`}>
      <div className={classes.dropdownLabel}>
        <Translation id="kiPaginationItem.resultPerPage" />        
      </div>

      <div className={classes.dropdown} onClick={() => setDropdownOpened(value => !value)}>
        <span>{rowsPerPage}</span>
        <i class={`${classes.dropdownIcon} fa-solid fa-chevron-${dropdownOpened ? "up" : "down"}`} />
      
        {dropdownOpened && 
          <div className={classes.dropdownContent}>
            {DROPDOWN_VALUES.map((value, idx) => (
              <>
                {idx !== 0 && <div className={classes.dropdownItemSteparator} />}
                <div className={`${classes.dropdownItem} ${value === rowsPerPage ? classes.selected : ""}`} onClick={() => onRowsPerPageChange(value)}>
                  {value}
                </div>
              </>
            ))}
          </div>
        }
      </div>

      <div className={classes.info}>
        <span>{1 + pageKey * rowsPerPage} - {rowsPerPage + pageKey * rowsPerPage} </span>
        <Translation id="kiPaginationItem.of" />
        <span> {count?.toLocaleString("fr-FR")}</span>
      </div>

      <div className={classes.pageSelectors}>
        <PageSelectorArrow
          direction={"left"}
          isDisabled={pageKey === 0}
          pageKey={pageKey}
          onPageChange={onPageChange}
        />
        <PageSelectorKeys
          pageKey={pageKey}
          maxPageKey={maxPageKey}
          onPageChange={onPageChange}
        />
        <PageSelectorArrow
          direction={"right"}
          isDisabled={pageKey === maxPageKey || maxPageKey === undefined}
          pageKey={pageKey}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};