import { withStyles } from "@material-ui/core/styles";
import { default as MaterialSwitch } from "@material-ui/core/Switch";

import { palette } from "../../../styles/palette";

export const Switch = withStyles(() => ({
  root: {
    width: 35,
    height: 20,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    paddingTop: 2.5,
    padding: 2,
    color: palette.primaryWhite,
    "&$checked": {
      transform: "translateX(15px)",
      color: palette.primaryWhite,
      "& + $track": {
        opacity: 1,
        backgroundColor: palette.primaryYellow200,
      },
    },
  },
  thumb: {
    width: 15,
    height: 15,
    boxShadow: "none",
  },
  track: {
    borderRadius: 10,
    opacity: 1,
    backgroundColor: "#DDDAD5",
  },
  checked: {},
}))(MaterialSwitch);