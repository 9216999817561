import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

import { CmsDialog } from "../../common/CmsDialog/CmsDialog";
import { TextField } from "../../../../common/Input/TextField";
import { CustomButton } from "../../../../common/Button/CustomButton";
import { useAuth } from "../../../../../hooks/useAuth";
import { EventSystem } from "../../../../../eventsystem/EventSystem";

import { styles } from "./OtherCms.styles";
import picture from "../../../../../public/otherCms.png";
import { usePrevious } from "../../../../../hooks/usePrevious";
import { reloadAccountContext } from "../../../../../contexts/context";

const useStyles = makeStyles(styles);

export const OtherCmsDialog = ({
  open,
  handleClose,
}) => {
  const [cms, setCms] = useState("");

  const classes = useStyles();
  const intl = useIntl();
  const auth = useAuth();

  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (!prevOpen && open) {
      auth.sendEventToAmplitude("oops.see_modale");
    } else if (prevOpen && !open) {
      auth.sendEventToAmplitude("oops.leave_modale");
    }
  }, [auth, prevOpen, open]);

  const submit = async () => {
    if (cms) {
      auth.sendEventToAmplitude("oops.which_cms_is_missing");

      await auth.fetch("/api/account_management/otherCms", {
        method: "POST",
        body: JSON.stringify({
          CMS: cms,
        }),
      });
      await reloadAccountContext();
      EventSystem.newNotification("notification.action", intl.messages["onBoarding.chooseCms.otherCms.form.success"]);
    }
  };

  return (
    <CmsDialog
      open={open}
      handleClose={handleClose}
    >
      <div className={classes.otherCmsDialog}>

        <div className={classes.title}>
          <p className={"display2"}>{intl.messages["onBoarding.chooseCms.otherCms.title"]}</p>
        </div>
        <h2 className={classes.subtitle}>
          {intl.messages["onBoarding.chooseCms.otherCms.subtitle"]}
        </h2>

        <div className={classes.content}>

          <div className={classes.formContainer}>
            <div className={classes.explanation}>
              <p className={"textBold"}>{intl.messages["onBoarding.chooseCms.otherCms.form.title"]}</p>
              <br />
              <p className={"text"}>{intl.messages["onBoarding.chooseCms.otherCms.form.subtitle"]}</p>
            </div>

            <TextField
              dataCy="cms"
              placeholder={intl.messages["onBoarding.chooseCms.otherCms.form.field"]}
              value={cms}
              onChange={event => setCms(event.target.value)}
              autoFocus
            />

            <div className={classes.buttonContainer}>
              <CustomButton
                onClick={submit}
              >
                {intl.messages["onBoarding.chooseCms.otherCms.form.button"]}
              </CustomButton>
            </div>
          </div>

          <div className={classes.imageContainer}>
            <img className={classes.image} alt="" src={picture} />
          </div>
        </div>

      </div>
    </CmsDialog>
  );
};
