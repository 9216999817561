import { useState, useMemo, useEffect, useCallback, useContext } from "react";
import { Translation } from "../../common/Translation";
import { RightMenuForm } from "./RightMenuForm";
import { RightMenuEditContent } from "./RightMenuEditContent";
import { TemplateEditorContext } from "../TemplateEditorContext";
import { extractContentFromComponent } from "../grapesJsKiliba/blocks/index";
import { getContentFromProduct } from "./ProductMenu";
import { RightMenuAddBlock } from "./RightMenuAddBlock";
import { useImageGaleryMenu } from "./ImageGaleryMenu/ImageGaleryMenu";
import { templateEditorUtils } from "../templateEditorUtils";
import { useCleanCropPreview } from "./CropMenu";
import { useCleanProductFocusPreviewFromRightMenu } from "./ImageGaleryMenu/ProductFocusGenerator";
import { extractContentFromColumnsComponent } from "../grapesJsKiliba/blocks/columns";

import classes from "./RightMenu.module.scss";

import { CATEGORIES } from "../blockCategoriesConfig";

const WIDTH_OPENED = 423;
const WIDTH_OPENED_CROP = 550;
const WIDTH_OPENED_IMAGE_GALERY = 575;
const WIDTH_CLOSED = 147;
const WIDTH_NAV = 60;
const FOOTER_HEIGHT = 75;

const CATEGORIES_TYPE = ["blocks", "components"];

const CATEGORIES_WITH_CONTENT_WHICH_START_WITH_FORM = [/* "text" */];
const CATEGORIES_WITH_MULTIPLE_CONTENT = [{ name: "promoCode", properties: ["promoCode"] }];
const CATEGORIES_WITHOUT_FORM = ["preheader", "column", "columnComponent"];

export const checkContent = (category, content) => {

  if (category === "text") {
    if (content?.disableGPT) {
      return true;
    }
    return Object.keys(content || {}).includes("title") && Object.keys(content || {}).includes("subtitle") && Object.keys(content || {}).includes("text");
  }
  
  return !!Object.keys(content || {}).length;
};

export const RightMenu = ({ isOpened, setIsOpened, blocks, editor, canvasContainerRef, wordings, language, templateId, generatedWordings }) => {

  const [lastIsOpened, setLastIsOpened] = useState(isOpened);
  const [navigationItemSelected, setNavigationItemSelected] = useState("content");
  const [content, setContent] = useState({});
  const [formBlockId, setFormBlockId] = useState(null);
  const [defaultBlockId, setDefaultBlockId] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [mustUpdateComponent, setMustUpdateComponent] = useState(0);
  const [isPromoCodeOpened, setIsPromoCodeOpened] = useState(null);
  const [forceComponentToSelect, setForceComponentToSelect] = useState(null);
  const [prevLanguage, setPrevLanguage] = useState(language);
  const [disabledPopover, setDisabledPopover] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editMenuInfo, setEditMenuInfo] = useState(null);
  const [addBlockCurrentStep, setAddBlockCurrentStep] = useState(null);

  const { products, promoCode, variables, productsInTemplate, categoryToSelect, setCategoryToSelect, onCategoryDragStart } = useContext(TemplateEditorContext);
  
  const CATEGORIES_WITH_CONTENT = useMemo(() => {
    const result = ["image", "text", "promoCode", "preheader", "product"];
    return result;
  }, []);

  const getCategoriesWithContentOnlyEdit = (component) => {
    const result = ["logo", "button", "product", "columns", "column"];

    if (!component || !component?.attributes.attributes["data-blocklabel"].startsWith("columnSeparator")) {
      result.push("columnComponent");
    }

    return result;
  };


  const menuWidth = useMemo(() => {
    if (!isOpened) {
      return WIDTH_CLOSED;
    }
    if (!selectedCategory) {
      return WIDTH_OPENED;
    }
    if (editMenuInfo?.type === "crop" && navigationItemSelected === "content") {
      return WIDTH_OPENED_CROP + WIDTH_NAV;
    }
    if (editMenuInfo?.type === "image") {
      return WIDTH_OPENED_IMAGE_GALERY;
    }
    if (addBlockCurrentStep?.name === "image") {
      return WIDTH_OPENED_IMAGE_GALERY;
    }
    return WIDTH_OPENED + WIDTH_NAV;
  }, [selectedCategory, isOpened, editMenuInfo, navigationItemSelected, addBlockCurrentStep]);

  useCleanCropPreview(editMenuInfo, navigationItemSelected, isOpened, editor);
  useImageGaleryMenu(editMenuInfo, isOpened, addBlockCurrentStep);

  useEffect(() => {
    if (!selectedCategory || selectedComponent) {
      setAddBlockCurrentStep(null);
    }
  }, [selectedCategory, selectedComponent]);
  
  useCleanProductFocusPreviewFromRightMenu(editMenuInfo, navigationItemSelected, isOpened, editor);

  const resetNavigationItemSelected = useCallback((category, isEdit = false, component = null) => {
    if (!CATEGORIES_WITH_CONTENT.includes(category)) {
      if (getCategoriesWithContentOnlyEdit(component).includes(category) && isEdit) {
        setNavigationItemSelected("content");
      } else {
        setNavigationItemSelected("form");
      }
    } else {
      if (!isEdit && CATEGORIES_WITH_CONTENT_WHICH_START_WITH_FORM.includes(category)) {
        setNavigationItemSelected("form");
      } else {
        setNavigationItemSelected("content");
      }
    }
  }, [CATEGORIES_WITH_CONTENT]);

  const appendBlock = useCallback((block, index = null, isPreview = false) => {
    const appended = [];
    editor.getComponents().at(0).onAll(component => {
      if (component.is("mj-body")) {
        let content = block.getContent();
        if (isPreview) {
          editor.UndoManager.stop();
          content = content.includes("data-preview=") ? content : content.replaceAll("data-gjs=\"kilibaBlock\"", "data-gjs=\"kilibaBlock\" data-preview=\"true\"");
        }

        const parsed = editor.getComponents().parseString(content);
        const parsedArray = Array.isArray(parsed) ? parsed : [parsed];
        parsedArray.forEach(toAdd => {
          const _appended = component.append(
            {
              ...toAdd,
              "script-props": [{
                "fromBlock": block.getLabel(),
              }],
            },
            { at: (Number.isInteger(index) ? index + 1 : undefined) }
          );
          appended.push(..._appended);
          if (Number.isInteger(index)) {
            index++;
          }
        });

        if (isPreview) {
          editor.UndoManager.start();
        }        
      }
    });

    return appended;
  }, [editor]);

  const replaceBlock = useCallback((component, block, isPreview = false) => {
    const index = component.index();

    let appended;

    if (component.attributes.attributes["data-gjs"] !== "kilibaColumn" && component.attributes.attributes["data-column-component"] !== "true") {

      appended = appendBlock(block, index, isPreview);

      if (component.attributes.type.startsWith("preview-")) {
        editor.UndoManager.stop();
      }
      
      component.remove();
      
      if (component.attributes.type.startsWith("preview-")) {      
        editor.UndoManager.start();
      }
    } else {
      // COLUMN

      const kilibaBlockParent = component.closest("[data-gjs*=\"kilibaBlock\"]");
      const parent = component.parent();      
      const components = parent.components();

      let idx = components.filter(e => !!e.getEl().tagName).findIndex(e => e.getId() === component.getId());

      const mjml = parent.components().map(c => {
        if (c.getId() === component.getId()) {
          return block.getContent();
        } else {
          return c.toHTML();
        }
      }).join("");

      const extractedContent = extractContentFromColumnsComponent(kilibaBlockParent, wordings, language, variables);
      const label = kilibaBlockParent.attributes.attributes["data-blocklabel"];
      const kilibaBlock = blocks.find(block => block.getLabel() === label);

      kilibaBlock.set({ 
        attributes: { 
          ...kilibaBlock.attributes.attributes, 
          wordings: { ...extractedContent.columnsWordings, ...block.attributes.attributes.wordings },
          variables: { ...extractedContent.columnsVariables, ...block.attributes.attributes.variables }
        } 
      });
      kilibaBlockParent.set("script-props", [{ "fromBlock": kilibaBlock.getLabel() }]);

      const result = parent.components(mjml);
      
      if (idx >= result.length) {
        idx = result.length - 1;
      }

      appended = [result[idx]];
    }

    return appended;

  }, [editor, appendBlock, blocks, wordings, language, variables]);

  const addPreviewBlock = useCallback((block, forceIndex = null) => {
    let previewComponent = null;
    editor.getComponents().at(0).onAll(component => {
      if (component.attributes.type.startsWith("preview-")) {
        previewComponent = component;
      }
    });    

    if (previewComponent) {
      const appended = replaceBlock(previewComponent, block, true);
      templateEditorUtils.deletePreviewBlocks(editor, appended);
    } else {
      const selected = editor.getSelected();
      let index = selected ? selected.index() : null;
      if (Number.isInteger(forceIndex)) {
        index = forceIndex;
      }
      const appended = appendBlock(block, index, true);
      const scrollingContainer = canvasContainerRef.current.parentNode;

      setTimeout(() => {
        scrollingContainer.scrollTo({
          left: 0,
          top: !selected && !Number.isInteger(forceIndex) ? scrollingContainer.scrollHeight : appended[appended.length - 1].getEl()?.getBoundingClientRect().top,
          behavior:  "smooth",
        }); 
      }, 300);
      
    }
  }, [appendBlock, editor, canvasContainerRef, replaceBlock]);

  const getBaseContent = useCallback((category) => {
    if (category === "product" && products.length === 1) {
      return getContentFromProduct(products[0]);
    } 
    
    if (category === "promoCode") {
      return { promoCode };
    } 
    
    if (category === "text") {
      return { disableGPT: true, productIds: [] };
    }
    
    else {
      return {};
    }
  }, [products, promoCode]);

  const setDefaultBlock = useCallback((categoryName) => {
    const filteredBlocks = blocks
      .filter(block => block.getCategoryLabel().startsWith(`${categoryName}:`) && block.attributes.activate);

    const block = filteredBlocks.find(block => !!block.attributes.attributes.isDefaultBlock) || filteredBlocks[0];

    if(block) {
      setDefaultBlockId(block.getId());
      addPreviewBlock(block, categoryName === "preheader" ? -1 : null);
    } else {
      setDefaultBlockId(null);
    }
    
    return block;
  }, [blocks, addPreviewBlock]);

  const onAddBlock = useCallback((categoryName) => {
    const baseContent = getBaseContent(categoryName);
    editor.runCommand("kiliba:blocks:updateContent", { category: categoryName, content: baseContent, language });

    const block = setDefaultBlock(categoryName);

    setForceComponentToSelect(null);
    resetNavigationItemSelected(categoryName);
    setContent(baseContent);
    if (CATEGORIES_WITH_CONTENT_WHICH_START_WITH_FORM.includes(categoryName) || selectedCategory === "text") {
      setFormBlockId(block.getId());
    } else {
      setFormBlockId(null);
    }
    
    editor.selectRemove(editor.getSelectedAll());
    setSelectedCategory(categoryName);
  }, [editor, getBaseContent, language, resetNavigationItemSelected, selectedCategory, setDefaultBlock]);

  const updateForm = (blockId) => {
    const block = blocks.find(block => block.getId() === blockId);
    setFormBlockId(blockId);

    if (selectedComponent) {
      const appended = replaceBlock(selectedComponent, block);
      if (appended?.length) {
        editor.select(appended[appended.length - 1], { preventReload: true, preventRefreshBlock: true });
      }
    } else {
      addPreviewBlock(block);
    }
  };

  const getNeededContentForCategory = useCallback((category, content) => {
    if (category === "product") {
      if (!content.productDescription || content.productsDescription?.length) {
        const product = products.find(({ id_product }) => id_product === content.productId);
        if (product) {
          content.product = product;
        }
      }
    }

    if (category === "promoCode") {
      if (!content.promoCode && !content.forceDelete) {
        content.promoCode = promoCode;
      }
    }
  }, [products, promoCode]);

  const getUpdatedContent = useCallback((content, newContent) => {
    const finalContent = { ...content, ...newContent };
    for (const key of Object.keys(finalContent)) {
      if (finalContent[key] === undefined) {
        delete finalContent[key];
      }
    }
    
    getNeededContentForCategory(selectedCategory, finalContent);
    return finalContent;
  }, [getNeededContentForCategory, selectedCategory]);

  const updateContent = useCallback((newContent, forceBlockId = null) => {
    const finalContent = getUpdatedContent(content, newContent);
    editor.runCommand("kiliba:blocks:updateContent", { category: selectedCategory, content: finalContent, language });
    const blockId = forceBlockId || formBlockId || defaultBlockId;
    if (!blockId) {
      setDefaultBlock(selectedCategory);
    };
    let block = blocks.find(block => block.getId() === blockId);

    if (block && !block.attributes.activate) {
      const filteredBlocks = blocks
        .filter(block => block.getCategoryLabel().startsWith(`${selectedCategory}:`) && block.attributes.activate);

      block = filteredBlocks.find(block => !!block.attributes.attributes.isDefaultBlock) || filteredBlocks[0];
      if (block) {
        setDefaultBlockId(block.getId());

        if (formBlockId) {
          setFormBlockId(block.getId());
        }
      }
    }

    setContent(prev => {
      const finalContent = { ...prev, ...newContent };
      for (const key of Object.keys(finalContent)) {
        if (finalContent[key] === undefined) {
          delete finalContent[key];
        }
      }
      return finalContent;
    });

    if (
      CATEGORIES_WITH_MULTIPLE_CONTENT.find(elem => elem.name === selectedCategory && elem.properties.some(key => key in newContent))
    ) {
      const componentsToUpdate = [];
      editor.getComponents().at(0).onAll(component => {
        if (component.attributes.attributes["data-gjs"] === "kilibaBlock") {
          const block = blocks.find(block => block.getLabel() === component.attributes.attributes["data-blocklabel"]);
          if (block.getCategoryLabel().startsWith(`${selectedCategory}:`) && component.cid !== selectedComponent?.cid && !component.attributes.type.startsWith("preview-")) {
            componentsToUpdate.push(component);
          }
        }
      });

      componentsToUpdate.forEach(component => {
        const extractedContent = extractContentFromComponent(selectedCategory, component, wordings, language);
        const finalContent = getUpdatedContent(extractedContent, newContent);
        editor.runCommand("kiliba:blocks:updateContent", { category: selectedCategory, content: finalContent });
        const block = blocks.find(block => block.getLabel() === component.attributes.attributes["data-blocklabel"]);
        replaceBlock(component, block);
      });
    };

    if (selectedComponent && block) {
      const appended = replaceBlock(selectedComponent, block);
      if (appended?.length) {
        editor.select(appended[appended.length - 1], { preventReload: true, preventRefreshBlock: true });
      }
    } else if(block) {
      addPreviewBlock(block);
    }
  }, [addPreviewBlock, blocks, content, defaultBlockId, editor, formBlockId, getUpdatedContent, language, replaceBlock, selectedCategory, selectedComponent, setDefaultBlock, wordings]);

  const onAddBlockEnd = useCallback((event) => {
    const blockId = formBlockId || defaultBlockId;
    const block = blocks.find(block => block.getId() === blockId);

    let previewComponent = null;
    editor.getComponents().at(0).onAll(component => {
      if (component.attributes.type.startsWith("preview-")) {
        previewComponent = component;
      }
    });  

    if (previewComponent) {
      templateEditorUtils.deletePreviewBlocks(editor, [previewComponent]);
      const appended = replaceBlock(previewComponent, block);
      if (appended?.length && isOpened) {
        editor.select(appended[appended.length - 1], event);
      }

      return appended[appended.length - 1];
    }
    
  }, [defaultBlockId, formBlockId, blocks, editor, replaceBlock, isOpened]);  

  const onCancel = useCallback((force = false, event = {}) => {
    const blockId = formBlockId || defaultBlockId;
    if (!force && !selectedComponent && blockId && (checkContent(selectedCategory, content) || !CATEGORIES_WITH_CONTENT.includes(selectedCategory))) {
      onAddBlockEnd(event);
    } else {
      setSelectedCategory(null);  
      setForceComponentToSelect(null);
      setFormBlockId(null);
      setContent({});
    }

    templateEditorUtils.deletePreviewBlocks(editor);
  }, [editor, content, onAddBlockEnd, selectedCategory, selectedComponent, formBlockId, defaultBlockId, CATEGORIES_WITH_CONTENT, setSelectedCategory]);

  const onComponentSelected = useCallback((component, event) => {
    if (!component) {
      return ;
    }
    
    if (component.attributes.attributes["data-gjs"] === "kilibaColumn" && component.getEl().getElementsByClassName("dataPreviewColumn").length) {
      return ;
    }

    const prevContent = content;
    onCancel();
    setSelectedComponent(component);
    const label = component.attributes.attributes["data-blocklabel"];
    const block = blocks.find(block => block.getLabel() === label);
    if (block) {
      const category = block.getCategoryLabel().split(":")[0];
      const extractedContent = extractContentFromComponent(category, component, wordings, language, variables);
      const finalContent = event.preventReload ? { ...generatedWordings, ...prevContent, ...extractedContent } : { ...generatedWordings, ...extractedContent};
      
      getNeededContentForCategory(category, finalContent);

      if (!event.preventRefreshBlock) {
        editor.runCommand("kiliba:blocks:updateContent", { category, content: finalContent, language });
      }

      setSelectedCategory(category);
      setForceComponentToSelect(null);
      if (!event.preventReload) {
        resetNavigationItemSelected(category, true, component);
        setEditMenuInfo(null);
      }
      setContent(finalContent);
      setFormBlockId(block.getId());
    }
  
  }, [onCancel, blocks, resetNavigationItemSelected, editor, content, wordings, language, generatedWordings, getNeededContentForCategory, variables, setSelectedCategory]);

  const onComponentDeselected = useCallback(() => {
    setSelectedComponent(null);
    if (forceComponentToSelect) {
      editor.select(forceComponentToSelect);
      setForceComponentToSelect(null);
    } else {
      setSelectedCategory(null);
    }
  }, [forceComponentToSelect, editor, setSelectedCategory]);

  useEffect(() => {
    // reset menu if no selected component and no preview
    if (!selectedComponent && editor) {
      let previewComponent = null;
      editor.getComponents().at(0)?.onAll(component => {
        if (component.attributes.type.startsWith("preview-")) {
          previewComponent = component;
        }
      });

      if (!previewComponent) {
        setSelectedCategory(null);
      }
    }
  }, [selectedComponent, editor, setSelectedCategory]);

  const onComponentRemove = useCallback((component) => {
    // reset menu on component removed
    if (component.getId() === selectedComponent?.getId()) {
      setSelectedCategory(null);
    }
  }, [selectedComponent, setSelectedCategory]);

  /* ===== on language change ===== */

  useEffect(() => {
    if (prevLanguage !== language) {
      editor.runCommand("kiliba:blocks:updateContent", { category: selectedCategory, content, language });
      setPrevLanguage(language);
    }
  }, [language, prevLanguage, editor, content, selectedCategory]);

  /* ===== reload blocks on component update (needed for wordings) ===== */

  const onComponentUpdate = useCallback(component => {
    if (selectedComponent) {
      const parent = component.closest("[data-gjs*=\"kilibaBlock\"]");
      if (parent && parent.getId() === selectedComponent.getId()) {
        setMustUpdateComponent(prev => prev + 1);
      }
    }
  }, [selectedComponent]);

  useEffect(() => {
    if (mustUpdateComponent) {
      const timeout = setTimeout(() => {
        const preventRefreshBlock = selectedCategory === "promoCode";
        onComponentSelected(selectedComponent, { preventReload: true, preventRefreshBlock });
        setMustUpdateComponent(0);
      }, 500);
      return () => clearTimeout(timeout);
    }

  }, [mustUpdateComponent, selectedComponent, onComponentSelected, selectedCategory]);

  useEffect(() => {
    if (editor) {
      editor.on("component:update", onComponentUpdate);
      return () => {
        editor.off("component:update", onComponentUpdate);
      };
    }
  }, [editor, onComponentUpdate]);

  /* =================================== */

  /* ====== Reset on add preview from BlockAction ====== */

  const onAddPreview = useCallback(() => {
    setSelectedCategory(null);
    setFormBlockId(null);
    setDefaultBlockId(null);
  }, [setSelectedCategory]);

  useEffect(() => {
    if (editor) {
      editor.on("addPreview", onAddPreview);
      return () => {
        editor.off("addPreview", onAddPreview);
      };
    }
  }, [editor, onAddPreview]);

  /* =================================== */

  useEffect(() => {
    if (editor) {
      editor.on("component:selected", onComponentSelected);
      editor.on("component:deselected", onComponentDeselected);
      editor.on("component:remove", onComponentRemove);
      return () => {
        editor.off("component:selected", onComponentSelected);
        editor.off("component:deselected", onComponentDeselected);
        editor.off("component:remove", onComponentRemove);
      };
    }
  }, [editor, onComponentSelected, onComponentDeselected, onComponentRemove]);

  useEffect(() => {
    // reset menu on close
    if (!isOpened && lastIsOpened) {
      onCancel();
      setSelectedCategory(null);
      setEditMenuInfo(null);
    }
    setLastIsOpened(isOpened);
  }, [isOpened, onCancel, lastIsOpened, setSelectedCategory]);

  const showFooter = useMemo(() => selectedCategory && !selectedComponent, [selectedCategory, selectedComponent]);

  const categoriesFilter = (category, type) => {

    const result = (!category.parent && category.type === type && blocks.filter(block => block.getCategoryLabel().startsWith(`${category.name}:`)).length) || (!category.parent && category.emptyComponent && category.type === type);

    //hide preheader if already in template
    if (category.name === "preheader" && result) {
      let preheaderComponent = null;

      if (!editor || !editor.getComponents().at(0)) {
        return false;
      }

      editor.getComponents().at(0).onAll(component => {
        if (component.attributes.attributes["data-blocklabel"] === "preheader") {
          preheaderComponent = component;
        }
      });

      return preheaderComponent === null;
    }

    return result;
  };

  useEffect(() => {
    if (disabledPopover) {
      const timeout = setTimeout(() => setDisabledPopover(null), 4000);
      return () => clearTimeout(timeout);
    }
  }, [disabledPopover]);

  useEffect(() => {

    if (editor && editor.getComponents().length && categoryToSelect && isOpened) {
      onAddBlock(categoryToSelect);
      setCategoryToSelect(null);
    }

  }, [categoryToSelect, onAddBlock, isOpened, setCategoryToSelect, editor]);

  /* ============== on drag end category ============== */

  const onDragCategoryEnd = useCallback((props) => {
    onAddBlock(props.category);
  }, [onAddBlock]);

  useEffect(() => {
    editor.on("dragCategoryEnd", onDragCategoryEnd);
    return () => editor.off("dragCategoryEnd", onDragCategoryEnd);
  }, [editor, onDragCategoryEnd]);

  /* ============================ */

  /* ============== Update content event ============== */

  const onUpdateContentEvent = useCallback((props) => {
    updateContent(props.content, props.blockId);
  }, [updateContent]);

  useEffect(() => {
    editor.on("updateContent", onUpdateContentEvent);
    return () => editor.off("updateContent", onUpdateContentEvent);
  }, [editor, onUpdateContentEvent]);

  /* ============================ */

  const showNavBar = useMemo(() => {
    if (addBlockCurrentStep?.name === "image") {
      return false;
    }
    return !!selectedCategory && editMenuInfo?.type !== "image";
  }, [selectedCategory, editMenuInfo, addBlockCurrentStep]);

  const enableForm = useMemo(() => {
    if (!showNavBar || !selectedCategory) {
      return false;
    }

    if (selectedComponent?.attributes.attributes["data-blocklabel"].startsWith("columnSeparator")) {
      return true;
    }

    return !CATEGORIES_WITHOUT_FORM.includes(selectedCategory);
  }, [showNavBar, selectedCategory, selectedComponent]);

  return (
    <div
      className={classes.rightMenu}
      style={{
        "--menu-width": `${menuWidth}px`,
        "--footer-width": `${menuWidth - (showNavBar ? WIDTH_NAV : 0)}px`,
        "--footer-height": `${showFooter ? FOOTER_HEIGHT : 0}px`
      }}
    >

      {!isOpened ?
        <div className={classes.rightMenuContentClosed} onClick={() => setIsOpened(true)}>
          <div>
            <h4><Translation id="templateEditor.addElement" /></h4>
            <i
              className={`fa-solid fa-circle-plus ${classes.addElementIcon}`}
            />
          </div>
        </div> :

        <div className={classes.rightMenuContainerOpened}>

          {showNavBar &&
            <div style={{ "--navigation-width": `${WIDTH_NAV}px`, width: "var(--navigation-width)" }}>
              <div className={classes.navigationContainer}>
                <div className={classes.navigation}>
                  <div
                    onClick={() => enableForm && setNavigationItemSelected("form")}
                    className={`${classes.navigationItem} ${navigationItemSelected === "form" ? classes.navigationItemSelected : ""}`}>
                    <h3><Translation id="newsletter.rightMenu.form" />
                      {!enableForm &&
                        <i className="fa-solid fa-lock-keyhole" />}
                    </h3>
                    <i className={`fa-solid fa-chevron-down ${classes.arrow}`} />
                  </div>

                  <div
                    onClick={() => {
                      if (CATEGORIES_WITH_CONTENT.includes(selectedCategory)) {
                        setNavigationItemSelected("content");
                      } else if (getCategoriesWithContentOnlyEdit(selectedComponent).includes(selectedCategory)) {
                        if (selectedComponent) {
                          setNavigationItemSelected("content");
                        } else {
                          onCancel();
                        }
                      }
                    }}
                    className={`${classes.navigationItem} ${navigationItemSelected === "content" ? classes.navigationItemSelected : ""}`}>
                    <h3><Translation id="newsletter.rightMenu.content" />
                      {!CATEGORIES_WITH_CONTENT.includes(selectedCategory) && !getCategoriesWithContentOnlyEdit(selectedComponent).includes(selectedCategory) &&
                        <i className="fa-solid fa-lock-keyhole" />}
                    </h3>
                    <i className={`fa-solid fa-chevron-down ${classes.arrow}`} />
                  </div>
                </div>
              </div>
            </div>
          }

          <div className={classes.rightMenuContentOpenedContainer}>
            <div className={classes.rightMenuContentOpened}>

              {!selectedCategory &&
                <div className={classes.categoriesContainer}>
                  {CATEGORIES_TYPE.map(type => (
                    <div key={type}>
                      <h4><Translation id={`blockManager.${type}`} /></h4>
                      <div className={classes.categories}>
                        {CATEGORIES.filter(category => categoriesFilter(category, type)).map(category => {
                          const disabled = category.name === "product" && productsInTemplate.length >= 6;
                          
                          return (
                            <div 
                              id={`category.${category.name}`} 
                              key={category.name} 
                              className={`${classes.category} ${disabled ? classes.categoryDisabled : ""}`} 
                              onClick={() => !disabled ? onAddBlock(category.name) : setDisabledPopover(category.name)}
                              draggable="true" 
                              onDragStart={!disabled ? onCategoryDragStart : null}
                            >
                              <i className={category.icon} />
                              <div className="info"><Translation id={`blockManager.category.${category.name}`} /></div>
                              {category.topRightIcon && <i className={`${category.topRightIcon} ${classes.categoryTopRightIcon}`} />}
                            </div>                              
                          );
                        })}
                      </div>
                    </div>
                  ))}
                  {disabledPopover && 
                    <div className={`text ${classes.disabledPopover}`} style={{ top: document.getElementById(`category.${disabledPopover}`)?.getBoundingClientRect().top + 30 }}>
                      <Translation id={`rightMenu.disabledPopover.${disabledPopover}`} />
                      <i className="fa-solid fa-times" onClick={() => setDisabledPopover(null)}/>
                    </div>
                  }
                </div>
              }

              {selectedCategory && !selectedComponent && 
                <RightMenuAddBlock 
                  category={selectedCategory}
                  blocks={blocks}
                  selectedCategory={selectedCategory}
                  updateForm={updateForm}
                  formBlockId={formBlockId}
                  defaultBlockId={defaultBlockId}
                  scrollableContainerClass={classes.rightMenuContentOpened}
                  onCancel={onCancel}
                  setNavigationItemSelected={setNavigationItemSelected}
                  updateContent={updateContent}
                  content={content}
                  templateId={templateId}
                  navigationItemSelected={navigationItemSelected}
                  editor={editor}
                  language={language}
                  onAddBlock={onAddBlock}
                  canvasContainerRef={canvasContainerRef}
                  addBlockCurrentStep={addBlockCurrentStep}
                  setAddBlockCurrentStep={setAddBlockCurrentStep}
                />
              }

              {selectedCategory && selectedComponent && navigationItemSelected === "content" &&
                <RightMenuEditContent 
                  component={selectedComponent}
                  block={blocks.find(block => block.getId() === formBlockId)}
                  category={selectedCategory}
                  content={content}
                  updateContent={updateContent}
                  editor={editor}
                  onCancel={onCancel}
                  editMenuInfo={editMenuInfo}
                  setEditMenuInfo={setEditMenuInfo}                  
                  isPromoCodeOpened={isPromoCodeOpened}                  
                  setIsPromoCodeOpened={setIsPromoCodeOpened}
                  scrollableContainerClass={classes.rightMenuContentOpened}
                  templateId={templateId}
                  language={language}
                  generatedWordings={generatedWordings}
                  canvasContainerRef={canvasContainerRef}
                />
              }

              {selectedCategory && selectedComponent && navigationItemSelected === "form" &&
                <>
                  <div className={classes.elementButton} onClick={() => onCancel()}>
                    <div className="textMedium"><Translation id="newsletter.rightMenu.element" /></div>
                    <i className="fa-solid fa-circle-plus" />
                  </div>
                  <RightMenuForm
                    blocks={blocks}
                    selectedCategory={selectedCategory}
                    allowCrop={false}
                    onChange={updateForm}
                    selectedBlockId={formBlockId || defaultBlockId}
                    isEdit={!!selectedComponent}
                    scrollableContainerClass={classes.rightMenuContentOpened}
                    editor={editor}
                    templateId={templateId}
                    language={language}
                  />
                </>
              }
            </div>
          </div>
        </div>
      }
    </div>
  );
};