import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import { useAuth } from "../../../../hooks/useAuth";
import { PageBody } from "../common/PageBody/PageBody";
import { PageBodyWrapper } from "../common/PageBodyWrapper/PageBodyWrapper";
import { UsecaseSelectionBlock } from "./UsecaseSelectionBlock";

import { styles } from "./UsecaseSelectionBlock.styles.js";

const useStyles = makeStyles(styles);

export function UsecaseSelection(props) {
  const history = useHistory();
  const auth = useAuth();
  const intl = useIntl();
  const classes = useStyles();

  useEffect(() => {
    auth.sendEventToAmplitude("onboarding_usecase_selection.see_page");
  }, [auth]);

  return (
    <PageBodyWrapper>
      <PageBody
        style={{
          padding: "50px 70px 40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className={classes.header}>
          <p className="display2">{intl.messages["onBoarding.usecaseSelection.title"]}</p>
          <h3 className={classes.subtitle}>{intl.messages["onBoarding.usecaseSelection.subtitle"]}</h3>
        </div>
        <UsecaseSelectionBlock
          onUsecaseClick={usecaseName => {
            auth.sendEventToAmplitude("onboarding_usecase_selection.click_usecase", { name: usecaseName });
            history.push(`/usecase_details?usecase=${usecaseName}`);
          }}
          onGoToUsecasesList={() => {
            auth.sendEventToAmplitude("onboarding_usecase_selection.click_see_all_usecases");
            history.push("/usecase");
          }}
        />
      </PageBody>
    </PageBodyWrapper>
  );
}
