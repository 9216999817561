import React, { Component } from "react";
import { injectIntl } from "react-intl";
import withStyles from "@material-ui/core/styles/withStyles";

import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import { FormHelperText } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { Input, InputAdornment } from "@material-ui/core";

import { palette } from "../../styles/palette";
import pickerLists from "../../ressources/pickerLists.json";

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: "5px",
    backgroundColor: theme.palette.background.paper,
  },
  customPaper: {
    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
    width: "100%",
    borderRadius: "5px",
    textDecoration: "none",
    marginTop: "5px",
  },
  selectInput: {
    width: "100%",
    fontFamily: "Coolvetica Book",
    letterSpacing: 1,
    fontSize: "13px",
    color: palette.primaryBlack,
    lineHeight: "24px",
  },
  cssUnderline: {
    "&:after": {
      borderBottomColor: palette.primaryYellow200,
    },
  },
  cssNotUnderline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  hover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  inputAdornment: {
    fontSize: "15px",
    color: palette.lightgray,
    paddingLeft: 8,
    paddingRight: 15,
  },
  buttonStyle: {
    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
    fontFamily: "Coolvetica Book",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    borderRadius: 5,
    backgroundColor: "#ffffff",
    color: "#878787",
    fontSize: "11px",
    height: "40px",
    width: "100%",
    textDecoration: "none",
    textTransform: "none",
    letterSpacing: 1,
    "&:focus": {
      backgroundColor: "#ffffff",
      border: "1px solid " + palette.primaryYellow200,
    },
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
});

export const ItemPicker = withStyles(styles)(
  injectIntl(
    class ItemPicker extends Component {
      constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.closePopper = this.closePopper.bind(this);
        this.handleClickItem = this.handleClickItem.bind(this);
        this.getListTextMarginLeft = this.getListTextMarginLeft.bind(this);
        this.state = {
          anchorEl: null,
          isOpen: false,
          title: "",
        };
      }

      UNSAFE_componentWillMount() {
        //when titleKey is assigned make change at first render
        const { titleKey, listName, intl } = this.props;
        const title =
          titleKey && listName ? intl.messages[listName + "." + titleKey] : "";
        this.setState({ title });
      }

      componentDidUpdate(prevProps) {
        const { titleKey, listName, intl } = this.props;
        if (titleKey !== prevProps.titleKey) {
          const title = intl.messages[listName + "." + titleKey] || "";
          this.setState({ title });
        }
      }

      handleClickItem(value) {
        const { intl, listName } = this.props;
        this.setState({
          title: listName ? intl.messages[listName + "." + value] : value,
        });
        this.props.onSelect(value);
        this.closePopper();
      }

      handleClick(event) {
        this.setState({
          anchorEl: event.currentTarget,
          isOpen: !this.state.isOpen,
        });
      }

      handleClose = function () {
        this.setState({ anchorEl: null });
      };

      closePopper(event) {
        this.setState({ isOpen: false });
      }

      getListTextMarginLeft() {
        const { alternativeStyle, whithoutBorder, startIcon } = this.props;
        let marginLeft = "13px";
        if (alternativeStyle && !startIcon) {
          marginLeft = "0px";
        } else if (alternativeStyle) {
          marginLeft = "30px";
        } else if (whithoutBorder) {
          marginLeft = "0px";
        }
        return marginLeft;
      }

      render() {
        const {
          classes,
          width,
          listName,
          intl,
          startIcon,
          selectedItem,
          placeholder,
          alternativeStyle,
          whithoutBorder,
          errorMessage,
          style,
          noMargin,
          dataCy,
          popperPlacement,
          maxHeight,
        } = this.props;

        const fontFamily =
          listName === "availableFontFamilies" ? this.state.title : "inherit";

        return (
          <div style={style} data-cy={dataCy}>
            {alternativeStyle ? (
              <Button
                className={classes.buttonStyle}
                aria-owns={this.state.anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                variant="contained"
                style={{
                  fontFamily: fontFamily,
                }}
                onClick={this.handleClick}
              >
                <i
                  style={{
                    fontSize: "18px",
                    position: "absolute",
                    left: "15px",
                  }}
                  className={startIcon}
                ></i>
                <div
                  style={{
                    textAlign: "justify",
                    position: "absolute",
                    left: startIcon ? "45px" : 16,
                    textOverflow: "ellipsis",
                  }}
                >
                  {this.state.title}
                </div>
                <i
                  style={{
                    fontSize: "14px",
                    position: "absolute",
                    right: "14px",
                  }}
                  className="fal fa-angle-down"
                ></i>
              </Button>
            ) : (
              <Input
                autoComplete="nope"
                classes={{
                  underline: whithoutBorder
                    ? classes.cssNotUnderline
                    : classes.cssUnderline,
                  input: classes.hover,
                }}
                className={classes.selectInput}
                placeholder={placeholder}
                value={this.state.title}
                onClick={this.handleClick}
                style={{
                  marginBottom: errorMessage === null && !noMargin ? 24 : null,
                  cursor: "pointer",
                }}
                startAdornment={
                  startIcon ? (
                    <InputAdornment
                      position="start"
                      className={startIcon + " " + classes.inputAdornment}
                    >
                      <span />
                    </InputAdornment>
                  ) : null
                }
                endAdornment={
                  <InputAdornment
                    position="start"
                    className={"fal fa-chevron-down"}
                    style={{ fontSize: "10px" }}
                  >
                    <span />
                  </InputAdornment>
                }
              ></Input>
            )}
            {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}

            <Popper
              anchorEl={this.state.anchorEl}
              open={this.state.isOpen}
              placement={popperPlacement || "bottom-start"}
              style={{ zIndex: "1000000", width }}
              modifiers={{ flip: { enabled: false } }}
            >
              <ClickAwayListener onClickAway={this.closePopper}>
                <Paper
                  className={classes.customPaper}
                  style={{
                    width: "auto",
                    maxHeight: maxHeight ? maxHeight : "300px",
                    overflowY: "auto",
                  }}
                >
                  <List className={classes.root}>
                    {pickerLists[listName].map((value, key) => {
                      const fontFamily =
                        listName === "availableFontFamilies"
                          ? value === "arial"
                            ? "Arial"
                            : value === "helvetica"
                            ? "Helvetica"
                            : value === "geneva"
                            ? "Geneva"
                            : value === "verdana"
                            ? "Verdana"
                            : value === "tahoma"
                            ? "Tahoma"
                            : value === "georgia"
                            ? "Georgia"
                            : value === "trebuchetMs"
                            ? "Trebuchet MS"
                            : value === "timesNewRoman"
                            ? "Times New Roman"
                            : value === "palatino"
                            ? "Palatino"
                            : value === "comicSansMs"
                            ? "Comic Sans MS"
                            : value === "courier"
                            ? "Courier"
                            : value === "impact"
                            ? "Impact"
                            : value === "arialBlack"
                            ? "Arial Black"
                            : ""
                          : "inherit";
                      return (
                        <ListItem
                          data-cy={`${dataCy}-choice${key}`}
                          key={value}
                          button
                          selected={selectedItem === value}
                          onClick={() => this.handleClickItem(value)}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                fontFamily: fontFamily,
                                fontSize: !alternativeStyle ? "14px" : "11px",
                                marginLeft: this.getListTextMarginLeft(),
                                letterSpacing: "1px",
                                fontWeight: "normal",
                                fontStyle: "normal",
                                fontStretch: "normal",
                                lineHeight: "normal",
                                borderRadius: 5,
                              },
                            }}
                            primary={
                              listName
                                ? intl.messages[listName + "." + value]
                                : value
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </div>
        );
      }
    }
  )
);
