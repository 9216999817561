import { CustomButton } from "../common/Button/CustomButton";
import { Dialog } from "../common/Dialog";
import { Translation } from "../common/Translation";

import classes from "./ConfirmDeleteNewsletterPopup.module.scss";

export const ConfirmPauseNewsletterPopup = ({ isOpen, close, pauseNewsletter }) => {

  return (
    <Dialog
      isOpen={isOpen}
      close={close}
      style={{ padding: "40px 60px" }}
      PaperProps={{ style: { borderRadius: 15 } }}
    >
      <div className={classes.container}>
        <h4 className={classes.title}><Translation id="newsletter.pausePopup.title" /></h4>
        <div className={`info ${classes.content}`}><Translation id="newsletter.pausePopup.content" /></div>
        <div className={classes.buttonsContainer}>
          <CustomButton
            type="secondary"
            size="sm"
            onClick={close}
          >
            <Translation id="button.anulation" />
          </CustomButton>
          <CustomButton
            type="primary"
            size="sm"
            onClick={pauseNewsletter}
          >
            <Translation id="newsletter.editPopup.pause" />
          </CustomButton>
        </div>
      </div>
    </Dialog>
  );
};