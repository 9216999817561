import { palette } from "../../../../styles/palette";

export const styles = {
  welcome: {
    display: "flex",
    flexDirection: "row",
    gridGap: 110,
    width: 833,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: palette.neutralLightGrey,
  },
  imageBlock: {
    display: "flex",
  },
  image: {
    objectFit: "contain",
  },
  formContainer: {
    width: 328,
  },
  title: {
    marginBottom: 10,
  },
  error: {
    color: palette.errorRed,
    fontFamily: "Poppins",
    fontSize: "12px",
    textAlign: "start",
  }
};
