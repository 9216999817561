const types = ["mj-text", "mj-image", "mj-divider", "mj-button"];

export const setUpkilibaColumnSubComponents = (editor) => {
  types.forEach(key => {
    editor.Components.addType(`column-component-${key}`, {
      extend: key,
      isComponent: el => {
        if (el.tagName?.toLowerCase() === key && "data-column-component" in el.attributes) {
          return true;
        }
      },
      model: {
        defaults: {
          hoverable: true,
          selectable: true,
        }
      }
    });
  });
};