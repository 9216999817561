import { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";
import Typewriter from "typewriter-effect";

import { palette } from "../../../../../styles/palette";

const styles = theme => ({
  proposalsContainer: {
    zIndex: 2,
    background: palette.primaryWhite,
    boxShadow: "0px 3px 16px -5px rgba(0, 0, 0, 0.22)",
    borderRadius: "10px",
    padding: "30px 0px",
    textAlign: "center",
    maxHeight: 500,
    overflowY: "scroll",
    position: "relative",
    "& .Typewriter__cursor": {
      borderRight: `1px solid ${palette.anotherlightgray}`,
      borderLeft: `1px solid ${palette.anotherlightgray}`,
      background: palette.anotherlightgray,
      color: palette.anotherlightgray,
    },
    minWidth: 490,
  },
  close: {
    position: "absolute",
    cursor: "pointer",
    top: 10,
    right: 15,
  },
  title: {
    fontSize: 20,
    marginBottom: 10,
  },
  proposals: {
    textAlign: "left",
    padding: "10px 0px",
    paddingTop: 20,
  },
  proposal: {
    color: palette.anotherlightgray,
    padding: "20px 30px",
    lineHeight: "18px",
    cursor: "pointer",
    "&:hover": {
      background: palette.neutralCarts,
    }
  },
  selected: {
    color: palette.black,
    background: palette.neutralCarts,
  },
  proposalText: {
    outline: "none",
    fontSize: 14,
  },
  divider: {
    padding: "0px 30px",
    "& hr": {
      margin: 0,
      border: `1px solid ${palette.neutralLightGrey}`,
    }
  },
  dividerHide: {
    padding: "0px 30px",
    "& hr": {
      margin: 0,
      border: "1px solid transparent",
    }
  },
  generateMoreButton: {
    color: palette.purple,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gridGap: 10,
    cursor: "pointer"
  },
});

const useStyles = makeStyles(styles);

export const HtmlCustomizerProposalsList = injectIntl(({
  close,
  scrollingContentRef,
  proposals,
  selected,
  proposalToWrite,
  onProposalWritten,
  loading,
  generateWording,
  showStopButton,
  onStopGeneration,
  updateProposal,
  partKey,
  intl,
}) => {
  const classes = useStyles();
  const [proposalWriting, setProposalWriting] = useState(null);

  useEffect(() => {
    if (proposalWriting === null && proposalToWrite) {
      setProposalWriting(proposalToWrite);
    }
  }, [proposalToWrite, proposalWriting]);

  const loadingStrings = [
    intl.messages["htmlCustomizerWordingProposal.loading1"],
    intl.messages["htmlCustomizerWordingProposal.loading2"],
    intl.messages["htmlCustomizerWordingProposal.loading3"],
    intl.messages["htmlCustomizerWordingProposal.loading4"],
  ];

  const cleanString = string => string.replaceAll(/<[^>]*>/g, "").replaceAll(/&nbsp;/g, " ");

  return (
    <div className={classes.proposalsContainer} ref={scrollingContentRef}>
      <i className={`fal fa-times ${classes.close}`} onClick={close} />
      <span className={classes.title}><FormattedMessage id={`htmlCustomizerWordingProposal.${partKey}`} /></span>
      <div className={classes.proposals}>
        {[...proposals, ...(proposalWriting ? [proposalWriting] : [])].map((proposal, idx) => {
          
          const isTypeWriter = proposalToWrite && idx >= proposals.length;

          return (
            <div key={idx}>
              <div
                onClick={() => !isTypeWriter ? updateProposal(idx) : null}
                className={`${classes.proposal} ${selected === idx ? classes.selected : ""}`}>

                {isTypeWriter ?
                  <span className={classes.proposalText}>
                    <Typewriter
                      onInit={typewriter => {
                        typewriter.changeDelay(15)
                          .typeString(cleanString(proposal))
                          .callFunction(() => {
                            onProposalWritten(proposal);
                            setProposalWriting(null);
                          })
                          .start();
                      }}
                    />
                  </span> :
                  <span className={classes.proposalText}>{cleanString(proposal)}</span>
                }
              </div>
              { (proposalToWrite && idx === proposals.length) ||
                (!proposalToWrite && idx === proposals.length - 1) ||
                selected === idx ||
                selected - 1 === idx ?
                <div className={classes.dividerHide}><hr /></div> :
                <div className={classes.divider}><hr /></div>
              }
            </div>
          );
        })}
        {loading &&
          <div className={classes.proposal}>
            <span className={classes.proposalText}>
              <Typewriter
                options={{
                  strings: loadingStrings,
                  autoStart: true,
                  loop: true,
                  delay: 20,
                }}
              />
            </span>
          </div>
        }
      </div>
      {!loading && !proposalToWrite &&
        <div className={classes.generateMoreButton} onClick={generateWording}>
          <i className="fa-solid fa-rotate-right" />
          <FormattedMessage id="htmlCustomizerWordingProposal.generateMore.button" />
        </div>
      }
      {!loading && showStopButton &&
        <div className={classes.generateMoreButton} onClick={onStopGeneration}>
          <i className="fa-regular fa-circle-stop" />
          <FormattedMessage id="htmlCustomizerWordingProposal.stopGenerate.button" />
        </div>
      }
    </div>
  );
});

