import { useEffect, useMemo, useState, useContext, useCallback } from "react";
import { useIntl } from "react-intl";
import { KiTable } from "../common/KiTable/KiTable";
import { KiDropdown } from "../common/Input/KiDropdown";
import { KiTextField } from "../common/Input/KiTextField";
import { StatsCarousel } from "./StatsCarousel/StatsCarousel";
import { getFormattedNumber } from "../../tools/getFormattedNumber.js";
import { KiDatePicker } from "../common/KiDatePicker/KiDatePicker";
import { Translation } from "../common/Translation";
import { AccountContext, reloadAccountContext } from "../../contexts/context";
import { palette } from "../../styles/palette";
import { NewsletterEditPopup } from "./NewsletterEditPopup.jsx";

import moment from "moment-timezone";
import classes from "./NewsletterStats.module.scss";


export const NewsletterStats = ({ mjmlTemplates = [], statistics }) => {

  const intl = useIntl();
  const { account } = useContext(AccountContext);
  const [search, setSearch] = useState("");
  const [displayedData, setDisplayedData] = useState([]);
  const [calculatedStats, setCalculatedStats] = useState({ turnover: "", nbOrder: 0, avgOrder: 0, send: 0, openRate: 0, click: 0, conversionRate: 0, unsubscribe: 0 });
  const [pagination, setPagination] = useState({
    pageKey: 0,
    rowsPerPage: 10,
  });
  const now = new Date();
  const init_day_end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  const [dayBegin, setDayBegin] = useState(new Date(2019, 1, 1));
  const [dayEnd, setDayEnd] = useState(init_day_end);
  const [temporality, setTemporality] = useState("fromBeginning");
  const [selectedRow, setSelectedRow] = useState(null);


  ////////////////////////// FILTERS //////////////////////


  useEffect(() => {
    const today = new Date();
    let dayBegin;
    let dayEnd = today;
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);
    switch (temporality) {
      case "fromBeginning":
        dayBegin = new Date(account.date_creation);
        break;
      case "thisMonth":
        dayBegin = new Date(today.getFullYear(), today.getMonth(), 1); //first day of month
        break;
      case "previousMonth":
        dayBegin = new Date(today.getFullYear(), today.getMonth() - 1, 1); //first day of previous month
        dayEnd = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, -1); //last day of previous month
        break;
      case "interval":
        dayBegin = localStorage.filterDayBegin ? new Date(localStorage.filterDayBegin) : thirtyDaysAgo;
        dayEnd = localStorage.filterDayEnd ? new Date(localStorage.filterDayEnd) : today;
        break;
      default:
        console.error("Not valid date period");
    }
    setTemporality(temporality);
    setDayBegin(dayBegin);
    setDayEnd(dayEnd);
  }, [temporality]);


  ////////////////////////// TABLE ////////////////////// 


  const getStatusValue = useCallback((template, statistic) => {
    if (statistic) {
      return { 
        displayedValue: intl.messages["newsletterList.table.status.sent"],
        startAdornment: <div className={classes.statusIcon} style={{ backgroundColor: palette.accentGreen200 }}></div>
      };  
    }
  }, [intl]);

  const columns = useMemo(() => [
    { key: "name", name: intl.messages["newsletterList.table.head.name"] },
    { key: "createdDate", name: intl.messages["newsletterList.table.head.createdAt"] },
    { key: "date", name: intl.messages["newsletterList.table.head.date"] },
    { key: "status", name: intl.messages["newsletterList.table.head.status"] },
    { key: "avg_order", name: intl.messages["card.title.avg_order"] },
    { key: "emails", name: intl.messages["statistics.table.head.sentCount"], sortProperty: "rawValue" },
    { key: "open", name: intl.messages["statistics.table.head.openedPercentage"], sortProperty: "rawValue" },
    { key: "clics", name: intl.messages["statistics.table.head.clickedPercentage"], sortProperty: "rawValue" },
    { key: "orders", name: intl.messages["statistics.table.head.ordersCount"], sortProperty: "rawValue" },
    { key: "ca", name: intl.messages["statistics.table.head.ca"], sortProperty: "rawValue" },
    { key: "unsuscribe", name: intl.messages["card.title.conversion_rate"], sortProperty: "rawValue" },
    { key: "convertionRate", name: intl.messages["statistics.table.head.unsubscribedPercentage"], sortProperty: "rawValue" },
    { key: "icon", name: "" },
  ], [intl]);

  const rows = useMemo(() =>
  
    mjmlTemplates
      .filter(template => 
        template.dateSent && 
        statistics.find(elem => elem.newsletter_id === template.id) &&
        moment(template.dateSent).isBetween(dayBegin, dayEnd, null, "[]")
      )
      .map(template => {
        const statistic = statistics.find(elem => elem.newsletter_id === template.id);
        const date = template.dateSent || template.suspendedDate;
        const avg_order = statistic?.turnover / statistic?.nb_order || 0;
        return {
          id: template.id,
          customClass: classes.draftRow,
          statistic,
          values: {
            name: { displayedValue: template.name },
            createdDate: { displayedValue: moment(template.createdAt).format("DD/MM/YYYY") },
            date: { displayedValue: date ? moment(date).format("DD/MM/YYYY") : "-" },
            status: getStatusValue(template, statistic),
            avg_order: { rawValue: avg_order, displayedValue: getFormattedNumber(avg_order, account.currency, intl.locale), },
            emails: { rawValue: statistic?.send, displayedValue: getFormattedNumber(statistic?.send, "", intl.locale), },
            open: { rawValue: statistic?.open, displayedValue: getFormattedNumber(statistic?.open, "%", intl.locale), },
            clics: { rawValue: statistic?.click, displayedValue: getFormattedNumber(statistic?.click, "%", intl.locale), },
            orders: { rawValue: statistic?.nb_order, displayedValue: getFormattedNumber(statistic?.nb_order, "", intl.locale), },
            ca: { rawValue: statistic?.turnover || 0, displayedValue: getFormattedNumber(statistic?.turnover, account.currency, intl.locale) || 0 },
            conversionRate: { rawValue: statistic?.nb_conversion || 0, displayedValue: statistic?.nb_conversion || 0 },
            unsuscribe: { rawValue: statistic?.unsubscribe, displayedValue: getFormattedNumber(statistic?.unsubscribe, "%", intl.locale), },
            icon: { displayedValue: <div className={classes.arrowIconContainer}><i className="fa-solid fa-chevron-right"></i></div> }
          }
        };
      })
      .filter(row => !search || row && row.values.name.displayedValue?.includes(search)),
  [mjmlTemplates, getStatusValue, search, statistics, intl, dayBegin, dayEnd]);

  useEffect(() => {
    const tabSent = rows.filter(row => row?.values.status?.displayedValue === intl.messages["newsletterList.table.status.sent"] ).reverse();    
    setDisplayedData(tabSent);
  }, [rows, intl]);

  useEffect(() => {
    if (selectedRow) {
      const newRow = rows.find(({ id }) => id === selectedRow.id);
      setSelectedRow(newRow);
    }
  }, [rows, selectedRow]);


  ////// STATS CAROUSEL ///////

  useEffect(() => {
    let averageOpenRate = 0;
    let averageClick = 0;
    let averageUnsubscribe = 0;
    let averageConvertionRate = 0;
    let averageOrder = 0;


    const calculatedStats = displayedData.reduce((accumulator, row) => {
      accumulator.turnover += parseInt(row.values.ca.rawValue) || 0;
      accumulator.nbOrder += parseInt(row.values.orders.rawValue) || 0;
      accumulator.emailSend += row.values.emails.rawValue || 0;
      accumulator.openRate += row.values.open.rawValue || 0;
      accumulator.click += row.values.clics.rawValue || 0;
      accumulator.unsubscribe += row.values.unsuscribe.rawValue || 0;
      accumulator.conversionRate += row.values.conversionRate.rawValue || 0 ;

      return accumulator;
    }, { turnover: 0, nbOrder: 0, avgOrder: 0, emailSend: 0, openRate: 0, click: 0, unsubscribe: 0, conversionRate: 0});

    if(displayedData.length > 0) {
      averageOpenRate = calculatedStats.openRate / displayedData.length;
      averageClick = calculatedStats.click / displayedData.length;
      averageUnsubscribe = calculatedStats.unsubscribe / displayedData.length;
      averageConvertionRate = calculatedStats.conversionRate / displayedData.length;
      averageOrder = (calculatedStats.turnover / calculatedStats.nbOrder) || 0;
    }

    calculatedStats.turnover = getFormattedNumber(calculatedStats.turnover, account.currency, intl.locale);
    calculatedStats.openRate = (averageOpenRate).toFixed(2) + " %";
    calculatedStats.unsubscribe = (averageUnsubscribe).toFixed(2) + " %";
    calculatedStats.conversionRate = (averageConvertionRate).toFixed(2) + " %";
    calculatedStats.click = (averageClick).toFixed(2) + " %";
    calculatedStats.avgOrder = getFormattedNumber(averageOrder.toFixed(2), account.currency, intl.locale);
    setCalculatedStats(calculatedStats);
   
  }, [displayedData]);


  return (
    <>
      <div className={classes.filters}>
        <KiTextField
          placeholder={intl.messages["products.filters.search.placholder"]}
          type="whiteBg"
          autoComplete="off"
          name="template_search"
          handleChange={event => setSearch(event.target.value)}
        />
        <KiDropdown
          value={temporality}
          options={[
            { value: "fromBeginning", label: intl.messages["datePeriodList.fromBeginning"], },
            { value: "thisMonth", label: intl.messages["datePeriodList.thisMonth"], },
            { value: "previousMonth", label: intl.messages["datePeriodList.previousMonth"], },
            { value: "interval", label: intl.messages["datePeriodList.interval"], },
          ]}
          onChange={setTemporality}
          variant="table"
        />
        {
          temporality === "interval" && (
            <>
              <KiDatePicker
                value={dayBegin}
                onChange={setDayBegin}
              />
              <Translation id="home.statistics.to" />
              <KiDatePicker
                value={dayEnd}
                onChange={setDayEnd}
                minDate={dayBegin}
              />
            </>
          ) 
        }
      </div>

      <div className={classes.container}>
        <div className={classes.carouselStats}>
          <StatsCarousel
            stats={{
              turnover: calculatedStats.turnover,
              nb_order: calculatedStats.nbOrder,
              avgOrder: calculatedStats.avgOrder,
              send: calculatedStats.emailSend,
              open: calculatedStats.openRate,
              click: calculatedStats.click,
              conversion_rate: calculatedStats.conversionRate,
              unsubscribe: calculatedStats.unsubscribe,
            }}
          />

        </div>
        <div className={classes.newsletterList}>
          <KiTable
            columns={columns}
            rows={displayedData}
            total={rows.length}
            pagination={pagination}
            paginationType="item"
            savePagination={setPagination}
            account={account}
            onLineClick={setSelectedRow}
          />
        </div>
        <NewsletterEditPopup
          isOpen={!!selectedRow}
          row={selectedRow}
          close={() => setSelectedRow(null)}
          reload={() => reloadAccountContext()}
        />
      </div>
    </>
  );
};