import { useEffect, useState } from "react";
import { Translation } from "../../common/Translation";
import { CmsConnectingStateBadge } from "../../common/CmsConnectingStateBadge";
import classes from "./Integration.module.scss";

import Tooltip from "@mui/material/Tooltip";
import kiliba from "../../../public/logo-small.png";
import shopify from "../../../public/shopifyLogo.png";
import woocommerce from "../../../public/woocommerceLogo.png";
import prestashop from "../../../public/prestashopLogo.png";
import magento from "../../../public/magentoLogo.png";
import googleAnalytics from "../../../public/googleAnalyticsLogo.png";
import brevo from "../../../public/brevoLogo.png";
import mailChimp from "../../../public/mailChimpLogo.png";
import lock from "../../../public/greyLock.png";


export const MenuCard = ({platform, noCms, mainCms, openCmsCard, isSuspended, brevoConnected, mailChimpConnected, secondaryCmsRevoked}) => {
  
  const [logo, setLogo] = useState([]);
  const [mainCmsLock, setMainCmsLock] = useState(false);
  const [secondaryCmsLock, setSecondaryCmsLock] = useState(false);

  useEffect(() => {
    const platformLogos = {
      "Kiliba Connect": kiliba,
      Shopify: shopify,
      Prestashop: prestashop,
      Magento: magento,
      "Google Analytics": googleAnalytics,
      Mailchimp: mailChimp,
      Brevo: brevo,
      Woocommerce: woocommerce,
    };
    if (platform in platformLogos) {
      setLogo(platformLogos[platform]);
    }

    if((!noCms && ["Prestashop", "Shopify", "Magento", "Woocommerce"].includes(platform) && !mainCms )){
      setMainCmsLock(true);
    } else if (noCms && ["Kiliba Connect", "Brevo", "Google Analytics", "Mailchimp"].includes(platform)){
      setSecondaryCmsLock(true);
    }
  }, [platform,mainCms,noCms]);

  function isSecondaryCmsRevoked(platform) {
    return !!secondaryCmsRevoked.find(x => x === platform.toLowerCase());
  }

  return (
    <div onClick={() => openCmsCard(platform)} className={`${classes.card} ${ (!mainCmsLock && !secondaryCmsLock ) && classes.unlocked}`}>
      <div className={classes.logo} >
        <img style={{ width: "44px" }} alt={platform} src={logo} />
      </div>
      <div style={{ gridArea: "b" }}>
        { platform }
        <h4 className={classes.cardText}>
          {
            !noCms && mainCms === true ?
              <Translation id={"integration.menuCard.textConnected"} />
              :
              <Translation id={`integration.menuCard.${platform}.text`} />
          }
        </h4>
      </div>
      <div className={classes.leftCard} style={{ gridArea: "c" }}>
        {
          mainCms === true ?
            !noCms && isSuspended=== false ? 
              <CmsConnectingStateBadge isConnected={ true }/>
              : isSuspended &&
              <CmsConnectingStateBadge isConnected={ false }/>
            : (brevoConnected  && platform === "Brevo") ?
              <CmsConnectingStateBadge isConnected={ isSecondaryCmsRevoked(platform) ? false : true } isSecondaryCMS={true}/>
              : (mailChimpConnected && platform === "Mailchimp") &&
              <CmsConnectingStateBadge isConnected={ isSecondaryCmsRevoked(platform) ? false : true } isSecondaryCMS={true}/>
        }
        {
          (mainCmsLock || secondaryCmsLock) ?
            <Tooltip title={ mainCmsLock ? <Translation id="integration.tooltipLock"/> : secondaryCmsLock && <Translation id="integration.tooltipSecondaryLock"/>} placement="right">
              <img style={{ width: "20px" }} alt="" src={lock} />
            </Tooltip>

            :
            <i className={"fa-solid fa-chevron-right"}></i>
        }
      </div>
    </div>

    
  );
};
