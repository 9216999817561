import { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";

import { Translation } from "../common/Translation";
import { CustomButton } from "../common/Button/CustomButton";
import { useAuth } from "../../hooks/useAuth";
import { EventSystem } from "../../eventsystem/EventSystem";

import classes from "./NewsletterChooseProduct.module.scss";
import { NewsletterChooseProductInput } from "./NewsletterChooseProductInput";
import { isEqual } from "lodash";
import { ConfirmRegenNewsletterPopup } from "./ConfirmRegenNewsletterPopup";

export const NewsletterChooseProduct = ({ setSelectedProductsExceededPopupIsOpened, setStep, products = [], setProducts, defaultProducts, isFirstGenerationDone, setIsFirstGenerationDone, objective }) => {
  const auth = useAuth();  
  const intl = useIntl();

  const [productPlaceholder, setProductPlaceholder] = useState("");
  const [isRegenPopupOpen, setIsRegenPopupOpen] = useState(false);
  const [maxProducts, setMaxProducts] = useState(6);

  const getTitleFromJson = useCallback((title) => title[intl.locale] || title.fr || title.en || title.es || title.de || title.nl || "", [intl]);

  useEffect(() => {
    if (!productPlaceholder) {
      auth.fetch("/data/searchUsableProducts", {
        method: "POST",
        body: JSON.stringify({
          searchText: "",
        })
      }).then((result) => {
        if (result.length) {
          const product = result[0];
          setProductPlaceholder(getTitleFromJson(product.title));
        }
      });
    }
  }, [auth, productPlaceholder, getTitleFromJson]);

  useEffect(() => {
    if (objective && objective === "monoProduct") {
      setMaxProducts(1);
    }
  }, [objective]);

  const onDeleteProduct = id => {
    setProducts(products.filter(elem => elem.id_product !== id));
    // setProductSearch("");
    EventSystem.newNotification(
      "notification.action",
      intl.messages["newsletter.product.delete.notif"]
    );
  };

  const generateMinimalDalleImage = async () => {
    let url = "/dalle/generateMinimalDalleImage?";
    for(const index in products) {
      url += `productIds[${index}]=${products[index].id_product}&`;
    }
    try {
      const response = await auth.fetch(url, {}, {endpoint: "llm-hub"});
      return response;
    } catch {}
  };

  const handleNextStep = () => {
    generateMinimalDalleImage();
    setStep(prev => prev + 1);
  };

  const onNextStep = () => {
    if (isFirstGenerationDone && !isEqual(defaultProducts.map(product => product.id_product), products.map(product => product.id_product))) {
      setIsRegenPopupOpen(true);
    } else {
      handleNextStep();
    }
  };

  return (
    <div className={classes.productFormContainer}>
      <h1><Translation id={`newsletter.${objective}.product.title`} /></h1>
      <div className="text"><Translation id={`newsletter.${objective}.product.subtitle`} /></div>
      <div className={classes.productForm}>
        <div className="textMedium"><Translation id="newsletter.product.label" /></div>
        <NewsletterChooseProductInput 
          placeholder={`${intl.messages["newsletter.product.input.placeholder"]} ${productPlaceholder}`}
          disabled={products.length >= maxProducts}
          onAddProduct={product => {
            if (products.length < maxProducts && !products.find(_product => _product.id_product === product.id_product)) {
              setProducts([...products, product]);
            }
          }}
          onClick={() => products.length >= maxProducts && setSelectedProductsExceededPopupIsOpened(true)}
        />
        { objective !== "monoProduct" ? <div className={`text ${classes.inputInfo}`}>
          <Translation id={products.length ? "newsletter.product.limit.done" : "newsletter.product.limit"} variables={{ limit: maxProducts - products.length }} />
        </div> : null }
      </div>

      <div className={classes.selectedProductsContainer}>
        {products.map(product => (
          <div key={product.id_product} className={classes.selectedProductTag}>
            <i className="fa-solid fa-tags" />
            <span className="info">{getTitleFromJson(product.title)}</span>
            <i
              className={`fa-solid fa-times ${classes.selectedProductTagClose}`}
              onClick={() => onDeleteProduct(product.id_product)}
            />
            <div className={`info ${classes.selectedProductTagHover}`}>
              {getTitleFromJson(product.title)}
            </div>
          </div>
        ))}
      </div>

      <div className={classes.selectedProductsbtn}>
        <CustomButton
          type="secondary"
          size="lg"
          onClick={() => setStep(prev => prev - 1)}
          style={{ marginRight: 20 }}
        >
          <Translation id="dialog.back" />
        </CustomButton>
        <CustomButton
          size="lg"
          disabled={!products.length}
          onClick={onNextStep}
        >
          <Translation id="button.next2" />
        </CustomButton>
      </div>

      <ConfirmRegenNewsletterPopup 
        isOpen={isRegenPopupOpen}
        close={() => setIsRegenPopupOpen(false)}
        onCancel={() => {
          setProducts(defaultProducts);
          setIsFirstGenerationDone(true);
          setIsRegenPopupOpen(false);
        }}
        onConfirm={() => {
          setIsFirstGenerationDone(false);
          handleNextStep();
        }}
      />
    </div>
  );
};