import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { injectIntl } from "react-intl";
import { Tooltip } from "../Tooltip.js";

const iconKeys = {
  information: "info-circle",
  warning: "exclamation-triangle",
};

const styles = (theme) => ({
  icon: {
    fontSize: 14,
    fontWeight: 400,
    cursor: "help",
  },
});

export const InformationsIcon = withStyles(styles)(
  injectIntl(
    class InformationsIcon extends Component {
      render() {
        const { classes, text, type = "information" } = this.props;

        return (
          <Tooltip title={text}>
            <i className={`fal fa-${iconKeys[type]} ${classes.icon}`}></i>
          </Tooltip>
        );
      }
    }
  )
);
