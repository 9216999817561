export const styles = {
  usecasesByCategory: {
    display: "grid",
    gridTemplateAreas: "\"a a a\" \"b c d\" \"e f g\"",
    gridGap: 30,
    padding: "10px 20px",
  },
  category: {
    "&:nth-child(2)": {
      gridArea: "b",
    },
    "&:nth-child(3)": {
      gridArea: "c",
    },
    "&:nth-child(4)": {
      gridArea: "d",
    },
    "&:nth-child(5)": {
      gridArea: "e",
    },
    "&:nth-child(6)": {
      gridArea: "f",
    },
    "&:nth-child(7)": {
      gridArea: "g",
    },
  },
  categoryLabel: {
    maxWidth: 200,
    marginBottom: 20,
  },
  usecase: {
    fontSize: 14,
  }
};
