import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CustomButton } from "../common/Button/CustomButton";
import { useHistory } from "react-router-dom";

import { styles } from "./FirstSteps.styles";
import { useAuth } from "../../hooks/useAuth";
import { Step } from "./Step";
import { AccountContextBehaviorSubject$ } from "../../contexts/context";

const useStyles = makeStyles(styles);

export function FirstSteps() {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const auth = useAuth();

  const [stepRegister, setStepRegister] = useState(null);
  const [step3Done, setStep3Done] = useState(false);
  const [createdFrom, setCreatedFrom] = useState(null);
  const [showFirstStep, setShowFirstStep] = useState(false);
  const [pageKey, setPageKey] = useState(0);

  const { account, config } = AccountContextBehaviorSubject$.getValue();

  useEffect(() => {
    (async () => {
      setStepRegister(account.step_register);
      setCreatedFrom(account.createdFrom);

      const usecaseActivated = account.usecases && 
      account.usecases.some(usecase => usecase.launch_date != null);

      setStep3Done(usecaseActivated);

      setShowFirstStep(
        account.step_register < 4 ||
        !usecaseActivated ||
        config?.isCMSConnected
      );
    })();
  }, [auth]);

  useEffect(() => {
    if (stepRegister < 3 && createdFrom !== "shopify") setPageKey(0);
    else if (stepRegister < 4) setPageKey(1);
    else if (stepRegister >= 4) setPageKey(2);
  }, [stepRegister, createdFrom]);

  function renderSteps() {
    const steps = [];
    for (let i = 0; i < 3; i++) {
      const status = {
        active: pageKey === i,
        done: stepRegister - 2 > i,
        disabled: stepRegister === 2 && i > 0,
      };

      if (i === 2) {
        status.done = step3Done;
      }

      steps.push(
        <Step
          pageKey={pageKey}
          status={status}
          index={i}
          onClick={(status.disabled || status.done) ? null : () => setPageKey(i)}
        />
      );
    }
    return steps;
  }

  function renderStepsContent() {
    let stepsContent;

    if (pageKey === 0)
      stepsContent = <div className={classes.stepContent}>
        <div className="text">{intl.messages["firstSteps.stepDescription0"]}</div>
        <div className={classes.stepDescriptionButtonAndLink}>
          <CustomButton
            size="sm"
            onClick={() => history.push("/on_boarding")}
          >
            {intl.messages["button.connectMyShop"]}
          </CustomButton>
          {/* temporarily disabled */}
          {/* <a className="link" href >
            {intl.messages["firstSteps.checkTuto"]}
          </a> */}
        </div>
      </div>;
    if (pageKey === 1)
      stepsContent = <div className={classes.stepContent}>
        <div className="text">{intl.messages["firstSteps.stepDescription1"]}</div>
        <CustomButton
          size="sm"
          onClick={() => history.push("/template_customization")}
        >
          {intl.messages["button.personalizeTemplate"]}
        </CustomButton>
      </div>;

    if (pageKey === 2)
      stepsContent = <div className={classes.stepContent}>
        <div className="text">{intl.messages["firstSteps.stepDescription2"]}</div>
        <div className={classes.stepDescriptionButtonAndLink}>
          <CustomButton
            size="sm"
            onClick={() => history.push("/usecase")}
          >
            {intl.messages["button.activeUsecase"]}
          </CustomButton>
          {/* temporarily disabled */}
          {/* <a className="link" href>
            {intl.messages["firstSteps.checkTuto"]}
          </a> */}
        </div>
      </div>;

    if (pageKey > 3)
      stepsContent = <div className={classes.stepContent}>
        <div className="text">{intl.messages["firstSteps.stepDescriptionDone"]}</div>
        <a
          href
          className="link"
          onClick={() => history.push("/template_customization")}
        >
          {intl.messages["firstSteps.discoverUsecases"]}
        </a>
      </div>;

    return stepsContent;
  }

  const stepsDisplayed = renderSteps();
  const stepsContentDisplayed = renderStepsContent();

  return (
    showFirstStep
      ? (
        <div className={classes.firstSteps}>
          <h2 className={classes.title}>
            {intl.messages["firstSteps.start"]}
          </h2>
          <div className={classes.content}>
            <div className={classes.steps}>
              {stepsDisplayed}
            </div>
            <div className={classes.stepDescription}>
              {stepsContentDisplayed}
            </div>
          </div>
        </div>
      )
      : null
  );
}
