// EventSystem permet le partage de données entre composants.
class EventSystem0 {
  constructor() {
    this.queue = {};
  }

  /*
		à utiliser dans le callback après la mise à jour d'une donnée commune à plusieurs composants
		exemple:
		this.setState({modification},function(){
			EventSystem.publish('cle',valeur_modifiée)
		})
	*/
  publish(event, data) {
    //console.log(event)
    //console.log('publish ' + event + " : " + data)
    const queue = this.queue[event];
    if (typeof queue === "undefined") {
      return false;
    }
    this.queue[event].forEach(function (func) {
      func(data);
    });

    return true;
  }

  /*
		à appeler une seule fois du composant qui écoute la mise à jour de la donnée.
		Callback représente la fonction appellée dans le composant qui écoute après la mise à jour.
	*/
  subscribe(event, callback) {
    //console.log('subscribe ' + event + " : " + callback)
    if (typeof this.queue[event] === "undefined") {
      this.queue[event] = [];
    }
    this.queue[event].push(callback);
  }

  //  the callback parameter is optional. Without it the whole event will be removed, instead of
  // just one subscibtion. Enough for simple implementations
  unsubscribe(event, callback) {
    const queue = this.queue;

    if (typeof queue[event] !== "undefined") {
      if (typeof callback === "undefined") {
        delete queue[event];
      } else {
        this.queue[event] = queue[event].filter(function (sub) {
          return sub !== callback;
        });
      }
    }
  }

  newNotification(type, message) {
    // console.log(message + " : " + type)
    this.publish(type, message);
  }

  details() {
    console.log(this.queue);
  }
}

export const EventSystem = new EventSystem0();
