import React, { Component } from "react";

import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

import { Checkbox } from "../../../../../../common/Input/Checkbox.js";
import { TextField } from "../../../../../../common/Input/TextField.js";

import { styles } from "./styles.js";

export const MinimumAmountInput = withStyles(styles)(
  injectIntl(
    class extends Component {
      getMinimumAmountLabel() {
        const {
          classes,
          intl,
          getError,
          promoCodeTemplateWrapper,
          setPromoCodeTemplateWrapperProp,
        } = this.props;

        return (
          <span className={classes.inputGroupContainer}>
            <span>
              {
                intl.messages[
                  "usecase.promoCodeTemplateEditor.options.minimumAmount.0"
                ]
              }
            </span>
            <TextField
              value={promoCodeTemplateWrapper.minimumAmount}
              errorMessage={
                getError("minimumAmount") && getError("minimumAmount").message
              }
              disabled={!promoCodeTemplateWrapper.mustUseMinimumAmount}
              placeholder={
                intl.messages[
                  "usecase.promoCodeTemplateEditor.options.minimumAmount.placeholder"
                ]
              }
              onChange={(event) => {
                let value =
                  event.target.value === "" ? null : Number(event.target.value);
                if (value < 0) value = 0;
                setPromoCodeTemplateWrapperProp("minimumAmount", value);
                setPromoCodeTemplateWrapperProp(
                  "mustUseMinimumAmount",
                  value > 0
                );
              }}
              type="number"
              min={0}
              style={{ width: 192 }}
              marginIsEnabled={false}
            />
          </span>
        );
      }

      render() {
        const { promoCodeTemplateWrapper, setPromoCodeTemplateWrapperProp } =
          this.props;

        return (
          <Checkbox
            checked={promoCodeTemplateWrapper.mustUseMinimumAmount}
            onChange={(event) =>
              setPromoCodeTemplateWrapperProp(
                "mustUseMinimumAmount",
                event.target.checked
              )
            }
            label={this.getMinimumAmountLabel()}
          />
        );
      }
    }
  )
);
