import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Dialog } from "../common/Dialog.js";
import { CustomButton } from "../common/Button/CustomButton";
import { injectIntl, FormattedMessage } from "react-intl";
import { styles } from "../../styles/accountSetup.js";

export const WaitingCmsModal = withStyles(styles)(
  injectIntl(
    class WaitingCmsModal extends Component {
      render() {
        const { classes } = this.props;

        return (
          <Dialog PaperProps={{ style: { borderRadius: 20 } }} close={this.props.closeWaitingModal} isOpen={true}>
            <div className={classes.waitingModal}>
              <div className={classes.modalWording}>
                <FormattedMessage id="usecaseDialog.title" defaultMessage="LastStep" />
              </div>
              <div className={classes.modalSubWording}>
                <FormattedMessage id="usecaseDialog.subTitle" defaultMessage="LastStepCgv" />
              </div>
              <div className={classes.modalSubWording}>
                <FormattedMessage id="usecaseDialog.text0" defaultMessage="LastStepCgv" />
                <span className={classes.bigger}><FormattedMessage id="usecaseDialog.text1" defaultMessage="LastStepCgv" /></span>
                <FormattedMessage id="usecaseDialog.text2" defaultMessage="LastStepCgv" />
              </div>
              <CustomButton onClick={this.props.closeWaitingModal} type={"primary"} >
                <span className={classes.buttonWording}>
                  <FormattedMessage id="button.letsgo" defaultMessage="LetsGo" /> !
                </span>
              </CustomButton>
            </div>
          </Dialog>
        );
      }
    }
  )
);

WaitingCmsModal.propTypes = {
  classes: PropTypes.object.isRequired,
};
