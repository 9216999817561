import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import { TextField } from "../common/Input/TextField.js";
import { injectIntl, FormattedMessage } from "react-intl";
import { SubmitButton } from "../common/Button/SubmitButton";
import { CustomButton } from "../common/Button/CustomButton";

import { AuthService } from "../AuthService";
import { ResultLabelTO } from "../ResultLabelTO.js";

import resetPasswordImg from "../../public/resetPassword.png";

import { styles } from "./style.js";
import { ResetPasswordEmailSentPopup } from "./ResetPasswordEmailSentPopup.js";

export const CallResetPassword = withStyles(styles)(
  injectIntl(
    class CallResetPassword extends Component {
      constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.leaveFocus = this.leaveFocus.bind(this);
        this.changeUrl = this.changeUrl.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.Auth = new AuthService();
        this.state = {
          email: "",
          password: "",
          error_password: false,
          global_error: false,
          error_text: "",
          redText: true,
          showPopup: false,
          errorSpam: false,
          isLoading: false,
        };
      }

      UNSAFE_componentWillMount() {
        if (this.Auth.loggedIn()) {
          this.props.history.push("/");
        }
      }

      validateEmail(email) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      leaveFocus = (event) => {
        this.setState({ hidden: false });
      };
      handleFocus = (event) => {
        this.setState({ hidden: true });
      };

      changeUrl = (url) => (event) => {
        this.props.history.push(url);
      };

      handleSubmit(e) {
        e.preventDefault();
        this.setState({
          global_error: false,
          redText: false,
          error_text: "",
          errorSpam: false,
          isLoading: true,
        });
        this.Auth.forgotPassword(this.state.email)
          .then((res) => {
            if (this.state.showPopup) {
              this.setState({
                showPopup: false,
                isLoading: false,
              }, () => setTimeout(() => this.setState({ showPopup: true }), 500));
            } else {
              this.setState({
                showPopup: true,
                isLoading: false,
              });
            }            
          })
          .catch((err) => {
            if (err.name === "LimitExceededException") {
              this.setState({
                errorSpam: true,
                showPopup: true,
                isLoading: false,
              });
            } else {
              this.setState({
                global_error: true,
                redText: true,
                error_text: "error.resetPassword_email_not_found",
                isLoading: false,
              });
            }
          });
      }

      render() {
        const { classes } = this.props;

        return (
          <div className={classes.root}>
            <Paper className={classes.mainwindow}>
              <Grid
                container
                item
                justify-xs-center="true"
                xs={12}
                sm={12}
                spacing={0}
              >
                <div className={classes.successNotifContainer}>
                  <ResultLabelTO
                    visible={this.state.global_error && !this.state.redText}
                    style={{
                      width: "40%",
                      marginLeft: "30%",
                      marginTop: "40px",
                    }}
                    label="ok.resetPassword_check_email"
                    inactive_style={{ display: "none" }}
                  />
                </div>
                <Grid item xs={6}>
                  <div className={classes.confirmModalContainer}>
                    <div className={classes.confirmModalTitle}>
                      <FormattedMessage id="callResetPassword.welcome" />
                    </div>

                    <p className={classes.confirmModalTitleContent}>
                      <FormattedMessage id="callResetPassword.slogan" />
                    </p>

                    <div className={classes.resetPasswordMarginUp}>
                      <div className={classes.error}>
                        {this.state.global_error && this.state.redText && (
                          <FormattedMessage id={this.state.error_text} />
                        )}
                        &nbsp;
                      </div>
                      <form onSubmit={this.handleSubmit}>
                        <TextField
                          value={this.state.email}
                          onChange={(event) =>
                            this.setState({ email: event.target.value })
                          }
                          type="email"
                          autoFocus
                          error={this.state.global_error && this.state.redText}
                        />
                        <div style={{ marginTop: "30px" }}>
                          <CustomButton size="lg" loading={this.state.isLoading}>
                            <FormattedMessage id="button.callResetpass" />
                          </CustomButton>
                        </div>
                      </form>
                    </div>

                    <div className={classes.confirmModalText}>
                      <FormattedMessage id="callResetPassword.still_not_registered" />
                      <a
                        href
                        rel="noreferrer"
                        onClick={this.changeUrl("/register")}
                        className={classes.callResetPasswordHyperText}
                      >
                        <FormattedMessage
                          id="callResetPassword.create_account"
                          defaultMessage="Welcome"
                        />
                      </a>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.confirmModalRightWindow}>
                    <img
                      className={classes.slide}
                      alt=""
                      src={resetPasswordImg}
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
            <ResetPasswordEmailSentPopup
              isOpen={this.state.showPopup} 
              close={() => this.setState({ showPopup: false })}
              onResend={this.handleSubmit}
              error={this.state.errorSpam}
            />
          </div>
        );
      }
    }
  )
);

CallResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};
