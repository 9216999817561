import React, { Component } from "react";
import { injectIntl } from "react-intl";

import { withStyles } from "@material-ui/core/styles";

import { HtmlCustomizerTextField } from "./HtmlCustomizerInputs/HtmlCustomizerTextField/HtmlCustomizerTextField.js";
import { HtmlCustomizerImage } from "./HtmlCustomizerInputs/HtmlCustomizerImage/HtmlCustomizerImage.js";
import { HtmlCustomizerWordingProposals } from "./HtmlCustomizerInputs/HtmlCustomizerWordingProposals/HtmlCustomizerWordingProposals.js";
import { HtmlCustomizerWordingAiGenerated } from "./HtmlCustomizerInputs/HtmlCustomizerWordingAiGenerated/HtmlCustomizerWordingAiGenerated.js";

const styles = (theme) => ({
  htmlCustomizer: {
    position: "relative",
  },
  inputWrapper: {
    position: "absolute",
    zIndex: 1,
  },
});

const propertyKeyResizable = ["generatedWordings", "aiGeneratedWordings"];

/**
 * Html customization interface.
 * It provides an html preview based on @prop htmlString and a set of inputs on top of it based on @prop customizableParts.
 *
 * Explanation of the lifecycle of this component:
 * 1. The component renders with an empty div#htmlCustomizerWrapper that will hold the template.
 * 2. When mounted (componentDidMount) or when @prop htmlString has changed (shouldComponentUpdate), the following happens:
 *   2.1 Create a DOM structure based on @prop htmlString and append it to div#htmlCustomizerOriginalTemplate
 *   2.2 Create a set of inputs for updating the DOM created previously. The configuration of this layer is described in @prop customizableParts.
 * 3. When @prop customizableParts changes:
 *   3.1 Update the DOM based on @prop customizableParts. Note that the inputs are uncontrolled, so we don't assign them a new value. For more informations check the component.
 *
 * The notion of "original element" refers to an element in the template that should be customizable.
 */
export const HtmlCustomizer = injectIntl(withStyles(styles)(
  class HtmlCustomizer extends Component {
    constructor(props) {
      super(props);

      this.detectWhenImagesAreLoaded =
        this.detectWhenImagesAreLoaded.bind(this);
      this.updateCustomizableElements =
        this.updateCustomizableElements.bind(this);
      this.initRefs = this.initRefs.bind(this);

      this.initRefs();

      this.state = {
        htmlString: null,
        images: [],
        imagesAreLoaded: false,
        headbandImgFile: undefined,
      };
    }

    initRefs() {

      const propertyKeyResizable = ["generatedWordings", "aiGeneratedWordings"];

      this.generatedWordingsRefs = 
        this.props.customizableParts.reduce((acc, {selector, customizableProperties}) => {
          if (propertyKeyResizable.find(key => !!customizableProperties[key])) {
            acc[selector] = React.createRef();
          }
          return acc;
        }, {});
    }

    getNewHtml(htmlString) {
      const parser = new DOMParser();
      return parser.parseFromString(htmlString, "text/html");
    }

    // create a DOM structure based on prop htmlString and incorporate it into the document
    createHtml() {
      const { htmlString } = this.props;

      let html = document.querySelector("#customizableHtmlBody");
      if (html) html.remove();

      html = this.getNewHtml(htmlString.replace("#mailBody", "#customizableHtmlBody"));
      html.body.id = "customizableHtmlBody";
      document
        .querySelector("#htmlCustomizerOriginalTemplate")
        .append(html.querySelector("html"));
    }

    createHtmlCustomizer() {
      const { htmlString } = this.props;

      this.createHtml();

      // once the preview is created, detect when its images are loaded. This is important since the position of an element inside
      // the template depend on the presence of the images, which loading may finish after the preview is rendered
      const images = Array.from(
        document.querySelectorAll("#htmlCustomizerOriginalTemplate img")
      );
      this.setState({ htmlString, images }, this.detectWhenImagesAreLoaded);
    }

    // detect when images loading is finished, either succesfully or with error
    detectWhenImagesAreLoaded() {
      for (let i = 0; i < this.state.images.length; i++) {
        const image = this.state.images[i];

        const imageLoadCallback = () => {
          const imagesAreLoaded =
            this.state.images
              .map((stateImage) => stateImage.complete)
              .indexOf(false) === -1;
          if (imagesAreLoaded) {
            this.setState({ imagesAreLoaded: true });
          }
        };

        image.onload = imageLoadCallback;
        image.onerror = imageLoadCallback;
      }
    }

    componentDidMount() {
      this.createHtmlCustomizer();
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.htmlString !== this.state.htmlString) {
        this.createHtmlCustomizer();
        this.initRefs();
      }
    }

    static getDerivedStateFromProps(props, state) {
      if (props.htmlString !== state.htmlString) {
        state.imagesAreLoaded = false;
      }
      return state;
    }

    updateCustomizableElements() {
      const { customizableParts } = this.props;

      customizableParts.forEach((customizablePart, customizablePartKey) => {
        const customizableElement = document.querySelector(
          customizablePart.selector
        );

        if (!customizableElement) return;

        Object.keys(customizablePart.customizableProperties).forEach(
          (customizablePropertyKey) => {
            const customizableProperty =
              customizablePart.customizableProperties[customizablePropertyKey];

            if (propertyKeyResizable.includes(customizablePropertyKey)) {
              customizableElement.style["margin-bottom"] = Math.ceil(this.generatedWordingsRefs[customizablePart.selector].current?.getBoundingClientRect().height + 10) + "px";
              customizableElement.style["display"] = "block";
            }

            // update value
            const value =
              customizableProperty.value === null ||
                customizableProperty.value === undefined
                ? customizableElement.innerHTML
                : customizableProperty.value;

            customizableElement.innerHTML = value;

            // update style
            const customizableElementInnerHtml = customizableElement.innerHTML;
            customizableElement.innerHTML = customizableProperty.placeholder;
            customizableElement.style.minWidth =
              customizableElement.offsetWidth + "px";
            customizableElement.style.minHeight =
              customizableElement.offsetHeight + "px";
            customizableElement.innerHTML = customizableElementInnerHtml;

            if (customizablePropertyKey === "generatedWordings") {
              customizableElement.style.minWidth = "490px";
            }
          }
        );
      });
    }

    // adds a layers on top of the preview that contains inputs for customizing the template based on @prop customizableParts
    renderInputs() {
      const {
        keywords,
        customizableParts,
        classes,
        templateName,
        isWordingsLoading,
        wordingsGenerationHasError,
        usecaseName,
        intl,
        isFirstWordingGeneration,
        onFirstGenerationEnds,
        language,
      } = this.props;

      const inputs = [];

      customizableParts.forEach((customizablePart, customizablePartKey) => {
        const customizableElement = document.querySelector(
          customizablePart.selector
        );
        if (!customizableElement) return;
        Object.keys(customizablePart.customizableProperties).forEach(
          (customizablePropertyKey) => {
            const customizableProperty =
              customizablePart.customizableProperties[customizablePropertyKey];

            const containerBoundingRect = document
              .querySelector("#htmlCustomizerOriginalTemplate")
              .getBoundingClientRect();
            const customizableElementBoundingRect =
              customizableElement.getBoundingClientRect();
            const inputWrapperStyle = {
              top:
                customizableElementBoundingRect.top - containerBoundingRect.top,
              left:
                customizablePropertyKey !== "image"
                  ? customizableElementBoundingRect.left -
                  containerBoundingRect.left
                  : 110,
              width: customizableElementBoundingRect.width + "px", // width is used for centering the tooltip
              height: customizableElementBoundingRect.height
            };

            const value =
              customizableProperty.value === null ||
                customizableProperty.value === undefined
                ? customizableElement.innerHTML
                : customizableProperty.value;

            // give the input the same style than the customizable element
            const customizableElementComputedStyle =
              window.getComputedStyle(customizableElement);
            const inputStyle = {
              fontSize: customizableElementComputedStyle.fontSize,
              fontFamily: customizableElementComputedStyle.fontFamily,
              fontWeight: customizableElementComputedStyle.fontWeight,
              // lineHeight: customizableElementComputedStyle.lineHeight,
              letterSpacing: customizableElementComputedStyle.letterSpacing,
              textAlign: customizableElementComputedStyle.textAlign,
              color: customizableElementComputedStyle.color,
            };
            customizableElement.style.visibility = "hidden";

            inputs.push(
              <div
                id={`customizablePart${customizablePartKey}-${customizablePropertyKey}`}
                key={
                  "part" +
                  customizablePartKey +
                  "prop" +
                  customizablePropertyKey
                }
                className={classes.inputWrapper}
                style={inputWrapperStyle}
              >
                {customizablePropertyKey === "wording" ? (
                  <HtmlCustomizerTextField
                    value={value}
                    setValue={value => customizableProperty.setValue(value)}
                    style={inputStyle}
                    placeholder={customizableProperty.placeholder}
                    keywords={keywords}
                  />
                ) : null}
                {
                  customizablePropertyKey === "generatedWordings"
                    ?
                    (
                      <>
                        <HtmlCustomizerWordingProposals
                          style={{
                            top: inputWrapperStyle.height,
                            width: inputWrapperStyle.width,
                          }}
                          ref={this.generatedWordingsRefs[customizablePart.selector]}
                          onResize={() => this.forceUpdate()}
                          usecaseName={templateName}
                          value={value}
                          setGenerated={customizableProperty.setGenerated}
                          setValue={value => customizableProperty.setValue(value)}
                          isGenerated={customizableProperty.isGenerated}
                          originalPromptVersion={customizableProperty.promptVersion}
                          inputStyle={inputStyle}
                          keywords={keywords}
                          isWordingsLoading={isWordingsLoading}
                          hasError={wordingsGenerationHasError}
                          intl={intl}
                          partKey={customizableProperty.key}
                          language={language}
                        />
                      </>
                    )
                    : null
                }
                {
                  customizablePropertyKey === "aiGeneratedWordings" ?
                    <HtmlCustomizerWordingAiGenerated 
                      style={{
                        top: inputWrapperStyle.height,
                        width: inputWrapperStyle.width,
                      }}
                      ref={this.generatedWordingsRefs[customizablePart.selector]}
                      onResize={() => this.forceUpdate()}
                      value={value}  
                      setValue={value => customizableProperty.setValue(value)}
                      inputStyle={inputStyle}
                      keywords={keywords}
                      isWordingsLoading={isWordingsLoading}
                      isFirstWordingGeneration={isFirstWordingGeneration}
                      onFirstGenerationEnds={onFirstGenerationEnds}
                      intl={intl}
                      originalPromptVersion={customizableProperty.promptVersion}
                      generatedWordingCreatedDate={customizableProperty.generatedWordingCreatedDate}
                      setGenerated={customizableProperty.setGenerated}
                      usecaseName={usecaseName}
                      language={language}
                      history={customizableProperty.generatedWordingHistory}
                      hasError={wordingsGenerationHasError}
                    /> : null
                }
                {customizablePropertyKey === "image" ? (
                  <HtmlCustomizerImage
                    setValue={(value) => customizableProperty.setValue(value)}
                    headbandImgUrl={value ? value : null}
                    setHeadbandImgFile={(value) =>
                      customizableProperty.setHeadbandImgFile(value)
                    }
                    link={customizableProperty.link}
                    setHeadbandLink={customizableProperty.setHeadbandLink}
                  />
                ) : null}
              </div>
            );
          }
        );
      });

      return <div className={classes.inputs}>{inputs}</div>;
    }

    render() {
      const { classes } = this.props;

      let inputs = null;

      // in the template, the position of an element may depend on the presence of images,
      // which loading may finish after the preview is rendered, therefore it is required to wait
      // until all images in the preview are loaded before rendering the inputs
      if (this.state.imagesAreLoaded) {
        this.updateCustomizableElements();
        inputs = this.renderInputs();
      }

      return (
        <div className={classes.htmlCustomizer}>
          {inputs}
          <div
            id="htmlCustomizerOriginalTemplate"
            data-cy="html-customizer-original-template"
          ></div>
        </div>
      );
    }
  }
));
