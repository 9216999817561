const LABEL = "footer1";

export const footer1 = (logoUrl, blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, isLight, { fontFamily, footerColor, senderEmail }) => ({
  label: LABEL,
  block: () => {

    const mjml = `
      <mj-wrapper full-width="full-width"  background-color="${footerColor}" data-gjs="kilibaBlock" padding-top="0" padding-bottom="0">

        <mj-section padding-top="20px" padding-bottom="5px">
          <mj-column padding-left="0px" padding-right="0px" padding-top="0px" padding-bottom="0px"  >
            <mj-image width="150px" css-class="footer1-image" src="${logoUrl}"></mj-image>
            <mj-text padding-left="70px" padding-right="70px" color=${ isLight ? "black" : "white"} padding-top="5px" padding-bottom="0px" css-class="footer1-text1" font-family="${fontFamily}" font-size="14px" align="center">
              {{title1}}
            </mj-text>
            <mj-text padding-left="70px" padding-right="70px" color=${ isLight ? "black" : "white"} padding-top="5px" padding-bottom="0px" css-class="footer1-text1" font-family="${fontFamily}" font-size="14px" align="center">
              {{title2}}
            </mj-text>
            </mj-text><mj-text padding-left="70px" padding-right="70px" color=${ isLight ? "black" : "white"} padding-top="5px" padding-bottom="8px" css-class="footer1-text1" font-family="${fontFamily}" font-size="14px" align="center">
              <a style="color: ${ isLight ? "black" : "white"}" href="${senderEmail}">${senderEmail}</a>
            </mj-text>
          </mj-column>
        </mj-section>

        <mj-section padding-top="0px" padding-bottom="0px">
          <mj-column>
            <mj-social icon-size="25px" mode="horizontal">
                ${blockRsInstagram}
                ${blockRsFacebook}
                ${blockRsTwitter}
                ${blockRsYoutube}
                ${blockRsDiscord}
                ${blockRsLinkedin}
                ${blockRsPinterest}
                ${blockRsTiktok}
            </mj-social>
          </mj-column>
        </mj-section>

        <mj-section padding-top="0px" padding-bottom="20px" padding-left="30px" padding-right="30px">
          <mj-column css-class="footer1-link">
            <mj-text padding-left="0px" padding-right="0px" padding-top="10px" padding-bottom="0px" font-family="${fontFamily}" color=${ isLight ? "black" : "white"} font-weight="400" font-size="12px" align="center">
              {{foot}}
              <a style="color:${isLight ? "black" : "white"}" href="https://app.thatsowl.com/unsubscribe"> {{link}} </a>
            </mj-text>
          </mj-column>
        </mj-section>

      </mj-wrapper>
    `;

    const wordings = {
      title1: {
        fr: "Une question ? Une suggestion ?",
        en: "Have a question? A suggestion ?",
        es: "¿Tiene alguna pregunta? ¿Una sugerencia ?",
        de: "Haben Sie eine Frage? Haben Sie eine Anregung ?",
        nl: "Heb je een vraag? Een suggestie ?",
        it: "Avete una domanda? Un suggerimento ?",
        pt: "Tem uma pergunta? Uma sugestão ?",
      },
      title2: {
        fr: "Nous sommes à votre écoute à cette adresse",
        en: "Contact us at the following address",
        es: "Póngase en contacto con nosotros en la siguiente dirección",
        de: "Kontaktieren Sie uns unter folgender Adresse",
        nl: "Neem contact met ons op via het volgende adres",
        it: "Contattateci al seguente indirizzo",
        pt: "Contacte-nos para o seguinte endereço",
      },
     
      foot: {
        fr: "Si vous ne souhaitez plus recevoir nos e-mails, ",
        en: "If you no longer wish to receive our emails, ",
        es: "Si no desea seguir recibiendo nuestros correos electrónicos, ",
        de: "Wenn Sie unsere E-Mails nicht mehr erhalten möchten, ",
        nl: "Als u onze e-mails niet langer wilt ontvangen",
        it: "Se non desiderate più ricevere le nostre e-mail",
        pt: "Se não quiser continuar a receber as nossas mensagens electrónicas, ",
      },
      link: {
        fr: "cliquez ici pour vous désinscrire.",
        en: "click here to unsubscribe.",
        es: "pulse aquí para darse de baja.",
        de: "klicken Sie hier, um sich abzumelden.",
        nl: "klik hier om u af te melden.",
        it: "clicca qui per annullare l'iscrizione.",
        pt: "clique aqui para cancelar a subscrição.",
      },
    };

    return {
      id: LABEL,
      label: LABEL,
      category: "footer:largefooter",
      activate: true,
      content: mjml,
      attributes: {
        wordings,
      }
    };
  },
  style: `
    .footer1-image img {
      margin: 0 auto;
    }
    .inlineSocialIcon {
      width:30px !important;
    }
  `,
});