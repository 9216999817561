import { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import moment from "moment-timezone";

import { AccountContextBehaviorSubject$ } from "../../../../contexts/context";
import { useAuth } from "../../../../hooks/useAuth";

import { styles } from "./UsecaseSelectionBlock.styles.js";
import image from "../../../../public/usecase_list_get_started.png";

const useStyles = makeStyles(styles);

export const UsecaseSelectionBlock = ({ onUsecaseClick, onGoToUsecasesList, onUsecasesSelected, showImg, showStatus }) => {
  const intl = useIntl();
  const classes = useStyles();
  const auth = useAuth();
  
  const [closestUpcomingUsecaseName, setClosestUpcomingUsecaseName] = useState(null);

  const usecaseIconNames = useMemo(() => ({
    blackFriday: "bolt",
    mothersDay: "flower-daffodil",
    fathersDay: "mustache",
    halloween: "jack-o-lantern",
    christmas: "tree-christmas",
    newYear: "champagne-glasses",
    backToSchool: "bus-school",
    valentinesDay: "calendar-heart",
    summerSales: "sun-bright",
    winterSales: "snowflake",
  }), []);

  const displayedUsecases = useMemo(() => [
    {
      name: "abandonedCarts",
      title: intl.messages["usecase.abandonedCarts"],
      iconName: "basket-shopping",
      description: intl.messages["usecases.description.abandonedCarts"],
      catchphrase: intl.messages["onBoarding.usecaseSelection.usecase0.catchphrase"],
    },
    {
      name: "booster",
      title: intl.messages["usecase.booster"],
      iconName: "rocket-launch",
      description: intl.messages["usecases.description.booster"],
      catchphrase: intl.messages["onBoarding.usecaseSelection.usecase1.catchphrase"],
    },
    {
      name: closestUpcomingUsecaseName,
      title: intl.messages[`usecase.${closestUpcomingUsecaseName}`],
      iconName: usecaseIconNames[closestUpcomingUsecaseName],
      description: intl.messages[`usecases.description.${closestUpcomingUsecaseName}`],
      catchphrase: intl.messages["onBoarding.usecaseSelection.usecase2.catchphrase"],
    },
  ], [closestUpcomingUsecaseName, intl, usecaseIconNames]);

  useEffect(() => {
    (async () => {
      function getUsecasesWithDateDiffs(usecase) {
        const dateDiffs = usecase.templateDatas.map(templateData => new Date(templateData.useSettings.date).getTime() - new Date());
        const smallestDateDiff = dateDiffs.reduce((dateA, dateB) => Math.min(dateA, dateB), Infinity);
        return {
          name: usecase.name,
          smallestDateDiff: smallestDateDiff,
        };
      }

      function sortBySmallestDateDiff(usecaseA, usecaseB) {
        if (usecaseA.smallestDateDiff > usecaseB.smallestDateDiff) return 1;
        else if (usecaseA.smallestDateDiff < usecaseB.smallestDateDiff) return -1;
        else return 0;
      }

      function filterOnlyUpcomingUsecases(usecase) {
        return usecase.smallestDateDiff >= 0;
      }

      const closestUsecase = (await auth.fetch("/api/usecase/getAll"))
        .usecases
        .filter(usecase => usecase.category === "events")
        .filter(usecase => usecase.templateDatas.find(templateData => templateData.useSettings.type === "fixedDate"))
        .filter(usecase => usecase.name !== "custom")
        .map(getUsecasesWithDateDiffs)
        .filter(filterOnlyUpcomingUsecases)
        .sort(sortBySmallestDateDiff)[0];

      setClosestUpcomingUsecaseName(closestUsecase?.name || "christmas");
    })();
  }, [auth, displayedUsecases]);

  useEffect(() => {
    if (onUsecasesSelected && displayedUsecases.every(({ name }) => !!name)) {
      onUsecasesSelected(displayedUsecases);
    }
  }, [displayedUsecases, onUsecasesSelected]);

  const getUsecaseHasLaunched = (launchDateString) => {
    if (!launchDateString) return false;

    const launchDate = new Date(launchDateString);
    const today = new Date().setHours(0, 0, 0, 0);

    return launchDate <= today;
  };

  const getLaunchDate = (idx) => {
    const { account } = AccountContextBehaviorSubject$.getValue();    
    return account.usecases?.find(({ name }) => name === displayedUsecases[idx].name)?.launch_date;
  };

  const getStatusString = (idx) => {
    const launchDate = getLaunchDate(idx);
    const localLaunchDate = moment(new Date(launchDate))
      .tz("Europe/Paris")
      .format("DD/MM");
    if (getUsecaseHasLaunched(launchDate)) {
      return intl.messages["usecaseCard.active"];
    } else {
      return localLaunchDate;
    }
  }; 

  const getStatusClass = (idx) => {
    const launchDate = getLaunchDate(idx);
    return getUsecaseHasLaunched(launchDate) ? classes.statusActif : classes.statusActifDate;
  };

  return (
    <>
      <div className={classes.cardsContainer}>
        {
          displayedUsecases.map((displayedUsecase, key) => {
            return (
              <div className={classes.cardContainer}>
                <div
                  data-test={`card-${key}`}
                  className={classes.card}
                  key={key}
                  onClick={() => onUsecaseClick(displayedUsecase.name)}
                >
                  <div className={classes.cardBody}>
                    <i className={`${classes.icon} fal fa-${displayedUsecase.iconName}`}></i>
                    <h3>{displayedUsecase.title}</h3>
                    <p className={`${classes.description} info`}>{displayedUsecase.description}</p>
                  </div>
                  <div className={classes.cardCatchphrase}>
                    <p className={`${classes.catchphrase} textMedium`}>{displayedUsecase.catchphrase}</p>
                  </div>                             
                  {showStatus && !!getLaunchDate(key) && 
                      <div className={classes.statusContainer}>
                        <span className={`${classes.status} ${getStatusClass(key)}`}>
                          {getStatusString(key)}
                        </span>
                      </div>
                  }
                </div>

                {showImg && key === displayedUsecases.length - 1 && 
                  <img src={image} alt="" className={classes.image} />}   
              </div>
            );
          })
        }
      </div>
      {!!onGoToUsecasesList && 
        <a
          data-test="goToUsecaseList"
          className={classes.goToUsecaseList}
          href
          onClick={onGoToUsecasesList}
        >
          {intl.messages["onBoarding.usecaseSelection.goToUsecaseList"]}
        </a>
      }
    </>
  );
};