import { withStyles } from "@material-ui/core";
import { Component } from "react";

import { styles } from "./PageBodyWrapper.styles.js";

class PageBodyWrapper extends Component {
  render() {
    const {
      classes,
      children,
      withStepper = false,
    } = this.props;

    return (
      <div className={`${classes.pageBodyWrapper} ${withStepper ? classes.withStepper : ""}`}>
        {children}
      </div>
    );
  }
}

const _PageBodyWrapper = withStyles(styles)(PageBodyWrapper);
export { _PageBodyWrapper as PageBodyWrapper };
