function urlHasShopifyContext(params) {
  return (
    Boolean(params.get("CMS")) &&
    Boolean(params.get("shop"))
  );
}

function getShopifyParams() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const hasShopifyParams = (
    urlHasShopifyContext(urlParams)
  );

  if (!hasShopifyParams) return null;

  const CMS = urlParams.get("CMS") ? window.atob(urlParams.get("CMS")) : null;
  const shopUrl = urlParams.get("shop") ? window.atob(urlParams.get("shop")) : null;


  return {
    CMS,
    shopUrl,
    rawShopUrl: urlParams.get("shop"),
    rawCMS: urlParams.get("CMS"),
    connectionType: "fromShopifyModule",
    createdFrom: "shopify"
  };
}

module.exports = {
  urlHasShopifyContext,
  getShopifyParams
};
