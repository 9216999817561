import {processStarsMjml} from "./review.tools";
const LABEL = "globalTrustpilot3";
const CATEGORY = "review:trustpilot";

export const globalTrustpilot3 = ({ rating, fontFamily, textColor, dominantColor }) => ({
  label: LABEL,
  category: CATEGORY,
  block: () => {
    if (!rating) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const starMjml = processStarsMjml("TP", rating);

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${dominantColor}" padding-top="0" padding-bottom="0" full-width="full-width">
        <mj-section padding-bottom="10px" padding-top="10px">
          <mj-group>            
            <mj-column width="39%" css-class="globalTrustpilot3-column-39" vertical-align="top">
              <mj-text data-readonly="true" font-family="${fontFamily}" color="${textColor}" align="right" padding-left="8px" padding-right="8px" padding-bottom="5px" padding-top="16px" line-height="14px" font-size="14px">{{text}}</mj-text>
            </mj-column>
            <mj-column width="22%" css-class="globalTrustpilot3-column-22" vertical-align="top">
              <mj-raw>
                <tr>
                  <td>
                    <div style="text-align:center;padding-top:16px">
                    ${starMjml}
                    </div>
                  </td>
                </tr>
              </mj-raw>
            </mj-column>
            <mj-column width="39%" css-class="globalTrustpilot3-column-39" vertical-align="top">
              <mj-image data-readonly="true" align="left" padding-left="8px" padding-right="8px" width="80px" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/trustpilot-logo.png"></mj-image>
            </mj-column>
          </mj-group>
        </mj-section>
      </mj-wrapper>
    `;

    const wordings = {
      text: rating > 4.2 ? {
        fr: "Excellent",
        en: "Excellent",
        es: "Excelente",
        de: "Ausgezeichnet",
        nl: "Uitstekend",
        it: "Eccellente",
        pt: "Excelente",
      } : {
        fr: "Bien",
        en: "Great",
        es: "Fino",
        de: "Gut",
        nl: "Fijn",
        it: "Fine",
        pt: "Ótimo",
      }
    };

    return {
      id: LABEL,
      label: LABEL,
      category: "review:trustpilot",
      activate: rating > 3.7,
      content: mjml,
      attributes: {
        wordings,
      }
    };
  },
  style: `
    .globalTrustpilot3-column-39 {
      width: 39% !important;
    }

    .globalTrustpilot3-column-22 {
      width: 22% !important;
    }
  `
});