import { CustomButton } from "../common/Button/CustomButton";
import { Dialog } from "../common/Dialog";
import { Translation } from "../common/Translation";

import classes from "./SelectedProductsExceededPopup.module.scss";

export const SelectedProductsExceededPopup = ({ isOpen, close, objective }) => {

  return (
    <Dialog
      isOpen={isOpen}
      close={close}
      style={{ padding: "40px 60px" }}
      PaperProps={{ style: { borderRadius: 15 } }}
    >
      <div className={classes.container}>
        <h4>
          <Translation id={objective !== "monoProduct" ? "selectedProductsExceededPopup.title" : "selectedMonoProductExceededPopup.title" } />
          </h4>
        <div className={`info ${classes.subtitle}`}>
        <Translation id={objective !== "monoProduct" ? " selectedProductsExceededPopup.subtitle" : "selectedMonoProductExceededPopup.subtitle" } /></div>
        <CustomButton
          type="primary"
          size="xs"
          onClick={close}
        >
          <Translation id="button.understand" />
        </CustomButton>
      </div>
    </Dialog>
  );
};