import { injectIntl } from "react-intl";
import { makeStyles, darken } from "@material-ui/core/styles";

import { htmlCustomizerTooltipStyleValues } from "../htmlCustomizerTooltipStyleValues.js";
import { palette } from "../../../../../../styles/palette";

const styles = theme => ({
  button: {
    fontSize: 16,
    backgroundColor: htmlCustomizerTooltipStyleValues.tooltipBackgroundColor,
    border: "none",
    borderRadius: 4,
    color: palette.purple,
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: "0px 8px",
    "&:hover": {
      backgroundColor: darken(htmlCustomizerTooltipStyleValues.tooltipBackgroundColor, 0.15),
    },
    height: "100%",
  },
  container: {
    borderRight: props => props.seprator ? `1px solid ${palette.gray}` : null,
  },
  text: {
    paddingLeft: 7,
  }
});

const useStyles = makeStyles(styles);

export const HtmlCustomizerTooltipAiButton = injectIntl(({ intl, onClick, seprator = true }) => {

  const classes = useStyles({ seprator });

  return (
    <div className={classes.container}>
      <button className={classes.button} onClick={onClick}>
        <i className="fa-regular fa-sparkles" />
        <span className={classes.text}>{intl.messages["button.tooltip.generateWording"]}</span>
      </button>
    </div>
  );
});
