export const styles = {
	title: {
		paddingBottom: 50,
	},
	shopInfo: {
		display: "flex",
		position: "relative",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	formContainer: {
		paddingRight: 100,
		width: 340,
	},
	imageContainer: {
		display: "flex",
  	alignItems: "center",
  	justifyContent: "center",
		position: "relative",
		width: 250,
	},
	image: {
		width: "100%",
		height: "70%",
		objectFit: "contain",
	},
	buttonContainer: {
		paddingTop: 40,
	}
};
