import { useState, useCallback, useMemo } from "react";

import { palette } from "../../../styles/palette";

const TEMPLATE_EDITOR_HEADER_HEIGHT = 77;
const DEFAULT_TEMPLATE_EDITOR_BACKGROUND = palette.neutralBCGGrey;
const DEFAULT_TEMPLATE_EDITOR_BACKGROUND_IMAGE = "radial-gradient(#EBE3DA 2.5px, transparent 0)";
const DEFAULT_TEMPLATE_EDITOR_BACKGROUND_IMAGE_DARK = "radial-gradient(#838281 2.5px, transparent 0)";

export const useEditorCssVariables = () => {

  const [editorCssVariables, setEditorCssVariables] = useState({
    "--editor-header-height": `${TEMPLATE_EDITOR_HEADER_HEIGHT}px`,
    "--editor-header-display": "flex",
    "--editor-block-actions-visibility": "visible",
    "--editor-background": DEFAULT_TEMPLATE_EDITOR_BACKGROUND,
    "--editor-background-image": DEFAULT_TEMPLATE_EDITOR_BACKGROUND_IMAGE,
    "--editor-righ-menu-background": "#FFFFFF",
  });

  const hideHeader = useCallback(() => {
    setEditorCssVariables(prev => ({
      ...prev,
      "--editor-header-height": "0px",
      "--editor-header-display": "none",
    }));
  }, []);

  const showHeader = useCallback(() => {
    setEditorCssVariables(prev => ({
      ...prev,
      "--editor-header-height": `${TEMPLATE_EDITOR_HEADER_HEIGHT}px`,
      "--editor-header-display": "flex",
    }));
  }, []);

  const isHeaderHidden = useMemo(() => editorCssVariables["--editor-header-display"] === "none", [editorCssVariables]);

  const hideBlockActions = useCallback(() => {
    setEditorCssVariables(prev => ({
      ...prev,
      "--editor-block-actions-visibility": "hidden",
    }));
  }, []);

  const showBlockActions = useCallback(() => {
    setEditorCssVariables(prev => ({
      ...prev,
      "--editor-block-actions-visibility": "visible",
    }));
  }, []);

  const setBackgroundToDark = useCallback(() => {
    setEditorCssVariables(prev => ({
      ...prev,
      "--editor-background": "#949494",
      "--editor-background-image": DEFAULT_TEMPLATE_EDITOR_BACKGROUND_IMAGE_DARK,
      "--editor-righ-menu-background": "#232327",
    }));
  }, []);

  const resetBackground = useCallback(() => {
    setEditorCssVariables(prev => ({
      ...prev,
      "--editor-background": DEFAULT_TEMPLATE_EDITOR_BACKGROUND,
      "--editor-background-image": DEFAULT_TEMPLATE_EDITOR_BACKGROUND_IMAGE,
      "--editor-righ-menu-background": "#FFFFFF",
    }));
  }, []);

  return {
    editorCssVariables,
    hideHeader,
    showHeader,
    isHeaderHidden,
    hideBlockActions,
    showBlockActions,
    setBackgroundToDark,
    resetBackground
  };
};