import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@material-ui/core";
import { palette } from "../../../styles/palette.js";
import { EventSystem } from "../../../eventsystem/EventSystem.js";
import { Translation } from "../Translation";



export const KiTextField = ({ disabled,type,textAlign,value,placeholder,handleChange,textColor,autoComplete,name, onFocus, onBlur, onKeyDown }) => {
  const styles = () => ({
    yellowBackground:{
      backgroundColor: palette.yellow,
      color:"black"
    },
    adornment: {
      height:"40px",
      width:"45px",
      borderRadius:"10px"
    },
    icon:{
      fontSize:"17px",
      width:"30px"
    },
    root: {
      "& .MuiOutlinedInput-root": {
        borderRadius:"10px",
        background: disabled && palette.neutralUltraLightGrey,

        "& input": {
          textOverflow: "ellipsis",
          textAlign: textAlign ? textAlign : "left",
        },

        "& fieldset": {
          borderColor: !disabled ? type === "whiteBg" ? "white" : "#7A756E" : "none",
          borderWidth: disabled ? "0" : "",
        },
        "&:hover fieldset": {
          borderColor: !disabled ? type === "whiteBg" ? "white" : "#7A756E" : "none",
        },
        "&.Mui-focused fieldset": {
          border: !disabled ? type ===  "whiteBg" ? `1px solid ${palette.neutralDarkGrey}` : "1px solid #7A756E" : "none",
        },
      },
    },
  });
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(true);

  useEffect(() => {
    if (type?.includes("password")) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  }, [type]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const copyToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    EventSystem.newNotification("notification.action", <Translation id="editProfile.notif.id_account" />);
  };

  const CustomStyle =  type === "whiteBg" ? 
    {
      background : "white",
      color:"#7A756E",
      height:"40px",
      borderRadius:"10px",
      boxShadow: "0px 1px 7px 0px rgba(120, 120, 120, 0.25)",
    } :
    {
      color: "#ffd481"
    };

  const customPropsStyle =  {
    height: "40px",
    paddingRight: 4,
    paddingLeft: 10,
    fontFamily: "Poppins",
    fontSize: 14 ,
    fontWeight: 400,
    color: textColor ? textColor : palette.neutralDarkGrey
  };

  return (
    <div className={classes.root}>
      <TextField
        name={name}
        style={{...CustomStyle}}
        type={showPassword ? "text" : "password"}
        variant="outlined"
        disabled={disabled}
        fullWidth
        value={value}
        placeholder={placeholder}
        autoComplete={autoComplete === "on" ? "on" : "off"}
        onChange={ handleChange ? (e) => handleChange(e) : null}
        InputProps={{
          style:{...customPropsStyle},
          endAdornment: (
            <InputAdornment position="end" >
              {
                (type && type.includes("password") )&&
                <IconButton onClick={ togglePasswordVisibility } edge="end">
                  <div className={classes.adornment}>
                    {showPassword ? <i class={`${classes.icon} fal fa-eye`}></i> : <i class={`${classes.icon} fal fa-eye-slash`}></i>}
                  </div>
                </IconButton>
              }
              {
                (type && type.includes("copy")) &&
                <IconButton onClick={ copyToClipboard } edge="end">
                  <div className={`${classes.adornment} ${classes.yellowBackground}`} >
                    <i class={`${classes.icon} fa-solid fa-copy`} />
                  </div>
                </IconButton>
              }
            </InputAdornment>
          ),
        }}
        inputProps={{
          onFocus: () => onFocus && onFocus(),
          onBlur: event => onBlur && onBlur(event),
          onKeyDown: event => onKeyDown && onKeyDown(event),
        }}
      />
    </div>
  );
};









