import { Component } from "react";

import { withStyles, darken } from "@material-ui/core/styles";

import { htmlCustomizerTooltipStyleValues } from "../htmlCustomizerTooltipStyleValues.js";

const styles = (theme) => {
  const { tooltipBackgroundColor } = htmlCustomizerTooltipStyleValues;

  return {
    tooltipStyleInput: {
      fontSize: 16,
      width: "2em",
      height: "2em",
      padding: 0,
      backgroundColor: tooltipBackgroundColor,
      border: "none",
      borderRadius: 4,
      color: "white",
      cursor: "pointer",
      "&:not(&:last-child)": {
        marginRight: 8,
      },
      "&:hover": {
        backgroundColor: darken(tooltipBackgroundColor, 0.15),
      },
      "& .fal": {
        fontWeight: "bold",
      },
      "&.active": {
        backgroundColor: darken(tooltipBackgroundColor, 0.15),
      },
    },
  };
};

export const HtmlCustomizerTooltipInputStyle = withStyles(styles)(
  class HtmlCustomizerTooltipInputStyle extends Component {
    render() {
      const { classes, styleInputType, getIsSelected, onClick } = this.props;

      const formattedClasses = [classes.tooltipStyleInput];
      if (getIsSelected(styleInputType)) formattedClasses.push("active");

      return (
        <button
          className={formattedClasses.join(" ")}
          // prevent losing the selection on the associated text field when clicking on this button
          onMouseDown={(event) => event.preventDefault()}
          onClick={(event) => onClick(styleInputType)}
        >
          <i className={`fal fa-${styleInputType.toLowerCase()}`}></i>
        </button>
      );
    }
  }
);
