import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import * as amplitude from "@amplitude/analytics-browser";
import { reloadAccountContext } from "./../contexts/context.js";

export class HTTPResponseStatusError extends Error {
  constructor(response) {
    super(`HTTP ${response.status}`);
    this.name = "HTTPResponseStatusError";
    this.status = response.status;
    this.response = response;
  }
}

export class AuthService {
  constructor() {
    this.signUp = this.signUp.bind(this);
    this.login = this.login.bind(this);
    this.fetch = this.fetch.bind(this);
    this.loggedIn = this.loggedIn.bind(this);
    this.getServerProfile = this.getServerProfile.bind(this);
    this.updateUserAttributes = this.updateUserAttributes.bind(this);
    this.sendVerifyEmail = this.sendVerifyEmail.bind(this);
    this.verifyEmail = this.verifyEmail.bind(this);
  }

  async signUp(email, password, additionnalAttributes = {}) {
    try {
      return await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          "email": email,
          ...additionnalAttributes
        },
        autoSignIn: {
          enabled: true
        },
        clientMetadata: {
          email: email
        }
      });
    } catch (error) {
      switch(error.code) {
        case "InvalidPasswordException":
          error.error = "PASSWORD_LENGTH";
          break;
        case "UsernameExistsException":
        case "UserLambdaValidationException":
          error.error = "ALREADY_CREATED";
          break;
      }
      throw error;
    }
  }

  async linkAccount(email, password) {
    const account = await this.fetch("/api/account_management/linkAccount", {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password
      })
    }, {
      ignoreLogout: true
    }).catch((error) => {
      if(error instanceof HTTPResponseStatusError) {
        if(error.status === 409) {
          error.error = "ACCOUNT_ALREADY_LINKED";
        } else if(error.status === 401) {
          error.error = "UNAUTHORIZED_ACCESS";
        }
      }
      throw error;
    });
    await reloadAccountContext();
    return account;
  }

  async resetHasCheckedTemplateBeforeActivating() {
    const { usecases } = await this.fetch("/api/usecase/getAll", { method: "GET" });

    const newHasCheckedTemplateBeforeActivating = {};
    usecases.forEach(usecase => newHasCheckedTemplateBeforeActivating[usecase.name] = false);

    await this.fetch("/api/account_management/editAccount", {
      method: "POST",
      body: JSON.stringify({
        hasCheckedTemplateBeforeActivating: newHasCheckedTemplateBeforeActivating,
      })
    });
    await reloadAccountContext();
  }

  /**
   * 
   * @param {string} email User email
   * @param {string} password User password
   * @param {Object} options Login options
   * @param {boolean} options.forceSessionRefresh Force refresh user credentials after sign in
   */
  async login(email, password, options = {}) {
    try {
      let user = await Auth.signIn(email, password, { email: email });
      if(options.forceSessionRefresh) {
        user = await this.forceSessionRefresh();
      } 

      this.switchAccount(user.attributes["custom:admin_account"]);
      await this.resetHasCheckedTemplateBeforeActivating();
    } catch (error) {
      switch(error.code) {
        // User didn't confirm email yet
        case "NotAuthorizedException":
          error.error = "UNAUTHORIZED_ACCESS";
          break;
        case "UserNotFoundException":
          error.error = "USER_NOT_FOUND";
          break;
        case "UserNotConfirmedException":
          error.error = "INVALID_ACCOUNT";
          break;
        case "PasswordResetRequiredException":
          error.error = "RESET_REQUIRED";
          await this.forgotPassword(email)
            .catch(error => console.error("Unable to send reset password for user", error));
          break;
      }
      throw error;
    }
  }

  /**
   * Login with Google via custom popup
   */
  async loginWithGoogle() {
    return await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  }

  async switchAccount(accountId) {
    localStorage.setItem("id_account", accountId || "");
    localStorage.setItem("is_impersonating", "false");
  }

  async impersonate(accountId) {
    sessionStorage.clear();
    localStorage.setItem("id_account", accountId || "");
    localStorage.setItem("is_impersonating", "true");
  }

  isImpersonating() {
    return localStorage.getItem("is_impersonating") === "true";
  }

  loggedIn() {
    return !!this.getAccountId();
  }

  async logout() {
    localStorage.removeItem("is_impersonating");
    localStorage.removeItem("id_account");
    amplitude.reset();
    return await Auth.signOut();
  }

  getAccountId() {
    return localStorage.getItem("id_account");
  }

  /**
   * Force user data to reload from Cognito
   * @returns {Promise<import('@aws-amplify/auth').CognitoUser>} reloaded user
   */
  async forceSessionRefresh() {
    return await Auth.currentAuthenticatedUser({ bypassCache: true });
  }
  
  /**
   * Get current Cognito user
   * @returns {Promise<import('@aws-amplify/auth').CognitoUser | null>}
   */
  async getUser() {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch(e) {
      return null;
    }

    try {
      let isKilibaEmployee = false;
      if(user.attributes.identities) {
        const identities = JSON.parse(user.attributes.identities);
        isKilibaEmployee = 
          user.attributes.email.endsWith("@kiliba.com") && 
          identities.some(identity => identity.providerType === "Google");
      }

      user.canImpersonateRead = isKilibaEmployee;
      user.canImpersonateWrite = isKilibaEmployee && user.attributes["custom:kiliba_admin"] === "true";
      user.isImpersonating = !user.attributes["custom:accounts_full_access"].split(",").includes(this.getAccountId());
    } catch(error) {}

    return user;
  }

  async sendEventToAmplitude(eventName, event = {}) {
    if (!eventName) {
      throw new Error("Missing parameters.");
    }

    if (this.loggedIn()) {
      const { account, user } = await this.getServerProfile();
      amplitude.setUserId(account._id);
      event.email = user.attributes.email;
      event.MRR = account.MRR;
    }

    amplitude.track(eventName, event);
  }

  async getServerProfile() {
    const user = await this.getUser();

    let accountResponse;
    try {
      accountResponse = await this.fetch("/api/account_management/getAccount");
    } catch(error) {
      if(this.loggedIn()) {
        await this.logout();
        return { user, account: null, config: null };
      }
    }

    return {
      user,
      account: accountResponse?.account || null,
      config: accountResponse?.config || null,
      additionalInfos: accountResponse?.additionalInfos || null,
    };
  }

  /**
   * Fetch wrapper with authentication and error handling
   * @param {string} url URL to fetch
   * @param {RequestInit} init Fetch options
   * @param {object} options Additional options
   * @param {boolean} options.ignoreLogout Do not logout if 401
   * @param {boolean} options.rawResponse return raw response
   * @returns 
   */
  async fetch(url, init = {}, options = { ignoreLogout: false, rawResponse: false, endpoint: "default" }) {
    let headers = {};
    if (init && init.headers) {
      headers = {
        ...init.headers
      };
    }


    // If Content-Type header not overrode
    // And body is not FormData (mainly for uploads)
    // Force Content-Type header to application/json
    if(!headers["Content-Type"] && !(init && init.body instanceof FormData)) {
      headers["Content-Type"] = "application/json";
    }

    // Add user credentials to headers if user authenticated
    try {
      const session = await Auth.currentSession();
      const token = session.getAccessToken().getJwtToken();
      headers["Authorization"] =  `Bearer ${token}`;
      headers["id-account"] =  this.getAccountId(); // Fetch lowercase all headers, can't send Id-Account
    } catch {}

    let endpoint = process.env.REACT_APP_BACKEND_API_URL || "http://localhost:3000";
    switch(options.endpoint) {
      case "llm-hub":
        endpoint = process.env.REACT_APP_BACKEND_LLM_HUB_URL;
        break;
      default:
        break;
    }

    const response = await fetch(endpoint + url.replace("/api/", "/"), {
      ...init,
      headers
    });

    if(response.status === 401 && !options.ignoreLogout) {
      return this.logout();
    }
    else if(response.status < 200 || response.status >= 300) {
      throw new HTTPResponseStatusError(response);
    }
    else { // Success status lies between 200 to 300
      return options.rawResponse ? response : response.json();
    }
  }

  async updateUserAttributes(attributes) {
    const user = await this.getUser();
    return await Auth.updateUserAttributes(user, attributes);
  }

  async sendVerifyEmail() {
    try {
      await Auth.verifyCurrentUserAttribute("email");
      await this.updateUserAttributes({ "custom:welcome_email_sent": "true" });
      await this.forceSessionRefresh();
    } catch(e) { }
  }

  async verifyEmail(confirmationCode) {
    return await Auth.verifyCurrentUserAttributeSubmit("email", confirmationCode);
  }

  async forgotPassword(username) {
    return await Auth.forgotPassword(username, { email: username });
  }

  async forgotPasswordSubmit(username, confirmationCode, password) {
    return await Auth.forgotPasswordSubmit(username, confirmationCode, password, { email: username });
  }

  async checkSf({ email = null, shopUrl = null, isGoogleAuth = false }) {
    let url = "/public/getInfosAccountExists?";
    if (email) {
      url += `email=${encodeURIComponent(email)}&`;
    }
    if (shopUrl) {
      url += `shopUrl=${encodeURIComponent(shopUrl)}&`;
    }

    return await this.fetch(
      url,
      {
        method: "GET"
      }
    );
  } 
}
