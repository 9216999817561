import { makeStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import classes from "./StatsCarousel.module.scss";
import { palette } from "../../../styles/palette";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const StatsCarousel = ({ stats }) => {

  const intl = useIntl();
  const ref = useRef();
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 4 });

  const onScrollButtonCallback = useCallback((direction) => {
    const increment = direction === "down" ? 1 : -1;
    setPagination({ ...pagination, page: pagination.page + increment});
  }, [pagination]);

  useEffect(() => {
    if (ref) {
      const observer = new ResizeObserver(() => {
        setPagination(prev => ({
          page: ref.current.getBoundingClientRect().height > 800 ? 0 : prev.page,
          rowsPerPage: ref.current.getBoundingClientRect().height > 800 ? 8 : 4,
        }));
      });
      observer.observe(ref.current, { subtree: true, attributes: true, attributeOldValue: true, attributeFilter: ["style"], childList: true });
      return () => observer.disconnect();
    }
  }, []);

  const cards = useMemo(() => [
    {
      title: intl.messages["card.title.emails_sent"],
      icon: "box-open-full",
      value: stats?.send,
      color: "accentGreen",
    },
    {
      title: intl.messages["card.title.open_rate"],
      icon: "envelope-open",
      value: stats?.open,
      color: "accentPurple",
    },
    {
      title: intl.messages["card.title.clic_rate"],
      icon: "computer-mouse",
      value: stats?.click,
      color: "primaryYellow",
    },
    {
      title: intl.messages["card.title.unsubscribe_rate"],
      icon: "trash-xmark",
      value: stats?.unsubscribe,
      color: "accentGreen",
    },
    {
      title: intl.messages["card.title.ca"],
      icon: "money-check-dollar",
      value: stats?.turnover,
      color: "accentRed",
    },
    {
      title: intl.messages["card.title.nb_order"],
      icon: "fa-solid fa-envelope-circle-check",
      value: stats?.nb_order,
      color: "primaryYellow",
    },
    {
      title: intl.messages["card.title.avg_order"],
      icon: "fa-solid fa-envelope-circle-check",
      value: stats?.avgOrder,
      color: "accentPurple",
    },
    {
      title: intl.messages["card.title.conversion_rate"],
      icon: "arrow-right-arrow-left",
      value: stats?.conversion_rate,
      color: "accentPurple",
    },
    
  ], [intl, stats]);

  function renderCards() {
    return cards.map((card, key) => {
      const iconStyle = { color: palette[`${card.color}500`] };
      return (
        <div key={key} className={classes.card}>
          <h1 className={classes.value}>{card.value}</h1>
          <span className={`${classes.title} textMedium`}>{card.title}</span>
          <i className={`${classes.icon} fa-solid fa-${card.icon}`} style={iconStyle} />
        </div>
      );
    })
      .filter((card, key) => {
        return (
          key >= pagination.page * pagination.rowsPerPage &&
          key < (pagination.page + 1) * pagination.rowsPerPage
        );
      });
  }

  return (
    <div>
      <div ref={ref} className={classes.carousel}>
        <div
          className={`${classes.scrollButton} ${pagination.page > 0 && pagination.rowsPerPage < 8 ? "" : classes.hidden}`}
          onClick={() => onScrollButtonCallback("up")}
        >
          <i className={"fa-solid fa-chevron-up"}></i>
        </div>
        <div className={classes.cards}>
          {renderCards()}
        </div>
        <div
          className={`${classes.scrollButton} ${pagination.page < Math.ceil(cards.length / 4) - 1 && pagination.rowsPerPage < 8 ? "" : classes.hidden}`}
          onClick={() => onScrollButtonCallback("down")}
        >
          <i className={"fa-solid fa-chevron-down"}></i>
        </div>
      </div>
    </div>

  );
};