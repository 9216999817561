import moment from "moment-timezone";

export function prepareDateText(promoCode, shouldIncludeValue, shouldIncludeSpareText, shouldIncludeWithCodeText, dateFromFormat = "DD/MM", dateToFormat = "DD/MM") {
  const shouldIncludeDate = promoCode?.mustShowDates;
  if (promoCode.dateFrom && promoCode.dateTo) {
    return {
      fr: (shouldIncludeSpareText ? "Economisez" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value} ${promoCode.type} ${promoCode.type !== "%" ? promoCode.includesTaxes ? "TTC" : "HT" : ""}</span>` : "") + (shouldIncludeDate ? ` Du ${moment(promoCode?.dateFrom).format(dateFromFormat)} au ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " avec le code" : ""),
      en: (shouldIncludeSpareText ? "Save" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` from ${moment(promoCode?.dateFrom).format(dateFromFormat)} to ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " with code" : ""),
      es: (shouldIncludeSpareText ? "Ahorra" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` del ${moment(promoCode?.dateFrom).format(dateFromFormat)} al ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " con el código" : ""),
      de: (shouldIncludeSpareText ? "Sparen Sie" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` vom ${moment(promoCode?.dateFrom).format(dateFromFormat)} bis ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " mit dem Code" : ""),
      nl: (shouldIncludeSpareText ? "Bespaar" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` van ${moment(promoCode?.dateFrom).format(dateFromFormat)} tot ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " met code" : ""),
      it: (shouldIncludeSpareText ? "Risparmia" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` dal ${moment(promoCode?.dateFrom).format(dateFromFormat)} al ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " con il codice" : ""),
      pt: (shouldIncludeSpareText ? "Poupe" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` de ${moment(promoCode?.dateFrom).format(dateFromFormat)} a ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " com o código" : ""),
    };
  } else if (promoCode.dateFrom && !promoCode.dateTo) {
    return {
      fr: (shouldIncludeSpareText ? "Economisez" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value} ${promoCode.type} ${promoCode.type !== "%" ? promoCode.includesTaxes ? "TTC" : "HT" : ""}</span>` : "") + (shouldIncludeDate ? ` à partir du ${moment(promoCode?.dateFrom).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " avec le code" : ""),
      en: (shouldIncludeSpareText ? "Save" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` from ${moment(promoCode?.dateFrom).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " with code" : ""),
      es: (shouldIncludeSpareText ? "Ahorra" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` a partir del ${moment(promoCode?.dateFrom).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " con el código" : ""),
      de: (shouldIncludeSpareText ? "Sparen Sie" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` ab dem ${moment(promoCode?.dateFrom).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " mit dem Code" : ""),
      nl: (shouldIncludeSpareText ? "Bespaar" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` van ${moment(promoCode?.dateFrom).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " met code" : ""),
      it: (shouldIncludeSpareText ? "Risparmia" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` dal ${moment(promoCode?.dateFrom).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " con il codice" : ""),
      pt: (shouldIncludeSpareText ? "Poupe" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` a partir de ${moment(promoCode?.dateFrom).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " com o código" : ""),
    };
  }
  else if (!promoCode.dateFrom && promoCode.dateTo) {
    return {
      fr: (shouldIncludeSpareText ? "Economisez" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value} ${promoCode.type} ${promoCode.type !== "%" ? promoCode.includesTaxes ? "TTC" : "HT" : ""}</span>` : "") + (shouldIncludeDate ? ` jusqu'au ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " avec le code" : ""),
      en: (shouldIncludeSpareText ? "Save" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` until ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " with code" : ""),
      es: (shouldIncludeSpareText ? "Ahorra" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` hasta ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " con el código" : ""),
      de: (shouldIncludeSpareText ? "Sparen Sie" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` bis ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " mit dem Code" : ""),
      nl: (shouldIncludeSpareText ? "Bespaar" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` tot ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " met code" : ""),
      it: (shouldIncludeSpareText ? "Risparmia" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` fino al ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " con il codice" : ""),
      pt: (shouldIncludeSpareText ? "Poupe" : "") + (shouldIncludeValue ? ` <span style="font-weight:600">-${promoCode?.value}</span> ${promoCode.type}` : "") + (shouldIncludeDate ? ` até ${moment(promoCode?.dateTo).format(dateToFormat)}` : "") + (shouldIncludeWithCodeText ? " com o código" : ""),
    };
  }
}

export function getColorLuminosity(hexColor) {
  const r = parseInt(hexColor.slice(1, 3), 16) / 255;
  const g = parseInt(hexColor.slice(3, 5), 16) / 255;
  const b = parseInt(hexColor.slice(5, 7), 16) / 255;

  const luminosity = 0.299 * r + 0.587 * g + 0.114 * b;
  const threshold = 0.5;
  return luminosity > threshold;
};