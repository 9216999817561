import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Dialog } from "../../../common/Dialog.js";
import { CustomButton } from "../../../common/Button/CustomButton.js";
import { injectIntl, FormattedMessage } from "react-intl";
import { TextField } from "../../../common/Input/TextField.js";
import { EventSystem } from "../../../../eventsystem/EventSystem.js";
import { withAuth } from "../../../withAuth";
import { reloadAccountContext } from "../../../../contexts/context.js";

const styles = (theme) => ({
  main: {
    width: "600px",
    marginTop: "-30px",
    marginBottom: "-30px",
  },
  head: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    width: "415px",
    textAlign: "center",
    gap: "15px",
    margin: "auto",
  },
  title: {
    fontSize: "18px",
    fontFamily: "Coolvetica",
    marginBottom: "7px",
  },
  text: {
    fontSize: "16px",
    fontFamily: "Coolvetica Book",
    textAlign: "left",
    lineHeight: "1.2",
    margin: "5px",
  },
  separator: {
    width: "95%",
    height: "0.2px",
    marginTop: "37px",
    marginBottom: "40px",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    margin: "20px 35px",
  },
  marginBottom: {
    marginBottom: "20px",
  },
});

export const UsecasePopupSalesContact = withAuth(
  injectIntl(
    withStyles(styles)(
      injectIntl(
        class UsecasePopupSalesContact extends Component {
          constructor(props) {
            super(props);
            this.editPhoneNumber = this.editPhoneNumber.bind(this);
            this.setCalendlyAppointment =
              this.setCalendlyAppointment.bind(this);

            this.state = {
              phoneNumber: "",
              isValidPhoneNumber: true,
            };
          }

          componentDidMount() {
            this.props.auth.getUser()
              .then(user => {
                this.setState({ phoneNumber: user.attributes.phone_number });
              });
          }

          async editPhoneNumber() {
            const { intl, auth } = this.props;

            try {
              // Check phone number format for Cognito
              try {
                await auth.updateUserAttributes({
                  "phone_number": this.state.phoneNumber.replace(/[^0-9+]/g, "").replace(/^0/, "+33")
                });
                this.setState({ isValidPhoneNumber: true });
              } catch(error) {
                if(error.code === "InvalidParameterException") {
                  this.setState({ isValidPhoneNumber: false });
                  return;
                } else {
                  throw error;
                }
              }

              await auth.fetch("/api/account_management/editPhoneNumber", { method: "POST" });
              await reloadAccountContext();

              EventSystem.newNotification(
                "notification.action",
                intl.messages["usecase.popupContact.validation"]
              );
              this.props.closeSalesContactPopup();
            } catch (error) {
              EventSystem.newNotification(
                "notification.action",
                intl.messages["error.main_error"]
              );
            }
          }

          async setCalendlyAppointment() {
            const { auth } = this.props;
            let formattedCalendlyUrl = "https://calendly.com/antoinelessard/discovery-kiliba";
            try {
              const res = await auth.fetch("/api/account_management/getOwnerCalendly", {
                method: "GET"
              });
              
              if (res.ownerCalendly !== null) {
                formattedCalendlyUrl = res.ownerCalendly.includes("https://")
                  ? res.ownerCalendly
                  : "https://" + res.ownerCalendly;
              }
              window.open(formattedCalendlyUrl);
              this.props.closeSalesContactPopup();
            } catch (error) {
              window.open(formattedCalendlyUrl);
            }
          }

          render() {
            const { intl, classes } = this.props;

            return (
              <Dialog
                PaperProps={{ style: { borderRadius: 20 } }}
                close={this.props.closeSalesContactPopup}
                isOpen={true}
              >
                <div className={classes.main}>
                  <div className={classes.head}>
                    <p className={classes.title}>
                      {this.props.variant === "adaptPackage" ?
                        intl.messages["subscriptionAdaptPackageCustom.button"] :
                        this.props.variant === "usecase"
                          ? intl.messages["usecase.popupContact.title"]
                          : this.props.variant === "subscription"
                            ? intl.messages["usecase.popupContact.subscription"]
                            : intl.messages["popup.title_blocked_account"]}
                    </p>
                    <p className={classes.text}>
                      {this.props.variant === "usecase"
                        ? intl.messages["usecase.popupContact.text1"]
                        : null}
                    </p>
                    <p className={`${classes.text} ${classes.marginBottom}`}>
                      {intl.messages["usecase.popupContact.text2"]}
                    </p>

                    <TextField
                      type="phone"
                      errorMessage={
                        !this.state.isValidPhoneNumber &&
                        intl.messages["error.phone.format"]
                      }
                      onChange={(event) =>
                        this.setState({ phoneNumber: event.target.value })
                      }
                      value={this.state.phoneNumber}
                      style={{ margin: "0 15px 15px 15px" }}
                    />
                    <CustomButton
                      onClick={this.editPhoneNumber}
                      style={{ margin: "auto", alignSelf: "center" }}
                      type={"primary"}
                    >
                      <FormattedMessage
                        id="usecase.popupContact.btnContact"
                        defaultMessage="LetsGo"
                      />
                    </CustomButton>
                  </div>

                  <hr className={classes.separator}></hr>

                  <div className={classes.footer}>
                    <p className={classes.text}>
                      {" "}
                      {intl.messages["usecase.popupContact.text3"]}{" "}
                    </p>
                    <CustomButton
                      onClick={this.setCalendlyAppointment}
                      type={"secondary"}
                      size={"md"}
                      style={{ minWidth: "175px" }}
                    >
                      {intl.messages["usecase.popupContact.btnContactRdv"]}
                    </CustomButton>
                  </div>
                </div>
              </Dialog>
            );
          }
        }
      )
    )
  )
);

UsecasePopupSalesContact.propTypes = {
  classes: PropTypes.object.isRequired,
};
