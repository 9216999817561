import { prepareDateText, getColorLuminosity } from "./promoCode.tools";

const LABEL = "promoCode4";
const CATEGORY = "promoCode:wideHeadband";

export const promoCode4 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {
    const { promoCode, backgroundColor, fontFamily, textColor, buttonColor } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    if (!promoCode) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const shouldIncludeDate = promoCode?.mustShowDates;
    const shouldIncludeQuantity = promoCode?.quantity;
    const tagTextColor = getColorLuminosity(buttonColor)  ? "#000000" : "#FFFFFF";

    const prepareValueAndDateMjml = () => {
      return `
          <mj-text font-family="${fontFamily}" color="${textColor}" data-readonly="true" align="center" font-size="30px" font-weight="600" padding-bottom="10px">- {{value}} {{type}} ${promoCode.type !== "%" ? "{{taxes}}" : ""}</mj-text>
          ${shouldIncludeDate ? `<mj-text font-family="${fontFamily}" color="${textColor}" data-readonly="true" align="center" line-height="1" padding-top="0" padding-top="0" padding-bottom="0" font-size="14px" font-weight="500">{{promoCodeDate}}</mj-text>` : ""}
          <mj-text font-family="${fontFamily}" color="${textColor}" data-readonly="true" align="center" line-height="1" padding-top="3px" padding-bottom="10px" font-size="14px" font-weight="500">{{promoCodeText}}</mj-text>
        `;
    };

    const prepareQuantityMjml = () => {
      return shouldIncludeQuantity ?`
          <mj-text font-family="${fontFamily}" color="${textColor}" data-readonly="true" align="center" padding-top="15px" font-size="14px" font-weight="500" >{{quantity}}</mj-text>
        ` : "";
    };

    const mjml = `
    <mj-wrapper data-gjs="kilibaBlock" data-promo-code-id="${promoCode._id}" background-color="${backgroundColor}" padding-top="10px" padding-bottom="10px" full-width="full-width">
      <mj-section>
        <mj-column padding-top="10px" css-class="column60" vertical-align="middle">
          ${prepareValueAndDateMjml()}
          <mj-button font-family="${fontFamily}" background-color="${buttonColor}" data-readonly="true" css-class="newproduct1-tag" border-radius="0" color="${tagTextColor}" background-color="#000000" padding-top="10px" align="center" font-weight="500" font-size="16px" data-promo-code-value="true">{{code}}</mj-button>
          ${prepareQuantityMjml()}
        </mj-column>
      </mj-section>
    </mj-wrapper>
    `;

    const wordings = {
      promoCodeDate: prepareDateText(promoCode, false, false, false, "DD", "DD MMMM"),
      promoCodeText: {
        fr: " avec le code",
        en: " with the code",
        es: " con el código",
        de: " mit dem Code",
        nl: " met code",
        it: " con il codice",
        pt: " com o código",
      },
      code: {
        fr: promoCode?.code,
        en: promoCode?.code,
        es: promoCode?.code,
        de: promoCode?.code,
        nl: promoCode?.code,
        it: promoCode?.code,
        pt: promoCode?.code,
      },
      value: {
        fr: promoCode?.value,
        en: promoCode?.value,
        es: promoCode?.value,
        de: promoCode?.value,
        nl: promoCode?.value,
        it: promoCode?.value,
        pt: promoCode?.value,
      },
      quantity: {
        fr: `${promoCode?.quantity} coupons disponibles !`,
        en: `${promoCode?.quantity} coupons available !`,
        es: `${promoCode?.quantity} cupones disponibles !`,
        de: `${promoCode?.quantity} Gutscheine verfügbar !`,
        nl: `${promoCode?.quantity} coupons beschikbaar !`,
        it: `${promoCode?.quantity} coupon disponibili !`,
        pt: `${promoCode?.quantity} cupões disponíveis !`,
      },
      type: {
        fr: promoCode?.type,
        en: promoCode?.type,
        es: promoCode?.type,
        de: promoCode?.type,
        nl: promoCode?.type,
        it: promoCode?.type,
        pt: promoCode?.type,
      },
      taxes: {
        fr: promoCode.includesTaxes ? "TTC" : "HT",
        en: promoCode.includesTaxes ? "VAT included" : "VAT excluded",
        es: promoCode.includesTaxes ? "IVA incluido" : "IVA excluido",
        de: promoCode.includesTaxes ? "MwSt. enthalten" : "MwSt. ausgeschlossen",
        nl: promoCode.includesTaxes ? "BTW inbegrepen" : "BTW uitgesloten",
        it: promoCode.includesTaxes ? "IVA inclusa" : "IVA esclusa",
        pt: promoCode.includesTaxes ? "IVA incluída" : "IVA excluída",
      }
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        wordings,
        editableProperties: [
          { key: "promoCode", type: "promoCode" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.color" },
          { key: "buttonColor", type: "color", label: "newsletter.rightMenu.property.tagColor", group: "newsletter.rightMenu.propertyGroup.color" },
          { key: "textColor", type: "color", label: "newsletter.rightMenu.property.textColor", group: "newsletter.rightMenu.propertyGroup.color" },
        ],
      }
    };
  },
  style: `
    .column60 {
      width: 60% !important;
    }
    .column40 {
      width: 40% !important;
    }
  `
});