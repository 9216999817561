import React from "react";
import { makeStyles } from "@material-ui/core";
import { Translation } from "./Translation";

const styles = () => ({
  badge:{
    width:"100px",
    height:"25px",
    borderRadius:"20px",
    margin: "5px 10px",
    fontFamily: "POPPINS",
    fontSize: "13px",
    display: "flex",
    justifyContent: "center",
  },

  connected:{
    background:"#d4f1af",
  },
  suspended:{
    background:"#ffc5ac",
  },

  text:{
    alignSelf:"center",
  }
});

export const CmsConnectingStateBadge = ({ isConnected, isSecondaryCMS = false }) => {

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div className={`${classes.badge} ${ isConnected ? classes.connected : classes.suspended}`}>
      <span className={classes.text}>
        <Translation id={ isConnected ? 
          "badge.connected" : 
          isSecondaryCMS ? 
            "badge.failed" :
            "badge.suspended"
        }></Translation>
      </span>
    </div>
  );
};









