const LABEL = "columnText";
const CATEGORY = "columnComponent:text";

export const columnText = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {
    const { fontFamily, textColor, textValue, backgroundColor } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-text data-column-component="true" data-blocklabel="${LABEL}" color="${textColor}" font-family="${fontFamily}" font-size="14px" align="center" line-height="1.3" padding-left="0" padding-right="0" container-background-color="${backgroundColor}">
        {{text}}
      </mj-text>
    `;

    const wordings = {
      text: textValue,
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        wordings,
        editableProperties: [
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ]
      }
    };
  },
});
