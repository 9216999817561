import { useIntl } from "react-intl";
import { CmsDialog } from "../../common/CmsDialog/CmsDialog";
import { useAuth } from "../../../../../hooks/useAuth";
import { MagentoForm } from "./MagentoForm.jsx";
import underline from "../../../../../public/underline.png";
import { useEffect } from "react";
import { usePrevious } from "../../../../../hooks/usePrevious";

import classes from "./MagentoDialog.module.scss";


export const MagentoDialog = ({ open = false, handleClose = () => { }, onSubmit = () => { }, loading = false }) => {

  const intl = useIntl();
  const auth = useAuth();
  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (!prevOpen && open) {
      auth.sendEventToAmplitude("magento_connection.see_modale");
    } else if (prevOpen && !open) {
      auth.sendEventToAmplitude("magento_connection.leave_modale");
    }
  }, [auth, prevOpen, open]);

  return (
    <CmsDialog open={open} handleClose={handleClose} customScrollBar>
      <div className={classes.magentoDialog}>
        <div className={classes.title}>
          <p className={"display2"}>{intl.messages["onBoarding.chooseCms.magento.title"]}</p>
        </div>
        <h2 className={classes.subtitle}>
          {intl.messages["onBoarding.chooseCms.prestashop.subtitle"]}
        </h2>
        <img alt="" src={underline} className={classes.underlineImg} />
        <MagentoForm loading={loading} onSubmit={onSubmit} />
      </div>
    </CmsDialog>
  );
};
