import { palette } from "./palette.js";

export const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "100%",
    position: "fixed",
    overflowY: "scroll",
    margin: "0",
    backgroundColor: palette.neutralBCGGrey,
  },
  topbar: {
    width: "100%",
    minWidth: "800px",
    height: "60px",
    backgroundColor: palette.white,
    position: "relative",
  },
  wording: {
    marginTop: "100px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    position: "relative",
    // width: '718px',
    // height: '63px',
    fontFamily: "Coolvetica",
    fontSize: "40px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "-1px",
    color: palette.black,
  },
  subWording: {
    marginTop: "24px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    position: "relative",
    // width: '421px',
    // height: '27px',
    fontFamily: "Coolvetica Book",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: palette.black,
  },
});
