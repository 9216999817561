import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { injectIntl } from "react-intl";

import classes from "./TopBar.module.scss";
import { AppBar, Toolbar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ResultLabelTO } from "../ResultLabelTO.js";

import { EventSystem } from "../../eventsystem/EventSystem.js";
import { PopupTO } from "../PopupTO.js";
import { withAuth } from "../withAuth.js";
import { Tooltip } from "../Tooltip.js";
import { DropDownMenu } from "./DropDownMenu.js";
import { TextField } from "../common/Input/TextField.js";

import "../../styles/scss/TopBarSubMenu.css";
import { List, ListItem } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import Divider from "@material-ui/core/Divider";

import fr from "../../public/fr.png";
import en from "../../public/en.png";
import es from "../../public/es.png";
import { AccountContextBehaviorSubject$, reloadAccountContext } from "../../contexts/context.js";

const localeImages = { fr, en, es };

export const TopBar = withAuth(
  injectIntl(
    class TopBar extends Component {
      constructor(props) {
        super(props);
        this.loadProfile = this.loadProfile.bind(this);
        this.openSubMenuList = this.openSubMenuList.bind(this);
        this.openLocaleMenuList = this.openLocaleMenuList.bind(this);
        this.openAccountList = this.openAccountList.bind(this);
        this.handleClickAway = this.handleClickAway.bind(this);
        this.changeUrl = this.changeUrl.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.closeDeleteAccount = this.closeDeleteAccount.bind(this);
        this.openCloseFreemium = this.openCloseFreemium.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.handlePopupDeleteAccount =
            this.handlePopupDeleteAccount.bind(this);
        this.getMultiAccounts = this.getMultiAccounts.bind(this);
        this.switchAccount = this.switchAccount.bind(this);
        this.openClosePopupLinkNewShop =
            this.openClosePopupLinkNewShop.bind(this);
        this.linkNewShop = this.linkNewShop.bind(this);
        this.hideSubscriptionBar = this.hideSubscriptionBar.bind(this);
        this.showSubscriptionBar = this.showSubscriptionBar.bind(this);
        this.onScroll = this.onScroll.bind(this);

        this.state = {
          countNotif: 0,
          auth: true,
          anchorEl: null,
          user_locale:"fr",
          first_name: "",
          company_name: "",
          userMenuListOpen: false,
          localeOpen: false,
          shopsMenuOpen: false,
          date_creation: "",
          access_customer: true,
          deleteAccount: false,
          popupFreemium: false,
          popupLinkNewShop: false,
          account: null,
          accountList: [],
          accountListRender: [],
          errorIdShop: false,
          clickable: true,
          error_text: "",
          email: "",
          password: "",
          countDaysFreemium: null,
          account_type: null,
          actifs: 0,
          userCanImpersonateRead: false,
          userCanImpersonateWrite: false,
          userImpersonationWarning: false,
          showBarShadow: false,
          hasStripeCustomer: false,
          paymentPlaform: null,
          user: null,
        };
      }

      onScroll() {
        if (window.scrollY >= 10 && !this.state.showBarShadow) {
          this.setState({ showBarShadow: true });
        } 
          
        if (window.scrollY < 10 && this.state.showBarShadow) {
          this.setState({ showBarShadow: false });
        }
      }

      async linkNewShop(e) {
        e.preventDefault();
        const { clickable, email, password } = this.state;
        if (clickable === true) {
          this.setState({ clickable: false });
          try {
            const { accountId } = await this.props.auth.linkAccount(email, password);
            await this.props.auth.forceSessionRefresh();
            this.switchAccount(accountId);
          } catch (err) {
            console.error(err);
            switch (err.error) {
              case "INVALID_ACCOUNT":
                this.setState({
                  error_text: "error.invalid_account",
                  clickable: true,
                });
                break;
              case "ACCOUNT_ALREADY_LINKED":
                this.setState({
                  error_text: "error.already_linked",
                  clickable: true,
                });
                break;
              case "UNAUTHORIZED_ACCESS":
              case "USER_NOT_FOUND":
                this.setState({
                  error_text: "error.unauthorized_access",
                  clickable: true,
                });
                break;
              default:
                this.setState({
                  error_text: "error.main_error",
                  clickable: true,
                });
                break;
            }
          }
        }
      }

      openClosePopupLinkNewShop() {
        this.setState({
          popupLinkNewShop: !this.state.popupLinkNewShop,
          error_text: "",
        });
      }

      changeUrl = (url) => (event) => {
        if (url === "/") {
          EventSystem.publish("active_sub_menu", "submenu_1");
        } else {
          this.setState({ userMenuListOpen: false });
          EventSystem.publish("active_sub_menu", null);
        }
        EventSystem.publish("url_switch", url);
      };

      handleClickAway(e) {
        if (e.target.id !== "buttonUserMenu") {
          this.setState({ userMenuListOpen: false });
        }
        if (e.target.id !== "buttonSwitchAccount") {
          this.setState({ shopsMenuOpen: false });
        }
        if (e.target.id !== "buttonLocaleMenu") {
          this.setState({ localeMenuListOpen: false });
        }
      }

      openLocaleMenuList() {
        this.setState({ localeMenuListOpen: !this.state.localeMenuListOpen });
      }

      openSubMenuList() {
        this.setState({ userMenuListOpen: !this.state.userMenuListOpen });
      }

      openAccountList() {
        this.setState({ shopsMenuOpen: !this.state.shopsMenuOpen });
      }

      handleChange = (event, checked) => {
        this.setState({ auth: checked });
      };

      handleMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
      };

      redirectToSupport = () => {
        if(this.state.user.attributes.locale === "fr")
          window.open( "https://support.kiliba.com/hc/fr","_blank");
        if(this.state.user.attributes.locale === "en")
          window.open( "https://support.kiliba.com/hc/en-gb","_blank");
        if(this.state.user.attributes.locale === "es")
          window.open( "https://support.kiliba.com/hc/es","_blank");
      };


      handleAdminSwitchAccount() {
        const idAccount = prompt("Quel compte ?");
        if (idAccount) {
          this.props.auth.impersonate(idAccount);
          window.location.reload();
        }
      }

      // Deconnexion du compte
      async handleLogout() {
        return await this.props.auth.logout();
      };

      handleClose = () => {
        // this.setState({access_customer : true})
        // this.setState({block : false})
      };
      closeDeleteAccount() {
        this.setState({ deleteAccount: false });
      }

      openCloseFreemium() {
        this.setState({ popupFreemium: !this.state.popupFreemium });
      }

      hideSubscriptionBar() {
        this.setState({ hasSubscribed: true });
      }

      showSubscriptionBar() {
        this.setState({ hasSubscribed: false });
      }

      deleteAccount() {
        const { intl } = this.props;

        const url = "/api/account_management/deleteAccount";
        const options = {
          method: "POST",
          body: JSON.stringify({}),
        };
        this.props.auth
          .fetch(url, options)
          .then((result) => {
            EventSystem.newNotification(
              "notification.action",
              intl.messages["topBar.deleteAccount.success"]
            );
            this.handleLogout();
          })
          .catch((error) => {
            this.setState({ deleteAccount: false });
            console.log(error);
            EventSystem.newNotification(
              "notification.action",
              intl.messages["topBar.deleteAccount.error"]
            );
          });
      }

      async getMultiAccounts() {
        const url = "/api/account_management/getMultiAccount";
        const { accounts } = await this.props.auth.fetch(url, {
          method: "GET",
        });

        const sortedAccounts = [];
        for (const account of accounts) {
          // account.action = () => this.switchAccount(account.id); // put it back later on when mystic bug will be fixed
          account.id === this.props.auth.getAccountId()
            ? sortedAccounts.unshift(account)
            : sortedAccounts.push(account);
        }
        // sortedAccounts.push({ id: "linkNewShop", name: "+ Ajouter", action: this.openClosePopupLinkNewShop }) // put it back later on when mystic bug will be fixed

        this.setState({ accountList: sortedAccounts });
      }

      switchAccount(idAccount) {
        if (this.props.auth.getAccountId() !== idAccount) {
          this.props.auth.switchAccount(idAccount);
          window.location.reload();
        }
      }

      async updateLocale(locale) {
        await this.props.auth.updateUserAttributes({
          locale: locale,
        });
        window.location.reload();
      }

      async fetchHasOpportunity() {
        try {
          if (this.state.account.CMS === "shopify") {
            const opportunity = await this.props.auth.fetch("/api/account_management/opportunitySf");

            return !!opportunity;
          } else {
            return null;
          }
        } catch (error) {
          console.error(error);
          return null;
        }
      }

      async loadProfile() {
        const { account, additionalInfos } = AccountContextBehaviorSubject$.getValue();
        const user = await this.props.auth.getUser();

        await new Promise((resolve, reject) => this.setState({ account, user_locale:user.attributes.locale }, resolve));

        const hasOpportunity = await this.fetchHasOpportunity();
        const hasSubscribed = account.owner === "Client Payant";
        const actifs = await this.fetchAccountAmountOfContacts();

        let sfAccount;

        try {
          sfAccount = await this.props.auth.fetch("/api/account_management/getSfAccount", {
            method: "GET"
          });
        } catch (e) {}

        await new Promise((resolve, reject) => {
          let active = account.active;

          if (
            account.endDateFreemium &&
              !account.billing_info.contract_start_date
          ) {
            const parsedToday = new Date();
            const parsedEndDateFreemium = new Date(account.endDateFreemium);

            // `ceil` allows to show "1 day left" even when 10 seconds remains with freemium
            // This behavior shows "31 days" on the first hour of an account created 30 days before winter daylight saving
            const countDaysFreemium = Math.ceil(
              (parsedEndDateFreemium - parsedToday) / 86400000
            );
            this.setState({ countDaysFreemium: countDaysFreemium });

            if (parsedEndDateFreemium - parsedToday <= 0) {
              active = false;
            }
          }

          this.getMultiAccounts();
          this.setState({
            account_type: account.owner,
            company_name: account.company_name,
            first_name: user.attributes.given_name,
            date_creation: account.date_creation,
            access_customer: active,
            userCanImpersonateRead: user.canImpersonateRead,
            userCanImpersonateWrite: user.canImpersonateWrite,
            userImpersonationWarning: user.isImpersonating,
            hasOpportunity,
            hasSubscribed,
            actifs,
            hasStripeCustomer: additionalInfos.hasStripeCustomer,
            paymentPlaform: sfAccount?.Plateforme_de_paiement__c,
          }, resolve);
        });
      }

      async componentDidMount() {
        EventSystem.subscribe("delete_account_popup", this.handlePopupDeleteAccount);
        EventSystem.subscribe("hide_subscription_bar", this.hideSubscriptionBar);
        EventSystem.subscribe("show_subscription_bar", this.showSubscriptionBar);
        EventSystem.subscribe("updateProfile", this.loadProfile);
          
        window.addEventListener("scroll", this.onScroll);
        this.props.auth.getUser().then((user) => {
          this.setState({ user : user });
        });

        AccountContextBehaviorSubject$.asObservable().subscribe(value => {
          if (value.account) {
            this.setState({ account: value.account });
          }
        });

        this.loadProfile();
      }

      async fetchAccountAmountOfContacts() {
        const url = "/api/data/getAccountAmountOfContacts";
        const options = { method: "GET" };
        return this.props.auth
          .fetch(url, options)
          .then((result) => result.actifs)
          .catch((error) => console.error(error));
      }

      componentWillUnmount() {
        EventSystem.unsubscribe("delete_account_popup", null);

        window.removeEventListener("scroll", this.onScroll);
      }

      handlePopupDeleteAccount() {
        this.setState({ deleteAccount: true });
      }

      render() {
        const {
          intl
        } = this.props;

        const {
          shopsMenuOpen,
          userCanImpersonateRead,
          userCanImpersonateWrite,
          userImpersonationWarning,
          accountList,
          error_text,
          hasStripeCustomer,
          paymentPlaform,
        } = this.state;

        const userMenuList = [
          {
            id: "submenu.profile",
            name: intl.messages["submenu.profile"],
            action: this.changeUrl("/myprofile"),
          },
          ...(
            userCanImpersonateRead ?
              [{
                id: "submenu.admin.account",
                name: "🦉 Accéder au compte...",
                action: () => this.handleAdminSwitchAccount(),
              }] :
              []
          ),
          {
            id: "submenu.yourIntegration",
            name: intl.messages["submenu.yourIntegration"],
            action: this.changeUrl("/integration")
          },
          ...(hasStripeCustomer || paymentPlaform === "Shopify store" ? 
            [{
              id: "submenu.yourSubscription",
              name: intl.messages["submenu.yourSubscription"],
              action: this.changeUrl("/subscription_info"),
            }] : []
          ),
          {
            id: "submenu.logout",
            name: intl.messages["submenu.logout"],
            action: this.handleLogout,
          },
        ];
        const localeMenuList = [
          {
            id: "submenu.locale.fr",
            name: "Français",
            action: () => this.updateLocale("fr"),
          },
          {
            id: "submenu.locale.en",
            name: "English",
            action: () => this.updateLocale("en"),
          },
          {
            id: "submenu.locale.es",
            name: "Español",
            action: () => this.updateLocale("es"),
          },
        ];

        if (this.state.account && !this.state.hasSubscribed) {
          const index = userMenuList.findIndex(userMenu => userMenu.id === "submenu.yourSubscription");
          if (index !== -1) {
            userMenuList.splice(index, 1);
          }
        }

        return (
          <div className={classes.root}>
            <AppBar
              dp="6"
              position="static"
              className={classNames(
                classes.appbar,
                this.state.showBarShadow ? classes.appbar_shadow : "",
                userImpersonationWarning &&
                    (userCanImpersonateWrite
                      ? classes.impersonationWarningWrite
                      : classes.impersonationWarningRead)
              )}
            >
              <Toolbar className={classes.toolbar}>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={3}
                  className={classes.toolbar}
                >
                  <PopupTO
                    open={this.state.popupFreemium}
                    stripe={true}
                    title="popup.title_freemium"
                    label="popup.wording_freemium"
                    handleClose={this.openCloseFreemium}
                    closingSubLabel={intl.messages["button.check_price"]}
                    buttons={[
                      {
                        onClick: this.openCloseFreemium,
                        label:
                            this.props.intl.messages[
                              "button.keep_freemium"
                            ],
                        alternate: true,
                        style: { boxShadow: "none", width: "210px" },
                      },
                      {
                        onClick: () =>
                          window.open(
                            "https://calendly.com/kiliba/premium",
                            "_blank"
                          ),
                        label:
                            this.props.intl.messages[
                              "button.make_appointment"
                            ],
                        alternate: false,
                        style: { boxShadow: "none", width: "210px" },
                      },
                    ]}
                  />    

                  <Grid item onClick={() => this.setState({ shopsMenuOpen: !shopsMenuOpen }) }>
                    <div className={classes.switchAccountButton} id="buttonSwitchAccount">
                      <span style={{ marginLeft: "15px", pointerEvents: "none" }} >
                        {this.state.company_name}
                      </span>
                      <span className={"fa-solid fa-chevron-down"} style={{ marginLeft: "8px", pointerEvents: "none", fontSize: 12, paddingTop: 5 }}/>
                    </div>
                  </Grid>                

                  <Grid
                    item
                    className={classes.button_submenu}
                    onClick={this.openLocaleMenuList}
                  >
                    <img
                      src={localeImages[intl.locale]}
                      alt="Locale selector"
                      className={classes.localeButton}
                      id="buttonLocaleMenu"
                    />
                  </Grid>

                  <Tooltip title={intl.messages["support.tooltip"]}>
                    <Grid
                      item
                      className={
                        classes.button_submenu + " fa-solid fa-question-circle"
                      }
                      onClick={this.redirectToSupport}
                    ></Grid>
                  </Tooltip>

                  <Grid
                    item
                    className={classes.button_submenu + " fa-solid fa-user-circle"}
                    onClick={this.openSubMenuList}
                    id="buttonUserMenu"
                  ></Grid>
                </Grid>
              </Toolbar>
            </AppBar>

            <ClickAwayListener onClickAway={this.handleClickAway}>
              <div className={classes.root_submenu}>
                <Fade in={shopsMenuOpen}>
                  <div className="submenutopbaralt">
                    <List disablePadding>
                      {accountList.map((item, i) => {
                        return (
                          <Fragment key={item.id}>
                            <ListItem
                              button
                              onClick={() => this.switchAccount(item.id)}
                              className={classes.submenu_button}
                            >
                              {item.name}
                            </ListItem>
                            <Divider />
                          </Fragment>
                        );
                      })}
                      {
                        <ListItem
                          button
                          onClick={this.openClosePopupLinkNewShop}
                          className={classes.submenu_button}
                        >
                          {intl.messages["topBar.addShop"]}
                        </ListItem>
                      }
                    </List>
                  </div>
                </Fade>
              </div>
            </ClickAwayListener>

            <DropDownMenu
              list={localeMenuList}
              menuIsOpen={this.state.localeMenuListOpen}
              handleClickAway={this.handleClickAway}
              selectedKey={`submenu.locale.${this.state.user_locale}`}
              style={{ marginRight: 85 }}
            />

            <DropDownMenu
              list={userMenuList}
              menuIsOpen={this.state.userMenuListOpen}
              handleClickAway={this.handleClickAway}
            />

            <PopupTO
              open={this.state.popupLinkNewShop}
              title="popup.title_add_shop"
              label="popup.wording_add_shop"
              styleWording={{ marginBottom: "25px" }}
              handleClose={this.openClosePopupLinkNewShop}
              close
              form={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  spacing={2}
                >
                  {
                    /// Error message ///
                    !!error_text && (
                      <Grid item>
                        <ResultLabelTO
                          label={error_text}
                          error
                          visible
                          style={{
                            marginTop: "24px",
                            marginLeft: "64px",
                            paddingTop: "3px",
                            marginRight: "64px",
                          }}
                          inactive_style={{
                            marginTop: "24px",
                            paddingTop: "3px",
                            backgroundColor: "transparent",
                          }}
                        />
                      </Grid>
                    )
                  }
                  <Grid item>
                    <TextField
                      id="email"
                      style={{ width:"200px"}}
                      placeholder="Email"
                      value={this.state.email}
                      onChange={(e) =>
                        this.setState({ email: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="password"
                      style={{ width:"200px"}}
                      type="password"
                      value={this.state.password}
                      placeholder="Mot de passe"
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </Grid>
                </Grid>
              }
              buttons={[
                {
                  onClick: this.linkNewShop,
                  label: intl.messages["addShop.submit"],
                  alternate: false,
                  style: { boxShadow: "none" },
                },
              ]}
            />
          </div>
        );
      }
    }
  )
);
