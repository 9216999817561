export const usecaseLocalDatas = [
  {
    name: "postVisits",
    stats: [
      { value: 230, iconName: "envelope-open" },
      { value: 350, iconName: "envelope-open" },
    ],
    explanations: [
      {
        // an explanation key refers to an image and a wording
        // images are shared between usecases whereas wordings are unique to each usecase
        key: "target",
      },
      {
        key: "senddate",
        // used to display a mail preview
        templateDataKey: 0,
      },
      {
        key: "algo",
      },
    ],
  },
  {
    name: "newProducts",
    stats: [{ value: 32, iconName: "glasses" }],
    explanations: [
      { key: "senddate" },
      { key: "target" },
      { key: "algo", templateDataKey: 0 },
    ],
  },
  {
    name: "crossSelling",
    stats: [
      { value: 60, iconName: "shopping-basket" },
      { value: 35, iconName: "users" },
    ],
    explanations: [
      { key: "target" },
      { key: "clients" },
      { key: "senddate", templateDataKey: 0 },
      { key: "algo" },
    ],
  },
  {
    name: "abandonedCarts",
    stats: [
      { value: 41, iconName: "shopping-basket" },
      { value: 70, iconName: "shopping-basket" },
    ],
    explanations: [
      { key: "target" },
      { key: "clients" },
      { key: "senddate", templateDataKey: 0 },
      { key: "algo" },
      { key: "basket" },
    ],
  },
  {
    name: "promotions",
    stats: [{ value: 91, iconName: "shopping-basket" }],
    explanations: [
      { key: "target" },
      { key: "clients" },
      { key: "senddate", templateDataKey: 0 },
    ],
  },
  {
    name: "birthday",
    stats: [{ value: 3, iconName: "mouse-pointer" }],
    explanations: [{ key: "gift", templateDataKey: 0 }],
  },
  {
    name: "anniversaryRegistration",
    stats: [{ value: 3, iconName: "mouse-pointer" }],
    explanations: [{ key: "gift", templateDataKey: 0 }],
  },
  {
    name: "bestClients",
    stats: [{ value: 80, iconName: "users" }],
    explanations: [
      { key: "target" },
      { key: "senddate" },
      { key: "clients", templateDataKey: 0 },
    ],
  },
  {
    name: "welcome",
    stats: [{ value: 78, iconName: "heart" }],
    explanations: [
      { key: "target", templateDataKey: 0 },
      { key: "clients" },
      { key: "senddate" },
    ],
  },
  {
    name: "mothersDay",
    stats: [{ value: 50, iconName: "shopping-basket" }],
    explanations: [{ key: "mothersdayletter", templateDataKey: 0 }],
  },
  {
    name: "fathersDay",
    stats: [{ value: 50, iconName: "heart" }],
    explanations: [{ key: "dadtshirt", templateDataKey: 0 }],
  },
  {
    name: "winterSales",
    stats: [{ value: 223, iconName: "shopping-basket" }],
    explanations: [
      { key: "letter1", templateDataKey: 0 },
      { key: "letter2", templateDataKey: 1 },
      { key: "letter3", templateDataKey: 2 },
    ],
  },
  {
    name: "summerSales",
    stats: [{ value: 223, iconName: "shopping-basket" }],
    explanations: [
      { key: "letter1", templateDataKey: 0 },
      { key: "letter2", templateDataKey: 1 },
      { key: "letter3", templateDataKey: 2 },
    ],
  },
  {
    name: "blackFriday",
    stats: [{ value: 94, iconName: "shopping-basket" }],
    explanations: [
      { key: "letter1", templateDataKey: 0 },
      { key: "letter2", templateDataKey: 1 },
      { key: "letter3", templateDataKey: 2 },
    ],
  },
  {
    name: "backToSchool",
    stats: [{ value: 484, iconName: "child" }],
    explanations: [{ key: "backpack", templateDataKey: 0 }],
  },
  {
    name: "newYear",
    stats: [{ value: 2, iconName: "smile-beam" }],
    explanations: [{ key: "partyhorn", templateDataKey: 0 }],
  },
  {
    name: "valentinesDay",
    stats: [{ value: 52, iconName: "bullseye-pointer" }],
    explanations: [
      { key: "cupid", templateDataKey: 0 },
      { key: "valentinesheart", templateDataKey: 1 },
    ],
  },
  {
    name: "christmas",
    stats: [{ value: 549, iconName: "gift" }],
    explanations: [
      { key: "santaclaus", templateDataKey: 0 },
      { key: "gifts", templateDataKey: 1 },
    ],
  },
  {
    name: "halloween",
    stats: [{ value: 56, iconName: "users" }],
    explanations: [
      { key: "witchshat", templateDataKey: 0 },
      { key: "pumpkin", templateDataKey: 1 },
    ],
  },
  {
    name: "ephemeris",
    stats: [{ value: 6, iconName: "shopping-cart" }],
    explanations: [
      { key: "calendar" },
      { key: "partyhorn", templateDataKey: 0 },
      { key: "algo" },
    ],
  },
  {
    name: "booster",
    stats: [{ value: 35, iconName: "users" }],
    explanations: [{ key: "target" }, { key: "algo", templateDataKey: 0 }],
  },
  {
    name: "sponsorship",
    stats: [{ value: 92, iconName: "users" }],
    explanations: [
      {
        key: "target",
        templateDataKey: 0,
        mustDisablePromoCodeTemplateOpener: true,
      },
      {
        key: "sponsorship",
        templateDataKey: 1,
        mustDisablePromoCodeTemplateOpener: true,
      },
      {
        key: "groupgifts",
        templateDataKey: 2,
        mustDisablePromoCodeTemplateOpener: true,
      },
      {
        key: "groupgifts0",
        templateDataKey: 3,
        mustDisablePromoCodeTemplateOpener: true,
      },
    ],
  },
  {
    name: "custom",
    stats: [],
    explanations: [],
  },
  {
    name: "welcomeAi",
    stats: [{ value: 78, iconName: "heart" }],
    explanations: [
      { key: "target", templateDataKey: 0 },
    ],
  },
];
