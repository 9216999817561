
export const templateEditorUtils = {

  deletePreviewBlocks: (editor, except = []) => {
    const components = [];
    
    try {
      editor?.getComponents().at(0)?.onAll(component => {
        if (
          component.attributes.type.startsWith("preview-")
        ) {
          if (!except.find(elem => elem.getId() === component.getId())) {
            components.push(component);
          }
        }
      });

      components.forEach(component => {
        editor.UndoManager.stop();
        component.remove();
        editor.UndoManager.start();
      });
    } catch {}
  },

  deleteColumnPreviewBlocks: (editor, except = []) => {
    const components = [];
    
    try {
      editor?.getComponents().at(0)?.onAll(component => {
        if (
          component.attributes.type.startsWith("column-preview-")
        ) {
          if (!except.find(elem => elem.getId() === component.getId())) {
            components.push(component);
          }
        }
      });

      components.forEach(component => {
        editor.UndoManager.stop();
        component.remove();
        editor.UndoManager.start();
      });
    } catch {}
  }
};