import React, { Component } from "react";

import { Dialog } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { injectIntl } from "react-intl";
import classes from "./UsecasePopupEmailEditor.module.scss";

import { FormButtonTO } from "../../../../FormButtonTO.jsx";

import { UsecasePopupEmailEditorPreview } from "./UsecasePopupEmailEditorPreview.js";
import { UsecasePopupEmailEditorForm } from "./UsecasePopupEmailEditorForm.js";
import { TranslateWordingPopup } from "./TranslateWordingPopup";

import { IconButton } from "@material-ui/core";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import LaptopIcon from "@material-ui/icons/Laptop";
import { EmptyState } from "../../../../common/EmptyState/EmptyState.js";
import classNames from "classnames";

export const UsecasePopupEmailEditor =
  injectIntl(
    class UsecasePopupEmailEditor extends Component {
      constructor(props) {
        super(props);
        this.changeWording = this.changeWording.bind(this);
        this.changeGeneratedWording = this.changeGeneratedWording.bind(this);
        this.deleteGeneratedWording = this.deleteGeneratedWording.bind(this);
        this.changeHeadbandImgUrls = this.changeHeadbandImgUrls.bind(this);
        this.changeHeadbandLink = this.changeHeadbandLink.bind(this);
        this.renderPreviewSwitch = this.renderPreviewSwitch.bind(this);
        this.reset = this.reset.bind(this);
        this.getSelectedDeviceType = this.getSelectedDeviceType.bind(this);
        this.getWordingsToTranslate = this.getWordingsToTranslate.bind(this);

        const availableLanguages = props.availableLanguages.reduce((acc, language) => ({
          ...acc,
          [language]: props.intl.messages[`availableLanguages.${language}`].toLowerCase(),
        }), {});

        const selectedLanguageKey = Object.keys(availableLanguages).find(
          (languageKey) => languageKey === props.intl.locale
        )
          ? props.intl.locale
          : "en";

        this.state = {
          templateDataKey: null,
          selectedLanguageKey: selectedLanguageKey,
          wordings: undefined,
          generatedWordings: undefined,
          subject: undefined,
          tabKey: "customizer",
          selectedDeviceType: "desktop",
          headbandImgUrls: undefined,
          headbandImgFile: undefined,
          headbandLink: undefined,
          availableLanguages: availableLanguages,
          isWordingsLoading: undefined,
          translateWordingPopupIsOpen: false,
          translateWordingsCompare: null,
          languageAfterTranslation: null,
          forceReloadGeneratedWordings: false,
        };
      }

      static getDerivedStateFromProps(props, state) {
        const newState = {};

        const noEmailIsSelected = props.templateDataKey === null;
        const hasSelectedAnotherEmail = props.templateDataKey !== state.templateDataKey;
        const hasChangedWordings = JSON.stringify(props.wordings) !== JSON.stringify(state.wordings);
        const hasChangedGeneratedWordings = JSON.stringify(props.generatedWordings) !== JSON.stringify(state.generatedWordings);
        const hasChangedHeadbandImgUrls = JSON.stringify(props.headbandImgUrls) !== JSON.stringify(state.headbandImgUrls);
        const selectedEmailHasNoCustomWording = props.wordings === null;
        const hasSelectedEmail = !!props.wordings;
        const hasGeneratedWordingsLoaded = props.isWordingsLoading === false && state.isWordingsLoading === true;
        const hasChangedHeadbandLink = props.headbandLink !== state.headbandLink;

        if (noEmailIsSelected) throw new Error("No email is selected.");

        newState.isWordingsLoading = props.isWordingsLoading;
        newState.forceReloadGeneratedWordings = false;

        if (hasSelectedAnotherEmail) {
          newState.templateDataKey = props.templateDataKey;
          newState.subject = props.usecase.templateDatas[props.templateDataKey].wordings[state.selectedLanguageKey].plural[props.tone].subject;
          newState.wordings = JSON.parse(JSON.stringify(props.wordings || {}));
          newState.generatedWordings = JSON.parse(JSON.stringify(props.generatedWordings || {}));
          newState.headbandImgUrls = props.headbandImgUrls;
          newState.headbandLink = props.headbandLink;
          return newState;
        }
        if (hasChangedWordings || hasChangedHeadbandImgUrls || hasChangedGeneratedWordings || hasChangedHeadbandLink) {
          if (hasChangedWordings) {
            newState.wordings = state.wordings;
          }
          if (hasChangedHeadbandImgUrls) {
            newState.headbandImgUrls = state.headbandImgUrls;
          }
          if (hasChangedGeneratedWordings) {
            if (hasGeneratedWordingsLoaded || state.forceReloadGeneratedWordings) {
              newState.generatedWordings = props.generatedWordings;
            } else {
              newState.generatedWordings = state.generatedWordings;
            }
          }
          if (hasChangedHeadbandLink) {
            newState.headbandLink = state.headbandLink;
          }
          return newState;
        }
        if (selectedEmailHasNoCustomWording) {
          newState.wordings = {};
          return newState;
        }
        if (hasSelectedEmail) {
          newState.headbandImgUrls = props.headbandImgUrls;
          newState.wordings = JSON.parse(JSON.stringify(props.wordings));
          newState.generatedWordings = JSON.parse(JSON.stringify(props.generatedWordings || {}));
          newState.headbandLink = props.headbandLink;
          return newState;
        }

        return null;
      }

      componentDidMount() {
        this.setState({ headbandImgUrls: this.props.headbandImgUrls });

        if (this.props.tabKey) {
          this.setState({ tabKey: this.props.tabKey });
        }
      }

      changeWording(newValue = null, language, number, tone, wordingKey) {
        this.setState(prevState => {
          const newWordings = JSON.parse(JSON.stringify(prevState.wordings));

          if (!newWordings[language]) newWordings[language] = {};
          if (!newWordings[language][number]) newWordings[language][number] = {};
          if (!newWordings[language][number][tone])
            newWordings[language][number][tone] = {};

          newWordings[language][number][tone][wordingKey] = newValue;

          return {
            wordings: newWordings,
          };
        });
        
        this.deleteGeneratedWording(language, tone, wordingKey);
      }

      changeGeneratedWording(newValue, language, tone, wordingKey) {
        const newGeneratedWordings = JSON.parse(JSON.stringify(this.state.generatedWordings));

        if (!newGeneratedWordings[language]) newGeneratedWordings[language] = {};
        if (!newGeneratedWordings[language][tone]) newGeneratedWordings[language][tone] = {};

        if (newValue) {
          newGeneratedWordings[language][tone][wordingKey] = newValue;
        } else if (this.props.generatedWordings && this.props.generatedWordings[language] && this.props.generatedWordings[language][tone] && this.props.generatedWordings[language][tone]) {
          newGeneratedWordings[language][tone][wordingKey] = this.props.generatedWordings[language][tone][wordingKey];
        } else {
          newGeneratedWordings[language][tone][wordingKey] = null;
        }

        this.setState({
          generatedWordings: newGeneratedWordings,
        });
      }

      deleteGeneratedWording(language, tone, wordingKey) {
        const newGeneratedWordings = JSON.parse(JSON.stringify(this.state.generatedWordings));

        if (!newGeneratedWordings[language]) newGeneratedWordings[language] = {};
        if (!newGeneratedWordings[language][tone]) newGeneratedWordings[language][tone] = {};

        newGeneratedWordings[language][tone][wordingKey] = null;

        this.setState({
          generatedWordings: newGeneratedWordings,
        });
      }

      changeHeadbandImgUrls(newValue = null, language) {
        const newHeadbandImgUrls = JSON.parse(
          JSON.stringify(this.state.headbandImgUrls)
        );
        newHeadbandImgUrls[language] = newValue;

        this.setState({ headbandImgUrls: newHeadbandImgUrls });
      }

      changeHeadbandLink(newValue) {
        this.setState({ headbandLink: newValue });
      }

      reset() {
        this.setState({
          templateDataKey: null,
          wordings: undefined,
          generatedWordings: undefined,
          headbandImgUrls: undefined,
        });
        this.props.close();
      }

      changePreview() {
        if (this.state.tabKey === "customizer") {
          this.setState({ tabKey: "preview" });
        }
        if (this.state.tabKey === "preview") {
          this.setState({ tabKey: "customizer" });
        }
      }

      getSelectedDeviceType(device) {
        this.setState({ selectedDeviceType: device });
      }

      renderPreviewSwitch() {
        const {
          emailExamples,
          usecase,
          templateDataKey,
          tone,
          noCms,
          isWordingsLoading,
          wordingsGenerationHasError,
          isFirstWordingGeneration,
          onFirstGenerationEnds,
        } = this.props;

        if (!emailExamples || !this.state.wordings || !usecase) return null;
        const emailExample = emailExamples[templateDataKey];

        let formattedHtml = emailExample.htmls[this.state.selectedLanguageKey]["plural"][tone].replace(/href="https:\/\/www\.kiliba\.com"/g, "");
        formattedHtml = formattedHtml.replace(/href="http:\/\/www\.kiliba\.com\/"/g, "");

        switch (this.state.tabKey) {
          case "preview":
            return !noCms ? (
              <UsecasePopupEmailEditorPreview
                templateName={`${usecase.name}${templateDataKey}`}
                language={this.state.selectedLanguageKey}
                number={"plural"}
                tone={tone}
                wordings={this.state.wordings}
                generatedWordings={this.state.generatedWordings}
                selectedDeviceType={this.state.selectedDeviceType}
                headbandImgUrls={this.state.headbandImgUrls}
                emailExample={emailExample}
                headbandLink={this.state.headbandLink}
              />
            ) : (
              <EmptyState variant="noCmsEmailPreview" />
            );
          case "customizer":
            return (
              <UsecasePopupEmailEditorForm
                subject={this.state.subject}
                template={formattedHtml}
                templateDataKey={templateDataKey}
                language={this.state.selectedLanguageKey}
                number={"plural"}
                tone={tone}
                usecaseName={usecase.name}
                templateName={usecase.name + (usecase.templateDatas.length > 1 ? templateDataKey : "")}
                wordings={this.state.wordings}
                generatedWordings={this.state.generatedWordings}
                changeWording={this.changeWording}
                changeGeneratedWording={this.changeGeneratedWording}
                isWordingsLoading={isWordingsLoading}
                wordingsGenerationHasError={wordingsGenerationHasError}
                isFirstWordingGeneration={isFirstWordingGeneration}
                onFirstGenerationEnds={onFirstGenerationEnds}
                headbandImgUrls={this.state.headbandImgUrls}
                changeHeadbandImgUrls={this.changeHeadbandImgUrls}
                headbandLink={this.state.headbandLink}
                changeHeadbandLink={this.changeHeadbandLink}
                setHeadbandImgFile={this.props.setHeadbandImgFile}
                betaFeatures={this.props.betaFeatures}
              />
            );
          default:
            return null;
        }
      }

      getWordingsToTranslate() {
        const {
          tone,
          generatedWordings: currentGeneratedWordings,
          wordings: currentWordings,
        } = this.props;
        const {
          generatedWordings,
          wordings,
          selectedLanguageKey,
          translateWordingsCompare
        } = this.state;

        const wordingsToTranslate = {};
        const keys = ["title", "subject", "header", "subtitle1", "subtitle2", "description"];
        const newTranslateWordingsCompare = {};

        keys.forEach(key => {

          let translateWording = null;

          if (!translateWordingsCompare) {

            let currentWording = null;
            
            if (!translateWordingsCompare && currentGeneratedWordings &&
              currentGeneratedWordings[selectedLanguageKey] &&
              currentGeneratedWordings[selectedLanguageKey][tone] &&
              currentGeneratedWordings[selectedLanguageKey][tone][key]) {
              currentWording = 
                currentGeneratedWordings[this.state.selectedLanguageKey][tone][key].wording;
            }

            if (!currentWording) {
              if (!translateWordingsCompare && currentWordings &&
                currentWordings[selectedLanguageKey] && 
                currentWordings[selectedLanguageKey].plural &&
                currentWordings[selectedLanguageKey].plural[tone] &&
                currentWordings[selectedLanguageKey].plural[tone][key]) {
                currentWording = 
                  currentWordings[this.state.selectedLanguageKey].plural[tone][key];
              }
            }
              
            newTranslateWordingsCompare[key] = currentWording;

          } else {

            let currentWording;
            
            if (
              generatedWordings &&
              generatedWordings[selectedLanguageKey] &&
              generatedWordings[selectedLanguageKey][tone] &&
              generatedWordings[selectedLanguageKey][tone][key]
            ) {
              currentWording = 
                generatedWordings[this.state.selectedLanguageKey][tone][key].wording;
            }

            if (
              !currentWording &&
              wordings &&
              wordings[selectedLanguageKey] &&
              wordings[selectedLanguageKey].plural &&
              wordings[selectedLanguageKey].plural[tone] &&
              wordings[selectedLanguageKey].plural[tone][key]
            ) {
              currentWording = 
                wordings[selectedLanguageKey].plural[tone][key];
            }

            if (currentWording !== translateWordingsCompare[key]) {
              translateWording = currentWording;
            }
          }

          if (translateWording) {
            wordingsToTranslate[key] = translateWording;
          }
        });

        if (Object.keys(newTranslateWordingsCompare).length) {
          this.setState({ translateWordingsCompare: newTranslateWordingsCompare });
        }

        return wordingsToTranslate;
      }

      render() {
        const {
          emailExamples,
          usecase,
          templateDataKey,
          tone,
          saveCustomization,
          intl,
        } = this.props;

        if (!emailExamples || !this.state.wordings || !usecase) return null;

        const translateWordings = this.getWordingsToTranslate();

        return (
          <>
            <Dialog
              open={true}
              onClose={() => {
                this.reset();
                this.props.close();
              }}
              PaperProps={{
                style: {
                  height: "calc(100% - 64px)",
                  maxWidth: "90%",
                  display: "block",
                  overflow: "overlay",
                  margin:"0 0 0 22vh"
                },
              }}
              BackdropProps={{ style: { opacity: 0.8 } }}
              data-cy="usecase-popup-email-example"
            >
              <UsecasePopupEmailEditorHeader
                close={() => {
                  this.reset();
                  this.props.close();
                }}
                selectedLanguageKey={this.state.selectedLanguageKey}
                changeSelectedLanguageKey={(value) =>
                  this.setState({
                    selectedLanguageKey: value,
                    subject:
                      usecase.templateDatas[templateDataKey] &&
                      usecase.templateDatas[templateDataKey].wordings[value]
                        .plural[tone].subject,
                  })
                }
                saveCustomization={async () => {
                  await saveCustomization(
                    this.state.wordings,
                    this.state.generatedWordings,
                    this.state.headbandLink,
                  );
                  this.setState({ forceReloadGeneratedWordings: true });
                }}
                changePreview={() => this.changePreview()}
                isPreview={this.state.tabKey}
                selectedDeviceType={this.state.selectedDeviceType}
                getSelectedDeviceType={this.getSelectedDeviceType}
                availableLanguages={this.state.availableLanguages}
                openTranslatePopup={
                  Object.keys(translateWordings).length && 
                  this.state.selectedLanguageKey === intl.locale ? 
                    () => this.setState({ translateWordingPopupIsOpen: true }) : null
                }
                setLanguageAfterTranslation={
                  value => this.setState({ languageAfterTranslation: value })
                }
                usecaseName={usecase.name}
              />
              {this.renderPreviewSwitch()}
            </Dialog>
            <TranslateWordingPopup
              isOpen={this.state.translateWordingPopupIsOpen}
              close={() => this.setState({
                translateWordingPopupIsOpen: false,
                shouldOpenTranslatePopup: false,
                translateWordingsCompare: {
                  ...this.state.translateWordingsCompare,
                  ...translateWordings
                },
                selectedLanguageKey: this.state.languageAfterTranslation,
                languageAfterTranslation: null,
              })}
              language={this.state.selectedLanguageKey}
              wordings={translateWordings}
              setTranslatedWordings={translationsWordings =>
                Object.entries(translationsWordings).forEach(([key, translations]) =>
                  Object.entries(translations).forEach(([language, newWording]) =>
                    this.changeWording(newWording, language, "plural", this.props.tone, key))
                )
              }
              changePreview={() => this.changePreview()}
              isPreview={this.state.tabKey}
              selectedDeviceType={this.state.selectedDeviceType}
              getSelectedDeviceType={this.getSelectedDeviceType}
              availableLanguages={this.state.availableLanguages}
              usecaseName={usecase.name}
            />
          </>
        );
      }
    }
  );

const UsecasePopupEmailEditorHeader =
  injectIntl(
    class UsecasePopupEmailEditorHeader extends Component {
      render() {
        const {
          intl,
          close,
          selectedLanguageKey,
          changeSelectedLanguageKey,
          saveCustomization,
          changePreview,
          isPreview,
          availableLanguages,
          usecaseName,
          openTranslatePopup,
          setLanguageAfterTranslation,
        } = this.props;

        const dropdown = (
          <UsecasePopupEmailEditorDropdown
            availableLanguages={availableLanguages}
            selectedLanguageKey={selectedLanguageKey}
            changeSelectedLanguageKey={changeSelectedLanguageKey}
            usecaseName={usecaseName}
            openTranslatePopup={openTranslatePopup}
            setLanguageAfterTranslation={setLanguageAfterTranslation}
          />
        );

        return (
          <div className={classes.header} data-cy="header">
            {
              <i
                onClick={close}
                className={`${classes.headerItem} fal fa-times`}
                data-cy="close"
              ></i>
            }
            <div className={classes.containerTextHeader}>
              {isPreview === "preview" ? <div className={classes.dropdownContainer}>{intl.messages["emailEditor.head.preview.label.0"]}{dropdown}</div> :
                <div>
                  <div className={classes.dropdownContainer}>
                    {intl.messages["emailEditor.head.editor.label.0"]}{dropdown}{intl.messages["emailEditor.head.editor.label.1"]}
                  </div>
                </div>}

              {isPreview === "preview" ? (
                <div className={classes.icons}>
                  <IconButton
                    aria-label="Desktop"
                    onClick={() => {
                      this.props.getSelectedDeviceType("desktop");
                    }}
                    className={this.props.selectedDeviceType === "desktop" ? classes.primaryYellow200 : classes.lightgray}
                  >
                    <LaptopIcon fontSize="large" style={{ fontSize: "50px" }} />
                  </IconButton>
                  <IconButton
                    aria-label="Mobile"
                    onClick={() => {
                      this.props.getSelectedDeviceType("mobile");
                    }}
                    className={this.props.selectedDeviceType === "mobile" ? classes.primaryYellow200 : classes.lightgray}
                  >
                    <SmartphoneIcon fontSize="large" />
                  </IconButton>
                </div>
              ) : null}
            </div>
            <div data-cy="switch-tabs">
              <FormButtonTO
                type={"tertiary"}
                style={{ marginLeft: 32 }}
                label={
                  intl.messages[
                    isPreview === "preview"
                      ? "button.email_edit"
                      : "button.email_preview"
                  ]
                }
                action={changePreview}
              />
            </div>
            <div data-cy="save-customization">
              <FormButtonTO
                type={"primary"}
                style={{ marginLeft: 15 }}
                label={intl.messages["button.save"]}
                action={saveCustomization}
              />
            </div>
          </div>
        );
      }
    }
  );

const UsecasePopupEmailEditorDropdown = injectIntl(
  class UsecasePopupEmailEditorDropdown extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isOpen: false,
      };
    }

    render() {
      const {
        availableLanguages,
        selectedLanguageKey,
        changeSelectedLanguageKey,
        openTranslatePopup,
        setLanguageAfterTranslation,
      } = this.props;

      return (
        <ClickAwayListener onClickAway={() => this.setState({ isOpen: false })}>
          <div>
            <div
              className={classes.dropdownValue}
              onClick={() => Object.keys(availableLanguages).length > 1 && 
                this.setState({ isOpen: !this.state.isOpen })}
            >
              {availableLanguages[selectedLanguageKey]}
              {Object.keys(availableLanguages).length > 1 &&
                <i
                  className={classes.dropdownValueIcon + " fal fa-angle-down"}
                  style={{
                    transform: this.state.isOpen ? "rotate(180deg)" : null,
                  }}
                ></i>
              }
              {this.state.isOpen ? (
                <div className={classes.dropdownItems}>
                  {Object.keys(availableLanguages).map(
                    (availableLanguageKey, index) => (
                      <div
                        key={index}
                        className={classes.dropdownItem}
                        onClick={() => {
                          if (openTranslatePopup) {
                            openTranslatePopup();
                            setLanguageAfterTranslation(availableLanguageKey);
                          } else {
                            changeSelectedLanguageKey(availableLanguageKey);
                            this.setState({ isOpen: false });
                          }
                        }}
                      >
                        {availableLanguages[availableLanguageKey]}
                      </div>
                    )
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </ClickAwayListener>
      );
    }
  }
);
