import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

import { styles } from "./Form.styles";

export const Form = withStyles(styles)(injectIntl(
	class extends Component {
		render() {
			const {
				classes,
				children,
			} = this.props;

			return (
				<div className={classes.form}>
					{ children }
				</div>
			);
		}
	}
));