import { Component } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  ContentState,
  Entity,
  Modifier,
  SelectionState,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import htmlToDraft from "html-to-draftjs";

import { withStyles } from "@material-ui/core/styles";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { palette } from "../../../../../styles/palette.js";

import { HtmlCustomizerTooltip } from "../../common/HtmlCustomizerTooltip/HtmlCustomizerTooltip.js";
import { htmlCustomizerTooltipStyleValues } from "../../common/HtmlCustomizerTooltip/htmlCustomizerTooltipStyleValues.js";
import { HtmlCustomizerTooltipInputStyle } from "../../common/HtmlCustomizerTooltip/HtmlCustomizerTooltipInputs/HtmlCustomizerTooltipInputStyle.js";
import { HtmlCustomizerTooltipInputKeyword } from "../../common/HtmlCustomizerTooltip/HtmlCustomizerTooltipInputs/HtmlCustomizerTooltipInputKeyword.js";
import { HtmlCustomizerTooltipInputFontSize } from "../../common/HtmlCustomizerTooltip/HtmlCustomizerTooltipInputs/HtmlCustomizerTooltipInputFontSize.js";
import { HtmlCustomizerTooltipAiButton } from "../../common/HtmlCustomizerTooltip/HtmlCustomizerTooltipInputs/HtmlCustomizerTooltipAiButton.js";
import { HtmlCustomizerTooltipInputLink } from "../../common/HtmlCustomizerTooltip/HtmlCustomizerTooltipInputs/HtmlCustomizerTooltipInputLink.js";
import { HtmlCustomizerLinkForm } from "../common/HtmlCustomizerLink.js";
import { HtmlCustomizerLinkEdit } from "../common/HtmlCustomizerLink.js";

const styles = (theme) => {
  const { inputVerticalPadding, inputHorizontalPadding } =
    htmlCustomizerTooltipStyleValues;

  return {
    editorWrapper: {
      "& .public-DraftEditorPlaceholder-root": {
        display: "none",
      },
      "&:hover": {
        "& .public-DraftEditorPlaceholder-root": {
          display: "block",
        },
        "& .public-DraftEditorPlaceholder-hasFocus": {
          display: "none",
        },
      },
      "& .DraftEditor-root span": {
        overflowWrap: "inherit",
        whiteSpace: "inherit",
      },
      "& .DraftEditor-editorContainer": {
        width: `calc(100% + ${inputHorizontalPadding * 2}px)`,
      },
      "& .public-DraftEditor-content": {
        position: "absolute",
        zIndex: 1,
        top: `-${inputVerticalPadding}px`,
        left: `-${inputHorizontalPadding}px`,
        padding: `${inputVerticalPadding}px ${inputHorizontalPadding}px`,
        margin: 0,
        width: "100%",
        "&:hover, &:focus": {
          backgroundImage: props => !props.disableBorder ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23${palette.purple.replace(
            "#",
            ""
          )}' stroke-width='2' stroke-dasharray='10%2c10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")` : null,
          borderRadius: 4,
          "&::placeholder": {
            color: palette.graymagic,
          },
        },
        paddingBottom: props => props.paddingBottom || inputVerticalPadding,
      },
    },
    inputWrapper: {
      position: "absolute",
      width: "100%",
    },
    linkContainer: {
      position: "absolute",
    }
  };
};

export const HtmlCustomizerTextField = withStyles(styles)(
  class HtmlCustomizerTextField extends Component {
    constructor(props) {
      super(props);

      this.setValueFromEditorState = this.setValueFromEditorState.bind(this);
      this.insertKeyword = this.insertKeyword.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.onFocus = this.onFocus.bind(this);
      this.onClickAway = this.onClickAway.bind(this);
      this.onInsertLink = this.onInsertLink.bind(this);
      this.openLink = this.openLink.bind(this);
      this.onDeleteLink= this.onDeleteLink.bind(this);
      this.onEditorClick = this.onEditorClick.bind(this);

      const formattedValue = props.value
        // workaround for lib's defect: it uses wrong tags
        // see https://github.com/jpuri/html-to-draftjs/issues/103 for more informations
        .replaceAll("<u", "<ins")
        .replaceAll("</u", "</ins")
        .replaceAll("<b>", "<strong>")
        .replaceAll("</b>", "</strong>")
        .replaceAll("<span><br></span>", "<span><br/></span>");
      const contentBlock = htmlToDraft(formattedValue);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      const styleInputTypes = ["BOLD", "ITALIC", "STRIKETHROUGH", "UNDERLINE"];
      const keywords = props.keywords;
      const fontSizes = [
        8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 38, 46, 54, 62, 72,
      ];

      // custom style options, such as bold or italic
      const styleMap = {};

      fontSizes.forEach((fontSize) => {
        styleMap[`fontsize-${fontSize}`] = {
          fontSize: fontSize,
        };
      });
      this.state = {
        tooltipIsOpen: false,
        styleMap,
        customizationOptions: {
          styleInputTypes,
          keywords,
          fontSizes,
        },
        editorState: editorState,
        inputWrapperStyle: props.style,
        linkFormIsOpen: false,
        linkEditIsOpen: false,
        link: "",
        linkError: false,
        linkSelection: null,
        linkEntityId: null,
        linkEntityOffset: null,
        tooltipPosition: 0,
      };
    }

    removeFontSize() {
      const { editorState, customizationOptions } = this.state;

      let newEditorState = editorState;

      customizationOptions.fontSizes.forEach((fontSize) => {
        const sizeKey = `fontsize-${fontSize}`;
        // in order to remove styles nested in the selection (eg: "bc" in "abcd"), set these styles on the whole selection.
        // this way, styles on "bc" will be applied on "abcd" and therefore removed from "bc".
        newEditorState = RichUtils.toggleInlineStyle(
          newEditorState,
          `fontsize-${fontSize}`
        );
        if (newEditorState.getCurrentInlineStyle().has(sizeKey)) {
          newEditorState = RichUtils.toggleInlineStyle(
            newEditorState,
            `fontsize-${fontSize}`
          );
        }
      });

      return newEditorState;
    }

    setValueFromEditorState(editorState) {
      this.setState({ editorState });
      const options = {
        inlineStyles: {},
      };
      this.state.customizationOptions.fontSizes.forEach((fontSize) => {
        options.inlineStyles[`fontsize-${fontSize}`] = {
          element: "span",
          style: {
            fontSize: fontSize,
          },
        };
      });
      const htmlString = stateToHTML(editorState.getCurrentContent(), options)
        // value may be used in an element that does not support block contents such as a p, so all tags in value should
        // be phrasing contents, which are better supported. see https://html.spec.whatwg.org/#phrasing-content for more informations
        .replaceAll("<p", "<span")
        .replaceAll("</p", "</span")
        // add a br at the end of spans that don't have one to avoid layout glitches
        .replace(/(<a[^>]*)(title="[^>]*")([^>]*>)/g, (match, group1, group2, group3) => `${group1}${group3}`)
        .replaceAll("\n", "");
      const parser = new DOMParser();

      // add a newline at the end of lines that don't have any. draftjs aligns spans vertically, but outside of draftjs, spans are aligned horizontally
      const linesElement = parser.parseFromString(htmlString, "text/html").body;
      const lines = [...linesElement.children];

      lines.forEach((line, key) => {
        if (key < lines.length - 1 && !line.innerHTML.endsWith("<br>") && !line.innerHTML.endsWith("<br/>")) {
          const newlineElement = document.createElement("span");
          newlineElement.innerHTML = "<br/>";
          line.insertAdjacentElement("afterend", newlineElement);
        }
      });
      const html = parser.parseFromString(linesElement.outerHTML, "text/html");

      this.props.setValue(html.documentElement.querySelector("body").innerHTML);
    }

    formatLangKeyword(keyword){
      let trueKeyword;
      if (keyword === "#PRENOM#" || keyword === "#NOMBRE#"){ trueKeyword = "#FIRSTNAME#"; }
      else if (keyword === "#NOM#" || keyword === "#APELLIDO#"){ trueKeyword = "#LASTNAME#"; }
      else if (keyword === "#NOM_SITE#" || keyword === "#NOMBRE_SITIO#"){ trueKeyword = "#SHOP_NAME#"; }
      else if (keyword === "#ANNEE#" || keyword === "#AÑO#"){ trueKeyword = "#YEAR#"; }
      else if (keyword === "#VALEUR_PROMO#" || keyword === "#VALOR_PROMO#"){ trueKeyword = "#PROMO_VALUE#"; }
      else if (keyword === "#CODE_PROMO#" || keyword === "#CODIGO_PROMO#"){ trueKeyword = "#PROMO_CODE#"; }
      else if (keyword === "#PRENOM_PARRAIN#" || keyword === "#NOMBRE_PATROCINADOR#"){ trueKeyword = "#SPONSOR_FIRSTNAME#"; }
      else if (keyword === "#NOM_PARRAIN#" || keyword === "#APELLIDO_PATROCINADOR#"){ trueKeyword = "#SPONSOR_LASTNAME#"; }
      else if (keyword === "#PRENOM_FILLEUL#" || keyword === "#NOMBRE_REFERENCIA#"){ trueKeyword = "#GODSON_FIRSTNAME#"; }
      else if (keyword === "#NOM_FILLEUL#" || keyword === "#APELLIDO_REFERENCIA#"){ trueKeyword = "#GODSON_LASTNAME#"; }
      else trueKeyword = keyword;

      return trueKeyword;
    }

    async insertKeyword(keyword) {
      const { editorState } = this.state;

      const content = editorState.getCurrentContent();
      const selection = editorState.getSelection();

      const entityKey = Entity.create("MENTION", "IMMUTABLE", {
        meta: keyword,
      });

      const trueKeyword = await this.formatLangKeyword(keyword);

      const textWithEntity = Modifier.replaceText(
        content,
        selection,
        trueKeyword,
        editorState.getCurrentInlineStyle(),
        entityKey
      );      

      // insert keyword
      let newEditorState = EditorState.push(
        editorState,
        textWithEntity,
        "insert-characters"
      );

      // select the inserted keyword
      newEditorState = EditorState.forceSelection(
        newEditorState,
        textWithEntity.getSelectionAfter()
      );
      let newSelection = newEditorState.getSelection();
      newSelection = new SelectionState({
        anchorKey: newSelection.anchorKey,
        anchorOffset: selection.anchorOffset,
        focusKey: newSelection.focusKey,
        focusOffset: newSelection.focusOffset,
      });
      newEditorState = EditorState.forceSelection(newEditorState, newSelection);

      this.setValueFromEditorState(newEditorState);
    }

    handleChange(editorState) {
      const currentContentState = this.state.editorState.getCurrentContent();
      const newContentState = editorState.getCurrentContent();

      if (currentContentState !== newContentState) {
        this.setValueFromEditorState(editorState);
      } else {
        // the change detected is due to focus/selection. update the editor state but don't ask the parent to update the value
        this.setState({ editorState });
      }
    }

    onFocus() {
      this.setState({ tooltipIsOpen: true, linkFormIsOpen: false, linkError: false });
      if (this.props.onFocus) {
        this.props.onFocus();
      }
    }

    onClickAway() {
      this.setState({ tooltipIsOpen: false, linkFormIsOpen: false, linkError: false });
      if (this.props.onClickAway) {
        this.props.onClickAway();
      }
    }
    
    onInsertLink() {
      const regex = new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/);

      if (this.state.link.match(regex)) {

        if (this.state.linkEntityId) {

          const contentStateWithEntity = this.state.editorState.getCurrentContent()
            .replaceEntityData(this.state.linkEntityId, { url: this.state.link });

          const nextEditorState = EditorState.set(this.state.editorState,
            { currentContent: contentStateWithEntity }
          );

          this.setValueFromEditorState(nextEditorState);

        } else {
          const contentStateWithEntity = this.state.editorState.getCurrentContent().createEntity(
            "LINK",
            "MUTABLE",
            { url: this.state.link },
          );

          let nextEditorState = EditorState.set(this.state.editorState,
            { currentContent: contentStateWithEntity }
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

          nextEditorState = RichUtils.toggleLink(nextEditorState,
            this.state.linkSelection, entityKey
          );

          nextEditorState = RichUtils.toggleInlineStyle(
            nextEditorState,
            "UNDERLINE"
          );

          this.setValueFromEditorState(nextEditorState);

        }
        this.setState({ linkFormIsOpen: false, linkError: false });
      } else {
        this.setState({ linkError: true });
      }
    };

    findEntityOffsets(entityId, offset, block) {
      let offsetStart = offset;
      let offsetEnd = offset;
      while (block.getEntityAt(offsetStart) === entityId) {
        offsetStart--;
      }
      while (block.getEntityAt(offsetEnd) === entityId) {
        offsetEnd++;
      }
      return {
        offsetStart,
        offsetEnd
      };
    }

    openLink() {

      const selection = this.state.editorState.getSelection();

      const block = this.state.editorState.getCurrentContent().getBlocksAsArray()[0];

      const entityIdStart = block.getEntityAt(selection.getStartOffset());
      const entityIdEnd = block.getEntityAt(selection.getEndOffset() - 1);

      if (selection.getStartOffset() === selection.getEndOffset() ||
        entityIdStart !== entityIdEnd) {
        return ;
      }

      const entity = entityIdStart ?
        this.state.editorState.getCurrentContent().getEntity(entityIdStart) : null;

      this.setState({
        linkFormIsOpen: !entityIdStart,
        link: entity?.getData().url || "",
        linkSelection: selection,
        linkEntityId: entity ? entityIdStart : null,
        linkEditIsOpen: !!entityIdStart,
        linkEntityOffset: entity ?
          this.findEntityOffsets(entityIdStart, selection.getStartOffset(), block) : null,
      });
    }

    onDeleteLink() {

      const block = this.state.editorState.getCurrentContent().getBlocksAsArray()[0];

      const characterList = block.getCharacterList();

      const newCharacterList = characterList.map((value, key) => {
        if (key > this.state.linkEntityOffset.offsetStart && 
          key < this.state.linkEntityOffset.offsetEnd) {
          value = value.set("style", value.get("style").clear());
          value = value.set("entity", null);
        }
        return value;
      });

      const updatedBlock = block.set("characterList", newCharacterList);

      const newBlockMap = this.state.editorState.getCurrentContent().getBlockMap().map(() => {
        return updatedBlock;
      });
      const newContentState = this.state.editorState.getCurrentContent().set("blockMap", newBlockMap);

      const nextEditorState = EditorState.set(this.state.editorState,
        { currentContent: newContentState }
      );

      this.setState({ linkEditIsOpen: false });
      this.setValueFromEditorState(nextEditorState);
    }

    onEditorClick = (event) => {
      const newPos = event.clientY - event.target.getBoundingClientRect().y;
      if (this.props.autoCalcTooltipPosition) {
        this.setState({ tooltipPosition: newPos });
      }
    };

    render() {
      const { classes, placeholder, inputRef, id, onAiGenerate, editorRef } = this.props;

      if (!this.state.editorState) return null;

      return (
        <ClickAwayListener
          onClickAway={this.onClickAway}
        >
          <div
            className={classes.editorWrapper}
            id={id}
            data-cy="html-customizer-input-wrapper"
            ref={inputRef}
          >
            <HtmlCustomizerTooltip styles={{ top: this.state.tooltipPosition }} open={this.state.tooltipIsOpen}>
              {
                this.state.linkEditIsOpen ?
                  <HtmlCustomizerLinkEdit
                    withTriangle={false}
                    withMargin={false}
                    value={this.state.link}
                    onOpenForm={() => this.setState({
                      linkFormIsOpen: true,
                      linkEditIsOpen: false
                    })}
                    onDeleteLink={this.onDeleteLink}
                  /> :
                  this.state.linkFormIsOpen ?
                    <HtmlCustomizerLinkForm
                      withTriangle={false}
                      withMargin={false}
                      value={this.state.link}
                      onChange={event => this.setState({ link: event.target.value })}
                      onSave={this.onInsertLink}
                      error={this.state.linkError}
                    />
                    : (
                      <>
                        {!!onAiGenerate && <HtmlCustomizerTooltipAiButton onClick={onAiGenerate} />}
                        {this.state.customizationOptions.styleInputTypes.map(
                          (styleInputType) => (
                            <HtmlCustomizerTooltipInputStyle
                              key={styleInputType}
                              styleInputType={styleInputType}
                              getIsSelected={(value) =>
                                this.state.editorState.getCurrentInlineStyle().has(value)
                              }
                              onClick={(newStyleInputType) =>
                                this.setValueFromEditorState(
                                  RichUtils.toggleInlineStyle(
                                    this.state.editorState,
                                    newStyleInputType
                                  )
                                )
                              }
                            />
                          )
                        )}
                        <HtmlCustomizerTooltipInputLink
                          onClick={this.openLink}
                        />
                        <HtmlCustomizerTooltipInputKeyword
                          keywords={this.state.customizationOptions.keywords}
                          onSelect={this.insertKeyword}
                        />
                        <HtmlCustomizerTooltipInputFontSize
                          fontSizes={this.state.customizationOptions.fontSizes}
                          getIsSelected={(value) =>
                            this.state.editorState.getCurrentInlineStyle().has(value)
                          }
                          onSelect={(newFontSize) => {
                            const newEditorState = this.removeFontSize();
                            this.setValueFromEditorState(
                              RichUtils.toggleInlineStyle(newEditorState, newFontSize)
                            );
                          }}
                        />
                      </>
                    )
              }
            </HtmlCustomizerTooltip>
            <div
              className={classes.inputWrapper}
              style={this.state.inputWrapperStyle}
              onClick={this.onEditorClick}
            >
              <Editor
                ref={editorRef}
                onFocus={this.onFocus}
                placeholder={placeholder}
                stripPastedStyles={true}
                editorState={this.state.editorState}
                customStyleMap={this.state.styleMap}
                handleKeyCommand={(command, editorState) => {
                  const newEditorState = RichUtils.handleKeyCommand(
                    editorState,
                    command
                  );
                  if (newEditorState)
                    this.setValueFromEditorState(newEditorState);
                }}
                onChange={(editorState) => this.handleChange(editorState)}
              />
            </div>
          </div>
        </ClickAwayListener>
      );
    }
  }
);