import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import TagManager from "react-gtm-module";
import { FormattedMessage, injectIntl } from "react-intl";
import { GoogleButton } from "../common/Button/GoogleButton";
import { SubmitButton } from "../common/Button/SubmitButton";
import { TextField } from "../common/Input/TextField.js";
import { ResultLabelTO } from "../ResultLabelTO.js";
import { ModalCgv } from "./ModalCgv";
// IMG
import stars from "../../public/5_stars.png";
import arrow from "../../public/arrow.png";
import avatar from "../../public/Avatar_femme.png";
import hawai from "../../public/brand/hawai.png";
import paris from "../../public/brand/paris.png";
import pyrenex from "../../public/brand/pyrenex.png";
import well from "../../public/brand/well.png";
import checkEmail from "../../public/checkEmail.png";
import logo from "../../public/logo_black.png";
import * as shopifyTools from "../../tools/shopify";
import { AuthService } from "../AuthService";
import { styles } from "./style.js";
import { withRouter } from "react-router-dom";
import { PasswordTextField } from "../common/Input/PasswordTextField";
import { CustomButton } from "../common/Button/CustomButton";

import { AccountContextBehaviorSubject$, reloadAccountContext } from "./../../contexts/context";

const authService = new AuthService();
const tagManagerArgs = { gtmId: "GTM-NRVRK7Z" };

TagManager.initialize(tagManagerArgs);

export const SignUp = withRouter(withStyles(styles)(
  injectIntl(
    class SignUp extends Component {
      constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this._checkStatus = this._checkStatus.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.createAccount = this.createAccount.bind(this);
        this.setError = this.setError.bind(this);
        this.changeUrl = this.changeUrl.bind(this);
        this.checkCgv = this.checkCgv.bind(this);
        this.state = {
          id: "0",
          email: "",
          lastname: "",
          firstname: "",
          shopUrl: "",
          myShopifyUrl: "",
          CMS: "",
          createdFrom: "kiliba",
          showPage: false,
          password: "",
          passwordIsValid: false,
          cgv: false,
          done: false,
          error: null,
          hidden: false,
          isLoading: false,
          global_error: false,
          send_again: false,
          showModalCgv: false,
          customerToken: null,
          errorField: null,
          sfParams: {},
          accountState: {}
        };
      }

      resetState = () => {
        this.setState({
          email: "",
          lastname: "",
          firstname: "",
          shopUrl: "",
          password: "",
          sfParams: {},
        });
      };

      async componentDidMount() {
        this.checkURLParams();
        if (window.location.pathname === "/register/cgv") {
          await this.handleFederatedSignUp();
        } else {
          await this.handleShopifySignUp();
        }
        authService.sendEventToAmplitude("signup.see_page", { shopUrl: this.state.shopUrl });
      }

      async getAccountState() {
        const accountState = {
          exists: null,
          isLoggedIn: authService.loggedIn(),
          hasShopifyConfiguration: null,
        };

        accountState.exists = await this.fetchAccountExists();
        accountState.hasShopifyConfiguration = await this.fetchAccountHasShopifyConfiguration();

        return accountState;
      }

      async handleFederatedSignUp() {
        if (authService.loggedIn()) {
          return this.props.history.push("/");
        }

        const user = await authService.getUser();
        if (!user) {
          return this.props.history.push("/register");
        }

        const utm = {};
        if (sessionStorage.getItem("utm")) {
          try {
            Object.assign(utm, { sfParams: JSON.parse(sessionStorage.getItem("utm")) });
          } catch { }
        }

        const prebuiltRegisterData = {};
        if (sessionStorage.getItem("register_data")) {
          try {
            Object.assign(prebuiltRegisterData, JSON.parse(sessionStorage.getItem("register_data")));
          } catch { }
        }

        this.setState({
          ...utm,
          ...prebuiltRegisterData,
          email: user.attributes.email,
          lastname: user.attributes.family_name,
          firstname: user.attributes.given_name,
          showModalCgv: true
        });
      }

      async handleShopifySignUp() {
        try {
          const shopifyParams = shopifyTools.getShopifyParams();

          if (!shopifyParams) {
            this.setState({ showPage: true });
            if (authService.loggedIn()) {
              return this.props.history.push("/on_boarding");
            }

            return;
          }

          const { account } = AccountContextBehaviorSubject$.getValue();

          this.setState({
            CMS: shopifyParams.CMS,
            myShopifyUrl: shopifyParams.shopUrl,
            connectionType: shopifyParams.connectionType,
          });

          const accountState = await this.getAccountState();

          this.setState({ accountState: accountState });

          this.setState({ createdFrom: "shopify" });

          if (authService.loggedIn() && !accountState.hasShopifyConfiguration) {
            if (shopifyParams && account.shopify && shopifyParams.shopUrl !== account.shopify?.session?.shopUrl) {
              localStorage.removeItem("id_account");
              return this.setState({ showPage: true });
            }

            return this.props.history.push(account?.step_register < 3 ? `/login?shop=${shopifyParams.rawShopUrl}&CMS=${shopifyParams.rawCMS}` : "/");
          }

          if (authService.loggedIn()) {
            if (
              shopifyParams && 
              accountState.hasShopifyConfiguration && 
              shopifyParams.shopUrl !== account.shopify?.session?.shopUrl
            ) {
              sessionStorage.setItem("shopifyAlreadyConnected", "true");

              this.setError({ error: "SHOPIFY_URL_USED" });
              this.setState({ showPage: true });
              return;
            }

            return this.props.history.push(account?.step_register < 3 ? "/on_boarding" : "/");
          }

          this.setState({ showPage: true });
        } catch (error) {
          console.error(error);
          this.setState({ showPage: true });
        }
      }

      async fetchAccountExists() {
        return await authService.fetch(`/api/public/getAccountExists?shopUrl=${this.state.shopUrl || localStorage.shopUrl}`);
      }

      async fetchAccountHasShopifyConfiguration() {
        return await authService.fetch(`/api/public/getShopifyAccountExists?shopUrl=${this.state.myShopifyUrl}`);
      }

      checkURLParams() {
        const queryString = window.location.search;
        if (queryString.includes("utm_source") || queryString.includes("utm_campaign") || queryString.includes("utm_canal"))
          this.getUtmParams();
      }

      getUtmParams() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const utmParams = {
          canal: decodeURIComponent(urlParams.get("utm_canal")),
          sources: decodeURIComponent(urlParams.get("utm_source")),
          campain: decodeURIComponent(urlParams.get("utm_campaign")),
        };

        sessionStorage.setItem("utm", JSON.stringify(utmParams));
        this.setState({ sfParams: utmParams });

        ///SalesForce URL Example : /register?t=test2-firstname+Lastname+&m=unconfiguredemail%2540kiliba.com&canal=Commercial&s=testSource&c=testCampain&domain=test.com
        /// 't' to catch token(FirstName+Lastname) ,'m' to catch hashed mail ,'domain' to catch domain
        if (urlParams.get("t") && urlParams.get("m")) {
          let sfName = "";
          let sfMail = "";
          const hash = decodeURIComponent(urlParams.get("t"));
          sfMail = decodeURIComponent(urlParams.get("m"));
          if (hash.indexOf("/") !== -1) {
            sfName = hash.slice(0, hash.indexOf("/") - 2);
          } else {
            sfName = hash;
          }

          const prebuiltRegisterData = {
            lastname: sfName.slice(sfName.indexOf(" ")).trim(),
            firstname: sfName.slice(0, sfName.indexOf(" ")).trim(),
            shopUrl: decodeURIComponent(urlParams.get("domain")),
            email: sfMail,
            customerToken: encodeURI(hash),
          };

          sessionStorage.setItem("register_data", JSON.stringify(prebuiltRegisterData));
          this.setState(prebuiltRegisterData);
        }
      }

      handleCheck = (event, checked) => {
        this.setState({ cgv: checked });
      };

      handleHiddenEyes = (event) => {
        this.setState({ hidden: !this.state.hidden });
      };

      async handleSubmit (event) {
        event.preventDefault();

        const email = this.state.email;
        const password = this.state.password;
        const shopUrl = this.state.shopUrl;
        authService.sendEventToAmplitude("signup.click_lets_go", { shopUrl: this.state.shopUrl, email: this.state.email });
        this.setState({ isLoading: true });

        let error = this.checkForm();
        error = await authService.checkSf({ email, shopUrl });

        if (error) {
          this.setError(error);
          return;
        }

        const additionnalAttributes = {
          locale: this.props.intl.locale
        };

        if (this.state.firstname || this.state.lastname) {
          additionnalAttributes.given_name = this.state.firstname;
          additionnalAttributes.family_name = this.state.lastname;
        }

        try {
          await authService.signUp(
            email,
            password,
            additionnalAttributes
          );
          await this.createAccount(email);

          await authService.login(email, password, { forceSessionRefresh: true });
        } catch(e) {
          this.setError(e);
          this.setState({ isLoading: false });
          return;
        }

        this.props.history.push("/");

        this.setState({ isLoading: false });
        sessionStorage.removeItem("utm");
        sessionStorage.removeItem("register_data");
      };

      async checkCgv(event) {
        event.preventDefault();
        this.setState({ isLoading: true });
        try {
          const { attributes: { email } } = await authService.getUser();
          await this.createAccount(email);

          const { attributes } = await authService.forceSessionRefresh();
          authService.switchAccount(attributes["custom:admin_account"]);
          await reloadAccountContext();

          this.props.history.push("/");
        } catch (error) {
          this.setError(error);
        }
        this.setState({ isLoading: false });
      }

      async createAccount(email) {
        const { intl } = this.props;
        let locale = intl.locale;
        const avaibleLanguagesOnSalesforce = [
          "fr",
          "en",
          "it",
          "de",
          "es",
          "nl",
          "pt",
        ];
        if (avaibleLanguagesOnSalesforce.indexOf(locale) === -1) {
          locale = "unknown";
        }
        const signUpPayload = {
          email: email,
          shopUrl: this.state.shopUrl,
          language: locale,
          customerToken: this.state.customerToken,
          sfParams: this.state.sfParams,
        };

        // SPECIFIC ADDS FOR SHOPIFY PRE SIGN-UP
        if (this.state.CMS === "shopify") {
          signUpPayload["CMS"] = this.state.CMS;
          signUpPayload["shopify"] = {
            session: {
              shopUrl: this.state.myShopifyUrl,
              setupDate: new Date().toISOString(),
              setupType: this.state.setupType,
            },
          };
        }
        signUpPayload["createdFrom"] = this.state.createdFrom;

        const account = await authService.fetch("/api/public/createAccount", {
          method: "POST",
          body: JSON.stringify(signUpPayload),
        });

        return account;
      }

      setError(err) {
        switch (err.error || err) {
          case "ALREADY_CREATED":
          case "EMAIL_ALREADY_USED":
            this.setState({
              errorField: "email",
              showModalCgv: false,
              redText: true,
              error_text: "error.email.already",
              global_error: true,
            });
            break;
          case "FORMAT_EMAIL":
            this.setState({
              errorField: "email",
              showModalCgv: false,
              redText: true,
              error_text: "error.email.format",
              global_error: true,
            });
            break;
          case "URL_FORMAT":
            this.setState({
              errorField: "shopUrl",
              showModalCgv: false,
              redText: true,
              error_text: "error.shopUrl.format",
              global_error: true,
            });
            break;
          case "ACCEPT_CGV":
            this.setState({
              errorField: "cgv",
              redText: true,
              error_text: "error.cgv.accept",
              global_error: true,
            });
            break;
          case "SHOPIFY_URL_USED":
            this.setState({
              errorField: null,
              showModalCgv: false,
              redText: true,
              error_text: "error.email.shopify_url_used",
              global_error: true,
            });
            break;
          case "DOMAIN_ALREADY_USED":
            this.setState({
              errorField: "shopUrl",
              showModalCgv: false,
              redText: true,
              error_text: "error.domain_used",
              global_error: true,
            });
            break;
          default:
            this.setState({
              errorField: null,
              showModalCgv: false,
              redText: true,
              error_text: null,
              global_error: true,
            });
            break;
        }

        this.setState({ isLoading: false });
      }

      validateEmail = (email) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      };

      validURL(str) {
        str = str
          .replace("www.", "")
          .replace("https://", "")
          .replace("http://", "")
          .replace("http;//", "")
          .replace("https;//", "");
        this.setState({ shopUrl: str });
        const expression =
          /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
        const regex = new RegExp(expression);
        return str.match(regex);
      }

      checkForm() {
        if (!this.validateEmail(this.state.email)) {
          return "FORMAT_EMAIL";
        } else if (!this.state.cgv) {
          return "ACCEPT_CGV";
        } else if (!this.validURL(this.state.shopUrl)) {
          return "URL_FORMAT";
        }
      }

      changeUrl = (url) => (event) => {
        // Url params used for setting up Shopify on the account are not secure, so the user can modify them.
        // Therefore for security purpose the Shopify setup can only be done on the user's account, so the user must be authenticated.
        const canOnlyBeConfiguredOnSignIn = !this.state.hasShopifyConfiguration &&
          !this.state.accountState.isLoggedIn;
        if (this.state.accountState.exists && canOnlyBeConfiguredOnSignIn) {
          url += (
            `?shop=${window.btoa(this.state.shopUrl)}` +
            `&CMS=${window.btoa(this.state.CMS)}`
          );
        }
        this.props.history.push(url);
      };

      /** raises an error in case response status is not a success */
      _checkStatus(response) {
        return response.json().then((result) => {
          return new Promise(function (resolve, reject) {
            if (result.error) reject(result);
            else resolve(result);
          });
        });;
      }

      ///////////////////////////////  VIEW  ///////////////////////////////

      render() {
        const { classes } = this.props;
        let contentPage;
        // ADD GTM EVENT
        window.dataLayer.push({
          event: "creation_compte",
        });

        //////// SHOW CONFIRM MODAL  //////
        if (this.state.done === true) {
          authService.sendEventToAmplitude("check_your_email.see_page");
          contentPage = (
            <Grid
              container
              item
              justify-xs-center="true"
              xs={12}
              sm={12}
              spacing={0}
            >
              <div className={classes.successNotifContainer}>
                <ResultLabelTO
                  visible={this.state.send_again}
                  style={{ width: "40%", marginLeft: "40%", marginTop: "40px" }}
                  label="register_after.send_again_confirm"
                  inactive_style={{ display: "none" }}
                />
              </div>
              <Grid item xs={6}>
                <div className={classes.confirmModalContainer}>
                  <div className={classes.confirmModalTitle}>
                    <FormattedMessage id="register_after.verify" />
                  </div>

                  <p className={classes.confirmModalTitleContent}>
                    <FormattedMessage
                      id="register_after.message1"
                      defaultMessage="Un email a été envoyé à "
                    />
                    <b>{this.state.email}</b>.
                    <br />
                    <FormattedMessage
                      id="register_after.message2"
                      defaultMessage="Merci de le valider pour activer votre compte."
                    />
                    <br />
                    <FormattedMessage
                      id="register_after.checkSpam"
                      defaultMessage="(Pensez à vérifier votre dossier spam)"
                    />
                  </p>

                  <div className={classes.confirmModalText}>
                    <FormattedMessage id="register_after.didnt_receive" />
                  </div>

                  <div>
                    <SubmitButton
                      style={{ marginTop: "10px", fontFamily: "Poppins" }}
                      label="register_after.send_again"
                      onClick={this.handleSubmit}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.confirmModalRightWindow}>
                  <img className={classes.slide} alt="" src={checkEmail} />
                </div>
              </Grid>
            </Grid>
          );

          //////// SHOW SIGNUP FORM //////
        } else {
          if (this.state.showModalCgv) {
            contentPage = (
              <div>
                <ModalCgv
                  globalError={this.state.global_error}
                  isLoading={this.state.isLoading}
                  handleSubmit={this.checkCgv}
                  handleClick={(event) =>
                    this.handleCheck(event, !this.state.cgv)
                  }
                  checked={this.state.cgv}
                />
              </div>
            );
          } else {
            if (this.state.showPage) {
              contentPage = (
                <Grid
                  container
                  item
                  justify-xs-center="true"
                  xs={12}
                  sm={12}
                  spacing={0}
                >
                  <Grid item xs={6}>
                    <div className={classes.leftWindow}>
                      {/* HEADER */}
                      <div className={classes.roundedDiv}></div>
                      <img
                        className={classes.logo}
                        src={logo}
                        alt="logo"
                        onClick={this.changeUrl("/")}
                      />

                      <div className={classes.head}>
                        <div className={classes.subWording}>
                          <FormattedMessage
                            id="login.smartWelcome"
                            defaultMessage="Welcome"
                          />
                          <br />
                        </div>
                        <div className={`${classes.wording} ${classes.marginUp}`}>
                          <FormattedMessage
                            id="register.customerSignup"
                            defaultMessage="Welcome"
                          />
                        </div>
                      </div>

                      {/* FORM */}
                      <div className={`${classes.form}`}>
                        {this.state.customerToken && (
                          <div>
                            {this.state.lastname} {this.state.firstname}{" "}
                          </div>
                        )}
                        <div
                          className={`${classes.error} ${classes.signUpError}`}
                        >
                          {this.state.global_error && (
                            <FormattedMessage id={this.state.error_text} />
                          )}
                          &nbsp;
                        </div>

                        <form onSubmit={this.handleSubmit}>
                          <div className={classes.formSignupGrid}>
                            <TextField
                              value={this.state.shopUrl}
                              style={{ gridArea: "a" }}
                              type="url"
                              onChange={(event) =>
                                this.setState({ shopUrl: event.target.value })
                              }
                              poppins
                              error={this.state.errorField === "shopUrl"}
                            />
                            <TextField
                              value={this.state.email}
                              style={{ gridArea: "c" }}
                              type="email"
                              onChange={(event) =>
                                this.setState({ email: event.target.value })
                              }
                              poppins
                              error={this.state.errorField === "email"}
                            />
                            <PasswordTextField
                              value={this.state.password}
                              style={{ gridArea: "d" }}
                              type="password"
                              onEyeClick={this.handleHiddenEyes}
                              label="password"
                              onChange={(event) => this.setState({ password: event.target.value })}
                              onPasswordValid={() => {
                                this.setState({
                                  passwordIsValid: true,
                                  ...(
                                    this.state.errorField === "password" && {
                                      errorField: null,
                                      redText: false,
                                      error_text: null,
                                      global_error: false,
                                    }
                                  )
                                });
                              }}
                              onPasswordInvalid={() => {
                                this.setState({
                                  passwordIsValid: false,
                                  errorField: "password",
                                  redText: true,
                                  error_text: "error.password.format",
                                  global_error: true,
                                });
                              }}
                              poppins
                            />
                          </div>

                          {this.state.customerToken && (
                            <jsx>
                              <div>
                                <br></br>
                              </div>
                            </jsx>
                          )}

                          <div
                            className={`${classes.checkCgv} ${classes.marginUp}`}
                          >
                            <Checkbox
                              checked={this.state.cgv}
                              className={classes.checkbox}
                              classes={{
                                root:
                                  this.state.errorField === "cgv"
                                    ? classes.checkboxError
                                    : null,
                              }}
                              onClick={(event) =>
                                this.handleCheck(event, !this.state.cgv)
                              }
                            />

                            <div>
                              <FormattedMessage
                                id="register.cgv"
                                defaultMessage="cgv"
                              />
                              <a
                                href="https://www.kiliba.com/cgu"
                                rel="noreferrer"
                                target="_blank"
                                className={classes.loginWording_hypertext}
                              >
                                <FormattedMessage
                                  id="register.cgv2"
                                  defaultMessage="cgv"
                                />
                              </a>
                              <FormattedMessage
                                id="register.cgv3"
                                defaultMessage="cgv"
                              />
                              <a
                                href="https://www.kiliba.com/politique-de-confidentialite-app"
                                rel="noreferrer"
                                target="_blank"
                                className={classes.loginWording_hypertext}
                              >
                                <FormattedMessage
                                  id="register.cgv4"
                                  defaultMessage="Welcome"
                                />
                              </a>
                              <FormattedMessage
                                id="register.cgv5"
                                defaultMessage="cgv"
                              />
                              <a
                                href="https://www.kiliba.com/mentions-legales-app"
                                rel="noreferrer"
                                target="_blank"
                                className={classes.loginWording_hypertext}
                              >
                                <FormattedMessage
                                  id="register.cgv6"
                                  defaultMessage="cgv"
                                />
                              </a>
                              <FormattedMessage
                                id="register.cgv7"
                                defaultMessage="cgv"
                              />
                            </div>
                          </div>
                          <img src={arrow} alt="logo" className={classes.arrowSignUp} />
                          <div className={classes.buttonContainer}>
                            <CustomButton
                              style={{ fontFamily: "Poppins" }}
                              loading={this.state.isLoading}
                              disabled={
                                !this.state.shopUrl ||
                                !this.state.email ||
                                !this.state.cgv ||
                                !this.state.passwordIsValid
                              }
                            >
                              <FormattedMessage id="button.sign_in" />
                            </CustomButton>
                          </div>
                        </form>
                      </div>

                      {/* FOOTER */}
                      <div className={`${classes.footer} ${classes.marginTop}`}>
                        <div
                          className={`${classes.checkCgv} ${classes.marginUp}`}
                        >
                          {!this.state.customerToken && (
                            <jsx>
                              <div>
                                <FormattedMessage
                                  id="register.already"
                                  defaultMessage="Welcome"
                                />
                                <a
                                  href
                                  rel="noreferrer"
                                  onClick={this.changeUrl("/login")}
                                  className={classes.loginWording_hypertext}
                                >
                                  <FormattedMessage
                                    id="register.login"
                                    defaultMessage="Welcome"
                                  />
                                </a>
                              </div>
                            </jsx>
                          )}
                        </div>
                        <div
                          className={`${classes.subWording} ${classes.loginOr}`}
                        >
                          <hr className={classes.leftHr}></hr>
                          <FormattedMessage id="login.or" />
                          <hr className={classes.rightHr}></hr>
                        </div>
                        <div>
                          <GoogleButton />
                        </div>
                        {!this.state.customerToken && (
                          <jsx>
                            <div className={classes.subWording}>
                              <a
                                className={classes.fakeLink}
                                href="https://www.kiliba.com/"
                                rel="noreferrer"
                                target="_blank"
                              >
                                <FormattedMessage id="button.back_to_home" />
                              </a>
                            </div>
                          </jsx>
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.rightWindow}>
                      <img alt="avatar" className={classes.avatar} src={avatar} />
                      <div className={classes.head}>
                        <div className={classes.textName}>
                          <FormattedMessage
                            id="register.avis0"
                            defaultMessage="cgv"
                          />
                        </div>
                        <div className={classes.textJob}>
                          <FormattedMessage
                            id="register.avis1"
                            defaultMessage="cgv"
                          />
                        </div>
                      </div>
                      <img alt="stars" className={classes.stars} src={stars} />
                      <div className={classes.form}>
                        <div className={classes.textQuote}>
                          <FormattedMessage
                            id="register.avis2"
                            defaultMessage="cgv"
                          />
                        </div>
                        <div className={classes.textReview}>
                          <FormattedMessage
                            id="register.avis3"
                            defaultMessage="cgv"
                          />
                        </div>
                      </div>
                      <div className={classes.logoFooter}>
                        <img
                          alt="paris"
                          className={classes.brandImg}
                          src={paris}
                        />
                        <img
                          alt="hawai"
                          className={classes.brandImg}
                          src={hawai}
                        />
                        <img
                          alt="pyrenex"
                          className={classes.brandImg}
                          src={pyrenex}
                        />
                        <img alt="well" className={classes.brandImg} src={well} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              );
            }
          }
        }

        return (
          <div className={classes.root}>
            <Paper className={classes.mainwindow}>{contentPage}</Paper>
          </div>
        );
      }
    }
  )
));

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
};
