import { useIntl } from "react-intl";
import { CmsDialog } from "../../common/CmsDialog/CmsDialog";
import { useEffect } from "react";
import { useAuth } from "../../../../../hooks/useAuth";
import { usePrevious } from "../../../../../hooks/usePrevious";
import { ShopifyForm } from "./ShopifyForm";

import classes from "./ShopifyDialog.module.scss";


export const ShopifyDialog = ({ open = false, handleClose = () => { }, onSubmit = () => { }, loading = false }) => {

  const intl = useIntl();
  const auth = useAuth();
  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (!prevOpen && open) {
      auth.sendEventToAmplitude("shopify_connection.see_modale");
    } else if (prevOpen && !open) {
      auth.sendEventToAmplitude("shopify_connection.leave_modale");
    }
  }, [auth, prevOpen, open]);

  return (
    <CmsDialog open={open} handleClose={handleClose}>
      <div className={classes.shopifyDialog}>
        <div className={classes.title}>
          <p className={"display2"}>{intl.messages["onBoarding.chooseCms.shopify.title"]}</p>
        </div>
        <ShopifyForm loading={loading} onSubmit={onSubmit} />
      </div>
    </CmsDialog>
  );
};
