import { image1 } from "../images/image1";
import { paragraph8 } from "../texts/paragraph8";
import { logo2 } from "../logo/logo2";
import { lineSeparator } from "../components/separators/lineSeparator";
import { globalTrustpilot2 } from "../review/globalTrustpilot2";
import { globalAvisverifies1 } from "../review/globalAvisverifies1";
import { whiteSeparator } from "../components/separators/whiteSeparator";
import { button1 } from "../button/button1";
import { button2 } from "../button/button2";
import { footer2 } from "../footer/footer2";
import { rewriteWordings, makeTranslatable, rewriteVariables } from "../utils";
import { preheader } from "../preheader/preheader";
import { getProductsBlocks } from "./utils";

const LABEL = "productPreset";

export const productPreset = ({
  unsplashPhotos,
  imageUrl,
  shopUrl,
  fontFamily,
  textColor,
  generatedWordings,
  dominantColor,
  accentColor,
  products,
  buttonTextColor,
  logoUrl,
  headerColor,
  initialDalleImage,
  isDominantColorLight,
  trustPilotReviews,
  avisVerifiesReviews,
  blockRsInstagram,
  blockRsFacebook,
  blockRsTwitter,
  blockRsYoutube,
  blockRsDiscord,
  blockRsLinkedin,
  blockRsPinterest,
  blockRsTiktok,
  isLight,
  footerColor,
  tone,
  buttonRadius,
  currency,
  priceIsVisible,
  currencyBefore,
}) => ({
  label: LABEL,
  block: () => {
    const image = imageUrl || unsplashPhotos[0]?.url;
    
    const preheaderBlock = preheader({ fontFamily, textColor, title: generatedWordings.preheader, backgroundColor: headerColor, isVisible: false }).block();
    const logoBlock = logo2({ logoUrl: makeTranslatable(logoUrl), href: makeTranslatable(shopUrl), alt: makeTranslatable(""), backgroundColor: headerColor }).block();
    const image1Block = image ? image1({ imageUrl: makeTranslatable(image), href: makeTranslatable(shopUrl), alt: makeTranslatable(""), backgroundColor: headerColor }).block() : null;
    const paragraphBlock = paragraph8({ fontFamily, textColor, backgroundColor: dominantColor, title: generatedWordings.title, subtitle: generatedWordings.subtitle, text: generatedWordings.text }).block();
    const dalleImageBlock = initialDalleImage?.length ? image1({ imageUrl: makeTranslatable(initialDalleImage[0]), href: makeTranslatable(shopUrl), alt: makeTranslatable(""), backgroundColor: headerColor }).block() : null;
    const separatorBlock = lineSeparator({ dominantColor, isDominantColorLight }).block();
    const separatorWhiteBlock = whiteSeparator({ dominantColor }).block();
    const buttonBlock = tone === "serieux" ? button2({ fontFamily, buttonTextColor, backgroundColor: dominantColor, buttonColor: accentColor, href: makeTranslatable(shopUrl) }).block() : button1({ fontFamily, buttonTextColor, backgroundColor: dominantColor, buttonColor: accentColor, href: makeTranslatable(shopUrl) }).block();
    
    const footerBlock = footer2(logoUrl, blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, isLight, { fontFamily, footerColor }).block();

    let reviewMjml = "";

    if (trustPilotReviews?.reviews?.length && trustPilotReviews?.globalRating >= 4 && (!avisVerifiesReviews?.reviews.length || trustPilotReviews?.globalRating >= avisVerifiesReviews?.globalRating )) {
      const trustpilotBlock = globalTrustpilot2({ rating: trustPilotReviews.globalRating, fontFamily, textColor, dominantColor }).block();
      reviewMjml = trustpilotBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${trustpilotBlock.label}"`);
    } else if (avisVerifiesReviews?.reviews?.length && avisVerifiesReviews?.globalRating >= 4 && (!trustPilotReviews?.reviews.length || avisVerifiesReviews?.globalRating > trustPilotReviews?.globalRating )) {
      const avisverifiesBlock = globalAvisverifies1({ rating: avisVerifiesReviews.globalRating, fontFamily, textColor, dominantColor }).block();
      reviewMjml = avisverifiesBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${avisverifiesBlock.label}"`);
    }

    const { wordings: buttonWordings, mjml: buttonMjml } = rewriteWordings("button", buttonBlock.attributes.wordings, buttonBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${buttonBlock.label}"`));
    const { wordings: buttonVariables, mjml: finalButtonMjml } = rewriteVariables("button-var", buttonBlock.attributes.wordings, buttonMjml);

    const { wordings: footerWordings, mjml: footerMjml } = rewriteWordings("footer", footerBlock.attributes.wordings, footerBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${footerBlock.label}"`));
    const { wordings: preheaderWordings, mjml: preheaderMjml } = rewriteWordings("preheader", preheaderBlock.attributes.wordings, preheaderBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${preheaderBlock.label}"`));
    const { variables: image1Variables, mjml: image1Mjml } = image1Block ? rewriteVariables("image1", image1Block.attributes.variables, image1Block.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${image1Block.label}"`) ) : {};
    const { variables: dalleImageVariables, mjml: dalleImageMjml } = dalleImageBlock ?  rewriteVariables("dalleImage", dalleImageBlock.attributes.variables, dalleImageBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${dalleImageBlock.label}"`)) : {};
    const { wordings: productsWordings, mjml: productsMjml, variables: productsVariables } = getProductsBlocks({ products, fontFamily, buttonTextColor, accentColor, textColor, dominantColor, buttonRadius, isDominantColorLight, currency, priceIsVisible, currencyBefore });

    const mjml = `
      ${preheaderMjml}
      ${logoBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${logoBlock.label}"`)}
      ${ image1Block ? image1Mjml : ""}
      ${paragraphBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${paragraphBlock.label}"`)}
      ${productsMjml}
      ${dalleImageBlock && products.length !== 5 ? dalleImageMjml : ""}
      ${separatorBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${separatorBlock.label}"`)}
      ${reviewMjml}
      ${finalButtonMjml}
      ${products.length === 5 ? 
    separatorWhiteBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${separatorWhiteBlock.label}"`) :
    separatorBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${separatorBlock.label}"`)
  }
      ${footerMjml}
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: "preset",
      activate: true,
      content: mjml,
      attributes: {
        wordings: {
          ...paragraphBlock.attributes.wordings,
          ...productsWordings,
          ...buttonWordings,
          ...footerWordings,
          ...preheaderWordings, 
        },
        variables: {
          ...logoBlock.attributes.variables,
          ...image1Variables,
          ...dalleImageVariables,
          ...productsVariables,
          ...buttonVariables,
        }
      }
    };
  },
});