import { useState } from "react";
import { useIntl } from "react-intl";
import { Dialog } from "../common/Dialog";
import { TextField } from "../common/Input/TextField";
import { Translation } from "../common/Translation";
import { CustomButton } from "../common/Button/CustomButton";
import { useAuth } from "../../hooks/useAuth";
import { getUpdatedWordings } from "./TranslationManager";
import { EventSystem } from "../../eventsystem/EventSystem";

import classes from "./TemplateEditorSendMailPopup.module.scss";

export const TemplateEditorSendMailPopup = ({ editor, wordings, isOpen, close, template, language, variables }) => {

  const intl = useIntl();
  const auth = useAuth();
  const [email, setEmail] = useState("");
  const [emailSendLoading, setEmailSendLoading] = useState(false);

  async function sendNewsletterPreview() {
    const mjmlPayload = !template ? editor.getHtml() : template;
    const wordingsPayload = editor ? getUpdatedWordings(editor, wordings, language) : wordings;

    setEmailSendLoading(true);
    try {
      await auth.fetch("/account_management/sendNewsletterPreview", {
        method: "POST",
        body: JSON.stringify({
          email: email,
          mjmlTemplate: mjmlPayload,
          wordings: wordingsPayload,
          language: language,
          variables,
        })
      });
      
      EventSystem.newNotification(
        "notification.action",
        intl.messages["templateEditorSendMailPopup.send.success"]
      );
      close();
    } catch(error) {
      EventSystem.newNotification(
        "notification.error",
        intl.messages["templateEditorSendMailPopup.send.error"]
      );
    }
    setEmailSendLoading(false);
  }
  
  return (
    <Dialog 
      isOpen={isOpen}
      close={close}
      style={{
        padding: "80px 120px",
      }}
    >
      <div className={classes.container}>
        <div className={`display4 ${classes.title}`}><Translation id="templateEditorSendMailPopup.title" /></div>
        <div className="text">
          <Translation id="templateEditorSendMailPopup.desc1" />
        </div>

        <div className={classes.inputContainer}>
          <TextField 
            value={email}
            onChange={event => setEmail(event.target.value)}
            placeholder={intl.messages["templateEditorSendMailPopup.input.placeholder"]}
          />
        </div>
        <div className={classes.inputTips}><Translation id="templateEditorSendMailPopup.input.tips" /></div>

        <CustomButton 
          type={"primary"}
          size="lg"
          loading={emailSendLoading}
          onClick={() => sendNewsletterPreview()}
        >
          <Translation id="button.sendMail" />
        </CustomButton>
      </div>
    </Dialog>
  );
};