import { TextField } from "../common/Input/TextField";
import { Translation } from "../common/Translation";
import { Tooltip } from "../Tooltip.js";

import classes from "./TemplateEditorSubject.module.scss";

export const TemplateEditorSubject = ({ subject = "", setSubject, editor }) => {
  
  return (
    <div className={classes.container}>
      <div className={classes.subject}>
        <div className="textMedium"><Translation id="email.subject" /></div>
        <Tooltip title={subject}>
          <div className={classes.inputContainer}>
            <TextField 
              value={subject}
              onChange={event => setSubject(event.target.value)}
              onBlur={() => editor.trigger("update")}
              size="small"
              isAutoSized
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};