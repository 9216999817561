import React, { Component } from "react";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import { withAuth } from "../../../../withAuth.js";
import { palette } from "../../../../../styles/palette.js";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { EventSystem } from "../../../../../eventsystem/EventSystem.js";
import { htmlCustomizerTooltipStyleValues } from "../../common/HtmlCustomizerTooltip/htmlCustomizerTooltipStyleValues.js";
import { TextField } from '@material-ui/core';
import { CustomButton } from "../../../../common/Button/CustomButton.js";






const styles = (theme) => {
  const { inputHorizontalPadding } =
    htmlCustomizerTooltipStyleValues;

  const containerInputFilledHover = {
    "& label": {
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23${palette.purple.replace(
        "#",
        ""
      )}' stroke-width='2' stroke-dasharray='10%2c10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
      boxShadow: "inset 0 0 0 1000px rgba(255,255,255,.2)",
    },
    "& .delete": {
      display: "block !important",
    },
    "& $linkIcon": {
      visibility: "visible",
    },
  };

  return {
    container: {
      position: "absolute",
      color: "transparent",
    },
    containerInputEmpty: {
      "& label": {
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23${palette.purple.replace(
          "#",
          ""
        )}' stroke-width='2' stroke-dasharray='10%2c10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
      },
    },
    containerInputFilled: {
      "&:hover": containerInputFilledHover,
    },
    containerInputFilledHover: containerInputFilledHover,
    containerInput: {
      position: "relative",
      "& label": {
        minHeight: "45px",
        color: palette.purple,
        cursor: "pointer",
      },
      color: palette.purple,
      "& .delete": {
        display: "none",
        position: "absolute",
        fontSize: "25px",
        right: "10px",
        top: "5px",
        zIndex: "1000000",
        "&:hover": {
          color: "red",
          cursor: "pointer",
        },
      },
    },
    label: {
      position: "absolute",
      display: "block",
      color: "transparent",
      fontSize: "20px",
      width: "190px",
      marginTop: "-15px",
      padding: `12px ${inputHorizontalPadding}px 4px`,
    },
    labelWithImage: {
      display: "block",
      color: "transparent",
      fontSize: "24px",
      position: "absolute",
    },
    input: {
      display: "none",
    },
    layer: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: "10000000",
      "&:hover": {
        "& label": {
          backgroundColor: "rgba(248, 247, 216, 0.7)",
        },
      },
    },
    labelText: {
      textAlign: "center",
    },
    linkIcon: {
      visibility: "hidden",
      position: "absolute",
      fontSize: "17px",
      right: "40px",
      top: "5px",
      zIndex: "1000000",
      "&:hover": {
        cursor: "pointer",
      },
    },
    linkInputContainer: {
      backgroundColor: palette.black,
      padding: "10px 20px",
      display: "flex",
      alignItems: "start",
      gridGap: 5,
      color: palette.white,
      fontSize: 14,
      borderRadius: 7,
      "& i": {
        paddingRight: 5,
      },
      "& span": {
        cursor: "pointer",
      },
      "& .MuiFormHelperText-root": {
        paddingTop: 4,
        paddingBottom: 0,
        color: "#FF9797",
      }
    },
    triangle: {
      position: "absolute",
      bottom: -20,
      right: 10,
      width: 0,
      height: 0,
      borderTop: `10px solid ${palette.black}`,
      borderBottom: "10px solid transparent",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
    },
    linkPopover: {
      position: "absolute",
      top: "-55px",
      right: "32px",
      width: "auto",
    },
    linkPopover2: {
      position: "absolute",
      top: "-73px",
      right: "32px",
      width: "300px",
    },
    linkButton:{
      width:"40px",
      height:"40px",
    },
    paper: {
      backgroundColor: "transparent",
    }
  };
};


export const HtmlCustomizerImage = withAuth(
  withStyles(styles)(
    injectIntl(
      class HtmlCustomizerImage extends Component {
        constructor(props) {
          super(props);
          this.imageHandler = this.imageHandler.bind(this);
          this.closeImage = this.closeImage.bind(this);
          this.onSaveLink = this.onSaveLink.bind(this);
          this.onDeleteLink = this.onDeleteLink.bind(this);
          this.onOpenLink = this.onOpenLink.bind(this);
          this.onCloseLink = this.onCloseLink.bind(this);
          this.onOpenLinkForm = this.onOpenLinkForm.bind(this);
          this.anchorRef = React.createRef();

          this.state = {
            headbandHeight: 0,
            headbandWitdh: 0,
            headbandImgUrl: null,
            linkFormIsOpen: false,
            linkEditIsOpen: false,
            linkInfoIsOpen: false,
            link: props.link || null,
            linkError: false,
          };
        }

        async componentDidMount() {
          const img = document.querySelector("#headbandImg-img");
          const td = document.querySelector("#tdHeadbandImg");


          let headbandImgUrlIsValid = false;


          try {
            const response = await fetch(this.props.headbandImgUrl, {
              // Chrome cache bug when caching S3 assets
              // https://stackoverflow.com/a/55163299
              cache: "no-cache",
            });
            if (response.status === 404) throw new Error();

            headbandImgUrlIsValid = true;
          } catch (error) {
            headbandImgUrlIsValid = false;
          }
          this.setState({
            headbandImgUrl: headbandImgUrlIsValid
              ? this.props.headbandImgUrl
              : null,
          });
          if (!headbandImgUrlIsValid) {
            Object.assign(td, {
              style: "width: 100%; display: block; height: 75px",
            });
            Object.assign(img, {
              src: null,
            });
          } else if (
            this.props.headbandImgUrl !== undefined &&
            this.props.headbandImgUrl !== null
          ) {
            Object.assign(img, {
              src: this.props.headbandImgUrl,
              style: "width: 100%",
            });
            if (img.complete) {
              this.setState({ headbandHeight: img.height });
            } else {
              img.addEventListener("load", () =>
                this.setState({ headbandHeight: img.height })
              );
            }
          } else {
            Object.assign(td, {
              style: "width: 100%; display: block; height: 75px",
            });
            Object.assign(img, {
              src: null,
            });
            this.props.setValue(null);
          }
        }

        // upload , display headband image
        imageHandler = (e) => {
          const { intl } = this.props;

          const reader = new FileReader();
          reader.onloadend = () => {
            if (reader.readyState === 2) {
              const file = document.querySelector("input[type=file]").files[0]; // Récupération du fichier
              const image = new Image();
              image.src = reader.result;

              // get dimension of img (DOM)
              image.onload = () => {
                // access image size here
                document.getElementById("file-input").value = null;
                const img = document.querySelector("#headbandImg-img");

                if (file.size <= 150 * 1024 && image.width <= 800 && image.height <= 800) {
                  Object.assign(img, {
                    src: reader.result,
                    style: "width: 100%;",
                  });
                  this.props.setHeadbandImgFile(file);
                  this.setState({
                    headbandHeight: img.height,
                    headbandWitdh: img.width,
                    headbandImgUrl: reader.result,
                  });
                  this.props.setValue(reader.result);
                } else {
                  EventSystem.newNotification(
                    "notification.action",
                    intl.messages["htmlCustomizer.image.error"]
                  );
                  Object.assign(td, {
                    style: "width: 100%; display: block; height: 75px",
                  });
                  Object.assign(img, {
                    src: null,
                  });
                }
              };

              const td = document.querySelector("#tdHeadbandImg");
              Object.assign(td, {
                style: "width: 100%; display: block;",
              });
            }
          };
          // "if" condition fix crash if instead of choose an attachement, clicking cancel
          if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
          }
        };

        closeImage() {
          const file = document.querySelector("input[type=file]").files[0]; // Récupération du fichier
          const img = document.querySelector("#headbandImg-img");
          const td = document.querySelector("#tdHeadbandImg");
          Object.assign(img, {
            src: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
            width: 0,
          });
          Object.assign(td, {
            style: "width: 100%; display: block; height: 75px",
          });

          this.props.setValue(null);
          this.props.setHeadbandImgFile(file);

          this.setState({
            headbandHeight: 0,
            headbandWitdh: 0,
            headbandImgUrl: null,
          });
        }

        onSaveLink() {
          const regex = new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/);
          if (this.state.link.match(regex)) {
            this.props.setHeadbandLink(this.state.link);
            this.setState({ linkFormIsOpen: false, linkError: false });
          } else {
            this.props.setHeadbandLink(null);
            this.setState({ linkError: true });
          }
        }

        onDeleteLink() {
          this.setState({ link: null, linkInfoIsOpen: false, linkEditIsOpen:false });
          this.props.setHeadbandLink(null);
        }
        
        onOpenLink() {
          if (this.state.headbandImgUrl && this.state.linkFormIsOpen === false && !this.state.link) {
            this.setState({linkInfoIsOpen: true });
          }
        }

        onCloseLink() {
          this.setState({linkInfoIsOpen:false });
        }

        onOpenLinkForm() {
          if (this.state.link) {
            this.setState({linkFormIsOpen: false, linkEditIsOpen: this.state.linkEditIsOpen ? false :true, linkInfoIsOpen: false});
          } else {
            this.setState({linkFormIsOpen: this.state.linkFormIsOpen ? false : true , linkEditIsOpen: false, linkInfoIsOpen: false});
          }
        }
       

        render() {
          const { classes, intl } = this.props;


          return (
            <div
              className={`${classes.container}`}
              style={{
                width: this.state.headbandImgUrl ? "800px" : "590px",
                height: this.state.headbandImgUrl
                  ? this.state.headbandHeight
                  : "72px",
                marginTop: this.state.headbandImgUrl
                  ? "-" + this.state.headbandHeight + "px"
                  : 0,
              }}
            >
              <div
                className={classNames(classes.containerInput, {
                  [classes.containerInputEmpty]: !this.state.headbandImgUrl,
                  [classes.containerInputFilled]: !!this.state.headbandImgUrl,
                  [classes.containerInputFilledHover]:
                    this.state.linkEditIsOpen || this.state.linkFormIsOpen,
                })}
              >

              {/* ////// POPUP A REFAIRE  */}

                {this.state.linkFormIsOpen ? 
                    <div className={classes.linkPopover2} ref={(ref) => (this.containerRef = ref)} onClick={this.handleClick}>
                    <div className={classes.linkInputContainer}>
                      <div className={classes.linkField}>
                        <TextField
                          style={{backgroundColor:"white", borderRadius:"5px", width:"210px"}}
                          placeholder={intl.messages["htmlCustomizerImage.link.field.placeholder"]}
                          size="small"
                          value={this.state.link}
                          onChange={event => this.setState({ link: event.target.value })}
                          errorMessage={this.state.linkError ? intl.messages["error.url.format"] : null}
                        />
                      </div>
                      <div>
                        <CustomButton size="xs" onClick={this.onSaveLink}>
                          {intl.messages["button.ok"]}
                        </CustomButton>
                      </div>
                      <div className={classes.triangle} />
                    </div>
                  </div>:null
                }

               
                {this.state.linkEditIsOpen ? 
                  <div className={classes.linkPopover} ref={(ref) => (this.containerRef = ref)} onClick={this.handleClick}>
                    <div className={classes.linkInputContainer}>
                    
                        <div style={{ width:"202px" }}>
                          <span onClick={() => this.setState({ linkEditIsOpen: false, linkFormIsOpen: true })}>
                            <i className="fa-regular fa-pen-to-square" />
                            {intl.messages["htmlCustomizerImage.link.edit"]}
                          </span>
                          <span>|</span>
                          <span onClick={this.onDeleteLink}>
                            <i className="fa-regular fa-trash" />
                            {intl.messages["htmlCustomizerImage.link.delete"]}
                          </span>
                        </div>
                    <div className={classes.triangle} />
                  </div>
                </div> : null
                }
                
                {this.state.linkInfoIsOpen ? 
                  <div className={classes.linkPopover} ref={(ref) => (this.containerRef = ref)} onClick={this.handleClick}>
                    <div className={classes.linkInputContainer}>
                        <div style={{ width:"auto" }}>
                          <span>
                            <i className="fa-regular fa-plus" />
                            {intl.messages["htmlCustomizerImage.link.add"]}
                          </span>
                        </div>
                    <div className={classes.triangle} />
                  </div>
                </div> : null
                }


                 {/* ////// *************  */}

                <div
                  onMouseEnter={this.onOpenLink}
                  onMouseLeave={this.onCloseLink}
                  onClick={this.onOpenLinkForm}
                  ref={(ref) => (this.containerRef = ref)} 
                  className={classes.linkIcon}>
                  <i className="fa-regular fa-link" />
                </div> 

               
                <CloseRoundedIcon
                  ref={this.anchorRef}
                  className="delete"
                  type="button"
                  onClick={this.closeImage}
                />

                <input
                  type="file"
                  accept="image/*"
                  name="image-upload"
                  className={classes.input}
                  id="file-input"
                  onChange={this.imageHandler}
                />
                <label
                  htmlFor="file-input"
                  className={
                    this.state.headbandImgUrl
                      ? classes.labelWithImage
                      : classes.label
                  }
                  style={{
                    width: this.state.headbandImgUrl ? "800px" : "490px",
                    height: this.state.headbandImgUrl
                      ? this.state.headbandHeight
                      : "72px",
                    minHeight: "44px",
                    marginLeft: this.state.headbandImgUrl ? null : "145px",
                  }}
                >
                  {this.state.headbandImgUrl ? null : (
                    <div className={classes.labelText}>
                      {intl.messages["htmlCustomizer.image.placeholder"]}
                    </div>
                  )}
                </label>
              </div>
            </div>
          );
        }
      }
    )
  )
);
