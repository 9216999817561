const LABEL = "columnLogo";
const CATEGORY = "columnComponent:logo";

export const columnLogo = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {
    const { logoUrl, href, alt, width = 200 } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-image data-column-component="true" data-blocklabel="${LABEL}" align="center" href="{{href}}" alt="{{alt}}" padding-bottom="0" padding-left="0" padding-top="0" padding-right="0" src="{{logoUrl}}" width="${width}px"></mj-image>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        variables: {
          logoUrl,
          href,
          alt,
        },
        editableProperties: [
          { key: "logoUrl", type: "logo", label: "optionalMenu.category.myImages", group: "newsletter.rightMenu.propertyGroup.image" },
          { key: "width", type: "imageSize", label: "newsletter.rightMenu.property.imageSize", options: { disableFullSize: true } },
          { key: "href", type: "link", label: "newsletter.rightMenu.property.imageLink" },
          { key: "alt", type: "string", label: "newsletter.rightMenu.property.alt" },
        ]
      }
    };
  },
});
