import { useContext, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";

import { CustomButton } from "../../common/Button/CustomButton";
import { Translation } from "../../common/Translation";
import { TemplateEditorContext } from "../TemplateEditorContext";

import classes from "./GeneratedTextProductMenu.module.scss";

export const GeneratedTextProductMenu = ({ content, onChange, onAddBlock }) => {

  const intl = useIntl();
  const { productsInTemplate, setIsFirstLoading } = useContext(TemplateEditorContext);
  
  const getTitleFromJson = useCallback((title) => title[intl.locale] || title.fr || title.en || title.es || title.de || title.nl || "", [intl]);

  useEffect(() => {
    if (content?.title || content?.subtitle || content?.text) {
      onChange({ title: undefined, subtitle: undefined, text: undefined });
    }
  }, [onChange, content]);

  useEffect(() => {
    if (productsInTemplate.length > 1 && (!content?.productIds || content?.productIds.length === productsInTemplate.length)) {
      onChange({ productIds: [], disableGPT: false });
    }
    if (productsInTemplate.length === 1 && content.productIds?.length !== 1 && !content.disableGPT) {
      onChange({ productIds: [productsInTemplate[0].id_product], disableGPT: false });
    }
  }, [content, onChange, productsInTemplate]);


  const onProductClick = (productId) => {
    setIsFirstLoading(true);
    if (content.productIds.includes(productId)) {
      //remove product
      onChange({ productIds: content.productIds.filter(id => id !== productId), disableGPT: false });
    } else {
      //add product
      onChange({ productIds: [...content.productIds, productId], disableGPT: false });
    }
  };

  return (
    <div className={classes.container}>
      <h2><Translation id="newsletter.rightMenu.content.textProduct.title" /></h2>
      <>
        <div className={classes.separator} />

        <div className={`${classes.productAll} ${ content.disableGPT ? classes.productSelected : "" }`} onClick={() => onChange({ disableGPT: true, productIds: [] })}>
          <Translation id="newsletter.generatedTextProductMenu.ownText" />
        </div>        

        <div className={classes.productList}>          
          <div className={classes.productListTitle}>
            <i className="fa-solid fa-sparkles" />
            <h4><Translation id="newsletter.generatedTextProductMenu.iaText" /></h4>
          </div>
          {productsInTemplate.length ? 
            <div className={`text ${classes.productListSubtitle}`}>
              <Translation id="newsletter.generatedTextProductMenu.iaText.subtitle" />
            </div> :
            <div className={classes.noProducts}>
              <div className="text"><Translation id="newsletter.generatedTextProductMenu.noproducts" /></div>
              <CustomButton 
                size="xs"
                type="secondary"
                onClick={() => onAddBlock("product")}
              >
                <Translation id="newsletter.generatedTextProductMenu.addproduct" />
              </CustomButton>
            </div>
          }
          {productsInTemplate.length > 1 &&
          <div className={`${classes.productAll} ${ content.productIds?.length === 0 && !content.disableGPT ? classes.productSelected : "" }`} onClick={() => onChange({ productIds: [], disableGPT: false })}>
            <Translation id="generatedTextProductMenu.all" />
          </div>
          }
          <div className={classes.productsContainer}>
            {!!productsInTemplate?.length && productsInTemplate.map(product => (
              <div className={`${classes.product} ${content?.productIds?.includes(product.id_product) ? classes.productSelected : ""}`} key={product.id_product} onClick={() => onProductClick(product.id_product)}>
                <img className={classes.productImage} height={70} alt="" src={product.image_url} />
                <div className={`text ${classes.productTitle}`}>{getTitleFromJson(product.title)}</div>
              </div>
            ))}
          </div>
        </div>
      </>
    </div>
  );
};