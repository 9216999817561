import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";

import { TextField } from "../../../../common/Input/TextField";
import { CustomButton } from "../../../../common/Button/CustomButton";

import { palette } from "../../../../../styles/palette";

const styles = theme => ({
  linkInputContainer: {
    backgroundColor: palette.black,
    padding: "10px 20px",
    display: "flex",
    alignItems: "start",
    gridGap: 5,
    color: palette.white,
    fontSize: 14,
    borderRadius: 7,
    "& i": {
      paddingRight: 5,
    },
    "& span": {
      cursor: "pointer",
    },
    "& .MuiFormHelperText-root": {
      paddingTop: 4,
      paddingBottom: 0,
      color: "#FF9797",
    }
  },
  triangle: {
    position: "absolute",
    bottom: -20,
    right: 10,
    width: 0,
    height: 0,
    borderTop: `10px solid ${palette.black}`,
    borderBottom: "10px solid transparent",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
  },
  linkPopover: props => ({
    position: "relative",
    marginBottom: props.withMargin ? 15 : 0,
    marginRight: props.withMargin ? 20 : 0,
  }),
  linkField: {
    width: 200,
    minHeight: 46,
    paddingTop: 16,
    paddingLeft: 10,
  },
  linkButton: {
    paddingTop: 16,
    paddingRight: 10,
  },
});

const useStyles = makeStyles(styles);

export const HtmlCustomizerLinkForm = injectIntl(({
  intl,
  value,
  onChange,
  error,
  onSave,
  withTriangle = true,
  withMargin = true,
}) => {

  const classes = useStyles({ withTriangle, withMargin });

  return (
    <div className={classes.linkPopover}>
      <div className={classes.linkInputContainer}>
        <div className={classes.linkField}>
          <TextField
            placeholder={intl.messages["htmlCustomizerImage.link.field.placeholder"]}
            size="small"
            value={value}
            onChange={onChange}
            errorMessage={error ? intl.messages["error.url.format"] : null}
          />
        </div>
        <div className={classes.linkButton}>
          <CustomButton
            size="xxs"
            onClick={onSave}
          >
            {intl.messages["button.ok"]}
          </CustomButton>
        </div>
        {withTriangle && <div className={classes.triangle} />}
      </div>
    </div>
  );
});

export const HtmlCustomizerLinkEdit = injectIntl(({
  intl,
  value,
  onOpenForm,
  onDeleteLink,
  withTriangle = true,
  withMargin = true
}) => {
  
  const classes = useStyles({ withTriangle, withMargin });
  
  return (
    <div className={classes.linkPopover}>
      <div className={classes.linkInputContainer}>
        {value ? 
          <>
            <span onClick={onOpenForm}>
              <i className="fa-regular fa-pen-to-square" />
              {intl.messages["htmlCustomizerImage.link.edit"]}
            </span>
            <span>|</span>
            <span onClick={onDeleteLink}>
              <i className="fa-regular fa-trash" />
              {intl.messages["htmlCustomizerImage.link.delete"]}
            </span>
          </> : <>
            <span onClick={onOpenForm}>
              <i className="fa-regular fa-plus" />
              {intl.messages["htmlCustomizerImage.link.add"]}
            </span>
          </> 
        }
        {withTriangle && <div className={classes.triangle} /> }
      </div>
    </div>
  );
});
