import { CircularProgress } from "@material-ui/core";
import { useState, useEffect, useCallback, useContext } from "react";
import mjml2html from "mjml-browser";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Dialog } from "../common/Dialog";
import { KiDatePicker } from "../common/KiDatePicker/KiDatePicker";
import { Translation } from "../common/Translation";

import { useAuth } from "../../hooks/useAuth";

import { ConfirmDeleteNewsletterPopup } from "./ConfirmDeleteNewsletterPopup";
import { ConfirmPauseNewsletterPopup } from "./ConfirmPauseNewsletterPopup";
import { ConfirmRescheduleNewsletterPopup } from "./ConfirmRescheduleNewsletterPopup";
import { KiTextField } from "../common/Input/KiTextField";
import { EventSystem } from "../../eventsystem/EventSystem.js";

import classes from "./NewsletterEditPopup.module.scss";
import { palette } from "../../styles/palette";
import { getMinNewsletterDate, getNewsletterInSameRange } from "./NewsletterChooseDate.jsx";
import { AccountContext } from "../../contexts/context.js";
import { ConfirmPlanNewsletterPopup } from "./ConfirmPlanNewsletterPopup.jsx";

export const NewsletterEditPopup = ({ isOpen, close, row, reload }) => {

  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [template, setTemplate] = useState(null);
  const [html, setHtml] = useState("");
  const [isDeletePopupOpened, setIsDeletePopupOpened] = useState(false);
  const [isPausePopupOpen, setIsPausePopupOpened] = useState(false);
  const [isReschedulePopupOpen, setIsReschedulePopupOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [date, setDate] = useState();
  const [isSent, setIsSent] = useState();
  const [isToday, setIsToday] = useState(null);
  const [name, setName] = useState();
  const [scheduleError, setRescheduleError] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [loading, setLoading] = useState(null);
  const [shouldShowNotif, setShouldShowNotif] = useState(false);
  const [minDate, setMinDate] = useState(undefined);
  const { account } = useContext(AccountContext);
  const [planInfo, setPlanInfo] = useState(null);
  const [datePickerKey, setDatePickerKey] = useState(0);
  const auth = useAuth();
  const intl = useIntl();

  useEffect(() => {
    if (!isOpen) {
      setIsDeletePopupOpened(false);
    }
  }, [isOpen]);

  const loadData = useCallback(() => {
    if (row) {
      
      setIsSent(!!row?.statistic);
      auth.fetch(`/api/account_management/mjmlTemplate?templateId=${row.id}`).then(template => {
        auth.fetch("/api/data/getProductsByIds", {
          method: "POST",
          body: JSON.stringify({
            productIds: template.productIds,
          })
        }).then(setProducts);
        setTemplate(template);
      });      
    } else {
      setProducts([]);
      setTemplate(null);
      setRescheduleError(false);
      setEditMode(false);
      setShowErrorMessage(false);
      setLoading(null);
    }
  }, [row, auth]);

  useEffect(loadData, [loadData]);

  useEffect(() => {
    if (template) {
      let html = mjml2html(template.template, { validationLevel: "skip" }).html;
      Object.entries(template.wordings).forEach(([wordingId, wordings]) => {
        if (wordings) {
          html = html.replaceAll(`{{${wordingId}}}`, wordings[intl.locale]);
        }
      });

      Object.entries(template.variables).forEach(([key, variable]) => {
        if (variable) {
          html = html.replaceAll(`{{${key}}}`, variable[intl.locale]);
        }
      });

      html = html.replaceAll(/href="[^"]*"/g, "href=\"\" onclick=\"return false;\"");

      if(template?.dateSent) {
        const date = new Date(template.dateSent);
        const todayFormatted =  new Date().toISOString().split('T')[0];
        const dateToCheckFormatted = date.toISOString().split('T')[0];
        setDate(date);
        setIsToday(todayFormatted === dateToCheckFormatted);
      }
      
      setName(template.name);

      setHtml(html);
    } else {
      setHtml("");
    }
  }, [template, intl]);  

  const deleteNewsletter = () => {
    setLoading("delete");
    auth.fetch(`/account_management/mjmlTemplate?templateId=${row.id}`, { method: "DELETE" }).then(() => {      
      reload();
      EventSystem.newNotification("notification.action", intl.messages["notif.deleteNewsletter"]);
      close();
    });
  };

  const pauseNewsletter = () => {
    auth.fetch("/api/account_management/saveMjmlTemplate", {
      method: "POST",
      body: JSON.stringify({
        templateId: row.id,
        suspendedDate: template.dateSent,
        dateSent: null,
      }),
    }).then(() => {
      loadData();
      reload();
      setShouldShowNotif(true);
      setIsPausePopupOpened(false);
    });
  };

  const rescheduleNewsletter = () => {
    auth.fetch("/api/account_management/saveMjmlTemplate", {
      method: "POST",
      body: JSON.stringify({
        templateId: row.id,
        dateSent: template.suspendedDate,
        suspendedDate: null,
      }),
    }).then(() => {
      loadData();
      reload();
      setShouldShowNotif(true);

      if (new Date(template.suspendedDate)?.getTime() < Date.now()) {
        setRescheduleError(true);
      }

      setIsReschedulePopupOpen(false);
    });
  };

  const getTitleFromJson = useCallback((title) => title[intl.locale] || title.fr || title.en || title.es || title.de || title.nl || "", [intl]);

  const saveDate = (newDate) => {
    if (scheduleError && newDate.getTime() < Date.now()) {
      return setShowErrorMessage(true);
    }

    setLoading("template");
    if (!template.dateSent) {
      return ;
    }

    auth.fetch("/api/account_management/saveMjmlTemplate", {
      method: "POST",
      body: JSON.stringify({
        templateId: row.id,
        dateSent: newDate
      })
    }).then(() => {
      loadData();
      reload();
      setShouldShowNotif(true);
      setShowErrorMessage(false);
      setLoading(null);
    });
  };

  useEffect(() => {
    if (!row || !editMode || row.name === name) {
      return ;
    }

    const timeout = setTimeout(() => {
      auth.fetch("/api/account_management/saveMjmlTemplate", {
        method: "POST",
        body: JSON.stringify({
          templateId: row.id,
          name,
        })
      }).then(() => {
        setShouldShowNotif(true);
        loadData();
        reload();
      });
    }, 500);

    return () => clearTimeout(timeout);

  }, [name, auth, row, editMode, loadData, reload]);

  const getDateComponent = () => {
    if (!template) {
      return "";
    } 

    if (!template?.dateSent) {
      return <Translation id="newsletter.editPopup.unplanned" /> ;
    }

    if (template.suspendedDate) {
      return <Translation id="newsletterList.table.status.suspended" />;
    }

    if (!isSent && date && !isToday) {      

      return (
        <div className={`${classes.datePickerContainer} ${scheduleError ? classes.datePickerError : ""}`} style={{ color: "red" }}>
          {minDate !== undefined && <KiDatePicker
            key={datePickerKey}
            value={date}
            onChange={(value) => {
              if (value.getTime() > Date.now()) {
                setRescheduleError(false);
              }

              const templateInSameRange = getNewsletterInSameRange(account, value, row.id);
              if (templateInSameRange.length) {
                setPlanInfo({
                  templateInSameRange,
                  value,
                });
              } else {
                setDate(value);
                saveDate(value);
              }
            }}
            disablePast
            disableToday
            minDate={minDate}
          />}
        </div>
      );
    }

    const formattedDate = new Date(template.dateSent).toLocaleString(intl.locale, { year: "numeric", month: "2-digit", day: "2-digit"});
    return formattedDate;
  };  

  useEffect(() => {
    if (!scheduleError) {
      setShowErrorMessage(false);
    }
  }, [scheduleError]);

  const cloneNewsletter = () => {
    setLoading("clone");
    const newName = template.name + ` (${intl.messages["copy"]})`;
    auth.fetch("/api/account_management/cloneMjmlTemplate", {
      method: "POST",
      body: JSON.stringify({
        templateId: row.id,
        name: newName,
      }),
    }).then(() => {
      reload();
      EventSystem.newNotification("notification.action", intl.messages["notif.cloneNewsletter"]);
      close();
    });
  };

  useEffect(() => {
    if (account && minDate === undefined) {
      auth.fetch("/data/getNewsletterStatistics").then(({ statistics, date_last_sync }) => {
        const _minDate = getMinNewsletterDate(account, statistics, date_last_sync);
        setMinDate(_minDate);        
      });
    }
  }, [auth, account, minDate]);

  const onConfirmDate = async () => {
    for (const template of planInfo.templateInSameRange) {
      await auth.fetch("/api/account_management/saveMjmlTemplate", {
        method: "POST",
        body: JSON.stringify({
          templateId: template.id,
          dateSent: null
        })
      });
    }

    setDate(planInfo.value);
    saveDate(planInfo.value);
    setPlanInfo(null);
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        close={() => {
          if (shouldShowNotif) {
            EventSystem.newNotification("notification.action", intl.messages["notif.newsletterSaved"]);
          }
          setIsToday(null);
          close();
        }}
        style={{ padding: "0px", }}
        PaperProps={{ style: { borderRadius: 20, marginLeft: 150, overflowY: "hidden", } }}
      >
        <div className={classes.container}>
          <div className={classes.actions}>
            <div className={classes.actionsGroup}>
              <div
                className={classes.action} 
                onClick={() => {
                  if (shouldShowNotif) {
                    EventSystem.newNotification("notification.action", intl.messages["notif.newsletterSaved"]);
                  }
                  history.push(`/editor/${row.id}?from=newsletterList`);
                }}>
                <i className="fa-solid fa-pen-to-square" />
                <span className="infoMedium"><Translation id="newsletter.editPopup.edit" /></span>
              </div>
              <div className={classes.action} onClick={!loading ? cloneNewsletter : null}>
                {loading === "clone" ? <CircularProgress size={15} className={classes.cloneProgress} /> : <i className="fa-solid fa-clone" />}
                <span className="infoMedium"><Translation id="button.clone" /></span>
              </div>
              {!!template?.dateSent && !isSent && isToday === false &&
                <div className={classes.action} onClick={() => setIsPausePopupOpened(true)}>
                  <i className="fa-solid fa-pause" />
                  <span className="infoMedium"><Translation id="newsletter.editPopup.pause" /></span>
                </div>
              }
              {!template?.dateSent && !!template?.suspendedDate &&
                <div className={classes.action} onClick={() => setIsReschedulePopupOpen(true)}>
                  <i className="fa-solid fa-play" />
                  <span className="infoMedium"><Translation id="newsletter.editPopup.reschedule" /></span>
                </div>
              }
            </div>
            
            {!isSent && !isToday &&
              <div className={classes.action} onClick={() => setIsDeletePopupOpened(true)}>
                <i className="fa-solid fa-trash-can" />
                <span className="infoMedium"><Translation id="button.remove" /></span>
              </div>
            }
          </div>

          <div className={classes.content}>
            <div className={classes.rightPart}>
              <div>
                {editMode ?
                  <KiTextField 
                    type="whiteBg"
                    autoComplete="off"
                    name="newsletter_name"
                    value={name}
                    textColor={palette.primaryBlack}
                    handleChange={event => setName(event.target.value)}
                  /> :
                  <div className={classes.title}>
                    <h2>{name}</h2>
                    <span onClick={() => setEditMode(true)}><i className="fa-solid fa-pen-to-square" /></span>
                  </div>
                }
                <div className={`${classes.dateText} text`} style={editMode ? { marginTop: 20, marginBottom: !showErrorMessage ? 20 : 0 } : {}}>
                  <Translation id="newsletterList.table.head.date" /> :&nbsp;
                  <span className="textMedium">
                    {getDateComponent()}
                  </span>                  
                </div>
                {showErrorMessage && <div className={`${classes.errorMessage} info`}><Translation id="newsletter.editPopup.rescheduleError" /></div>}
                <div>
                  <span className="text"><Translation id="newsletter.editPopup.products" /></span>
                  <div className={classes.products}>
                    {products.map(product => (
                      <div key={product.id_product} className={classes.productTag}>
                        <i className="fa-solid fa-tags" />
                        <div className="info">{getTitleFromJson(product.title)}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.previewIframeContainer}>
              <iframe
                className={classes.previewIframe}
                onLoad={event => event.target.contentDocument.body.style["overscroll-behavior"] = "contain"}
                srcDoc={html}
                title="previewTemplateEmail"
              ></iframe>
            </div>
          </div>
        </div>
      </Dialog>
      <ConfirmDeleteNewsletterPopup isOpen={isDeletePopupOpened} close={() => setIsDeletePopupOpened(false)} deleteNewsletter={deleteNewsletter} loading={loading === "delete"} />
      <ConfirmPauseNewsletterPopup isOpen={isPausePopupOpen} close={() => setIsPausePopupOpened(false)} pauseNewsletter={pauseNewsletter} />
      <ConfirmRescheduleNewsletterPopup isOpen={isReschedulePopupOpen} close={() => setIsReschedulePopupOpen(false)} suspendedDate={template?.suspendedDate} rescheduleNewsletter={rescheduleNewsletter} />
      <ConfirmPlanNewsletterPopup 
        isOpen={!!planInfo}
        close={() => {
          setPlanInfo(null);
          setDatePickerKey(prev => prev + 1);
        }}
        onConfirm={onConfirmDate}
      />
    </>
  );
};