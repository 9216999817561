import React, { Component } from "react";

import { injectIntl } from "react-intl";

import { Checkbox } from "../../../../../../common/Input/Checkbox.js";

export const IncludesFreeShippingInput = injectIntl(
  class extends Component {
    render() {
      const {
        intl,
        promoCodeTemplateWrapper,
        setPromoCodeTemplateWrapperProp,
      } = this.props;

      return (
        <Checkbox
          checked={
            promoCodeTemplateWrapper.promoCodeTemplate.includesFreeShipping
          }
          disabled={promoCodeTemplateWrapper.promoCodeTemplate.type === "%"}
          disabledExplanation={
            intl.messages[
              "usecase.promoCodeTemplateEditor.options.includesFreeShipping.disabled.explanation"
            ]
          }
          onChange={(event) =>
            setPromoCodeTemplateWrapperProp(
              "promoCodeTemplate.includesFreeShipping",
              event.target.checked
            )
          }
          label={
            intl.messages[
              "usecase.promoCodeTemplateEditor.options.includesFreeShipping"
            ]
          }
        />
      );
    }
  }
);
