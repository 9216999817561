import { useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { Translation } from "../../../common/Translation";
import { checkImage } from "../../grapesJsKiliba/optionalMenu/imageMenu";
import { parseFileInput } from "../../../../services/file.service";
import { EventSystem } from "../../../../eventsystem/EventSystem";

import classes from "./UploadImageInput.module.scss";

export const UploadImageInput = ({ onUpload, width, height }) => {

  const intl = useIntl();
  const imageInputRef = useRef();

  const customStyles = useMemo(() => ({
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
  }), [width, height]);

  const onUploadImage = event => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.readyState === 2) {
        const image = new Image();
        image.src = reader.result;
        const file = imageInputRef.current.files[0];
        image.onload = () => {
          if (checkImage(file, image)) {
            parseFileInput(file).then(fileData => {
              onUpload(fileData);
            });
          } else {
            EventSystem.newNotification(
              "notification.error",
              intl.messages["optionalMenu.image.error"]
            );
          }
        };
      }
    };

    // "if" condition fix crash if instead of choose an attachement, clicking cancel
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div
      className={classes.uploadContainer}
      style={customStyles}
      onClick={() => imageInputRef?.current.click()}
    >
      <i className="fa-solid fa-cloud-arrow-up" />
      <div className="infoMedium"><Translation id={"optionalMenu.category.upload"} /></div>
      <div className={`infoSmall ${classes.uploadConstraints}`}><Translation id="optionalMenu.category.uploadConstraints" /></div>
      <input
        ref={imageInputRef}
        type="file"
        accept="image/*"
        className={classes.imageInput}
        onChange={onUploadImage}
      />
    </div>
  );
};