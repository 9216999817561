import { useIntl } from "react-intl";
import { CustomButton } from "../common/Button/CustomButton";
import { Translation } from "../common/Translation";
import { KiTextField } from "../common/Input/KiTextField";

import classes from "./NewsletterChooseName.module.scss";

export const NewsletterChooseName = ({ setStep, value, setValue, createTemplate, loading }) => {
  const intl = useIntl();

  const onKeyDown = (event) => {
    if (event.key === "Enter" && value) {
      createTemplate();
    }
  };

  return (
    <div className={classes.nameFormContainer}>
      <h1><Translation id="newsletter.name.title" /></h1>
      <div className="text"><Translation id="newsletter.name.subtitle" /></div>
      <div className={classes.inputContainer}>
        <KiTextField
          placeholder={`${intl.messages["newsletter.name.input.placeholder"]}`}
          type="whiteBg"
          autoComplete="off"
          name="newsletter_name"
          value={value}
          handleChange={event => setValue(event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))}
          onKeyDown={onKeyDown}
        />
      </div>
      <div style={{ display:"flex" }}>
        <CustomButton
          type="secondary"
          size="lg"
          onClick={() => setStep(prev => prev - 1)}
          style={{ marginRight: 20 }}
        >
          <Translation id="button.anulation" />
        </CustomButton>

        <CustomButton
          disabled={!value}
          onClick={createTemplate}
          loading={loading}
        >
          <Translation id="button.next2" />
        </CustomButton>
       
      </div>
      
    </div>
  );
};