import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { RadioGroup } from "../../../common/RadioGroup.js";
import { Radio } from "../../../common/Input/Radio/Radio.js";
import { InputSection } from "../../../common/Input/InputSection.js";


export const PriceParameters = injectIntl(
  class PriceParameters extends Component {
    render() {
      const { intl, priceIsVisible, setPriceIsVisible } = this.props;

      if (priceIsVisible === undefined) return null;

      return (
        <InputSection
          title={intl.messages["templateCustomization.sub_title_price_is_visible"]}
        >
          <RadioGroup value={priceIsVisible} onChange={setPriceIsVisible}>
            <Radio legacy
              value={true}
              label={intl.messages["templateCustomization.show_prices"]}
            />
            <Radio legacy
              value={false}
              label={intl.messages["templateCustomization.hide_prices"]}
            />
          </RadioGroup>
        </InputSection>
      );
    }
  }
);
