import { makeTranslatable, rewriteVariables } from "../utils";
import { logo2 } from "../logo/logo2";
import { image1 } from "../images/image1";
import { title } from "../texts/title";

const LABEL = "header3";

export const header3 = ({ logoUrl, buttonTextColor, imageUrl, fontFamily, shopUrl, generatedWordings, headerColor, accentColor }) => ({
  label: LABEL,
  block: () => {

    const logo2Block = logo2({ logoUrl: makeTranslatable(logoUrl), href: makeTranslatable(shopUrl), alt: makeTranslatable(""), backgroundColor: headerColor }).block();
    const titleBlock = title({ fontFamily, textColor: buttonTextColor, title: generatedWordings.title, backgroundColor: accentColor, fontSize: 14, sectionPadding: 0, fontWeight: 400 }).block();
    const image1Block = image1({ imageUrl: makeTranslatable(imageUrl), href: makeTranslatable(shopUrl), alt: makeTranslatable(""), backgroundColor: headerColor }).block();

    const { variables: image1Variables, mjml: image1Mjml } = rewriteVariables("image1", image1Block.attributes.variables, image1Block.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${image1Block.label}"`) );

    const mjml = `
      ${logo2Block.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${logo2Block.label}"`)}
      ${image1Mjml}
      ${titleBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${titleBlock.label}"`)}
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: "header:subHeader",
      activate: true,
      content: mjml,
      attributes: {
        wordings: titleBlock.attributes.wordings,
        variables: {
          ...logo2Block.attributes.variables,
          ...image1Variables,
        }
      }
    };
  },
  style: `
    .header3-image-wrapper > tbody > tr > td > div {
      max-width: 800px !important;
    }

    .header3-image-wrapper div[full-width="full-width"] {
      max-width: 800px !important;
    }

    .header3-image-wrapper div[full-width="full-width"] div {
      max-width: 800px !important;
    }
  `,
});