import { useEffect, useRef } from "react";

/**
 * Track the previous value.
 * @param {any} value: the value that must be tracked 
 * @returns the previous value of @param value
 */
function usePrevious(value) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export { usePrevious };
