import { createRef, Component } from "react";

import { withStyles, darken } from "@material-ui/core/styles";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";

import { palette } from "../../../../../styles/palette.js";
import { htmlCustomizerTooltipStyleValues } from "../HtmlCustomizerTooltip/htmlCustomizerTooltipStyleValues.js";

const styles = (theme) => {
  const { tooltipBackgroundColor, tooltipPadding } =
    htmlCustomizerTooltipStyleValues;

  return {
    dropdown: {
      color: palette.white,
      fontSize: 16,
      position: "relative",
      backgroundColor: tooltipBackgroundColor,
      borderRadius: 4,
      cursor: "pointer",
    },
    dropdownTitle: {
      padding: "0 8px",
      height: "100%",
      borderRadius: "inherit",
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
      "&:hover": {
        backgroundColor: darken(tooltipBackgroundColor, 0.15),
      },
    },
    dropdownTitleIcon: {
      width: 12,
      marginLeft: 12,
      fontSize: 12,
      fontWeight: "bold",
    },
    dropdownChoices: {
      marginTop: tooltipPadding,
      backgroundColor: tooltipBackgroundColor,
      position: "absolute",
      top: "100%",
      left: 0,
      borderRadius: "0 0 4px 4px",
      overflow: "hidden",
      boxSizing: "border-box",
      zIndex: 1,
    },
    dropdownChoice: {
      padding: "4px 8px",
      height: "100%",
      "&:hover": {
        backgroundColor: darken(tooltipBackgroundColor, 0.15),
      },
      "&.selected": {
        backgroundColor: darken(tooltipBackgroundColor, 0.15),
      },
    },
  };
};

export const HtmlCustomizerDropdown = withStyles(styles)(
  class HtmlCustomizerDropdown extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isOpen: false,
      };
    }

    componentDidMount() {
      // make title and choice list the same width
      const titleElement = this.titleRef.current;
      const choicesElement = this.choicesRef.current;

      const titleWidth = titleElement.offsetWidth;
      const choicesWidth = choicesElement.offsetWidth;

      if (titleWidth > choicesWidth)
        choicesElement.style.width = `${titleWidth}px`;
      if (choicesWidth > titleWidth)
        titleElement.style.width = `${choicesWidth}px`;
    }

    render() {
      const {
        classes,
        choices,
        label,
        getIsSelected = () => false,
        onSelect,
      } = this.props;

      this.titleRef = createRef();
      this.choicesRef = createRef();

      return (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={() => this.setState({ isOpen: false })}
        >
          <div
            // prevent losing the selection on the associated text field when clicking on this dropdown
            onMouseDown={(event) => event.preventDefault()}
            className={`${classes.dropdown} ${this.state.isOpen ? "open" : ""}`}
          >
            <p
              className={classes.dropdownTitle}
              onClick={() => this.setState({ isOpen: !this.state.isOpen })}
              ref={this.titleRef}
            >
              <span>{label}</span>
              <i
                className={`${classes.dropdownTitleIcon} fal fa-caret-${
                  this.state.isOpen ? "up" : "down"
                }`}
              />
            </p>
            <Fade in={this.state.isOpen} timeout={0}>
              <ul className={classes.dropdownChoices} ref={this.choicesRef}>
                {choices.map((choice, key) => {
                  const isSelected = getIsSelected(choice);
                  return (
                    <li
                      className={`${classes.dropdownChoice} ${
                        isSelected ? "selected" : ""
                      }`}
                      onClick={() => {
                        onSelect(choice);
                        this.setState({ isOpen: false });
                      }}
                      key={key}
                    >
                      {choice.label}
                    </li>
                  );
                })}
              </ul>
            </Fade>
          </div>
        </ClickAwayListener>
      );
    }
  }
);
