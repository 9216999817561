import { useCallback, useContext, useEffect, useState } from "react";

import { Dialog } from "../../../common/Dialog.js";
import { Translation } from "../../../common/Translation.js";

import { useAuth } from "../../../../hooks/useAuth.js";
import { EventSystem } from "../../../../eventsystem/EventSystem.js";
import { AccountContext, reloadAccountContext } from "../../../../contexts/context.js";
import { FormButtonTO } from "../../../FormButtonTO.jsx";
import { StatusPicker } from "../../../pickers/StatusPicker.js";

import classes from "./UsecaseSponsorshipConfigPopup.module.scss";

export const UsecaseSponsorshipConfigPopup = ({ isOpen, close, usecaseConfig, templateDataKey }) => {

  const auth = useAuth();
  const { account } = useContext(AccountContext);
  const [defaultValue, setDefaultValue] = useState(account?.mw_custom_options?.sponsorship_order_status);
  const [statusList, setStatusList] = useState(account?.mw_custom_options?.sponsorship_order_status);
  const [defaultStatusList, setDefaultStatusList] = useState(null);
  const [pickerKey, setPickerKey] = useState(1);
  
  const onSubmit = () => {
    auth.fetch("/api/account_management/updateMwOptions", {
      method: "PUT",
      body: JSON.stringify({ 
        sponsorship_order_status: statusList,
      }),
    }).then(() => {
      setDefaultValue(statusList);
      reloadAccountContext();
      EventSystem.newNotification("notification.action", <Translation id="usecase.configPopup.save.confirm" />);
      close();
    });
  };

  const reset = useCallback(() => {
    setStatusList([...defaultStatusList, "NA"]);
    setPickerKey(prev => prev + 1);
  }, [defaultStatusList]);

  useEffect(() => {
    if (!defaultStatusList?.length) {
      return ;
    }
    if (!defaultValue?.length) {
      setStatusList([...defaultStatusList, "NA"]);
      setDefaultValue([...defaultStatusList, "NA"]);
    } else {
      setStatusList(defaultValue);
    }
  }, [isOpen, account, defaultStatusList, usecaseConfig, templateDataKey, defaultValue]);

  useEffect(() => {
    try {

      if (defaultStatusList) {
        return ;
      }

      const accountId = auth.getAccountId();
      auth.fetch(`/data/getDistinctOrderStatuses?accountId=${accountId}`, {
        method: "GET"
      }).then(orderStatuses => {
        setDefaultStatusList(orderStatuses);
        if (!statusList) {
          setStatusList(orderStatuses);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }, [auth, statusList, defaultStatusList]);

  return (
    <Dialog 
      PaperProps={{ style: { borderRadius: 20 } }}
      isOpen={isOpen}
      close={close}
      padding="64px 64px 40px 64px"
    >
      <div className={classes.container}>
        <h2 className={classes.title}><Translation id="usecaseConfigPopup.title" /></h2>
        
        {statusList &&
          <div className={classes.statusFormContainer}>
            <h3 className={classes.formTitle}><Translation id="usecaseCrossSellingConfigPopup.form.status.title" /></h3>
            <span className={classes.subtitle}><Translation id="usecaseSponsorshipConfigPopup.form.status.subtitle" /></span>
            <StatusPicker 
              key={pickerKey}
              setStatusList={setStatusList}
              statusCheckedList={statusList}
              statusList={defaultStatusList}
              zIndex={9999}
            />
          </div>
        }

        <div className={`info ${classes.errorContainer}`}>
          {!statusList?.length &&<Translation id="usecaseCrossSellingConfigPopup.error.status" />}
        </div>

        <div className={classes.buttonsContainer}>
          <FormButtonTO 
            label={<Translation id="usecaseConfigPopup.form.button.save" />}
            action={onSubmit}
            disabled={!statusList?.length}
          />
          <small
            className={classes.buttonReset}
            onClick={reset}
          >
            <Translation id="usecaseConfigPopup.form.button.reset" />
          </small>
        </div>
      </div>
    </Dialog>
  );
};