import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { injectIntl } from "react-intl";
//import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Checkbox from "@material-ui/core/Checkbox";

import { palette } from "../../styles/palette.js";

const styles = (theme) => ({
  button: {
    fontFamily: "Poppins",
    color: palette.primaryBlack,
    fontSize: "14px",
    letterSpacing: "1px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    borderRadius: 10,
    backgroundColor: palette.white,
    height: "40px",
    width: "180px",
    textDecoration: "none",
    textTransform: "none",
    boxShadow: "0px 1px 7px 0px rgba(120, 120, 120, 0.25)",
    "&:hover": {
      backgroundColor: palette.neutralBCGGreyHover,
    },
  },
  checkbox: {
    color: "gray",
  },
  checked: {
    "&$checked": {
      color: palette.primaryYellow200,
    },
  },
  root: {
    width: "100%",
    borderRadius: "5px",
    padding:0,
    maxWidth: "360px",
    "& .MuiListItem-container": {
      "&:hover": {
        backgroundColor: palette.neutralBCGGreyHover,
      },
    },  
  },
  reactDaypickerRoot: {
    width: "180px",
    borderRadius: "5px",
    textDecoration: "none",
    marginTop: "5px",
  },
 
});

export const SelectPicker = withStyles(styles)(
  injectIntl(
    class SelectPicker extends Component {
      constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickAway = this.handleClickAway.bind(this);
        this.state = {
          anchorEl: null,
          open: false,
        };
      }

      handleClick(event) {
        this.setState({
          anchorEl: event.currentTarget,
          open: !this.state.open,
        });
      }

      handleClose = function () {
        this.setState({ anchorEl: null });
      };

      handleClickAway(event) {
        this.setState({ open: false });
      }

      handleToggleOld = (value) => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }

        this.setState({
          checked: newChecked,
        });
      };

      handleToggle = (value) => () => {
        const checked = this.props.selected;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        this.props.action(this.props.name, newChecked);
      };

      render() {
        const selectList = this.props.selectList;
        const checked = this.props.selected;
        const classes = this.props.classes;

        // var wording = "Motif"
        // if( checked.length === 0 ){
        //   wording = "Aucun"
        // }else if( checked.length === selectList.length ){
        //   wording = "Tous"
        // }
        const wording = this.props.displayName;

        return (
          <div>
            <Button
              aria-owns={this.state.anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
              className={classes.button}
              style={{
                border:
                  this.state.open === true
                    ? "1px solid " + palette.primaryBlack
                    : "",
                backgroundColor: this.state.open === true ? "white" : "",
              }}
            >
              {/* <i style={{fontSize:'14px', top: '13px', position: 'absolute', left: '14px'}} className="fal fa-atom"></i> */}
              <div
                style={{
                  textAlign: "justify",
                  position: "absolute",
                  left: "34px",
                  letterSpacing: 1,
                }}
              >
                {wording}
              </div>
              <i
                style={{
                  fontSize: "14px",
                  top: "13px",
                  position: "absolute",
                  right: "14px",
                }}
                className="fal fa-angle-down"
              ></i>
            </Button>

            <Popper
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              placement={"bottom-start"}
              style={{zIndex: "1000000"}}
            >
              <ClickAwayListener onClickAway={this.handleClickAway}>
                <Paper className={classes.reactDaypickerRoot}>
                  <List dense className={classes.root}>
                    {selectList.map((value) => {
                      return (
                        <ListItem
                          disabled={this.props.disabled}
                          key={value}
                          button
                          onClick={this.handleToggle(value)}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                paddingTop:3,
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                letterSpacing: "0.5px",
                                fontWeight: "normal",
                                fontStyle: "normal",
                                fontStretch: "normal",
                                lineHeight: "normal",
                                borderRadius: 5,
                                color: palette.primaryBlack,
                                marginLeft: "19px",
                                "&:hover": {
                                  background: "blue",
                                  cursor: "pointer"
                                }
                              },
                            }}
                            primary={value}
                          />
                          <ListItemSecondaryAction>
                            <Checkbox
                              disabled={this.props.disabled}
                              classes={{
                                root: classes.checkbox,
                                checked: classes.checked,
                              }}
                              checked={checked.indexOf(value) !== -1}
                              tabIndex={-1}
                              onClick={this.handleToggle(value)}
                              disableRipple
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </div>
        );
      }
    }
  )
);
