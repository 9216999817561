import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withAuth } from "../../withAuth";
import { injectIntl } from "react-intl";
import { EventSystem } from "../../../eventsystem/EventSystem.js";
import { usecaseLocalDatas } from "../usecaseLocalDatas.js";

import { UsecasePopupLaunch } from "./UsecasePopups/UsecasePopupLaunch.js";
import { UsecasePopupSalesContact } from "./UsecasePopups/UsecasePopupSalesContact.js";
import { PromoCodeTemplateEditor } from "./UsecasePopups/PromoCodeTemplateEditor/PromoCodeTemplateEditor.js";
import { UsecasePopupFreemium } from "./UsecasePopups/UsecasePopupFreemium.js";
import { UsecasePopupEmailEditor } from "./UsecasePopups/UsecasePopupEmailEditor/UsecasePopupEmailEditor.js";
import { UsecasePopupLandingPageExample } from "./UsecasePopups/UsecasePopupLandingPageExample.js";

import { UsecaseHeader } from "./UsecaseHeader.js";
import { UsecaseOverview } from "./UsecaseOverview.js";
import { UsecaseExplanations } from "./UsecaseExplanations.js";
import { UsecaseGetStarted } from "./UsecaseGetStarted.js";
import { UsecaseCustom } from "./UsecaseCustom";

// Styles
import "../../../styles/scss/Template.css";

import { getMidnightDate, getIncrementedDate } from "../../../tools/date.js";

import { palette } from "../../../styles/palette.js";
import { freemiumUsecaseList } from "../../../ressources/freemiumUsecaseList.js";
import { UsecasePopupCheckTemplateContent } from "./UsecasePopups/UsecasePopupCheckTemplateContent";
import { usecaseAIList } from "../../../ressources/usecaseAIList.js";
import { UsecasePopupOpenAiLoading } from "./UsecasePopups/UsecasePopupOpenAiLoading";
import { UsecasePopupOpenAiError } from "./UsecasePopups/UsecasePopupOpenAiError";
import { UsecasePopupGender } from "./UsecasePopups/UsecasePopupGender";
import { UsecasePopupHistory } from "./UsecasePopups/UsecasePopupHistory";
import { AccountContextBehaviorSubject$, reloadAccountContext } from "../../../contexts/context";
import { parseFileInput } from "../../../services/file.service";

const styles = (theme) => ({
  root: {
    width: "100%",
    align: "center",
    height: "auto",
    overflow: "none",
  },
  cssOutlinedInput: {
    fontFamily: "Coolvetica Book",
    marginRight: "0px",
    marginLeft: "8px",
    height: "35px",
    width: "50px",
    overflow: "hidden",
    "&$cssFocused $notchedOutline": {
      borderColor: palette.purple,
    },
  },
  cssFocused: {},
  notchedOutline: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: palette.purple,
    },
  },
  startIcon: {
    position: "relative",
    fontSize: "30px",
    color: "#a5adb8",
  },
  input: {
    textIndent: "15px",
    fontFamily: "Coolvetica Book",
    color: palette.black,
  },
  text: {
    width: "500px",
  },
  tagButton: {
    margin: theme.spacing.unit,
    textTransform: "none",
    height: "35px",
    marginTop: "0",
    color: palette.white,
    fontSize: "15px",
    paddingLeft: "19px",
    paddingRight: "19px",
    fontWeight: "normal",
    backgroundColor: palette.anothergray,
    borderRadius: "17.5px",
    "&:hover": {
      backgroundColor: palette.anothergray,
    },
  },
  selectedTagButton: {
    margin: theme.spacing.unit,
    textTransform: "none",
    height: "35px",
    marginTop: "0",
    color: palette.black,
    fontSize: "15px",
    paddingLeft: "19px",
    paddingRight: "19px",
    fontWeight: "normal",
    backgroundColor: palette.purple,
    borderRadius: "17.5px",
    "&:hover": {
      backgroundColor: palette.purple,
    },
  },
  rootPaperCard: {
    flexGrow: 1,
    alignItems: "center",
  },
  container: {
    display: "flex",
  },
  rectangle: {
    width: "1020px",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
    backgroundColor: palette.white,
  },
  grey: {
    fontSize: "14px",
    lineHeight: "22px",
    color: palette.lightgray,
  },
  subtitle: {
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    fontWeight: "bold",
    color: palette.black,
  },
  details_title: {
    fontFamily: "Coolvetica Book",
    fontSize: "40px",
    fontWeight: "600",
    textAlign: "center",
    color: palette.black,
  },
  details_subtitle: {
    fontFamily: "Coolvetica Book",
    fontSize: "20px",
    fontWeight: "600",
    color: palette.black,
  },
  details_wording: {
    fontFamily: "Coolvetica Book",
    fontSize: "16px",
    fontWeight: "normal",
    color: palette.darkergray,
  },
  details_subwording: {
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    color: palette.darkergray,
  },
  card_stat_block: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card_stat: {
    fontSize: "77px",
    fontFamily: "Coolvetica Book",
    margin: "0",
    padding: "0",
    marginLeft: "35px",
    color: palette.black,
  },
  card_stat_newproducts: {
    fontSize: "77px",
    fontFamily: "Coolvetica Book",
    margin: "0",
    padding: "0",
    marginTop: "-35px",
    color: palette.black,
    marginLeft: "236px",
    top: "27px",
  },
  card_wording: {
    fontSize: "19px",
    textAlign: "center",
    fontFamily: "Coolvetica Book",
    marginBottom: "20px",
    color: palette.darkgray,
  },
  lower_banner_title: {
    fontFamily: "Coolvetica",
    fontSize: "40px",
    fontWeight: "normal",
    textAlign: "center",
    color: palette.white,
  },
  checkbox_root: {},
  label: {
    "&$label": {
      fontFamily: "Coolvetica Book",
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "1.57",
      letterSpacing: "normal",
      color: palette.black,
    },
  },
  label_disabled: {
    "&$label": {
      fontFamily: "Coolvetica Book",
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "1.57",
      letterSpacing: "normal",
      color: palette.gray,
    },
  },
  popUpTextStyle: {
    marginTop: "10px",
    height: "auto",
    fontSize: "14px",
    fontFamily: "Coolvetica Book",
    color: "#0E0E0F",
  },
});

export const Usecase = withAuth(
  injectIntl(
    withStyles(styles)(
      class Usecase extends Component {
        constructor(props) {
          super(props);
          this.getUsecaseNameFromHistory = this.getUsecaseNameFromHistory.bind(this);
          this.fetchEmailExamples = this.fetchEmailExamples.bind(this);
          this.fetchEmailExample = this.fetchEmailExample.bind(this);
          this.getIsFreeAccount = this.getIsFreeAccount.bind(this);
          this.getLaunchDate = this.getLaunchDate.bind(this);
          this.getTone = this.getTone.bind(this);
          this.savePromoCodeTemplate = this.savePromoCodeTemplate.bind(this);
          this.deletePromoCodeTemplate = this.deletePromoCodeTemplate.bind(this);
          this.activateUsecase = this.activateUsecase.bind(this);
          this.deactivateUsecase = this.deactivateUsecase.bind(this);
          this.getHeaderMainButtonAction = this.getHeaderMainButtonAction.bind(this);
          this.openActivationPopup = this.openActivationPopup.bind(this);
          this.showEmailEditor = this.showEmailEditor.bind(this);
          this.showPromoCodeTemplateEditor = this.showPromoCodeTemplateEditor.bind(this);
          this.saveCustomization = this.saveCustomization.bind(this);
          this.setHeadbandImgFile = this.setHeadbandImgFile.bind(this);

          this.openLaunchPopup = this.openLaunchPopup.bind(this);
          this.openCheckTemplateContentPopup = this.openCheckTemplateContentPopup.bind(this);
          this.openGenderPopup = this.openGenderPopup.bind(this);
          this.openPromoCodeTemplateEditorPopup = this.openPromoCodeTemplateEditorPopup.bind(this);
          this.openFreemiumPopup = this.openFreemiumPopup.bind(this);
          this.openEmailEditorPopup = this.openEmailEditorPopup.bind(this);
          this.openLandingPageExamplePopup = this.openLandingPageExamplePopup.bind(this);

          this.closeLaunchPopup = this.closeLaunchPopup.bind(this);
          this.closeCheckTemplateContentPopup = this.closeCheckTemplateContentPopup.bind(this);
          this.closePromoCodeTemplateEditorPopup = this.closePromoCodeTemplateEditorPopup.bind(this);
          this.closeFreemiumPopup = this.closeFreemiumPopup.bind(this);
          this.closeEmailEditorPopup = this.closeEmailEditorPopup.bind(this);
          this.closeLandingPageExamplePopup = this.closeLandingPageExamplePopup.bind(this);
          this.closeSalesContactPopup = this.closeSalesContactPopup.bind(this);
          this.saveUnisex = this.saveUnisex.bind(this);
          this.closeGenderPopup = this.closeGenderPopup.bind(this);

          this.refetchData = this.refetchData.bind(this);

          this.state = {
            selectedTemplateDataKey: null,
            emailExamples: null,
            isLegalDateChecked: false,
            params: { usecase: "default" },
            popupLaunchIsOpen: false,
            popupCheckTemplateContentIsOpen: false,
            popupGenderIsOpen: false,
            popupPromoCodeTemplateEditorIsOpen: false,
            popupFreemiumIsOpen: false,
            popupEmailEditorIsOpen: false,
            popupSalesContactIsOpen: false,
            launchDate: null,
            endDate: null,
            isActive: false,
            usecaseSettingLists: [],
            // the index of which content is displayed in the stats card
            cardTabIndex: 0,
            isFreeAccount: false,
            wordings: undefined,
            generatedWordings: undefined,
            emailEditorTabKey: "customizer",
            headbandImgUrls: undefined,
            headbandImgFiles: {},
            noCms: false,
            CMS: "",
            customName: null,
            customDate: null,
            isWordingsLoading: false,
            wordingsGenerationHasError: false,
            isFirstWordingGeneration: false,
            betaFeatures: [],
            customerBlacklistDone: false,
            popupOpenAiLoadingOpen: false,
            popupOpenAiLoadingError: false,
            popupHistoryIsOpen: false,
            shouldOpenPopupHistory: false,
            unisex: null,
            usecaseNotif: null,
            availableLanguages: null,
            calendaryUsecase:["christmas", "newYear", "valentinesDay", "halloween", "blackFriday", "backToSchool", "mothersDay", "fathersDay"],
          };
        }

        getUsecaseNameFromHistory(history) {
          const URLHasQuery =
            history.location && history.location.search.length > 0;
          if (!URLHasQuery) {
            throw new Error("Impossible de déterminer le scénario sélectionné");
          }

          const params = {};
          const paramStrings = history.location.search.substr(1).split("&");
          paramStrings.forEach((paramString) => {
            const key = paramString.split("=")[0];
            const value = paramString.split("=")[1];
            params[key] = value;
          });
          return params.usecase;
        }

        async fetchEmailExamples(usecase, tone) {
          const emailExamples = [];
          for (let i = 0; i < usecase.templateDatas.length; i++) {
            const emailExample = await this.fetchEmailExample(
              usecase.name,
              i,
              tone
            );
            emailExamples.push(emailExample);
          }
          return emailExamples;
        }

        async generateWordings(usecase, forceRegenerateWordings = false) {
          const { auth } = this.props;

          if (usecaseAIList.includes(usecase.name)) {
            const result = await auth.fetch("/api/account_management/generateEmail", {
              method: "POST",
              body: JSON.stringify({ usecase: usecase.name, forceRegenerateWordings }),
            });
            await reloadAccountContext();
            return result;
          } else {
            return auth.fetch("/api/account_management/generateWordings", {
              method: "POST",
              body: JSON.stringify({
                usecase: usecase.name,
                nbTemplates: usecase.templateDatas.length
              }),
            }).then(async (result) => {
              await reloadAccountContext();
              return result;
            });
          }
        }

        async fetchEmailExample(usecaseName, templateDataKey, tone) {
          const { auth } = this.props;

          const response = await auth.fetch(
            `/api/account_management/getEmail?templateName=${usecaseName}${templateDataKey}`,
            {
              method: "POST",
              body: JSON.stringify({
                variationSettings: {
                  htmls: {
                    languages: ["fr", "en", "es", "de", "nl", "it", "pt"],
                    numbers: ["plural"],
                    tones: [tone],
                  },
                  wordings: {
                    languages: ["fr", "en", "es", "de", "nl", "it", "pt"],
                    numbers: ["plural"],
                    tones: [tone],
                  },
                },
                structureSettings: {
                  mustAddFakeCodeSuffix: true,
                  mustAddDefaultProductImages: true,
                },
              }),
            }
          );
          return response.email;
        }

        getIsFreeAccount(account) {
          if (account.owner === "User") {
            return true;
          } else if (account.owner === "Guest") {
            return false;
          } else {
            const isFreeTrial =
              !account.billing_info.contract_start_date ||
              new Date(account.billing_info.contract_start_date) > new Date();
            const hasPremiumExpirationDate = "endDateFreemium" in account;
            const premiumExpirationDate = hasPremiumExpirationDate
              ? new Date(account.endDateFreemium)
              : null;
            const today = new Date();
            today.setHours(0, 0, 0);
            const premiumExpirationDateIsExpired =
              premiumExpirationDate <= today;
            // rare case for legacy production accounts
            if (!("billing_info" in account)) return true;
            if (!isFreeTrial) {
              return false;
            } else if (hasPremiumExpirationDate) {
              return premiumExpirationDateIsExpired;
            }
            return true;
          }
        }

        getLaunchDate(usecaseSettings) {
          return usecaseSettings && usecaseSettings.launch_date
            ? usecaseSettings.launch_date
            : getMidnightDate(getIncrementedDate(new Date(), 1));
        }

        getEndDate(launchDate, usecase, usecaseSettings) {
          if (usecaseSettings && usecaseSettings.end_date)
            return usecaseSettings.end_date;
          if (usecase.hasEnd) {
            const endDate = getIncrementedDate(launchDate, 10);
            return getMidnightDate(endDate);
          }
          return null;
        }

        getTone(account) {
          return account.template_parameters.find(
            (template_parameter) => template_parameter.name === "tone"
          ).value;
        }

        async componentDidMount() {
          await this.refetchData();
        }

        componentDidUpdate(prevProps, prevState) {
          if (prevState.isWordingsLoading &&
            !this.state.isWordingsLoading &&
            this.state.popupOpenAiLoadingOpen) {
            this.setState({
              popupOpenAiLoadingOpen: false,
              popupOpenAiLoadingError: this.state.wordingsGenerationHasError
            });
            this.openEmailEditorPopup();
          }
        }

        async refetchData(forceRegenerateWordings = false) {
          const { auth, intl, history } = this.props;
          const { account } = AccountContextBehaviorSubject$.getValue();

          try {
            // fetch data from the API
            const usecaseName = this.getUsecaseNameFromHistory(history);
            const { usecases } = await auth.fetch("/api/usecase/getAll?withMeta=true", { method: "GET" });

            const usecase = usecases.find(
              (usecase) => usecase.name === usecaseName
            );

            if (usecaseName !== "sponsorship") {
              this.setState({ isWordingsLoading: true });
              this.generateWordings(usecase, forceRegenerateWordings)
                .then(async response => {
                  const { account } = AccountContextBehaviorSubject$.getValue();
                  const usecaseSettingLists = account.usecases;
                  const usecaseSettings = usecaseSettingLists.find(
                    (usecaseSettings) => usecaseSettings.name === usecaseName
                  );
                  const generatedWordings = (
                    usecaseSettings &&
                    usecaseSettings.generatedWordings &&
                    usecaseSettings.generatedWordings.length
                  ) ? usecaseSettings.generatedWordings : [];
                  const wordings =
                    usecaseSettings && usecaseSettings.wordings.length
                      ? usecaseSettings.wordings
                      : [];
                  this.setState({
                    wordingsGenerationHasError: false,
                    isWordingsLoading: false,
                    generatedWordings,
                    isFirstWordingGeneration: !response.alreadyGenerated,
                    wordings
                  });
                })
                .catch(error => {
                  this.setState({
                    wordingsGenerationHasError: true,
                    isWordingsLoading: false,
                  });
                });
            }

            const tone = this.getTone(account);

            if (!usecase) {
              throw new Error("Le scénario sélectionné n'est pas valide");
            }

            if (account.step_register > 2) {
              this.setState({ noCms: false });
            } else {
              this.setState({ noCms: true });
            }

            const emailExamples = await this.fetchEmailExamples(usecase, tone);

            // format data
            const usecaseSettingLists = account.usecases;
            const usecaseSettings = usecaseSettingLists.find(
              (usecaseSettings) => usecaseSettings.name === usecaseName
            );

            const isFreeAccount = this.getIsFreeAccount(account);

            emailExamples.forEach((emailExample) => {
              Object.keys(emailExample.htmls).forEach((language) => {
                emailExample.htmls[language]["plural"][tone] =
                  new DOMParser().parseFromString(
                    emailExample.htmls[language]["plural"][tone],
                    "text/html"
                  ).documentElement.innerHTML;
              });
            });

            const launchDate = this.getLaunchDate(usecaseSettings);
            const endDate = this.getEndDate(
              launchDate,
              usecase,
              usecaseSettings
            );

            const isActive = usecaseSettings && !!usecaseSettings.launch_date;

            const wordings =
              usecaseSettings && usecaseSettings.wordings.length
                ? usecaseSettings.wordings
                : [];
            const generatedWordings = (usecaseSettings && usecaseSettings.generatedWordings && usecaseSettings.generatedWordings.length) ? usecaseSettings.generatedWordings : [];
            // store data in state

            this.setState((prevState) => ({
              usecaseName,
              isLegalDateChecked: !("hasEnd" in usecase),
              account,
              usecase,
              isFreeAccount,
              usecaseSettingLists,
              isActive: isActive,
              customerBlacklistDone: account.customerBlacklistUploaded,
              unisex: account.unisex,
              launchDate: launchDate,
              endDate: endDate,
              hasEnd: "hasEnd" in usecase,
              tone: this.getTone(account),
              emailExamples: emailExamples,
              mustRemoveObsoletePromoCodes: account.mustRemoveObsoletePromoCodes,
              wordings: wordings,
              generatedWordings,
              headbandImgUrls:
                usecaseSettings && usecaseSettings.headbandImgUrls
                  ? usecaseSettings.headbandImgUrls
                  : {},
              CMS: account.CMS ? account.CMS : "",
              customName: usecaseSettings ? usecaseSettings.customName : "",
              customDate: usecaseSettings ? usecaseSettings.customDate : "",
              betaFeatures: account.betaFeatures,
              headbandLink: usecaseSettings?.headbandLink,
              hasCheckedTemplateBeforeActivating: account.hasCheckedTemplateBeforeActivating || {},
              availableLanguages: [...new Set([
                ...account.email_lang_settings.map(({ email_lang}) => email_lang),
                intl.locale
              ])],
              usecaseConfig: usecaseSettings?.config,
            }));
          } catch (error) {
            console.error(error);
            EventSystem.newNotification(
              "notification.action",
              intl.messages["usecase.loading.error"]
            );
          }
        }


        saveUnisex(value) {
          const { auth } = this.props;

          this.closeGenderPopup();
          this.setState({ unisex: value }, () => {
            auth.fetch("/api/account_management/editAccount", {
              method: "POST",
              body: JSON.stringify({
                unisex: value ? "True" : "False",
              }),
            }).then(this.openActivationPopup);
          });
        }

        async savePromoCodeTemplate(
          promoCodeTemplate,
          mustRemoveObsoletePromoCodes
        ) {
          const { auth, intl } = this.props;
          const { selectedTemplateDataKey } = this.state;

          auth.sendEventToAmplitude("usecase_page.add_promo_code", { usecaseName: this.state.usecaseName, usecaseOccurrence: this.state.selectedTemplateDataKey + 1 });

          try {
            const newPromoCodeTemplate = JSON.parse(
              JSON.stringify(promoCodeTemplate)
            );

            await auth.fetch(
              `/api/usecase/editPromoCodeTemplate?templateName=${this.state.usecaseName}${selectedTemplateDataKey}`,
              {
                method: "POST",
                body: JSON.stringify({
                  promoCodeTemplate: newPromoCodeTemplate,
                }),
              }
            );

            await auth.fetch("/api/account_management/editAccount", {
              method: "POST",
              body: JSON.stringify({
                mustRemoveObsoletePromoCodes: mustRemoveObsoletePromoCodes,
              }),
            });
            await reloadAccountContext();

            if (this.state.usecaseName === "welcomeAi") {
              await this.refetchData(true);
              const notif = this.state.emailExamples[0].promoCodeTemplate ? 
                intl.messages["usecase.notif.regen.update.promocode"] :
                intl.messages["usecase.notif.regen.new.promocode"];
              this.setState({
                usecaseNotif: notif,
                shouldOpenPopupHistory: !localStorage.getItem("popupHistorySeen"),
              });
            }

            await this.reloadEmailExamples();

            this.setState({
              mustRemoveObsoletePromoCodes,
            });

            EventSystem.newNotification(
              "notification.action",
              intl.messages["usecase.promoCodeTemplateEditor.save.success"]
            );
          } catch (error) {
            console.error(error);
            EventSystem.newNotification(
              "notification.action",
              intl.messages["usecase.promoCodeTemplateEditor.save.error"]
            );
            throw new Error("Could not save promo code.");
          }
        }

        async deletePromoCodeTemplate(id) {
          const { auth, intl } = this.props;

          try {
            await auth.fetch(`/api/usecase/deletePromoCodeTemplate?id=${id}`, {
              method: "DELETE",
            });
            await reloadAccountContext();

            if (this.state.usecaseName === "welcomeAi") {
              await this.refetchData(true);
              this.setState({
                usecaseNotif: intl.messages["usecase.notif.regen.delete.promocode"],
                shouldOpenPopupHistory: !localStorage.getItem("popupHistorySeen"),
              });
            }

            await this.reloadEmailExamples();

            EventSystem.newNotification(
              "notification.action",
              intl.messages["usecase.promoCodeTemplateEditor.delete.success"]
            );
          } catch (error) {
            console.error(error);
            EventSystem.newNotification(
              "notification.action",
              intl.messages["usecase.promoCodeTemplateEditor.delete.error"]
            );
            throw new Error("Could not delete promo code.");
          }
        }

        async reloadEmailExamples() {
          const { selectedTemplateDataKey, tone, usecase, emailExamples } =
            this.state;

          let newEmailExamples;

          if (selectedTemplateDataKey) {
            const emailExample = await this.fetchEmailExample(
              usecase.name,
              selectedTemplateDataKey,
              tone
            );
            newEmailExamples = JSON.parse(JSON.stringify(emailExamples));
            newEmailExamples[selectedTemplateDataKey] = emailExample;
          } else {
            newEmailExamples = await this.fetchEmailExamples(usecase, tone);
          }

          this.setState({
            emailExamples: newEmailExamples,
          });
        }

        async activateUsecase() {

          const { intl } = this.props;
          const url = "/api/usecase/activate";

          const options = {
            method: "POST",
            body: JSON.stringify({
              usecase: this.state.usecaseName,
              launch_date: this.state.launchDate,
              active: this.state.isActive,
              day_end: this.state.endDate || undefined,
              wordings: this.state.wordings,
              generatedWordings: this.state.generatedWordings,
              headbandImgUrls: this.state.headbandImgUrls,
              headbandLink: this.state.headbandLink,
              promoCodeTemplateIds: this.state.emailExamples.map(
                (emailExample) =>
                  emailExample.promoCodeTemplate &&
                  emailExample.promoCodeTemplate._id
              ),
            }),
          };

          try {
            await this.props.auth.fetch(url, options);
            const emailExamples = await this.fetchEmailExamples(
              this.state.usecase,
              this.state.tone
            );
            await reloadAccountContext();
            this.setState({ emailExamples, popupLaunchIsOpen: false });

            if (!this.state.isActive) {
              EventSystem.publish("url_switch", "/usecase");
              EventSystem.newNotification(
                "notification.action",
                intl.messages["usecase.activate.new.success"]
              );
            } else if (this.state.hasEnd && this.state.isActive) {
              EventSystem.newNotification(
                "notification.action",
                intl.messages["usecase.activate.edit.success"]
              );
            }
          } catch (e) {
            EventSystem.newNotification(
              "notification.action",
              intl.messages["usecase.activate.error"]
            );
          }
    
        }

        deactivateUsecase() {
          const { intl } = this.props;

          const url = "/api/usecase/activate";
          const options = {
            method: "POST",
            body: JSON.stringify({
              usecase: this.state.usecaseName,
              launch_date: null,
              wordings: this.state.wordings,
              generatedWordings: this.state.generatedWordings,
              headbandImgUrls: this.state.headbandImgUrls,
              headbandLink: this.state.headbandLink,
              promoCodeTemplateIds: this.state.emailExamples.map(
                (emailExample) =>
                  emailExample.promoCodeTemplate &&
                  emailExample.promoCodeTemplate._id
              ),
            }),
          };
          return this.props.auth
            .fetch(url, options)
            .then(async (result) => {
              await reloadAccountContext();
              this.setState(() => ({
                usecaseSettingLists: [],
                popupLaunchIsOpen: false,
                isActive: false,
              }));
              EventSystem.newNotification(
                "notification.action",
                intl.messages["usecase.deactivate.success"]
              );
              EventSystem.publish("url_switch", "/usecase");
            })
            .catch((error) =>
              EventSystem.newNotification(
                "notification.action",
                intl.messages["usecase.deactivate.edit.error"]
              )
            );
        }

        getHeaderMainButtonAction() {
          const { openActivationPopup, deactivateUsecase, openFreemiumPopup } = this;
          const { isActive, hasEnd, isFreeAccount, usecaseName } = this.state;

          if (isFreeAccount) {
            return this.setState({ popupSalesContactIsOpen: true });
          } else if (isActive && !hasEnd) {
            return deactivateUsecase();
          } else {
            const freemiumV2StartDate = new Date(2022, 1, 4, 0, 0, 0);
            if (this.state.account) {
              const creationDate = new Date(this.state.account.date_creation);
              const hasPremiumExpirationDate =
                "endDateFreemium" in this.state.account;
              if (
                creationDate < freemiumV2StartDate &&
                !hasPremiumExpirationDate
              ) {
                return !isFreeAccount ||
                  freemiumUsecaseList.includes(usecaseName)
                  ? openActivationPopup()
                  : openFreemiumPopup();
              } else {
                return !isFreeAccount
                  ? openActivationPopup()
                  : openFreemiumPopup();
              }
            } else {
              return !isFreeAccount || freemiumUsecaseList.includes(usecaseName)
                ? openActivationPopup()
                : openFreemiumPopup();
            }
          }
        }

  

        get mustShowGenderPopup() {
          return this.state.unisex === null || this.state.unisex === undefined;
        }

        openActivationPopup() {
          this.props.auth.sendEventToAmplitude("usecase_page.click_continue", { usecaseName: this.state.usecaseName });

          if (!this.state.hasCheckedTemplateBeforeActivating[this.state.usecaseName]) {
            this.openCheckTemplateContentPopup();
          } else if (this.mustShowGenderPopup) {
            return this.openGenderPopup();
          } else {
            this.openLaunchPopup();
          }
        }

        async showEmailEditor(templateDataKey, tabKey) {
          this.setState(
            {
              selectedTemplateDataKey: templateDataKey,
              emailEditorTabKey: tabKey,
            },
            await this.openEmailEditorPopup
          );
        }

        showPromoCodeTemplateEditor(templateDataKey) {
          this.setState(
            {
              selectedTemplateDataKey: templateDataKey,
            },
            this.openPromoCodeTemplateEditorPopup
          );
        }

        async saveCustomization(newWordings, newGeneratedWordings, headbandLink) {
          const { auth, intl } = this.props;

          try {
            const response = await auth.fetch(
              `/api/account_management/setUsecaseSettings?usecaseName=${this.state.usecaseName}`,
              {
                method: "POST",
                body: JSON.stringify({
                  wordings: newWordings,
                  generatedWordings: newGeneratedWordings,
                  headbandLink,
                }),
              }
            );
            EventSystem.newNotification(
              "notification.action",
              intl.messages["usecase.saveCustomization.success"]
            );
            const emailExamples = await this.fetchEmailExamples(
              this.state.usecase,
              this.state.tone
            );
            this.setState({
              wordings: newWordings,
              generatedWordings: response.usecaseSettings.generatedWordings,
              headbandLink,
              emailExamples,
            });
          } catch (error) {
            console.error(error);
            EventSystem.newNotification(
              "notification.action",
              intl.messages["usecase.saveCustomization.error"]
            );
          }
        }

        setHeadbandImgFile(file, language) {
          if (file !== undefined) {
            this.setState({
              headbandImgFiles: {
                ...this.state.headbandImgFiles,
                [language]: file,
              },
            });
          } else {
            const emptyFile = new File([], "empty");
            this.setState({
              headbandImgFiles: {
                ...this.state.headbandImgFiles,
                [language]: emptyFile,
              },
            });
          }
        }

        // upload de nouveaux headbandImgs
        async uploadFiles() {
          const filesEntries = await Promise.all(
            Object.entries(this.state.headbandImgFiles).map(async ([language, file]) => {
              if(!file.size) {
                return [language, null];
              }

              const fileData = await parseFileInput(file);
              return [language, {
                filename: fileData.name,
                type: fileData.type,
                content: fileData.content
              }];
            })
          );

          const response = await this.props.auth.fetch("/api/account_management/editHeadbandImg", {
            method: "POST",
            body: JSON.stringify({
              usecaseName: this.state.usecaseName,
              files: Object.fromEntries(filesEntries)
            })
          });
          await reloadAccountContext();
          
          this.setState({ headbandImgUrls: response.data });
        }

        openLaunchPopup() {
          this.props.auth.sendEventToAmplitude("activation_popup.see_modale", { usecaseName: this.state.usecaseName });

          const isCalendaryEnvent = this.state.calendaryUsecase.includes(this.state.usecase.name);

          if (!isCalendaryEnvent) {
            this.setState({ popupLaunchIsOpen: true });
          } else {
            this.setState({ launchDate: new Date()});
            this.activateUsecase();
          }
        }
        openCheckTemplateContentPopup() { this.setState({ popupCheckTemplateContentIsOpen: true }); }
        openPromoCodeTemplateEditorPopup() {
          this.setState({ popupPromoCodeTemplateEditorIsOpen: true });
        }
        openFreemiumPopup() { this.setState({ popupFreemiumIsOpen: true }); }
        async openEmailEditorPopup() {
          this.props.auth.sendEventToAmplitude("usecase_page.click_edit_email", { usecaseName: this.state.usecaseName, usecaseOccurrence: this.state.selectedTemplateDataKey + 1 });

          if (this.state.isWordingsLoading) {
            this.setState({ popupOpenAiLoadingOpen: true, usecaseNotif: null });
          } else {
            const newHasCheckedTemplateBeforeActivating = JSON.parse(JSON.stringify(this.state.hasCheckedTemplateBeforeActivating));
            newHasCheckedTemplateBeforeActivating[this.state.usecaseName] = true;

            this.props.auth.fetch("/api/account_management/editAccount", {
              method: "POST",
              body: JSON.stringify({
                hasCheckedTemplateBeforeActivating: newHasCheckedTemplateBeforeActivating,
              })
            });
            await reloadAccountContext();
            this.setState({
              popupEmailEditorIsOpen: true,
              hasCheckedTemplateBeforeActivating: newHasCheckedTemplateBeforeActivating,
              usecaseNotif: null,
              popupHistoryIsOpen: !!this.state.shouldOpenPopupHistory,
              shouldOpenPopupHistory: false,
            });
          }
        }
        openLandingPageExamplePopup() { this.setState({ popupLandingPageExampleIsOpen: true }); }
        openGenderPopup() { this.setState({ popupGenderIsOpen: true }); }

        closeLaunchPopup() {
          this.props.auth.sendEventToAmplitude("activation_popup.leave_modale", { usecaseName: this.state.usecaseName });
          this.setState({ popupLaunchIsOpen: false });
        }
        closeCheckTemplateContentPopup() { this.setState({ popupCheckTemplateContentIsOpen: false }); }
        closePromoCodeTemplateEditorPopup() { this.setState({ popupPromoCodeTemplateEditorIsOpen: false }); }
        closeFreemiumPopup() { this.setState({ popupFreemiumIsOpen: false }); }
        closeEmailEditorPopup() { this.setState({ popupEmailEditorIsOpen: false }); }
        closeLandingPageExamplePopup() { this.setState({ popupLandingPageExampleIsOpen: false }); }
        closeSalesContactPopup() { this.setState({ popupSalesContactIsOpen: false }); }
        closeGenderPopup() { this.setState({ popupGenderIsOpen: false }); }

        render() {
          const { classes } = this.props;
          /* usecase local data = data used in frontend only, such as stats or images to show. they are not persisted in db. */
          const usecaseLocalData = usecaseLocalDatas.find(
            (usecaseLocalData) =>
              usecaseLocalData.name === this.state.usecaseName
          );
          const wordingsByLanguage = (this.state.wordings && this.state.wordings[this.state.selectedTemplateDataKey]) || null;
          const generatedWordingsByLanguage = (this.state.generatedWordings && this.state.generatedWordings[this.state.selectedTemplateDataKey]) || null;
          const promoCodeTemplate =
            this.state.emailExamples &&
            this.state.emailExamples[this.state.selectedTemplateDataKey] &&
            this.state.emailExamples[this.state.selectedTemplateDataKey]
              .promoCodeTemplate;

          return (
            <div className={classes.root}>
              {this.state.popupSalesContactIsOpen ? (
                <UsecasePopupSalesContact
                  variant="usecase"
                  closeSalesContactPopup={this.closeSalesContactPopup}
                ></UsecasePopupSalesContact>
              ) : null}
              {usecaseLocalData ? (
                <div>
                  <UsecaseHeader
                    usecase={usecaseLocalData}
                    mainButtonAction={this.getHeaderMainButtonAction}
                    isActive={this.state.isActive}
                    hasEnd={this.state.hasEnd}
                    freeAccount={this.state.isFreeAccount}
                    noCms={this.state.noCms}
                    customName={this.state.customName}
                  />
                  {usecaseLocalData.name !== "custom" ? (
                    <>
                      <UsecaseOverview
                        usecase={usecaseLocalData}
                        cardTabIndex={this.state.cardTabIndex}
                      />
                      <UsecaseExplanations
                        usecase={usecaseLocalData}
                        usecaseData={this.state.usecase}
                        emailExamples={this.state.emailExamples}
                        showEmailEditor={this.showEmailEditor}
                        showPromoCodeTemplateEditor={
                          this.showPromoCodeTemplateEditor
                        }
                        showLandingPageExample={
                          this.openLandingPageExamplePopup
                        }
                        usecaseNotif={this.state.usecaseNotif}
                        usecaseConfig={this.state.usecaseConfig}
                        betaFeatures={this.state.account.betaFeatures}
                      />
                    </>
                  ) : (
                    <UsecaseCustom
                      customName={this.state.customName}
                      customDate={this.state.customDate}
                      onUpdate={this.refetchData}
                      showEmailEditor={this.showEmailEditor}
                      emailExamples={this.state.emailExamples}
                      showPromoCodeTemplateEditor={
                        this.showPromoCodeTemplateEditor
                      }
                    />
                  )}
                  <UsecaseGetStarted
                    mainButtonAction={this.getHeaderMainButtonAction}
                    usecase={usecaseLocalData}
                    isActive={this.state.isActive}
                    hasEnd={this.state.hasEnd}
                    noCms={this.state.noCms}
                  />
                </div>
              ) : null}
              {this.state.popupLaunchIsOpen ? (
                <UsecasePopupLaunch
                  close={this.closeLaunchPopup}
                  usecase={this.state.usecaseName}
                  emailExamples={this.state.emailExamples}
                  hasEnd={this.state.hasEnd}
                  isActive={this.state.isActive}
                  deactivateUsecase={this.deactivateUsecase}
                  showPromoCodeTemplateEditor={this.showPromoCodeTemplateEditor}
                  launchDate={this.state.launchDate}
                  endDate={this.state.endDate}
                  save={(dateInterval) => {
                    this.setState(
                      {
                        launchDate: dateInterval.launchDate,
                        endDate: dateInterval.endDate,
                        isLegalDateChecked: dateInterval.isLegalDateChecked,
                      },
                      this.activateUsecase
                    );
                  }}
                  customDate={this.state.customDate}
                />
              ) : null}
              <UsecasePopupCheckTemplateContent
                isOpen={this.state.popupCheckTemplateContentIsOpen}
                isCalendaryEnvent={this.state.calendaryUsecase.includes(this.state.usecaseName)}
                openEmailEditorPopup={() => {
                  this.setState(
                    {
                      selectedTemplateDataKey: 0,
                      emailEditorTabKey: 0,
                    },
                    this.openEmailEditorPopup
                  );
                }}
                openNextPopup={this.openLaunchPopup}
                close={this.closeCheckTemplateContentPopup}
              />
              {this.state.popupPromoCodeTemplateEditorIsOpen ? (
                <PromoCodeTemplateEditor
                  isOpen={this.state.popupPromoCodeTemplateEditorIsOpen}
                  close={this.closePromoCodeTemplateEditorPopup}
                  account={this.state.account}
                  promoCodeTemplate={promoCodeTemplate}
                  save={(promoCodeTemplate, mustRemoveObsoletePromoCodes) =>
                    this.savePromoCodeTemplate(
                      promoCodeTemplate,
                      mustRemoveObsoletePromoCodes
                    )
                  }
                  delete={this.deletePromoCodeTemplate}
                  mustRemoveObsoletePromoCodes={
                    this.state.mustRemoveObsoletePromoCodes
                  }
                  usecaseName={this.state.usecaseName}
                  shouldDisplayFromDateOption={true}
                  caller={"marketing_automation"}
                />
              ) : null}
              <UsecasePopupGender
                isOpen={this.state.popupGenderIsOpen}
                close={this.closeGenderPopup}
                onSave={this.saveUnisex}
              />
              <UsecasePopupFreemium
                isOpen={this.state.popupFreemiumIsOpen}
                close={this.closeFreemiumPopup}
              />
              {this.state.popupEmailEditorIsOpen ? (
                <UsecasePopupEmailEditor
                  close={() => {
                    this.closeEmailEditorPopup();
                    this.setState({ selectedTemplateDataKey: null });
                  }}
                  emailExamples={this.state.emailExamples}
                  usecase={this.state.usecase}
                  templateDataKey={this.state.selectedTemplateDataKey}
                  tone={this.state.tone}
                  wordings={wordingsByLanguage}
                  generatedWordings={generatedWordingsByLanguage}
                  isWordingsLoading={this.state.isWordingsLoading}
                  wordingsGenerationHasError={this.state.wordingsGenerationHasError}
                  isFirstWordingGeneration={this.state.isFirstWordingGeneration}
                  onFirstGenerationEnds={() => this.setState({ isFirstWordingGeneration: false })}
                  tabKey={this.state.emailEditorTabKey}
                  saveCustomization={async (wordingsByLanguage, generatedWordingsByLanguage, headbandLink) => {
                    const newWordings = JSON.parse(JSON.stringify(this.state.wordings));
                    const newGeneratedWordings = JSON.parse(JSON.stringify(this.state.generatedWordings));
                    newWordings[this.state.selectedTemplateDataKey] = wordingsByLanguage;
                    newGeneratedWordings[this.state.selectedTemplateDataKey] = generatedWordingsByLanguage;
                    await this.saveCustomization(newWordings, newGeneratedWordings, headbandLink);
                    this.uploadFiles();
                  }}
                  headbandImgUrls={this.state.headbandImgUrls}
                  headbandLink={this.state.headbandLink}
                  setHeadbandImgFile={this.setHeadbandImgFile}
                  noCms={this.state.noCms}
                  betaFeatures={this.state.betaFeatures}
                  availableLanguages={this.state.availableLanguages}
                />
              ) : null}
              {this.state.popupLandingPageExampleIsOpen ? (
                <UsecasePopupLandingPageExample
                  close={this.closeLandingPageExamplePopup}
                />
              ) : null}
              <UsecasePopupOpenAiLoading
                close={() => this.setState({ popupOpenAiLoadingOpen: false })}
                isOpen={this.state.popupOpenAiLoadingOpen}
              />
              <UsecasePopupOpenAiError
                close={() => this.setState({ popupOpenAiLoadingError: false })}
                isOpen={this.state.popupOpenAiLoadingError}
              />
              <UsecasePopupHistory 
                close={() => this.setState({ popupHistoryIsOpen: false })}
                isOpen={this.state.popupHistoryIsOpen}
              />
            </div>
          );
        }
      }
    )
  )
);
