export function processStarsMjml(platform, rating, alignment = "left") {
  const roundedRating = Math.floor(rating);
  const fullStars = roundedRating;
  const halfStar = rating - roundedRating >= 0.5 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStar;

  const starsLinks = {
    "empty": `https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/${platform}-emptystar.png`,
    "half": `https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/${platform}-midstar.png`,
    "full": `https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/${platform}-fullstar.png`
  };

  function getTableAlignment() {
    switch(alignment) {
      case "right":
        return "<table style=\"text-align:center; float: right\">";
      case "center":
        return "<table style=\"text-align:center;margin-right:auto;margin-left:auto\">";
      default:
        return "<table style=\"text-align:center\">"; 
    }
  }

  // align right => float: right
  // align center => margin-right: auto; margin-left: auto;
  // align left => rien
  return (`
    ${getTableAlignment()}
      <tr>
      ${ Array.from({ length: fullStars }, () => "<td><image width=\"16\" src="+starsLinks["full"]+" style=\"width:16px;margin-left:2px;display:inline-block\" /></td>")}
      
      ${halfStar ? "<td><image width=\"16\" src="+starsLinks["half"]+" style=\"width:16px;margin-left:2px;display:inline-block\" /></td>" : ""}
        
      ${Array.from({ length: emptyStars }, () => "<td><image width=\"16\" src="+starsLinks["empty"]+" style=\"width:16px;margin-left:2px;display:inline-block\" /></td>")}
      </tr>
    </table>
  `).replaceAll(",", "");
}