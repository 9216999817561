import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { AuthService } from "../../AuthService";
import google from "../../../public/GOOGLE.png";

const styles = (theme) => ({
  btnGoogle: {
    display: "flex",
    gap: "15px",
    justifyContent: "center",
    borderRadius: "15px !important",
    backgroundColor: "#f3f2f0",
    color: "#838580",
    width: "100%",
    height: "40px",
    border: "none",
    "&:hover": {
      backgroundColor: "#ebebeb",
      cursor: "pointer",
    },
  },
  textGoogle: {
    alignSelf: "center",
    fontSize: "12px",
  },
  logoGoogle: {
    height: "20px",
    alignSelf: "center",
  },
});

const useStyles = makeStyles(styles);

export function GoogleButton() {
  const classes = useStyles();
  const authService = new AuthService();

  return (
    <button
      onClick={() => authService.loginWithGoogle()}
      className={classes.btnGoogle}
      style={{}}
    >
      <img className={classes.logoGoogle} src={google} alt="logo" />
      <div className={classes.textGoogle}>
        <FormattedMessage
          id="button.signin_google"
          defaultMessage="Welcome"
        />
      </div>
    </button>
  );
}