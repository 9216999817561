import React, { Component } from "react";

import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

import { InputSection } from "../../../../../common/Input/InputSection.js";
import Tooltip from "@material-ui/core/Tooltip";
import { CodeInput } from "../TemplateEditorInputs/Code/CodeInput.js";

const styles = (theme) => ({
  inputGroupContainer: {
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: 16,
    },
  },
  codeSuffix: {
    fontSize: 18,
    fontFamily: "Courier",
    letterSpacing: 2,
    color: "darkgrey",
    height: 40,
    display: "flex",
    alignItems: "center",
  },
});

export const CodeSection = withStyles(styles)(
  injectIntl(
    class CodeSection extends Component {
      render() {
        const {
          classes,
          intl,
          promoCodeTemplateWrapper,
          setPromoCodeTemplateWrapperProp,
          getError,
          partNames,
        } = this.props;

        return (
          <InputSection title={intl.messages["usecase.promoCodeTemplateEditor.code.title"]}>
            <div className={classes.inputGroupContainer} style={{ alignItems: "flex-start" }}>
              
              {partNames.find((partName) => partName === "codeInput") ? (
                <CodeInput
                  promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                  getError={getError}
                  setPromoCodeTemplateWrapperProp={
                    setPromoCodeTemplateWrapperProp
                  }
                />
              ) : null}
              
              {partNames.find((partName) => partName === "codeSuffix") &&
              !promoCodeTemplateWrapper.promoCodeTemplate.isGeneric ? (
                <Tooltip title={ intl.messages["usecase.promoCodeTemplateEditor.code.suffix.tooltip"]} placement={"top"}>
                  <p className={classes.codeSuffix}>-12AB</p>
                </Tooltip>
              ) : null}
            </div>
          </InputSection>
        );
      }
    }
  )
);
