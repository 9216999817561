
const types = ["mj-text", "mj-image"];

export const setUpReadOnlyComponents = (editor) => {
  //Read only managment
  types.forEach(key => {
    editor.Components.addType(`readonly-${key}`, {
      extend: key,
      isComponent: el => {
        if (el.tagName?.toLowerCase() === key && "data-readonly" in el.attributes) {
          return true;
        }
      },
      model: {
        defaults: {
          hoverable: false,
          selectable: false,
          editable: false,
        }
      },
      view: {
        events: {
          click: "clickOnElement"
        },
  
        clickOnElement() {
        },
      }
    });
  });
};
