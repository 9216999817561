import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { palette } from "../../../styles/palette.js";
import PropTypes from "prop-types";

const autoCompletes = {
  email: "email",
  password: "current-password",
  name: "name",
};

const styles = (theme) => ({
  textField: {
    borderRadius: 9,
    "& .MuiTextField-root .MuiInput-underline": {
      overflow: "hidden",
      "& input": {
        height: props => props.size === "small" || props.type === "copy" ? 40 : 45,
        textAlign: props => (props.type === "copy" || props.variant === "centered") ? "center" : null,
        minWidth: props => props.type === "password" ? null : "min-content",
        "&::placeholder": {
          color: props => (props.variant === "secondary" ? "white" : null),
          opacity: 1,
        },
      },
      gridTemplateColumns: props => ` ${["px"].includes(props.type) ? "60px" : "1fr"} ${props.errorMessage || ["password", "copy", "px"].includes(props.type) ? "40px" : ""}`,
      backgroundColor: props => {
        let inputBackgroundColor = palette.neutralInputs;

        if (props.style?.backgroundColor) inputBackgroundColor = props.style.backgroundColor;

        return inputBackgroundColor;
      },
      borderRadius: 9,
      fontSize: 10,
      height: props => {
        let inputHeight = "";

        if (props.style?.height) inputHeight = props.style.height;

        return inputHeight;
      },
      color: props => props.style?.color,
      "&.Mui-focused": {
        "&::before": {
          border: props => props.isAutoSized ? null :`1px solid ${palette.neutralDarkGrey} !important`,
          borderRadius: "8px",
          height: props => {
            let height = 43;

            if (props.size === "small") height = 28;
            if (props.size === "medium") height = 43;

            if (props.style?.height) height = props.style.height - 2;

            return height;
          }
        },
      },
      "&::after": {
        border: "none",
      },
    },
    "& .MuiTextField-root .MuiInput-input": {
      paddingRight: props => props.type === "dropdown" ? 40 : 0,
      fontSize: props => props.type === "dropdown" ? 14 : 11,
    },
    "& .MuiInputBase-root": {
      backgroundColor: props => props.type === "copy" ? `${palette.white} !important` : null,
    },
  },
  inputShadow: {
    visibility: "hidden",
    position: "fixed",
    fontSize: 14,
    fontFamily: "Coolvetica Book",
    padding: "0 0 0 12px",
  },
  inputPlaceholderShadow: {
    visibility: "hidden",
    position: "fixed",
    fontSize: 14,
    fontFamily: "Coolvetica Book",
    padding: "0 0 0 12px",
  },
  errorMessageShadow: {
    visibility: "hidden",
    position: "fixed",
    fontSize: 12,
    fontFamily: "Roboto",
    padding: "8px 0",
  },
  boxVariant: {
    borderRadius: 10,
    background: palette.white,
    "&:hover": {
      border: "1px solid" + palette.primaryYellow200,
    },
    border: "1px solid #e1e4e7",
    boxSizing: "border-box",
  },
  hoverColor: {
    "& .MuiInputBase-root": {
      "&:hover:not(:focus-within)": {
        backgroundColor:  props => props.variant === "secondary" ? null : palette.neutralBCGGreyHover + " !important",
      },
    },
  },
  medium: {
    "& input": {
      height: props => `${props.style?.height || 45}px !important`,
    },
  },
  small: {
    "& input": {
      height: props => `${props.style?.height || 30}px !important`,
    },
  },
  copyHover: {
    "&:hover": {
      border: "1px solid" + palette.primaryBlack,
    },
  },
  copyAdornment: {
    padding: "10px 0",
    backgroundColor: palette.primaryYellow200,
    borderTopRightRadius: "9px",
    borderBottomRightRadius: "9px",
    cursor: "pointer !important",
    "&::before": {
      fontSize: 14,
      color: `${palette.primaryBlack} !important`,
    },
  },
  pxAdornment: {
    textAlign: "right",
    display: "inline",
    paddingRight: 15,
    color: palette.neutralDarkGrey,
    fontSize: 14,
    paddingTop: 5,
    fontFamily: "Poppins",
    fontWeight: "400 !important",
  },
  error: {
    "& .MuiInput-root::before": {
      display: "block",
      border: "1px solid " + palette.errorRed + " !important",
      height: props => props.size === "small" || props.type === "copy" ? "28px" : "43px",
      borderRadius: "8px",
    },
  },
  phone: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  dropdownIcon: {
    pointerEvents: "none",
    position: "absolute",
    right: 13,
    "&.MuiInputAdornment-root:before": {
      color: palette.neutralDarkGrey,
    }
  },
});

const useStyles = makeStyles(styles);

function CustomTextField(props) {
  const [contentIsHidden, setContentIsHidden] = useState(props.type === "password");
  const [copied, setCopied] = useState(false);
  const [variant, setVariant] = useState(props.variant);

  const inputRef = useRef(null);
  const inputShadowRef = useRef(null);
  const inputPlaceholderShadowRef = useRef(null);
  const errorMessageShadowRef = useRef(null);

  const classes = useStyles({ copied, ...props });
  const intl = useIntl();

  const autoSize = useCallback(() => {
    if (props.type === "number")
      return console.warn("Prop autoSize is not supported with type \"number\".");

    const numberArrowsWidth = 20;
    const endAdornmentWidth = 40;

    const inputShadowWidth = inputShadowRef.current.offsetWidth;
    const inputPlaceholderShadowWidth = inputPlaceholderShadowRef.current.offsetWidth;

    const errorMessageShadowWidth = errorMessageShadowRef.current.offsetWidth;

    const hasValue = props.value !== undefined && props.value !== null && props.value !== "";

    const widthsToCompare = [];
    if (hasValue) widthsToCompare.push(inputShadowWidth);
    else widthsToCompare.push(inputPlaceholderShadowWidth);
    if (props.errorMessage) widthsToCompare.push(errorMessageShadowWidth - endAdornmentWidth);
    const highestWidth = widthsToCompare.reduce((a, b) => (a > b ? a : b));
    const newWidth = highestWidth + Number(props.type === "number" ? numberArrowsWidth : 0);
    inputRef.current.querySelector("input").style.width = newWidth + "px";
  }, [props.errorMessage, props.type, props.value]);

  useEffect(() => {
    if (props.isAutoSized) autoSize();

    if (props.type === "copy") setVariant("box");
  }, [props.type, props.isAutoSized, autoSize]);

  function switchContentIsHidden() {
    setContentIsHidden(!contentIsHidden);
    if (props.onEyeClick) props.onEyeClick();
  }

  function onCopy() {
    navigator.clipboard.writeText(props.value);

    props.onCopyClick();

    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  }

  const formattedPlaceholder = props.placeholder || intl.messages[`field.${props.type}`];
  const autoComplete = autoCompletes[props.type];

  function getAdornment(settings) {
    return (
      <InputAdornment
        position={settings.position}
        className={`${settings.iconName} ${settings.className}`}
        onClick={settings.onClick}
      >
        <span>{settings.text || ""}</span>
      </InputAdornment>
    );
  }

  const passwordEndAdornment = getAdornment({
    position: "end",
    iconName: `fal fa-eye${contentIsHidden ? "" : "-slash"}`,
    onClick: switchContentIsHidden
  });
  const copyEndAdornment = getAdornment({
    position: "end",
    iconName: "fa-solid fa-copy",
    className: classes.copyAdornment,
    onClick: onCopy
  });
  const errorMessageEndAdornment = getAdornment({
    position: "end",
    iconName: "fal fa-exclamation-circle",
  });
  const dropdownEndAdornment = getAdornment({
    position: "end",
    iconName: !props.dropdownIsOpen ? `fa-solid fa-chevron-down ${classes.dropdownIcon}` : `fa-solid fa-chevron-up ${classes.dropdownIcon}` ,
  });
  const pxEndAdornment = getAdornment({
    position: "end",
    className: classes.pxAdornment,
    text: "px"
  });

  const formattedInputProps = {
    endAdornment:
      (props.type === "password" && passwordEndAdornment) ||
      (props.type === "copy" && copyEndAdornment) ||
      (props.errorMessage && errorMessageEndAdornment) ||
      (props.type === "dropdown" && dropdownEndAdornment) ||
      (props.type === "px" && pxEndAdornment),
    readOnly: props.readOnly === true,
    onFocus: props.onFocus,
    onBlur: props.onBlur,
  };

  let formattedClassName = "";
  if (props.type === "phone" || props.type === "phoneOptional") {
    formattedClassName = classes.phone;
  }

  let formattedType = "text";
  if (props.type === "number" || props.type === "time")
    formattedType = props.type;

  if (props.type === "px") {
    formattedType = "number";
  }

  if (props.type === "phone" || props.type === "phoneOptional") formattedType = "tel";
  if (contentIsHidden) formattedType = "password";

  let formattedValue = props.value;
  if (props.value === null) formattedValue = "";

  let noBorderClass = props.hasBorder && variant !== "box" ? "" : " MuiTextField-root-noBorder";
  if (props.error) {
    noBorderClass = " MuiTextField-root-noBorder";
  }
  const variantClass = variant === "box" ? classes.boxVariant : "";
  const hoverClass =
    props.type !== "copy"
      ? classes.hoverColor
      : classes.copyHover;
  const errorBorderClass = props.error
    ? classes.error
    : "";
  const sizeClass = (
    (props.type === "copy" || props.size === "small") ? classes.small : classes.medium
  );

  const classNames = [
    classes.textField,
    noBorderClass,
    variantClass,
    hoverClass,
    errorBorderClass,
    sizeClass,
    props.type,
  ];

  return (
    <div
      className={classNames.join(" ")}
      style={Object.assign(JSON.parse(JSON.stringify(props.style || {})))}
      ref={props.anchor}
    >
      <TextField
        onWheel={(event) => {
          event.target.blur();
        }}
        data-cy={props.dataCy}
        ref={inputRef}
        value={formattedValue}
        disabled={props.disabled}
        error={!!props.errorMessage}
        helperText={props.errorMessage}
        id={props.id}
        InputProps={formattedInputProps}
        placeholder={formattedPlaceholder}
        type={formattedType}
        onChange={props.onChange}
        autoComplete={autoComplete}
        autoFocus={props.autoFocus}
        className={formattedClassName}
        onClick={props.onClick}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
      <div className={classes.inputShadow} ref={inputShadowRef}>
        {formattedValue}
      </div>
      <div
        className={classes.inputPlaceholderShadow}
        ref={inputPlaceholderShadowRef}
      >
        {props.placeholder}
      </div>
      <div className={classes.errorMessageShadow} ref={errorMessageShadowRef}>
        {props.errorMessage}
      </div>
    </div>
  );
};

CustomTextField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.oneOf([
    "text",
    "number",
    "password",
    "copy",
    "phone",
    "phoneOptional",
    "dropdown",
    "px",
  ]),
  dataCy: PropTypes.string,
  autoFocus: PropTypes.bool,
  hasBorder: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onCopyClick: PropTypes.func,
  variant: PropTypes.oneOf(["box", "flat"]),
  style: PropTypes.object,
  error: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
};

CustomTextField.defaultProps = {
  value: "",
  type: "text",
  hasBorder: true,
  variant: "flat",
  error: false,
  size: "medium",
};

export { CustomTextField as TextField };
