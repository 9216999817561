
import { CmsDialog } from "../../common/CmsDialog/CmsDialog";
import { WoocommerceForm } from "./WoocommerceForm.jsx";
import { useIntl } from "react-intl";
import { useAuth } from "../../../../../hooks/useAuth";
import { useEffect } from "react";
import { usePrevious } from "../../../../../hooks/usePrevious";
import underline from "../../../../../public/underline.png";

import classes from "./WoocommerceDialog.module.scss";

export const WoocommerceDialog = ({ open = false, handleClose = () => { }, onSubmit = () => { }, loading }) => {

    const auth = useAuth();
    const intl = useIntl();
    const prevOpen = usePrevious(open);

    useEffect(() => {
        if (!prevOpen && open) {
            auth.sendEventToAmplitude("woocommerce_connection.see_modale");
        } else if (prevOpen && !open) {
            auth.sendEventToAmplitude("woocommerce_connection.leave_modale");
        }
    }, [auth, prevOpen, open]);

    return (
        <CmsDialog open={open} handleClose={handleClose} customScrollBar>
            <div className={classes.woocommerceDialog}>
                <div className={classes.title}>
                    <p className={"display2"}>{intl.messages["onBoarding.chooseCms.woocommerce.title"]}</p>
                </div>
                <h2 className={classes.subtitle}>
                    {intl.messages["onBoarding.chooseCms.prestashop.subtitle"]}
                </h2>
                <img alt="" src={underline} className={classes.underlineImg} />
                <WoocommerceForm loading={loading} onSubmit={onSubmit}/>
            </div>
        </CmsDialog>
    );
};
