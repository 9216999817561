export const styles = {
  otherCmsDialog: {
    width: "75vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 0px",
    minWidth: 690,
    maxWidth: 850,
  },
  title: {
    paddingBottom: 10,
  },
  subtitle: {
    paddingBottom: 40,
  },
  content: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  formContainer: {
    paddingRight: 107,
    width: 343,
  },
  image: {
    width: 264,
  },
  explanation: {
    marginBottom: 20,
  },
  buttonContainer: {
    marginTop: 30,
  }
};
