import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../../../hooks/useAuth";

import { styles } from "./Welcome.styles";

import { TextField } from "../../../common/Input/TextField";
import { PageBody } from "../common/PageBody/PageBody";
import { PageBodyWrapper } from "../common/PageBodyWrapper/PageBodyWrapper";
import { RadioGroup } from "../../../common/RadioGroup";
import { TextFieldGroup } from "../../../common/TextFieldGroup";
import { Radio } from "../../../common/Input/Radio/Radio";
import { CustomButton } from "../../../common/Button/CustomButton";
import { Form } from "../../../common/Form/Form";

import picture from "../../../../public/welcome.png";
import { AccountContextBehaviorSubject$ } from "../../../../contexts/context";

const useStyles = makeStyles(styles);

export function Welcome(props) {
  const [firstName, setFirstName] = useState(undefined);
  const [isFirstNameAcceptable, setFirstNameAcceptable] = useState(true);
  const [lastName, setLastName] = useState(undefined);
  const [isLastNameAcceptable, setLastNameAcceptable] = useState(true);
  const [usesGoogleAuth, setUsesGoogleAuth] = useState(undefined);
  const [shopUrl, setShopUrl] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [isMoreThan5kCustomers, setisMoreThan5kCustomers] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isPhoneNumberFormatCorrect, setPhoneNumberFormatCorrect] = useState(true);
  const [errorShopUrl, setErrorShopUrl] = useState("");

  const auth = useAuth();
  const classes = useStyles();
  const intl = useIntl();
  const { account } = AccountContextBehaviorSubject$.getValue();

  useEffect(() => {
    auth.sendEventToAmplitude("welcome.see_page");
  }, [auth]);

  useEffect(() => {
    async function fetchProfile() {
      const user = await auth.getUser();
      setFirstName(user.attributes.given_name || "");
      setLastName(user.attributes.family_name || "");
      setUsesGoogleAuth(!account.url_shop);
      setShopUrl(account.url_shop || "");
      setPhoneNumber(user.attributes.phone_number || "");
    }
    fetchProfile();
  }, [auth]);

  function handleChangeShopUrl(shopUrl) {
    setErrorShopUrl("");
    setShopUrl(shopUrl);
  }

  function handleChangePhone(phone) {
    setPhoneNumberFormatCorrect(true);
    setPhoneNumber(phone);
  }

  const handleFirstName = (event) => {
    event.preventDefault();

    const specialCharRegex = /[<>&/]/;
    const match = specialCharRegex.test(event.target.value);

    setFirstNameAcceptable(!match);
    setFirstName(event.target.value);
  };

  const handleLastName = (event) => {
    event.preventDefault();

    const specialCharRegex = /[<>&/]/;
    const match = specialCharRegex.test(event.target.value);

    setLastNameAcceptable(!match);
    setLastName(event.target.value);
  };

  async function submit() {
    setIsLoading(true);

    const user = await auth.getUser();
    if (user.username.startsWith("google_")) {
      const error = await auth.checkSf({ shopUrl, isGoogleAuth: true });
      if (error) {
        setErrorShopUrl(error);
        setIsLoading(false);
        console.error(`DOMAIN_ALREADY_USED ${shopUrl}`);
        return;
      }
    }


    try {
      await auth.sendEventToAmplitude("welcome.click_continue", { isMoreThan5kCustomers: isMoreThan5kCustomers });

      if (phoneNumber) {
        try {
          await auth.updateUserAttributes({
            phone_number: phoneNumber.replace(/[^0-9+]/g, "").replace(/^0/, "+33")
          });
          setPhoneNumberFormatCorrect(true);
        } catch (error) {
          setPhoneNumberFormatCorrect(false);
          setIsLoading(false);
          return;
        }
      }

      await auth.updateUserAttributes({
        given_name: firstName,
        family_name: lastName
      });

      const url = "/api/account_management/updateAccount";
      const options = {
        method: "POST",
        body: JSON.stringify({
          shopUrl,
          usesGoogleAuth: usesGoogleAuth,
          isMoreThan5kCustomers: isMoreThan5kCustomers,
          step_register: 1
        }),
      };
      await auth.fetch(url, options);

      props.onSubmit();
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }

  return (
    <PageBodyWrapper>
      <PageBody
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "80vw",
          height: "90vh",
          minWidth: 950,
          maxWidth: 1100,
          minHeight: 670,
          maxHeight: 775,
        }}
      >
        <div className={classes.welcome}>
          <div className={classes.formContainer}>
            <Form>
              <div>
                <p className={`display1 ${classes.title}`}>
                  {intl.messages["accountSetup.stepUser.welcome"]}
                </p>
                <h3 className={classes.subtitle}>
                  {intl.messages["accountSetup.stepUser.letsTalk"]}
                </h3>
              </div>

              <TextFieldGroup>
                <TextField
                  dataCy={"firstName"}
                  placeholder={intl.messages["field.firstname"]}
                  value={firstName}
                  onChange={(event) => handleFirstName(event)}
                  iconName={"user"}
                  autoFocus
                  error={!isFirstNameAcceptable}
                />

                <TextField
                  dataCy={"lastName"}
                  placeholder={intl.messages["field.lastname"]}
                  value={lastName}
                  onChange={(event) => handleLastName(event)}
                  iconName={"user"}
                  error={!isLastNameAcceptable}
                />
                {usesGoogleAuth && (
                  <Fragment>
                    <div
                      className={`${classes.error}`}
                    >
                      {errorShopUrl && (
                        <FormattedMessage id="error.domain_used" />
                      )}
                    </div>
                    <TextField
                      dataCy={"shopUrl"}
                      value={shopUrl}
                      type="url"
                      onChange={(event) => handleChangeShopUrl(event.target.value)}
                      iconName={"user"}
                      error={errorShopUrl}
                    />
                  </Fragment>
                )}
                <TextField
                  dataCy={"phoneNumber"}
                  value={phoneNumber}
                  type="phoneOptional"
                  onChange={(event) => handleChangePhone(event.target.value)}
                  iconName={"user"}
                  error={!isPhoneNumberFormatCorrect}
                />
              </TextFieldGroup>

              <div className={classes.separator}></div>

              <RadioGroup
                value={isMoreThan5kCustomers}
                onChange={(event) =>
                  setisMoreThan5kCustomers(event.target.value === "true")
                }
                title={intl.messages["accountSetup.stepUser.howManyCustomers"]}
              >
                <Radio
                  dataCy={"lessThan5k"}
                  label={intl.messages["accountSetup.stepUser.lessThan5k"]}
                  value={false}
                />
                <Radio
                  dataCy={"moreThan5k"}
                  label={intl.messages["accountSetup.stepUser.moreThan5k"]}
                  value={true}
                />
              </RadioGroup>

              <CustomButton 
                disabled={!firstName || !lastName || !shopUrl || !isFirstNameAcceptable || !isLastNameAcceptable}
                onClick={submit}
                loading={isLoading}
              >
                {intl.messages["button.next"]}
              </CustomButton>
            </Form>
          </div>

          <img className={classes.image} alt="Happy humans" src={picture} />
        </div>
      </PageBody>
    </PageBodyWrapper>
  );
}
