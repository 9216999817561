import React, { Component } from "react";
import { injectIntl } from "react-intl";
import classes from "./UsecasePopupEmailEditorForm.module.scss";

import { HtmlCustomizer } from "../../../../common/HtmlCustomizer/HtmlCustomizer.js";
import { HtmlCustomizerWordingProposals } from "../../../../common/HtmlCustomizer/HtmlCustomizerInputs/HtmlCustomizerWordingProposals/HtmlCustomizerWordingProposals.js";
import { HtmlCustomizerSubject } from "../../../../common/HtmlCustomizer/HtmlCustomizerInputs/HtmlCustomizerSubject/HtmlCustomizerSubject.js";

import { openAiUsecaseKeys } from "../../../../../ressources/openAiUsecaseKeys";

import { usecaseAIList } from "../../../../../ressources/usecaseAIList.js";

const GENERATED_ALLOWED_LANGUAGES = ["fr", "en", "es"];

export const UsecasePopupEmailEditorForm = 
  injectIntl(
    class UsecasePopupEmailEditorForm extends Component {
      constructor(props) {
        super(props);
        this.getCustomWording = this.getCustomWording.bind(this);
        this.getWordingCustomizationSettings =
          this.getWordingCustomizationSettings.bind(this);
        this.getKeywords = this.getKeywords.bind(this);
      }

      getGeneratedWording(language, tone, key) {
        const {
          generatedWordings,
        } = this.props;

        const wording = (
          generatedWordings &&
          generatedWordings[language] &&
          generatedWordings[language][tone] &&
          generatedWordings[language][tone][key]
        );

        return wording || null;
      }

      getCustomWording(language, number, tone, key) {
        const { wordings } = this.props;

        const wording =
          wordings &&
          wordings[language] &&
          wordings[language][number] &&
          wordings[language][number][tone] &&
          wordings[language][number][tone][key];

        return wording !== undefined ? wording : null;
      }

      getWordingCustomizationSettings(key) {
        const { intl, language, number, tone, changeWording, changeGeneratedWording } = this.props;

        const generatedWording = this.getGeneratedWording(language, tone, key);

        return {
          value: generatedWording?.wording || this.getCustomWording(language, number, tone, key),
          isGenerated: !!generatedWording,
          promptVersion: generatedWording?.promptVersion,
          setValue: (value) =>
            changeWording(value, language, number, tone, key),
          setGenerated: value => changeGeneratedWording(value, language, tone, key),
          placeholder: intl.messages[`emailEditor.${key}.placeholder`],
          generatedWordingCreatedDate: generatedWording ? new Date(generatedWording.created) : null,
          generatedWordingHistory: generatedWording ? generatedWording.history : null,
          key
        };
      }

      getHeadbandImgCustomizationSettings() {
        const {
          changeHeadbandImgUrls,
          setHeadbandImgFile,
          language,
          headbandLink,
          changeHeadbandLink
        } = this.props;

        return {
          value: this.props.headbandImgUrls
            ? this.props.headbandImgUrls[language]
            : null,
          link: headbandLink,
          setValue: (value) => changeHeadbandImgUrls(value, language),
          setHeadbandImgFile: (value) => setHeadbandImgFile(value, language),
          setHeadbandLink: changeHeadbandLink,
        };
      }

      getKeywords() {
        const { intl, usecaseName, templateDataKey } = this.props;

        const templateName = usecaseName + templateDataKey;

        const keywords = [];

        keywords.push(intl.messages["email.keywords.firstname"]);
        keywords.push(intl.messages["email.keywords.lastname"]);
        if (templateName === "sponsorship1") {
          keywords.push(intl.messages["email.keywords.sponsorFirstname"]);
          keywords.push(intl.messages["email.keywords.sponsorLastname"]);
        } else if (templateName === "sponsorship2") {
          keywords.push(intl.messages["email.keywords.sponsorFirstname"]);
          keywords.push(intl.messages["email.keywords.sponsorLastname"]);
        } else if (templateName === "sponsorship3") {
          keywords.push(intl.messages["email.keywords.godsonFirstname"]);
          keywords.push(intl.messages["email.keywords.godsonLastname"]);
        }
        keywords.push(intl.messages["email.keywords.shopName"]);
        keywords.push(intl.messages["email.keywords.year"]);
        keywords.push(intl.messages["email.keywords.promoValue"]);
        keywords.push(intl.messages["email.keywords.promoCode"]);
        keywords.push(intl.messages["email.keywords.promoDuration"]);
        keywords.push(intl.messages["email.keywords.promoMinimumCartPrice"]);
        keywords.push(intl.messages["email.keywords.promoAvailableQuantity"]);

        return keywords;
      }

      render() {
        const {
          template,
          subject,
          language,
          number,
          tone,
          wordings,
          changeWording,
          changeGeneratedWording,
          templateName,
          usecaseName,
          isWordingsLoading,
          wordingsGenerationHasError,
          isFirstWordingGeneration,
          onFirstGenerationEnds,
        } = this.props;

        if (!wordings) return null;

        const generatedObject = this.getGeneratedWording(language, tone, "subject");

        let customSubject = generatedObject?.wording || this.getCustomWording(
          language,
          number,
          tone,
          "subject"
        );
        const isSubjectGenerated = !!generatedObject;
        if (customSubject === null || customSubject === "") {
          customSubject = subject;
        }

        return (
          <div className={classes.formContainer}>
            {
              GENERATED_ALLOWED_LANGUAGES.includes(language) && 
              !["sponsorship"].includes(usecaseName) ?
                <HtmlCustomizerWordingProposals
                  usecaseName={templateName}
                  value={customSubject}
                  setGenerated={value =>
                    changeGeneratedWording(
                      value,
                      language,
                      tone,
                      "subject"
                    )
                  }
                  setValue={value =>
                    changeWording(
                      value,
                      language,
                      number,
                      tone,
                      "subject"
                    )
                  }
                  isGenerated={isSubjectGenerated}
                  originalPromptVersion={generatedObject?.promptVersion}
                  isWordingsLoading={isWordingsLoading}
                  partKey="subject"
                  style={{
                    position: "static",
                    padding: 0,
                    paddingBottom: 10,
                  }}
                  hasError={wordingsGenerationHasError}
                  language={language}
                /> :
                <HtmlCustomizerSubject
                  value={customSubject}
                  onChange={value =>
                    changeWording(
                      value,
                      language,
                      number,
                      tone,
                      "subject"
                    )
                  }
                />
            }
            {
              <HtmlCustomizer
                templateName={templateName}
                usecaseName={usecaseName}
                htmlString={template}
                setHeadbandImgFile={this.props.setHeadbandImgFile}
                keywords={this.getKeywords()}
                isWordingsLoading={isWordingsLoading}
                wordingsGenerationHasError={wordingsGenerationHasError}
                isFirstWordingGeneration={isFirstWordingGeneration}
                onFirstGenerationEnds={onFirstGenerationEnds}
                language={language}
                customizableParts={[
                  {
                    selector: "#header",
                    customizableProperties: {
                      [GENERATED_ALLOWED_LANGUAGES.includes(language) &&
                        !["sponsorship"].includes(usecaseName) ?
                        "generatedWordings" : "wording"]: this.getWordingCustomizationSettings("header"),
                    },
                  },
                  {
                    selector: "#headbandImg",
                    customizableProperties: {
                      image: this.getHeadbandImgCustomizationSettings(),
                    },
                  },
                  {
                    selector: "#title",
                    customizableProperties: {
                      [GENERATED_ALLOWED_LANGUAGES.includes(language) &&
                        usecaseName !== "sponsorship" ?
                        "generatedWordings" : "wording"]: this.getWordingCustomizationSettings("title"),
                    },
                  },
                  {
                    selector: "#customTemplateTitle",
                    customizableProperties: {
                      wording: this.getWordingCustomizationSettings(
                        "customTemplateTitle"
                      ),
                    },
                  },
                  {
                    selector: "#customTemplateDescription",
                    customizableProperties: {
                      wording: this.getWordingCustomizationSettings(
                        "customTemplateDescription"
                      ),
                    },
                  },
                  {
                    selector: "#subtitle1",
                    customizableProperties: {
                      wording:
                        this.getWordingCustomizationSettings("subtitle1"),
                    },
                  },
                  {
                    selector: "#subtitle2",
                    customizableProperties: {
                      [GENERATED_ALLOWED_LANGUAGES.includes(language) &&
                        usecaseName !== "sponsorship" &&
                        openAiUsecaseKeys.subtitle2.includes(usecaseName) ?
                        "generatedWordings" : "wording"]: this.getWordingCustomizationSettings("subtitle2"),
                    },
                  },
                  {
                    selector: "#description",
                    customizableProperties: {
                      [usecaseAIList.includes(usecaseName) 
                        && GENERATED_ALLOWED_LANGUAGES.includes(language) ? 
                        "aiGeneratedWordings" : "wording"]: this.getWordingCustomizationSettings("description"),
                    },
                  },
                  {
                    selector: "#ps",
                    customizableProperties: {
                      wording: this.getWordingCustomizationSettings("ps"),
                    },
                  },
                ]}
              />
            }
          </div>
        );
      }
    }
  );
