import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { CustomButton } from "../Button/CustomButton";
import { Translation } from "../Translation";
import { withAuth } from "../../withAuth";
import { ExceedingPricePopup } from "./exceedingPricePopup.js";
import { UsecasePopupSalesContact } from "../../usecase/Usecase/UsecasePopups/UsecasePopupSalesContact.js";
import { AccountContextBehaviorSubject$ } from "../../../contexts/context";

const styles = (theme) => ({
  bannerHome: {
    height:"100px",
    backgroundColor:"#ffe1a7",
    borderRadius:"10px",
    position: "relative",
    display:"flex",
    flexDirection: "row",
    fontFamily:"Poppins",
    marginBottom:"10px",
    justifyContent: "space-between",
  },
  bannerSubscription: {
    height:"100px",
    backgroundColor:"white",
    borderRadius:"10px",
    position: "relative",
    display:"flex",
    flexDirection: "row",
    fontFamily:"Poppins",
    margin:"30px 0px",
    justifyContent: "space-between",
  },
  wording: {
    position: "relative",
    alignSelf:"center",
    marginLeft:"30px",
    fontSize:"15px",
  },
  button: {
    position: "relative",
    alignSelf:"center",
    minWidth: "350px",
    display: "flex",
    justifyContent: "center",
  },
  close_button: {
		zIndex: "1",
		position: "absolute",
		right: "10px",
		top: "5px",
		height: "auto",
		width: "auto",
		cursor: "pointer"
	},

});

export const ExceedingPrice = withAuth(withStyles(styles)(
  class ExceedingPrice extends Component {
    constructor(props) {
      super(props);
      this.handleClose = this.handleClose.bind(this);
      this.handleClosePopup = this.handleClosePopup.bind(this);
      this.redirectToSubscription = this.redirectToSubscription.bind(this);
      this.openContactModal = this.openContactModal.bind(this);
      this.closeContactModal = this.closeContactModal.bind(this);

      this.state = {
        open: false,
        exceedingPriceBannerIsOpen:false,
        isBannerOpen:true,
        exceedingPricePopupIsOpen:false,
        exceedingStatus: null,
        wording:null,
        subWording:null,
        limitDate:null,
        paymentPlatform:null,
        autoUpgrade:false,
        showModalSalesContact: false,
        isBanner:null,
        isSubscriptionUpdated:false,
      };
    }

    async getAmountOfContact() {
      return await this.props.auth.fetch("/api/data/getAccountAmountOfContacts", { method: "GET" });
    } 

    async getSfSubscription(account) {
      const subscription = await this.props.auth.fetch("/api/public/getSfSubscription", {
        method: "POST",
        body: JSON.stringify({ id: account._id }),
      });
      this.setState({ paymentPlatform: subscription.Plateforme_de_paiement , autoUpgrade:subscription.Auto_upgrade  });
    } 

    async updateSFexceedingStatus(user,account,amountOfContact){
      return await this.props.auth.fetch("/api/public/updateSFexceedingStatus",
        { 
          method: "POST",  
          body: JSON.stringify({ 
            email: user.attributes.email,
            isExceeding :  account.billing_info.active_clients_limit <= amountOfContact.actifs
          }) 
        });
    }

    async fetchRenewDate(account) {
      const renewDate = await this.props.auth.fetch("/api/account_management/getSfAccount", {
        method: "GET"
      });
      const formattedDate = renewDate.Date_du_renouvellement__c ? new Date(renewDate.Date_du_renouvellement__c).toLocaleDateString('fr-FR') :null ;
      return formattedDate;
    }

    async checkExceedingStatus(account,amountOfContact,user) {

      await this.updateSFexceedingStatus(user,account,amountOfContact);
      if ( account.billing_info.active_clients_limit >= amountOfContact.actifs || !this.state.autoUpgrade || this.state.paymentPlatform === "Stripe"  ) return;

      // SET BANNER 1 -> no overage , too much contact for the package 
      this.setState({ wording:"exceedingPriceBanner.wording1", exceedingStatus:1 });

      const today = new Date();
      const FakeRenewDate = await this.fetchRenewDate(account); // Format : DD-MM-YYYY
      const parts = FakeRenewDate.split('/');
      const renewDate = new Date(parts[2], parts[1] - 1, parts[0]); // Formatting YYYY-MM-DD
      let limitDate = new Date(renewDate) ;
      limitDate = new Date(limitDate.setDate(limitDate.getDate() + 7));
      if(FakeRenewDate){
          const exceedingDays = (today.getTime() - new Date(renewDate).getTime()) / (1000 * 3600 * 24);
          this.setState({ limitDate: FakeRenewDate});

          // SET BANNER 2 -> Less than 7 days overage
          if ( today > renewDate && exceedingDays < 7 ){
            this.setState({ 
              wording:"exceedingPriceBanner.wording2" , 
              subWording:"exceedingPriceBanner.wording3",
              exceedingStatus:2,
              limitDate: limitDate.toLocaleDateString("fr-FR")
            });
          } 
          // SET BANNER 3 -> More than 7 days overage
          else if ( today > renewDate && exceedingDays > 7 ) {
            this.setState({ 
              wording:"exceedingPriceBanner.wording4", 
              exceedingStatus:3,
              exceedingPricePopupIsOpen:this.state.isSubscriptionUpdated ? false : true,
            });
          }
          
          // SHOW BANNER 
          if( ((this.state.isBanner != this.state.exceedingStatus)  || this.state.isBannerOpen != "false") && !this.state.isSubscriptionUpdated){
            this.setState({ exceedingPriceBannerIsOpen:true , isBanner:null });
            localStorage.setItem('isBanner', null);
            localStorage.setItem('isBannerOpen', true);
          } 
      }

      // SHOW POPUP UPDATE SUBSCRIPTION 
      if(this.state.isSubscriptionUpdated){
        this.setState({ 
          exceedingStatus:2 ,
          exceedingPricePopupIsOpen: true,
        });
      }
    } 

    async componentDidMount(){
      const { account, config } = AccountContextBehaviorSubject$.getValue();
      if(account._id === "64a53859f32e6d1f7693e632"){
        const user = this.props.auth.getUser();

        // CHECK IF CUSTOMER UPGRADE SUBSCRIPTION
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get("subscriptionUpdated") && urlParams.get("subscriptionUpdated") !== "" ) {
          this.setState({ isSubscriptionUpdated : true });
        }

        // GET PAYMENT METHOD IN SF SUBSCRIPTION
        await this.getSfSubscription(account);

        // CHECK IF BANNER NOT MANUALY CLOSED
        this.setState({ isBannerOpen: localStorage.getItem('isBannerOpen') ?? true , isBanner: localStorage.getItem('isBanner') ?? null });

        if (config.exceedingPriceBanner) {
          await this.checkExceedingStatus(account, await this.getAmountOfContact(), user);
        }
      }
    }

    handleClose() {
      localStorage.setItem('isBannerOpen', false);
      localStorage.setItem('isBanner', this.state.exceedingStatus);
      this.setState({ exceedingPriceBannerIsOpen: false, isBanner: this.state.exceedingStatus ?? null  });
    }
    handleClosePopup() {
      this.setState({ exceedingPricePopupIsOpen: false });
    }
    openContactModal() {
      this.setState({ exceedingPricePopupIsOpen: false , showModalSalesContact: true });
    }
    closeContactModal() {
      this.setState({ showModalSalesContact: false });
    }


    redirectToSubscription() {
      if(this.state.paymentPlatform){
        if(this.state.paymentPlatform === "Shopify store") {
          window.open("https://admin.shopify.com/", '_blank');
        } else {
          this.openContactModal();
        }
      }
    }


    render() {
      const { classes,from } =
        this.props;

      return (
          <div>
            {this.state.exceedingPriceBannerIsOpen ? 
              <div className={ from === "Home" ? classes.bannerHome : classes.bannerSubscription } >

                { 
                  this.state.exceedingStatus === 1 ? 
                  <div onClick={this.handleClose} className={classes.close_button}>
                    <i className="fal fa-times" style={{ fontSize: "15px" }}></i>
                  </div> : null 
                }

                <span className={classes.wording} >
                  <Translation id={ this.state.wording }/> 
                  { this.state.exceedingStatus != 3 ? this.state.limitDate  :null }
                  { this.state.exceedingStatus === 2 ? <Translation id={ this.state.subWording }/>  :null }
                </span>

                {
                  this.state.exceedingStatus != 1 ?
                    <span className={classes.button} >
                      <CustomButton size="md" onClick={() => this.redirectToSubscription()} style={{ fontFamily:"Poppins" ,minWidth: "230px" }}  type={ from === "Home" ? "secondary" : "primary"}>
                        <Translation id={ this.state.paymentPlatform === "Shopify store" ? "exceedingPriceBanner.buttonWording" : "usecase.popupContact.btnContactRdv" }  />
                      </CustomButton>            
                    </span>
                  : null
                }

                {
                  this.state.showModalSalesContact ? (
                    <UsecasePopupSalesContact
                      variant="subscription"
                      closeSalesContactPopup={this.closeContactModal}
                    ></UsecasePopupSalesContact>
                  ) : null
                }
                
              </div>
            :null}

            {this.state.exceedingPricePopupIsOpen && from === "Home" ?
              <ExceedingPricePopup 
                paymentPlatform={ this.state.paymentPlatform } 
                closeExceedingPopup={ this.handleClosePopup } 
                openContactModal={ this.openContactModal } 
                isUpdate={ this.state.isSubscriptionUpdated }
                exceedingStatus={ this.state.exceedingStatus }
              />
            :null }
          </div>              

      );
    }
  }
));