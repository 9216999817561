import { useContext, useEffect, useMemo, useState } from "react";
import { Translation } from "../../common/Translation";
import { cloneDeep } from "lodash";

import { TemplateEditorContext } from "../TemplateEditorContext";
import { CustomButton } from "../../common/Button/CustomButton";
import Typewriter from "typewriter-effect";
import { useIntl } from "react-intl";
import { palette } from "../../../styles/palette";

import classes from "./GeneratedTextMenu.module.scss";

export const cleanString = string => string?.replaceAll(/<[^>]*>/g, " ").replaceAll(/&nbsp;/g, " ").replaceAll(/&amp;/g, "&");

const checkTextsToShow = (block) => {
  const newTextsToShow = [];
  ["title", "subtitle", "text"].forEach(value => {
    if (block.getContent().includes(`data-text-id="${value}"`)) {
      newTextsToShow.push(value);
    }
  });

  return newTextsToShow;
};

const checkIsSelectedWordings = (textsToShow, wordings, selectedWordings, language) => {
  let result = true;

  const clean = string => cleanString(string)?.replaceAll(/\/n/g, "").trim();

  textsToShow.forEach(value => {
    if (!selectedWordings || !selectedWordings[value] || clean(wordings[value][language]) !== clean(selectedWordings[value][language])) {
      result = false;
    }
  });
  return result;
};

export const GeneratedTextProposal = ({ language, selectedWordings, onChange, wordings, block }) => {

  const [textsToShow, setTextsToShow] = useState([]);
  const [buttonText, setButtonText] = useState(null);

  useEffect(() => {
    if (block) {
      setTextsToShow(checkTextsToShow(block));
    }
  }, [block, wordings]);  

  useEffect(() => {
    if (block && block.getContent().includes("<mj-button")) {
      setButtonText(wordings?.buttonText || block.attributes.attributes.wordings.button);
    }
  }, [block, wordings]);

  return (
    <div 
      className={`${classes.proposal} ${onChange ? classes.clickableProposal : ""} ${checkIsSelectedWordings(textsToShow, wordings, selectedWordings, language) ? classes.selectedProposal : ""}`} 
      onClick={onChange ? () => onChange(wordings /* textsToShow.reduce((acc, value) => ({ ...acc, [value]: wordings[value] }), {}) */) : null}
    >
      {textsToShow.includes("title") && !!wordings.title && <h4>{cleanString(wordings.title[language])}</h4>}
      {textsToShow.includes("subtitle") && !!wordings.subtitle && <div className="text">{cleanString(wordings.subtitle[language])}</div>}
      {textsToShow.includes("text") && !!wordings.text && <div className="text">{cleanString(wordings.text[language])}</div>}
      {buttonText && <button className={classes.button}>{buttonText[language]}</button>}
    </div>
  );
};

export const GeneratedTextMenu = ({ language, block, onChange, selectedWordings, addSelectedFirst = false, goBack, isEdit = false }) => {

  const { wordingProposals, isWordingProposalsLoading, loadMoreProposals, getProposalsIdxFromProducts, isFirstLoading, setIsFirstLoading } = useContext(TemplateEditorContext);  
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [firstWording, setFirstWording] = useState(null);
  const intl = useIntl();


  if(isFirstLoading){
    setTimeout(() => {
      setIsFirstLoading(false);
    }, 6000); 
  }
  
  useEffect(() => {
    const idx = getProposalsIdxFromProducts(selectedWordings.productIds || []);
    if (idx < 0) {
      loadMoreProposals(selectedWordings.productIds);
    }
  }, [selectedWordings, getProposalsIdxFromProducts, loadMoreProposals]);

  const proposals = useMemo(() => {
    const idx = getProposalsIdxFromProducts(selectedWordings.productIds || []);

    if (idx < 0) {
      return [];
    }

    const _proposals = [ ...wordingProposals[idx].proposals ];

    if (!_proposals || !addSelectedFirst) {
      return _proposals || [];
    }

    let first = firstWording;
    if (!first) {
      first = { ...selectedWordings };
      setFirstWording(first);
    }

    const textsToShow = checkTextsToShow(block);

    const cleanedProposals = _proposals.filter(wordings => !checkIsSelectedWordings(textsToShow, wordings, first, language));

    cleanedProposals.unshift(first);

    const result = cleanedProposals.filter(wordings => !checkIsSelectedWordings(textsToShow, wordings, selectedWordings, language));

    return [      
      ...result.slice(0, selectedIdx),
      selectedWordings,
      ...result.slice(selectedIdx)
    ];
  }, [wordingProposals, addSelectedFirst, block, selectedWordings, language, selectedIdx, getProposalsIdxFromProducts, firstWording]);

  const loadingStrings = [
    intl.messages["htmlCustomizerWordingProposal.loading1"],
    intl.messages["htmlCustomizerWordingProposal.loading2"],
    intl.messages["htmlCustomizerWordingProposal.loading3"],
  ];

  const removeSpans = (string) => {
    if (string.trim().startsWith("<span")) {
      const tmpElem = document.createElement("div");
      tmpElem.innerHTML = string.trim();
      let spanElem = tmpElem.firstChild;
      let text = spanElem.innerHTML;
      while (spanElem.children === 1) {
        text = spanElem.innerHTML;
        spanElem = tmpElem.firstChild;
      }
      return text;
    }
    return string;
  };
  
  const applyColors = (wordings) => {
  
    if (Object.keys(selectedWordings).length !== 0 && isEdit) {
      ["title", "subtitle", "text"].forEach(key => {
        if (selectedWordings[key] && selectedWordings[key][language].trim().startsWith("<span")) {
          const tmpElem = document.createElement("div");
          tmpElem.innerHTML = selectedWordings[key][language].trim();
          let spanElem = tmpElem.firstChild;
          let color = spanElem.style.color;
          while (spanElem.children.length === 1) {
            spanElem = spanElem.firstChild;
            if (spanElem.style.color) {
              color = spanElem.style.color;
            }
          }
          if (color) {
            wordings[key][language] = `<span style="color:${color}">${removeSpans(wordings[key][language])}</span>`;
          }
          
        }
      });
    }    
    return wordings;
  };

  return (
    <div className={classes.container}>
      {!!goBack &&
        <div
          className={classes.closeString}
          onClick={goBack}
          data-cy={"close-string"}
        >
          <i className="fa-solid fa-angle-left"></i>
          <h4><Translation id="dialog.back" /></h4>
        </div>
      }
      <div className={classes.separator} />
      <div className="textMedium"><Translation id="newsletter.generatedTextMenu.title" /></div>
      <div className={classes.proposalsContainer}>
        { isFirstLoading ?   
          <div style={{ marginTop:"10%", fontSize:"14px", color:palette.neutralDarkGrey }}>
            <Typewriter
              options={{
                strings: loadingStrings,
                autoStart: true,
                loop: true,
                delay: 20,
              }}
            />
          </div>
          :
          proposals?.map((proposal, idx) => (
            <GeneratedTextProposal 
              key={idx}
              wordings={proposal}
              language={language}
              selectedWordings={selectedWordings}
              onChange={wordings => {
                setSelectedIdx(idx);
                onChange(applyColors(cloneDeep(wordings))); 
              }}
              block={block}
            />
          ))   
        }

        {
          !!proposals?.length && !isFirstLoading &&
            <div className={classes.buttonContainer}>
              <CustomButton
                size="sm"
                type="secondary"
                loading={isWordingProposalsLoading}
                onClick={() =>loadMoreProposals(selectedWordings.productIds)}
              >
                <Translation id="button.regenerateText" />
              </CustomButton>
            </div>
        }

      </div>
    </div>
  );
};