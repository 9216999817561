import { extractWordingsFromMjTextComponent, makeTranslatable, extractVariable } from "../utils";
import { TRANSLATABLE_COMPONENT_TYPES, COMPONENT_TYPES_WITH_TRANSLATABLE_ATTRIBUTES } from "../../../TranslationManager";
import { defaultWordings } from "../texts";

import { columns } from "./columns";
import { column } from "./column";
import { columnText } from "./columnText";
import { columnImage } from "./columnImage";
import { columnLogo } from "./columnLogo";
import { columnSeparator } from "./columnSeperator";
import { columnButton } from "./columnButton";

export const getColumnBlocks = ({ dominantColor, fontFamily, textColor, shopUrl, imageUrl, logoUrl, buttonTextColor, accentColor, isDominantColorLight }) => {

  return [
    columns({ backgroundColor: dominantColor }, 2),
    columns({ backgroundColor: dominantColor }, 3),
    columns({ backgroundColor: dominantColor }, 4),

    columnText({ fontFamily, textColor, textValue: defaultWordings.text, backgroundColor: dominantColor }),
    columnImage({ imageUrl: makeTranslatable(imageUrl), alt: makeTranslatable(""), href: makeTranslatable(shopUrl) }),
    columnLogo({ logoUrl: makeTranslatable(logoUrl), alt: makeTranslatable(""), href: makeTranslatable(shopUrl) }),
    columnButton({ fontFamily, buttonTextColor, buttonColor: accentColor, href: makeTranslatable(shopUrl) }),
    
    columnSeparator({ backgroundColor: dominantColor, isDominantColorLight }),
    columnSeparator({ backgroundColor: dominantColor, isDominantColorLight }, 0.75),

    column({ backgroundColor: dominantColor }),
  ];
};

const getAllWordingsAndVariables = (component, wordings, language, variables) => {
  const _wordings = {};
  const _variables = {};

  component.onAll(_component => {
    if (TRANSLATABLE_COMPONENT_TYPES.includes(_component.attributes.type)) {
      const props = _component.get("script-props");
      const wordingId = props[0].wordingId;
      _wordings[wordingId] = extractWordingsFromMjTextComponent(_component, wordings, language);
    }
    if (COMPONENT_TYPES_WITH_TRANSLATABLE_ATTRIBUTES.includes(_component.attributes.type)) {
      const props = _component.get("script-props");
      const translatableAttributes = props[0].translatableAttributes;
      translatableAttributes?.forEach(attribute => {
        _variables[attribute.attributeId] = extractVariable(attribute, _component.attributes.attributes[attribute.key], variables, language);
      });
    }
  });

  return { wordings: _wordings, variables: _variables };
};

export const extractContentFromColumnsComponent = (component, wordings, language, variables) => {

  const content = {};

  const columnComponents = component.findType("kilibaColumn");

  content.columnContents = columnComponents.map(e => e.getInnerHTML());
  content.columnSizes = columnComponents.map(e => parseInt(e.attributes.attributes.width));
  content.columnsBackgroundColor = columnComponents.map(e => e.attributes.attributes["background-color"]);
  
  const result = getAllWordingsAndVariables(component, wordings, language, variables);
  content.columnsWordings = result.wordings;
  content.columnsVariables = result.variables;

  content.backgroundColor = component.attributes.attributes["background-color"];
  return content;
};

export const extractContentFromColumnComponent = (component, wordings, language, variables) => {

  const content = {};

  content.content = component.getInnerHTML();
  content.size = parseInt(component.attributes.attributes.width);
  content.paddingTop = parseInt(component.attributes.attributes["padding-top"]);
  content.paddingBottom = parseInt(component.attributes.attributes["padding-bottom"]);
  content.paddingLeft = parseInt(component.attributes.attributes["padding-left"]);
  content.paddingRight = parseInt(component.attributes.attributes["padding-right"]);
  content.backgroundColor = component.attributes.attributes["background-color"];

  const result = getAllWordingsAndVariables(component, wordings, language, variables);
  content.columnWordings = result.wordings;
  content.columnVariables = result.variables;

  return content;
};

export const extractContentFromColumnSubComponent = (component, wordings, language, variables) => {

  const content = {};

  if (component.attributes.attributes["data-blocklabel"] === "columnText") {
    content.textValue = extractWordingsFromMjTextComponent(component, wordings, language);
  }

  if (component.attributes.attributes["data-blocklabel"] === "columnImage" || component.attributes.attributes["data-blocklabel"] === "columnLogo") {
    const props = component.get("script-props");
    const translatableAttributes = props[0].translatableAttributes;

    const srcAttribute = translatableAttributes?.find(attr => attr.key === "src");
    const hrefAttribute = translatableAttributes?.find(attr => attr.key === "href");
    const altAttribute = translatableAttributes?.find(attr => attr.key === "alt");

    content[component.attributes.attributes["data-blocklabel"] === "columnImage" ? "imageUrl" : "logoUrl"] = extractVariable(srcAttribute, component.attributes.attributes.src, variables, language);
    content.href = extractVariable(hrefAttribute, component.attributes.attributes.href, variables, language);
    content.alt = extractVariable(altAttribute, component.attributes.attributes.alt || "", variables, language);
    content.width = parseInt(component.attributes.attributes.width);
  }

  if (component.attributes.attributes["data-blocklabel"] === "columnButton") {
    const buttonProps = component.get("script-props");
    const buttonTranslatableAttributes = buttonProps[0].translatableAttributes;  
    const buttonHrefAttribute = buttonTranslatableAttributes?.find(attr => attr.key === "href");
    
    content.href = extractVariable(buttonHrefAttribute, component.attributes.attributes.href, variables, language);
    
    content.buttonColor = component.attributes.attributes["background-color"];
    content.buttonText = extractWordingsFromMjTextComponent(component, wordings, language);
  }

  content.backgroundColor = component.attributes.attributes["container-background-color"];

  return content;
};