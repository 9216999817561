import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { EventSystem } from "../../eventsystem/EventSystem.js";
import { injectIntl } from "react-intl";
import moment from "moment-timezone";

import { palette } from "../../styles/palette.js";
import { styles } from "../../styles/usecase.js";

export const UsecaseCard = withStyles(styles)(
  injectIntl(
    class UsecaseCard extends Component {
      constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.getUsecaseHasLaunched = this.getUsecaseHasLaunched.bind(this);
      }

      handleClick() {
        EventSystem.publish("usecase_details", this.props.usecaseName);
      }

      getUsecaseHasLaunched(launchDateString) {
        if (!launchDateString) return false;

        const launchDate = new Date(launchDateString);
        const today = new Date().setHours(0, 0, 0, 0);

        return launchDate <= today;
      }

      getCardContentIsComingSoon() {
        const { classes, isComingSoon, intl } = this.props;

        return isComingSoon ? (
          <div className={classes.isComingSoon}>
            <div className={classes.isComingSoon_text}>
              {intl.messages["usecase.comingSoon"]}
            </div>
          </div>
        ) : null;
      }

      getCardContentIcon() {
        const { isComingSoon, icon } = this.props;

        const iconStyle = {
          color: palette.black,
          fontSize: "55px",
          marginTop: isComingSoon ? "0px" : "35px",
        };

        return (
          <Grid item xs>
            <div>
              <i className={icon} style={{ ...iconStyle }}></i>
            </div>
          </Grid>
        );
      }

      getCardContentTitle() {
        const { classes, isComingSoon, title } = this.props;

        return (
          <Grid item xs>
            <div
              className={classes.title}
              style={{
                color: isComingSoon ? palette.lightgray : palette.black,
              }}
            >
              {title}
            </div>
          </Grid>
        );
      }

      getCardContentDescription() {
        const { classes, description } = this.props;

        return (
          <Grid item xs>
            <div className={classes.description}>{description}</div>
          </Grid>
        );
      }

      getStatusString() {
        const { intl, launchDate } = this.props;

        const localLaunchDate = moment(new Date(launchDate))
          .tz("Europe/Paris")
          .format("DD/MM");
        let launchStatus;

        if (!this.props.active)
          launchStatus = intl.messages["usecaseCard.inactive"];
        else if (this.getUsecaseHasLaunched(launchDate))
          launchStatus = intl.messages["usecaseCard.active"];
        else launchStatus = localLaunchDate;

        return launchStatus;
      }

      getStatus() {
        const { classes, active, isComingSoon, launchDate } = this.props;

        const statusClass = 
          !active ? classes.statusInactif : 
            this.getUsecaseHasLaunched(launchDate) ? classes.statusActif :
              classes.statusActifDate;

        return isComingSoon ? null : (
          <div className={classes.statusContainer}>
            <span className={`${classes.status} ${statusClass}`}>
              {this.getStatusString()}
            </span>
          </div>
        );
      };

      render() {
        const { classes, isComingSoon, usecaseName, hideStatus } =
          this.props;

        return (
          <Card
            onClick={!isComingSoon && this.handleClick}
            className={classes.card}
            id={`usecaseCard-${usecaseName}`}
          >
            <CardActionArea disabled={isComingSoon}>
              <CardMedia>
                <div className={classes.card}>
                  <Grid
                    style={{ whiteSpace: "pre-line" }}
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    spacing={0}
                  >
                    {!hideStatus && this.getStatus()}
                    {this.getCardContentIsComingSoon()}
                    {this.getCardContentIcon()}
                    {this.getCardContentTitle()}
                    {this.getCardContentDescription()}
                  </Grid>
                </div>
              </CardMedia>
            </CardActionArea>
          </Card>
        );
      }
    }
  )
);
