import { palette } from "../../../../styles/palette";

export const styles = {
  blockTitle: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: 10,
  },

  ovalArrow: {
    position: "absolute",
    transform: "scaleX(-1) rotate(33deg)",
    width: 105,
    top: 20,
    right: -370,
  },

  cardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: 30,
    gridRowGap: 30,
    margin: "0 170px",
  },

  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: 330,
    height: 180,
    borderRadius: 30,
    backgroundColor: palette.neutralBCGGrey,
  },

  cardTitle: {
    margin: "20px 0 0 30px",
    lineHeight: "34px",
  },
  highlight: {
    padding: "0 13px 6px 4px",
  },
  highlightCard1: {
    backgroundColor: palette.accentRed200,
  },
  highlightCard2: {
    backgroundColor: palette.accentPurple200,
  },
  highlightCard3: {
    backgroundColor: palette.primaryYellow200,
  },
  highlightCard4: {
    backgroundColor: palette.accentGreen200,
  },

  imageCard: {
    alignSelf: "flex-end",
    justifySelf: "flex-end",
    margin: "20px 12px",
  },
  imageCard1: {
    maxWidth: 108,
  },
  imageCard2: {
    maxWidth: 93,
  },
  imageCard3: {
    maxWidth: 117,
  },
  imageCard4: {
    maxWidth: 115,
  },

  "@media (max-width: 1350px)": {
    ovalArrow: {
      display: "none",
    },
  },
};
