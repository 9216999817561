import { useEffect, useRef, useState, useContext, useCallback } from "react";
import { createRoot } from "react-dom/client";
import grapesjs from "grapesjs";
import GjsEditor, { Canvas } from "@grapesjs/react";
import grapesJSMJML from "grapesjs-mjml";
import { useIntl } from "react-intl";
import { useParams, useHistory, useLocation, Prompt } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { cloneDeep } from "lodash";

import { TemplateEditorHeader } from "./TemplateEditorHeader";
import { TemplateEditorToolbar } from "./TemplateEditorToolbar";
import { SaveManager } from "./SaveManager";
import { BlockActions } from "./BlockActions";
import { TranslationManager, onLanguageChange, getUpdatedWordings, shouldTranslate, removeUselessWordings } from "./TranslationManager";
import { AddLinkPopup } from "./popups/AddLinkPopup";
import { CropImagePopup } from "./popups/CropImagePopup";
import { RteToolbar } from "./RteToolbar";
import { TemplateEditorSubject } from "./TemplateEditorSubject";
import { TemplateEditorPreheader, PREHEADER_HEIGHT, PREHEADER_HEIGHT_EMPTY } from "./TemplateEditorPreheader";
import { useAuth } from "../../hooks/useAuth";
import { useConfetti } from "./hooks/useConfetti";
import { AccountContext } from "../../contexts/context.js";
import * as grapeJsKiliba from "./grapesJsKiliba/grapesJsKiliba";
import { LoadingPopup } from "../Newsletter/LoadingPopup";
import { RightMenu } from "./rightMenu/RightMenu";
import { forceReadOnly } from "./grapesJsKiliba/components";
import { CheckNewsletterPopup } from "./popups/CheckNewsletterPopup.jsx";
import { TranslateWordingPopup } from "../usecase/Usecase/UsecasePopups/UsecasePopupEmailEditor/TranslateWordingPopup.js";
import { useUpdatePrice } from "./hooks/useUpdatePrice.js";
import { useKilibaColumn } from "./hooks/useKilibaColumn.js";
import { ColumnActions } from "./ColumnAction.jsx";
import { EventSystem } from "../../eventsystem/EventSystem";
import { Translation } from "../common/Translation.js";

import { TemplateEditorContext, TemplateEditorContextProvider } from "./TemplateEditorContext";

import "./GjsEditor.css";
import classes from "./TemplateEditor.module.scss";

const TemplateEditor = ({ menuIsExpanded, templateId }) => {

  const canvasContainerRef = useRef();
  const { account } = useContext(AccountContext);
  const auth = useAuth();
  const intl = useIntl();
  const { search } = useLocation();
  const history = useHistory();

  const [editor, setEditor] = useState(null);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [bodyElement, setBodyElement] = useState(null);
  const [height, setHeight] = useState(null);
  const [wordings, setWordings] = useState(null);
  const [template, setTemplate] = useState(null);
  const [saving, setSaving] = useState(false);
  const [isBlockActionsOpened, setIsBlockActionsOpened] = useState(false);
  const [isColumnActionOpened, setIsColumnActionOpened] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [stylesToAdd, setStylesToAdd] = useState([]);
  const [isPluginInitialized, setIsPluginInitialized] = useState(false);
  const showConfetti = useConfetti();
  const [isPopupLoading, setIsPopupLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [translateInProgress, setTranslateInProgress] = useState(false);
  const [prevWordings, setPrevWordings] = useState();
  const [initialWordings, setInitialWordings] = useState();
  const [popupWordingInfo, setPopupWordingInfo] = useState(null);
  const [checkNLInfo, setCheckNLInfo] = useState(null);
  const [objective, setObjective] = useState(null);

  const { 
    language, 
    setLanguage, 
    setVariables, 
    variables, 
    setCategoryToSelect,
    editorCssVariables,
    templateInfo,
    setTemplateInfo,
    products,
  } = useContext(TemplateEditorContext);
  const [isPreheaderHidden, setIsPreheaderHidden] = useState(false);

  useUpdatePrice(editor, isReadOnly);
  useKilibaColumn(editor, blocks);

  //Init languages
  useEffect(() => {
    if (!availableLanguages?.length) {
      const _availableLanguages = [...new Set([
        ...account.email_lang_settings.map(({ email_lang }) => email_lang),
      ])];
      setAvailableLanguages(_availableLanguages);
      if (!language) {
        const defaultLanguage = _availableLanguages.includes(intl.locale) ? intl.locale : _availableLanguages[0];
        setLanguage(defaultLanguage);
      }
    }
  }, [intl, account, language, availableLanguages, setLanguage]);

  //Prevent page scrolling and hide zendesk
  useEffect(() => {
    window.zE("messenger", "hide");
    window.scrollTo(0, 0);
  }, []);

  //Refresh editor on resize
  useEffect(() => {
    if (editor && canvasContainerRef.current) {
      const observer = new ResizeObserver(() => {
        editor.refresh();
      });
      observer.observe(canvasContainerRef.current, { subtree: true, attributes: true, attributeOldValue: true, attributeFilter: ["style"], childList: true });
      return () => observer.disconnect();
    }
  }, [editor]);

  //Auto resize editor height
  useEffect(() => {
    if (bodyElement) {
      const observer = new ResizeObserver(() => {
        setHeight(bodyElement.getBoundingClientRect().height);
      });
      observer.observe(bodyElement, { subtree: true, attributes: true, attributeOldValue: true, attributeFilter: ["style"], childList: true });
      return () => observer.disconnect();
    }
  }, [bodyElement]);

  const onEditor = _editor => {
    console.log("Editor loaded", { _editor });
    setEditor(_editor);

    //text editor: custom on paste to prevent paste html
    const onPaste = event => {
      const clipboardData = event.clipboardData || window.clipboardData;
      const text = clipboardData.getData("text");

      const html = text.replace(/(?:\r\n|\r|\n)/g, "<br/>");
      event.currentTarget.execCommand("insertHTML", false, html);

      event.preventDefault();
      event.stopPropagation();
    };

    _editor.on("rte:enable", (view, rte) => {
      rte?.doc?.addEventListener("paste", onPaste, { capture: true });
    });
    _editor.on("rte:disable", (view, rte) => {
      rte?.doc?.removeEventListener("paste", onPaste, { capture: true });
    });

    _editor.on("component:mount", component => {
      if (component.is("mj-body")) {
        setBodyElement(component.getEl());
      }
    });

    _editor.on("block:custom", props => {
      setBlocks([...props.blocks]);
    });

    //setup RTE toolbar
    _editor.on("rte:custom", props => {
      if (!props.container.classList.contains("rte-custom-toolbar")) {
        props.container.innerHTML = "";
        const root = createRoot(props.container);
        root.render(
          <RteToolbar editor={_editor} intl={intl} />
        );
        props.container.classList.add("rte-custom-toolbar");
      }
    });
  };

  // Styles managment
  const applyStyles = useCallback(() => {
    let headComponent;
    const styleComponents = [];

    editor.getComponents().at(0).onAll(component => {
      if (component.is("mj-head")) {
        headComponent = component;
      }
      if (component.is("mj-style")) {
        styleComponents.push(component);
      }
    });

    //remove duplicates and add style if not already in mjml
    stylesToAdd.filter((elem, idx) => {
      return stylesToAdd.findIndex(({ id }) => id === elem.id) === idx;
    }).forEach(style => {
      const styleComponent = styleComponents.find(component => component.attributes?.attributes["data-gjs"] === style.id);
      styleComponent?.remove();
      headComponent.append(style.style);
    });

    setStylesToAdd([]);
  }, [editor, stylesToAdd]);

  //Apply styles to mjml templates
  useEffect(() => {
    if (editor && editor.getComponents().length && stylesToAdd.length) {
      applyStyles();
    }

  }, [editor, stylesToAdd, applyStyles]);

  //Set template into editor
  useEffect(() => {
    if (template && wordings && editor && !editor.getComponents().length && products) {
      editor.setComponents(template);
      applyStyles();
      editor.UndoManager.clear();
      editor?.Canvas.getFrameEl()?.setAttribute("scrolling", "no");
      
      const queryParameters = new URLSearchParams(search);
      const open = queryParameters.get("open");

      if (open === "product") {
        setTimeout(() => {
          setIsMenuOpened(true);
          setCategoryToSelect("product");
        }, 300);
      }
    }
  }, [template, wordings, editor, applyStyles, blocks, search, setCategoryToSelect, products]);

  // Load template
  useEffect(() => {

    if (editor && !template) {
      const queryParameters = new URLSearchParams(search);
      const from = queryParameters.get("from");
      const objective = queryParameters.get("objective");      

      if (from === "newsletter") {
        setObjective(objective);
        setIsPopupLoading(true);
      }

      const loadTemplate = async () => {
        await auth.fetch(`/api/account_management/generateNewsletterSubject?templateId=${templateId}`, { method: "POST" });

        const { template, wordings, name, isFirstGenerationDone, generatedWordings, objective, dateSent, variables, ...rest } = await auth.fetch(`/api/account_management/mjmlTemplate?templateId=${templateId}`);
        const _wordings = {
          subject: generatedWordings?.subject,
          ...(wordings ? wordings : {}),
        };
        setWordings(cloneDeep(_wordings));
        setVariables(cloneDeep(variables));
        if (isFirstGenerationDone) {
          setPrevWordings(cloneDeep(_wordings));
          setInitialWordings(cloneDeep(_wordings));
        }
        setTemplate(template);
        setTemplateInfo({ name, isFirstGenerationDone, objective, dateSent, generatedWordings, ...rest });
        const readOnly = !!dateSent && Date.now() > new Date(dateSent).getTime();
        if (readOnly) {
          forceReadOnly(editor);
        }
        setIsReadOnly(readOnly);
      };

      loadTemplate();
    }

  }, [editor, auth, templateId, search, template, setVariables, setTemplateInfo]);

  const getModifiedWordings = useCallback((updatedWordings, _language) => {
    const modifiedWordings = [];

    const clean = string => {
      return (string || "").replaceAll("\n", "").replaceAll("&nbsp;", " ").replace(/\t|\xA0/g, " ").trim();
    };

    for (const [prevWordingId, prevWording] of Object.entries(prevWordings)) {

      if (clean(prevWording[_language]) !== clean(updatedWordings[prevWordingId][_language])) {
        modifiedWordings.push(prevWordingId);
      }
    }

    return modifiedWordings; 
  }, [prevWordings]);

  //On language change
  const updateLanguage = useCallback(async (currentLanguage, updatedWordings, wordingToForceTranslate = [], callback = null, newLanguage) => {

    const updateLanguageEnd = async (_wordings) => {

      const finalWordings = cloneDeep(_wordings);

      if (wordingToForceTranslate.length) {
        setTranslateInProgress(true);
        const { translations } = await auth.fetch("/api/account_management/translateWordings", {
          method: "POST",
          body: JSON.stringify({
            language: currentLanguage,
            wordings: Object.entries(updatedWordings)
              .filter(([key]) => wordingToForceTranslate.includes(key))
              .reduce((acc, [key, wording]) => ({ ...acc, [key]: wording[currentLanguage] }), {}),
          })
        });
        Object.entries(translations).forEach(([key, translation]) => {
          finalWordings[key] = {
            ...finalWordings[key],
            ...translation,
          };
        });

        setInitialWordings(cloneDeep(finalWordings));
      }

      if (callback) {
        await auth.fetch("/api/account_management/saveMjmlTemplate", {
          method: "POST",
          body: JSON.stringify({
            templateId: templateId,
            wordings: removeUselessWordings(editor.getHtml(), cloneDeep(finalWordings)),
          }),
        });
      }

      setLanguage(newLanguage);
      onLanguageChange(editor, newLanguage, finalWordings, variables);
      setWordings(cloneDeep(finalWordings));
      setPrevWordings(cloneDeep(finalWordings));
      setTranslateInProgress(false);
      editor.UndoManager.clear();
      editor.UndoManager.start();
      callback && callback();
    };

    editor.selectRemove(editor.getSelectedAll());

    if (shouldTranslate(removeUselessWordings(editor.getHtml(), cloneDeep(updatedWordings)), availableLanguages)) {
      setTranslateInProgress(true);
      auth.fetch("/account_management/translateNewsletterMissingWordings", {
        method: "POST",
        body: JSON.stringify({
          templateId,
          sourceLang: currentLanguage
        }),
      }).then(result => {
        setWordings(result);
        updateLanguageEnd(result);
      });
    } else {
      updateLanguageEnd(wordings);
    }
  }, [auth, templateId, availableLanguages, editor, wordings, setLanguage, variables]);

  const changeLanguage = (newLanguage) => {
    if (editor) {
      editor.UndoManager.stop();

      const updatedWordings = getUpdatedWordings(editor, wordings, language);
      setWordings(updatedWordings);

      const modifiedWordings = getModifiedWordings(updatedWordings, language);

      if (language === intl.locale && modifiedWordings.length && availableLanguages.length > 1) {
        setPopupWordingInfo({ updatedWordings, modifiedWordings, currentLanguage: language, newLanguage });
      } else {
        updateLanguage(language, updatedWordings, [], null, newLanguage);
      }
    }
  };

  const isSecondaryVersionModified = () => {

    if (!popupWordingInfo) {
      return false;
    }

    const clean = string => string.replaceAll("\n", "").replaceAll("&nbsp;", " ").replace(/\t|\xA0/g, " ").trim();

    try {
      for (const [key, initialWording] of Object.entries(initialWordings)) {
        if (wordings[key]) {
          for (const [_language, wordingByLang] of Object.entries(initialWording)) {
            if (_language !== popupWordingInfo.currentLanguage && wordings[key][_language]) {
              if (clean(wordingByLang) !== clean(wordings[key][_language])) {
                return true;
              }
            }
          }
        }
      }
    } catch {
      return true;
    }

    return false;
  };

  const unselectAll = () => {
    editor.selectRemove(editor.getSelectedAll());
  };

  //open menu on element selected
  useEffect(() => {
    if (isBlockActionsOpened || isColumnActionOpened) {
      setIsMenuOpened(true);
    }
  }, [isBlockActionsOpened, isColumnActionOpened]);

  /* ======= Fix border bottom on selected component ======= */

  const onResize = useCallback(() => {
    try {
      if (Math.abs(editor.getWrapper().getView().el.getBoundingClientRect().height - height) < 1) {
        editor.getWrapper().getView().el.classList.add("wrapperHeightFull");
      } else {
        editor.getWrapper().getView().el.classList.remove("wrapperHeightFull");
      }
    } catch { }
  }, [editor, height]);

  useEffect(() => {
    if (editor && canvasContainerRef.current) {
      const observer = new ResizeObserver(() => {
        onResize();
      });
      observer.observe(canvasContainerRef.current, { subtree: true, attributes: true, attributeOldValue: true, attributeFilter: ["style"], childList: true });
      return () => observer.disconnect();
    }
  }, [editor, onResize]);

  /* ===================== */

  const onInitialized = () => {
    console.log("initialized");
    setIsPluginInitialized(true);
  };

  useEffect(() => {
    if (editor && blocks?.length && isPluginInitialized && "isFirstGenerationDone" in templateInfo && !templateInfo.isFirstGenerationDone) {
      const block = editor.Blocks.getAll().find(block => block.getLabel() === `${templateInfo.objective}Preset`);

      if (block) {
        editor.getComponents().at(0).onAll(component => {
          if (component.is("mj-body")) {
            const parsed = editor.getComponents().parseString(block.getContent());
            const parsedArray = Array.isArray(parsed) ? parsed : [parsed];

            parsedArray.forEach(toAdd => {
              component.append({
                ...toAdd,
                "script-props": [{
                  "fromBlock": block.getLabel(),
                }],
              });
            });
          }
        });

        editor.UndoManager.clear();

        setTemplateInfo(prev => ({ ...prev, isFirstGenerationDone: true }));
      }
    }
  }, [editor, isPluginInitialized, templateInfo, blocks, setTemplateInfo]);

  useEffect(() => {
    if (!prevWordings && !!wordings && templateInfo.isFirstGenerationDone) {
      setPrevWordings(cloneDeep(wordings));
      setInitialWordings(cloneDeep(wordings));
    }
  }, [prevWordings, wordings, templateInfo]);

  useEffect(() => {
    if (isLoaded) {
      showConfetti();
      setIsPopupLoading(false);
      history.replace(`/editor/${templateId}`);
    }
  }, [showConfetti, isLoaded, templateId, history]);

  if (!account || !language) {
    return;
  }

  const showLoader = (!isPluginInitialized || translateInProgress || !products);

  return (
    <>
      <div
        className={classes.container}
        style={editorCssVariables}
      >
        <GjsEditor
          plugins={[
            editor => grapesJSMJML(editor, {
              useCustomTheme: false,
              columnsPadding: "0px",
              blocks: [],
            }),
            editor => grapeJsKiliba.plugin(editor, {
              auth,
              account,
              templateId,
              onInitialized,
              addStyle: (style) => setStylesToAdd(prev => [...prev, style]),
              language,
              intlMessages: intl.messages,
            })
          ]}
          grapesjs={grapesjs}
          onEditor={onEditor}
          options={{
            canvas: {
              styles: ["/css/fontawesome.all.min.css"]
            },
            storageManager: false,
            blockManager: {
              custom: true,
            },
            undoManager: {
              trackSelection: false,
            },
            richTextEditor: {
              custom: true,
            },
            modal: {
              custom: true,
            },
            canvasCss: `
            .gjs-hovered[data-gjs*="kilibaBlock"], .gjs-hovered[data-gjs*="kilibaColumn"], .gjs-hovered[data-gjs-type^="column-component"] {
              cursor: pointer;
              outline: 2px solid #000000 !important;
              outline-offset: -2px
            }

            [data-gjs-type="mj-text"] div {
              min-height: 15px;
              min-width: 80px;
            }

            [data-gjs*="kilibaBlock"]:not([data-gjs-type="preview-mj-wrapper"]) [data-gjs-type="mj-text"] div:hover, 
            [data-gjs*="kilibaBlock"]:not([data-gjs-type="preview-mj-wrapper"]) [data-gjs-type="mj-button"] a:hover,
            [data-gjs*="kilibaBlock"]:not([data-gjs-type="preview-mj-wrapper"]) [data-gjs-type="mj-button"] p:hover {
              cursor: pointer;
              outline: 1px solid #0C0C0E;
            }

            .gjs-selected[data-gjs*="kilibaBlock"], .gjs-selected[data-gjs*="kilibaColumn"],  .gjs-selected[data-gjs-type^="column-component"] {
              outline: 2px solid #000000 !important;
              border-radius: 0px;
            }

            .wrapperHeightFull [data-gjs*="kilibaBlock"]:last-child {
              border-radius: 0px 0px 20px 20px;
            }

            .wrapperHeightFull [data-gjs*="kilibaBlock"]:last-child > div > table {
              border-radius: 0px 0px 18px 18px;
              overflow: hidden;
            }

            [contenteditable]:focus {
              outline: 1px solid #0C0C0E;
            }
            [data-gjs-type="mj-body"] [data-preview] {
              border-top: 2px dashed #000;
              border-bottom: 2px dashed #000;
            }

            [data-preview] ~ [data-preview] {
              border-top: none;
            }

            [data-preview]:has(~ [data-preview]) {
              border-bottom: none;
            }

            [data-preview] {
              border-radius: 5px;
              border-right: 2px dashed #000;
              border-left: 2px dashed #000;
            }

            .dataPreviewColumnHover {
              border-radius: 5px;
              outline: 1.5px solid #0C0C0E;
              outline-offset: -2px;
            }
          `
          }}
        >
          <SaveManager
            editor={editor}
            wordings={wordings}
            setSaving={setSaving}
            templateId={templateId}
          />
          <TranslationManager
            editor={editor}
            blocks={blocks}
            wordings={wordings}
            setWordings={setWordings}
            setPrevWordings={setPrevWordings}
          />
          <TemplateEditorHeader            
            language={language}
            setLanguage={setLanguage}
            saving={saving}
            templateName={templateInfo.name}
            templateId={templateId}
            templateInfoLoading={!Object.keys(templateInfo).length}
            showNextButton={!templateInfo.dateSent || Date.now() < new Date(templateInfo.dateSent).getTime()}
            availableLanguages={availableLanguages}
            setAvailableLanguages={setAvailableLanguages}
            onNext={() => {
              const updatedWordings = getUpdatedWordings(editor, wordings, language);
              const modifiedWordings = getModifiedWordings(updatedWordings, language);

              if (language === intl.locale && modifiedWordings.length && availableLanguages.length > 1) {
                setPopupWordingInfo({ updatedWordings, modifiedWordings, currentLanguage: language, callback: () => history.push(`/newsletter?templateId=${templateId}&step=date`), newLanguage: language });
              } else {

                if (availableLanguages.length > 1) {
                  setCheckNLInfo({ updatedWordings });
                } else {
                  updateLanguage(language, updatedWordings, [], () => history.push(`/newsletter?templateId=${templateId}&step=date`), language);
                }
              }
            }}
            translateInProgress={translateInProgress}
            changeLanguage={changeLanguage}
          />

          <div className={classes.body}>
            <div
              className={classes.editorPart}
              onClick={event => {
                if (
                  !event.target.closest(".gjs-rte-actionbar") &&
                  !event.target.closest(".sketch-picker") &&
                  !event.target.closest("div[class^=\"TemplateEditorSubject\"]") &&
                  !event.target.closest("div[class^=\"TemplateEditorPreheader\"]")
                ) {
                  setIsMenuOpened(!isMenuOpened);
                  unselectAll();
                }
              }}
              onScroll={() => editor.refresh()}
            >
              <div ref={canvasContainerRef}
                className={classes.canvasContainer}
                style={{
                  "--editor-height": `${height}px`, 
                  "--preheader-height": `${!isPreheaderHidden ? PREHEADER_HEIGHT_EMPTY : PREHEADER_HEIGHT}px`,                  
                }}
              >
                <TemplateEditorToolbar
                  editor={editor}
                  menuIsExpanded={menuIsExpanded}
                  wordings={wordings}
                  language={language}
                  isBlockActionsOpened={isBlockActionsOpened}
                  canvasContainerRef={canvasContainerRef}
                  variables={variables}
                />
                <BlockActions
                  editor={editor}
                  setIsBlockActionsOpened={setIsBlockActionsOpened}
                  canvasContainerRef={canvasContainerRef}
                  wordings={wordings}
                  setWordings={setWordings}
                  language={language}
                  isReadOnly={isReadOnly}
                  preheaderHeight={isPreheaderHidden ? PREHEADER_HEIGHT : PREHEADER_HEIGHT_EMPTY}
                />
                <ColumnActions
                  editor={editor}
                  canvasContainerRef={canvasContainerRef}
                  wordings={wordings}
                  language={language}
                  setIsColumnActionOpened={setIsColumnActionOpened}
                  preheaderHeight={isPreheaderHidden ? PREHEADER_HEIGHT : PREHEADER_HEIGHT_EMPTY}
                  setWordings={setWordings}
                  isReadOnly={isReadOnly}
                />
                <div style={{ visibility: !showLoader ? "visible" : "hidden" }}>
                  <TemplateEditorSubject
                    isReadOnly={isReadOnly}
                    subject={wordings?.subject ? wordings.subject[language] || "" : ""}
                    editor={editor}
                    setSubject={(value) => setWordings(prevWordings => {
                      const clone = { ...prevWordings };
                      if (!clone.subject) {
                        clone.subject = {};
                      }
                      clone.subject[language] = value;
                      return clone;
                    })}
                  />
                  <TemplateEditorPreheader 
                    editor={editor}
                    isPreheaderHidden={isPreheaderHidden}
                    setIsPreheaderHidden={setIsPreheaderHidden}
                    wordings={wordings}
                    language={language}
                    setWordings={setWordings}
                  />
                  <Canvas scrolling="no" />
                </div>
              </div>
              {showLoader && <div className={classes.spinnerContainer}><CircularProgress size={60} /></div>}
            </div>

            {!isReadOnly &&
              <RightMenu
                isOpened={isMenuOpened}
                setIsOpened={setIsMenuOpened}
                blocks={blocks}
                editor={editor}
                canvasContainerRef={canvasContainerRef}
                wordings={wordings}
                language={language}
                templateId={templateId}
                generatedWordings={templateInfo?.generatedWordings}
              />
            }
          </div>
        </GjsEditor>
        <AddLinkPopup editor={editor} />
        <CropImagePopup editor={editor} />

        {isPopupLoading &&
          <LoadingPopup
            isOpen={true}
            isLoaded={() => setIsLoaded(true)}
            closePopup={() => setIsPopupLoading(false)}
            subject="createNewsletter"
            objective={objective}
          />
        }
      </div>
      <TranslateWordingPopup 
        isOpen={!!popupWordingInfo}
        showSubtitle={!!popupWordingInfo && isSecondaryVersionModified()}
        close={() => {
          setPopupWordingInfo(null);
          editor.UndoManager.start();
        }}
        onDontTranslate={() => {
          setPopupWordingInfo(null);
          updateLanguage(popupWordingInfo.currentLanguage, popupWordingInfo.updatedWordings, [], popupWordingInfo.callback, popupWordingInfo.newLanguage);
        }}
        onTranslate={() => {
          setPopupWordingInfo(null);
          updateLanguage(popupWordingInfo.currentLanguage, popupWordingInfo.updatedWordings, popupWordingInfo.modifiedWordings, popupWordingInfo.callback, popupWordingInfo.newLanguage);
        }}
      />
      <CheckNewsletterPopup 
        isOpen={!!checkNLInfo}
        close={() => setCheckNLInfo(null)}
        onNext={() => updateLanguage(language, checkNLInfo.updatedWordings, [], () => history.push(`/newsletter?templateId=${templateId}&step=date`), language)}
      />
      <Prompt when={true} message={(event) => {
        if (!templateInfo.dateSent && !event.pathname.startsWith("/editor/") && (event.pathname !== "/newsletter" || !event.search.includes("step=date"))) {
          EventSystem.newNotification(
            "notification.action",
            <Translation id="templateEditor.saveLater" variables={{ name: templateInfo.name }} />
          );
        }
        return true;
      }} />
    </>
  );
};

const TemplateEditorWithContext = ({ menuIsExpanded }) => {

  const { templateId } = useParams();

  return (
    <TemplateEditorContextProvider templateId={templateId}>
      <TemplateEditor menuIsExpanded={menuIsExpanded} templateId={templateId} />
    </TemplateEditorContextProvider>
  );
};

export { TemplateEditorWithContext as TemplateEditor };