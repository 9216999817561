import { extractVariable, extractWordingsFromMjTextComponent, makeTranslatable } from "../utils";
import { logo1 } from "./logo1";
import { logo2 } from "./logo2";
import { logo3 } from "./logo3";
import { logo4 } from "./logo4";
import { logo5 } from "./logo5";

const previewText = {
  fr: "Ton titre ici",
  en: "Your title here",
  es: "Su título aquí",
  de: "Dein Titel hier",
  nl: "Uw titel hier",
  it: "Il vostro titolo qui",
  pt: "O seu título aqui",
};

export const getLogoBlocks = ({ logoUrl, shopUrl, headerColor, fontFamily, textColor }) => {  
  
  return [
    logo1({ logoUrl: makeTranslatable(logoUrl), href: makeTranslatable(shopUrl), alt: makeTranslatable(""), backgroundColor: headerColor }),
    logo2({ logoUrl: makeTranslatable(logoUrl), href: makeTranslatable(shopUrl), alt: makeTranslatable(""), backgroundColor: headerColor }),
    logo3({ logoUrl: makeTranslatable(logoUrl), href: makeTranslatable(shopUrl), alt: makeTranslatable(""), backgroundColor: headerColor }),
    logo4({ logoUrl: makeTranslatable(logoUrl), href: makeTranslatable(shopUrl), alt: makeTranslatable(""), backgroundColor: headerColor, fontFamily, textColor, text: previewText }),
    logo5({ logoUrl: makeTranslatable(logoUrl), href: makeTranslatable(shopUrl), alt: makeTranslatable(""), backgroundColor: headerColor, fontFamily, textColor, text: previewText }),
  ];
};

export const extractContentFromLogoComponent = (component, wordings, language, variables) => {

  const content = {};
  const imageComponents = component.findType("mj-image");

  const props = imageComponents[0].get("script-props");
  const translatableAttributes = props[0].translatableAttributes;

  content.width = parseInt(imageComponents[0].attributes.attributes.width);

  const srcAttribute = translatableAttributes?.find(attr => attr.key === "src");
  const hrefAttribute = translatableAttributes?.find(attr => attr.key === "href");
  const altAttribute = translatableAttributes?.find(attr => attr.key === "alt");

  content.logoUrl = extractVariable(srcAttribute, imageComponents[0].attributes.attributes.src, variables, language);
  content.href = extractVariable(hrefAttribute, imageComponents[0].attributes.attributes.href, variables, language);
  content.alt = extractVariable(altAttribute, imageComponents[0].attributes.attributes.alt || "", variables, language);

  content.backgroundColor = component.attributes.attributes["background-color"];

  const textComponents = component.findType("mj-text");
  if (textComponents.length) {
    content.text = extractWordingsFromMjTextComponent(textComponents[0], wordings, language);
  } else {
    content.text = previewText;
  }

  return content;
};