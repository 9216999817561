const LABEL = "lineSeparator";

export const lineSeparator = ({ dominantColor, isDominantColorLight }) => ({
  label: LABEL,
  block: () => {

    const mjml = `
      <mj-section data-gjs="kilibaBlock" background-color="${dominantColor}" css-class="lineSeparator" full-width="full-width" padding-top="0" padding-bottom="0">
        <mj-column width="2%"></mj-column>
        <mj-column width="96%">
         <mj-divider width="100%" border-width="0.75px" border-color="${isDominantColorLight ? "#BEBAB3" : "#F6F4F0"}" />
        </mj-column>
        <mj-column width="2%"></mj-column>
        
      </mj-section>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: "separator:subSeparator",
      activate: true,
      content: mjml,
    };
  },
  style: `
    .lineSeparator {
      height: 50px;
      width: 100%;
      backgroundColor: #ffffff;
    }
  `
});