
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { Dialog } from "../../../common/Dialog";

export const UsecasePopupCheckTemplateContent = injectIntl(
  class UsecasePopupCheckTemplateContent extends Component {
    render() {
      const {
        intl,
        isOpen,
        isCalendaryEnvent,
        close,
        openNextPopup,
        openEmailEditorPopup,
      } = this.props;

      const buttonsProps = [
        {
          type: "secondary",
          label: isCalendaryEnvent ? intl.messages["button.activate_usecase_wording"] : intl.messages["usecasePopupCheckTemplateContent.openNextPopup"],
          onClick: () => {
            close();
            openNextPopup();
          },
        },
        {
          type: "primary",
          label: intl.messages["usecasePopupCheckTemplateContent.openEmailEditorPopup"],
          onClick: () => {
            close();
            openEmailEditorPopup();
          }
        }
      ];

      return (
        <Dialog
          isOpen={isOpen}
          title={intl.messages["usecasePopupCheckTemplateContent.title"]}
          buttonsProps={buttonsProps}
          close={close}
        >
          <p>{intl.messages["usecasePopupCheckTemplateContent.description"]}</p>
        </Dialog>
      );
    }
  }
);
