export const matchingUnisex = {
  food: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  pets: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  sport: {
    B2C: "False",
    B2B: "True",
    "B2B+B2C": "False",
  },
  jewelry: {
    B2C: "False",
    B2B: "True",
    "B2B+B2C": "False",
  },
  culture: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  general: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  gardening: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  decoration: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  appliances: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  office: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  games: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  construction: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  hardware: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  medical: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  furniture: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  bodycare: {
    B2C: "False",
    B2B: "True",
    "B2B+B2C": "False",
  },
  carparts: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  child: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  tourism: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  clothing: {
    B2C: "False",
    B2B: "True",
    "B2B+B2C": "False",
  },
  alcohol: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
  other: {
    B2C: "True",
    B2B: "True",
    "B2B+B2C": "True",
  },
};
