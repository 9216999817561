import { ClickAwayListener, Fade, Popper } from "@mui/material";
import React from "react";

export function KiPopper(props) {
  const modifiers = [];

  if (!props.flipIsEnabled) modifiers.push({
    name: "flip",
    enabled: false,
  });

  return (
    <Popper
      open={props.isOpen}
      anchorEl={props.anchor?.current}
      transition
      placement={props.placement}
      modifiers={modifiers}
      style={{zIndex: 20000}}
    >
      {
        ({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => props.setIsOpen(false)}>
            <Fade {...TransitionProps}>
              {props.children}
            </Fade>
          </ClickAwayListener>
        )
      }
    </Popper>
  );
}
