import { palette } from "../../styles/palette.js";

export const styles = (theme) => ({
  root: {
    background: "#f6afaa",
    color: "#963939",
    marginTop: "-47px",
    marginLeft: "30px",
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    minWidth: "100%",
    height: "auto",
    pointerEvents: "initial",
    maxWidth: "none",
    boxShadow: "none",
  },
  home: {
    width: "100%",
    align: "center",
    height: "auto",
    overflow: "none",
    marginLeft: "64px",
    marginRight: "64px",
    paddingTop: "48px",
    paddingBottom: "20px",
  },
  title: {
    marginTop: "32px",
    fontFamily: "Coolvetica",
    fontSize: "40px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    color: "#0E0E0F",
    textAlign: "center",
  },
  sub_title: {
    marginTop: "28px",
    height: "54px",
    fontFamily: "Coolvetica Book",
    fontSize: "20px",
    fontWeight: "600",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.38",
    letterSpacing: "normal",
    color: "#0E0E0F",
    textAlign: "center",
  },
  button: {
    color: "white",
    marginTop: "64px",
    marginLeft: "calc(50% - 130px)",
    height: "52px",
    width: "260px",
    borderRadius: "5px",
    backgroundColor: palette.purple,
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    fontWeight: "normal",
    letterSpacing: "-0.25px",
    lineHeight: "15px",
    textAlign: "center",
    boxShadow: "none",
    textTransform: "None",
    "&:hover": {
      backgroundColor: palette.purple,
    },
  },
  alternate: {
    marginTop: "23px",
    marginLeft: "calc(50% - 97px)",
    height: "52px",
    width: "194px",
    borderRadius: "5px",
    color: "#d8d8d8",
    backgroundColor: palette.white,
    fontFamily: "Coolvetica Book",
    border: "1px solid #d8d8d8",
    fontSize: "14px",
    fontWeight: "normal",
    letterSpacing: "-0.25px",
    lineHeight: "15px",
    textAlign: "center",
    boxShadow: "none",
    textTransform: "None",
    "&:hover": {
      backgroundColor: "#d8d8d8",
      border: "1px solid #d8d8d8",
    },
  },
  imgfield: {
    marginTop: "30px",
    marginLeft: "calc(50% - 152px)",
    height: "304px",
    width: "303px",
    textAlign: "center",
    objectFit: "contain",
  },
});
