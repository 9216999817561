import { useEffect, useState, useMemo } from "react";

import { Translation } from "../../../common/Translation";
import { SelectedImage } from "./SelectedImage";
import { ImageList } from "./ImageList";
import { useAuth } from "../../../../hooks/useAuth";

import classes from "./MyImageLibrary.module.scss";

const TAGS = ["all", "upload", "ambient", "productShowcase", "outpainting", "unsplash"];

export const MyImageLibrary = ({ selectedUrl, onChange, openCropMenu, onDelete }) => {

  const [uploadedImages, setUploadedImages] = useState([]);
  const [savedImages, setSavedImages] = useState([]);
  const [selectedTag, setSelectedTag] = useState("all");
  const auth = useAuth();

  useEffect(() => {
    auth.fetch("/account_management/creatorStudioImages").then(setSavedImages);
  }, [auth]);
  
  useEffect(() => {
    if (uploadedImages.length) { return; }
    auth.fetch("/account_management/imageAssets?type=images").then(response => {
      setUploadedImages(response.reverse());
    });
  }, [auth, uploadedImages]);

  const onUpload = (fileData) => {
    auth.fetch("/account_management/uploadImageAsset", {
      method: "POST",
      body: JSON.stringify({
        file: {
          filename: fileData.name,
          type: fileData.type,
          content: fileData.content,
        },
        folder: "images",
      })
    }).then(url => {
      setUploadedImages(prev => [url, ...prev]);
      setSavedImages(prev => [...prev, { url, type: "upload" }]);
      auth.fetch(`/account_management/addCreatorStudioImage?type=upload&url=${encodeURIComponent(url)}`, {
        method: "POST",
      });
    });
  };

  const images = useMemo(() => {
    const _savedImages = savedImages?.filter(({ type }) => selectedTag === "all" || selectedTag === type)?.map(({ url }) => url).reverse() || [];

    const result = [...new Set([..._savedImages, ...(["all", "upload"].includes(selectedTag) ? uploadedImages : [])])];

    return result;
  }, [savedImages, uploadedImages, selectedTag]);

  const onDeleteImages = (selectedImages) => {
    auth.fetch("/api/account_management/deleteBulkImageAsset", {
      method: "POST",
      body: JSON.stringify({
        imageUrls: selectedImages,
      })
    });
    setUploadedImages(prev => prev.filter(elem => !selectedImages.includes(elem)));
    auth.fetch(`/account_management/deleteCreatorStudioImage?${selectedImages.map(url => `urls=${url}`).join("&")}`, {
      method: "DELETE",
    });
    setSavedImages(prev => [...prev].filter(elem => !selectedImages.includes(elem.url)));
    onDelete(selectedImages);
  };

  return (
    <div className={classes.container}>
      <h3 className={classes.title}><Translation id="myImageLibrary.title" /></h3>
      <SelectedImage selectedUrl={selectedUrl} openCropMenu={openCropMenu} />
      
      <div className={classes.tagsContainer}>
        {TAGS.map(tag => (
          <div 
            className={`infoMedium ${classes.tag} ${selectedTag === tag ? classes.selectedTag : ""}`}
            onClick={() => setSelectedTag(tag)}
          >
            <Translation id={`myImageLibrary.tag.${tag}`} />
          </div>
        ))}
      </div>
      
      <div className={classes.separator} />

      <ImageList
        images={images}
        onUpload={["all", "upload"].includes(selectedTag) ? onUpload : null}
        selectedUrl={selectedUrl} 
        onImageClick={onChange}
        onDeleteImages={onDeleteImages}
      />
    </div>
  );
};