import React from "react";
import { Translation } from "../../common/Translation";


export const KiFileInput = ({ file, setFile }) => {

  const changeFile = (e) => {
    if (!e) { return; }
    const file = e.target.files[0];
    if (!file) { return; }
    const reader = new FileReader();
    reader.onloadend = function () {
      setFile(file);
    };
    reader.readAsDataURL(file);
  };

  const handleClick = (e) => {
    e.preventDefault();
    document.getElementById("file_input").click();
  };


  return (
    <div>
      <input style={{ display: "none" }} type="file" hidden accept=".csv" id="file_input" onChange={changeFile} />
      {
        file === "" ? (
          <div style={{ display:"flex", justifyContent:"center", alignItems:"center", color:"#B5BDB8" , cursor:"pointer"}} onClick={handleClick}>
            <i style={{ marginRight:"10px", fontSize:"23px"}} className="fal fa-paperclip"></i>
            <div>
              <Translation id= "blacklist.input" defaultMessage="Email" />
            </div>
          </div>
        ) : ( 
          <div style={{ display:"flex", justifyContent:"center", alignItems:"center", color:"#B5BDB8"}} >
            {file.name}
            <i style={{color:"#963939", marginLeft:"10px"}} onClick={() => {setFile("");}} className="fal fa-times-circle"></i>
          </div>
        )
      }
    </div>
  );
};



