import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { InputTitle } from "./InputTitle.js";

const styles = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

export const InputSection = withStyles(styles)(
  class InputSection extends Component {
    render() {
      const { classes, title, helperText, children, titleStyle } = this.props;

      return (
        <div>
          {title && <InputTitle title={title} helperText={helperText} titleStyle={titleStyle} />}
          <div className={classes.content}>{children}</div>
        </div>
      );
    }
  }
);
