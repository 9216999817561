import { trustpilot3 } from "./trustpilot3";
import { multiTrustpilot } from "./multiTrustpilot";
import { globalTrustpilot1 } from "./globalTrustpilot1";
import { globalTrustpilot2 } from "./globalTrustpilot2";
import { globalTrustpilot3 } from "./globalTrustpilot3";
import { avisverifies4 } from "./avisverifies4";
import { globalAvisverifies1 } from "./globalAvisverifies1";
import { globalAvisverifies2 } from "./globalAvisverifies2";
import { multiAvisverifies } from "./multiAvisverifies";

export const getReviewBlocks = ({ trustPilotReviews, avisVerifiesReviews, fontFamily, textColor, buttonTextColor, accentColor, dominantColor }) => {
  return [
    globalTrustpilot1({ rating: trustPilotReviews?.globalRating, fontFamily, textColor, dominantColor }),
    globalTrustpilot2({ rating: trustPilotReviews?.globalRating, fontFamily, textColor, dominantColor }),
    globalTrustpilot3({ rating: trustPilotReviews?.globalRating, fontFamily, textColor, dominantColor }),
    trustpilot3({ reviews: trustPilotReviews?.reviews, fontFamily, textColor, buttonTextColor, accentColor, dominantColor }),
    multiTrustpilot({ reviews: trustPilotReviews?.reviews?.slice(0, 3), fontFamily, textColor, buttonTextColor, accentColor, dominantColor }),
    globalAvisverifies1({ rating: avisVerifiesReviews?.globalRating, fontFamily, textColor, dominantColor }),
    globalAvisverifies2({ rating: avisVerifiesReviews?.globalRating, fontFamily, textColor, dominantColor }),
    avisverifies4({ reviews: avisVerifiesReviews?.reviews, fontFamily, textColor, buttonTextColor, accentColor, dominantColor }),
    multiAvisverifies({ reviews: avisVerifiesReviews?.reviews?.slice(0, 3), fontFamily, textColor, buttonTextColor, accentColor, dominantColor })
  ];
};