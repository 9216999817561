import { withStyles } from "@material-ui/core";
import { Component } from "react";

import { styles } from "./PageBody.styles.js";

class PageBody extends Component {
	render() {
		const {
			classes,
			children,
			style
		} = this.props;

		return (
			<div className={classes.pageBody}
				style={style}
			>
				{ children }
			</div>
		);
	}
}

const _PageBody = withStyles(styles)(PageBody);
export { _PageBody as PageBody };