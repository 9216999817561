import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";

import classes from "./FormButtonTO.module.scss";

export function FormButtonTO({ label, icon, type = "primary", size = "lg", style, action, loadingButton, loading, disabled, classes: classesProps, isResponsive = false }) {
  const typeClass = classes[type];
  const disabledClasses = disabled || loading ? `${classes.disabled} ${classes[`${type}Disabled`]}` : "";
  const sizeClass = classes[size];

  return (
    <button
      type="submit"
      style={style}
      onClick={action}
      className={classNames(classesProps?.button, classes.button, typeClass, sizeClass, disabledClasses)}
      disabled={disabled || (loadingButton && loading)}
    >
      {loadingButton && loading ? (
        <CircularProgress size={34} className={classes.loading} />
      ) : null}
      {icon ? (
        <div className={classes.buttonWithIcon}>
          <div className={isResponsive ? classes.responsiveButtonText : null}>{label}</div>
          <div>{icon}</div>
        </div>
      ) : (
        label
      )}
    </button>
  );
}