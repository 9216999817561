import React, { useState, useEffect } from "react";
// Translations
import { IntlProvider } from "react-intl";
// Pour utiliser les formats de date, time ..
//Chargement des traduction
import messages_fr from "../public/translations/fr.json";
import messages_en from "../public/translations/en.json";
import messages_es from "../public/translations/es.json";
import { CircularProgress } from "@material-ui/core";
// Router 
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Login
import { SignIn } from "./Auth/SignIn.js";
import { SignUp } from "./Auth/SignUp.js";

import { CallResetPassword } from "./Auth/callResetPassword.js";
import { ResetPassword } from "./Auth/resetPassword.js";

import { OnBoarding } from "./OnBoarding/OnBoarding.js";
import { Unsubscribe } from "./Unsubscribe.js";
import { SponsorshipLanding } from "./SponsorshipLanding/SponsorshipLanding.js";
import { LoggedInApp } from "./App.js";
import { reloadAccountContext, emptyAccountContext, isContextLoaded$ } from "./../contexts/context";
import { useAuth } from "../hooks/useAuth";
import { AuthCallback } from "./Auth/AuthCallback";
import { ActionNotification } from "./notification/ActionNotification.jsx";

const messages = {
  fr: messages_fr,
  en: messages_en,
  es: messages_es,
};

export function MainApp() {
  const defaultLocale = "fr";
  const isLocaleValid = (locale) => Object.keys(messages).includes(locale);

  const [appLoading, setAppLoading] = useState(true);
  const [locale, setLocale] = useState(defaultLocale);

  const auth = useAuth();

  // Handle account fetching
  useEffect(() => {
    auth.getUser().then((user) => {
      if(user && user.attributes.locale) {
        setLocale(isLocaleValid(user.attributes.locale) ? user.attributes.locale : defaultLocale);
      } else {
        const navigatorLocale = navigator.language?.split(/[-_]/)?.[0];
        setLocale(isLocaleValid(navigatorLocale) ? navigatorLocale : defaultLocale);
      }
    });
    
    if (auth.loggedIn()) {
      reloadAccountContext()
        .then(response => {
          if (!response.account) {
            return auth.logout();
          }
        });
    } else {
      emptyAccountContext();
    }
  }, [auth]);

  // Handle app loading
  useEffect(() => {
    const subscription = isContextLoaded$.subscribe(loaded => setAppLoading(!loaded));
    return () => subscription.unsubscribe();
  }, []);

  return appLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <IntlProvider
      key={locale}
      locale={locale}
      defaultLocale={defaultLocale}
      messages={messages[locale]}
    >
      <Router>
        <div className="App">
          <ActionNotification/>
          <Switch>
            <Route exact path="/callback" component={AuthCallback} />
            <Route exact path="/on_boarding" component={OnBoarding} />
            <Route exact path="/login" component={SignIn} />
            <Route exact path="/register/cgv" component={SignUp} />
            <Route exact path="/register" component={SignUp} />
            <Route exact path="/callResetPassword" component={CallResetPassword} />
            <Route exact path="/resetPassword" component={ResetPassword} />
            <Route exact path="/unsubscribe" component={Unsubscribe} />
            <Route exact path="/sponsorship_landing" component={SponsorshipLanding} />
            <Route path="/" component={LoggedInApp} />
          </Switch>
        </div>
      </Router>
    </IntlProvider>
  );
}
