import React, { Component } from "react";
import { injectIntl } from "react-intl";
import classes from "../ParametersSection.module.scss";
import fr from "../../../../public/Illu_no_logo_fr.png";
import en from "../../../../public/Illu_no_logo_en.png";
import es from "../../../../public/Illu_no_logo_es.png";
import { CircularProgress } from "@material-ui/core";
import { CustomButton } from "../../../common/Button/CustomButton";
import { EmptyState } from "../../../common/EmptyState/EmptyState.js";

const localeImagesError = { fr, en, es };

export const LogoParameters = (
  injectIntl(
    class LogoParameters extends Component {
      openFilePicker(e) {
        e.preventDefault();
        document.getElementById("logoInput").click();
      }

      render() {
        const {
          intl,
          logo,
          noCms,
          updateLogo,
          deleteLogo,
          loading,
        } = this.props;

        return (
          <div className={classes.logoParameters}>
            {noCms ? (
              <EmptyState variant="noCmsLogo" />
            ) : (
              <div>
                <input
                  type="file"
                  data-test="updateLogo"
                  hidden
                  accept="image/*"
                  id="logoInput"
                  onChange={updateLogo}
                />
                {loading ? (
                  <CircularProgress size={34} className={classes.loading} />
                ) : (
                  <>
                    <div
                      className={classes.logoPickerImageContainer}
                    >
                      {!logo ? (
                        <div className={classes.noLogoContainer}>
                          <img
                            className={classes.logoErrorImage}
                            alt={
                              intl.messages[
                                "templateCustomization.logoParameters.unableToLoad"
                              ]
                            }
                            src={localeImagesError[intl.locale]}
                          />
                          <div>
                            <CustomButton type="secondary" onClick={this.openFilePicker} size="sm">{intl.messages["button.addLogo"]}</CustomButton>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            className={classes.hoverUpdate}
                            onClick={this.openFilePicker}
                          >
                            <h3>{intl.messages["editprofile.button.editlogo"]}</h3>
                          </div>
                          <img
                            data-test="logo"
                            className={classes.logoPickerImage}
                            alt={
                              intl.messages[
                                "templateCustomization.logoParameters.unableToLoad"
                              ]
                            }
                            src={logo}
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
                {logo && <div className={classes.buttonContainer}>
                  <CustomButton onClick={deleteLogo} type='secondary' size="sm" >{intl.messages["templateCustomization.logoParameters.delete"]}</CustomButton>
                </div>}
              </div>
            )}
          </div>
        );
      }
    }
  )
);
