import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment/min/moment-with-locales";

import { Translation } from "../../common/Translation";
import { calcStepDate } from "../Subscription/utils";
import { palette } from "../../../styles/palette";
import { CustomButton } from "../../common/Button/CustomButton";

const styles = () => ({
  title: {
    marginBottom: 10,
  },
  packagesContainer: {
    marginTop: 30,
    display: "flex",
    gridGap: 40,
  },
  packageContainer: {
    background: palette.primaryWhite,
    borderRadius: 20,
    padding: "20px 50px 35px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  caContainer: {
    background: palette.primaryYellow15,
    borderRadius: 8,
    padding: "5px 40px",
    marginBottom: 8,
  },
  greyText: {
    color: palette.neutralDarkGrey,
  },
  price: {
    marginTop: 17,
    letterSpacing: "1px",
  },
  upToContainer: {
    lineHeight: "normal",
    marginBottom: 33,
  },
  sliderBackground: {
    height: 7,
    width: 215,
    borderRadius: 40,
    position: "relative",
    marginBottom: 15,
  },
  sliderBar: {
    background: palette.primaryYellow200,
    borderRadius: 40,
    height: 7,
  },
  outOfPackageCustomer: {
    color: palette.accentRed500
  },
  buttonContainer: {
    marginTop: 30,
  }
});

const useStyles = makeStyles(styles);

export const SubscriptionRecommendedPackages = ({ steps, subscription, nextPlan, contacts, onUpgrade }) => {

  const classes = useStyles();
  const [nextPackage, setNextPackage] = useState();
  const [fullPackage, setFullPackage] = useState();
  const intl = useIntl();

  useEffect(() => {
    moment.locale(intl.locale); 
  }, [intl]);

  const currency = subscription.Currency__c === "EUR" ? "€" : "$";

  useEffect(() => {
    setFullPackage(steps.steps.find(step => step.step === "always"));
    const _nextPackage = steps.steps.find(step => step.upTo > subscription.Limite_de_contacts__c);
    if (_nextPackage && _nextPackage.step !== "always") {
      setNextPackage(_nextPackage);
    }
  }, [steps, subscription]);

  const getRecencyWording = (step) => {
    if (step !== "always") {
      return <FormattedMessage id="subscriptionRecommendedPackages.package.recency" values={{ ...calcStepDate(step) }} />;
    } else {
      return <FormattedMessage id="subscriptionRecommendedPackages.package.recency.always" />;
    }
  };

  const packages = nextPlan ? [{
    ...nextPlan,
    step: nextPlan.yearNbr,
    outPackageCustomerNbr: contacts.actifs - nextPlan.customerNbr,
  }]: [nextPackage, fullPackage].filter(_package => !!_package && _package.upTo > subscription.Limite_de_contacts__c);

  if (!packages.length || (nextPlan && nextPlan.upTo === "custom")) {
    return ;
  }

  return (
    <div>
      <h2 className={classes.title}><Translation id="subscriptionRecommendedPackages.title" /></h2>
      <div className="text"><Translation id="subscriptionRecommendedPackages.subtitle" /></div>

      <div className={classes.packagesContainer}>
        {packages.map(_package => (
          <div key={_package.step} className={classes.packageContainer}>

            <div className={classes.caContainer}>
              <span className="infoMedium"><Translation id="subscriptionRecommendedPackages.ca.value" /></span>
            </div>

            <div className={`info ${classes.greyText}`}>{getRecencyWording(_package.step)}</div>

            <div className={classes.price}>
              <span className="display2">{subscription.interval === "month" ? _package.price : _package.price_annual}{currency}</span>
              <span className="display4">/<Translation id={`subscriptionInfo.package.interval.${subscription.interval}`} /></span>
            </div>

            <div className={classes.upToContainer}>
              <span className="text"><Translation id="subscriptionRecommendedPackages.package.upTo" /> </span>
              <span className="textBold">{_package.upTo} <Translation id="subscriptionSlider.contacts"/></span>
            </div>

            <div className={classes.sliderBackground} style={{ background: _package.outPackageCustomerNbr > 0 ? palette.accentRed200 : palette.neutralLightGrey }}>
              <div className={classes.sliderBar} style={{ width: `${_package.customerNbr * 100 / _package.upTo}%` }} />
            </div>

            <div className="infoMedium">
              {_package.outPackageCustomerNbr > 0 ?
                <span className={classes.outOfPackageCustomer}>{_package.outPackageCustomerNbr} <Translation id="subscriptionRecommendedPackages.package.outOfPackage" /></span> :
                <span>{_package.customerNbr}/{_package.upTo} <Translation id="subscriptionSlider.contacts"/></span>
              }
            </div>

            <div className={classes.buttonContainer}>
              <CustomButton
                size="md"
                onClick={() => onUpgrade(_package)}
              >
                <Translation id="button.subscribe" />
              </CustomButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};