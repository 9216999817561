import { AddReviewLinkComponent } from "./grapesJsKiliba/emptyComponents/AddReviewLinkComponent";
import { PromoCodeOptionalMenu } from "./grapesJsKiliba/optionalMenu/promoCodeMenu";
export const CATEGORIES = [
  // main
  {
    name: "header",
    type: "blocks",
    icon: "fa-light fa-arrow-up-to-dotted-line",
  },
  {
    name: "body",
    type: "blocks",
    icon: "fa-light fa-arrows-to-dotted-line",
  },
  {
    name: "footer",
    type: "blocks",
    icon: "fa-light fa-arrow-down-to-dotted-line",
  },
  {
    name: "preheader",
    type: "components",
    icon: "fa-light fa-heading",
  },
  {
    name: "logo",
    type: "components",
    icon: "fa-light fa-square-star",
  },
  {
    name: "image",
    type: "components",
    icon: "fa-light fa-image",
    topRightIcon: "fa-solid fa-sparkles",
  },
  {
    name: "text",
    type: "components",
    icon: "fa-light fa-text-size",
  },
  {
    name: "product",
    type: "components",
    icon: "fa-light fa-box-open",
  },
  {
    name: "review",
    type: "components",
    icon: "fa-light fa-messages",
    emptyComponent: true
  },
  {
    name: "separator",
    type: "components",
    icon: "fa-light fa-square-dashed",
  },
  {
    name: "social",
    type: "components",
    icon: "fa-light fa-thumbs-up"
  },
  {
    name: "button",
    type: "components",
    icon: "fa-light fa-rectangle-wide",
  },
  {
    name: "promoCode",
    type: "components",
    icon: "fa-light fa-percent"
  },
  {
    name: "columns",
    type: "components",
    icon: "fa-light fa-columns-3"
  },
  {
    name: "columnComponent",
  },
  // secondary
  {
    parent: "header", 
    name: "subHeader",
    show: false,
  },
  { 
    parent: "body",
    name: "subBody",
    show: false,
  },
  {
    parent: "footer",
    name: "largefooter",
  },
  {
    parent: "footer",
    name: "bandfooter",
  },
  {
    parent: "logo",
    name: "subLogo",
    show: false,
  },
  {
    parent: "image",
    name: "headerImage",
    show: false,
  },
  {
    parent: "text",
    name: "title",
  },
  {
    parent: "text",
    name: "subtitle",
  },
  {
    parent: "text",
    name: "paragraph",
  },
  {
    parent: "product",
    name: "card"
  },
  {
    parent: "product",
    name: "new"
  },
  {
    parent: "product",
    name: "multiProducts"
  },
  {
    parent: "product",
    name: "promotions"
  },
  {
    parent: "review",
    name: "trustpilot",
    emptyComponent: AddReviewLinkComponent
  },
  {
    parent: "review",
    name: "avisverifies",
    emptyComponent: AddReviewLinkComponent
  },
  {
    parent: "social",
    name: "social",
    show: false,
  },
  {
    parent: "separator",
    name: "subSeparator",
    show: false,
  },
  {
    parent: "button",
    name: "button",
    show: false,
  },
  {
    parent: "promoCode",
    name: "headband",
    emptyComponent: PromoCodeOptionalMenu
  },
  {
    parent: "promoCode",
    name: "wideHeadband"
  },
  {
    parent: "columns",
    name: "columns",
    show: false,
  },
  {
    parent: "columnComponent",
    name: "separator",
    show: false,
  },
];