import {processStarsMjml} from "./review.tools";
import { formatHtmlIntoShortText } from "../../../../../services/text.service";
const LABEL = "trustpilot3";
const CATEGORY = "review:trustpilot";

export const trustpilot3 = ({ reviews, fontFamily, textColor, buttonTextColor, accentColor, dominantColor }) => ({
  label: LABEL,
  category: CATEGORY,
  block: () => {
    if (!reviews?.length) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }
    const starMjml = processStarsMjml("TP", reviews[0].rating, "center");
      
    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${dominantColor}" padding-top="0" padding-bottom="0" full-width="full-width">
        <mj-section padding-bottom="0px" padding-top="0px">
          <mj-column>
            <mj-raw>
              <tr>
                <td align="center">
                  <div style="text-align:center;padding-bottom:15px;padding-top:15px">
                    <span style="color:${buttonTextColor};background-color:${accentColor};display:inline-block;width:26px;height:14px;text-align:center;border-radius:40px;padding:15px 9px;box-sizing:initial;font-size:13px">
                      ${reviews[0].minimizedName}
                    </span>
                  </div>
                </td>
              </tr>
            </mj-raw>
          </mj-column>
        </mj-section>
        <mj-section padding-bottom="0px" padding-top="0px">
          <mj-column>
            <mj-raw>
              <tr>
                <td align="center">
                  <div style="text-align:center">
                    ${starMjml}
                  </div>
                </td>
              </tr>
            </mj-raw>
          </mj-column>
        </mj-section>
        <mj-section padding-bottom="0px" padding-top="0px">
          <mj-column width="25%"></mj-column>
          <mj-column width="50%">                    
            <mj-image data-readonly="true" padding-bottom="0px" width="70px" align="center" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/trustpilot-logo.png"></mj-image>

            <mj-text data-readonly="true" font-family="${fontFamily}" color="${textColor}" align="center" padding-top="13px" font-weight="600" padding-left="8px" padding-right="8px" font-size="12px">${reviews[0].title}</mj-text>
            <mj-text data-readonly="true" font-family="${fontFamily}" color="${textColor}" align="center" padding-top="0px" padding-left="8px" padding-right="8px" font-size="12px" line-height="14px">${formatHtmlIntoShortText(reviews[0].text)}</mj-text>

          </mj-column>
          <mj-column width="25%"></mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: "review:trustpilot",
      activate: true,
      content: mjml,
    };
  },
});