import React, { Component } from "react";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { injectIntl, FormattedMessage } from "react-intl";

import withStyles from "@material-ui/core/styles/withStyles";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withAuth } from "../withAuth";
import { EventSystem } from "../../eventsystem/EventSystem.js";
import { FormButtonTO } from "../FormButtonTO.jsx";
import { KiTable } from "../common/KiTable/KiTable.js";
import { SelectPicker } from "../common/SelectPicker.js";

import { BestProduct } from "../bestProduct/BestProduct.js";
import { KiTextField } from "../common/Input/KiTextField.js";

import { getFormattedNumber } from "../../tools/getFormattedNumber.js";
import { palette } from "../../styles/palette.js";
import { Dialog } from "../common/Dialog";
import { CustomButton } from "../common/Button/CustomButton";
import { Translation } from "../common/Translation";
import { AccountContextBehaviorSubject$ } from "../../contexts/context";

import fr from "../../../src/public/emptybestproducts-fr.png";
import en from "../../../src/public/emptybestproducts-en.png";
import es from "../../../src/public/emptybestproducts-es.png";
import { PopupProductsCategories } from "./PopupProductsCategories";
import { ScrollSelectPicker } from "../common/ScrollSelectPicker";

const localeImages = { fr, en, es };

const styles = theme => ({
  root: {
    width: "calc(100% - 68px - 80px)",
    padding: "0 68px",
  },
  title: {
    fontFamily: "Coolvetica",
    fontSize: "40px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    marginBottom: "36px",
    color: palette.black
  },
  ctaIcon: {
    marginLeft: 8,
    marginTop: 5,
    fontSize: 20,
  },
  subtitle: {
    fontFamily: "Coolvetica Book",
    fontSize: "20px",
    fontWeight: "600",
    color: "#0E0E0F",
  },
  ctas: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  ctasGroup: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: 15,
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: "4px 15px 4px 55px",
    transition: theme.transitions.create("width"),
    fontSize: "14px",
    height: "25px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 100,
      "&:focus": {
        width: 200,
      },
    }
  },
  header: {
    display: "flex",
  },
  toolbar: {
    margin: "0px",
    height: "48px"
  },
  highlight: {
    color: palette.black,
    backgroundColor: "#F4D093"
  },
  checkbox: {
    color: palette.anothergray,
    "&:hover": {
      color: palette.purple
    }
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  statusIcon: {
    minWidth: 8,
    height: 8,
    borderRadius: 1000,
    marginRight: 8,
  },
  selectedRowsToolbar: {
    backgroundColor: palette.primaryBlack,
    color: palette.primaryWhite,
    height: 64,
    padding: "0 32px",
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: 16,
    }
  },
  list: {
    width: "100%",
    maxHeight: 466,
    overflow: "hidden",
    padding: 0,
  },
  fakeProduct: {
    marginTop: "22px",
    marginBottom: "14px",
    height: 200
  },
  productImage: {
    borderRadius: 5,
    maxWidth: 30,
    maxHeight: 30,
  },
  productImageContainer: {
    width: 30,
  },
  productCell: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  }
});
export const Products = withAuth(
  withStyles(styles)(
    injectIntl(
      class Products extends Component {
        constructor(props) {
          super(props);
          this.getPaginatedData = this.getPaginatedData.bind(this);
          this.loadMoreData = this.loadMoreData.bind(this);
          this.resetData = this.resetData.bind(this);
          this.exportProductsCSV = this.exportProductsCSV.bind(this);
          this.handleSubmitBlacklist = this.handleSubmitBlacklist.bind(this);
          this.renderBestProducts = this.renderBestProducts.bind(this);
          this.selectRows = this.selectRows.bind(this);
          this.onSelectAll = this.onSelectAll.bind(this);
          this.changeSearch = this.changeSearch.bind(this);
          this.switchStatuses = this.switchStatuses.bind(this);
          this.blacklistProducts = this.blacklistProducts.bind(this);
          this.whitelistProducts = this.whitelistProducts.bind(this);
          this.switchCategories = this.switchCategories.bind(this);
          this.handleProductCategoryPopUpClose = this.handleProductCategoryPopUpClose.bind(this);
          this.handleOpenCategoriePopup = this.handleOpenCategoriePopup.bind(this);
          this.savePagination = this.savePagination.bind(this);
          this.isProductBlacklisted = this.isProductBlacklisted.bind(this);
          this.isProductWhitelisted = this.isProductWhitelisted.bind(this);
          this.computeStatus = this.computeStatus.bind(this);

          this.state = {
            account: undefined,
            products: [],
            bestProducts: [],
            search: "",
            statuses: {
              active: { isActive: true, wording: props.intl.messages["products.filters.status.active"], },
              unavailable: { isActive: true, wording: props.intl.messages["products.filters.status.unavailable"], },
              blacklisted: { isActive: true, wording: props.intl.messages["products.filters.status.blacklisted"], }
            },
            
            columns: [
              {
                key: "product",
                name: props.intl.messages["products.table.head.product"],
              },
              {
                key: "id",
                name: props.intl.messages["products.table.head.id"],
              },
              {
                key: "categories",
                name: props.intl.messages["products.table.head.categories"],
              },
              {
                key: "status",
                name: props.intl.messages["products.table.head.status"],
              },
              {
                key: "reason",
                name: props.intl.messages["products.table.head.reason"],
              },
              {
                key: "ca",
                name: props.intl.messages["products.table.head.turnover"],
                sortProperty: "rawValue",
                tooltipContent: props.intl.messages["product.ca.tooltip"]
              },
            ],
            whitelistPopupIsOpen: false,
            blacklistConfirmationPopupIsOpen: false,
            noCms: false,
            emptyStateVariant: "",
            productsLoading: true,
            manualWhitelistingIsInProgress: false,
            manualBlacklistingIsInProgress: false,
            limit: 3000,
            cmsConfig: null,
            isCategoriesPopupOpened: false,
            categories: [],
            viewAllProductFromBlacklistCategoriesMode: false,
            pagination: {
              pageKey: 0,
              page: 0,
              rowsPerPage: 10
            },
            exportProductsCSVLoading: false
          };

          this.onSearch$ = new Subject();
        }

        handleSubmitBlacklist(e) {
          e.preventDefault();
          EventSystem.publish("url_switch", "/blacklist?blacklistType=products");
        }

        renderBestProducts() {
          const {
            intl,
          } = this.props;

          const bestProducts = this.state.bestProducts;
          const finalBestProducts = [];
          for (const product of bestProducts) {
            finalBestProducts.push(
              <Grid item key={product.url}>
                <BestProduct
                  date={product.date}
                  active={product.active}
                  url={product.url}
                  title={product.title}
                  ca={getFormattedNumber(String(product.ca), this.state.account && this.state.account.currency, intl.locale, 0)}
                />
              </Grid>
            );
          }
          return finalBestProducts;
        }

        async componentDidMount() {
          const { account, config } = AccountContextBehaviorSubject$.getValue();
          this.onSearch$.asObservable().pipe(
            debounceTime(300),
            distinctUntilChanged()
          ).subscribe(debounced => {
            this.setState({ search: debounced }, () => this.resetData());
          });

          this.setState({ cmsConfig: config, account});

          if (!config?.productCategory) {
            const newColumns = JSON.parse(JSON.stringify(this.state.columns));
            newColumns.splice(2, 1);
            this.setState({ columns: newColumns });
          }

          await this.getPaginatedData();
          await this.getAllProductCategorieNames();
          await this.fetchBestProducts(account);

          if (account.CMS) {
            localStorage.setItem("CMS", account.CMS);
          }
          this.setEmptyStateVariant(account);
        }

        componentWillUnmount() {
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
        }

        setEmptyStateVariant(account) {
          if (account.step_register > 2) {
            if (this.state.products.length === 0) {
              this.setState({ emptyStateVariant: "syncActiveProducts" });
              this.setState({ noCms: true });
            }
          } else {
            this.setState({ noCms: true });
            this.setState({ emptyStateVariant: "noCmsProducts" });
          }
        }

        computeStatus(product) {
          let status = product.date_blacklist ? 
            "blacklisted" : 
            product.mw_is_usable ? 
              "active" : 
              product.mw_is_usable === null ? 
                "in_progress" : 
                "unavailable";

          if (product.iscategoryblacklisted) {
            status = "unavailable";
          }
          return status;
        }

        async getPaginatedData(page = 0) {
          const { intl } = this.props;
          this.setState({ productsLoading: true });
          try {
            const { account } = AccountContextBehaviorSubject$.getValue();
            let url = `/api/data/getPaginatedProducts?accountId=${account._id}&limit=${this.state.limit}&page=${page}&searchText=${this.state.search}`;

            // filter by status
            const activeStatus = this.getActiveFilters(this.state.statuses);
            activeStatus.forEach((activeFilter, index) => {
              url += `&statuses=${activeFilter.key}`;
            });

            // filter by categories
            const activeCategories = this.state.categories.filter(categorie => categorie.isActive);
            activeCategories.forEach(activeCategorie => {
              url += `&categorieIds=${activeCategorie.id}`;
            });

            const result = await this.props.auth.fetch(url, { method: "GET" });
            const products = result.products;

            const getReason = product => {
              if (product.iscategoryblacklisted) {
                return "categoryBlacklisted";
              }
              if (product.mw_is_usable) {
                return "";
              }

              try {
                const details = JSON.parse(product.is_usable_details);
                if (!details.is_product_active) {
                  return "inactive";
                }
                if (!details.is_image_valid) {
                  return "invalidImage";
                }
                if (!details.is_positive_price) {
                  return "invalidPrice";
                }
                if (!details.is_stock_valid) {
                  return "invalidStock";
                }
                if (!details.is_default_variant) {
                  return "notDefaultVariant";
                }
              
              } catch {
                return "";
              }
            };

            const formattedProducts = products
              .filter(product => product.title)
              .map(product => {
                const formatNumber = (price) => {
                  return price ? Number.parseFloat(price).toFixed(2) : "0";
                };

                return {
                  id: product.id_product,
                  name: product.title[intl.locale] || product.title.fr || product.title.en || product.title.es || product.title.de || product.title.nl || "",
                  categories: account.CMS === "shopify" 
                    ? product.category_name?.[intl.locale] || product.category_name?.fr || product.category_name?.en || product.category_name?.es || "-"
                    : product.product_categories ? Object.keys(product.product_categories).map(key => product.product_categories[key]).join(", ") : "-",
                  status: this.computeStatus(product),
                  turnover: formatNumber(product.ca),
                  isBlacklisted: !!product.date_blacklist,
                  isSelected: false,
                  availableForOrder: product.available_for_order,
                  availableWhenOutOfStock: product.available_when_out_of_stock,
                  image: product.image_url,
                  reason: getReason(product),
                };
              });

            this.setState({
              products: [...this.state.products, ...formattedProducts],
              total: result.total,
              productsLoading: false
            });
          } catch (error) {
            console.error(error);
            EventSystem.newNotification("notification.action", intl.messages["products.loading.error"]);
          }
        }

        async getAllProductCategorieNames() {
          const { intl } = this.props;

          const categories = await this.props.auth.fetch(`/data/getAllProductCategorieNames?locale=${intl.locale}`, { method: "GET" });
          this.setState({ categories: categories.map(category => ({ id: category.id, name: category.name, isActive: false })) });
        }

        async loadMoreData(page) {
          this.getPaginatedData(page);
        }

        async resetData() {
          const updatedPagination = Object.assign(this.state.pagination, { key: 0, pageKey: 0 });
          this.setState({ products: [], pagination: updatedPagination }, () => this.getPaginatedData());
        }

        getActiveFilters(filterObject) {
          return Object.keys(filterObject)
            .map(key => { return { status: filterObject[key], key }; })
            .filter(filteredObject => filteredObject.status.isActive);
        }

        // KI-1180 : EXPORT DES PRODUITS, CHANGER product.category en product.product_categories
        async exportProductsCSV() {
          const { auth, intl } = this.props;
          this.setState({ exportProductsCSVLoading: true });
          try {
            const header = [
              { id: "id", title: intl.messages["products.table.head.id"] },
              { id: "name", title: intl.messages["products.table.head.name"] },
              ...(this.state.cmsConfig.productCategory ? [{ id: "category", title: intl.messages["products.table.head.category"] }] : []),
              { id: "status", title: intl.messages["products.table.head.status"] },
              { id: "turnover", title: intl.messages["products.table.head.turnover"] }
            ];

            const statusWordings = {};
            for(const status in this.state.statuses) {
              statusWordings[status] = this.state.statuses[status].wording;
            }

            const sendExportViaEmail = this.state.total >= 10_000;

            const response = await auth.fetch("/api/data/exportProductsCSV", {
              method: "POST",
              body: JSON.stringify({
                header,
                statusWordings: statusWordings,
                searchText: this.state.search,
                statuses: Object.keys(this.state.statuses).filter(status => this.state.statuses[status].isActive),
                sendViaEmail: sendExportViaEmail,
                locale: intl.locale
              }),
            }, {
              rawResponse: true
            });

            if(sendExportViaEmail) {
              const user = await auth.getUser();
              EventSystem.newNotification(
                "notification.action",
                intl.formatMessage({ id: "csv.export.sendViaEmail" }, { email: user.attributes.email })
              );
            } else {
              const blob = await response.blob();
              const fileUrl = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = fileUrl;
              link.download = "products_export.csv";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          } catch (error) {
            console.error(error);
            EventSystem.newNotification("notification.action", intl.messages["error.main_error"]);
          }

          this.setState({ exportProductsCSVLoading: false });
        }

        changeSearch(event) {
          const search = event.target.value;

          this.setState({ search: search });

          this.onSearch$.next(event.target.value);
        }

        switchStatuses(name, value) {
          const {
            intl,
          } = this.props;

          const newStatuses = JSON.parse(JSON.stringify(this.state.statuses));

          Object.keys(newStatuses).forEach(newStatusKey => {
            newStatuses[newStatusKey].isActive = value.includes(intl.messages[`products.filters.status.${newStatusKey}`]);
          });
          this.setState({ statuses: newStatuses }, () => this.resetData());
        }

        switchCategories(id) {
          const updatedCategories = this.state.categories.map(category => 
            category.id === id ? { ...category, isActive: !category.isActive} : category
          );

          this.setState({ categories: updatedCategories },
            () => this.resetData()
          );
        }

        fetchBestProducts = async (account) => {
          const {
            auth
          } = this.props;
          const result = await auth.fetch(`/api/data/getBestProducts?accountId=${account._id}`, { method: "GET" });

          this.setState({ bestProducts: result.products });
        };

        async blacklistProducts() {
          const {
            intl,
          } = this.props;
          const { account } = AccountContextBehaviorSubject$.getValue();
          this.setState({ manualBlacklistingIsInProgress: true });

          const options = {
            method: "POST",
            body: JSON.stringify({
              productIds: this.state.products
                .filter(product => product.isSelected)
                .map(product => product.id),
            })
          };
          try {
            await this.props.auth.fetch(`/api/data/blacklistProducts?accountId=${account._id}`, options);
            this.setState({ products: [] }, () => {
              this.getPaginatedData();
            });

            EventSystem.newNotification("notification.action", intl.messages["products.blacklist.success"]);
          } catch (error) {
            EventSystem.newNotification("notification.action", intl.messages["products.blacklist.error"]);
          }

          this.setState({
            manualBlacklistingIsInProgress: false,
          });
        }

        async whitelistProducts() {
          const {
            intl,
          } = this.props;
          const { account } = AccountContextBehaviorSubject$.getValue();
          await new Promise((resolve, reject) => this.setState({ manualWhitelistingIsInProgress: true }, resolve));

          const options = {
            method: "POST",
            body: JSON.stringify({
              productIds: this.state.products
                .filter(product => product.isSelected)
                .map(product => product.id),
            })
          };
          try {
            await this.props.auth.fetch(`/api/data/whitelistProducts?accountId=${account._id}`, options);
            this.setState({ products: [] }, () => {
              this.getPaginatedData(0);
            });

            EventSystem.newNotification("notification.action", intl.messages["products.whitelist.success"]);
          } catch (error) {
            EventSystem.newNotification("notification.action", intl.messages["products.whitelist.error"]);
          }

          this.setState({
            manualWhitelistingIsInProgress: false,
            whitelistPopupIsOpen: false,
          });
        }

        handleProductCategoryPopUpClose(id_category) {
          if (id_category) {
            this.switchCategories(id_category);
            this.setState({ viewAllProductFromBlacklistCategoriesMode: true });
          }
          this.setState({ isCategoriesPopupOpened: false }, () => this.resetData());
        }

        handleOpenCategoriePopup() {
          this.setState({ isCategoriesPopupOpened: true, viewAllProductFromBlacklistCategoriesMode: false, categories: this.state.categories.map(category => ({ ...category, isActive: false })) });
        }

        getRows(products) {
          const {
            classes,
            intl,
          } = this.props;
          return products ? products.map(product => {            
            let statusIcon;
            if(product.status === "active"){ statusIcon = <div className={classes.statusIcon} style={{ backgroundColor: "#C1EB8D" }}></div> ; } 
            if(product.status === "unavailable"){ statusIcon = <div className={classes.statusIcon} style={{ backgroundColor: "#FFAC89" }}></div> ; } 
            if(product.status === "blacklisted"){ statusIcon = <div className={classes.statusIcon} style={{ backgroundColor: "#FFAC89" }}></div> ; } 

            const getColumnKey = (columnIndexKey) => this.state.columns[columnIndexKey].key;
            const getRowValue = (columnIndexKey) => product[this.state.columns[columnIndexKey].key];

            const productCell = (
              <div className={classes.productCell}>
                <div className={classes.productImageContainer}><img alt="" src={product.image} className={classes.productImage} /></div>
                <div>{product.name}</div>
              </div>
            );

            return {
              id: product.id,
              isSelected: product.isSelected,
              values: this.state.cmsConfig.productCategory ?
                {
                  [getColumnKey(0)]: { displayedValue: productCell },
                  [getColumnKey(1)]: { displayedValue: getRowValue(1) },
                  [getColumnKey(2)]: { displayedValue: getRowValue(2) },
                  [getColumnKey(3)]: { displayedValue: intl.messages[`products.filters.status.${getRowValue(3)}`], startAdornment: statusIcon },
                  [getColumnKey(4)]: { displayedValue: !getRowValue(4) ? "-" : intl.messages[`products.unavailable.reason.${getRowValue(4)}`] },
                  [getColumnKey(5)]: { rawValue: product.turnover, displayedValue: product.turnover },
                } :
                {
                  [getColumnKey(0)]: { displayedValue: productCell },
                  [getColumnKey(1)]: { displayedValue: getRowValue(1) },
                  [getColumnKey(2)]: { displayedValue: intl.messages[`products.filters.status.${getRowValue(2)}`], startAdornment: statusIcon },
                  [getColumnKey(3)]: { displayedValue: !getRowValue(3) ? "-" : intl.messages[`products.unavailable.reason.${getRowValue(3)}`] },
                  [getColumnKey(4)]: { rawValue: product.turnover, displayedValue: product.turnover },
                }
            };
          }) : undefined;
        }

        selectRows(id) {
          const updatedRows = this.state.products.map(row => row.id === id ? { ...row, isSelected: !row.isSelected} : row);
          this.setState({
            products: updatedRows
          });
        }

        savePagination(pagination) {
          if (JSON.stringify(pagination) !== JSON.stringify(this.state.pagination)) {
            this.setState({ pagination });
          }
        };

        onSelectAll(ids) {
          const updatedRows = this.state.products.map(row => ({ ...row, isSelected: ids.length ? true : false}));
          this.setState({ products: updatedRows });
        };

        isProductBlacklisted() {
          return this.state.products.filter(product => product.isSelected).some(product => product.isBlacklisted);
        }

        isProductWhitelisted() {
          return this.state.products.filter(product => product.isSelected).some(product => product.isBlacklisted === null || !product.isBlacklisted);
        }

        render() {
          const { classes, intl, } = this.props;
          const bestProducts = this.renderBestProducts();
          const rows = this.getRows(this.state.products);

          const selectedRowsCount = rows ? rows.filter(row => row.isSelected).length : 0;

          return (
            <div className={classes.root}>
              <div className={classes.header}>
                <div className={classes.title} style={{ paddingTop: "25px" }}>
                  <FormattedMessage id="products.title" defaultMessage="Vos produits" />
                </div>
              </div>

              {!this.state.noCms && bestProducts.length !== 0 && (
                <div style={{ marginBottom: 32, }}>
                  <div style={{ display: "flex", justifyContent: "150px", }}>
                    <div
                      className={classes.subtitle}
                    >
                      {intl.messages["products.bestProducts.title"]}
                    </div>
                  </div>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ marginTop: "15px" }}
                  >
                    <List className={classes.list}>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={3}
                      >
                        {bestProducts}
                      </Grid>
                    </List>
                  </Grid>
                </div>
              )}

              {this.state.noCms && (
                <div style={{ marginBottom: 32, }}>
                  <div style={{ display: "flex", justifyContent: "150px", }}>
                    <div className={classes.subtitle}>
                      <FormattedMessage id="products.bestProducts.title" />
                    </div>
                  </div>
                  <img className={classes.fakeProduct} alt='Best products no CMS' src={localeImages[intl.locale]} />
                </div>
              )}

              <div
                className={classes.subtitle}
                style={{ marginBottom: 24 }}
              >
                {intl.messages["products.productsList.title"]}
              </div>

              {
                selectedRowsCount > 0
                  ? (
                    <SelectedRowsToolbar
                      selectedRowsCount={selectedRowsCount}
                      blacklistProducts={this.blacklistProducts}
                      openWithelistPopup={() => this.setState({ whitelistPopupIsOpen: true })}
                      manualBlacklistingIsInProgress={this.state.manualBlacklistingIsInProgress}
                      isProductBlacklisted={this.isProductBlacklisted}
                      isProductWhitelisted={this.isProductWhitelisted}
                    />
                  )
                  : (
                    !this.state.noCms && <Ctas
                      search={this.state.search}
                      statuses={this.state.statuses}
                      switchStatuses={this.switchStatuses}
                      statusReasons={this.state.statusReasons}
                      switchStatusReasons={this.switchStatusReasons}
                      switchCategories={this.switchCategories}
                      changeSearch={this.changeSearch}
                      exportProducts={this.exportProductsCSV}
                      exportProductsCSVLoading={this.state.exportProductsCSVLoading}
                      handleSubmitBlacklist={this.handleSubmitBlacklist}
                      handleSubmitLangSettings={this.handleSubmitLangSettings}
                      setIsCategoriesPopupOpened={value => this.setState({ isCategoriesPopupOpened: value })}
                      handleOpenCategoriePopup={this.handleOpenCategoriePopup}
                      categories={this.state.categories}
                      viewAllProductFromBlacklistCategoriesMode={this.state.viewAllProductFromBlacklistCategoriesMode}
                    />
                  )
              }
              <KiTable
                columns={this.state.columns}
                rows={rows}
                noCms={this.state.noCms}
                loading={this.state.productsLoading}
                onSelect={this.selectRows}
                onSelectAll={this.onSelectAll}
                emptyStateVariant={this.state.emptyStateVariant}
                loadMoreData={this.loadMoreData}
                resetData={this.resetData}
                total={this.state.total}
                limit={this.state.limit}
                filters={{ search: this.state.search, statuses: this.state.statuses }}
                account={this.state.account}
                paginationType="item"
                viewAllProductFromBlacklistCategorieMode={this.state.viewAllProductFromBlacklistCategoriesMode}
                onBackButtonClick={this.handleOpenCategoriePopup}
                pagination={this.state.pagination}
                savePagination={this.savePagination}
              ></KiTable>

              <Dialog
                isOpen={this.state.whitelistPopupIsOpen}
                open={() => this.setState({ whitelistPopupIsOpen: true })}
                close={() => this.setState({ whitelistPopupIsOpen: false })}
                title={intl.messages["products.whitelist.title"]}
                buttonsProps={
                  [
                    {
                      label: intl.messages["products.whitelist.cancel"],
                      onClick: () => this.setState({ whitelistPopupIsOpen: false }),
                      type: "tertiary",
                    },
                    {
                      label: intl.messages["products.whitelist.submit"],
                      onClick: async () => { await this.whitelistProducts(); this.setState({ whitelistPopupIsOpen: false }); },
                      type: "secondary",
                      loading: this.state.manualWhitelistingIsInProgress,
                    }
                  ]
                }
              >
                <Translation id="products.whitelist.description" />
              </Dialog>

              <PopupProductsCategories 
                isOpen={this.state.isCategoriesPopupOpened}
                close={this.handleProductCategoryPopUpClose}
                resetData={this.resetData}
              />
            </div>
          );
        }
      }
    )
  )
);

const SelectedRowsToolbar = withAuth(withStyles(styles)(injectIntl(class extends Component {
  render() {
    const {
      classes,
      selectedRowsCount,
      blacklistProducts,
      openWithelistPopup,
      manualBlacklistingIsInProgress,
      intl,
      isProductBlacklisted,
      isProductWhitelisted
    } = this.props;

    return (
      <div className={classes.selectedRowsToolbar}>
        <p>{selectedRowsCount} {intl.messages["products.selected"]}</p>
        { isProductWhitelisted() ? <CustomButton
          loading={manualBlacklistingIsInProgress}
          onClick={blacklistProducts}
        >
          <p>{intl.messages["customers.blacklist.button"]}</p>
        </CustomButton> : null }
        { isProductBlacklisted() ? <CustomButton
          onClick={openWithelistPopup}
        >
          <p>{intl.messages["customers.whitelist.button"]}</p>
        </CustomButton> : null}
      </div>
    );
  }
})));

const Ctas = withAuth(withStyles(styles)(injectIntl(class extends Component {
  render() {
    const {
      classes,
      intl,
      statuses,
      switchStatuses,
      switchCategories,
      changeSearch,
      handleOpenCategoriePopup,
      viewAllProductFromBlacklistCategoriesMode,
      categories,
      exportProducts,
      handleSubmitBlacklist,
      exportProductsCSVLoading
    } = this.props;

    const statusKeys = Object.keys(statuses);
    const statusNames = statusKeys.map(statusKey => intl.messages[`products.filters.status.${statusKey}`]);
    const selectedStatusKeys = statusKeys.filter(statusKey => statuses[statusKey].isActive);
    const selectedStatusNames = selectedStatusKeys.map(statusKey => intl.messages[`products.filters.status.${statusKey}`]);

    const activeCategories = categories.filter(category => category.isActive);

    return (
      <div className={classes.ctas}>
        <div className={classes.ctasGroup}>
          <div className={classes.select}>
            {
              <SelectPicker
                selectList={statusNames}
                selected={selectedStatusNames}
                action={switchStatuses}
                displayName="Statut"
              />
            }
          </div>
          <div className={classes.select}>
            { categories.length ? 
              <ScrollSelectPicker
                selectList={categories}
                selected={activeCategories}
                action={switchCategories}
                displayName={intl.messages["categories.select.categorie_select_default"]}
                itemNbToDisplay="15"
                shouldIncludeSearch={true}
              />
              : null 
            }
          </div>
         
          <KiTextField 
            placeholder={intl.messages["products.filters.search.placholder"]}
            type="whiteBg"
            autoComplete="off"
            name="search"
            handleChange={changeSearch} > 
          </KiTextField>

        </div>
        <div className={classes.ctasGroup}>
          { !viewAllProductFromBlacklistCategoriesMode ? <FormButtonTO
            type={"secondary"}
            size={"xs"}
            action={handleOpenCategoriePopup}
            label={intl.messages["button.categories"]}
          /> : null }
          {exportProductsCSVLoading ? <CircularProgress size={26} className={classes.loading} /> : null}
          <FormButtonTO
            type={"secondary"}
            size={"xs"}
            action={exportProducts}
            label={intl.messages["button.download"]}
            icon={(<i className={`${classes.ctaIcon} fal fa-arrow-alt-from-top`} style={{ fontSize: "20px" }} />)}
            isResponsive={true}
          />
          <FormButtonTO
            type={"secondary"}
            size={"xs"}
            action={handleSubmitBlacklist}
            label={intl.messages["button.import_blacklist2"]}
            icon={(<i className={`${classes.ctaIcon} fal fa-arrow-alt-to-top`} style={{ fontSize: "20px" }} />)}
            isResponsive={true}
          />
        </div>
      </div>
    );
  }
})));
