import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import { PageBody } from "../common/PageBody/PageBody";
import { PageBodyWrapper } from "../common/PageBodyWrapper/PageBodyWrapper";
import { CustomButton } from "../../../common/Button/CustomButton";
import { PrestashopDialog } from "./PrestashopDialog/PrestashopDialog.jsx";
import { MagentoDialog } from "./MagentoDialog/MagentoDialog.jsx";
import { ShopifyDialog } from "./ShopifyDialog/ShopifyDialog.jsx";
import { WoocommerceDialog } from "./WoocommerceDialog/WoocommerceDialog.jsx";

import { OtherCmsDialog } from "./OtherCmsDialog/OtherCmsDialog";
import { useAuth } from "../../../../hooks/useAuth";

import { styles } from "./ChooseCms.styles";
import prestashopLogo from "../../../../public/prestashopLogo.png";
import shopifyLogo from "../../../../public/shopifyLogo.png";
import adobeLogo from "../../../../public/adobeLogo.png";
import woocommerceLogo from "../../../../public/woocommerceLogo.png";

import { HTTPResponseStatusError } from "../../../AuthService";
import { EventSystem } from "../../../../eventsystem/EventSystem";
import { reloadAccountContext } from "../../../../contexts/context";

const CMSLogos = {
  Prestashop: prestashopLogo,
  Magento: adobeLogo,
  Shopify: shopifyLogo,
  Woocommerce: woocommerceLogo
};

const useStyles = makeStyles(styles);

export const ChooseCms = ({ onSubmit = async () => { } }) => {
  const [openedDialog, setOpenedDialog] = useState();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const auth = useAuth();

  useEffect(() => {
    auth.sendEventToAmplitude("choose_cms.see_page");
  }, [auth]);

  // CHECK IF MODULE IS CONNECTED
  useEffect(() => {
    if(openedDialog && openedDialog !== "Shopify") {
      const intervalId = setInterval(() => {
        auth.fetch("/api/account_management/checkModule", { method: "POST" }).then(async response => {
          if (response.success) {
            clearInterval(intervalId);
            await reloadAccountContext();
            await onSubmit();
          }
        });
      }, 2000);
    
      return () => clearInterval(intervalId);
    }
  }, [openedDialog, auth, onSubmit]);

  const onCmsClick = async (CMS) => {
    auth.sendEventToAmplitude(`choose_cms.click_${CMS.toLowerCase()}`);

    await auth.fetch("/api/account_management/updateAccount", {
      method: "POST",
      body: JSON.stringify({
        CMS: CMS.toLowerCase() !== "shopify" ? CMS.toLowerCase() : null,
        step_register: 2
      }),
    });
    await reloadAccountContext();

    setOpenedDialog(CMS);
  };

  const submit = async (CMS, payload) => {
    setLoading(true);
    if (CMS !== "shopify") {
      try {
        await auth.fetch("/api/account_management/updateAccount", {
          method: "POST",
          body: JSON.stringify({
            step_register: 3,
            CMS: CMS.toLowerCase() !== "shopify" ? CMS.toLowerCase() : null,
            woocommerce: CMS === "woocommerce" ? payload : null
          })
        });
        await reloadAccountContext();
        await onSubmit();
      } catch (error) {
        if(error instanceof HTTPResponseStatusError) {
          const response = await error.response.json();
          if(response.code === "ERR_CONNECT_CMS") {
            EventSystem.newNotification("notification.error", intl.formatMessage({ id: "error.module_not_detected" }, { cms: CMS }));
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      }
    }

    // GET URL_LOGO BY API
    if(CMS === "woocommerce") {
      try {
        await auth.fetch("/api/account_management/getWoocommerceLogo", {
          method: "GET",
        });
      } catch (error) {
        console.log("Error fetching logo");
      }
    }
    
    setLoading(false);
  };

  return (
    <PageBodyWrapper withStepper>
      <PageBody style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "80vw",
        minWidth: 900,
        maxWidth: 1025,
        minHeight: 600,
        maxHeight: 700,
      }}>
        <div className={classes.title}>
          <p className={"display2"}>{intl.messages["onBoarding.chooseCms.title"]}</p>
        </div>
        <h4 className={classes.subtitle}>
          {intl.messages["onBoarding.chooseCms.subtitle"]}
        </h4>

        <div className={classes.cmsCards}>
          {["Prestashop", "Shopify", "Magento", "Woocommerce"].map((CMS, idx) => (
            <div key={idx} className={classes.cmsCard} onClick={() => onCmsClick(CMS)}>
              <div className={classes.cmsCardContent}>
                <img alt="" src={CMSLogos[CMS]} className={classes.cmsCardContentImage}></img>
                <h4>{CMS}</h4>
              </div>
            </div>
          ))}
        </div>

        <div className={classes.buttonContainer}>
          <CustomButton
            type="tertiary"
            size='xs'
            onClick={() => {
              auth.sendEventToAmplitude("choose_cms.click_you_have_another_cms");
              setOpenedDialog("otherCms");
            }}
          >
            {intl.messages["accountSetup.stepCMS.choice.askForOtherCMS"]}
          </CustomButton>
        </div>

        <a
          href
          className="link"
          onClick={() => {
            auth.sendEventToAmplitude("choose_cms.click_skip_this_step");
            history.push("/");
          }}
        >
          {intl.messages["onBoarding.chooseCms.skip"]}
        </a>

      </PageBody>
      <PrestashopDialog
        open={openedDialog === "Prestashop"}
        handleClose={() => setOpenedDialog(null)}
        onSubmit={submit}
        loading={loading}
      />
      <MagentoDialog
        open={openedDialog === "Magento"}
        handleClose={() => setOpenedDialog(null)}
        onSubmit={submit}
        loading={loading}
      />
      <ShopifyDialog
        open={openedDialog === "Shopify"}
        handleClose={() => setOpenedDialog(null)}
        onSubmit={submit}
        loading={loading}
      />
      <WoocommerceDialog
        open={openedDialog === "Woocommerce"}
        handleClose={() => setOpenedDialog(null)}
        onSubmit={submit}
        loading={loading}
      />
      <OtherCmsDialog
        open={openedDialog === "otherCms"}
        handleClose={() => setOpenedDialog(null)}
      />
    </PageBodyWrapper>
  );
};
