import { palette } from "../../styles/palette.js";

export const styles = (theme) => ({
  list: {
    width: "100%",
  },
  messageSnackbar: {
    display: "flex",
    alignItems: "center",
  },
  buttonScnakbar: {
    marginRight: "30px",
  },
  welcome: {
    fontFamily: "Coolvetica",
    fontSize: "40px",
    letterSpacing: "1px",
    color: "#0E0E0F",
    marginLeft: 120,
    position: "relative"
  },
  welcome_title: {
    width: "auto",
    height: "27px",
    fontWeight: "600",
    fontFamily: "Coolvetica",
    fontSize: "20px",
    color: "#0E0E0F",
    marginBottom: "23px",
    marginTop: "13px",
    letterSpacing: "1px",
  },
  banner: {
    marginTop: "20px",
    height: "304px",
    display: "flex",
    borderRadius: "5px",
  },
  backgroundImage: {
    height: "100%",
    width: "100%",
    backgroundColor: "#0E0E0F",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    position: "absolute",
    top: "0",
    display: "flex",
    flexDirection: "column",
  },
  verticalline: {
    height: "222px",
    width: "1px",
    backgroundColor: "#e1e4e7",
    marginTop: "45px",
  },

  valid_area: {
    width: "106px",
    height: "26px",
    fontFamily: "Coolvetica Book",
    borderRadius: "14px",
    position: "absolute",
    bottom: "32px",
    paddingTop: "4px",
    fontSize: "14px",
    textAlign: "center",
  },
  grey_area: {
    backgroundColor: "#FFFFFF",
    border: "solid 1px #a5adb8",
    color: "#a5adb8",
  },
  green_area: {
    backgroundColor: "#b8e986",
  },

  green_top_area: {
    width: "106px",
    height: "26px",
    borderRadius: "14px",
    alignSelf: "center",
    textAlign: "center",
    paddingTop: "4px",
    backgroundColor: "#b8e986",
  },

  dateFormatInput: {
    width: "195px",
  },
  dateFormatInputRoot: {
    paddingBottom: "20px",
    fontSize: "40px",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "line",
    alignItems: "center",
  },
  calendar: {
    width: "fit-content",
    height: "auto",
  },
  home: {
    width: "100%",
    align: "center",
    height: "auto",
    overflow: "hidden",
    marginLeft: "64px",
    marginRight: "64px",
    paddingTop: "25px",
  },
  title: {
    paddingTop: "20px",
    fontFamily: "Coolvetica Light",
    fontSize: "19px",
    textAlign: "center",
    color: "#fff",
    marginBottom: "10px",
  },
  banner_icon: {
    height: "54px",
  },
  banner_wording: {
    fontFamily: "Coolvetica Book",
    fontSize: "16px",
    color: "#0e0e0f",
    marginTop: "35px",
    marginBottom: "11px",
  },
  sub_banner_wording: {
    fontFamily: "Coolvetica Light",
    fontSize: "12px",
    letterSpacing: "0.5px",
    textAlign: "center",
    color: "#838580",
    lineHeight: "1.3",
  },
  card_home: {
    height: "304px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    paddingTop: "56px",
  },
  card_home_text: {
    paddingTop: "4px",
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    textAlign: "center",
    color: "#0E0E0F",
  },
  owl_banner: {
    marginTop: "28px",
    width: "132px",
    alignSelf: "center",
  },
  cta: {
    backgroundColor: palette.black,
    padding: "10px 30px",
    border: 0,
    color: "white",
    borderRadius: 5,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    marginLeft: 8,
    fontFamily: "Coolvetica Book",
    "&:hover": {
      boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.25)",
      backgroundColor: "#4D4D4D",
    },
  },
  ctaIcon: {
    marginLeft: 8,
    marginTop: 5,
    fontSize: 20,
  },
  stats: {
    display: "flex",
    position: "relative",
  },
  statsCarousel: {
    width: "calc(25%)",
    maxWidth: 255,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  statsTable: {
    flexGrow: 1,
    overflow: "hidden",
    paddingLeft: "min(calc(255px + 20px), calc(25% + 20px))",
  },
  to_doCustomMailDone: {
    color: palette.lightgray,
    paddingTop: "4px",
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    textAlign: "center",
    border: "solid 1px #a5adb8",
  },
  shopifyCmsCelebrationDialogDescription: {
    padding: "40px",
  },
  subscriptionTitle: {
    marginBottom: "40px",
  },
  logoSuggestionBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  logoPickerImage: {
    width: "60%",
    height: "auto",
    margin: "40px",
    display: "block",
  },
  buttonSpacing: {
    width: "60%",
    display: "flex",
    justifyContent: "space-around"
  },
  loading: {
    margin: "20px auto",
    color: palette.black,
  },
  shopifyLogoSuggestionButtons: {
    margin: "20px"
  },
  secondaryText: {
    fontSize: "12px"
  },
  arrow: {
    position: "absolute",
    width: 84,
    top: -15,
    left: -120,
  },
  popupText:{
    fontSize:"16px",
    fontWeight:"600",
    margin:"10px 0 80px 0"
  },
  popupGifContent:{
    width: "260px",
    height: "200px",
    overflow: "hidden",
    position: "absolute",
    top: "72%",
    borderRadius: "10px",
    zIndex:1
  },
  popupGifContentMask:{
    width: "280px",
    height: "220px",
    position: "absolute",
    top: "72%",
    zIndex:2,
  },
  giphy:{
    position:"relative", 
    right:"14px",
    width:"300px"
  }

});
