import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormButtonTO } from "../../FormButtonTO.jsx";
import { FormattedMessage, injectIntl } from "react-intl";

import { palette } from "../../../styles/palette.js";
import { NoCms } from "../../common/EmptyState/NoCms";

const styles = (theme) => ({
  lower_banner_title: {
    fontFamily: "Coolvetica",
    fontSize: "40px",
    fontWeight: "normal",
    textAlign: "center",
    color: palette.white,
  },
  buttonFooter: {
    marginTop: 16,
    backgroundColor: "#BDC0FF",
    color: "black",
  },
});

export const UsecaseGetStarted = withStyles(styles)(
  injectIntl(
    class UsecaseGetStarted extends Component {
      render() {
        const {
          intl,
          classes,
          usecase,
          mainButtonAction,
          isActive,
          hasEnd,
          noCms,
        } = this.props;

        return (
          <div
            style={{
              alignItems: "center",
              backgroundColor: palette.black,
              width: "100%",
              height: "259px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className={classes.lower_banner_title}
              style={{ paddingTop: "58px" }}
            >
              <FormattedMessage
                id={`details.${usecase.name}.lower_banner.title`}
              />
            </div>
            {noCms ? (
              <NoCms ctaText={intl.messages["noCms.cta.continue"]} />
            ) : (
              <FormButtonTO
                type={"primary"}
                style={{ marginTop: 16 }}
                label={
                  intl.messages[
                    isActive
                      ? hasEnd
                        ? "button.modify"
                        : "button.deactivate"
                      : "button.activate_usecase"
                  ]
                }
                action={mainButtonAction}
              />
            )}
          </div>
        );
      }
    }
  )
);
