const LABEL = "columnImage";
const CATEGORY = "columnComponent:image";

export const columnImage = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {
    const { imageUrl, href, alt } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-image data-column-component="true" data-blocklabel="${LABEL}" align="center" href="{{href}}" alt="{{alt}}" padding-bottom="0" padding-left="0" padding-top="0" padding-right="0" src="{{imageUrl}}"></mj-image>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        variables: {
          imageUrl,
          href,
          alt,
        },
        editableProperties: [
          { key: "imageUrl", type: "image", label: "newsletter.rightMenu.property.selectedImage" },
          { key: "href", type: "link", label: "newsletter.rightMenu.property.imageLink" },
          { key: "alt", type: "string", label: "newsletter.rightMenu.property.alt" },
        ]
      }
    };
  },
});
