import React, { Component } from "react";

import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

import { TextField } from "../../../../../../common/Input/TextField.js";
import { Radio } from "../../../../../../common/Input/Radio/Radio.js";

import { styles } from "./styles.js";

export const LifetimeInput = withStyles(styles)(
  injectIntl(
    class LifetimeInput extends Component {
      render() {
        const {
          classes,
          intl,
          promoCodeTemplateWrapper,
          setPromoCodeTemplateWrapperProp,
          disabled,
          getError,
        } = this.props;

        return (
          <Radio
            legacy
            value="lifetime"
            disabled={disabled}
            disabledExplanation={
              intl.messages[
                "usecase.promoCodeTemplateEditor.temporality.lifetime.disabled.explanation"
              ]
            }
            label={
              <span className={classes.temporalityLabel}>
                <span>
                  {
                    intl.messages[
                      "usecase.promoCodeTemplateEditor.temporality.lifetime.0"
                    ]
                  }
                </span>
                <TextField
                  value={
                    promoCodeTemplateWrapper.temporalityChoices.lifetime
                      .lifetime
                  }
                  errorMessage={
                    getError("lifetime") && getError("lifetime").message
                  }
                  disabled={disabled}
                  onChange={(event) => {
                    let value =
                      event.target.value === ""
                        ? null
                        : Number(event.target.value);
                    if (value < 0) value = 0;
                    setPromoCodeTemplateWrapperProp(
                      "temporalityChoices.lifetime.lifetime",
                      value
                    );
                    setPromoCodeTemplateWrapperProp(
                      "selectedTemporalityChoiceKey",
                      value > 0 ? "lifetime" : "fixed"
                    );
                  }}
                  placeholder={"15"}
                  type="number"
                  min={0}
                  style={{ width: 192 }}
                  marginIsEnabled={false}
                />
                <span>
                  {
                    intl.messages[
                      "usecase.promoCodeTemplateEditor.temporality.lifetime.1"
                    ]
                  }
                </span>
              </span>
            }
          />
        );
      }
    }
  )
);
