import { palette } from "./palette";

export const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "100%",
    position: "fixed",
    overflowY: "scroll",
    margin: "0",
    //backgroundColor: palette.gray,
  },
  topbar: {
    minWidth: "800px",
    width: "100%",
    height: "64px",
    backgroundColor: palette.white,
    position: "relative",
  },
  mainwindow: {
    width: "800px",
    zIndex: "-1",
    position: "relative",
    backgroundColor: palette.white,
    borderRadius: "16px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "58px",
    marginBottom: "58px",
    padding: "50px",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },
  mainWindowCMS: {
    width: "800px",
    position: "relative",
    backgroundColor: palette.white,
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 1,
    boxShadow: "none",
  },
  logo: {
    marginLeft: "20px",
    marginTop: "10px",
    height: "2.5em",
  },
  leftWindowContainer: {
    paddingRight: "10%",
  },
  leftwindow: {
    margin: "0",
    alignItems: "center",
    height: "100%",
    borderRadius: "16px 0 0 16px",
    justifyContent: "center",
  },
  cssUnderline: {
    "&:after": {
      borderBottomColor: palette.purple,
    },
  },
  textfield: {
    color: palette.purple,
  },
  imgfield: {
    height: "22px",
    width: "22px",
  },
  wording: {
    color: palette.black,
    fontFamily: "Coolvetica",
    fontSize: "28px",
    letterSpacing: "-0.62px",
    lineHeight: "34px",
  },
  textinput: {
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    color: palette.black,
    letterSpacing: "-0.4px",
    lineHeight: "24px",
  },
  textFooterInput: {
    width: "170px",
    height: "26px",
    fontFamily: "Coolvetica Book",
    fontSize: "9.6px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.2px",
    textAlign: "center",
    color: "#a5adb8",
  },
  owlContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textinput_error: {
    height: "6px",
    fontFamily: "Coolvetica Book",
    fontSize: "10px",
    lineHeight: "0px",
    color: palette.red,
    padding: "0 0 0 0",
  },
  textinput_hyperlink: {
    color: palette.purple,
    textDecoration: "underline",
    fontFamily: "Coolvetica Book",
    cursor: "pointer",
  },
  keyarea: {
    color: palette.black,
    backgroundColor: palette.white,
    textAlign: "center",
    height: "32px",
    width: "288px",
    border: "1px solid #0E0E0F",
    borderRadius: "5px",
    borderColor: "palette.black",
  },
  connectorActive: {
    "& $connectorLine": {
      borderColor: palette.neutralLightGrey,
    },
  },
  connectorCompleted: {
    "& $connectorLine": {
      borderColor: palette.neutralLightGrey,
    },
  },
  connectorDisabled: {
    "& $connectorLine": {
      borderColor: palette.neutralLightGrey,
    },
  },
  connectorLine: {
    transition: theme.transitions.create("border-color"),
    // width: 'calc(100% + 24px)',
    // marginLeft: '-12px'
  },
  stepperLabel: {
    fontFamily: "Coolvetica Book",
    fontSize: "10px",
    lineHeight: "14px",
  },
  inputAdornment: {
    fontSize: "18px",
    color: "lightslategray",
  },

  ////////////////////// MODAL CGV //////////////////////////////////

  waitingModal: {
    width: "535px",
    padding: "0 90px 0 90px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "40px",
  },
  modalWording: {
    color: palette.black,
    fontFamily: "Coolvetica",
    fontSize: "40px",
    lineHeight: "34px",
    textAlign: "center",
    marginBottom: "20px",
  },
  modalSubWording: {
    color: "#0c0c0e",
    fontFamily: "Coolvetica Light",
    fontSize: "18px",
    lineHeight: "1.22",
    letterSpacing: "1.29px",
    fontWeight: "bold",
  },
  buttonWording: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    margin: "30px",
  },

  bigger: {
    fontFamily: "Coolvetica Book",
  },
});
