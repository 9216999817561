
import { useEffect, useMemo, useState, useContext, useRef, useCallback } from "react";
import { isEqual } from "lodash";

import { CustomButton } from "../../../common/Button/CustomButton";
import { Translation } from "../../../common/Translation";
import { useAuth } from "../../../../hooks/useAuth";
import { SelectedImage } from "./SelectedImage";
import { TemplateEditorContext } from "../../TemplateEditorContext";

import defaultDalleImage from "../../../../public/iaImageMenuDefaultDalle.png";
import defaultOutpaintingImage from "../../../../public/iaImageMenuDefaultOutpainting.png";
import defaultProductShowcaseImage from "../../../../public/iaImageMenuDefaultProductShowcase.png";
import defaultUnsplashImage from "../../../../public/iaImageMenuDefaultUnspash.jpeg";
import { AmbientImagesPromptForm } from "./AmbientImagesPromptForm";
import { reloadAccountContext } from "../../../../contexts/context";
import { GeneratingImagesLoader } from "./GeneratingImagesLoader";
import { ProductFocusGenerator, useCleanProductFocusPreviewGeneratedImageLibrary } from "./ProductFocusGenerator";

import classes from "./GeneratedImagesLibrary.module.scss";

export const GeneratedImagesLibrary = ({ type, setType, setSubMenu, selectedUrl, openCropMenu, onChange, scrollableContainerClass, component, editor }) => {

  const auth = useAuth();
  const [images, setImages] = useState([]);
  const [outpaintedImages, setOutpaintedImages] = useState([]);
  const [savedImages, setSavedImages] = useState([]);
  const [notif, setNotif] = useState(null);
  const [loadingCards, setLoadingCards] = useState(0);
  const [lastSelectedUrl, setLastSelectedUrl] = useState(null);
  const [abortController, setAbortController] = useState(null);
  const { templateId, templateInfo, setTemplateInfo } = useContext(TemplateEditorContext);
  const [nbGeneratedDalleImages, setNbGeneratedDalleImages] = useState(typeof templateInfo?.nbGeneratedDalleImages === "number" ? templateInfo?.nbGeneratedDalleImages : 2);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useCleanProductFocusPreviewGeneratedImageLibrary(type, editor);

  const changeType = (type) => {
    setIsFirstRender(true);
    setType(type);
    setImages([]);
  };

  useEffect(() => {
    auth.fetch("/account_management/creatorStudioImages").then(setSavedImages);
  }, [auth]);
  
  useEffect(() => {
    if (type === "ambientImages") {
      setLoadingCards(nbGeneratedDalleImages - images.length);
    }
  }, [images, nbGeneratedDalleImages, type]);

  const intervalRef = useRef();
  const fetchDalleAssets = useCallback(() => {
    auth.fetch(`/dalle/dalleAssets?templateId=${templateId}`, {}, {endpoint: "llm-hub"})
      .then(response => {
        setIsFirstRender(false);
        if (!isEqual(images, response)) {
          setImages(response);
        }
        if (!templateInfo.manualPrompt) {
          reloadAccountContext().then(({ account}) => {
            const template = account.mjmlTemplates.find(({ id }) => id === templateId);
            setTemplateInfo(prev => ({ ...prev, manualPrompt: template.manualPrompt }));
          });
        }
      }).catch(() => {});
  }, [auth, templateId, setTemplateInfo, templateInfo, images]);

  useEffect(() => {
    if (type !== "ambientImages" || images === null) {
      return ;
    }

    if (!intervalRef.current && loadingCards > 0) {
      const timing = images.length ? 1000 : 5000;
      intervalRef.current = setInterval(fetchDalleAssets, timing);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [auth, type, images, fetchDalleAssets, templateInfo, loadingCards]);

  useEffect(() => {
    if (type === "ambientImages" && isFirstRender) {
      fetchDalleAssets();      
    }
  }, [type, fetchDalleAssets, isFirstRender]);

  useEffect(() => {
    if (type === "productShowcaseImages") {
      auth.fetch(`/account_management/getOutpaintedImages?templateId=${templateId}`).then(response => {
        setOutpaintedImages(response);
        setImages(response.filter(({ type }) => type === "uniform_background").map(({ url }) => url));
        setIsFirstRender(false);
      });
    }
    if (type === "outpaintingImages") {
      auth.fetch(`/account_management/getOutpaintedImages?templateId=${templateId}`).then(response => {        
        const urls = response.filter(({ type }) => type === "outpainting").map(({ url }) => url);
        setImages(urls);
        setIsFirstRender(false);
      });
    }
    if (type === "unsplashImages") {
      auth.fetch(`/account_management/getUnsplashPhotos?templateId=${templateId}`)
        .then((response) => {
          sessionStorage.setItem("unsplashPhotos", JSON.stringify(response.pictures));
          setImages(response.pictures.map(({ url }) => url));
          setIsFirstRender(false);
        });
    }
  }, [type, templateId, auth]);

  useEffect(() => {
    if (notif) {
      const timeout = setTimeout(() => {
        setNotif(null);
      }, 4000);
      return () => clearTimeout(timeout);
    }
  }, [notif]);

  const typesList = useMemo(() => [
    {type: "ambientImages", img: defaultDalleImage},
    {type: "productShowcaseImages", img: defaultProductShowcaseImage},
    {type: "outpaintingImages", img: defaultOutpaintingImage},
    {type: "unsplashImages", img: defaultUnsplashImage},
  ], []);

  const saveImage = useCallback(async (image) => {
    const _type = type.replace("Images", "");
    await auth.fetch(`/account_management/addCreatorStudioImage?type=${_type}&url=${encodeURIComponent(image)}`, {
      method: "POST",
    });
    setSavedImages(prev => [...prev, { url: image, type: _type }]);
    setNotif({ value: "ambientImagesPromptForm.notif.savedImage" });
  }, [auth, type]);

  const deleteImage = async (image) => {
    await auth.fetch(`/account_management/deleteCreatorStudioImage?urls=${encodeURIComponent(image)}`, {
      method: "DELETE",
    });
    setSavedImages(prev => prev.filter(({ url }) => url !== image));
  };

  const onRegenerate = (nbImages) => {    
    setNbGeneratedDalleImages(nbImages);
    setLoadingCards(nbImages);
    setImages([]);

    setTimeout(() => {
      const container = document.getElementsByClassName(scrollableContainerClass)[0];
      const element = document.getElementsByClassName(classes.imagesListContainer)[0];
      container.scrollTo({ 
        top: element.getBoundingClientRect().top + container.scrollTop - 250,
        behavior: "smooth"
      });
    }, 300);
  };

  const onImageClick = (image) => {
    if (!images.includes(selectedUrl)) {
      setLastSelectedUrl(selectedUrl);
    }
    onChange(image);
  };

  const onLastImageClick = () => {
    onChange(lastSelectedUrl);
    setLastSelectedUrl(null);
  };

  return (
    <div>
      <div className={classes.header}>
        <h3 className={classes.title}><Translation id="generatedImagesLibrary.title" /></h3>
        <CustomButton
          type="secondary"
          size="xs"
          onClick={() => setSubMenu("myLibrary")}
        >
          <Translation id="myImageLibrary.title" />
        </CustomButton>
      </div>

      <SelectedImage
        selectedUrl={selectedUrl}
        lastSelectedUrl={lastSelectedUrl}
        onLastImageClick={onLastImageClick}
        openCropMenu={openCropMenu}
      />

      <div className={classes.separator} />      
      
      <div className={classes.typesContainer}>
        {typesList.map(elem => (
          <div
            key={elem.type}
            className={`${classes.typeContainer} ${elem.type === type ? classes.typeContainerSelected : ""}`}
            onClick={() => changeType(elem.type)}
          >
            <img src={elem.img} alt="" />
            <div className="infoMedium"><Translation id={`iaImageMenu.${elem.type}`} /></div>
          </div>
        ))}
      </div>

      {(images.length === 0 && loadingCards === 0 && !isFirstRender && !["ambientImages", "productShowcaseImages"].includes(type)) &&
        <div className={classes.emptyStateContainer}>
          <div><div className={`infoMedium ${classes.emptyStateBetaTag}`}><Translation id="editor.beta" /></div></div>
          <div className="info"><Translation id="generatedImagesLibrary.emptyState" /></div>
        </div>
      }

      {!["productShowcaseImages"].includes(type) &&
        <div className={classes.imagesListContainer}>
          {(images || []).map((image, idx) => (
            <div
              key={idx}
              onClick={() => onImageClick(image)}
              className={`${classes.imageContainer} ${image === selectedUrl ? classes.imageContainerSelected : ""}`}
            >
              <div className={classes.gradient} />
              <img
                alt=""
                src={image}
              />
              <i 
                className={`${savedImages.find(({ url }) => url === image) ? "fa-solid" : "fa-light"} fa-bookmark`}
                onClick={(e) => {
                  e.stopPropagation(); 
                  if (savedImages.find(({ url }) => url === image)) {
                    deleteImage(image);
                  } else {
                    saveImage(image);
                  }
                }}
              />
            </div>
          ))}
          {Array.from({ length: loadingCards }).map((_,index) => (
            <div key={index} className={`${classes.imageContainer} ${classes.loading}`}></div>  
          ))}
        </div>
      }

      {loadingCards > 0 && 
        <GeneratingImagesLoader
          minPercent={images.length * 100 / (images.length + loadingCards)}
          maxPercent={(images.length + 1) * 100 / (images.length + loadingCards)}
          abortController={abortController}
          setLoadingCards={setLoadingCards}
        />
      }
      {type === "ambientImages" && !!templateInfo.manualPrompt && 
        <AmbientImagesPromptForm 
          onRegenerate={onRegenerate} 
          isLoading={loadingCards > 0}
          setAbortController={setAbortController} 
          setNotif={setNotif}
        />
      }

      {type === "productShowcaseImages" && 
        <ProductFocusGenerator 
          onImageClick={onImageClick}
          savedImages={savedImages}
          component={component}
          scrollableContainerClass={scrollableContainerClass}
          outpaintedImages={outpaintedImages}
          templateId={templateId}
          selectedUrl={selectedUrl}
          saveImage={saveImage}
          deleteImage={deleteImage}
          setNotif={setNotif}
        />
      }

      {notif && 
        <div className={classes.notificationContainer}>
          <div className={classes.notificationLeftPartContainer}>
            {notif.type === "error" ?
              <i className="fa-solid fa-circle-xmark" style={{ color: "#DB2419" }} /> :
              <i className="fa-solid fa-circle-check" />
            }
            <span className={`textMedium ${classes.notif}`}><Translation id={notif.value} /></span>
          </div>
          <i className={`fa-solid fa-times ${classes.closeNotif}`} onClick={() => setNotif(null)} />
        </div>
      }
    </div>
  );
};