import { prepareDateText } from "./promoCode.tools";

const LABEL = "promoCode3";
const CATEGORY = "promoCode:wideHeadband";

export const promoCode3 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {
    const { promoCode, backgroundColor, fontFamily, textColor } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    if (!promoCode) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const shouldIncludeDate = promoCode?.mustShowDates;
    const shouldIncludeQuantity = promoCode?.quantity;

    const prepareValueAndDateMjml = () => {
      return `
          ${shouldIncludeDate ? `<mj-text font-family="${fontFamily}" color="${textColor}" data-readonly="true" line-height="1.2" padding-top="0" padding-top="0" padding-bottom="13px" align="center" font-size="14px" font-weight="500">{{promoCodeDate}}</mj-text>` : ""}        
          <mj-text font-family="${fontFamily}" color="${textColor}" data-readonly="true" padding-bottom="0" align="center">
            <span style="font-size: 40px; vertical-align: middle; font-weight:500">- {{value}} {{type}} ${promoCode.type !== "%" ? "{{taxes}}" : ""}</span>
            <span style="font-size: 14px; vertical-align: middle; padding-left:3px: line-height:1.2">{{onSite}}</span>
          </mj-text>
          <mj-text font-family="${fontFamily}" color="${textColor}" data-readonly="true" align="center" font-size="14px" padding-bottom="5px">
            <span style="font-size:14px;font-family:${fontFamily}">{{promoCodeText}}</span>
            <span style="font-size:16px;font-weight:600;font-family:${fontFamily}" data-promo-code-value="true">{{code}}</span>
          </mj-text>
          ${prepareQuantityMjml()}
        `;
    };

    const prepareQuantityMjml = () => {
      return shouldIncludeQuantity ?`
          <mj-text font-family="${fontFamily}" color="${textColor}" data-readonly="true" align="center" padding-top="15px" font-size="14px" font-weight="500">{{quantity}}</mj-text>
        ` : "";
    };

    const mjml = `
    <mj-wrapper data-gjs="kilibaBlock" data-promo-code-id="${promoCode._id}" background-color="${backgroundColor}" padding-top="10px" padding-bottom="10px" full-width="full-width">
      <mj-section>
        <mj-column padding-top="10px" padding-left="0px" padding-right="0px" vertical-align="middle" width="40%">
          <mj-image css-class="desktop-align-right" align="center" width="60px" padding-left="0px" padding-right="0px" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/tag-solid.png"></mj-image>
        </mj-column>
        <mj-column css-class="desktop-align-left" padding-right="0" padding-left="0px" padding-right="0px" css-class="" vertical-align="middle" width="60%">
          ${prepareValueAndDateMjml()}
        </mj-column>
      </mj-section>
    </mj-wrapper>
    `;

    const wordings = {
      promoCodeDate: prepareDateText(promoCode, false, false, false),
      promoCodeText: {
        fr: "avec le code : ",
        en: "with the code : ",
        es: "con el código : ",
        de: "mit dem Code : ",
        nl: "met code : ",
        it: "con il codice : ",
        pt: "com o código : ",
      },
      onSite: {
        fr: " sur le site",
        en: " on the website",
        es: " en el sitio",
        de: " auf der Website",
        nl: " op de site",
        it: " sul sito",
        pt: " no site",
      },
      code: {
        fr: promoCode?.code,
        en: promoCode?.code,
        es: promoCode?.code,
        de: promoCode?.code,
        nl: promoCode?.code,
        it: promoCode?.code,
        pt: promoCode?.code,
      },
      value: {
        fr: promoCode?.value,
        en: promoCode?.value,
        es: promoCode?.value,
        de: promoCode?.value,
        nl: promoCode?.value,
        it: promoCode?.value,
        pt: promoCode?.value,
      },
      quantity: {
        fr: `${promoCode?.quantity} coupons disponibles !`,
        en: `${promoCode?.quantity} coupons available !`,
        es: `${promoCode?.quantity} cupones disponibles !`,
        de: `${promoCode?.quantity} Gutscheine verfügbar !`,
        nl: `${promoCode?.quantity} coupons beschikbaar !`,
        it: `${promoCode?.quantity} coupon disponibili !`,
        pt: `${promoCode?.quantity} cupões disponíveis !`,
      },
      type: {
        fr: promoCode?.type,
        en: promoCode?.type,
        es: promoCode?.type,
        de: promoCode?.type,
        nl: promoCode?.type,
        it: promoCode?.type,
        pt: promoCode?.type,
      },
      taxes: {
        fr: promoCode.includesTaxes ? "TTC" : "HT",
        en: promoCode.includesTaxes ? "VAT included" : "VAT excluded",
        es: promoCode.includesTaxes ? "IVA incluido" : "IVA excluido",
        de: promoCode.includesTaxes ? "MwSt. enthalten" : "MwSt. ausgeschlossen",
        nl: promoCode.includesTaxes ? "BTW inbegrepen" : "BTW uitgesloten",
        it: promoCode.includesTaxes ? "IVA inclusa" : "IVA esclusa",
        pt: promoCode.includesTaxes ? "IVA incluída" : "IVA excluída",
      }
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        wordings,
        editableProperties: [
          { key: "promoCode", type: "promoCode" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.color" },
          { key: "textColor", type: "color", label: "newsletter.rightMenu.property.textColor", group: "newsletter.rightMenu.propertyGroup.color" },
        ],
      }
    };
  },
  mediaQueryStyle: `
  @media only screen and (min-width:480px) {
    .desktop-align-right {
      float: right !important;
    }
    .desktop-align-left td div {
      text-align: left !important;
    }
  }    
  `,
  style: `
  `
});