import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withAuth } from "../withAuth";
import { FormattedMessage, injectIntl } from "react-intl";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import { UsecaseCard } from "./UsecaseCard.js";
import { UsecaseAdder } from "./UsecaseAdder.js";
import { CreateUsecasePopup } from "./CreateUsecasePopup.js";
import { EventSystem } from "../../eventsystem/EventSystem.js";
import "../../styles/scss/Template.css";
import "../../styles/scss/Popup.css";

import { palette } from "../../styles/palette.js";
import { WaitingCmsModal } from "../AccountSetup/WaitingCmsModal.js";

import { freemiumUsecaseList } from "../../ressources/freemiumUsecaseList.js";
import { usecaseAIList } from "../../ressources/usecaseAIList.js";
import { MultipleUsecasePopup } from "./MultipleUsecasePopup";
import { Translation } from "../common/Translation";
import { AccountContextBehaviorSubject$ } from "../../contexts/context";
import { UsecaseSelectionBlock } from "../OnBoarding/OnBoardingPages/UsecaseSelection/UsecaseSelectionBlock";
import { EmptyState } from "../common/EmptyState/EmptyState";

const usecase_icons = {
  promotions: "fa-solid fa-percent",
  postVisits: "fa-solid fa-store-slash",
  "cart-plus": "fal fa-cart-plus",
  birthday: "fa-solid fa-cake-candles",
  anniversaryRegistration: "fa-solid fa-gift-card",
  abandonedCarts: "fa-solid fa-shopping-basket",
  "calendar-alt": "fal fa-calendar-alt",
  newProducts: "fa-solid fa-tag",
  crossSelling: "fa-solid fa-cart-plus",
  atom: "fal fa-atom",
  upselling: "fal fa-shopping-bag",
  bestClients: "fa-solid fa-award",
  welcome: "fa-solid fa-hand-wave",
  welcomeAi: "fa-solid fa-hand-wave",
  mothersDay: "fa-solid fa-flower-daffodil",
  endcontainment: "fal fa-door-open",
  winterSales: "fa-solid fa-snowflake",
  summerSales: "fa-solid fa-sun-bright",
  blackFriday: "fa-solid fa-bolt",
  newYear: "fa-solid fa-glass-cheers",
  backToSchool: "fa-solid fa-bus-school",
  valentinesDay: "fa-solid fa-calendar-heart",
  christmas: "fa-solid fa-tree-christmas",
  halloween: "fa-solid fa-jack-o-lantern",
  fathersDay: "fa-solid fa-mustache",
  ephemeris: "fa-solid fa-calendar-star",
  booster: "fa-solid fa-rocket-launch",
  sponsorship: "fa-solid fa-users",
  custom: "fa-solid fa-calendar-pen",
};

const styles = (theme) => ({
  root: {
    width: "calc(100% - 68px - 80px)",
    padding: "25px 64px",
  },
  title: {
    fontFamily: "Coolvetica",
    fontSize: "40px",
    paddingTop: 25,
    color: palette.black
  },
  subtitle: {
    marginBottom: 35,
    color: palette.neutralDarkGrey,
    marginTop: 10,
  },
  categoryTitle: {
    paddingBottom: 15,
    paddingTop: 30,
  },
  categorySubtitle: {
    fontSize: 16,
    color: palette.neutralDarkGrey,
  },
  list: {
    [theme.breakpoints.down("xl")]: {},
    paddingBottom: 30,
  },
  loading: {
    marginLeft: "calc(50% - 50px)",
    marginTop: "10%",
    color: palette.purple,
    minWidth: "100px",
    minHeight: "100px",
  },
  doubleCircle: {
    position: "absolute",
    top: 0,
    right: "10%",
    height: 160,
    marginLeft: -8,
  },
  oval: {
    position: "absolute",
    top: 30,
    left: 142,
    width: 90,
  },
  usecasesContainer: {
    background: palette.white,
    borderRadius: 20,
    padding: "40px 40px 10px",
    border: `1px solid ${palette.neutralLightGrey}`,
    display: "flex",
    flexDirection: "column",
  },
  filters: {
    marginBottom: 34,
    display: "flex",
    flexDirection: "row",
    gridGap: 40,
  },
  filter: {
    fontSize: 16,
    color: palette.neutralDarkGrey,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& span:first-child": {
      paddingTop: 5,
    },
    "& p:first-child": {
      paddingBottom: 3,
    },
    "&:hover": {
      color: palette.black,
    }
  },
  selectedFilter: {
    color: palette.black,
  },
  selectedFilterUnderline: {
    height: 6,
    borderRadius: 4,
    background: palette.accentRed200,
    width: "calc(100% + 5px)",
  },
  filterNumber: {
    marginLeft: 10,
    width: 30,
    height: 30,
    background: "#FFE1D3",
    color: palette.accentRed500,
    display: "inline-block",
    textAlign: "center",
    borderRadius: 5,
    lineHeight: "30px",
  },
  categories: {
    display: "flex",
    gridGap: 20,
    paddingBottom: 25,
    borderBottom: `1px solid ${palette.neutralLightGrey}`
  },
  categoryButton: {
    display: "flex",
    padding: "7px 20px",
    justifyContent: "center",
    alignItems: "center",
    background: palette.neutralLightGrey,
    color: palette.neutralDarkGrey,
    borderRadius: 30,
    fontSize: 14,
    cursor: "pointer",
    "&:hover": {
      color: palette.neutralDarkGrey,
      background: palette.accentRed50,
    }
  },
  categoryButtonSelected: {
    background: palette.accentRed400,
    color: palette.primaryWhite,
  },
  getStartedBlock: {
    borderRadius: 30,
    background: palette.primaryWhite,
    padding: "27px 25px 35px",
    marginBottom: 65,
  },
  getStartedBlockTitle: {
    marginBottom: 31,
  },
  getStartedBlockContent: {
    padding: "0 100px",
    minHeight: "fit-content",
    position: "relative",
  },
  emptyStateContainer: {
    marginTop: 30, 
  }
});

const CATEGORIES = [
  "conversion",
  "trafic",
  "reactivation",
  "events",
  "customs",
];

export const UsecaseList = withAuth(
  withStyles(styles)(
    injectIntl(
      class UsecaseList extends Component {
        constructor(props) {
          super(props);
          this.getUsecases = this.getUsecases.bind(this);
          this.updateDetails = this.updateDetails.bind(this);
          this.closeWaitingModal = this.closeWaitingModal.bind(this);
          this.renderUsecasesCategory = this.renderUsecasesCategory.bind(this);
          this.getSortedUsecasesCategories =
            this.getSortedUsecasesCategories.bind(this);
          this.refreshData = this.refreshData.bind(this);
          this.getFilterNumber = this.getFilterNumber.bind(this);
          this.onGetStartedUsecasesSelected = this.onGetStartedUsecasesSelected.bind(this);

          const initDayLaunch = new Date();
          initDayLaunch.setDate(initDayLaunch.getDate() + 1);
          this.state = {
            usecasesCategories: {
              conversion: [],
              trafic: [],
              reactivation: [],
              events: [],
              customs: [],
            },
            details: null,
            blacklist_done: true,
            loading: true,
            launch: false,
            blacklist: false,
            day_launch: initDayLaunch,
            useFreemiumV2: true,
            CMS: "",
            isCmsConnected: false,
            firstSyncModalIsOpen: false,
            createUsecasePopupIsOpen: false,
            betaFeatures: [],
            multipleUsecases: null,
            filter: "all",
            selectedCategories: [],
            showGetStarted: false,
            showEmpty: false,
            getStartedLoaded: false,
          };
        }

        async componentDidMount() {
          EventSystem.subscribe("usecase_details", this.updateDetails);
          await this.refreshData();
        }

        componentDidUpdate() {
          if (!this.state.loading) {
            if (CATEGORIES.every(category => !document.getElementById(category))) {
              !this.state.showEmpty && this.setState({ showEmpty: true });
            } else {
              this.state.showEmpty && this.setState({ showEmpty: false });
            }
          }
        }

        componentWillUnmount() {
          EventSystem.unsubscribe("usecase_details", null);
        }

        async refreshData() {
          const { account } = AccountContextBehaviorSubject$.getValue();
          this.getUsecases()
            .then((result) => {
              const usecasesCategories = this.getSortedUsecasesCategories(
                result.usecasesCategories
              );
              this.setState({
                usecasesCategories,
                loading: false,
                blacklist_done: result.blacklist_done,
              });
            })
            .catch((error) => {
              console.error(error);
            });
          try {
            if (
              account.usecases.length === 0 &&
              localStorage.getItem("firstSyncModal") !== "close"
            ) {
              this.setState({ firstSyncModalIsOpen: true });
            }
            this.setState({
              isCmsConnected: account.step_register >= 3,
            });
            this.setState({ CMS: account.CMS });
          } catch (error) {
            console.error(error);
          }
        }

        closeWaitingModal() {
          this.setState({ firstSyncModalIsOpen: false });
          localStorage.setItem("firstSyncModal", "close");
        }

        updateDetails(value) {
          if ((usecaseAIList.includes(`${value}Ai`) || usecaseAIList.includes(`${value}`)) && !this.state.multipleUsecases) {

            let usecase;
            let usecaseAI;

            Object.values(this.state.usecasesCategories).forEach(usecases => {
              usecase = usecases.find(({ name }) => name === (value.endsWith("Ai") ? value.slice(0, -2) : value)) || usecase;
              usecaseAI = usecases.find(({ name }) => name === (value.endsWith("Ai") ? value : `${value}Ai`)) || usecaseAI;
            });

            this.setState({multipleUsecases: [usecase, usecaseAI] });

          } else {
            this.setState({ details: value }, () => {
              EventSystem.publish(
                "url_switch",
                "/usecase_details?usecase=" + String(value)
              );
            });
          }

          
        }

        async getUsecases() {
          const { account } = AccountContextBehaviorSubject$.getValue();

          const freemiumV2StartDate = new Date(2022, 1, 4, 0, 0, 0);
          let blacklist_done = true;
          let isFreemiumUsecase = true;
          let creationDate = new Date();
          let endDateFreemium = null;
          let usecases_param = [];
          const fetch = this.props.auth.fetch;
          const { intl } = this.props;

          const betaFeatures = account.betaFeatures;
          creationDate = new Date(account.date_creation);
          blacklist_done = account.blacklist_done;
          if (account.endDateFreemium) {
            endDateFreemium = new Date(account.endDateFreemium);
          }
          if (account.usecases) {
            usecases_param = account.usecases;
          }
          if (
            account.billing_info &&
                new Date(account.billing_info.contract_start_date) >
                new Date()
          ) {
            isFreemiumUsecase = false;
          }
          const result = await fetch("/api/usecase/getAll", { method: "GET" });

          const usecasesCategories = {
            conversion: [],
            trafic: [],
            reactivation: [],
            events: [],
            customs: [],
          };
          const useFreemiumV2 =
                creationDate > freemiumV2StartDate || endDateFreemium !== null;
          result.usecases = result.usecases.filter(
            (usecase) =>
              usecase.name !== "custom" ||
                  !!usecases_param.find(function (element) {
                    return element.name === usecase.name;
                  })
          );
          this.setState({ useFreemiumV2: useFreemiumV2, betaFeatures });
          result.usecases.forEach(function (usecase) {
            // MANAGE SUBUSECASES
            if (
              usecase.parentUsecase === undefined ||
                  usecase.parentUsecase === null
            ) {
              const concernedUsecaseParam = usecases_param.find(function (
                element
              ) {
                return element.name === usecase.name;
              });
              if (concernedUsecaseParam === undefined) {
                usecase.active = false;
                usecase.launch_date = null;
                usecase.promo_code = null;
              } else {
                if (concernedUsecaseParam.launch_date !== null) {
                  usecase.active = true;
                  usecase.launch_date = concernedUsecaseParam.launch_date;
                } else {
                  usecase.active = false;
                  usecase.launch_date = null;
                }
                if (
                  "promo_code" in concernedUsecaseParam &&
                      concernedUsecaseParam.promo_code !== null
                ) {
                  usecase.promo_code = concernedUsecaseParam.promo_code;
                }
              }

              usecase.isFreemiumUsecase =
                    useFreemiumV2 || freemiumUsecaseList.includes(usecase.name)
                      ? true
                      : isFreemiumUsecase;
              usecase.icon = usecase_icons[usecase.name];
              usecase.description =
                    intl.messages[
                      "usecases.description." + String(usecase.name)
                    ];
              usecase.nameTranslated =
                    usecase.name !== "custom"
                      ? intl.messages["usecases.title." + String(usecase.name)]
                      : concernedUsecaseParam.customName;
              usecasesCategories[usecase.category].push(usecase);
            }
          });
          const res = {
            usecasesCategories,
            blacklist_done,
            isFreemiumUsecase,
          };
          return Promise.resolve(res);
        }

        getSortedUsecasesCategories(usecasesCategories) {
          for (const categoryKey in this.state.usecasesCategories) {
            const usecaseList = [];

            if (categoryKey === "events") {
              /* sort by date */
              usecasesCategories[categoryKey].sort((a, b) => {
                const getDate = elem => 
                  elem.name === "summerSales" ? new Date(new Date().getFullYear(), 5, 28) :
                    elem.name === "winterSales" ? new Date(new Date().getFullYear(), 0, 10) :
                      new Date(elem.templateDatas[0].useSettings.date);
                
                const date1 = getDate(a);
                const date2 = getDate(b);
                let diff1, diff2;

                do {
                  diff1 = date1.getTime() - Date.now();
                  date1.setFullYear(date1.getFullYear() + 1);
                } while (diff1 < 0);

                do {
                  diff2 = date2.getTime() - Date.now();
                  date2.setFullYear(date2.getFullYear() + 1);
                } while (diff2 < 0);
                return diff1 - diff2;
              });

            } else {
              /* sort alphabeticaly */
              usecasesCategories[categoryKey].sort(function (a, b) {
                return a.nameTranslated.localeCompare(b.nameTranslated, "fr", {
                  sensitivity: "base",
                }); //change when multilang is available
              });
            }

            /* sort coming soon usecases to bottom of list */
            usecasesCategories[categoryKey].sort(function (a, b) {
              return a.isComingSoon === b.isComingSoon
                ? 0
                : a.isComingSoon
                  ? 1
                  : -1;
            });

            /* put freemium usecases at top of the list */
            for (const usecase of usecasesCategories[categoryKey]) {
              usecaseList.push(usecase);
            }
            usecasesCategories[categoryKey] = usecaseList;
          }

          return usecasesCategories;
        }

        getFilterNumber(filter) {
          switch (filter) {
            case "active": return Object.values(this.state.usecasesCategories).reduce((acc, usecases) => acc + usecases.filter(({ active }) => active).length, 0);
            case "inactive": return Object.values(this.state.usecasesCategories).reduce((acc, usecases) => acc + usecases.filter(({ active, name }) => !usecaseAIList.includes(name) && !active).length, 0);;
            case "custom": return this.state.usecasesCategories.customs.length;
          }
        }

        renderUsecasesCategory(category) {
          const { classes } = this.props;
          const { usecasesCategories, loading } = this.state;
          const usecaseList = [];

          if (loading !== true) {

            const usecasesToNotRender = [];

            for (const usecase of usecasesCategories[category]) {
              if (usecaseAIList.includes(usecase.name)) {
                if (usecase.launch_date) {
                  usecasesToNotRender.push(usecase.name.slice(0, -2));
                } else {
                  usecasesToNotRender.push(usecase.name);
                }
              }
              if((this.state.CMS === "shopify" || this.state.CMS === "woocommerce" ) && !usecasesToNotRender.includes("birthday")){
                usecasesToNotRender.push("birthday");
              }
            }

            for (const usecase of usecasesCategories[category]) {
              const shopifyInProgress = null;

                if (!usecasesToNotRender.includes(usecase.name)) {
                  if (this.state.filter === "all" ||
                    (this.state.filter === "active" && usecase.active) ||
                    (this.state.filter === "inactive" && !usecase.active) ||
                    (this.state.filter === "custom" && category === "customs")
                  ) {
                    usecaseList.push(
                      <Grid item key={usecase.name}>
                        <UsecaseCard
                          category={category}
                          active={usecase.active}
                          promoCode={usecase.promo_code}
                          icon={usecase.icon}
                          title={usecase.nameTranslated}
                          description={usecase.description}
                          usecaseName={usecase.name}
                          isComingSoon={shopifyInProgress || usecase.isComingSoon}
                          launchDate={usecase.launch_date}
                          isFreemium={usecase.isFreemiumUsecase}
                          useFreemiumV2={this.state.useFreemiumV2}
                        />
                      </Grid>
                    );
                  }
                }
              
            }

            if (
              category === "customs" &&
              !usecasesCategories[category].find(
                ({ name }) => name === "custom"
              ) &&
              this.state.filter !== "active"
            ) {
              usecaseList.push(
                <Grid item key="customUsecase">
                  <UsecaseAdder
                    onClick={() =>
                      this.setState({ createUsecasePopupIsOpen: true })
                    }
                  />
                </Grid>
              );
            }

            if (!usecaseList.length) {
              return null;
            }

            return (
              <Fragment>
                <div>
                  <div className={`${classes.categoryTitle} display2`}>
                    <Translation
                      id={`usecases.${category}.title`}
                      defaultMessage=""
                    />
                  </div>
                  <div className={classes.categorySubtitle}>
                    <Translation
                      id={`usecases.${category}.subtitle`}
                      defaultMessage=""
                    />
                  </div>
                </div>
                <List className={classes.list} id={category}>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    lg={12}
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={3}
                  >
                    {usecaseList}
                  </Grid>
                </List>
              </Fragment>
            );
          }
        }

        onGetStartedUsecasesSelected(usecases) {

          this.setState({ getStartedLoaded: true });

          if (this.getFilterNumber("active") >= 5) {
            return ;
          }

          if (usecases.every(usecase => !!Object.values(this.state.usecasesCategories).flat().find(({ name }) => name === usecase.name).launch_date)) {
            return ;
          }

          this.setState({ showGetStarted: true });
        }

        render() {
          const { classes } = this.props;

          return (
            <div className={classes.root} style={{ display: !this.state.getStartedLoaded ? "none" : null}}>
              <div className>
                <div className="display1">
                  <FormattedMessage id="home.title" defaultMessage="" />
                </div>
                <div className={classes.subtitle}>
                  <Translation id="home.wording_1" defaultMessage="" />
                </div>
              </div>

              {!this.state.loading && 
                <div className={classes.getStartedBlock} style={{ display: !this.state.showGetStarted ? "none" : null}}>
                  <div className={classes.getStartedBlockTitle}>
                    <h2>
                      <FormattedMessage id="usecaseList.getStarted" defaultMessage="" />
                    </h2>
                  </div>
                  <div className={classes.getStartedBlockContent}>
                    <UsecaseSelectionBlock 
                      onUsecaseClick={this.updateDetails}
                      onUsecasesSelected={this.onGetStartedUsecasesSelected}
                      showImg
                      showStatus
                    />
                  </div>
                </div>
              }

              <div className={classes.filters}>
                {["all", "active", "inactive", "custom"].map(filter => (
                  <div className={`${classes.filter} ${this.state.filter === filter ? classes.selectedFilter : ""}`} onClick={() => this.setState({ filter })}>
                    <span>
                      <p><Translation id={`usecaseList.filter.${filter}`} defaultMessage="" /></p>
                      <div className={classes.selectedFilterUnderline} style={{ visibility: this.state.filter === filter ? "" : "hidden" }}/>
                    </span>
                    {filter !== "all" && <span className={classes.filterNumber}>{this.getFilterNumber(filter)}</span>}
                  </div>
                ))}
              </div>

              <div className={classes.usecasesContainer}>
                <div className={classes.categories}>
                  {["all", ...CATEGORIES].map(category => (
                    <span
                      className={`${classes.categoryButton} ${(category === "all" && this.state.selectedCategories.length === 0) || this.state.selectedCategories.includes(category) ? classes.categoryButtonSelected : ""}`}
                      onClick={() => this.setState({selectedCategories: category === "all" ? [] : category })}
                    >
                      <Translation id={`usecases.${category}.title`} />
                    </span>
                  ))}
                </div>

                {CATEGORIES.filter(category => !this.state.selectedCategories.length || this.state.selectedCategories.includes(category)).map(category => this.renderUsecasesCategory(category))}

                {this.state.showEmpty &&
                  <div className={classes.emptyStateContainer}>
                    <EmptyState variant={"noUsecases"} />
                  </div>
                }

              </div>

              {this.state.firstSyncModalIsOpen ? (
                <WaitingCmsModal closeWaitingModal={this.closeWaitingModal} />
              ) : null}

              {this.state.loading ? (
                <CircularProgress className={classes.loading} />
              ) : null}

              <CreateUsecasePopup
                isOpen={this.state.createUsecasePopupIsOpen}
                close={() => this.setState({ createUsecasePopupIsOpen: false })}
                onSave={() => this.updateDetails("custom")}
              />
              <MultipleUsecasePopup 
                isOpen={!!this.state.multipleUsecases}
                close={() => this.setState({ multipleUsecases : null} )}
                usecases={this.state.multipleUsecases}
              />
            </div>
          );
        }
      }
    )
  )
);
