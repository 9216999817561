import React, { Component } from "react";

import { injectIntl } from "react-intl";

import { RadioGroup } from "../../../../../common/RadioGroup.js";
import { Radio } from "../../../../../common/Input/Radio/Radio.js";
import { InputSection } from "../../../../../common/Input/InputSection.js";

export const TargetSection = injectIntl(
  class TargetSection extends Component {
    onChange(value) {
      const { promoCodeTemplateWrapper, setPromoCodeTemplateWrapperProp } =
        this.props;

      setPromoCodeTemplateWrapperProp("promoCodeTemplate.isGeneric", value);
      if (
        promoCodeTemplateWrapper.selectedTemporalityChoiceKey === "lifetime" &&
        value
      ) {
        setPromoCodeTemplateWrapperProp(
          "selectedTemporalityChoiceKey",
          "fixed"
        );
      }
      if (!value) {
        setPromoCodeTemplateWrapperProp("quantity", 1);
        setPromoCodeTemplateWrapperProp("mustUseQuantity", true);
      } else {
        setPromoCodeTemplateWrapperProp("quantity", null);
        setPromoCodeTemplateWrapperProp("mustUseQuantity", false);
      }
    }

    render() {
      const { intl, promoCodeTemplateWrapper } = this.props;

      return (
        <InputSection
          title={intl.messages["usecase.promoCodeTemplateEditor.target.title"]}
        >
          <RadioGroup
            value={promoCodeTemplateWrapper.promoCodeTemplate.isGeneric}
            onChange={(event) => this.onChange(event.target.value === "true")}
          >
            <Radio
              legacy
              value={true}
              label={
                intl.messages[
                  "usecase.promoCodeTemplateEditor.target.choices.generic"
                ]
              }
            />
            <Radio
              legacy
              value={false}
              label={
                this.props.cms === "shopify" ?
                  intl.messages["usecase.promoCodeTemplateEditor.target.choices.specific.shopify"]
                  :
                  intl.messages["usecase.promoCodeTemplateEditor.target.choices.specific.generic"]
              }
              warningText={
                !promoCodeTemplateWrapper.promoCodeTemplate.isGeneric
                  ? intl.messages[
                    "usecase.promoCodeTemplateEditor.target.choices.specific.warning"
                  ]
                  : null
              }
            />
          </RadioGroup>
        </InputSection>
      );
    }
  }
);
