
import { useState, useCallback, useEffect, useRef } from "react";
import { TemplateEditorPreviewPopup } from "./TemplateEditorPreviewPopup";
import { TemplateEditorSendMailPopup } from "./TemplateEditorSendMailPopup";
import { Tooltip } from "../Tooltip.js";


import classes from "./TemplateEditorToolbar.module.scss";
import { useIntl } from "react-intl";

const TOOLBAR_MARGIN = 25;

export const TemplateEditorToolbar = ({ editor, menuIsExpanded, wordings, language, isBlockActionsOpened, canvasContainerRef, variables }) => {

  const containerRef = useRef();

  const [selectedDevice, setSelectedDevice] = useState("desktop");
  const [toolbarPosition, setTopbarPosition] = useState({ top: 0, left: 0, withTranstion: true });
  const [previewIsOpened, setPreviewIsOpened] = useState(false);
  const [sendMailIsOpened, setSendMailIsOpened] = useState(false);
  const [hasUndo, setHasUndo] = useState(false);
  const [hasRedo, setHasRedo] = useState(false);
  const intl = useIntl();

  //Calcul toolbar position
  const calcToolbarPosition = useCallback((deviceId) => {
    const device = editor?.Devices.get(deviceId);
    if (device && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const scrollingContainer = canvasContainerRef.current.parentNode;
      const top = rect.top + (scrollingContainer?.scrollTop || 0);
      let left = rect.left + (isBlockActionsOpened ? 0 : TOOLBAR_MARGIN);
      if (device.attributes.width) {
        left += Math.round((containerRef.current.getBoundingClientRect().width - parseInt(device.attributes.width)) / 2);
      }      
      return { top, left };
    }
    return { top: 0, left: 0 };
  }, [editor, isBlockActionsOpened, canvasContainerRef]);

  //on device change (desktop / mobile)
  const onDeviceSelect = useCallback((deviceId) => {
    setTopbarPosition({ ...calcToolbarPosition(deviceId), withTranstion: true });
    const device = editor?.Devices.get(deviceId);
    if (device.attributes.width) {
      canvasContainerRef.current.style.width = device.attributes.width;
    } else {
      canvasContainerRef.current.style.width = "";
    }
  }, [editor, calcToolbarPosition, canvasContainerRef]);

  const selectDevice = (event, deviceId) => {
    event.stopPropagation();
    setSelectedDevice(deviceId);
    onDeviceSelect(deviceId);
  };

  //reposition toolbar on resize
  useEffect(() => {
    if (containerRef.current) {
      const observer = new ResizeObserver(() => {
        const newPos = calcToolbarPosition(selectedDevice);
        if (newPos.top !== toolbarPosition.top || newPos.left !== toolbarPosition.left) {
          setTopbarPosition({ ...calcToolbarPosition(selectedDevice), withTranstion: false });
        }
      });
      observer.observe(containerRef.current, { subtree: true, attributes: true, attributeOldValue: true, attributeFilter: ["style"], childList: true });
      return () => observer.disconnect();
    }
  }, [calcToolbarPosition, toolbarPosition, selectedDevice]);

  //Update hasRedo / hasUndo on editor update
  const onUpdate = useCallback(() => {
    if (editor) {
      setHasUndo(editor.UndoManager.hasUndo());
      setHasRedo(editor.UndoManager.hasRedo());
    }
  }, [editor]);

  useEffect(() => {
    if (editor) {
      editor.on("update", onUpdate);
      editor.on("undo", onUpdate);
      editor.on("redo", onUpdate);
      return () => {
        editor.off("update", onUpdate);
        editor.off("undo", onUpdate);
        editor.off("redo", onUpdate);
      };
    }
  }, [editor, onUpdate]);


  const undo = event => {
    event.stopPropagation();
    editor.UndoManager.undo();
  };

  const redo = event => {
    event.stopPropagation();
    editor.UndoManager.redo();
  };

  return (
    <div className={classes.container} ref={containerRef} style={{ "--toolbar-margin-default": `${TOOLBAR_MARGIN}px` }}>
      <div 
        className={`${classes.toolbar} ${toolbarPosition.withTranstion ? classes.toolbarTransition : ""}`} 
        style={{ "--toolbar-top": `${toolbarPosition.top}px`, "--toolbar-left": `${toolbarPosition.left}px` }}>

        <div className={classes.devices}>
          <Tooltip title={intl.messages["templateEditorToolbar.desktop"]}>
            <div 
              className={`${classes.device} ${classes.deviceDesktop} ${selectedDevice === "desktop" ? classes.deviceSelected : ""}`}
              onClick={event => selectDevice(event, "desktop")} 
            >
              <i className="fa-solid fa-laptop" />
            </div>
          </Tooltip>

          <Tooltip title={intl.messages["templateEditorToolbar.mobile"]}>
            <div
              className={`${classes.device} ${classes.deviceMobile} ${selectedDevice === "mobilePortrait" ? classes.deviceSelected : ""}`}
              onClick={event => selectDevice(event, "mobilePortrait")} 
            >
            
              <i className="fa-regular fa-mobile-notch" />
            </div>
          </Tooltip>
        </div>

        <div className={classes.buttonContainer}>
          <Tooltip title={intl.messages["templateEditorToolbar.preview"]}>
            <div className={classes.button} onClick={() => setPreviewIsOpened(true)}>
              <i className="fa-solid fa-eye" />
            </div>
          </Tooltip>

          <Tooltip title={intl.messages["templateEditorToolbar.emailText"]}>
            <div className={classes.button} onClick={() => setSendMailIsOpened(true)}>
              <i className="fa-solid fa-paper-plane" />
            </div>
          </Tooltip>
        </div>

        <div className={classes.undoRedoContainer}>
          <Tooltip title={intl.messages["templateEditorToolbar.undo"]}>
            <i onClick={undo} className={`fa-solid fa-rotate-left ${hasUndo ? classes.active : ""}`} />
          </Tooltip>
          <Tooltip title={intl.messages["templateEditorToolbar.redo"]}>
            <i onClick={redo} className={`fa-solid fa-rotate-right ${hasRedo ? classes.active : ""}`} />
          </Tooltip>
        </div>

      </div>
      <TemplateEditorPreviewPopup
        isOpen={previewIsOpened}
        close={() => setPreviewIsOpened(false)}
        menuIsExpanded={menuIsExpanded}
        editor={editor}
        wordings={wordings}
        selectedDevice={selectedDevice}
      />
      <TemplateEditorSendMailPopup 
        editor={editor}
        wordings={wordings}
        isOpen={sendMailIsOpened}
        close={() => setSendMailIsOpened(false)}
        language={language}
        variables={variables}
      />
    </div>
  );
};