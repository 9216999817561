import { palette } from "../../../styles/palette";

export const styles = {
  datePicker: {
    "& .MuiInputBase-root": {
      backgroundColor: palette.primaryWhite,
      borderRadius: 9,
      height: 40,
      "& input": {
        padding: "0 14px",
        fontSize: 14,
      },
      "& fieldset": {
        height: 40,
        border: "none",
      },
      "&.Mui-focused": {
        "&::before": {
          content: "\"\"",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          border: `1px solid ${palette.neutralDarkGrey}`,
          borderRadius: 9,
        }
      },
    },
  },
};
