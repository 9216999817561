import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";

import { Dialog } from "../../../common/Dialog";

import gif from "../../../../public/mail_box.gif";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "25px",
  },
  image: {
    marginTop: 50,
    maxWidth: 250,
    borderRadius: 10,
  }
});

const useStyles = makeStyles(styles);

export const UsecasePopupOpenAiLoading = injectIntl(({ isOpen, close, intl }) => {
  const classes = useStyles();
  
  return (
    <Dialog
      isOpen={isOpen}
      PaperProps={{ style: { borderRadius: 20 } }}
      close={close}
    >
      <div className={classes.container}>
        <span className={classes.title}>{intl.messages["usecasePopupOpenAiLoading.title"]}</span>
        <span className={classes.title}>{intl.messages["usecasePopupOpenAiLoading.subtitle"]}</span>
        <img className={classes.image} src={gif} alt="" />
      </div>
    </Dialog>
  );
});
