import { useState } from "react";
import { makeStyles } from "@material-ui/core";

import { Translation } from "../../common/Translation";
import { CustomButton } from "../../common/Button/CustomButton";
import { UsecasePopupSalesContact } from "../../usecase/Usecase/UsecasePopups/UsecasePopupSalesContact";

import image from "../../../public/arrow3.png";

const styles = () => ({
  title: {
    marginBottom: 10,
  },
  buttonContainer: {
    marginTop: 60,
    position: "relative",
    display: "inline-block",
  },
  arrow: {
    position: "absolute",
    height: 65,
    top: -40,
    right: -70,
    transform: "rotate(28.694deg)",
  }
});

const useStyles = makeStyles(styles);

export const SubscriptionAdaptPackageCustom = () => {
  
  const classes = useStyles();
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <div className={classes.container}>
        <h2 className={classes.title}><Translation id="subscriptionAdaptPackageCustom.title" /></h2>
        <div className="text"><Translation id="subscriptionAdaptPackageCustom.subtitle" /></div>
        <div className={classes.buttonContainer}>
          <img className={classes.arrow} src={image} alt="" />
          <CustomButton
            type="primary"
            size="md"
            onClick={() => setShowPopup(true)}
          >
            <Translation id="subscriptionAdaptPackageCustom.button" />
          </CustomButton>
        </div>
      </div>
      {showPopup ? (
        <UsecasePopupSalesContact
          variant="adaptPackage"
          closeSalesContactPopup={() => setShowPopup(false)}
        ></UsecasePopupSalesContact>
      ) : null}
    </>
  );
};
