import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../../../hooks/useAuth";

import { PageBody } from "../common/PageBody/PageBody";
import { PageBodyWrapper } from "../common/PageBodyWrapper/PageBodyWrapper";
import { TextFieldGroup } from "../../../common/TextFieldGroup";
import { TextField } from "../../../common/Input/TextField";
import { KiDropdown } from "../../../common/Input/KiDropdown";
import { CustomButton } from "../../../common/Button/CustomButton";

import { styles } from "./ShopInfo.styles";
import pickerLists from "../../../../ressources/pickerLists.json";
import picture from "../../../../public/shopInfo.png";
import { reloadAccountContext } from "../../../../contexts/context";

const useStyles = makeStyles(styles);

export const ShopInfo = ({ onSubmit }) => {
  const [shopName, setShopName] = useState("");
  const [isShopNameAcceptable, setShopNameAcceptable] = useState(true);
  const [activity, setActivity] = useState("");
  const [businessSector, setBusinessSector] = useState("");

  const classes = useStyles();
  const auth = useAuth();
  const intl = useIntl();

  const canSubmit = useMemo(() => !!shopName && !!activity && !!businessSector && isShopNameAcceptable, 
    [shopName, activity, businessSector, isShopNameAcceptable]
  );

  useEffect(() => {
    auth.sendEventToAmplitude("shop_infos.see_page");
  }, [auth]);

  const submit = useCallback(async () => {
    auth.sendEventToAmplitude("shop_infos.click_continue");

    await auth.fetch("/api/account_management/updateAccount", {
      method: "POST",
      body: JSON.stringify({
        company_name: shopName,
        business_sector: businessSector,
        activity,
        step_register: 2
      }),
    });
    await reloadAccountContext();

    onSubmit();
  }, [activity, businessSector, auth, onSubmit, shopName]);

  const handleSubmitShopName = (event) => {
    event.preventDefault();

    const specialCharRegex = /[<>&/]/;
    const match = specialCharRegex.test(event.target.value);

    setShopNameAcceptable(!match);
    setShopName(event.target.value);
  };

  return (
    <PageBodyWrapper>
      <PageBody style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 800,
        width: "80vw",
        maxWidth: 975,
        maxHeight: 852,
        padding: "90px 0"
      }}>
        <div className={classes.shopInfo}>
          <div className={classes.formContainer}>

            <div className={classes.title}>
              <p className={"display2"}>{intl.messages["onBoarding.shopInfo.title"]}</p>
            </div>

            <TextFieldGroup>
              <TextField
                dataCy="companyName"
                placeholder={intl.messages["field.companyName"]}
                value={shopName}
                onChange={event => handleSubmitShopName(event)}
                iconName={"user"}
                autoFocus
                error={!isShopNameAcceptable}
              />

              <KiDropdown
                dataCy="activity"
                placeholder={intl.messages["field.activity"]}
                value={activity}
                onChange={setActivity}
                options={pickerLists.activityList.map(elem => ({
                  value: elem,
                  label: intl.messages[`activityList.${elem}`],
                }))}
              />

              <KiDropdown
                dataCy="businessSector"
                placeholder={intl.messages["field.business_sector"]}
                value={businessSector}
                onChange={setBusinessSector}
                options={pickerLists.businessSectorList.map(elem => ({
                  value: elem,
                  label: intl.messages[`businessSectorList.${elem}`],
                }))}
              />

            </TextFieldGroup>

            <div className={classes.buttonContainer}>
              <CustomButton
                onClick={submit}
                disabled={!canSubmit}
              >
                {intl.messages["button.next"]}
              </CustomButton>
            </div>
          </div>

          <div className={classes.imageContainer}>
            <img className={classes.image} alt="" src={picture} />
          </div>

        </div>
      </PageBody>
    </PageBodyWrapper>
  );
};
