import { palette } from "../../../../styles/palette";
import { styles as stepperStyles } from "../Stepper.styles.js";

export const styles = {
  step: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: stepperStyles.stepper["& > *:not(:last-child)"].marginRight,
    },
    "&$active $title": {
      fontWeight: 500,
    },
    "&$done $separator": {
      backgroundColor: palette.primaryYellow200,
    },
  },
  done: {},
  active: {},
  inactive: {},
  title: {
    "&::after": {
      display: "block",
      content: "attr(data-content)",
      fontWeight: 700,
      height: 0,
      color: "transparent",
      overflow: "hidden",
      visibility: "hidden",
    },
  },
  separator: {
    width: 80,
    height: 2,
    backgroundColor: palette.neutralLightGrey,
  },
};
