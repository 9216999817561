import { useState, useEffect, useCallback, useRef } from "react";
import { useIntl } from "react-intl";
import ReactCrop, { makeAspectCrop, centerCrop } from "react-image-crop";

import { Dialog } from "../../common/Dialog";
import { CustomButton } from "../../common/Button/CustomButton";
import { Translation } from "../../common/Translation";

import { useAuth } from "../../../hooks/useAuth";

import "react-image-crop/dist/ReactCrop.css";
import classes from "./CropImagePopup.module.scss";

const defaultCropValue = {
  unit: "%",
  x: 25,
  y: 25,
  width: 50,
  height: 50
};

export const CropImagePopup = ({ editor }) => {

  const auth = useAuth();
  const intl = useIntl();
  const imageRef = useRef();
  const [component, setComponent] = useState(null);
  const [type, setType] = useState(null);
  const [crop, setCrop] = useState(defaultCropValue);
  const [ratio, setRatio] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const ratioList = [
    { name: intl.messages["cropImagePopup.custom"], value: null },
    { name: "1 : 1", value: 1 },
    { name: "4 : 3", value: 4 / 3 },
    { name: "16 : 9", value: 16 / 9 },
  ];

  const onCropImage = useCallback((props) => {
    setCrop(defaultCropValue);
    setRatio(null);
    setIsLoading(false);
    setComponent(props.component);
    setType(props.type);
  }, []);

  useEffect(() => {
    if (editor) {
      editor.on("cropImage", onCropImage);
      return () => {
        editor.off("cropImage", onCropImage);
      };
    }
  }, [editor, onCropImage]);

  const onSave = () => {
    const rect = imageRef.current.getBoundingClientRect();

    const cropValues = {
      unit: "%",
      x: Math.round(crop.x * 100 / rect.width),
      y: Math.round(crop.y * 100 / rect.height),
      width: Math.round(crop.width * 100 / rect.width),
      height: Math.round(crop.height * 100 / rect.height),
    };

    setIsLoading(true);
    auth.fetch("/account_management/cropImage", {
      method: "POST",
      body: JSON.stringify({
        src: component?.attributes.src,
        ...(crop.unit === "%" ? crop : cropValues),
        type,
      })
    }).then(url => {
      setIsLoading(false);
      component.set("src", url);
      editor.trigger("onCropEnd", { url, type });
      setComponent(null);
    });
  };

  const onRatioChange = (value) => {
    const rect = imageRef.current.getBoundingClientRect();

    if (value) {
      setCrop(centerCrop(makeAspectCrop({
        unit: "%",
        width: 50,
      }, value, rect.width, rect.height), rect.width, rect.height));
    }

    setRatio(value);
  };

  return (
    <Dialog
      isOpen={!!component}
      close={() => setComponent(null)}
      style={{ padding: "40px 50px" }}
      PaperProps={{ style: { borderRadius: 10 } }}
    >
      <div className={classes.container}>
        <div className={classes.cropContainer}>
          <ReactCrop crop={crop} onChange={c => setCrop(c)} aspect={ratio}>
            <img ref={imageRef} alt="" src={component?.attributes.src} />
          </ReactCrop>
        </div>
        <div className={classes.rightMenu}>
          <h4 className={classes.title}><Translation id="cropImagePopup.title" /></h4>
          <span className="textMedium"><Translation id="cropImagePopup.formats" /></span>
          <div className={classes.ratiosContainer}>
            {ratioList.map(elem => (
              <div
                key={elem.value}
                className={`${classes.ratio} ${ratio === elem.value ? classes.ratioSelected : ""}`}
                style={{ height: 70 / (elem.value || 1) }} 
                onClick={() => onRatioChange(elem.value)} 
              >
                {elem.name}
              </div>
            ))}
          </div>
          <div className={classes.buttonsContainer}>
            <CustomButton
              type="secondary"
              size="xs"
              onClick={() => setComponent(null)}
            >
              <Translation id="button.anulation" />
            </CustomButton>
            <CustomButton
              size="xs"
              onClick={onSave}
              loading={isLoading}
            >
              <Translation id="button.confirm" />
            </CustomButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};