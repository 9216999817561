import { makeStyles } from "@material-ui/core";

import { Dialog } from "../../../common/Dialog";
import { Translation } from "../../../common/Translation";
import { CustomButton } from "../../../common/Button/CustomButton";

import { palette } from "../../../../styles/palette";
import stripeLogo from "../../../../public/stripe.png";

const styles = () => ({
  container: {
    textAlign: "center",
    padding: "0px 102px",
  },
  subtitle: {
    marginTop: 15,
    marginBottom: 30,
    textAlign: "left",
  },
  card: {
    borderRadius: 20,
    border: `1px solid ${palette.neutralLightGrey}`,
    background: palette.neutralCards,
    padding: "30px 58px"
  },
  greyText: {
    color: palette.neutralDarkGrey,
  },
  previousPackage: {
    color: palette.neutralDarkGrey,
  },
  upToContainer: {
    lineHeight: "normal",
    marginTop: 15,
    marginBottom: 35,
  },
  buttonContainer: {
    marginBottom: 15,
  },
  cgu: {
    textAlign: "left",
    color: palette.neutralDarkGrey,
    marginTop: 35,
    lineHeight: "15px",
    marginBottom: 15,
    "& a": {
      color: palette.neutralDarkGrey,
    }
  },
  stripeLogo: {
    height: 20,
  },
  powered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
});

const useStyles = makeStyles(styles);

export const StripeUpgradePopup = ({ isOpen, close, subscription, _package, onUpgrade, upgradeLoading }) => {
  
  const classes = useStyles();
  
  const currency = subscription.Currency__c === "EUR" ? "€" : "$";

  if (!_package) {
    return ;
  }
  
  return (
    <Dialog
      isOpen={isOpen}
      close={close}
      PaperProps={{ style: { borderRadius: 40, maxWidth: 680 } }}
      style={{
        padding: "60px 60px 40px"
      }}
    >
      <div className={classes.container}>
        <div className="display3"><Translation id="stripeUpgradePopup.title" /></div>
        <div className={`textMedium ${classes.subtitle}`}><Translation id="stripeUpgradePopup.subtitle" /></div>

        <div className={classes.card}>
          <div className={classes.price}>
            <span className="display1">{subscription.interval === "month" ? _package.price : _package.price_annual}{currency}</span>
            <span className={`display3 ${classes.greyText}`}>/<Translation id={`subscriptionInfo.package.interval.${subscription.interval}`} /></span>
          </div>
          <div className={`text ${classes.previousPackage}`}>
            <span><Translation id="subscriptionInfo.package.lastPrice" /> {subscription.Price__c}{currency}/<Translation id={`subscriptionInfo.package.interval.${subscription.interval}`} /></span>
          </div>
          <div className={classes.upToContainer}>
            <span className="text"><Translation id="subscriptionRecommendedPackages.package.upTo" /> </span>
            <span className="textBold">{_package.upTo} <Translation id="subscriptionSlider.contacts"/></span>
          </div>
          <div className={classes.buttonContainer}>
            <CustomButton
              onClick={onUpgrade}
              loading={upgradeLoading}
            >
              <Translation id="button.upgrade" />
            </CustomButton>
          </div>
          <div className="info"><Translation id="stripeUpgradePopup.button.info" /></div>
        </div>

        <div className={classes.cgu}>
          <span className="info"><Translation id="stripeUpgradePopup.cgu" /> </span>
          <a href="https://www.kiliba.com/cgu" target="_blank" rel="noreferrer" className="linkSmall"><Translation id="stripeUpgradePopup.cgu.link" /></a>
        </div>
        <div className={`info ${classes.greyText} ${classes.powered}`}>
          <Translation id="stripeUpgradePopup.powered" />
          <img className={classes.stripeLogo} src={stripeLogo} alt="" />
        </div>
      </div>
    </Dialog>
  );
};