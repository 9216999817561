import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import classNames from "classnames";
import qs from "qs";

import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import { KiDropdown } from "../common/Input/KiDropdown";
import { KiTextField } from "../common/Input/KiTextField.js";

import { withAuth } from "../withAuth.js";
import { CustomButton } from "../common/Button/CustomButton";
import { EventSystem } from "../../eventsystem/EventSystem.js";
import Dropzone from "react-dropzone";
import { PopupTO } from "../PopupTO.js";

import imgTooltip from "../../public/blacklist_tooltip.png";
import imgProductsTooltip from "../../public/blacklist_product_tooltip.png";
import { palette } from "../../styles/palette.js";
import { Translation } from "../common/Translation.js";
import { AccountContextBehaviorSubject$ } from "../../contexts/context.js";


const styles = theme => ({
  root: {
    width: "100%",
    align: "center",
    alignItems: "center",
    marginBottom: "20px",
    marginLeft: "64px",
    paddingTop: "30px"
  },
  title: {
    fontFamily: "Coolvetica",
    fontSize: "40px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "-1px",
    color: palette.black
  },
  text: {
    fontFamily: "Coolvetica Book",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.38",
    letterSpacing: "normal",
    color: palette.black
  },
  editlogo: {
    color: palette.purple,
    backgroundColor: "none",
    fontFamily: "Coolvetica Book",
    fontSize: "11px",
    fontWeight: "600",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "transparent"
    }
  },
  formButton: {
    marginTop: "68px",
    [theme.breakpoints.up("lg")]: {
      marginLeft: "calc(50% - 130px)"
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "150px"
    }
  },
  dropZone: {
    width: "100%",
    maxWidth: "100%",
    minWidth: "260px",
    height: "100%",
    borderRadius: "5px",
    alignItems: "center",
    textAlign: "center",
    boxShadow: "none"
  },
  dropzone_wording: {
    color: palette.lightgray,
    fontSize: "17px",
    marginTop: "42px"
  },
  close_button: {
    zIndex: "1",
    position: "absolute",
    right: "32px",
    top: "32px",
    height: "auto",
    width: "auto",
    cursor: "pointer"
  },
  join_file: {
    position: "relative",
    margin: "auto",
    borderRadius: "5px",
    borderStyle: "solid",
    borderWidth: "1px",
    marginTop: "32px",
    textAlign: "center",
    minWidth: "260px",
    width: "fit-content",
    color: palette.lightgray,
    height: "5vh",

    justifyContent: "center",
    alignItems: "center",
  },
  truncateLongTexts: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis" // This is where the magic happens
  },
  tooltipImage: {
    zIndex: 1,
    position: "absolute",
    borderRadius: "7px",
  },
  blacklistContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "20px",
    marginBottom: "30px",
    marginTop: "10px",
    fontFamily: "Coolvetica Book",
    fontSize: "16px",
  },
  blacklistContent: {
    width: "300px",
  },

});

const baseStyle = {
  width: "calc(100% - 150px)",
  maxWidth: "1128px",
  minWidth: "260px",
  height: "200px",
  borderWidth: "2px",
  borderColor: palette.lightgray,
  borderStyle: "dashed",
  borderRadius: 5
};

export const Blacklist = withAuth(
  withStyles(styles)(
    injectIntl(
      class Blacklist extends Component {
        constructor(props) {
          super(props);
          this.uploadFile = this.uploadFile.bind(this);
          this.changeBlacklist = this.changeBlacklist.bind(this);
          this.onDrop = this.onDrop.bind(this);
          this.removeLocalBlacklist = this.removeLocalBlacklist.bind(this);
          this.closeBlacklist = this.closeBlacklist.bind(this);
          this.closeExampleImage = this.closeExampleImage.bind(this);

          const { blacklistType } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
          this.state = {
            file: "",
            blacklist_src: "",
            loading: false,
            maxSize: 1048576 * 50.5,
            isHidden: false,
            blacklistType: blacklistType,
            blackListReason: "",
            currentUser: undefined,
            customBlacklistReason: ""
          };
        }

        handleClick(e) {
          e.preventDefault();
          document.getElementById("blacklist_input").click();
        }

        onDrop(acceptedFiles, rejectedFiles) {
          const {
            intl,
          } = this.props;

          // Do something with files
          if (rejectedFiles.length !== 0) {
            const errorString = (rejectedFiles[0].size > this.state.maxSize)
              ? intl.messages["blacklist.drop.error.size"]
              : intl.messages["blacklist.drop.error.format"];
            EventSystem.newNotification("notification.action", errorString);
            return;
          }
          // Récupération du fichier
          const file = acceptedFiles[0];
          // Initialisation du reader
          const reader = new FileReader();
          const self = this;
          reader.onloadend = function () {
            self.setState({ file: file, blacklist_src: reader.result });
          };
          // Lecture du fichier
          reader.readAsDataURL(file);
        }

        async uploadFile(e) {
          const {
            intl,
            auth
          } = this.props;

          e.preventDefault();
          this.setState({ loading: true });

          const { account } = AccountContextBehaviorSubject$.getValue();
          const { file, blacklistType } = this.state;
          const user = await auth.getUser();
          this.setState({currentUser: user ? user.attributes.email : account.admin_user});

          const fileContent = await file.text();
          const lines = fileContent.split(/(\r\n|\r|\n)/);

          if (blacklistType === "products") {
            const ObjectIdRegex = /^[0-9]+$/;
            const separators = [";", ",", " "];
            let ids = [];
            while (separators.length && !ids.length) {
              const separator = separators.splice(0, 1);

              ids = lines
                .filter(line => line.split(separator)[0].match(ObjectIdRegex))
                .map(line => line.split(separator)[0]);
            }

            if (!ids?.length) {
              this.setState({ loading: false });
              EventSystem.newNotification("notification.action", intl.messages["blacklist.save.error"]);
              return;
            }

            this.props.auth.fetch(`/api/data/blacklistProducts?accountId=${account._id}`, {
              method: "POST",
              body: JSON.stringify({ productIds: ids }),
            }).then(() => {
              EventSystem.newNotification("notification.action", intl.messages["blacklist.save.success"]);
              this.setState({ loading: false });
              EventSystem.publish("url_switch", "/products");
            }).catch((error) => {
              console.error(error);
              this.setState({ loading: false });
              EventSystem.newNotification("notification.action", intl.messages["blacklist.save.error"]);
            });
          }

          if (blacklistType === "customers") {
            const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            const emails = lines.filter(line => line.trim().match(emailRegex));

            if (!emails?.length) {
              this.setState({ loading: false });
              return;
            }

            this.props.auth.fetch(`/api/data/blacklistCustomers?accountId=${account._id}`, {
              method: "POST",
              body: JSON.stringify({ emails : emails, reason: {reasonName: "blacklist."+this.state.blackListReason, source: "webapp", adminUser: this.state.currentUser, customtext:this.state.customBlacklistReason }}),
            }).then(() => {
              EventSystem.newNotification("notification.action", intl.messages["blacklist.save.success"]);
              this.setState({ loading: false });
              EventSystem.publish("url_switch", "/customers");
            }).catch((error) => {
              console.error(error);
              this.setState({ loading: false });
            });
          }
        }

        changeBlacklist(e) {
          if (!e) { return; }
          // Récupération du fichier
          const file = e.target.files[0];
          if (!file) { return; }
          // Initialisation du reader
          const reader = new FileReader();

          const self = this;
          reader.onloadend = function () {
            self.setState({ file: file, blacklist_src: reader.result });
          };
          // Lecture du fichier
          reader.readAsDataURL(file);
        }

        closeExampleImage() {
          this.setState({ isHidden: false });
        }

        removeLocalBlacklist(e) {
          this.setState({ file: "", blacklist_src: "" });
        }

        closeBlacklist(e) {
          const path = new URLSearchParams(window.location.search).get("blacklistType");
          EventSystem.publish("url_switch", path);
        }

        render() {
          const {
            classes,
            intl,
          } = this.props;

          const { blacklistType } = this.state;
          return (
            <div className={classes.root}>

              <div onClick={this.closeBlacklist} className={classes.close_button}>
                <i className="fal fa-times" style={{ fontSize: "24px" }}></i>
              </div>
              <div style={{ flex: "1", position: "relative", marginBottom: "23px", width: "auto" }}>
                <div className={classes.title}>
                  <FormattedMessage id={"blacklist.title"} defaultMessage="Email" />
                </div>
                <div className={classes.text} style={{ marginTop: "32px" }}>
                  <Translation id={"blacklist." + blacklistType + ".text"} defaultMessage="Email" />
                </div>
                <div className={classes.text} style={{ fontWeight: "600" }}>
                  <Translation id={"blacklist." + blacklistType + ".text1"} defaultMessage="Email" />
                  <i onClick={() => this.setState({ isHidden: true })} style={{ fontSize: "20px", marginLeft: "5px", cursor: "pointer" }} className="fal fa-info-circle"></i>
                  <PopupTO
                    open={this.state.isHidden}
                    handleClose={this.closeExampleImage}
                    width="400px"
                    align="center"
                    title="popup.title_csv_example"
                    form={<img src={(blacklistType === "customers") ? imgTooltip : imgProductsTooltip} style={{ borderRadius: "5px" }} alt="CSV file example"></img>}
                    borderRadius='5px'
                    close
                  />
                </div>

                <input type="file" hidden accept=".csv" id="blacklist_input" onChange={this.changeBlacklist} style={styles.exampleImageInput} />
                <div className={classes.text} style={{ alingItems: "center", width: "calc(100% - 168px)", height: "35px", minWidth: "260px", maxWidth: "1128px", marginBottom: "10px" }}>
                  {
                    (this.state.file === "")
                      ? (
                        <div className={classes.join_file} onClick={this.handleClick} style={{ display: "flex", height: "35px", cursor: "pointer", backgroundColor: "transparent", borderColor: "transparent" }}>
                          <i className="fal fa-paperclip" style={{ fontSize: "24px", color: palette.lightgray, transform: "rotate(-45deg)" }}></i>
                          <div>
                            <FormattedMessage id={"blacklist.input"} defaultMessage="Email" />
                          </div>

                        </div>
                      )
                      : (
                        <div
                          className={classes.join_file}
                          style={{ paddingTop: "10px", paddingLeft: "25px", paddingRight: "35px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "35px", alignItems: "center", backgroundColor: "white", borderColor: palette.lightgray, maxWidth: "200px" }}>
                          {this.state.file.name}
                          <i onClick={this.removeLocalBlacklist} className="fal fa-times-circle" style={{ position: "absolute", cursor: "pointer", right: "12px", fontSize: "24px", color: palette.purple }}></i>
                        </div>
                      )
                  }
                </div>
                <div style={{ maxWidth: "1128px", minWidth: "260px", width: "calc(100% - 168px)" }}>
                  <div className={classes.text} style={{ position: "relative", marginLeft: "50%", marginTop: "10px", marginBottom: "-10px" }}>{intl.messages["or"]}</div>
                </div>
              </div>
              <Dropzone maxSize={this.state.maxSize} accept=".csv" onDrop={this.onDrop} style={{ width: "fit-content", marginTop: "auto", minWidth: "260px" }}>
                {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => {
                  const styles = { ...baseStyle };
                  return (
                    <div
                      {...getRootProps()}
                      className={classNames("dropzone", { "dropzone--isActive": isDragActive })}
                      style={styles}
                    >
                      <input {...getInputProps()} hidden style={{ width: "fit-content" }} />
                      {
                        <Paper className={classes.dropZone}>
                          <i className="fal fa-upload" style={{ marginLeft: "auto", marginRight: "auto", marginTop: "30px", fontSize: "50px", color: palette.lightgray }}></i>
                          <div className={classes.dropzone_wording}><Translation id={"blacklist.dropzone"} defaultMessage="Email" /></div>
                        </Paper>
                      }
                    </div>
                  );
                }}
              </Dropzone>
              <div style={{ maxWidth: "1128px", width: "calc(100% - 150px)", display:"flex", flexDirection:"column", marginTop:"20px" }}>
                { window.location.search === "?blacklistType=customers" &&
                  <div className={classes.blacklistContainer}>
                    <div>
                      <Translation id="customers.blacklist.description.short" />
                    </div>
                    <div className={classes.blacklistContent}>
                      <KiDropdown
                        placement={"bottom-start"}
                        placeholder={intl.messages["editprofile.button.select"]}
                        value={this.state.blackListReason}
                        options={[
                          { value: "SubscriptionAjust", label: intl.messages["customers.filters.statusReason.blacklist.SubscriptionAjust"], },
                          { value: "CustomerRequest", label: intl.messages["customers.filters.statusReason.blacklist.CustomerRequest"], },
                          { value: "Custom", label: intl.messages["customers.filters.statusReason.blacklist.Custom"], },
                        ]}
                        onChange={(event) => this.setState({ blackListReason: event })}
                        variant="table"
                      />
                    </div>
                    <div className={classes.blacklistContent}>
                      {
                        this.state.blackListReason === "Custom" &&
                        <KiTextField
                          placeholder={intl.messages["customers.filters.custom.placeholder"]}
                          type="whiteBg"
                          name="custom_blacklist_reason"
                          handleChange={event => this.setState({ customBlacklistReason: event.target.value })}
                        />
                      }
                    </div>
                  </div>
                }
                <div style={{ display: "flex", position: "relative", alignItems: "center", justifyContent: "center" }}>

                  <CustomButton 
                    loading={this.state.loading} 
                    size="sm" 
                    onClick={this.uploadFile} 
                    disabled={this.state.file === "" || (window.location.search === "?blacklistType=customers" && this.state.blackListReason === "") || (window.location.search === "?blacklistType=customers" && this.state.blackListReason === "Custom") && this.state.customBlacklistReason === ""} 
                  >
                    <Translation id="button.import_blacklist"/>
                  </CustomButton>
                  
                </div>
              </div>
            </div>
          );
        }
      }
    )
  )
);
