import {processStarsMjml } from "./review.tools";
import { formatHtmlIntoShortText } from "../../../../../services/text.service";
const LABEL = "multiAvisverifies";
const CATEGORY = "review:avisverifies";

export const multiAvisverifies = ({ reviews, fontFamily, textColor, buttonTextColor, accentColor, dominantColor }) => ({
  label: LABEL,
  category: CATEGORY,
  block: () => {
    if (!reviews?.length) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${dominantColor}" padding-top="0" padding-bottom="0" full-width="full-width">
        <mj-section padding-bottom="0px" padding-top="0px">
          ${reviews.map(review => {
          const starMjml = processStarsMjml("AV", review.rating, "center");
          return `
            <mj-column width="33%" vertical-align="top">
              <mj-raw>
                <tr>
                  <td align="center">
                    <div style="text-align:center;padding-bottom:15px;padding-top:15px">
                      <span style="color:${buttonTextColor};background-color:${accentColor};display:inline-block;width:26px;height:14px;text-align:center;border-radius:40px;padding:15px 9px;box-sizing:initial;font-size:13px">
                        ${review.minimizedName}
                      </span>
                    </div>
                  </td>
                </tr>
              </mj-raw>

              <mj-image data-readonly="true"data-readonly="true" padding-bottom="5px" padding-top="0px" width="85px" align="center" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/avisverifies-logo.png"></mj-image>

              <mj-raw>
                <tr>
                  <td align="center">
                    <div style="text-align:center;padding-top:0px;padding-bottom:0px">
                      ${starMjml}
                      <span style="vertical-align:top;padding-left:8px;color:${textColor};font-weight:600;font-size:14px;line-height:14px;font-family:${fontFamily}">${review.rating}/5</span>
                    </div>
                  </td>
                </tr>
              </mj-raw>

              <mj-text data-readonly="true" align="center" font-family="${fontFamily}" color="${textColor}" padding-top="15px" padding-left="8px" padding-right="8px" font-size="12px" line-height="12px">${formatHtmlIntoShortText(review.text)}</mj-text>

            </mj-column>
        `; 
      })}
        </mj-section>
      </mj-wrapper>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: "review:avisverifies",
      activate: true,
      content: mjml,
    };
  },
});