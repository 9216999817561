import { useEffect, useState, forwardRef } from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Typewriter from "typewriter-effect";
import moment from "moment/min/moment-with-locales";

import { CustomButton } from "../../../Button/CustomButton";
import { HtmlCustomizerTextField } from "../HtmlCustomizerTextField/HtmlCustomizerTextField";
import { useAuth } from "../../../../../hooks/useAuth";

import { palette } from "../../../../../styles/palette";
import { htmlCustomizerTooltipStyleValues } from "../../common/HtmlCustomizerTooltip/htmlCustomizerTooltipStyleValues.js";
import { reloadAccountContext } from "../../../../../contexts/context";

const { inputVerticalPadding, inputHorizontalPadding } =
    htmlCustomizerTooltipStyleValues;

const styles = theme => ({
  root: {
    "& .Typewriter__cursor": {
      borderRight: `1px solid ${palette.anotherlightgray}`,
      borderLeft: `1px solid ${palette.anotherlightgray}`,
      background: palette.anotherlightgray,
      color: palette.anotherlightgray,
    },
  },
  loading: {
    textAlign: "center",
  },
  container: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    paddingTop: 20,
  },
  footerContainer: {
    position: "relative",
    zIndex: 2,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#F8F9FF",
    padding: "25px 0px 5px",
  },
  clock: {
    position: "absolute",
    top: 10,
    right: 10,
    color: palette.purple,
    cursor: "pointer",
    
  },
  historyContainer: {
    "&:hover": {
      "& $historyPopover": {
        display: "block",
      }
    }
  },
  borderFocus: {
    position: "absolute",
    top: `-${inputVerticalPadding}px`,
    left: `-${inputHorizontalPadding}px`,
    padding: `${inputVerticalPadding}px ${inputHorizontalPadding}px 0px`,
    margin: 0,
    width: "100%",
    outline: `1px solid ${palette.purple}`,
  },
  warning: {
    paddingTop: 25,
    fontSize: 13,
    color: palette.lightgray,
  },
  list: {
    userSelect: "none",
    position: "absolute",
    top: 30,
    right: 10,
    width: 275,
    background: palette.primaryWhite,
    boxShadow: "0px 3px 16px -5px rgba(0, 0, 0, 0.22)",
    borderRadius: 10,
    padding: 10,
    maxHeight: 300,
    overflowY: "scroll",
  },
  proposal: {
    fontSize: 14,
    lineHeight: "16px",
    maxHeight: 32,
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    overflow: "hidden",
    color: palette.neutralDarkGrey,
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  date: {
    paddingTop: 5,
    fontSize: 12,
    color: palette.lightgray,
    "&::first-letter": {
      textTransform: "capitalize",
    }
  },
  divider: {
    margin: "6px 0px",
    borderTop: `1px solid ${palette.neutralLightGrey}`,
  },
  listItem: {
    cursor: "pointer",
  },
  selected: {
    color: palette.black
  },
  historyPopover: {
    position: "absolute",
    top: "-55px",
    right: 0,
    width: "auto",
    display: "none"
  },
  historyPopoverContent: {
    backgroundColor: palette.black,
    padding: "10px 20px",
    display: "flex",
    alignItems: "start",
    gridGap: 5,
    color: palette.white,
    fontSize: 14,
    borderRadius: 7,
  },
  triangle: {
    position: "absolute",
    bottom: -20,
    right: 10,
    width: 0,
    height: 0,
    borderTop: `10px solid ${palette.black}`,
    borderBottom: "10px solid transparent",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
  },
});

const useStyles = makeStyles(styles);

export const HtmlCustomizerWordingAiGenerated = forwardRef(({
  isWordingsLoading,
  isFirstWordingGeneration,
  value,
  inputStyle,
  keywords,
  intl,
  onFirstGenerationEnds,
  style,
  onResize,
  originalPromptVersion,
  setGenerated,
  usecaseName,
  generatedWordingCreatedDate,
  language,
  history,
  hasError: hasErrorProp,
}, ref) => {
  const [hasError, setHasError] = useState(false);
  const [wasWordingsLoading] = useState(isWordingsLoading);
  const [loading, setLoading] = useState(false);
  const [writing, setWriting] = useState(false);
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const [proposals, setProposals] = useState([]);
  const [isListOpen, setIsListOpen] = useState(false);
  const [promptVersion, setPromptVersion] = useState();
  const [key, setKey] = useState(1);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [date, setDate] = useState(null);

  const classes = useStyles();
  const auth = useAuth();
  
  const loadingStrings = [
    intl.messages["htmlCustomizerWordingProposal.loading1"],
    intl.messages["htmlCustomizerWordingProposal.loading2"],
    intl.messages["htmlCustomizerWordingProposal.loading3"],
    intl.messages["htmlCustomizerWordingProposal.loading4"],
  ];

  useEffect(() => {
    moment.locale(intl.locale);
  }, [intl]);

  useEffect(() => {
    const observer = new MutationObserver(onResize);
    observer.observe(ref.current, { subtree: true, attributes: true, attributeOldValue: true, attributeFilter: ["style"], childList: true });
    return () => observer.disconnect();
  }, [ref, onResize]);

  useEffect(() => {
    if (proposals.length) {
      return ;
    }

    if (wasWordingsLoading && !isWordingsLoading && isFirstWordingGeneration) {
      onFirstGenerationEnds();
      setWriting(true);
    } else if (isFirstWordingGeneration) {
      setLoading(true);
      onFirstGenerationEnds();
      setWriting(false);
      setLoading(false);
    } else if (!wasWordingsLoading && !isWordingsLoading && !writing && !loading && generatedWordingCreatedDate) {
      const proposals = [{
        value,
        date: generatedWordingCreatedDate,
        promptVersion: originalPromptVersion,
      }, ...(history ? history.map(elem => ({
        value: elem.wording,
        date: new Date(elem.created),
        promptVersion: elem.promptVersion,
      })) : [] )]
        .sort((wording1, wording2) => wording2.date.getTime() - wording1.date.getTime());
      
      setSelectedIdx(proposals.findIndex(({ date }) => 
        date.getTime() === generatedWordingCreatedDate.getTime()));

      setProposals(proposals);
    }
  }, [
    isWordingsLoading,
    isFirstWordingGeneration,
    wasWordingsLoading,
    proposals,
    onFirstGenerationEnds,
    generatedWordingCreatedDate,
    value,
    writing,
    loading,
    originalPromptVersion,
    history
  ]);

  const _loading = isWordingsLoading || loading;

  const generateWording = async () => {
    setLoading(true);
    setIsEditorFocused(false);
    setIsListOpen(false);
    try {

      const response = await auth.fetch("/api/account_management/generateEmailProposal", {
        method: "POST",
        body: JSON.stringify({ usecase: usecaseName, language }),
      });
      await reloadAccountContext();
      
      setGenerated({
        wording: response.proposals[0],
        promptVersion: response.prompt_version,
        created: new Date(response.created * 1000),
      });
      setPromptVersion(response.prompt_version);
      setDate(new Date(response.created * 1000));
      setLoading(false);
      setWriting(true);
      setHasError(false);
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  const onEdit = value => setGenerated({
    wording: value,
    created: proposals[selectedIdx].date,
    promptVersion: originalPromptVersion,
  });

  const onSelectProposal = idx => {
    setGenerated({
      wording: proposals[idx].value,
      promptVersion: proposals[idx].promptVersion,
      created: proposals[idx].date,
    });
    setKey(prev => prev + 1);
    setIsListOpen(false);
    setSelectedIdx(idx);
  };

  const cleanString = string => string.replaceAll(/<[^>]*>/g, "").replaceAll(/&nbsp;/g, " ");

  const errorText = <div className={classes.loading}><FormattedMessage id="htmlCustomizerWordingProposal.error" />...</div>;
  const showError = hasErrorProp || hasError;

  return (
    <>
      <div
        className={classes.root}>
        {
          showError && errorText
        }
        {
          !showError && _loading && 
            <div className={classes.loading}>
              <Typewriter 
                options={{
                  strings: loadingStrings,
                  autoStart: true,
                  loop: true,
                  delay: 20,
                }}
              />
            </div>
        }
        {
          !showError && writing && 
            <div style={inputStyle}>
              <Typewriter 
                onInit={typewriter => {
                  typewriter.changeDelay(15)
                    .typeString(value)
                    .callFunction(() => {
                      setWriting(false);
                      setSelectedIdx(0);
                      setProposals(previous => [{
                        value,
                        date,
                        promptVersion: promptVersion || originalPromptVersion,
                      }, ...previous]);
                      setIsEditorFocused(true);
                    })
                    .start();
                }}
              />
            </div>
        }
        {
          !showError && !_loading && !writing && 
            <HtmlCustomizerTextField
              key={key}
              value={value}
              setValue={onEdit}
              style={inputStyle}
              keywords={keywords}
              onFocus={() => setIsEditorFocused(true)}
              onClickAway={() => { setIsEditorFocused(false); setIsListOpen(false); }}
              paddingBottom={Math.ceil(ref.current?.getBoundingClientRect().height)}
              disableBorder={isEditorFocused}
              autoCalcTooltipPosition
            />
        }
        <div
          ref={ref}
          style={{
            ...style,
            width: parseInt(style.width) + inputHorizontalPadding * 2,
            left: -inputHorizontalPadding,
          }}
          className={classes.container}>
          {
            isEditorFocused &&
              <div className={classes.footerContainer}>
                <div className={classes.button}>
                  <CustomButton 
                    onClick={generateWording}
                    type={"secondary"}
                    size={"xs"}
                  >
                    <FormattedMessage id="button.generateWording2" />
                  </CustomButton>
                </div>
                <span className={classes.warning}><FormattedMessage id="htmlCustomizerWordingAiGenerated.warning" /></span>
                {proposals.length > 1 && 
                  <div className={classes.historyContainer} >
                    <i onClick={event => {event.stopPropagation(); setIsListOpen(previous => !previous);}} className={`fa-regular fa-clock-rotate-left ${classes.clock}`} />
                    <div className={classes.historyPopover} /* ref={(ref) => (this.containerRef = ref)} onClick={this.handleClick} */>
                      <div className={classes.historyPopoverContent}>
                        <div style={{ width:"auto" }}>
                          <span>
                            {intl.messages["htmlCustomizerWordingAiGenerated.history.popover"]}
                          </span>
                        </div>
                        <div className={classes.triangle} />
                      </div>
                    </div>
                  </div>
                }
                {isListOpen &&
                  <div className={classes.list}>
                    {proposals
                      .map((proposal, idx) => (
                        <>
                          <div className={classes.listItem} onClick={() => onSelectProposal(idx)}>
                            <div className={`${classes.proposal} ${idx === selectedIdx ? classes.selected : ""}`}>
                              {cleanString(proposal.value)}
                            </div>
                            <div className={classes.date}>
                              {moment(proposal.date).fromNow()}
                            </div>
                            { idx !== proposals.length - 1 && 
                              <div className={classes.divider} />
                            }
                          </div>
                        </>
                      ))}
                  </div>
                }
              </div>
          }
        </div>
      </div>
      {
        isEditorFocused && 
        <div
          style={{height: style.top + ref.current?.getBoundingClientRect().height}}
          className={classes.borderFocus}>
        </div>
      }
    </>
  );
});