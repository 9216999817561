import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { EventSystem } from "../../../eventsystem/EventSystem.js";
import { Translation } from "../../common/Translation";

import { useIntl } from "react-intl";

export const KiDropzone = ({ setFile }) => {
  const intl = useIntl();

  const [maxSize, setMaxSize] = useState(1048576 * 50.5);// 50.5MB

  const onDrop = (acceptedFiles, rejectedFiles) => {

    if (rejectedFiles.length !== 0) {
      const errorString = (rejectedFiles[0].size > maxSize)
        ? intl.messages["blacklist.drop.error.size"]
        : intl.messages["blacklist.drop.error.format"];
      EventSystem.newNotification("notification.action", errorString);
      return;
    }
    // Récupération du fichier
    const fileTmp = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = function () {
      setFile(fileTmp);
    };
    reader.readAsDataURL(fileTmp);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} style={dropzoneStyle}>
      <input {...getInputProps()} />
      {
        <>
          <i className="fal fa-upload" style={{ fontSize: "50px", marginBottom:"40px" }}></i>
          <Translation id={"blacklist.dropzone"} defaultMessage="Email" />
        </>
      }
    </div>
  );
};

const dropzoneStyle = {
  border: "2px dashed #B5BDB8",
  borderRadius: "4px",
  padding: "30px 20px",
  textAlign: "center",
  color: "#B5BDB8",
  width:"80%",
  height:"140px",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  lineHeight: "0.8",
};

