import { cloneDeep } from "lodash";

import { getContentElementFromComponent } from "../../TranslationManager";

export const rewriteWordings = (prefix, wordings, mjml) => {

  const newWordings = {};
  Object.entries(wordings).forEach(([key, value]) => {
    const newKey = `${prefix}-${key}`;
    mjml = mjml.replaceAll(`{{${key}}}`, `{{${newKey}}}`);
    newWordings[newKey] = value;
  });
  
  return {
    wordings: newWordings,
    mjml,
  };
};

export const rewriteVariables = (prefix, variables, mjml) => {

  const newVariables = {};
  Object.entries(variables).forEach(([key, value]) => {
    const newKey = `${prefix}-${key}`;
    mjml = mjml.replaceAll(`{{${key}}}`, `{{${newKey}}}`);
    newVariables[newKey] = value;
  });
  
  return {
    variables: newVariables,
    mjml,
  };
};

export const extractWordingsFromMjTextComponent = (component, wordings, language) => {
  const props = component.get("script-props");
  const wordingId = props[0].wordingId;
  const wordingValue = props[0].wordingValue;
  const result = { ...(wordingValue || {}), ...cloneDeep(wordings[wordingId] || {}) };
  result[language] = getContentElementFromComponent(component.attributes.type, component.getEl()).innerHTML;
  return result;
};

export const makeTranslatable = (value) => {
  return {
    fr: value,
    en: value,
    es: value,
    de: value,
    nl: value,
    it: value,
    pt: value,
  };
};

export const extractVariable = (attribute, value, variables, language) => {

  if (attribute) {
    const result = { ...attribute.value,...variables[attribute.attributeId] };
    result[language] = value;
    return result;
  } else {
    return makeTranslatable(value);
  }
};
