import { Component } from "react";

import { CircularProgress } from "@material-ui/core";
import { injectIntl } from "react-intl";

import classes from "./SponsorshipLanding.module.scss";

import { SponsorshipLandingContent } from "./SponsorshipLandingContent/SponsorshipLandingContent.js";
import { withAuth } from "../withAuth.js";


export const SponsorshipLanding = withAuth(
  injectIntl(
    class SponsorshipLanding extends Component {
      constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);

        this.state = {
          accountId: null,
          sponsorId: undefined,
          promoCodeTemplates: [],
          accountEmailSettings: undefined,
          godsonEmailAddresses: [null, null, null],
          hasSubmitted: false,
          hasErrored: false,
          isPromoCodeLoading: true,
        };
      }

      async componentDidMount() {
        try {
          const queryParameters = new URLSearchParams(this.props.location.search);
          const accountId = queryParameters.get("accountId");
          const sponsorId = queryParameters.get("sponsorId");

          const promoCodeTemplates = [];

          try {
            const godsonPromoCodeTemplate = await this.props.auth.fetch(
              `/public/getPromoCodeTemplate?accountId=${accountId || ""}&templateName=sponsorship2`
            );
            promoCodeTemplates.push(godsonPromoCodeTemplate);
          } catch {
            promoCodeTemplates.push(null);
          }
  
          try {
            const sponsorPromoCodeTemplate = await this.props.auth.fetch(
              `/public/getPromoCodeTemplate?accountId=${accountId || ""}&templateName=sponsorship3`,
            );
            promoCodeTemplates.push(sponsorPromoCodeTemplate);
          } catch {
            promoCodeTemplates.push(null);
          }

          const { accountEmailSettings } = await this.props.auth.fetch(
            `/public/accountEmailSettings?accountId=${accountId || ""}`,
            { method: "GET" }
          );
  
          this.setState({
            accountId: accountId,
            sponsorId: sponsorId || null,
            promoCodeTemplates: promoCodeTemplates || null,
            accountEmailSettings: accountEmailSettings || null
          });
        } catch(_) {}

        this.setState({
          isPromoCodeLoading: false
        });
      }

      async submit() {
        const { accountId, sponsorId, godsonEmailAddresses } = this.state;

        try {
          const result = await this.props.auth.fetch(
            `/api/public/sponsorships?accountId=${accountId}`,
            {
              method: "POST",
              body: JSON.stringify({
                sponsorId,
                godsonEmailAddresses: godsonEmailAddresses
                  .filter((godsonEmailAddress) => godsonEmailAddress && godsonEmailAddress.trim()) // remove nullish values before sending
                  .map((godsonEmailAddress) => godsonEmailAddress.toLowerCase()) // prevent users for adding unwanted uppercases
              }),
            }
          );

          if (result.status < 200 || result.status > 300)
            throw new Error("Failed to save sponsorship");

          this.setState({ hasErrored: false, hasSubmitted: true });
        } catch (error) {
          console.error(error);
          this.setState({ hasErrored: true });
        }
      }

      render() {
        const { intl } = this.props;
        const {
          sponsorId,
          promoCodeTemplates,
          accountEmailSettings,
          godsonEmailAddresses,
          hasSubmitted,
          hasErrored,
          isPromoCodeLoading,
        } = this.state;

        return (
          <div className={classes.root}>
            {isPromoCodeLoading ? (
              <CircularProgress />
            ) : sponsorId &&
              promoCodeTemplates[0] &&
              promoCodeTemplates[1] &&
              accountEmailSettings ? (
                <SponsorshipLandingContent
                  accountEmailSettings={accountEmailSettings}
                  promoCodeTemplates={promoCodeTemplates}
                  godsonEmailAddresses={godsonEmailAddresses}
                  hasSubmitted={hasSubmitted}
                  hasErrored={hasErrored}
                  setGodsonEmail={(value, key) => {
                    const newGodsonEmailAddresses = JSON.parse(
                      JSON.stringify(godsonEmailAddresses)
                    );
                    newGodsonEmailAddresses[key] = value;
                    this.setState({
                      godsonEmailAddresses: newGodsonEmailAddresses,
                    });
                  }}
                  submit={this.submit}
                />
              ) : (
                <div className={classes.unavailableForm} id="unavailable">
                  {intl.messages["sponsorshipLanding.unavailable"]}
                </div>
              )}
          </div>
        );
      }
    }
  )
);
