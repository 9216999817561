import { withStyles } from "@material-ui/core";
import React, { Component } from "react";

const styles = {
  form: {
    display: "grid",
    gap: 15,
  },
};

class CustomTextFieldGroup extends Component {
  render() {
    const { classes, children } = this.props;

    return <div className={classes.form}>{children}</div>;
  }
}

export const TextFieldGroup = withStyles(styles)(CustomTextFieldGroup);
