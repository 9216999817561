import { makeStyles } from "@material-ui/core";
import { useIntl } from "react-intl";

import { styles } from "./StatsCarousel.styles";
import { palette } from "../../../styles/palette";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const useStyles = makeStyles(styles);

export function StatsCarousel(props) {
  const intl = useIntl();
  const classes = useStyles();
  const ref = useRef();

  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 4,
  });

  const onScrollButtonCallback = useCallback((direction) => {
    const increment = direction === "down" ? 1 : -1;
    setPagination({
      ...pagination,
      page: pagination.page + increment,
    });
  }, [pagination]);

  useEffect(() => {
    if (ref) {
      const observer = new ResizeObserver(() => {
        setPagination(prev => ({
          page: ref.current.getBoundingClientRect().height > 800 ? 0 : prev.page,
          rowsPerPage: ref.current.getBoundingClientRect().height > 800 ? 8 : 4,
        }));
      });
      observer.observe(ref.current, { subtree: true, attributes: true, attributeOldValue: true, attributeFilter: ["style"], childList: true });
      return () => observer.disconnect();
    }
  }, []);

  const cards = useMemo(() => [
    {
      title: intl.messages["card.title.ca"],
      icon: "money-check-dollar",
      value: props.stats.turnover,
      color: "accentRed",
    },
    {
      title: intl.messages["card.title.nb_order"],
      icon: "envelope-circle-check",
      value: props.stats.nb_order,
      color: "primaryYellow",
    },
    {
      title: intl.messages["card.title.avg_order"],
      icon: "basket-shopping",
      value: props.stats.avg_order,
      color: "accentPurple",
    },
    {
      title: intl.messages["card.title.emails_sent"],
      icon: "box-open-full",
      value: props.stats.send,
      color: "accentGreen",
    },
    {
      title: intl.messages["card.title.open_rate"],
      icon: "envelope-open",
      value: props.stats.open,
      color: "accentRed",
    },
    {
      title: intl.messages["card.title.clic_rate"],
      icon: "computer-mouse",
      value: props.stats.click,
      color: "primaryYellow",
    },
    {
      title: intl.messages["card.title.conversion_rate"],
      icon: "arrow-right-arrow-left",
      value: props.stats.conversion_rate,
      color: "accentPurple",
    },
    {
      title: intl.messages["card.title.unsubscribe_rate"],
      icon: "trash-xmark",
      value: props.stats.unsubscribe,
      color: "accentGreen",
    },
  ], [intl, props.stats]);

  function renderCards() {
    return cards
      .map((card, key) => {
        const iconStyle = {
          backgroundColor: palette[`${card.color}100`],
          color: palette[`${card.color}500`],
        };

        return (
          <div key={key} className={classes.card}>
            <h1 className={classes.value}>{card.value}</h1>
            <p className={`${classes.title} textMedium`}>{card.title}</p>
            <i className={`${classes.icon} fa-solid fa-${card.icon}`} style={iconStyle} />
          </div>
        );
      })
      .filter((card, key) => {
        return (
          key >= pagination.page * pagination.rowsPerPage &&
          key < (pagination.page + 1) * pagination.rowsPerPage
        );
      });
  }

  return (
    <div ref={ref} className={classes.carousel}>
      <div
        className={`${classes.scrollButton} ${pagination.page > 0 && pagination.rowsPerPage < 8 ? "" : classes.hidden}`}
        onClick={() => onScrollButtonCallback("up")}
      >
        <i className={"fa-solid fa-chevron-up"}></i>
      </div>
      <div className={classes.cards}>
        {renderCards()}
      </div>
      <div
        className={`${classes.scrollButton} ${pagination.page < Math.ceil(cards.length / 4) - 1 && pagination.rowsPerPage < 8 ? "" : classes.hidden}`}
        onClick={() => onScrollButtonCallback("down")}
      >
        <i className={"fa-solid fa-chevron-down"}></i>
      </div>
    </div>
  );
}
