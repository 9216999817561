import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";

import Grid from "@material-ui/core/Grid";
import CountUp from "react-countup";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import { palette } from "../../../styles/palette.js";
import { Translation } from "../../common/Translation.js";

const styles = (theme) => ({
  details_title: {
    fontFamily: "Coolvetica Book",
    fontSize: "40px",
    fontWeight: "600",
    textAlign: "center",
    color: palette.black,
  },
  details_subtitle: {
    fontFamily: "Coolvetica Book",
    fontSize: "20px",
    fontWeight: "600",
    color: palette.black,
  },
  details_wording: {
    fontFamily: "Coolvetica Book",
    fontSize: "16px",
    fontWeight: "normal",
    color: palette.darkergray,
    marginTop: "13px",
    width: "538px",
  },
  details_subwording: {
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    color: palette.darkergray,
  },
  card_stat_block: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card_stat: {
    fontSize: "77px",
    fontFamily: "Coolvetica Book",
    margin: "0",
    padding: "0",
    marginLeft: "35px",
    color: palette.black,
  },
  card_wording: {
    fontSize: "19px",
    textAlign: "center",
    fontFamily: "Coolvetica Book",
    marginBottom: "20px",
    color: palette.darkgray,
  },
  selectedTagButton: {
    margin: theme.spacing.unit,
    textTransform: "none",
    height: "35px",
    marginTop: "0",
    color: palette.primaryBlack,
    fontSize: "15px",
    paddingLeft: "19px",
    paddingRight: "19px",
    fontWeight: "normal",
    backgroundColor: palette.primaryYellow200,
    borderRadius: "17.5px",
    "&:hover": {
      backgroundColor: palette.primaryYellow200,
    },
  },
  tagButton: {
    margin: theme.spacing.unit,
    textTransform: "none",
    height: "35px",
    marginTop: "0",
    color: palette.primaryBlack,
    fontSize: "15px",
    paddingLeft: "19px",
    paddingRight: "19px",
    fontWeight: "normal",
    backgroundColor: palette.anothergray,
    borderRadius: "17.5px",
    "&:hover": {
      backgroundColor: palette.anothergray,
    },
  },
});

export const UsecaseOverview = withStyles(styles)(
  injectIntl(
    class UsecaseOverview extends Component {
      constructor(props) {
        super(props);
        this.state = {
          cardTabIndex: 0,
        };
      }

      render() {
        const { classes, usecase } = this.props;
        const cardTabIndex = this.state.cardTabIndex;
        const cardTabValue = usecase.stats[cardTabIndex].value;
        const cardTabPrefixKey = `details.${usecase.name}.content.card.tab${cardTabIndex}.statValue.prefix`;
        const cardTabSuffixKey = `details.${usecase.name}.content.card.tab${cardTabIndex}.statValue.suffix`;

        return (
          <div id="boost" style={{ padding: "100px 0" }}>
            <div
              className={classes.details_title}
              style={{ marginBottom: "70px" }}
            >
              <FormattedMessage
                id={`details.${usecase.name}.content.title.boost2`}
              />
            </div>

            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="flex-start"
            >
              {/* overview text */}
              <Grid item style={{ marginBottom: "30px" }}>
                <div className={classes.details_subtitle}>
                  <Translation
                    id={`details.${usecase.name}.content.subtitle.boost`}
                  />
                </div>
                <div className={classes.details_wording}>
                  <Translation
                    id={`details.${usecase.name}.content.wording.boost`}
                  />
                </div>
              </Grid>

              {/* overview stats */}
              <Grid item>
                {/* card */}
                <Paper
                  style={{
                    padding: "20px",
                    width: "455px",
                    borderRadius: "5px",
                    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
                    minHeight: "200px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div className={classes.card_stat_block}>
                    <i
                      style={{
                        color: palette.primaryYellow200,
                        fontSize: "60px",
                      }}
                      className={`fal fa-${usecase.stats[cardTabIndex].iconName}`}
                    ></i>
                    <div className={classes.card_stat} id="card_start">
                      <FormattedMessage
                        id={cardTabPrefixKey}
                      ></FormattedMessage>
                      <CountUp end={cardTabValue} />
                      <FormattedMessage
                        id={cardTabSuffixKey}
                      ></FormattedMessage>
                    </div>
                  </div>
                  <div className={classes.card_wording}>
                    <Translation
                      id={`details.${usecase.name}.content.card.tab${cardTabIndex}.description`}
                    />
                  </div>
                </Paper>

                {/* stats tab switch */}
                {usecase && usecase.stats.length > 1 ? (
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    spacing={4}
                    alignItems="flex-start"
                    style={{ marginTop: "28px" }}
                  >
                    {usecase.stats.map((stat, index) => (
                      <Grid item key={index}>
                        <Button
                          onClick={() => this.setState({ cardTabIndex: index })}
                          className={
                            cardTabIndex === index
                              ? classes.selectedTagButton
                              : classes.tagButton
                          }
                        >
                          <FormattedMessage
                            id={`details.${usecase.name}.content.card.tab${index}.button`}
                          />
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </div>
        );
      }
    }
  )
);
