const LABEL = "whiteSeparator";

export const whiteSeparator = ({ dominantColor }) => ({
  label: LABEL,
  block: () => {

    const mjml = `
      <mj-section data-gjs="kilibaBlock" background-color="${dominantColor}" full-width="full-width" padding-top="0" padding-bottom="0">
        <mj-column css-class="whiteSeparator"></mj-column>
      </mj-section>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: "separator:subSeparator",
      activate: true,
      content: mjml,
    };
  },
  style: `
    .whiteSeparator {
      height: 40px;
      width: 100%;
    }
  `
});