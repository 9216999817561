import { useState, useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import { useAuth } from "./../../../../hooks/useAuth";
import { CustomButton } from "./../../../common/Button/CustomButton";
import { Translation } from "./../../../common/Translation";
import { AccountContext, reloadAccountContext } from "../../../../contexts/context.js";
import { PromoCodeTemplateEditor } from "./../../../../components/usecase/Usecase/UsecasePopups/PromoCodeTemplateEditor/PromoCodeTemplateEditor.js";
import { TemplateEditorContext } from "../../TemplateEditorContext.jsx";
import { EventSystem } from "../../../../eventsystem/EventSystem.js";

import moment from "moment-timezone";
import classes from "./promoCodeMenu.module.scss";


export const PromoCodeOptionalMenu = ({ templateId, updateContent, content, block, editor, onNext, currentStep }) => {
  const [popupPromoCodeTemplateEditorIsOpen, setPopupPromoCodeTemplateEditorIsOpen] = useState(false);

  const intl = useIntl();
  const auth = useAuth();
  const { promoCode, reloadPromoCode } = useContext(TemplateEditorContext);
  const {account} = useContext(AccountContext);

  useEffect(() => {
    if (promoCode && promoCode._id !== content?.promoCode?._id && !content?.forceDelete) {
      updateContent({ promoCode });
    }
  }, [promoCode, updateContent, content, block]);

  const savePromoCodeTemplate = async (
    promoCodeTemplate,
    mustRemoveObsoletePromoCodes
  ) => {
    try {
      const newPromoCodeTemplate = JSON.parse(
        JSON.stringify(promoCodeTemplate)
      );

      await auth.fetch(
        `/api/usecase/editPromoCodeTemplate?templateName=newsletter&newsletterTemplateId=${templateId}`,
        {
          method: "POST",
          body: JSON.stringify({
            promoCodeTemplate: newPromoCodeTemplate,
          }),
        }
      );

      await auth.fetch("/api/account_management/editAccount", {
        method: "POST",
        body: JSON.stringify({
          mustRemoveObsoletePromoCodes: mustRemoveObsoletePromoCodes,
        }),
      });

      const newPromoCode = await reloadPromoCode();
      updateContent({ promoCode: newPromoCode, forceDelete: false });
      await reloadAccountContext();

      EventSystem.newNotification(
        "notification.action",
        promoCode ? intl.messages["usecase.promoCodeTemplateEditor.save.save"] : intl.messages["usecase.promoCodeTemplateEditor.save.create"]
      );

      if(currentStep && currentStep.type === "content") {
        onNext();
      }
    } catch (error) {
      console.error(error);
      EventSystem.newNotification(
        "notification.error",
        intl.messages["usecase.promoCodeTemplateEditor.save.error"]
      );
      throw new Error("Could not save promo code.");
    }
  };

  const deletePromoCodeTemplate = async (id) => {
    try {
      await auth.fetch(`/api/usecase/deletePromoCodeTemplate?id=${id}`, {
        method: "DELETE",
      });
      await reloadAccountContext();

      updateContent({ promoCode: null, forceDelete: true });
      
      const componentsToRemove = []; 
      editor.getComponents().at(0).onAll(component => {
        if (component.attributes.attributes["data-promo-code-id"]) {
          componentsToRemove.push(component);
        }
      });
      componentsToRemove.forEach(component => component.remove());
      editor.selectRemove(editor.getSelectedAll());
    } catch (error) {
      console.error(error);
      throw new Error("Could not delete promo code.");
    }
  };

  const getFormattedDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  return (
    <>
      {promoCode
        ? (
          <div className={classes.wrapper}>
            <div className={classes.spacedFlex}>
              <span className={classes.weight500}>{ promoCode.code }</span>
              <span className={classes.weight500}>-{ promoCode.value } {promoCode.type} {promoCode.type !== "%" ? promoCode.includesTaxes ? "TTC" : "HT" : ""}</span>
            </div>

            <div>
              { promoCode.dateFrom && promoCode.dateTo ? <span className={classes.marginBottom20}>Du {(getFormattedDate(promoCode.dateFrom))} Au {getFormattedDate(promoCode.dateTo)}</span> : null }
              { promoCode.dateFrom && !promoCode.dateTo ? <span className={classes.marginBottom20}>A partir du {(getFormattedDate(promoCode.dateFrom))}</span> : null }
              { promoCode.lifetime ? <span className={classes.marginBottom20}>{promoCode.lifetime} jours après l'envoi de l'email</span> : null }
              <CustomButton 
                type={"secondary"}
                size="xs"
                onClick={() => setPopupPromoCodeTemplateEditorIsOpen(true)}
              >
                <Translation id="button.edit_promo_code" />
              </CustomButton>
            </div>
          </div>
        ) :
        <div className={classes.buttonWrapper}>
          <CustomButton
            onClick={() => setPopupPromoCodeTemplateEditorIsOpen(true)}
            size="sm"
          >
            <Translation id="button.create_promo_code" />
          </CustomButton>
        </div>
      }

      {popupPromoCodeTemplateEditorIsOpen ? (
        <PromoCodeTemplateEditor
          isOpen={popupPromoCodeTemplateEditorIsOpen}
          close={() => setPopupPromoCodeTemplateEditorIsOpen(false)}
          account={account}
          promoCodeTemplate={promoCode}
          save={(promoCodeTemplate, mustRemoveObsoletePromoCodes) =>
            savePromoCodeTemplate(
              promoCodeTemplate,
              mustRemoveObsoletePromoCodes
            )
          }
          delete={deletePromoCodeTemplate}
          shouldDisplayFromDateOption={false}
          caller={"newsletter"}
        />
      ) : null}
    </>


  );
};