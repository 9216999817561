import { makeStyles } from "@material-ui/core";

import { styles } from "./KiPagination.styles";
import { useIntl } from "react-intl";

const useStyles = makeStyles(styles);

export function PageSelectorArrow(props) {
  const classes = useStyles();

  const classNames = [
    classes.pageSelector,
    classes.pageSelectorArrow,
  ];
  if (props.isDisabled) classNames.push(classes.disabled);

  const pageIncrement = props.direction === "left" ? -1 : 1;

  return (
    <div
      className={classNames.join(" ")}
      onClick={() => !props.isDisabled && props.onPageChange(props.pageKey + pageIncrement)}
    >
      <i className={`fas fa-chevron-${props.direction}`} />
    </div>
  );
}

function PageSelectorKey(props) {
  const classes = useStyles();

  const isSelected = props.pageKey === props.pageSelectorKey;

  const classNames = [
    classes.pageSelector,
    classes.pageSelectorKey,
  ];
  if (isSelected) classNames.push(classes.selected);
  return (
    <div
      className={classNames.join(" ")}
      key={props.pageSelectorKey}
      onClick={() => !isSelected && props.onClick()}
    >
      {props.pageSelectorKey + 1}
    </div>
  );
}

export function PageSelectorKeys(props) {
  const pageSelectorKeys = [
    props.pageKey - 1,
    props.pageKey,
    props.pageKey + 1
  ]
    .filter(pageKey => pageKey > -1 && pageKey <= props.maxPageKey);

  return pageSelectorKeys.map(pageSelectorKey => (
    <PageSelectorKey
      pageSelectorKey={pageSelectorKey}
      pageKey={props.pageKey}
      onClick={() => props.onPageChange(pageSelectorKey)}
    />
  ));
}

export function KiPagination(props) {
  const classes = useStyles();
  const intl = useIntl();

  const maxPageKey = props.count ? (Math.ceil(props.count / props.rowsPerPage) - 1) : undefined;

  return (
    <div className={classes.pagination}>
      {
        props.count
          ? (
            <p className={classes.page}>
              <span>{intl.messages["table.pagination.page"]} </span>
              <span>{props.pageKey + 1}-{maxPageKey + 1}</span>
            </p>
          )
          : null
      }
      <div className={classes.pageSelectors}>
        <PageSelectorArrow
          direction={"left"}
          isDisabled={props.pageKey === 0}
          pageKey={props.pageKey}
          onPageChange={props.onPageChange}
        />
        <PageSelectorKeys
          pageKey={props.pageKey}
          maxPageKey={maxPageKey}
          onPageChange={props.onPageChange}
        />
        <PageSelectorArrow
          direction={"right"}
          isDisabled={props.pageKey === maxPageKey || maxPageKey === undefined}
          pageKey={props.pageKey}
          onPageChange={props.onPageChange}
        />
      </div>
    </div>
  );
}
