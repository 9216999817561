import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { InformationsIcon } from "../InformationsIcon.js";
import { palette } from "../../../styles/palette.js";

const styles = (theme) => ({
  title: {
    color: palette.neutralDarkGrey,
    marginBottom: 16,
    display: "grid",
    gridAutoFlow: "column",
    gridGap: 8,
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

export const InputTitle = withStyles(styles)(
  class extends Component {
    render() {
      const { classes, title, helperText, titleStyle } = this.props;

      return (
        <h3 className={`textBold ${classes.title}`} style={titleStyle}>
          {title}
          {helperText ? <InformationsIcon text={helperText} /> : null}
        </h3>
      );
    }
  }
);
