import { extractVariable, extractWordingsFromMjTextComponent, makeTranslatable } from "../utils";
import { product1 } from "./product1";
import { product2 } from "./product2";
import { product3 } from "./product3";
import { product4 } from "./product4";
import { product6 } from "./product6";
import { product7 } from "./product7";

import { newProduct1 } from "./newProduct1";

import { multiProducts2 } from "./multiProducts2";
import { multiProducts3 } from "./multiProducts3";
import { multiProducts4 } from "./multiProducts4";

const previewProduct = {
  absolute_url: "",
  price: "XX,XX€",
  image_url: "https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/productImagePreview.png",
  title: {
    fr: "Nom de ton produit",
    en: "Name of your product",
    es: "Nombre de Tu producto",
    de: "Name deines Produkts",
    nl: "Naam van uw product",
    it: "Nome del prodotto",
    pt: "Nome do seu produto",
  },
  description: {
    fr: "Ceci est la description de ton produit.",
    en: "This is your product description.",
    es: "Esta es la descripción de Tu producto.",
    de: "Dies ist die Beschreibung deines Produkts.",
    nl: "Dit is de beschrijving van je product.",
    it: "Questa è la descrizione del vostro prodotto.",
    pt: "Esta é a descrição do seu produto.",
  }
};

const getPriceWithCurrency = (price, currency, currencyBefore) => {
  
  if (currencyBefore === true) {
    return `${currency || "€"}${price}`;
  }

  const _currency = (currency === "$" ? "" : "&nbsp;") + (currency || "€");
  return `${price}${_currency}`;
};

export const getPriceFromProduct = (product, currency, currencyBefore) => {
  if (product.price === previewProduct.price) {
    return previewProduct.price;
  }
  
  if (product.price !== product.price_with_generic_promotion_included) {
    return `<strike style="font-size:14px;color:grey;">${roundPrice(product.price)}</strike> ${getPriceWithCurrency(roundPrice(product.price_with_generic_promotion_included || product.price), currency, currencyBefore)}`;
  }
  
  return getPriceWithCurrency(roundPrice(product.price_with_generic_promotion_included || product.price), currency, currencyBefore);
};

export const getTitleTranslation = (language, title) => title[language] || title.fr || title.en || title.es || title.de || title.nl || "";

export const getDescriptionTranslation = (language, description) => description[language] || description.fr || description.en || description.es || description.de || description.nl || "";

export const getProductBlocks = ({ fontFamily, buttonTextColor, accentColor, textColor, dominantColor, templateInfo, buttonRadius, currency, secondaryColor, priceIsVisible, currencyBefore }) => {

  const product = previewProduct;
  const productHref = makeTranslatable("");
  const imageAlt = makeTranslatable("");
  const imageUrl = makeTranslatable(product.image_url);

  return [
    product6({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, priceIsVisible, currencyBefore }),
    product1({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, priceIsVisible, currencyBefore }),
    product2({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, priceIsVisible, currencyBefore }),
    product3({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, priceIsVisible, currencyBefore }),
    product4({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, priceIsVisible, currencyBefore }),
    product7({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, priceIsVisible, currencyBefore }),

    newProduct1({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl, imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, secondaryColor, priceIsVisible, currencyBefore }),

    multiProducts2({ products: [product], fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: [imageUrl, imageUrl], imagesHref: [productHref, productHref], imagesAlt: [imageAlt, imageAlt], buttonsHref: [productHref, productHref], buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }),
    multiProducts3({ products: [product], fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: [imageUrl, imageUrl], imagesHref: [productHref, productHref], imagesAlt: [imageAlt, imageAlt], buttonsHref: [productHref, productHref], buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }),
    multiProducts4({ products: [product], fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: [imageUrl, imageUrl], imagesHref: [productHref, productHref], imagesAlt: [imageAlt, imageAlt], buttonsHref: [productHref, productHref], buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }),

    multiProducts2({ products: [product], fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: [imageUrl, imageUrl, imageUrl], imagesHref: [productHref, productHref, productHref], imagesAlt: [imageAlt, imageAlt, imageAlt], buttonsHref: [productHref, productHref, productHref], buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }, 3),
    multiProducts3({ products: [product], fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: [imageUrl, imageUrl, imageUrl], imagesHref: [productHref, productHref, productHref], imagesAlt: [imageAlt, imageAlt, imageAlt], buttonsHref: [productHref, productHref, productHref], buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }, 3),
    multiProducts4({ products: [product], fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: [imageUrl, imageUrl, imageUrl], imagesHref: [productHref, productHref, productHref], imagesAlt: [imageAlt, imageAlt, imageAlt], buttonsHref: [productHref, productHref, productHref], buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }, 3),
  ];
};

export const extractContentFromProductComponent = (component, wordings, language, variables) => {

  const content = {};
  const imageComponents = component.findType("mj-image");
  const buttonComponents = component.findType("mj-button");
  const textComponents = [...component.findType("mj-text"), ...component.findType("readonly-mj-text")];
  const buttons = buttonComponents.filter(component => !component.attributes.attributes["data-text-id"]);

  const props = imageComponents[0].get("script-props");
  const imageTranslatableAttributes = props[0].translatableAttributes;

  const srcAttribute = imageTranslatableAttributes?.find(attr => attr.key === "src");
  const hrefAttribute = imageTranslatableAttributes?.find(attr => attr.key === "href");
  const altAttribute = imageTranslatableAttributes?.find(attr => attr.key === "alt");

  content.imageUrl = extractVariable(srcAttribute, imageComponents[0].attributes.attributes.src, variables, language);
  content.imageHref = extractVariable(hrefAttribute, imageComponents[0].attributes.attributes.href, variables, language);
  content.imageAlt = extractVariable(altAttribute, imageComponents[0].attributes.attributes.alt || "", variables, language);

  content.imagesUrl = [];
  content.imagesHref = [];
  content.imagesAlt = [];

  imageComponents.forEach(imageComponent => {

    const props = imageComponent.get("script-props");
    const imageTranslatableAttributes = props[0].translatableAttributes;

    const srcAttribute = imageTranslatableAttributes?.find(attr => attr.key === "src");
    const hrefAttribute = imageTranslatableAttributes?.find(attr => attr.key === "href");
    const altAttribute = imageTranslatableAttributes?.find(attr => attr.key === "alt");

    const imageUrl = extractVariable(srcAttribute, imageComponent.attributes.attributes.src, variables, language);
    const imageHref = extractVariable(hrefAttribute, imageComponent.attributes.attributes.href, variables, language);
    const imageAlt = extractVariable(altAttribute, imageComponent.attributes.attributes.alt || "", variables, language);

    content.imagesUrl.push(imageUrl);
    content.imagesHref.push(imageHref);
    content.imagesAlt.push(imageAlt);
  });

  const buttonProps = buttons[0].get("script-props");
  const buttonTranslatableAttributes = buttonProps[0].translatableAttributes;  

  const buttonHrefAttribute = buttonTranslatableAttributes?.find(attr => attr.key === "href");

  content.buttonHref = extractVariable(buttonHrefAttribute, buttons[0].attributes.attributes.href, variables, language);

  content.buttonColor = buttons[0].attributes.attributes["background-color"];
  content.buttonText = extractWordingsFromMjTextComponent(buttons[0], wordings, language);

  content.buttonsHref = [];
  content.buttonsColor = [];
  content.buttonsText = [];

  buttons.forEach(buttonComponent => {
    const buttonProps = buttonComponent.get("script-props");
    const buttonTranslatableAttributes = buttonProps[0].translatableAttributes;  

    const buttonHrefAttribute = buttonTranslatableAttributes?.find(attr => attr.key === "href");

    const buttonHref = extractVariable(buttonHrefAttribute, buttonComponent.attributes.attributes.href, variables, language);

    content.buttonsHref.push(buttonHref);
    content.buttonsColor.push(buttonComponent.attributes.attributes["background-color"]);
    content.buttonsText.push(extractWordingsFromMjTextComponent(buttonComponent, wordings, language));
  });

  content.productsName = [];
  content.pricesText = [];
  content.productsDescription = [];
  content.pricesIsVisible = [];

  textComponents.forEach(textComponent => {
    if (textComponent.attributes.attributes["data-text-id"].startsWith("productName")) {
      content.productName = extractWordingsFromMjTextComponent(textComponent, wordings, language);
      content.productsName.push(extractWordingsFromMjTextComponent(textComponent, wordings, language));
    }
    if (textComponent.attributes.attributes["data-text-id"] === "pricePromotionText") {
      content.pricePromotionText = extractWordingsFromMjTextComponent(textComponent, wordings, language);
    }
    if (textComponent.attributes.attributes["data-text-id"].startsWith("priceText")) {
      content.priceIsVisible = textComponent.attributes.attributes["css-class"] !== "priceHidden";
      content.pricesIsVisible.push(textComponent.attributes.attributes["css-class"] !== "priceHidden");

      content.priceText = extractWordingsFromMjTextComponent(textComponent, wordings, language);
      content.pricesText.push(extractWordingsFromMjTextComponent(textComponent, wordings, language));
    }
    if (textComponent.attributes.attributes["data-text-id"].startsWith("productDescription")) {
      content.productDescription = extractWordingsFromMjTextComponent(textComponent, wordings, language);
      content.productsDescription.push(extractWordingsFromMjTextComponent(textComponent, wordings, language));
    }
  });

  buttonComponents.forEach(buttonComponent => {
    if (buttonComponent.attributes.attributes["data-text-id"] === "tagText") {
      content.secondaryColor = buttonComponent.attributes.attributes["background-color"];
      content.tagText = extractWordingsFromMjTextComponent(buttonComponent, wordings, language);
    }
  });
  
  content.productId = component.attributes.attributes["data-id-product"];
  content.productIds = [];

  component.onAll(innerComponent => {
    if (innerComponent.attributes.attributes["data-id-product"]) {
      content.productIds.push(innerComponent.attributes.attributes["data-id-product"]);
    } 
  });
  
  content.backgroundColor = component.attributes.attributes["background-color"];

  if (content.productsName.length === 1) {
    content.products = null;
    content.product = { ...previewProduct, id_product: content.productId };
  } else {
    content.product = null;
    content.products = content.productIds.map((id) => ({ ...previewProduct, id_product: id }));
  }

  return content;
};

function roundPrice(price) {
  return parseFloat(price).toFixed(2);
};

export const multiProductsToMonoProduct = (content) => {
  content.product = content.products[0];
  content.imageHref = content.imagesHref[0];
  content.imageAlt = content.imagesAlt[0];
  content.imageUrl = content.imagesUrl[0];
  content.buttonHref = content.buttonsHref[0];
  content.buttonColor = content.buttonsColor[0];
  content.buttonText = content.buttonsText[0];
  content.productName = content.productsName[0];
  content.priceText = content.pricesText[0];
  content.productDescription = content.productsDescription[0];
  content.priceIsVisible = content.pricesIsVisible[0];
  return content;
};

export const monoProductToMultiProducts = (content) => {
  content.products = [content.product];
  content.imagesHref = [content.imageHref];
  content.imagesAlt = [content.imageAlt];
  content.imagesUrl = [content.imageUrl];
  content.buttonsHref = [content.buttonHref];
  content.buttonsColor = [content.buttonColor];
  content.buttonsText = [content.buttonText];
  content.productsName = [content.productName];
  content.pricesText = [content.priceText];
  content.productsDescription = [content.productDescription];
  content.pricesIsVisible = [content.priceIsVisible];
  return content;
};
