import { useCallback, useContext, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { TemplateEditorContext } from "../TemplateEditorContext";

export const useKilibaColumn = (editor, blocks) => {

  const intl = useIntl();

  const { language } = useContext(TemplateEditorContext);

  /* =============== Preview column managment ================ */

  const preview = useMemo(() => `
    <div class="dataPreviewColumn" style="background:#F6F4F0;text-align:center;color:#7A756E;padding-top:48px;padding-bottom:48px;border-radius:5px;">
      <i class="fa-solid fa-circle-plus" style="font-size:20px"></i>
      <div style="font-size:12px;font-family:Arial;margin-top:10px">${intl.messages["newsletter.previewColumnComponent.text"]}</div>
    </div>
  `, [intl]);

  const onComponentRender = useCallback((component, fromDelete = false) => {

    if (component.attributes.type === "kilibaColumn") {
      const element = component.getEl();
      const table = element.getElementsByTagName("table")[0];
      if (table.children.length === 0 || (fromDelete && table.children.length === 1)) {
        const t = document.createElement("template");
        t.innerHTML = preview;
        table.appendChild(t.content);
      }
    }

    if (component.attributes.type.startsWith("column-component-")) {
      const parentElement = component.parent().getEl();
      const table = parentElement.getElementsByTagName("table")[0];
      const previewElements = table.getElementsByClassName("dataPreviewColumn");
      Array.from(previewElements).forEach(element => element.remove());
    }

  }, [preview]);

  const onComponentRemoved = useCallback((component) => {
    if (component.attributes.attributes["data-column-component"] === "true") {
      const columnComponent = component.closest("[data-gjs*=\"kilibaColumn\"]");
      onComponentRender(columnComponent, true);
    }
  }, [onComponentRender]);

  useEffect(() => {
    if (editor) {
      editor.on("component:mount", onComponentRender);
      return () => {
        editor.off("component:mount", onComponentRender);
      };
    }
  }, [editor, onComponentRender]);

  useEffect(() => {
    if (editor) {
      editor.on("component:remove", onComponentRemoved);
      return () => {
        editor.off("component:remove", onComponentRemoved);
      };
    }
  }, [editor, onComponentRemoved]);

  

  /* =============================== */

  /* =============== Category drop managment ================ */

  const onDragCategoryEnd = useCallback((props) => {
    editor.runCommand("kiliba:blocks:updateContent", { category: "columnComponent", content: {}, language });
    const block = blocks.find(block => block.getCategoryLabel() === `columnComponent:${props.category}`);
    
    let previewComponent = null;

    editor.getComponents().at(0).onAll(component => {
      if (component.attributes.type.startsWith("column-preview")) {
        previewComponent = component;
      }
    });
    
    if (block) {
      const content = block.getContent();
      const parsed = editor.getComponents().parseString(content);
      const parsedArray = Array.isArray(parsed) ? parsed : [parsed];

      const index = previewComponent?.index();

      parsedArray.forEach(toAdd => {
        props.component.append({
          ...toAdd,
          "script-props": [{
            "fromBlock": block.getLabel(),
          }],
        }, { at: (Number.isInteger(index) ? index + 1 : undefined) });
      });
    }

    if (previewComponent) {
        
      if (previewComponent.attributes.type.startsWith("preview-")) {
        editor.UndoManager.stop();
      }
      
      previewComponent.remove();
      
      if (previewComponent.attributes.type.startsWith("preview-")) {      
        editor.UndoManager.start();
      }
    }

    editor.selectRemove(editor.getSelectedAll());

  }, [blocks, editor, language]);

  useEffect(() => {
    if (editor) {
      editor.on("dragCategoryEndColumn", onDragCategoryEnd);
      return () => editor.off("dragCategoryEndColumn", onDragCategoryEnd);
    }
  }, [editor, onDragCategoryEnd]);

  /* =============================== */
};