import { Component } from "react";
import { injectIntl } from "react-intl";

import { HtmlCustomizerDropdown } from "../../HtmlCustomizerDropdown/HtmlCustomizerDropdown.js";

export const HtmlCustomizerTooltipInputKeyword = injectIntl(
  class HtmlCustomizerTooltipInputKeyword extends Component {
    render() {
      const { intl, keywords, onSelect } = this.props;

      return (
        <HtmlCustomizerDropdown
          choices={keywords.map((keyword) => ({
            label: keyword,
            value: keyword,
          }))}
          onSelect={(choice) => onSelect(choice.value)}
          label={intl.messages["htmlCustomizerTooltip.keywords"]}
        />
      );
    }
  }
);
