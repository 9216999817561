import React, { Component } from "react";

import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

import { InputSection } from "../../../../../common/Input/InputSection.js";

import { IncludesFreeShippingInput } from "../TemplateEditorInputs/Options/IncludesFreeShippingInput.js";
import { MinimumAmountInput } from "../TemplateEditorInputs/Options/MinimumAmountInput.js";
import { MustRemoveObsoletePromoCodesInput } from "../TemplateEditorInputs/Options/MustRemoveObsoletePromoCodesInput.js";
import { MustShowDatesInput } from "../TemplateEditorInputs/Options/MustShowDatesInput.js";
import { QuantityInput } from "../TemplateEditorInputs/Options/QuantityInput.js";
import { ShowHeadbandPromoInput } from "../TemplateEditorInputs/Options/ShowHeadbandPromoInput.js";

const styles = (theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
});

export const OptionsSection = withStyles(styles)(
  injectIntl(
    class OptionsSection extends Component {
      render() {
        const {
          intl,
          promoCodeTemplateWrapper,
          setPromoCodeTemplateWrapperProp,
          mustRemoveObsoletePromoCodes,
          setMustRemoveObsoletePromoCodes,
          getError,
          partNames,
          classes,
          caller
        } = this.props;

        return (
          <InputSection
            title={
              intl.messages["usecase.promoCodeTemplateEditor.options.title"]
            }
          >
            <div className={classes.main}>
              {partNames.find(
                (partName) => partName === "minimumAmountInput"
              ) ? (
                  <MinimumAmountInput
                    getError={getError}
                    promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                    setPromoCodeTemplateWrapperProp={
                      setPromoCodeTemplateWrapperProp
                    }
                  />
                ) : null}
              {partNames.find((partName) => partName === "quantityInput") ? (
                <QuantityInput
                  getError={getError}
                  disabled={
                    promoCodeTemplateWrapper.promoCodeTemplate.isGeneric ===
                    false
                  }
                  promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                  setPromoCodeTemplateWrapperProp={
                    setPromoCodeTemplateWrapperProp
                  }
                />
              ) : null}
              {partNames.find(
                (partName) => partName === "includesFreeShippingInput"
              ) ? (
                  <IncludesFreeShippingInput
                    promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                    setPromoCodeTemplateWrapperProp={
                      setPromoCodeTemplateWrapperProp
                    }
                  />
                ) : null}
              {partNames.find(
                (partName) => partName === "mustRemoveObsoletePromoCodesInput"
              ) ? (
                  <MustRemoveObsoletePromoCodesInput
                    promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                    setPromoCodeTemplateWrapperProp={
                      setPromoCodeTemplateWrapperProp
                    }
                    mustRemoveObsoletePromoCodes={mustRemoveObsoletePromoCodes}
                    setMustRemoveObsoletePromoCodes={
                      setMustRemoveObsoletePromoCodes
                    }
                  />
                ) : null}
              {partNames.find(
                (partName) => partName === "showHeadbandPromo"
              ) ? (
                  <ShowHeadbandPromoInput 
                    promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                    setPromoCodeTemplateWrapperProp={
                      setPromoCodeTemplateWrapperProp
                    }
                  />
                ) : null}
              {partNames.find(
                (partName) => partName === "mustShowDatesInput"
              ) ? (
                  <MustShowDatesInput
                    promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                    setPromoCodeTemplateWrapperProp={
                      setPromoCodeTemplateWrapperProp
                    }
                    disabled={promoCodeTemplateWrapper.selectedTemporalityChoiceKey === "lifetime" || (promoCodeTemplateWrapper.selectedTemporalityChoiceKey === "eternal" && caller !== "newsletter") }
                  />
                ) : null}
            </div>
          </InputSection>
        );
      }
    }
  )
);
