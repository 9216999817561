import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

import { Dialog } from "../common/Dialog.js";
import { UsecaseCard } from "./UsecaseCard.js";

import { palette } from "../../styles/palette.js";

const styles = theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  subtitle: {
    paddingTop: 20,
    paddingBottom: 60,
  },
  usecaseList: {
    display: "flex",
    gridGap: 50,
  },
  card: {
    position: "relative",
  },
  new: {
    position: "absolute",
    background: palette.yellow,
    color: palette.black,
    right: -15,
    top: -10,
    borderRadius: 20,
    padding: "3px 18px 2px",
    fontSize: 14,
    fontWeight: 400,
  }
});

const useStyles = makeStyles(styles);

export const MultipleUsecasePopup = injectIntl(({ isOpen, close, usecases, intl }) => {
  
  const classes = useStyles();

  if (!usecases || usecases.length !== 2) {
    return null;
  }

  return (
    <Dialog
      isOpen={isOpen}
      close={close}
      style={{
        padding: "80px 120px",
      }}
    >
      <div className={classes.container}>
        <h2>{intl.messages["multipleUsecasePopup.title"]}</h2>
        <div className={classes.subtitle}>
          <span>{intl.messages["multipleUsecasePopup.subtitle1"]}</span>
          <strong>{intl.messages[`usecases.title.${usecases[0].name}`]}</strong>
          <span>{intl.messages["multipleUsecasePopup.subtitle2"]}</span>
        </div>

        <div className={classes.usecaseList}>
          {usecases.map((usecase, idx) => (
            <div className={classes.card}>
              <UsecaseCard 
                category={usecase.category}
                active={usecase.active}
                promoCode={usecase.promo_code}
                icon={usecase.icon}
                title={usecase.nameTranslated}
                description={usecase.description}
                usecaseName={usecase.name}
                isComingSoon={usecase.isComingSoon}
                launchDate={usecase.launch_date}
                isFreemium={usecase.isFreemiumUsecase}
                hideStatus={!usecase.active && usecases.some(({ active }) => !!active)}
              />

              {(idx === usecases.length - 1) && 
                <div className={classes.new}>{intl.messages["multipleUsecasePopup.new"]}</div>
              }
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
});
