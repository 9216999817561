import { useEffect, useState, useMemo, useContext } from "react";

import { useAuth } from "../../../../hooks/useAuth";
import { Translation } from "../../../common/Translation";
import { MultipleChoicePicker } from "../../../pickers/MultipleChoicePicker";

import classes from "./PromoCodesGroupParameters.module.scss";
import { AccountContext } from "../../../../contexts/context";

export const PromoCodesGroupParameters = ({ setCustomerGroupPromoCode }) => {

  const auth = useAuth();
  const [groups, setGroups] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState(null);
  const { account } = useContext(AccountContext);

  useEffect(() => {
    auth.fetch("/api/data/getDistinctCustomersGroup").then(setGroups);
  }, [auth]);

  const pickerLabel = useMemo(() => {
    if (!selectedGroups) {
      return;
    }
    
    if (!selectedGroups.length) {
      return <Translation id="promoCodesGroupParameters.pickerLabel.empty" />;
    } else if (selectedGroups.length === 1) {
      return groups.find(elem => elem.id === selectedGroups[0]).label;
    } else {
      return <Translation id="promoCodesGroupParameters.pickerLabel.many" />;
    }

  }, [selectedGroups, groups]);

  useEffect(() => {
    if (!selectedGroups && groups && account) {
      if (!account.customerGroupsPromoCode) {
        setSelectedGroups(groups.map(e => e.id));
      } else {
        setSelectedGroups(account.customerGroupsPromoCode);
      }
    }
  }, [account, groups, selectedGroups]);

  useEffect(() => {
    if (selectedGroups) {
      setCustomerGroupPromoCode(selectedGroups);
    }
  }, [selectedGroups, setCustomerGroupPromoCode]);

  return (
    <div className={classes.container}>
      <span className={classes.textinput}>
        <Translation id="editprofile.content.promoCodesGroup.subtext" />
        <MultipleChoicePicker 
          items={groups || []}
          selectedItems={selectedGroups || []}
          setSelectedItems={setSelectedGroups}
          label={pickerLabel}
        />
      </span>
    </div>
  );
};