import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import { EventSystem } from "../eventsystem/EventSystem.js";
import { CustomButton } from "./common/Button/CustomButton.js";

import powered_by_stripe from "../public/powered_by_stripe.png";

import { palette } from "../styles/palette.js";
import { Translation } from "./common/Translation.js";

const styles = (theme) => ({
  title: {
    fontFamily: "Coolvetica",
    fontSize: "28px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "0.86",
    color: palette.black,
    paddingLeft: "44px",
    marginTop: "28px",
  },
  wording: {
    fontFamily: "Coolvetica Book",
    fontSize: "16px",
    fontWeight: "normal",
    maxWidth: "80%",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.38",
    letterSpacing: "normal",
    color: palette.black,
    paddingLeft: "68px",
  },
  underlineButtonText: {
    marginTop: "20px",
    marginLeft: "calc(50% - 100px)",
    width: "200px",
    height: "19px",
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.31px",
    textAlign: "center",
    color: palette.purple,
    cursor: "pointer",
    textDecoration: "underline",
  },
  updateSubscriptions: {
    marginLeft: "70px",
    marginTop: "20px",
    height: "19px",
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.31px",
  },
  updateSubscriptions_subTitle: {
    marginLeft: "70px",
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    fontWeight: "600",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.31px",
  },
});

const styleSubLink = {
  fontFamily: "Coolvetica Book",
  fontSize: "12px",
  fontWeight: "normal",
  fontStyle: "normal",
  fontStretch: "normal",
  letterSpacing: "normal",
  color: palette.purple,
  textDecoration: "underline",
  cursor: "pointer",
  textAlign: "center",
};

export const PopupTO = withStyles(styles)(
  injectIntl(
    class PopupTO extends Component {
      render() {
        const {
          classes,
          intl,
          buttons,
          open,
          handleClose,
          close,
          borderRadius,
          width,
          title,
          updateSubscriptions,
          actualPlan,
          nextPlan,
          stripe,
          label,
          styleWording,
          form,
          deactivateUsecase,
          closingSubLabel,
          cypress,
          deleteButton, // uncomment this when old subscription system come  back
        } = this.props;

        const renderButtonArray = [];
        let countering = 0;
        if (buttons) {
          for (const button of buttons) {
            let pending = false;
            if (button.loading !== undefined && button.undefined !== null) {
              pending = button.loading;
            }
            renderButtonArray.push(
              <Grid item key={countering}>
                <CustomButton
                  type={"primary"}
                  size="lg"
                  onClick={button.onClick}
                  disabled={pending}
                >
                  {pending ? (
                    <CircularProgress size={34} className={classes.loading} />
                  ) : (
                    button.label
                  )}
                </CustomButton>
              </Grid>
            );
            countering = countering + 1;
          }
        }
        return (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            style={{ overflowY: "scroll", container: { overflowY: "scroll" } }}
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                borderRadius: borderRadius ? borderRadius : "16px",
                minWidth: width ? width : "800px",
                maxWidth: "90%",
                maxHeight: "95%",
                position: "fixed",
                margin: "auto",
                paddingBottom: "35px",
              },
            }}
            BackdropProps={{ style: { opacity: "0.8" } }}
            data-cy={cypress}
          >
            {
              close ? (
                <i
                  onClick={handleClose}
                  className="fal fa-times"
                  style={{
                    fontSize: "24px",
                    zIndex: "10000",
                    position: "absolute",
                    right: "16px",
                    top: "10px",
                    cursor: "pointer",
                  }}
                ></i>
              ) : null
            }
            {
              title ? (
                <DialogTitle id="alert-dialog-title">
                  <div className={classes.title}>
                    <FormattedMessage id={title} default="Email" />
                  </div>
                </DialogTitle>
              ) : null}
            {updateSubscriptions === true ? (
              <div>
                <div className={classes.updateSubscriptions}>
                  Votre abonnement kiliba actuel est :
                </div>
                <div className={classes.updateSubscriptions_subTitle}>
                  <FormattedMessage id={actualPlan} />
                </div>

                <div className={classes.updateSubscriptions}>
                  Vous avez demandé à modifier votre abonnement vers :
                </div>
                <div className={classes.updateSubscriptions_subTitle}>
                  <FormattedMessage id={nextPlan} />
                </div>
                <div className={classes.updateSubscriptions}>
                  En cliquant sur «Changer de plan», votre abonnement sera mis à
                  jour automatiquement.
                </div>
              </div>
            ) : null}
            <div style={{ padding: "0", height: "fit-content" }}>
              {stripe === true ? (
                <div
                  style={{
                    width: "fit-content",
                    position: "absolute",
                    right: "380px",
                    top: "102px",
                  }}
                >
                  <img src={powered_by_stripe} alt="Powered by Stripe" />
                </div>
              ) : null}
              <Grid
                container
                direction="column"
                justify="center"
                style={{ height: "fit-content" }}
              >
                {label ? (
                  <Grid item key="wording">
                    <div
                      className={classes.wording}
                      style={styleWording !== undefined ? styleWording : {}}
                    >
                      <Translation id={label} />
                    </div>
                  </Grid>
                ) : null}
                {stripe === true ? (
                  <Grid item key="stripe">
                    <div
                      style={{
                        width: "420px",
                        alignItems: "left",
                        marginLeft: "70px",
                        marginBottom: "20px",
                        shadowColor: "#000000",
                        shadowOffset: {
                          width: 0,
                          height: 3,
                        },
                        shadowRadius: 5,
                        shadowOpacity: 1.0,
                      }}
                    >
                    </div>
                  </Grid>
                ) : null}
                {form ? (
                  <Grid
                    item
                    key="form"
                    styles={{ zIndex: "2000", padding: "0", height: "100%" }}
                  >
                    {form}
                  </Grid>
                ) : null}
              </Grid>
            </div>
            {renderButtonArray.length === 0 ? null : (
              <DialogActions
                style={{ alignItems: "center", marginTop: "20px" }}
              >
                <Grid
                  container
                  justify="center"
                  style={{ flexGrow: 1, margin: "unset" }}
                  spacing={4}
                >
                  {renderButtonArray}
                </Grid>
              </DialogActions>
            )}

            {/* uncomment this when old subscription system come  back */}
            {deleteButton ? (
              <div
                onClick={() => {
                  EventSystem.publish("delete_account_popup", null);
                }}
                className={classes.underlineButtonText}
              >
                Supprimer votre compte
              </div>
            ) : null}
            {deactivateUsecase ? (
              <div
                onClick={deactivateUsecase}
                className={classes.underlineButtonText}
                id="deactivateUsecaseButton"
              >
                {intl.messages["usecasePopupLaunch.deactivate"]}
              </div>
            ) : null}
            {
              closingSubLabel ? (
                <a
                  style={styleSubLink}
                  href="https://www.kiliba.com/tarifs"
                  target="_blank"
                  rel="noreferrer"
                >
                  {closingSubLabel}
                </a>
              ) : null
            }
          </Dialog >
        );
      }
    }
  )
);
