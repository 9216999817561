import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";

import { Dialog } from "../../../common/Dialog";
import { FormButtonTO } from "../../../FormButtonTO.jsx";
import { Translation } from "../../../common/Translation";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gridGap: 30,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "25px",
  },
});

const useStyles = makeStyles(styles);

export const UsecasePopupOpenAiError = injectIntl(({ intl, isOpen, close }) => {
  const classes = useStyles();

  return (
    <Dialog
      isOpen={isOpen}
      PaperProps={{ style: { borderRadius: 20 } }}
      close={close}
    >
      <div className={classes.container}>
        <span className={classes.title}>
          <Translation id="usecasePopupOpenAiError.title" defaultMessage="" />
        </span>
        <FormButtonTO
          size="xs"
          action={close}
          label={intl.messages["usecasePopupOpenAiError.button"]}
        />
      </div>
    </Dialog>
  );
});
