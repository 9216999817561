import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";

import { CodeSection } from "./TemplateEditorSections/CodeSection.js";
import { ValueSection } from "./TemplateEditorSections/ValueSection.js";
import { TemporalitySection } from "./TemplateEditorSections/TemporalitySection.js";
import { OptionsSection } from "./TemplateEditorSections/OptionsSection.js";

const styles = (theme) => ({
  body: {
    "& > div:not(:last-child)": {
      marginBottom: 32,
    },
  },
});

export const TemplateEditorManual = withStyles(styles)(
  injectIntl(
    class extends Component {
      render() {
        const {
          classes,
          tabKey,
          account,
          promoCodeTemplateWrapper,
          setPromoCodeTemplateWrapperProp,
          getError,
          usecaseName,
          shouldDisplayFromDateOption,
          caller
        } = this.props;

        return (
          <div className={classes.body}>
            <CodeSection
              promoCodeTemplateWrapper={promoCodeTemplateWrapper}
              setPromoCodeTemplateWrapperProp={setPromoCodeTemplateWrapperProp}
              getError={getError}
              partNames={["codeInput"]}
            />

            <ValueSection
              account={account}
              promoCodeTemplateWrapper={promoCodeTemplateWrapper}
              setPromoCodeTemplateWrapperProp={setPromoCodeTemplateWrapperProp}
              getError={getError}
              partNames={["typeInput", "valueInput"]}
            />

            <TemporalitySection
              tabKey={tabKey}
              promoCodeTemplateWrapper={promoCodeTemplateWrapper}
              setPromoCodeTemplateWrapperProp={setPromoCodeTemplateWrapperProp}
              getError={getError}
              partNames={ account.CMS === "woocommerce" ? ["untilDateInput"] : ["fixedInput", "lifetimeInput", "eternalInput"]}
              shouldDisplayFromDateOption={shouldDisplayFromDateOption}
              caller={caller}
            />

            <OptionsSection
              promoCodeTemplateWrapper={promoCodeTemplateWrapper}
              setPromoCodeTemplateWrapperProp={setPromoCodeTemplateWrapperProp}
              getError={getError}
              partNames={["quantityInput", "mustShowDatesInput", ...(!["sponsorship", "welcomeAi"].includes(usecaseName) ? ["showHeadbandPromo"] : [])]}
              caller={caller}
            />
          </div>
        );
      }
    }
  )
);
