import moment from "moment/min/moment-with-locales";

export const calcStepDate = (step) => {
  const years = Math.floor(step);
  const monthsDecimal = (step - years) * 12;
  const months = Math.floor(monthsDecimal);
  const daysDecimal = (monthsDecimal - months) * (365.25 / 12);
  const days = Math.floor(daysDecimal);

  const currentDate = new Date();
  const calculatedDate = new Date(
    currentDate.getFullYear() - years,
    currentDate.getMonth() - months,
    currentDate.getDate() - days
  );

  return {
    year: calculatedDate.getFullYear(),
    month: moment(calculatedDate).format("MMMM"),
  };
};