import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { RadioGroup } from "../../../common/RadioGroup.js";
import { Radio } from "../../../common/Input/Radio/Radio.js";
import { InputSection } from "../../../common/Input/InputSection.js";


export const ToneParameters = injectIntl(
  class ToneParameters extends Component {
    render() {
      const { intl, tone, setTone } = this.props;

      if (tone === undefined) return null;

      return (
        <InputSection title={intl.messages["templateCustomization.sub_title_tone"]}>
          <RadioGroup value={tone ? tone : "sobre"} onChange={setTone}>
            <Radio legacy
              value="sobre"
              label={intl.messages["templateCustomization.tone_sobre"]}
            />
            <Radio legacy
              value="serieux"
              label={intl.messages["templateCustomization.tone_pro"]}
            />
            <Radio legacy
              value="decale"
              label={intl.messages["templateCustomization.tone_fun"]}
            />
          </RadioGroup>
        </InputSection>
      );
    }
  }
);
