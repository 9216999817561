import { createTheme, lighten } from "@mui/material";
import { palette } from "./styles/palette";

export const theme = createTheme({
  components: {
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          boxShadow: "none",
          border: `1px solid ${palette.neutralLightGrey}`,
          borderRadius: 8,
          marginTop: 8,
          "& .MuiButtonBase-root": {
            backgroundColor: "none",
            color: palette.neutralDarkGrey,
            "&.Mui-selected": {
              backgroundColor: palette.primaryYellow200,
              "&:hover": {
                backgroundColor: palette.primaryYellow200,
              },
              "&:focus": {
                backgroundColor: palette.primaryYellow200,
              }
            },
            "&:hover": {
              backgroundColor: lighten(palette.primaryYellow200, 0.93),
            },
            "&:focus": {
              backgroundColor: "initial",
            },
            "&.MuiPickersDay-today": {
              border: `1px solid ${palette.primaryYellow200}`,
              "&:hover": {
                backgroundColor: lighten(palette.primaryYellow200, 0.93),
              },
            }
          },
          "& .MuiPickersCalendarHeader-label": {
            color: palette.primaryBlack,
          },
        }
      }
    }
  }
});
