import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core";

import { Dialog } from "../../../common/Dialog";
import { FormButtonTO } from "../../../FormButtonTO.jsx";
import { Translation } from "../../../common/Translation";
import { useEffect } from "react";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gridGap: 30,
    maxWidth: 400,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "25px",
  },
  subtitle: {
    lineHeight: "22px",
    fontFamily: "Poppins",
    textAlign: "center",
    fontSize: 14,
  }
});

const useStyles = makeStyles(styles);

export const UsecasePopupHistory = injectIntl(({ intl, isOpen, close }) => {
  const classes = useStyles();

  useEffect(() => {
    if (isOpen) {
      localStorage.setItem("popupHistorySeen", true);
    }
  }, [isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      PaperProps={{ style: { borderRadius: 20 } }}
      close={close}
    >
      <div className={classes.container}>
        <span className={classes.title}>
          <Translation id="usecasePopupHistory.title" defaultMessage="" />
        </span>
        <span className={classes.subtitle}>
          <Translation id="usecasePopupHistory.subtitle" defaultMessage="" />
        </span>
        <FormButtonTO
          action={close}
          label={intl.messages["usecasePopupHistory.button"]}
        />
      </div>
    </Dialog>
  );
});
