import { makeStyles } from "@material-ui/core";

import { Translation } from "../../common/Translation";
import { CustomButton } from "../../common/Button/CustomButton";

import { palette } from "../../../styles/palette";
import image1 from "../../../public/subscriptionTip1.png";
import image2 from "../../../public/subscriptionTip2.png";
import image3 from "../../../public/subscriptionTip3.png";

const styles = () => ({
  title: {
    marginBottom: 10,
  },
  tipsContainer: {
    marginTop: 30,
    display: "flex",
    gridGap: 20,
    marginBottom: 20,
  },
  tipContainer: {
    background: palette.primaryWhite,
    borderRadius: 20,
    padding: "20px 25px",
    display: "flex",
    gridGap: 20,
    alignItems: "center",
  },
  tipText: {
    marginTop: 10,
    marginBottom: 20,
  },
  tipImage: {
    width: 120,
    height: 120,
  },
  link: {
    textAlign: "center",
  }
});

const useStyles = makeStyles(styles);

export const SubscriptionTips = ({ tips }) => {

  const classes = useStyles();

  const tipsLink = {
    sponsorship: "https://support.kiliba.com/hc/fr/articles/10866401031196-Sc%C3%A9nario-Parrainage",
    strategy: "https://www.kiliba.com/blog/strategies-marketing-affiliation-2023",
    inbound: "https://www.kiliba.com/blog/exemples-inbound-marketing-ecommerce",
  };

  const tipsImage = {
    sponsorship: image1,
    strategy: image2,
    inbound: image3,
  };

  return (
    <div>
      <h2 className={classes.title}><Translation id="subscriptionTips.title" /></h2>
      <div className="text"><Translation id="subscriptionTips.subtitle" /></div>

      <div className={classes.tipsContainer}>
        {tips.map(tip => (
          <div className={classes.tipContainer}>
            <div>
              <div className="textMedium"><Translation id={`subscriptionTips.tip.${tip}.title`} /></div>
              <div className={`info ${classes.tipText}`}><Translation id={`subscriptionTips.tip.${tip}.text`} /></div>
              <CustomButton size="xs" onClick={() => window.open(tipsLink[tip], "_blank")}>
                <Translation id="subscriptionTips.tip.button" />
              </CustomButton>
            </div>
            <img src={tipsImage[tip]} alt="" className={classes.tipImage} />
          </div>
        ))}
      </div>

      <div className={`link ${classes.link}`} onClick={() => window.open("https://www.kiliba.com/blog", "_blank")}><Translation id="subscriptionTips.moreTips" /></div>
    </div>
  );
};