import React, { Component, Fragment } from "react";

import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

import { FormButtonTO } from "../../FormButtonTO.jsx";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import { NoCms } from "../../common/EmptyState/NoCms.js";

import ovalArrow from "../../../public/oval_arrow.png";
import oval from "../../../public/oval.png";

import { palette } from "../../../styles/palette.js";
import { Translation } from "../../common/Translation";

const styles = (theme) => ({
  headerCta: {
    position: "absolute",
    top: 120,
  },
  ctaAndArrow: {
    position: "absolute",
    right: "20%",
    zIndex: 999,
  },
  rawButton: {
    margin: theme.spacing.unit,
    width: "200px",
    height: "100%",
    marginTop: "0",
    textTransform: "none",
    color: palette.darkgray,
    fontSize: "14px",
    fontFamily: "Coolvetica Book",
    fontWeight: "normal",
    borderBottom: "3px solid " + palette.white,
    borderRadius: "0",
  },
  selectedRawButton: {
    margin: theme.spacing.unit,
    height: "100%",
    width: "200px",
    marginTop: "0",
    textTransform: "none",
    color: palette.primaryBlack,
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    fontWeight: "normal",
    borderRadius: "0",
    borderBottom: "3px solid " + palette.primaryBlack,
    "&:hover": {
      backgroundColor: palette.white,
    },
  },
  tagButton: {
    margin: theme.spacing.unit,
    textTransform: "none",
    height: "35px",
    marginTop: "0",
    color: palette.white,
    fontSize: "15px",
    paddingLeft: "19px",
    paddingRight: "19px",
    fontWeight: "normal",
    backgroundColor: palette.anothergray,
    borderRadius: "17.5px",
    "&:hover": {
      backgroundColor: palette.anothergray,
    },
  },
  selectedTagButton: {
    margin: theme.spacing.unit,
    textTransform: "none",
    height: "35px",
    marginTop: "0",
    color: palette.black,
    fontSize: "15px",
    paddingLeft: "19px",
    paddingRight: "19px",
    fontWeight: "normal",
    backgroundColor: palette.primaryBlack,
    borderRadius: "17.5px",
    "&:hover": {
      backgroundColor: palette.primaryBlack,
    },
  },
  alternateButton: {
    fontSize: "14px",
    fontWeight: "normal",
    textTransform: "none",
    height: "100%",
    width: "150px",
    color: palette.white,
    backgroundColor: palette.black,
    "&:hover": {
      backgroundColor: "#4D4D4D",
      boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
    },
  },
  rootPaper: {
    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
    flexGrow: 1,
    height: "76px",
    zIndex: "500",
  },
  toparea: {
    width: "calc(100% - 68px)",
    height: "200px",
    backgroundColor: palette.white,
    paddingLeft: "68px",
  },
  title: {
    position: "relative",
    fontFamily: "Coolvetica",
    fontSize: "40px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: palette.black,
    zIndex: 2,
  },
  header_text: {
    position: "relative",
    fontFamily: "Coolvetica Book",
    fontSize: "16px",
    marginTop: 15,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    color: palette.black,
    zIndex: 2,
  },
  subtitle: {
    position: "relative",
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    fontWeight: "bold",
    color: palette.black,
    zIndex: 2,
  },
  oval: {
    position: "absolute",
    top: 30,
    left: 128,
    width: 90,
  },
});

export const UsecaseHeader = withStyles(styles)(
  class UsecaseHeader extends Component {
    constructor(props) {
      super(props);
      const innerWidth = window.innerWidth;
      this.setAnchor = this.setAnchor.bind(this);
      this.listenToScroll = this.listenToScroll.bind(this);
      this.updateDimensions = this.updateDimensions.bind(this);
      this.state = {
        viewportWidth: innerWidth,
        activeAnchor: "boost",
      };
    }

    componentDidMount() {
      window.addEventListener("scroll", this.listenToScroll);
      window.addEventListener("resize", this.updateDimensions);
      window.scrollTo(0, 0);
    }
    componentWillUnmount() {
      window.removeEventListener("scroll", this.listenToScroll);
      window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
      this.setState({ viewportWidth: window.innerWidth });
    }

    listenToScroll() {
      const explanations = document.getElementById("explanations");
      const email = document.getElementById("email");
      let activeAnchor = "boost";

      if (
        explanations !== null &&
        window.pageYOffset >= explanations.offsetTop - 136
      ) {
        activeAnchor = "explanations";
      }

      if (email !== null && window.pageYOffset >= email.offsetTop - 136) {
        activeAnchor = "email";
      }

      this.setState({
        pageYOffset: window.pageYOffset,
        activeAnchor: activeAnchor,
      });
    }

    setAnchor(anchor) {
      this.setState({ activeAnchor: anchor }, () => {
        const elem = document.getElementById(anchor);
        window.scrollTo(0, elem.offsetTop - 76);
      });
    }

    render() {
      const { usecase, isActive, classes, mainButtonAction, hasEnd } =
        this.props;
      const navMustBeFixed =
        (this.state.pageYOffset > 273 && this.state.viewportWidth > 960) ||
        this.state.viewportWidth <= 960;

      return (
        <Fragment>
          <UsecaseHeaderBanner
            usecase={usecase}
            isActive={isActive}
            hasEnd={hasEnd}
            mainButtonAction={mainButtonAction}
            viewportWidth={this.state.viewportWidth}
            noCms={this.props.noCms}
            customName={this.props.customName}
          />

          {/* space used to prevent content from going up when nav becomes fixed */}
          {navMustBeFixed ? (
            <div style={{ height: "76px", width: "100%" }}></div>
          ) : null}

          <UsecaseHeaderNav
            mainButtonAction={mainButtonAction}
            navMustBeFixed={navMustBeFixed}
            pageYOffset={this.state.pageYOffset}
            viewportWidth={this.state.viewportWidth}
            isActive={isActive}
            activeAnchor={this.state.activeAnchor}
            setAnchor={this.setAnchor}
            classes={classes}
            noCms={this.props.noCms}
            usecase={usecase}
          />
        </Fragment>
      );
    }
  }
);

const UsecaseHeaderBanner = withStyles(styles)(
  injectIntl(
    class UsecaseHeaderBanner extends Component {
      state = {
        noCms: this.props.noCms,
      };

      render() {
        const {
          classes,
          intl,
          usecase,
          isActive,
          mainButtonAction,
          hasEnd,
          noCms,
          customName,
        } = this.props;

        return (
          <Hidden smDown>
            <div className={classes.toparea}>
              <div className={classes.ctaAndArrow}>
                <div className={classes.headerCta}>
                  {noCms ? (
                    <NoCms
                      style={{ padding: "initial" }}
                      ctaText={intl.messages["noCms.cta.continue"]}
                      type="primary"
                    />
                  ) : (
                    <FormButtonTO
                      type={"primary"}
                      label={
                        intl.messages[
                          isActive
                            ? hasEnd
                              ? "button.modify"
                              : "button.deactivate"
                            : "button.activate_usecase"
                        ]
                      }
                      icon={
                        <i
                          className="fa-light fa-angle-right"
                          style={{
                            fontSize: "16px",
                            position: "absolute",
                            top: "18px",
                            marginLeft: "10px",
                          }}
                        />
                      }
                      action={mainButtonAction}
                    />
                  )}
                </div>

                <img
                  src={ovalArrow}
                  alt="decoration"
                  style={{
                    position: "absolute",
                    top: 68,
                    height: "auto",
                    width: 120,
                    marginLeft: -140,
                  }}
                />
              </div>
              <img className={classes.oval} src={oval} alt="decoration" />

              <div className={classes.subtitle} style={{ paddingTop: "39px" }}>
                <FormattedMessage
                  id={`details.${usecase.name}.banner.subtitle`}
                  defaultMessage="Welcome"
                />
              </div>
              <div className={classes.title} style={{ paddingTop: "10px" }}>
                {customName ? (
                  customName
                ) : (
                  <FormattedMessage
                    id={`details.${usecase.name}.banner.title`}
                    defaultMessage="Welcome"
                  />
                )}
              </div>
              <div className={classes.header_text}>
                <Translation id={`details.${usecase.name}.banner.wording`} />
              </div>
            </div>
          </Hidden>
        );
      }
    }
  )
);

const UsecaseHeaderNav = withStyles(styles)(
  injectIntl(
    class UsecaseHeaderNav extends Component {
      render() {
        const {
          classes,
          intl,
          mainButtonAction,
          navMustBeFixed,
          pageYOffset,
          viewportWidth,
          isActive,
          activeAnchor,
          setAnchor,
          noCms,
          usecase,
        } = this.props;

        return (
          <Paper
            id="usecaseHeaderNav"
            square
            className={classes.rootPaper}
            style={
              navMustBeFixed
                ? {
                  position: "fixed",
                  width: "calc(100% -  80px)",
                  top: "60px",
                }
                : null
            }
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-around"
              style={{
                height: "100%",
                marginLeft: viewportWidth > 600 ? "100px" : "0px",
                width: viewportWidth > 960 ? "calc(100% - 100px)" : "100%",
              }}
            >
              {usecase.name !== "custom" && (
                <Hidden smDown>
                  <Grid xs={2} sm={3} item style={{ height: "100%" }}>
                    <Button
                      onClick={() => setAnchor("boost")}
                      id="button_boost"
                      className={
                        activeAnchor === "boost"
                          ? classes.selectedRawButton
                          : classes.rawButton
                      }
                    >
                      <FormattedMessage id="details.postVisits.content.title.boost" />
                    </Button>
                  </Grid>

                  <Grid xs={2} sm={3} item style={{ height: "100%" }}>
                    <Button
                      onClick={() => setAnchor("explanations")}
                      id="button_explanations"
                      className={
                        activeAnchor === "explanations"
                          ? classes.selectedRawButton
                          : classes.rawButton
                      }
                    >
                      <FormattedMessage id="details.postVisits.content.explanations.title" />
                    </Button>
                  </Grid>
                </Hidden>
              )}

              {pageYOffset > 280 || viewportWidth <= 600 ? (
                <Grid
                  sm={6}
                  md={3}
                  item
                  style={{
                    height: "49px",
                    marginBottom: viewportWidth < 600 ? "2px" : "0px",
                    display: isActive ? "none" : "block",
                  }}
                >
                  {noCms ? (
                    <NoCms
                      style={{ padding: "initial" }}
                      ctaText={intl.messages["noCms.cta.continue"]}
                    />
                  ) : (
                    <Button
                      onClick={mainButtonAction}
                      className={classes.alternateButton}
                    >
                      <FormattedMessage
                        id={isActive ? "button.deactivate" : "button.activate_usecase"}
                      />
                    </Button>
                  )}
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        );
      }
    }
  )
);
