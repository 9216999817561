import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import { TextField } from "../common/Input/TextField.js";
import { SubmitButton } from "../common/Button/SubmitButton";
import { GoogleButton } from "../common/Button/GoogleButton";
import { Dialog } from "../common/Dialog.js";
import { Translation } from "../common/Translation.js";
import { FormButtonTO } from "../FormButtonTO.jsx";
import { injectIntl, FormattedMessage } from "react-intl";
import { ResultLabelTO } from "../ResultLabelTO.js";
import { AuthService } from "../AuthService";
import { styles } from "./style.js";
//// IMG ///
import arrow from "../../public/arrow.png";
import slide from "../../public/signIn.png";
import logo from "../../public/logo_black.png";
import * as shopifyTools from "../../tools/shopify";
import { AccountContextBehaviorSubject$, reloadAccountContext } from "../../contexts/context";

const authService = new AuthService();

export const SignIn = withStyles(styles)(
  injectIntl(
    class SignIn extends Component {
      constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeUrl = this.changeUrl.bind(this);
        this.userLogin = this.userLogin.bind(this);
        this.setError = this.setError.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.Auth = new AuthService();
        this.state = {
          email: "",
          password: "",
          error_email: false,
          error_password: false,
          global_error: false,
          error_text: "",
          redText: true,
          clickable: true,
          showShopifyOnboardingConfirmationPopUp: false,
          companyName: "",
          shopifyName: ""
        };
      }

      async componentDidMount() {
        if (this.Auth.loggedIn()) {
          const shopifyParams = shopifyTools.getShopifyParams();
    
          if (shopifyParams) {
            const { account } = AccountContextBehaviorSubject$.getValue();

            await this.handleShopifySignIn(shopifyParams);
            if (!account?.CMS && !account.shopify) {
              this.setState({ showShopifyOnboardingConfirmationPopUp: true, companyName: account.company_name, shopifyName: shopifyParams.shopUrl });
            }
          } else {
            this.props.history.push("/");
          }
        }
      }

      async handleShopifySignIn(shopifyParams) {
        if (!shopifyParams) return;
        this.setState({
          CMS: shopifyParams.CMS,
          shopUrl: shopifyParams.shopUrl,
          setupType: shopifyParams.setupType,
          connectionType: shopifyParams.connectionType,
        });
      }

      UNSAFE_componentWillMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);

        // Validate account
        const username = params.get("username");
        const confirmationCode = params.get("confirmation_code");
        if(username && confirmationCode) {
          this.Auth.confirmSignUp(username, confirmationCode)
            .then(() => {
              this.setState({ email: username });
            }).catch(error => {
              this.setError(new Error());
            });
        }

        const { isreset } = this.props.location;
        if (isreset === true) {
          this.setState({
            global_error: true,
            redText: false,
            error_text: "success.resetPassword_updated",
          });
        }
      }

      validateEmail(email) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      changeUrl = (url) => (event) => {
        this.props.history.push(url);
      };

      handleHiddenEyes = (event) => {
        this.setState({ hidden: !this.state.hidden });
      };

      handleSubmit(e) {
        e.preventDefault();
        if (this.state.clickable === true) {
          this.userLogin();
        }
      }

      async setupShopifyModule() {
        const signUpPayload = {
          CMS: this.state.CMS,
          shopify: {
            session: {
              shopUrl: this.state.shopUrl,
              setupDate: new Date().toISOString(),
              setupType: this.state.setupType,
            },
          }
        };

        await authService.fetch(
          "/api/account_management/setupShopifyModule",
          {
            method: "post",
            body: JSON.stringify(signUpPayload),
          }
        );
        await reloadAccountContext();
      }

      async fetchAccountHasShopifyConfiguration() {
        return await authService.fetch(`/api/public/getShopifyAccountExists?shopUrl=${this.state.shopUrl}`);
      }

      async userLogin() {
        return this.checkFields()
          .then(() => {
            this.setState({ clickable: false });
            return this.Auth.login(
              this.state.email,
              this.state.password
            );
          })
          .then(async () => {
            const shopifyParams = shopifyTools.getShopifyParams();
            if (shopifyParams) {
              const { account } = AccountContextBehaviorSubject$.getValue();
              if (!account?.CMS && !account.shopify) {
                this.setState({ showShopifyOnboardingConfirmationPopUp: true, companyName: account.company_name, shopifyName: shopifyParams.shopUrl });
              }
            } else {
              this.props.history.push("/on_boarding");
            }
          })
          .catch((err) => {
            console.log(err);
            this.setError(err);
          });
      }

      async redirectToOnBoardingWithSetupShopify() {
        await this.setupShopifyModule();
        this.props.history.push("/on_boarding");
      }

      redirectToRegister() {
        localStorage.removeItem("id_account");
        this.props.history.push("/register");
      }

      setError(err) {
        switch (err.error) {
          case "RESET_REQUIRED":
            this.setState({
              global_error: true,
              redText: true,
              error_text: "error.reset_required",
              clickable: true,
            });
            break;
          case "INVALID_ACCOUNT":
            this.setState({
              global_error: true,
              redText: true,
              error_text: "error.invalid_account",
              clickable: true,
            });
            break;
          case "UNAUTHORIZED_ACCESS":
            this.setState({
              global_error: true,
              redText: true,
              error_text: "error.unauthorized_access",
              clickable: true,
            });
            break;
          case "USER_NOT_FOUND":
            this.setState({
              global_error: true,
              redText: true,
              error_text: "error.email.notFound",
              clickable: true,
            });
            break;
          case "FORMAT_EMAIL":
            this.setState({
              global_error: true,
              redText: true,
              error_text: "error.email.format",
              clickable: true,
            });
            break;
          default:
            this.setState({
              global_error: true,
              redText: true,
              error_text: "error.main_error",
              clickable: true,
            });
            break;
        }
      }

      checkFields() {
        let success = true;
        let error = "";
        if (!this.validateEmail(this.state.email)) {
          success = false;
          error = "FORMAT_EMAIL";
        }

        if (success === false) {
          return Promise.reject({ success: success, error: error });
        } else {
          return Promise.resolve();
        }
      }

      ///////////////////////////////  VIEW  ///////////////////////////////

      render() {
        const { classes, intl } = this.props;

        return (
          <div className={classes.root}>
            <Paper className={classes.mainwindow}>
              <Grid
                container
                item
                justify-xs-center="true"
                xs={12}
                sm={12}
                spacing={0}
              >
                <Grid item xs={6}>
                  <div className={classes.leftWindow}>
                    <div className={classes.roundedDiv}></div>
                    <img
                      className={classes.logo}
                      src={logo}
                      alt="logo"
                      onClick={this.changeUrl("/")}
                    />
                    {/* HEADER */}
                    <div>
                      <ResultLabelTO
                        style={{ width: "33.5%", marginTop: "8px" }}
                        visible={this.state.global_error && !this.state.redText}
                        inactive_style={{ display: "none" }}
                        label={this.state.error_text}
                      />

                      <div className={classes.subWording}>
                        <FormattedMessage
                          id="login.smartWelcome"
                          defaultMessage="Welcome"
                        />
                        <br />
                      </div>

                      <div className={classes.wording}>
                        <FormattedMessage
                          id="login.welcome"
                          defaultMessage="Welcome"
                        />
                      </div>
                    </div>
                    {/* FORM */}
                    <div className={classes.form}>
                      <form onSubmit={this.handleSubmit}>
                        <div className={classes.error}>
                          {this.state.global_error && this.state.redText && (
                            <FormattedMessage id={this.state.error_text} />
                          )}
                          &nbsp;
                        </div>

                        <div className={classes.formSigninGrid}>
                          <TextField
                            value={this.state.email}
                            style={{ gridArea: "a" }}
                            type="email"
                            id="inputEmail"
                            onChange={(event) =>
                              this.setState({ email: event.target.value })
                            }
                            autoFocus
                          />
                          <TextField
                            value={this.state.password}
                            style={{ gridArea: "b" }}
                            type="password"
                            id="inputPassword"
                            onChange={(event) =>
                              this.setState({ password: event.target.value })
                            }
                            onEyeClick={this.handleHiddenEyes}
                          />
                        </div>

                        <div className={classes.loginWording}>
                          <a
                            href
                            rel="noreferrer"
                            onClick={this.changeUrl("/callResetPassword")}
                            className={classes.loginWording_hypertext}
                          >
                            <FormattedMessage
                              id="login.forgot_pass"
                              defaultMessage="Welcome"
                            />
                          </a>
                        </div>
                        <img src={arrow} alt="logo" className={classes.arrow} />
                        <SubmitButton
                          style={{ fontFamily: "Poppins" }}
                          label="button.sign_in"
                          isLoading={!this.state.clickable}
                        />
                      </form>
                      <div
                        className={`${classes.checkCgv} ${classes.marginUp}`}
                      >
                        <div className={classes.marginUp}>
                          <FormattedMessage
                            id="login.still_not_registered"
                            defaultMessage="Welcome"
                          />
                          <a
                            href
                            rel="noreferrer"
                            onClick={this.changeUrl("/register")}
                            className={classes.loginWording_hypertext}
                          >
                            <FormattedMessage
                              id="login.create_account"
                              defaultMessage="Welcome"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* FOOTER */}
                    <div className={classes.footer}>
                      <div
                        className={`${classes.subWording} ${classes.loginOr}`}
                      >
                        <hr className={classes.leftHr}></hr>
                        <FormattedMessage id="login.or" />
                        <hr className={classes.rightHr}></hr>
                      </div>
                      <div>
                        <GoogleButton />
                      </div>
                      <div className={classes.subWording}>
                        <a
                          className={classes.fakeLink}
                          href="https://www.kiliba.com/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <FormattedMessage id="button.back_to_home" />
                        </a>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.rightWindow}>
                    <img
                      className={classes.slide}
                      alt="bug loading"
                      src={slide}
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
            <Dialog
              PaperProps={{ style: { borderRadius: 20, width: 600 } }}
              closeStyle={null}
              isOpen={this.state.showShopifyOnboardingConfirmationPopUp}
            >
              <div className={classes.container}>
                <h2>
                  <Translation id="shopify.onboarding.popup.text" variables={{ kilibaName: this.state.companyName, shopifyName: this.state.shopifyName.replaceAll(".myshopify.com", "") }} />
                </h2>
                <div className={classes.buttonContainer}>
                  <FormButtonTO 
                    label={intl.messages["common.button.no"]}
                    action={() => this.redirectToRegister()}
                    size="sm"
                    type="secondary"
                  />
                  <FormButtonTO 
                    label={intl.messages["common.button.yes"]}
                    action={() => this.redirectToOnBoardingWithSetupShopify()}
                    size="sm"
                  />
                </div>
              </div>
            </Dialog>
          </div>
        );
      }
    }
  )
);

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};
