import { useState, useEffect, useCallback } from "react";
import { Paper, CircularProgress } from "@material-ui/core";
import classNames from "classnames";
import classes from "./EmailAuthentication.module.scss";
import { useAuth } from "../../../hooks/useAuth.js";
import { InputSection } from "../../common/Input/InputSection.js";

export function EmailAuthentication({ className }) {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [hasInternalError, setHasInternalError] = useState(false);
  const [hasEmailAuthentication, setHasEmailAuthentication] = useState(null);
  const [domain, setDomain] = useState("");
  const [valid, setValid] = useState();
  const [dnsRecords, setDnsRecords] = useState([]);
  const [dmarcStatus, setDmarcStatus] = useState(false);

  useEffect(() => {
    auth.fetch("/account_management/getEmailAuthentication")
      .then((data) => {
        setHasEmailAuthentication(true);
        setDomain(data.domain);
        setValid(data.valid);
        setDnsRecords(data.dnsRecords);
        setDmarcStatus(data.dmarc);
      })
      .catch((error) => {
        if(error.status === 404) {
          setHasEmailAuthentication(false);
        } else {
          setHasInternalError(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth]);

  const addEmailAuthentication = useCallback(() => {
    auth.fetch("/account_management/addEmailAuthentication", {
      method: "POST",
      body: JSON.stringify({
        domain: domain
      })
    })
      .then((data) => {
        setHasEmailAuthentication(true);
        setDomain(data.domain);
        setValid(data.valid);
        setDnsRecords(data.dnsRecords);
        setDmarcStatus(data.dmarc);
      });
  }, [auth, domain]);

  const checkEmailAuthentication = useCallback(() => {
    auth.fetch("/account_management/checkEmailAuthentication", {
      method: "POST"
    })
      .then((data) => {
        setValid(data.valid);
        setDnsRecords(data.dnsRecords);
        setDmarcStatus(data.dmarc);
      });
  }, [auth]);

  return (
    <Paper className={classNames(className)}>
      <div style={{padding: 35}}>
        <InputSection>
          <p className={classes.subTitle}>
            Email authentication
          </p>

          {
            loading
              ? <CircularProgress />
              : hasInternalError
                ? (
                  <p>
                    <i className="fas fa-warning" /> An error occured...
                  </p>
                )
                : hasEmailAuthentication
                  ? (
                    <>
                      Your email authentication for {domain} is {valid ? "validated!" : "pending..."}
                      <table>
                        <thead>
                          <tr>
                            <th>Host</th>
                            <th>Type</th>
                            <th>Data</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            dnsRecords.map(record => (
                              <tr>
                                <td><code>{record.host}</code></td>
                                <td><code>{record.type?.toUpperCase()}</code></td>
                                <td><code>{record.data}</code></td>
                                <td><code>{record.valid ? "Validated" : "Pending"}</code></td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                      DMARC {dmarcStatus ? "setup!" : "missing!"}
                      {
                        dmarcStatus
                          ? undefined
                          : (
                            <table>
                              <thead>
                                <tr>
                                  <th>Host</th>
                                  <th>Type</th>
                                  <th>Data</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td><code>_dmarc.{domain}</code></td>
                                  <td><code>TXT</code></td>
                                  <td><code>v=DMARC1; p=none;</code></td>
                                </tr>
                              </tbody>
                            </table>
                          )
                      }
                      <button onClick={() => checkEmailAuthentication()}>Check DNS</button>
                    </>
                  )
                  : (
                    <>
                      Add email authentication now !
                      <input type="text" value={domain} onChange={(event) => setDomain(event.target.value)} />
                      <button onClick={() => addEmailAuthentication()}>Add domain</button>
                    </>
                  )
          }

        </InputSection>
      </div>
    </Paper>
  );
}