import { useCallback, useState, useEffect } from "react";
import { useAuth } from "../../../hooks/useAuth";

export const useWordingProposals = (templateId, language) => {
 
  const auth = useAuth();
  const [wordingProposals, setwordingProposals] = useState([]);
  const [isWordingProposalsLoading, setIsWordingProposalsLoading] = useState(false);
  const [prevLanguage, setPrevLanguage] = useState(language);

  useEffect(() => {
    if (language !== prevLanguage) {
      setPrevLanguage(language);
      setwordingProposals([]);
    }
  }, [language, prevLanguage]);

  useEffect(() => {
    if (wordingProposals.find(elem => !elem.proposals[0].title[language])) {
      setwordingProposals(prev => [...prev.filter(elem => !!elem.proposals[0].title[language])]);
    }

  }, [wordingProposals, language]);

  const getProposalsIdxFromProducts = useCallback((productIds = []) => wordingProposals.findIndex(wording => {
    return JSON.stringify(productIds.sort()) === JSON.stringify(wording.productIds.sort());
  }), [wordingProposals]);

  const loadMoreProposals = useCallback((productIds = []) => {
    if (!templateId || !language) {
      return ;
    }

    setIsWordingProposalsLoading(true);

    auth.fetch(`/account_management/getNewsletterWordingsProposals?templateId=${templateId}&language=${language}${productIds.map(id => `&productIds=${id}`).join("")}`).then((response) => {
      
      const idx = getProposalsIdxFromProducts(productIds);
      
      if (idx >= 0) {
        setwordingProposals(prev => prev.map((elem, _idx) => {
          if (idx === _idx) {
            return {
              ...elem,
              proposals: [...elem.proposals, ...response]
            };
          }
          return elem;
        }));
      } else {
        setwordingProposals(prev => [...prev, {
          productIds,
          proposals: response,
        }]);
      }

      setIsWordingProposalsLoading(false);
    });

  }, [templateId, auth, getProposalsIdxFromProducts, language]);


  return {
    wordingProposals,
    isWordingProposalsLoading,
    loadMoreProposals,
    getProposalsIdxFromProducts
  };
};