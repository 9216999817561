import { product6 } from "../products/product6";
import { multiProducts4 } from "../products/multiProducts4";
import { multiProducts2 } from "../products/multiProducts2";
import { whiteSeparator } from "../components/separators/whiteSeparator";
import { lineSeparator } from "../components/separators/lineSeparator";
import { makeTranslatable, rewriteVariables, rewriteWordings } from "../utils";

export const getProductsBlocks = ({
  products,
  fontFamily,
  buttonTextColor,
  accentColor,
  textColor,
  dominantColor, 
  buttonRadius,
  isDominantColorLight,
  currency,
  priceIsVisible,
  currencyBefore,
}) => {
  const productHrefs = [];
  const imagesUrl = [];
  for (const product of products) {
    let productHref = product.absolute_url;
    if (!productHref?.startsWith("http")) {
      productHref = `https://${productHref}`;
    }
    productHrefs.push(makeTranslatable(productHref));
    imagesUrl.push(makeTranslatable(product.image_url));
  }

  const imageAlt = makeTranslatable("");

  const separatorBlock = whiteSeparator({ dominantColor }).block();
  const separatorLineBlock = lineSeparator({ dominantColor, isDominantColorLight }).block();

  switch (products.length) {
    case 1: {
      const product = products[0];
      const productHref = productHrefs[0];
      const productBlock = product6({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl: makeTranslatable(product.image_url), imageHref: productHref, imageAlt, buttonHref: productHref, buttonRadius, currency, priceIsVisible, currencyBefore }).block();

      const { variables: productVariables, mjml: productMjml } = rewriteVariables("products1-var", productBlock.attributes.variables, productBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${productBlock.label}"`));

      return {
        mjml: `
          ${productMjml}
          ${separatorBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${separatorBlock.label}"`)}
        `,
        wordings: productBlock.attributes.wordings,
        variables: productVariables,
      };
    }
    case 2: {
      const productsBlock = multiProducts4({ products, fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl, imagesHref: productHrefs, imagesAlt: [imageAlt, imageAlt, imageAlt], buttonsHref: productHrefs, buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }, products.length).block();
      
      const { variables: productsVariables, mjml: productsMjml } = rewriteVariables("products1-var", productsBlock.attributes.variables, productsBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${productsBlock.label}"`));
      
      return {
        mjml: `
          ${productsMjml}
          ${separatorBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${separatorBlock.label}"`)}
        `,
        wordings: productsBlock.attributes.wordings,
        variables: productsVariables,
      };
    }
    case 3: {
      const productsBlock = multiProducts2({ products, fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl, imagesHref: productHrefs, imagesAlt: [imageAlt, imageAlt, imageAlt], buttonsHref: productHrefs, buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }, products.length).block();
      
      const { variables: productsVariables, mjml: productsMjml } = rewriteVariables("products1-var", productsBlock.attributes.variables, productsBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${productsBlock.label}"`));
      
      return {
        mjml: `
          ${productsMjml}
          ${separatorBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${separatorBlock.label}"`)}
        `,
        wordings: productsBlock.attributes.wordings,
        variables: productsVariables,
      };
    }
    case 4: 
    case 6: {
      const nbPerLine = products.length / 2;
      const productsBlock1 = multiProducts2({ products: products.slice(0, nbPerLine), fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: imagesUrl.slice(0, nbPerLine), imagesHref: productHrefs.slice(0, nbPerLine), imagesAlt: [imageAlt, imageAlt, imageAlt], buttonsHref: productHrefs.slice(0, nbPerLine), buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }, nbPerLine).block();
      const productsBlock2 = multiProducts2({ products: products.slice(nbPerLine), fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: imagesUrl.slice(nbPerLine), imagesAlt: [imageAlt, imageAlt, imageAlt], imagesHref: productHrefs.slice(nbPerLine), buttonsHref: productHrefs.slice(nbPerLine), buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }, nbPerLine).block();

      const { wordings: productsWordings1, mjml: productsMjml1 } = rewriteWordings("products1", productsBlock1.attributes.wordings, productsBlock1.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${productsBlock1.label}"`));
      const { wordings: productsWordings2, mjml: productsMjml2 } = rewriteWordings("products2", productsBlock2.attributes.wordings, productsBlock2.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${productsBlock2.label}"`));

      const { variables: productsVariables1, mjml: finalProductsMjml1 } = rewriteVariables("products1-var", productsBlock1.attributes.variables, productsMjml1);
      const { variables: productsVariables2, mjml: finalProductsMjml2 } = rewriteVariables("products2-var", productsBlock2.attributes.variables, productsMjml2);

      return {
        mjml: `
          ${finalProductsMjml1}
          ${finalProductsMjml2}
          ${separatorBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${separatorBlock.label}"`)}
        `,
        wordings: {
          ...productsWordings1,
          ...productsWordings2,
        },
        variables: {
          ...productsVariables1,
          ...productsVariables2,
        }
      };
    }
    case 5: {
      const productsBlock1 = multiProducts4({ products: products.slice(0, 3), fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: imagesUrl.slice(0, 3), imagesHref: productHrefs.slice(0, 3), imagesAlt: [imageAlt, imageAlt, imageAlt], buttonsHref: productHrefs.slice(0, 3), buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }, 3).block();
      const productsBlock2 = multiProducts2({ products: products.slice(3), fontFamily, buttonTextColor, buttonsColor: [accentColor, accentColor, accentColor], textColor, backgroundColor: dominantColor, imagesUrl: imagesUrl.slice(3), imagesHref: productHrefs.slice(3), imagesAlt: [imageAlt, imageAlt, imageAlt], buttonsHref: productHrefs.slice(3), buttonRadius, currency, pricesIsVisible: [priceIsVisible, priceIsVisible, priceIsVisible], currencyBefore }, 2).block();
      const { wordings: productsWordings1, mjml: productsMjml1 } = rewriteWordings("products1", productsBlock1.attributes.wordings, productsBlock1.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${productsBlock1.label}"`));
      const { wordings: productsWordings2, mjml: productsMjml2 } = rewriteWordings("products2", productsBlock2.attributes.wordings, productsBlock2.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${productsBlock2.label}"`));

      const { variables: productsVariables1, mjml: finalProductsMjml1 } = rewriteVariables("products1-var", productsBlock1.attributes.variables, productsMjml1);
      const { variables: productsVariables2, mjml: finalProductsMjml2 } = rewriteVariables("products2-var", productsBlock2.attributes.variables, productsMjml2);
      return {
        mjml: `
          ${finalProductsMjml1}
          ${separatorLineBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${separatorLineBlock.label}"`)}
          ${finalProductsMjml2}
        `,
        wordings: {
          ...productsWordings1,
          ...productsWordings2,
        },
        variables: {
          ...productsVariables1,
          ...productsVariables2,
        }
      };
    }
  }
};