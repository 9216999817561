import { useEffect, useState } from "react";
// import { Translation } from "../../../common/Translation";

import classes from "./GeneratingImagesLoader.module.scss";

export const GeneratingImagesLoader = ({ minPercent, maxPercent, abortController, setLoadingCards }) => {

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (percent < minPercent) {
      setPercent(minPercent);
    }
  }, [percent, minPercent]);

  useEffect(() => {
    if (percent < maxPercent && percent < 100) {
      const timeout = setTimeout(() => {
        setPercent(prev => prev + 0.5);
      }, 1000);
      
      return () => clearTimeout(timeout);
    }
  }, [percent, maxPercent]);

  /* const abortGeneration = () => {
    abortController.abort("cancel");
    setLoadingCards(0);
  }; */

  return (
    <div className={classes.container}>
      <div className={classes.outsideBar}>
        <div className={classes.insideBar} style={{ width: `${percent}%` }} />
      </div>

      {/* {abortController && 
        <div className={classes.abortGenerationButtonContainer}>
          <span onClick={abortGeneration} className={`link ${classes.abortGenerationButton}`}><Translation id="generatingImagesLoader.abortButton" /></span>
        </div>
      } */}
    </div>
  );
};