import { useState, useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

import { PageBody } from "../common/PageBody/PageBody";
import { PageBodyWrapper } from "../common/PageBodyWrapper/PageBodyWrapper";
import { Translation } from "../../../common/Translation";
import { CustomButton } from "../../../common/Button/CustomButton";
import { useAuth } from "../../../../hooks/useAuth";

import { styles } from "./CmsCongrats.styles";

import { AccountContextBehaviorSubject$, reloadAccountContext } from "../../../../contexts/context";

const useStyles = makeStyles(styles);

export const CmsCongrats = (props) => {
  const [cms, setCms] = useState();

  const classes = useStyles();
  const intl = useIntl();
  const auth = useAuth();
  const { account } = AccountContextBehaviorSubject$.getValue();

  async function onSubmit() {
    auth.sendEventToAmplitude("congrats.click_lets_go");

    // Shopify requires more actions on the side of the CMS before increasing step_register
    if (cms === "shopify") {
      await auth.fetch("/api/account_management/updateAccount", {
        method: "POST",
        body: JSON.stringify({
          step_register: 3
        })
      });
      await reloadAccountContext();
    }

    await props.onSubmit();
  }

  useEffect(() => {
    setCms(account.CMS);
  }, [auth, setCms]);

  useEffect(() => {
    auth.sendEventToAmplitude("congrats.see_page");
  }, [auth]);

  return (
    <PageBodyWrapper withStepper>
      <PageBody style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: 620,
        padding: "90px 0px"
      }}>
        <div>
          <div className={classes.title}>
            {cms && <p className={"display2"}><Translation id="onBoarding.cmsCongrats.title" variables={{ CMS: cms.charAt(0).toUpperCase() + cms.slice(1) }} /></p>}
          </div>

          <p className={`text ${classes.subtitle}`}>{intl.messages["onBoarding.cmsCongrats.subtitle"]}</p>

          <div className={classes.buttonContainer}>
            <CustomButton
              onClick={onSubmit}
            >
              {intl.messages["button.letsgo"]}
            </CustomButton>
          </div>

        </div>
      </PageBody>
    </PageBodyWrapper>
  );
};
