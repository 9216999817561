import React, { Component } from "react";

import { injectIntl } from "react-intl";

import { Checkbox } from "../../../../../../common/Input/Checkbox.js";

export const MustRemoveObsoletePromoCodesInput = injectIntl(
  class extends Component {
    render() {
      const {
        intl,
        mustRemoveObsoletePromoCodes,
        setMustRemoveObsoletePromoCodes,
      } = this.props;

      return (
        <Checkbox
          checked={mustRemoveObsoletePromoCodes}
          onChange={(event) =>
            setMustRemoveObsoletePromoCodes(event.target.checked)
          }
          label={
            intl.messages[
              "usecase.promoCodeTemplateEditor.options.mustRemoveObsoletePromoCodes"
            ]
          }
          helperText={
            intl.messages[
              "usecase.promoCodeTemplateEditor.options.mustRemoveObsoletePromoCodes.helper"
            ]
          }
        />
      );
    }
  }
);
