import { makeStyles } from "@material-ui/core";
import { styles } from "./KiUsecasePicker.styles";
import { KiDropdown } from "../common/Input/KiDropdown";
import { useIntl } from "react-intl";

import { Checkbox } from "../common/Input/Checkbox";

const useStyles = makeStyles(styles);

export function KiUsecasePicker(props) {
  const classes = useStyles();
  const intl = useIntl();

  function getUsecasesByCategory() {
    const usecaseCategories = {
      all: [
        {
          key: "all",
          wording: intl.messages["usecase.allUsecases"],
        },
      ],
    };

    props.usecases?.forEach(usecase => {
      if (!usecaseCategories[usecase.category]) usecaseCategories[usecase.category] = [];

      usecaseCategories[usecase.category].push({
        key: usecase.name,
        wording: intl.messages[`usecasePicker.${usecase.name}`],
        checked: usecase.checked,
      });
    });

    return usecaseCategories;
  }

  function getSortedUsecasesByCategory() {
    const usecasesByCategory = getUsecasesByCategory();

    for (const categoryKey in usecasesByCategory) {
      /* sort alphabeticaly */
      usecasesByCategory[categoryKey].sort(function (a, b) {
        return a.wording.localeCompare(b.wording, intl.locale, {
          sensitivity: "base",
        });
      });
    }
    return usecasesByCategory;
  }

  function renderUsecasesByCategory() {
    const sortedUsecasesByCategory = getSortedUsecasesByCategory();

    return (
      <ul className={classes.usecasesByCategory}>
        {
          Object.keys(sortedUsecasesByCategory).map(category => {
            return (
              <li className={classes.category} key={category.key}>
                {
                  category === "all"
                    ? null
                    : (
                      <h3 className={classes.categoryLabel}>
                        {intl.messages[`usecases.${category}.title`]}
                      </h3>
                    )
                }
                <ul className={classes.usecases}>
                  {
                    sortedUsecasesByCategory[category]
                      .map(usecase => {
                        const allUsecasesAreChecked = Boolean(props.usecases && !props.usecases.find(usecase => !usecase.checked));
                        const someUsecasesAreChecked = Boolean(props.usecases && props.usecases.find(usecase => usecase.checked));

                        return (
                          <li
                            key={usecase.key}
                            className={classes.usecase}
                          >
                            <Checkbox
                              checked={usecase.key === "all" ? someUsecasesAreChecked : usecase.checked}
                              indeterminate={usecase.key === "all" && !allUsecasesAreChecked}
                              label={usecase.wording}
                              onChange={() => props.onUsecaseClick(usecase.key)}
                            />
                          </li>
                        );
                      })
                  }
                </ul>
              </li>
            );
          })
        }
      </ul>
    );
  }

  function getTitle() {
    const checkedUsecases = props.usecases && props.usecases.filter(usecase => usecase.checked);

    let title;

    if (!checkedUsecases || checkedUsecases.length === 0) {
      title = intl.messages["usecasePicker.noUsecase"];
    } else if (checkedUsecases.length === 1) {
      title = intl.messages["usecase." + checkedUsecases[0].name];
    } else if (checkedUsecases.length === props.usecases.length) {
      title = intl.messages["usecasePicker.allUsecases"];
    } else {
      title = intl.messages["usecasePicker.multipleUsecases"];
    }

    return title;
  }

  return (
    <KiDropdown
      label={getTitle()}
      onChange={(a) => { console.log(a); }}
      variant="table"
      placement={"bottom-start"}
      flipIsEnabled={false}
    >
      {renderUsecasesByCategory()}
    </KiDropdown>
  );
}
