
export const setUpBodyComponent = (editor) => {
  editor.Components.addType("mj-body", {
    view: {
      attributes: {
        style: "width: 100%",
      },
    }
  });
};

