import { palette } from "../../../../styles/palette";

export const styles = {
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 50,
  },
  subtitle: {
    paddingTop: 10,
  },
  cardsContainer: {
    display: "flex",
    gridGap: 60,
    justifyContent: "center"
  },
  cardContainer: {
    position: "relative",
    display: "flex",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: 30,
    width: 240,
    cursor: "pointer",
    position: "relative",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "center",
    padding: 20,
    backgroundColor: palette.neutralUltraLightGrey,
    textAlign: "center",
  },
  cardCatchphrase: {
    padding: 14,
    backgroundColor: palette.primaryYellow50,
  },
  icon: {
    fontWeight: "bold",
    fontSize: 70,
    marginBottom: 16,
  },
  description: {
    textAlign: "center",
  },
  catchphrase: {
    textAlign: "center",
  },
  goToUsecaseList: {
    textAlign: "center",
    textDecoration: "underline",
    marginTop: 90,
  },
  image: {
    position: "absolute",
    right: -35,
    top: -45,
    width: 48,
    height: 52,
  },
  status: {
    position: "absolute",
    right: 0,
    height: 25,
    top: 15,
    width: 70,
    paddingTop: 4,
    textAlign: "center",
    borderTopLeftRadius: 40,
    borderBottomLeftRadius: 40,
    fontSize: 14,
  },
  statusActif: {
    backgroundColor: palette.accentGreen100,
    color: palette.accentGreen500,
  },
  statusActifDate: {
    backgroundColor: palette.accentPurple100,
    color: palette.accentPurple500,
  }
};
