
export const setUpTextComponent = (editor) => {
  editor.Components.addType("mj-text", {
    model: {
      toHTML() {
        //Code from https://github.com/GrapesJS/mjml/blob/master/src/components/index.ts

        const model = this;
        const tag = model.get("tagName");
        const voidTag = model.get("void");
        const attr = this.getAttrToHTML();
        let code = "";
        let strAttr = "";

        for (const prop in attr) {
          const val = attr[prop];
          const hasValue = typeof val !== "undefined" && val !== "";
          strAttr += hasValue ? ` ${prop}="${val}"` : "";
        }

        code += `<${tag}${strAttr}${voidTag ? "/" : ""}>` + model.get("content");

        const props = model.get("script-props");
        if (props && props?.length && props[0].wordingId) {
          code += `{{${props[0].wordingId}}}`;
        } else {
          model.components().forEach(model => {
            code += model.toHTML();
          });
        }

        if (!voidTag) {
          code += `</${tag}>`;
        }

        return code;
      }
    },

    view: {
      events: {
        click: "clickOnElement",
        input: "onInput",
      },

      clickOnElement(event) {
        if (event.target.tagName !== "DIV" && 
            event.target.tagName !== "SPAN" && 
            event.target.tagName !== "B" && 
            event.target.tagName !== "STRIKE" && 
            event.target.tagName !== "U" && 
            event.target.tagName !== "I") {
          return this.onDisable();
        }

        const { em } = this;
        const parent = this.model.closest("[data-gjs*=\"kilibaBlock\"]");
        const column = this.model.closest("[data-gjs*=\"kilibaColumn\"]");
        const columnComponent = this.model.closest("[data-column-component*=\"true\"]");

        if (parent && parent.attributes.attributes["data-preview"] === "true") {
          return em.trigger("textPreviewClick");
        }

        if (parent && 
          (em.getSelected()?.getId() === parent.getId() || 
          (column && em.getSelected()?.getId() === column.getId()) ||
          (columnComponent && em.getSelected()?.getId() === columnComponent.getId())
          )) {
          this.onActive();
        }
      },
    }
  });
};