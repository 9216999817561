import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withAuth } from "../withAuth";
import { Card, CardActionArea, CardMedia } from "@material-ui/core";
import { EventSystem } from "../../eventsystem/EventSystem.js";
import { injectIntl } from "react-intl";

import { styles } from "../../styles/product/product.js";

export const BestProduct = withAuth(
  withStyles(styles)(
    injectIntl(
      class BestProduct extends Component {
        constructor(props) {
          super(props);
          this.handleClick = this.handleClick.bind(this);
          this.formatTitle = this.formatTitle.bind(this);
        }

        handleClick() {
          EventSystem.publish("best_product", this.props.name);
        }

        formatTitle(str) {
          if (str !== undefined && str !== null) {
            str = str.toLowerCase();
            str = str.length > 57 ? str.substring(0, 57) + "..." : str;
            return str.charAt(0).toUpperCase() + str.slice(1);
          } else {
            return "";
          }
        }

        render() {
          const { intl, classes, url, title, ca, active } = this.props;

          const cdnUrl = `https://production.cdn.kiliba.eu/files/${encodeURIComponent(btoa(url))}/get/500`;

          return (
            <div>
              <Card
                className={classes.card}
                style={{
                  backgroundImage: `url(${cdnUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <CardActionArea disabled={active ? false : true}>
                  <CardMedia className={classes.area}>
                    <div className={classes.area_text}>
                      {intl.messages["products.bestProducts.turnover"]}{" "}
                      <p style={{ fontSize: "12px", display: "inline" }}>
                        {ca}
                      </p>
                    </div>
                  </CardMedia>
                </CardActionArea>
              </Card>
              <div className={classes.title}>{this.formatTitle(title)}</div>
            </div>
          );
        }
      }
    )
  )
);

BestProduct.propTypes = {
  classes: PropTypes.object.isRequired,
};
