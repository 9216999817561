import { useState, useRef, useCallback, useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import { ClickAwayListener, Fade } from "@mui/material";
import { debounce } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { Translation } from "../../../common/Translation";
import { KiTextField } from "../../../common/Input/KiTextField";
import { TemplateEditorContext } from "../../TemplateEditorContext";
import { useAuth } from "../../../../hooks/useAuth";
import { CustomButton } from "../../../common/Button/CustomButton";
import { UploadImageInput } from "./UploadImageInput";

import classes from "./ProductFocusChooseProduct.module.scss";

export const ProductFocusChooseProduct = ({ setTransparentImage, setProductId, setUploadKey, setFormat, onError, transparentImage, scrollToPreview, setSelectedPosition, productId, format, setProductImageWidth }) => {

  const intl = useIntl();
  const anchor = useRef();
  const auth = useAuth();
  const [selectedTab, setSelectedTab] = useState("product");
  const [selectedFormat, setSelectedFormat] = useState("landscape");
  const [showProductSearchResults, setShowProductSearchResults] = useState(false);
  
  const [productSearch, setProductSearch] = useState("");  
  const [productSearchResults, setProductSearchResults] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const [selectedImage, setSelectedImage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { productsInTemplate } = useContext(TemplateEditorContext);

  const getTitleFromJson = useCallback((title) => title[intl.locale] || title.fr || title.en || title.es || title.de || title.nl || "", [intl]);

  useEffect(() => {
    if (!selectedProduct && productId && !isInitialized) {
      setIsInitialized(true);
      auth.fetch("/api/data/getProductsByIds", {
        method: "POST",
        body: JSON.stringify({
          productIds: [productId],
        })
      }).then(result => {
        if (result.length) {
          setSelectedProduct(result[0]);
          setSelectedFormat(format);
          setIsButtonDisabled(true);
        }
      });
    }
  }, [productId, selectedProduct, auth, isInitialized, format]);

  const onGenerate = () => {
    setIsLoading(true);
    const uploadKey = uuidv4();
    auth.fetch("/account_management/getTransparentImage", {
      method: "POST",
      body: JSON.stringify({
        productId: selectedTab === "product" ? selectedProduct.id_product : null,
        uploadKey: selectedTab === "image" ? uploadKey : null,
        file: selectedTab === "image" ? {
          content: selectedImage,
        } : null,
        isUploadFile: selectedTab === "image",
      }),
    }).then(result => {
      setIsLoading(false);
      setTransparentImage(result);
      if (result === transparentImage) {
        scrollToPreview();
      }
      setProductId(selectedTab === "product" ? selectedProduct.id_product : null);
      setUploadKey(selectedTab === "image" ? uploadKey : null);
      setSelectedPosition("center");
      setFormat(selectedFormat);
      setProductImageWidth(null);
    }).catch(() => {
      setIsLoading(false);
      onError();
    });
  };

  //eslint-disable-next-line
  const searchUsableProducts = useCallback(debounce((value) => {

    if (value) {
      auth.fetch("/data/searchUsableProducts", {
        method: "POST",
        body: JSON.stringify({
          searchText: value,
          limit: 4,
        })
      }).then(result => {
        setProductSearchResults(result);
      });
    } else {
      setProductSearchResults([]);
    }

  }, 500), [auth]);

  const onProductTextfieldChange = (value) => {
    const product = productSearchResults.find(product => product.id_product === value);
    setSelectedProduct(product);
    setIsButtonDisabled(false);
    const input = anchor.current?.querySelector("input");
    input?.blur();
    setShowProductSearchResults(false);
    setProductSearch("");
  };

  useEffect(() => {
    if (!productSearch) {
      setProductSearchResults(productsInTemplate);
    } else {
      searchUsableProducts(productSearch);
    }
  }, [productSearch, productsInTemplate, searchUsableProducts]);

  const onUpload = (fileData) => {
    setSelectedImage(fileData.content);    
    setIsButtonDisabled(false);
    setProductId(null);
  };

  return (
    <div className={classes.container}>
      <div className={`display5 ${classes.title}`}>
        <i className="fa-solid fa-arrows-rotate" />
        <Translation id="productFocusChooseProduct.title" />
      </div>
      <div className={`infoMedium ${classes.subtitle}`}><Translation id="productFocusChooseProduct.subtitle" /></div>

      <div className={classes.tabs}>
        <div className={`${classes.tab} ${selectedTab === "product" ? classes.selectedTab : ""}`} onClick={() => setSelectedTab("product")}>
          <div className="textMedium"><Translation id="productFocusChooseProduct.tab.product" /></div>
          {selectedTab === "product" && <div className={classes.tabUnderline}></div>}
        </div>

        <div className={`${classes.tab} ${selectedTab === "image" ? classes.selectedTab : ""}`} onClick={() => setSelectedTab("image")}>
          <div className="textMedium"><Translation id="productFocusChooseProduct.tab.image" /></div>
          {selectedTab === "image" && <div className={classes.tabUnderline}></div>}
        </div>
      </div>

      {selectedTab === "product" && 
        <div className={classes.chooseProductContainer}>
          <div className="textMedium"><Translation id="productFocusChooseProduct.tab.product.title" /></div>
          <div className={classes.productInputContainer} ref={anchor}>
            <KiTextField 
              placeholder={intl.messages["productMenu.input.placeholder"]}
              autoComplete="off"
              name="product_search"
              value={productSearch}
              handleChange={event => {
                setProductSearch(event.target.value);
              }}
              onFocus={() => setShowProductSearchResults(true)}
            />
            <ClickAwayListener onClickAway={event => !anchor?.current.contains(event?.target) && setShowProductSearchResults(false)}>
              <Fade in={showProductSearchResults}>
                <div className={`${classes.dropdownPopper} ${classes.dropdown}`} style={{ position: "absolute", top: anchor.current?.getBoundingClientRect().height + 5, left: 0 }}>
                  {productSearchResults.map(product => (
                    <div
                      key={product.id_product} 
                      className={`text ${classes.option} ${classes.option}`}
                      onClick={(event) => onProductTextfieldChange(product.id_product, event)}
                    >
                      <img alt="" src={product.image_url} height={30} className={classes.productImage} />
                      <span>{getTitleFromJson(product.title)}</span>
                    </div>
                  ))}
                </div>
              </Fade>
            </ClickAwayListener>
          </div>

          {selectedProduct &&
            <div className={classes.selectedProductContainer}>
              <img alt="" src={selectedProduct.image_url} height={25} />
              <div className={`info ${classes.selectedProductTitle}`}>{getTitleFromJson(selectedProduct.title)}</div>
              <div className={classes.selectedProductDeleteIcon} onClick={() => setSelectedProduct(null)}>
                <i className="fa-solid fa-times" />
              </div>
            </div>
          }
        </div>
      }

      {selectedTab === "image" && 
        <div>
          {!selectedImage ?
            <UploadImageInput 
              onUpload={onUpload}
              width={331}
              height={138}
            /> :
            <div className={classes.selectedImage}>
              <img alt="" src={`data:image/png;base64,${selectedImage}`} height={125} />
              <div onClick={() => setSelectedImage(null)} className={classes.iconDeleteImageContainer}>
                <i className="fa-solid fa-trash-can" />
              </div>
            </div>
          }
        </div>
      }

      <div className={`text ${classes.formatTitle}`}><Translation id="ambientImagesPromptForm.format" /></div>
      <div className={classes.formatsContainer}>
        {[
          { name: "landscape", style: { width: 90, height: 51.42 }  },
          { name: "portrait", style: { width: 51.42, height: 90 } },
          { name: "square", style: { width: 90, height: 90 } },
        ].map(elem => (
          <div
            key={elem.name}
            className={`${classes.formatContainer} ${selectedFormat === elem.name ? classes.itemSelected : ""}`}
            onClick={() => {
              setSelectedFormat(elem.name);
              setIsButtonDisabled(false);
            }}
          >
            <div className={classes.ratio} style={elem.style}>
              <div className="info"><Translation id={`ambientImagesPromptForm.format.${elem.name}`} /></div>
            </div>
          </div>
        ))}
      </div>

      <div className={classes.buttonContainer}>
        <CustomButton
          type="secondary"
          size="medium"
          loading={isLoading}
          onClick={onGenerate}
          disabled={isButtonDisabled || (selectedTab === "product" && !selectedProduct) || (selectedTab === "image" && !selectedImage)}
        >
          <div className={`textMedium ${classes.changeImageButtonTextColor}`}>
            <i className="fa-solid fa-sparkles" />
            <Translation id="productFocusChooseProduct.button" />
          </div>
        </CustomButton>
      </div>

    </div>
  );
};
