import { formatHtmlIntoShortText } from "../../../../../services/text.service";
import { getPriceFromProduct, getTitleTranslation, getDescriptionTranslation } from ".";

const LABEL = "multiProducts4";
const CATEGORY = "product:multiProducts";

export const multiProducts4 = (variables, nbProducts = 2) => ({
  label: `${LABEL}-${nbProducts}`,
  category: CATEGORY,
  block: (overrides) => {

    const {
      products,
      fontFamily, 
      buttonTextColor,
      buttonsColor,
      textColor,
      backgroundColor,
      imagesUrl,
      imagesHref,
      imagesAlt = ["", "", ""],
      buttonsHref,
      buttonsText,
      productsName,
      buttonRadius,
      pricesText,
      productsDescription,
      currency,
      pricesIsVisible,
      currencyBefore,
    } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };  

    if (!products || products.length !== nbProducts) {
      return {
        id: `${LABEL}-${nbProducts}`,
        label: `${LABEL}-${nbProducts}`,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${backgroundColor}" padding-top="0" padding-bottom="0" full-width="full-width">
        ${products.map((product, idx) => `
          <mj-section direction="${idx % 2=== 0 ? "ltr" : "rtl"}" data-id-product="${product.id_product}">
            <mj-column width="10%"></mj-column>
            <mj-column width="40%" vertical-align="middle">
              <mj-image href="{{imageHref${idx}}}" alt="{{imageAlt${idx}}}" padding-left="10px" padding-right="10px" css-class="multiProducts4-image" src="{{imageUrl${idx}}}"></mj-image>
            </mj-column>
            <mj-column width="40%" css-class="multiProducts4-info" vertical-align="middle">
              <mj-text font-family="${fontFamily}" padding-left="10px" padding-right="10px" padding-top="0px" padding-bottom="10px" align="center" line-height="16px" color="${textColor}" padding-bottom="10px" font-size="14px" font-weight="700" data-text-id="productName${idx}">{{productName${idx}}}</mj-text>
              <mj-text font-family="${fontFamily}" padding-left="10px" padding-left="10px"padding-right="10px" padding-top="0px" align="center" line-height="14px" color="${textColor}" padding-bottom="10px" font-size="12px" data-text-id="productDescription${idx}">{{description${idx}}}</mj-text>

              <mj-text ${!pricesIsVisible[idx] ? "css-class=\"priceHidden\"" : "" } data-product-id="${product.id_product}" data-readonly="true" font-family="${fontFamily}" padding-left="10px" padding-right="10px" padding-top="0px" align="center" font-weight="700" line-height="18px" color="${textColor}" padding-bottom="20px" font-size="16px" data-text-id="priceText${idx}">
                {{price${idx}}}
              </mj-text>

              <mj-button border-radius="${buttonRadius}" href="{{buttonHref${idx}}}" font-family="${fontFamily}" padding-left="10px" padding-right="10px" line-height="16px" color="${buttonTextColor}" font-size="14px" padding-top="0px" padding-bottom="0px" inner-padding="8px 15px" background-color="${buttonsColor[idx]}">
                {{button${idx}}}
              </mj-button>
            </mj-column>
            <mj-column width="10%"></mj-column>
          </mj-section> 
        `)}        
      </mj-wrapper>
    `;

    const wordings = {
      ...products.reduce((acc, product, idx) => ({
        ...acc,
        [`button${idx}`]: (buttonsText && buttonsText[idx]) || {
          fr: "Acheter",
          en: "Buy",
          es: "¡Comprar!",
          de: "Kaufen",
          nl: "Kopen",
          it: "Acquista",
          pt: "Comprar",
        },
        [`productName${idx}`]: (productsName && productsName[idx]) || {
          fr: getTitleTranslation("fr", product.title),
          en: getTitleTranslation("en", product.title),
          es: getTitleTranslation("es", product.title),
          de: getTitleTranslation("de", product.title),
          nl: getTitleTranslation("nl", product.title),
          it: getTitleTranslation("it", product.title),
          pt: getTitleTranslation("pt", product.title),
        },
        [`price${idx}`]: (pricesText && pricesText[idx]) || {
          fr: getPriceFromProduct(product, currency, currencyBefore),
          en: getPriceFromProduct(product, currency, currencyBefore),
          es: getPriceFromProduct(product, currency, currencyBefore),
          de: getPriceFromProduct(product, currency, currencyBefore),
          nl: getPriceFromProduct(product, currency, currencyBefore),
          it: getPriceFromProduct(product, currency, currencyBefore),
          pt: getPriceFromProduct(product, currency, currencyBefore),
        },
        [`description${idx}`]: (productsDescription && productsDescription[idx]) || {
          fr: formatHtmlIntoShortText(getDescriptionTranslation("fr", product.description)),
          en: formatHtmlIntoShortText(getDescriptionTranslation("en", product.description)),
          es: formatHtmlIntoShortText(getDescriptionTranslation("es", product.description)),
          de: formatHtmlIntoShortText(getDescriptionTranslation("de", product.description)),
          nl: formatHtmlIntoShortText(getDescriptionTranslation("nl", product.description)),
          it: formatHtmlIntoShortText(getDescriptionTranslation("it", product.description)),
          pt: formatHtmlIntoShortText(getDescriptionTranslation("pt", product.description)),
        },
      }), {}),
    };

    const _variables = {
      ...products.reduce((acc, product, idx) => ({
        ...acc,
        [`imageUrl${idx}`]: imagesUrl[idx],
        [`imageHref${idx}`]: imagesHref[idx],
        [`imageAlt${idx}`]: imagesAlt[idx],
        [`buttonHref${idx}`]: buttonsHref[idx],
      }), {}),
    };

    return {
      id: `${LABEL}-${nbProducts}`,
      label: `${LABEL}-${nbProducts}`,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        variables: _variables,
        wordings,
        editableProperties: [
          { key: "products", type: "products", label: "", group: "newsletter.rightMenu.propertyGroup.products", properties: [
            { key: "imagesUrl", type: "image", label: "newsletter.rightMenu.property.selectedImage", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "imagesHref", type: "link", label: "newsletter.rightMenu.property.imageLink", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "imagesAlt", type: "string", label: "newsletter.rightMenu.property.alt", group: "newsletter.rightMenu.propertyGroup.image" },
            { key: "buttonsHref", type: "link", label: "newsletter.rightMenu.property.buttonLink", group: "newsletter.rightMenu.propertyGroup.button" },
            { key: "buttonsColor", type: "color", label: "newsletter.rightMenu.property.buttonColor", group: "newsletter.rightMenu.propertyGroup.button" },
            { key: "pricesIsVisible", type: "price", label: "", group: "newsletter.rightMenu.property.priceIsVisible" },
          ], keys: ["products", "imagesHref", "imagesAlt", "imagesUrl", "buttonsHref", "buttonsColor", "buttonsText", "productsName", "pricesText", "productsDescription", "pricesIsVisible"]},
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ],
      }
    };
  },
  mediaQueryStyle: `
    @media only screen and (min-width:480px) {
      .multiProducts4-info td div {
        text-align: left !important;
      }

      .multiProducts4-image {
        float: left;
      }

      .multiProducts4-info td {
        float: left;
      }      
    }    
  `,
  style: `
    .multiProducts4-image td {
      width: inital !important;
    }
    
    .multiProducts4-image img {
      max-width: 200px;
      max-height: 200px;
      margin: 0 auto;
    }

    .priceHidden {
      display: none !important;
      visibility:hidden;
      mso-hide:all;
      font-size:1px;
      line-height:1px;
      max-height:0px;
      max-width:0px;
      opacity:0;
      overflow:hidden;
    }
  `,
});