import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { PopupTO } from "../../../PopupTO.js";
import { DayPicker } from "../../../pickers/DayPicker.js";

import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { Radio, RadioGroup } from "@material-ui/core";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";

import { FormattedMessage, injectIntl } from "react-intl";

import {
  getMidnightDate,
  getIncrementedDate,
  getDecrementedDate,
} from "../../../../tools/date.js";

import { palette } from "../../../../styles/palette.js";
import { withAuth } from "../../../withAuth.js";

const styles = (theme) => ({
  popUpTextStyle: {
    marginTop: "10px",
    height: "auto",
    fontSize: "14px",
    fontFamily: "Coolvetica Book",
    color: "#0E0E0F",
  },
  label: {
    "&$label": {
      fontFamily: "Coolvetica Book",
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "1.57",
      letterSpacing: "normal",
      color: palette.black,
    },
  },
  checkbox_root: {},
  checkbox: {
    color: "gray",
    marginTop: "-4px",
    marginLeft: "2px",
  },
  checked: {
    color: palette.primaryBlack,
    "&$checked": {
      color: palette.primaryBlack,
    },
  },
  error_text: {
    height: "6px",
    fontFamily: "Coolvetica Book",
    fontSize: "10px",
    position: "relative",
    top: "-10px",
    left: "3px",
    color: palette.red,
  },
  customFormContainer: {
    height: "100%",
    paddingLeft: "68px",
    position: "relative",
    flexGrow: "1",
    paddingBottom: "30px",
    marginTop: "10px",
  },
  customFormLabelContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "32px",
  },
  customFormInputContainer: {
    marginLeft: "16px",
  },
});

export const UsecasePopupLaunch = withAuth(
  injectIntl(
    class UsecasePopupLaunch extends Component {
      constructor(props) {
        super(props);
        this.save = this.save.bind(this);
        this.state = {
          mustLaunchASAP: !props.hasEnd,
          plannedLaunchDate: props.launchDate && new Date(props.launchDate),
          plannedEndDate: props.endDate && new Date(props.endDate),
          isLegalDateChecked: !props.hasEnd,
          isLegalDateError: false,
          customDate: props.customDate,
        };
      }

      save() {
        this.props.auth.sendEventToAmplitude("activation_popup.click_activate");
        const tomorrow = getMidnightDate(getIncrementedDate(new Date(), 1));
        const {
          isLegalDateChecked,
          mustLaunchASAP,
          plannedLaunchDate,
          plannedEndDate,
          customDate,
        } = this.state;
        if (isLegalDateChecked) {
          const launchDate = mustLaunchASAP ? tomorrow : plannedLaunchDate;
          this.props.save({
            launchDate: this.props.usecase === "custom" ? customDate : launchDate,
            endDate: plannedEndDate,
            isLegalDateChecked,
          });
        } else {
          this.setState({ isLegalDateError: true });
        }
      }

      render() {
        const {
          intl,
          close,
          usecase,
          emailExamples,
          hasEnd,
          isActive,
          deactivateUsecase,
          showPromoCodeTemplateEditor,
        } = this.props;

        const popup_usecase_name = "popup.wording_launch_" + usecase; // popup qui gère le wording du usecase
        const buttonStyle = {
          boxShadow: "none",
          width: "210px",
        };

        const hasPromoCode = !!(
          emailExamples && emailExamples[0].promoCodeTemplate
        );

        const saveButtonProps = {
          onClick: this.save,
          label: isActive
            ? intl.messages["button.modify"]
            : intl.messages["button.activate_usecase"],
          alternate: false,
          style: buttonStyle,
        };
        const promoCodeTemplateButtonProps = {
          onClick: () => showPromoCodeTemplateEditor(0),
          label: hasPromoCode
            ? intl.messages["button.editPromoCodeTemplate"]
            : intl.messages["button.addPromoCodeTemplate"],
          alternate: false,
          style: buttonStyle,
        };
        const buttonProps = [];

        if (!(usecase === "sponsorship" && !hasPromoCode))
          buttonProps.push(saveButtonProps);
        if (usecase === "sponsorship")
          buttonProps.push(promoCodeTemplateButtonProps);

        return (
          <PopupTO
            open
            label={popup_usecase_name}
            title="popup.title_launch"
            handleClose={close}
            form={
              <UsecasePopupLaunchForm
                usecase={usecase}
                isActive={isActive}
                hasEnd={hasEnd}
                isLegalDateChecked={this.state.isLegalDateChecked}
                switchIsLegalDateChecked={() =>
                  this.setState({
                    isLegalDateChecked: !this.state.isLegalDateChecked,
                  })
                }
                isLegalDateError={this.state.isLegalDateError}
                mustLaunchASAP={this.state.mustLaunchASAP}
                setMustLaunchASAP={(value) =>
                  this.setState({ mustLaunchASAP: value })
                }
                plannedLaunchDate={this.state.plannedLaunchDate}
                setPlannedLaunchDate={(value) =>
                  this.setState({ plannedLaunchDate: getMidnightDate(value) })
                }
                plannedEndDate={this.state.plannedEndDate}
                setPlannedEndDate={(value) =>
                  this.setState({ plannedEndDate: getMidnightDate(value) })
                }
                customDate={this.state.customDate}
                setCustomDate={(value) => this.setState({ customDate: value })}
              />
            }
            deactivateUsecase={hasEnd && isActive ? deactivateUsecase : null}
            buttons={buttonProps}
          />
        );
      }
    }
  )
);

const UsecasePopupLaunchForm = withAuth(
  withStyles(styles)(
    injectIntl(
      class UsecasePopupLaunchForm extends Component {
        render() {
          const {
            classes,
            intl,
            usecase,
            hasEnd,
            mustLaunchASAP,
            setMustLaunchASAP,
            plannedLaunchDate,
            setPlannedLaunchDate,
            plannedEndDate,
            setPlannedEndDate,
            switchIsLegalDateChecked,
            isLegalDateChecked,
            isLegalDateError,
            isActive,
            customDate,
            setCustomDate,
          } = this.props;

          const today = getMidnightDate(new Date()).getTime();
          const modifyEndDateMax = getMidnightDate(
            getDecrementedDate(plannedEndDate, 3)
          ).getTime();
          let minEndDate = getIncrementedDate(plannedLaunchDate, 10);

          // si modification de la date de fin
          if (isActive && today <= modifyEndDateMax) {
            minEndDate = getIncrementedDate(today, 3);
          }

          const defaultForm = (
            <form
              style={{
                zIndex: "10000",
                height: "100%",
                paddingLeft: "68px",
                position: "relative",
                flexGrow: "1",
                paddingBottom: "30px",
                marginTop: "10px",
              }}
            >
              <FormControl component="fieldset">
                <RadioGroup
                  name="useless"
                  className={classes.group}
                  value={mustLaunchASAP}
                  onChange={(event) => {
                    if (event.target.value === "false") {
                      this.props.auth.sendEventToAmplitude("activation_popup.click_pick_date");
                    } else {
                      this.props.auth.sendEventToAmplitude("activation_popup.click_from_tomorrow");
                    }
                    setMustLaunchASAP(event.target.value === "true");
                  }}
                >
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    checked={mustLaunchASAP}
                    value={true}
                    label={intl.messages["checkbox.asap"]}
                    control={
                      <Radio
                        color={"default"}
                        disableRipple
                        classes={{
                          root: classes.checkbox_root,
                          checked: classes.checked,
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    checked={!mustLaunchASAP}
                    value={false}
                    label={intl.messages["checkbox.from"]}
                    control={
                      <Radio
                        color="default"
                        disableRipple
                        classes={{
                          root: classes.checkbox_root,
                          checked: classes.checked,
                        }}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
              <div
                style={{ position: "absolute", left: "190px", bottom: "35px" }}
              >
                <DayPicker
                  onClick={() => this.props.auth.sendEventToAmplitude("activation_popup.click_pick_date")}
                  futureOnly
                  active={plannedLaunchDate}
                  onDayClick={(value) => {
                    setMustLaunchASAP(false);
                    setPlannedLaunchDate(value);
                  }}
                  idStartDate="details"
                />
              </div>
            </form>
          );

          const formWithEnd = (
            <form
              style={{
                zIndex: "10000",
                height: "100%",
                paddingLeft: "60px",
                position: "relative",
                flexGrow: "1",
                marginTop: "20px",
                marginLeft: "9px",
              }}
            >
              <Grid container direction="row" spacing={2}>
                <Grid item className={classes.popUpTextStyle}>
                  {intl.messages["usecasePopupLaunch.startDate.0"]}
                  <FormattedMessage id={"usecase." + usecase} />
                  {intl.messages["usecasePopupLaunch.startDate.1"]}
                </Grid>
                <Grid item>
                  <DayPicker
                    onClick={() => this.props.auth.sendEventToAmplitude("activation_popup.click_pick_date")}
                    disabled={plannedLaunchDate.getTime() <= today}
                    futureOnly
                    active={plannedLaunchDate}
                    onDayClick={setPlannedLaunchDate}
                    idStartDate="details"
                  />
                </Grid>
                <Grid item className={classes.popUpTextStyle}>
                  Fin :
                </Grid>
                <Grid item>
                  <DayPicker
                    onClick={() => this.props.auth.sendEventToAmplitude("activation_popup.click_pick_date")}
                    disabled={modifyEndDateMax < today}
                    active={plannedEndDate}
                    minDate={minEndDate}
                    onDayClick={setPlannedEndDate}
                    idStartDate="details"
                    endDate
                  />
                </Grid>
              </Grid>
              <Grid
                id="confirmDatesAreLegal"
                container
                direction="row"
                style={{
                  marginTop: "20px",
                  marginLeft: "-15px",
                  marginBottom: "0px",
                }}
              >
                <Grid item>
                  <Checkbox
                    classes={{ root: classes.checkbox, checked: classes.checked }}
                    style={{ padding: 12 }}
                    checked={isLegalDateChecked}
                    onChange={switchIsLegalDateChecked}
                  />
                </Grid>
                <Grid
                  id="confirmDatesAreLegal-error"
                  item
                  className={classes.popUpTextStyle}
                >
                  {intl.messages["popup.soldes_dates_confirm"]}
                </Grid>
              </Grid>
              {isLegalDateError ? (
                <FormHelperText className={classes.error_text}>
                  {intl.messages["field.mandatory"]}
                </FormHelperText>
              ) : null}
            </form>
          );

          const customForm = (
            <div className={classes.customFormContainer}>
              <div className={classes.customFormLabelContainer}>
                {intl.messages["usecasePopupLaunch.customUsecase.date"]}
                <div className={classes.customFormInputContainer}>
                  <DayPicker
                    onClick={() => this.props.auth.sendEventToAmplitude("activation_popup.click_pick_date")}
                    futureOnly
                    active={customDate}
                    onDayClick={(value) => {
                      const finalDate = getMidnightDate(value);
                      setCustomDate(
                        finalDate.getTime() -
                        finalDate.getTimezoneOffset() * 60000
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          );

          if (usecase === "custom") {
            return customForm;
          }

          return hasEnd ? formWithEnd : defaultForm;
        }
      }
    )
  )
);
