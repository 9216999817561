import { useContext, useEffect, useState, useCallback } from "react";
import { TemplateEditorContext } from "../../TemplateEditorContext";
import { Translation } from "../../../common/Translation";
import { CustomButton } from "../../../common/Button/CustomButton";
import { ImageBentoLibrary } from "./ImageBentoLibrary";
import { IAImageMenu } from "./IAImagesMenu";
import { SelectedImage } from "./SelectedImage";
import { MyImageLibrary } from "./MyImageLibrary";
import { GeneratedImagesLibrary } from "./GeneratedImagesLibrary";
import { useCleanProductFocusPreviewImageGaleryMenu } from "./ProductFocusGenerator";

import classes from "./ImageGaleryMenu.module.scss";

export const imagePreview = "https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/imagePreview.png";

export const useImageGaleryMenu = (editMenuInfo, isOpened, addBlockCurrentStep) => {

  const { hideHeader, showHeader, isHeaderHidden, hideBlockActions, showBlockActions, setBackgroundToDark, resetBackground } = useContext(TemplateEditorContext);

  useEffect(() => {
    if ((editMenuInfo?.type === "image" || addBlockCurrentStep?.name === "image") && isOpened) {
      if (!isHeaderHidden) {
        hideHeader();
        hideBlockActions();
        setBackgroundToDark();
      }
    } else {
      if (isHeaderHidden) {
        showHeader();
        showBlockActions();
        resetBackground();
      }
    }
  }, [editMenuInfo, isOpened, hideHeader, showHeader, isHeaderHidden, hideBlockActions, showBlockActions, setBackgroundToDark, resetBackground, addBlockCurrentStep]);
};

export const ImageGaleryMenu = ({ goBack, selectedUrl, onChange, openCropMenu, scrollableContainerClass, canvasContainerRef, component, editor }) => {  

  const [subMenu, setSubMenu] = useState(null);
  const [needScroll, setNeedScroll] = useState(false);
  const [generatedImagesLibraryType, setGeneratedImagesLibraryType] = useState(null);

  useCleanProductFocusPreviewImageGaleryMenu(subMenu, editor);

  const scrollToImageInTemplate = useCallback(() => {

    if (!component) {
      return ;
    }

    const scrollingContainer = canvasContainerRef.current.parentNode;
    
    scrollingContainer.scrollTo({
      left: 0,
      top: component.getEl()?.getBoundingClientRect().top,
      behavior:  "smooth",
    }); 

  }, [canvasContainerRef, component]);
  
  const _onChange = useCallback((imageUrl) => {    
    scrollToImageInTemplate();
    onChange(imageUrl);
  }, [onChange, scrollToImageInTemplate]);

  const onDelete = useCallback((urls) => {
    if (urls.includes(selectedUrl)) {
      scrollToImageInTemplate();
      onChange(imagePreview);
    }
  }, [selectedUrl, scrollToImageInTemplate, onChange]);

  useEffect(() => {
    if (needScroll && !subMenu) {
      const elem = document.getElementById("iaImageMenu");
      const container = document.getElementsByClassName(scrollableContainerClass)[0];

      setTimeout(() => {
        container.scrollTo({
          top: elem.getBoundingClientRect().top + 50,
          behavior:  "smooth",
        });
      }, 200);
      setNeedScroll(false);
    }
  }, [needScroll, subMenu, scrollableContainerClass]);

  useEffect(() => {
    const container = document.getElementsByClassName(scrollableContainerClass)[0];
    let value = 0;
    if (subMenu === "generatedImagesLibrary") {
      value = 225;
    };
    
    container.scrollTo({ top: value });
  }, [subMenu, scrollableContainerClass]);

  return (
    <div className={classes.container}>
      <div className={classes.fixedHeader}>
        {subMenu ?
          <div className={classes.subMenuHeader}>
            <div>
              <div className={classes.backString} onClick={() => setSubMenu(null)}>
                <i className="fa-solid fa-angle-left" />
                <h3><Translation id="dialog.back" /></h3>
              </div>
            </div>
            <div className={`display5 ${classes.title}`}>
              <i className="fa-solid fa-sparkles" />
              <Translation id="imageGaleryMenu.title" />
            </div>
          </div> :
          <>
            <div className={classes.headerButtons}>
              <CustomButton
                size="xxs"
                type="tertiary"
                onClick={goBack}
              >
                <Translation id="dialog.back" />
              </CustomButton>
            </div>
            <div className={`display3 ${classes.title}`}>
              <i className="fa-solid fa-sparkles" />
              <Translation id="imageGaleryMenu.title" />
            </div>
          </>
        }
      </div>
      <div className={classes.content}>

        {subMenu ?
          <>
            {subMenu === "myLibrary" && <MyImageLibrary selectedUrl={selectedUrl} onChange={imageUrl => _onChange(imageUrl, "upload")} openCropMenu={openCropMenu} onDelete={onDelete} />}
            {subMenu === "generatedImagesLibrary" && <GeneratedImagesLibrary type={generatedImagesLibraryType} setType={setGeneratedImagesLibraryType} setSubMenu={setSubMenu} selectedUrl={selectedUrl} openCropMenu={openCropMenu} onChange={_onChange} scrollableContainerClass={scrollableContainerClass} component={component} editor={editor} />}
          </>
          :
          <>
            <SelectedImage selectedUrl={selectedUrl} openCropMenu={openCropMenu} />
            <ImageBentoLibrary selectedUrl={selectedUrl} onSeeMore={() => setSubMenu("myLibrary")} onChange={imageUrl => _onChange(imageUrl, "upload")} />
            <div id="iaImageMenu">
              <IAImageMenu 
                openGeneratedImageLibrary={(type) => {
                  setGeneratedImagesLibraryType(type);
                  setSubMenu("generatedImagesLibrary");
                }}
              />
            </div>
          </>
        }
      </div>
    </div>
  );
};
