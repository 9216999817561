import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { withAuth } from "../../../../withAuth";
import { Dialog } from "../../../../common/Dialog.js";
import { EventSystem } from "../../../../../eventsystem/EventSystem.js";

import { palette } from "../../../../../styles/palette.js";
import { lighten } from "@material-ui/core/styles/colorManipulator";

import {
  getMidnightDate,
  getIncrementedDate,
} from "../../../../../tools/date.js";

import { RadioGroup } from "../../../../common/RadioGroup.js";
import { Radio } from "../../../../common/Input/Radio/Radio.js";

import { TemplateEditorAutomatic } from "./TemplateEditorAutomatic.js";
import { TemplateEditorManual } from "./TemplateEditorManual.js";
import { ConfirmationDialog } from "../../../../common/ConfirmationDialog.js";

const styles = (theme) => ({
  tabs: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: 16,
    width: "100%",
    marginBottom: 40,
  },
  tab: {
    display: "flex",
    border: `1px solid ${lighten(palette.black, 0.85)}`,
    borderRadius: 5,
    padding: "10px 20px",
    cursor: "pointer",
    "&.active": {
      borderColor: palette.primaryYellow200,
    },
    "& .MuiIconButton-root": {
      "&:hover": {
        backgroundColor: "initial !important",
      },
    },
    "&:hover": {
      backgroundColor: palette.primaryYellow15,
      borderColor: palette.primaryYellow200,
    },
  },
  tabLabel: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
  },
  tabLabelTitle: {
    fontWeight: 600,
  },
});

export const PromoCodeTemplateEditor = withStyles(styles)(
  withAuth(
    injectIntl(
      class PromoCodeTemplateEditor extends Component {
        constructor(props) {
          super(props);

          this.save = this.save.bind(this);
          this.delete = this.delete.bind(this);
          this.getError = this.getError.bind(this);
          this.setPromoCodeTemplateWrapperProp =
            this.setPromoCodeTemplateWrapperProp.bind(this);
          this.getFinalData = this.getFinalData.bind(this);
          this.changeTab = this.changeTab.bind(this);

          this.state = this.initialState;
        }

        componentDidUpdate(prevProps, prevState) {
          if (
            JSON.stringify(this.props.promoCodeTemplate) !==
              JSON.stringify(prevProps.promoCodeTemplate) ||
            JSON.stringify(this.props.mustRemoveObsoletePromoCodes) !==
              JSON.stringify(prevProps.mustRemoveObsoletePromoCodes)
          ) {
            this.setState(this.initialState);
          }
        }

        get initialState() {
          const { promoCodeTemplate } = this.props;

          const defaultDateFrom = getMidnightDate(new Date());
          const defaultDateTo = getIncrementedDate(
            getMidnightDate(
              promoCodeTemplate && promoCodeTemplate.dateFrom
                ? new Date(promoCodeTemplate.dateFrom)
                : defaultDateFrom
            ),
            7
          );

          const defaultPromoCodeTemplate = {
            isGeneric: true,
            code: null,
            value: null,
            type: "%",
            includesTaxes: true,
            includesFreeShipping: false,
            mustShowDates: true,
            showHeadbandPromo: true,
          };

          let formattedPromoCodeTemplate;

          if (promoCodeTemplate) {
            formattedPromoCodeTemplate = JSON.parse(
              JSON.stringify(promoCodeTemplate)
            );

            // remove these datas since they are manipulated by the alternative data structure explained below
            // these properties will be given a value on saving. until then, they must not be set.
            delete formattedPromoCodeTemplate.includesMinimumAmountTaxes;
            delete formattedPromoCodeTemplate.dateFrom;
            delete formattedPromoCodeTemplate.dateTo;
            delete formattedPromoCodeTemplate.lifetime;
            delete formattedPromoCodeTemplate.minimumAmount;
            delete formattedPromoCodeTemplate.quantity;
          } else {
            formattedPromoCodeTemplate = defaultPromoCodeTemplate;
          }

          // The promo code template is wrapped in an object that contains the promo code template and also
          // properties corresponding to some of the promo code template properties.
          // These properties' data structures are different from their equivalent in the promo code template in a way
          // that enables this component to show all their possible values simultaneously.
          // Ultimately, the values to save are determined when submitting, based on what's in these special data structures.
          return {
            tabKey:
              promoCodeTemplate &&
                  !promoCodeTemplate.mustCreatePromoCodesInCms
                ? "manual"
                : "automatic",
              
            promoCodeTemplateWrapper: {
              // standard promo code template structure
              promoCodeTemplate: formattedPromoCodeTemplate,
              // special data structures
              temporalityChoices: {
                fixed: {
                  dateFrom:
                    (promoCodeTemplate && promoCodeTemplate.dateFrom) ||
                    defaultDateFrom.toISOString(),
                  dateTo:
                    (promoCodeTemplate && promoCodeTemplate.dateTo) ||
                    defaultDateTo.toISOString(),
                  lifetime: null,
                },
                lifetime: {
                  dateFrom: null,
                  dateTo: null,
                  lifetime:
                    (promoCodeTemplate && promoCodeTemplate.lifetime) || null,
                },
                eternal: {
                  dateFrom:
                    (promoCodeTemplate && promoCodeTemplate.dateFrom) ||
                    defaultDateFrom.toISOString(),
                  dateTo: null,
                  lifetime: null,
                },
                untilDate: {
                  dateTo:
                    (promoCodeTemplate && promoCodeTemplate.dateFrom) ||
                    defaultDateFrom.toISOString(),
                  dateFrom: null,
                  lifetime: null,
                }
              },
              selectedTemporalityChoiceKey:
                promoCodeTemplate && promoCodeTemplate.lifetime
                  ? "lifetime"
                  : promoCodeTemplate && promoCodeTemplate.dateTo === null
                  ? "eternal"
                  : promoCodeTemplate && promoCodeTemplate.dateTo && promoCodeTemplate.dateFrom === null
                  ? "untilDate"
                  : "fixed",
              minimumAmount: promoCodeTemplate
                ? promoCodeTemplate.minimumAmount
                : null,
              quantity: promoCodeTemplate ? promoCodeTemplate.quantity : null,
              mustUseMinimumAmount: promoCodeTemplate
                ? !!promoCodeTemplate.minimumAmount
                : false,
              mustUseQuantity: promoCodeTemplate
                ? !!promoCodeTemplate.quantity
                : false,
            },
            errors: [],
            mustRemoveObsoletePromoCodes:
              this.props.mustRemoveObsoletePromoCodes !== undefined
                ? this.props.mustRemoveObsoletePromoCodes
                : true,
            confirmDeletionPopupIsOpen: false,
          };
        }

        setPromoCodeTemplateWrapperProp(keysString, value) {
          this.setState((prevState) => {
            const newPromoCodeTemplateWrapper = JSON.parse(
              JSON.stringify(prevState.promoCodeTemplateWrapper)
            );

            const keys = keysString.split(".");

            let target = newPromoCodeTemplateWrapper;

            keys.forEach((key, indexKey) => {
              const isLastKey = keys.length - 1 === indexKey;
              if (isLastKey) {
                target[key] = value;
              } else {
                target = target[key];
              }
            });

            return { promoCodeTemplateWrapper: newPromoCodeTemplateWrapper };
          });
        }

        async getErrors(promoCodeTemplateWrapper) {
          const { auth, intl, account } = this.props;
          const {
            promoCodeTemplate,
            temporalityChoices,
            selectedTemporalityChoiceKey,
            minimumAmount,
            quantity,
            mustUseMinimumAmount,
            mustUseQuantity,
          } = promoCodeTemplateWrapper;

          const errors = [];
          const missesValue = !Number(promoCodeTemplate.value);
          const missesCode = !promoCodeTemplate.code;
          const missesLifetime = selectedTemporalityChoiceKey === "lifetime" && !Number(temporalityChoices.lifetime.lifetime);
          const missesTemporality = account.CMS === "woocommerce" && selectedTemporalityChoiceKey !== "lifetime" && selectedTemporalityChoiceKey !== "untilDate";

          const missesMinimumAmount = mustUseMinimumAmount && !minimumAmount;
          const missesQuantity = mustUseQuantity && !quantity;
          const alreadyExistingPromoCodeTemplate = await auth.fetch(
            `/api/usecase/getPromoCodeTemplate?code=${promoCodeTemplate.code}`,
            { method: "GET" }
          );
          const promoCodeTemplateAlreadyExists =
            !!alreadyExistingPromoCodeTemplate;
          const alreadyExistingPromoCodeTemplateIsTheOneBeingEdited =
            promoCodeTemplateAlreadyExists &&
            promoCodeTemplate._id === alreadyExistingPromoCodeTemplate._id;

          if (missesValue)
            errors.push({
              field: "value",
              message:
                intl.messages[
                  "usecase.promoCodeTemplateEditor.value.errors.default"
                ],
            });
          if (missesCode)
            errors.push({
              field: "code",
              message:
                intl.messages[
                  "usecase.promoCodeTemplateEditor.code.errors.default"
                ],
            });
          if (missesLifetime)
            errors.push({
              field: "lifetime",
              message:
                intl.messages[
                  "usecase.promoCodeTemplateEditor.temporality.lifetime.errors.default"
                ],
            });
          if (missesTemporality)
            errors.push({
              field: "untilDate",
              message:
                intl.messages[
                  "usecase.promoCodeTemplateEditor.temporality.lifetime.errors.default"
                ],
            });
          if (missesMinimumAmount)
            errors.push({
              field: "minimumAmount",
              message:
                intl.messages[
                  "usecase.promoCodeTemplateEditor.options.minimumAmount.errors.default"
                ],
            });
          if (missesQuantity)
            errors.push({
              field: "quantity",
              message:
                intl.messages[
                  "usecase.promoCodeTemplateEditor.options.quantity.errors.default"
                ],
            });

          if (
            promoCodeTemplateAlreadyExists &&
            !alreadyExistingPromoCodeTemplateIsTheOneBeingEdited
          )
            errors.push({
              field: "code",
              message:
                intl.messages[
                  "usecase.promoCodeTemplateEditor.code.errors.duplicate"
                ],
            });

          return errors;
        }

        // Get the data to save. Some values to save are stored in data structures specific to this component.
        // Before saving the data, these values must be translated into an acceptable format.
        getFinalData() {
          const { promoCodeTemplateWrapper, tabKey } = this.state;
          const newPromoCodeTemplateWrapper = JSON.parse(
            JSON.stringify(promoCodeTemplateWrapper)
          );
          const {
            temporalityChoices,
            selectedTemporalityChoiceKey,
            minimumAmount,
            quantity,
            mustUseMinimumAmount,
            mustUseQuantity,
          } = newPromoCodeTemplateWrapper;

          const newPromoCodeTemplate =
            newPromoCodeTemplateWrapper.promoCodeTemplate;

          newPromoCodeTemplate.dateFrom =
            temporalityChoices[selectedTemporalityChoiceKey].dateFrom;
          newPromoCodeTemplate.dateTo =
            temporalityChoices[selectedTemporalityChoiceKey].dateTo;
          newPromoCodeTemplate.lifetime =
            temporalityChoices[selectedTemporalityChoiceKey].lifetime;

          newPromoCodeTemplate.quantity = mustUseQuantity ? quantity : null;
          newPromoCodeTemplate.minimumAmount = mustUseMinimumAmount
            ? minimumAmount
            : null;

          newPromoCodeTemplate.includesMinimumAmountTaxes =
            newPromoCodeTemplate.includesTaxes;

          newPromoCodeTemplate.mustCreatePromoCodesInCms =
            tabKey === "automatic";

          return newPromoCodeTemplate;
        }

        async save() {
          const { intl, save, close } = this.props;
          const { promoCodeTemplateWrapper, mustRemoveObsoletePromoCodes } =
            this.state;

          try {
            const newErrors = await this.getErrors(promoCodeTemplateWrapper);
            const finalData = this.getFinalData();

            this.setState({ errors: newErrors });

            if (newErrors.length) {
              EventSystem.newNotification(
                "notification.error",
                intl.messages["usecase.promoCodeTemplateEditor.hasErrors"]
              );
            } else {
              await save(finalData, mustRemoveObsoletePromoCodes);
              close();
            }
          } catch (error) {
            console.error(error);
          }
        }

        async delete() {
          try {
            await this.props.delete(
              this.state.promoCodeTemplateWrapper.promoCodeTemplate._id
            );
            this.props.close();
          } catch (error) {
            console.error(error);
          }
        }

        getError(key) {
          const error = this.state.errors.find((error) => error.field === key);
          return error || null;
        }

        changeTab(newTabKey) {
          this.setState((prevState) => {
            const newState = JSON.parse(JSON.stringify(prevState));
            newState.tabKey = newTabKey;
            newState.errors = [];
            if (
              prevState.tabKey === "automatic" &&
              newState.tabKey === "manual"
            ) {
              if (
                newState.promoCodeTemplateWrapper
                  .selectedTemporalityChoiceKey === "lifetime"
              ) {
                newState.promoCodeTemplateWrapper.selectedTemporalityChoiceKey =
                  "fixed";
              }
              newState.promoCodeTemplateWrapper.promoCodeTemplate.isGeneric =
                null;
              newState.promoCodeTemplateWrapper.promoCodeTemplate.includesTaxes =
                null;
              newState.promoCodeTemplateWrapper.mustUseMinimumAmount = null;
              newState.promoCodeTemplateWrapper.minimumAmount = null;
            } else if (
              prevState.tabKey === "manual" &&
              newState.tabKey === "automatic"
            ) {
              newState.promoCodeTemplateWrapper.promoCodeTemplate.isGeneric = true;
              newState.promoCodeTemplateWrapper.promoCodeTemplate.includesTaxes = true;
              newState.promoCodeTemplateWrapper.mustUseMinimumAmount = false;
              newState.promoCodeTemplateWrapper.minimumAmount = null;
            }
            return newState;
          });
        }

        render() {
          const { intl, account, isOpen, close, usecaseName } = this.props;

          const promoCodeTemplateWrapper = this.state.promoCodeTemplateWrapper;

          const buttonProps = [
            {
              label: intl.messages["usecase.promoCodeTemplateEditor.ctas.save"],
              onClick: this.save,
              type: "primary",
              id: "promoCodeTemplateEditorSubmit",
            },
          ];

          if (promoCodeTemplateWrapper.promoCodeTemplate._id) {
            buttonProps.unshift({
              label:
                intl.messages["usecase.promoCodeTemplateEditor.ctas.delete"],
              onClick: () =>
                this.setState({ confirmDeletionPopupIsOpen: true }),
              type: "secondary",
              id: "promoCodeTemplateEditorDelete",
              dataCy: "deletePromoCodeTemplate",
            });
          }

          return (
            <Fragment>
              <Dialog
                isOpen={isOpen}
                close={close}
                title={
                  intl.messages[
                    `usecase.promoCodeTemplateEditor.${
                      promoCodeTemplateWrapper.promoCodeTemplate._id
                        ? "edit"
                        : "add"
                    }.title`
                  ]
                }
                buttonsProps={buttonProps}
                PaperProps={{ style: { minHeight: "calc(100vh - 127px)" , left:"7%",width:"80%" } }}
              >
                <div>
                  <Tabs
                    tabKey={this.state.tabKey}
                    account={account}
                    onChange={this.changeTab}
                  />

                  {this.state.tabKey === "automatic" ? (
                    <TemplateEditorAutomatic
                      tabKey={this.state.tabKey}
                      account={account}
                      promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                      setPromoCodeTemplateWrapperProp={
                        this.setPromoCodeTemplateWrapperProp
                      }
                      mustRemoveObsoletePromoCodes={
                        this.state.mustRemoveObsoletePromoCodes
                      }
                      setMustRemoveObsoletePromoCodes={(value) =>
                        this.setState({ mustRemoveObsoletePromoCodes: value })
                      }
                      getError={this.getError}
                      usecaseName={usecaseName}
                      shouldDisplayFromDateOption={this.props.shouldDisplayFromDateOption}
                      caller={this.props.caller}
                    />
                  ) : this.state.tabKey === "manual" ? (
                    <TemplateEditorManual
                      tabKey={this.state.tabKey}
                      account={account}
                      promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                      setPromoCodeTemplateWrapperProp={
                        this.setPromoCodeTemplateWrapperProp
                      }
                      getError={this.getError}
                      usecaseName={usecaseName}
                      shouldDisplayFromDateOption={this.props.shouldDisplayFromDateOption}
                      caller={this.props.caller}
                    />
                  ) : null}
                </div>
              </Dialog>

              <ConfirmationDialog
                isOpen={this.state.confirmDeletionPopupIsOpen}
                onConfirmation={this.delete}
                close={() =>
                  this.setState({ confirmDeletionPopupIsOpen: false })
                }
                title={
                  intl.messages["usecase.promoCodeTemplateEditor.delete.title"]
                }
              />
            </Fragment>
          );
        }
      }
    )
  )
);

const Tabs = withStyles(styles)(
  class Tabs extends Component {
    render() {
      const { classes, tabKey, onChange } = this.props;

      return (
        <RadioGroup value={tabKey} onChange={(event) => onChange(event.target.value)} >

          <div className={classes.tabs}>
            <Tab tabKey={"automatic"} active={tabKey === "automatic"} />
            <Tab tabKey={"manual"} active={tabKey === "manual"} />
          </div>
          
        </RadioGroup>
      );
    }
  }
);

const Tab = withStyles(styles)(
  injectIntl(
    class Tab extends Component {
      render() {
        const { classes, intl, tabKey, active } = this.props;

        return (
          <div className={`${classes.tab} ${active ? "active" : ""}`}>
            <Radio legacy
              style={{ marginRight: 0 }}
              value={tabKey}
              label={
                <div className={classes.tabLabel}>
                  <span className={classes.tabLabelTitle}>
                    {
                      intl.messages[
                        `usecase.promoCodeTemplateEditor.tabs.${tabKey}.0`
                      ]
                    }
                  </span>
                  <span className={classes.tabLabelSubtitle}>
                    {
                      intl.messages[
                        `usecase.promoCodeTemplateEditor.tabs.${tabKey}.1`
                      ]
                    }
                  </span>
                </div>
              }
            />
          </div>
        );
      }
    }
  )
);
