import { makeStyles } from "@material-ui/core";
import { styles } from "./KiPaper.styles";

const useStyles = makeStyles(styles);

export function KiPaper(props) {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      {props.children}
    </div>
  );
}
