import { lighten } from "@material-ui/core";
import { palette } from "../../../../styles/palette";

export const styles = {
  radio: {
    "& .MuiRadio-root": {
      color: palette.primaryYellow200,
      "& .MuiSvgIcon-root": {
        fontSize: 30,
      },
      "&.Mui-disabled": {
        color: lighten(palette.neutralLightGrey, 0.5),
      },
    },
  },
  legacy: {
    "& .MuiRadio-root": {
      color: "#CED2D7",
      "& .MuiSvgIcon-root": {
        fontSize: "1.5rem",
      },
    },
    "& .MuiRadio-colorSecondary": {
      "&.Mui-checked": {
        color: palette.primaryYellow200,
      },
      "&.Mui-disabled": {
        color: "#e6e8eb",
      },
    },
  },
};
