import { palette } from "../../../styles/palette";

export const styles = {
  stepper: {
    height: 70,
    width: "100%",
    backgroundColor: palette.primaryWhite,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: "regular",
    "& > *:not(:last-child)": {
      marginRight: 16,
    },
  },
};
