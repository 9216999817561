import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { EventSystem } from "../../eventsystem/EventSystem.js";
import moment from "moment/min/moment-with-locales";
import { injectIntl } from "react-intl";
import { palette } from "../../styles/palette.js";

const styles = (theme) => ({
  reactDaypickerRoot: {
    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
    width: "auto",
    padding: "10px",
    textDecoration: "none",
    marginTop: "5px",
    fontFamily: "Coolvetica Book",
    fontSize: "13px",
    zIndex: "10000",
  },
  day: {
    borderRadius: "5px",
    fontSize: "11px",
    color: "#3e464f",
    fontFamily: "Coolvetica Book",
    cursor: "pointer",
    "&:hover": {
      color: palette.primaryYellow200,
    },
  },
  inactive_day: {
    borderRadius: "5px",
    fontSize: "11px",
    color: "#A5ADB8",
    fontFamily: "Coolvetica Book",
    cursor: "default",
  },
  activeDay: {
    fontFamily: "Coolvetica Book",
    fontSize: "11px",
    backgroundColor: palette.primaryYellow200,
    color: "white",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    textDecoration: "none",
  },
  th: {
    padding: "5px",
    textAlign: "center",
    width: "25px",
  },
  td: {
    padding: "5px",
    textAlign: "center",
    width: "20px",
  },
  iconChangeMonthYear: {
    textAlign: "center",
    color: "#9b9b9b",
    cursor: "pointer",
    marginTop: "4px",
    userSelect: "none",
    width: "30px",
    letterSpacing: "-2px",
    "&:first-child": {
      letterSpacing: "-4px",
    },
    "&:hover": {
      color: "#000",
    },
  },
  incrementMonth: {
    color: "#9b9b9b",
    cursor: "pointer",
    userSelect: "none",
    width: "40px",
    textAlign: "right",
  },
  monthYear: {
    marginTop: "4px",
    marginRight: "auto",
    marginLeft: "auto",
    flex: "2",
    textAlign: "center",
    color: palette.primaryYellow200,
    fontFamily: "Coolvetica Book",
  },
  abbr: {
    borderBottom: "0",
    textDecoration: "none",
    fontFamily: "Coolvetica Book",
    fontSize: "13px",
    color: "#878787",
  },
});

export const DayPicker = withStyles(styles)(
  injectIntl(
    class DayPicker extends Component {
      constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickAway = this.handleClickAway.bind(this);
        this.forceOpen = this.forceOpen.bind(this);
        this.isValidDate = this.isValidDate.bind(this);
        this.longMonthName = this.longMonthName.bind(this);
        this.force_date_begin = this.force_date_begin.bind(this);

        moment.locale(props.intl.locale);
        const now = new Date();
        const id = "date_" + String(now.toISOString());
        this.state = {
          date: now.getDate(),
          month: now.getMonth(),
          today: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
          year: now.getFullYear(),
          anchorEl: null,
          open: false,
          linked_to: null,
          id: id,
          MONTHS: new Array(12)
            .fill()
            .map((_, key) => moment().set("month", key).format("MMMM")),
          DAYS_LONG: new Array(7).fill().map((_, key) =>
            moment()
              .set("day", key + 1)
              .format("dddd")
          ),
          DAYS_SHORT: new Array(7).fill().map((_, key) =>
            moment()
              .set("day", key + 1)
              .format("ddd")
          ),
        };
      }

      componentDidMount() {
        const activeDate = new Date(this.props.active);
        const date = activeDate.getDate();
        const month = activeDate.getMonth();
        const year = activeDate.getFullYear();
        let element;

        if (this.props.endDate) {
          EventSystem.subscribe(
            "date_start_" + String(this.props.idStartDate),
            this.forceOpen
          );
          element = document.getElementById(this.state.id);
          this.setState({
            linked_to: "date_start_" + String(this.props.idStartDate),
            anchorEl: element,
            date: date,
            month: month,
            year: year,
          });
        } else {
          EventSystem.subscribe("force_date_begin", this.force_date_begin);
          element = document.getElementById(this.state.id);
          this.setState({
            anchorEl: element,
            date: date,
            month: month,
            year: year,
          });
        }
      }

      forceOpen() {
        this.setState({ open: true });
      }

      force_date_begin(date_begin) {
        const date = date_begin.getDate();
        const month = date_begin.getMonth();
        const year = date_begin.getFullYear();
        this.setState({ date: date, month: month, year: year });
      }

      componentWillUnmount() {
        if (this.props.endDate) {
          EventSystem.unsubscribe(this.state.linked_to, null);
        } else {
          EventSystem.unsubscribe("force_date_begin", null);
        }
      }

      handleClick(event) {
        const { intl, onClick } = this.props;

        this.props.disabled
          ? EventSystem.newNotification(
            "notification.action",
            intl.messages["dayPicker.cannotOpen"]
          )
          : this.setState({ open: !this.state.open });
        onClick?.();
      }

      handleClose = function () {
        this.setState({ anchorEl: null });
      };

      static isSameDay(a, b) {
        a = new Date(a);
        b = new Date(b);
        return (
          a &&
          b &&
          a.getFullYear() === b.getFullYear() &&
          a.getMonth() === b.getMonth() &&
          a.getDate() === b.getDate()
        );
      }

      get days() {
        const { month, year } = this.state;
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const days = [];
        let offset = new Date(year, month, 1).getDay() - 1;
        if (offset === -1) offset = 6;
        if (offset < 7) {
          for (let i = 0; i < offset; i++) {
            days.push(null);
          }
        }
        for (let i = 1; i <= daysInMonth; i++) {
          days.push(new Date(year, month, i));
        }
        return days;
      }

      get weeks() {
        const days = this.days;
        const weeks = [];
        const weekCount = Math.ceil(days.length / 7);
        for (let i = 0; i < weekCount; i++) {
          weeks.push(days.slice(i * 7, (i + 1) * 7));
        }
        return weeks;
      }

      longMonthName(month) {
        if (this.props.monthNames) {
          return this.props.monthNames[month];
        }
        return this.state.MONTHS[month];
      }

      longDayName(dayOfWeek) {
        if (this.props.longDayNames) {
          return this.props.longDayNames[dayOfWeek];
        }
        return this.state.DAYS_LONG[dayOfWeek];
      }

      shortDayName(dayOfWeek) {
        if (this.props.shortDayNames) {
          return this.props.shortDayNames[dayOfWeek];
        }
        return this.state.DAYS_SHORT[dayOfWeek];
      }

      decrementMonth = () => {
        const { month, year } = this.state;

        this.setState({
          month: month !== 0 ? month - 1 : 11,
          year: month !== 0 ? year : year - 1,
        });
      };

      incrementMonth = () => {
        const { month, year } = this.state;

        this.setState({
          month: month !== 11 ? month + 1 : 0,
          year: month !== 11 ? year : year + 1,
        });
      };

      decrementYear = () => {
        this.setState({
          year: this.state.year - 1,
        });
      };

      incrementYear = () => {
        this.setState({
          year: this.state.year + 1,
        });
      };

      onDayClick = (day) => () => {
        if (day) {
          this.props.onDayClick(day);
          this.setState({
            date: day.getDate(),
            month: day.getMonth(),
            year: day.getFullYear(),
            open: false,
          });
          if (!this.props.endDate && this.props.idStartDate) {
            EventSystem.publish("date_start_" + this.props.idStartDate, null);
          }
        }
      };

      // Remember that the month is 0-based so February is actually 1...
      isValidDate(year, month, day) {
        const d = new Date(year, month, day);
        if (
          d.getFullYear() === year &&
          d.getMonth() === month &&
          d.getDate() === day
        ) {
          return true;
        }
        return false;
      }

      handleClickAway(event) {
        let { month, year, date } = this.state;
        let ref_date = new Date(year, month, date);
        const now = new Date();
        if (this.props.minDate) {
          while (this.props.minDate > ref_date) {
            date = date + 1;
            ref_date = new Date(year, month, date);
          }
        } else if (this.props.futureOnly) {
          while (now > ref_date) {
            date = date + 1;
            ref_date = new Date(year, month, date);
          }
        }

        date = ref_date.getDate();
        month = ref_date.getMonth();
        year = ref_date.getFullYear();

        const fake_date = new Date(year, month, date);
        this.props.onDayClick(fake_date);
        this.setState({ open: false, date: date, month: month, year: year });
        if (this.props.open && !this.props.endDate && this.props.idStartDate) {
          EventSystem.publish("date_start_" + this.props.idStartDate, null);
        }
      }

      // addDays =  (now, days) => {
      //   let futur = new Date()
      //   futur.setDate(now.getDate() + days)
      //   return futur
      // }

      renderDay = (day, index) => {
        let minDate = new Date(2018, 0, 1);
        if (this.props.minDate !== null && this.props.minDate !== undefined) {
          minDate = this.props.minDate;
        }
        const now = new Date();
        // const startDayInFutur = ((this.props.startDayInFutur !== undefined) ? this.addDays(now, this.props.startDayInFutur) : now)
        const classes = this.props.classes;
        const { /*date,*/ month, today, year } = this.state;
        const { active } = this.props;
        const isToday = day && day.valueOf() === today.valueOf();
        const isActive = active && day && DayPicker.isSameDay(active, day);
        const clickableDay =
          (this.props.futureOnly === true && day > now) ||
          (this.props.futureOnly !== true && day >= minDate);
        return (
          <td
            style={isActive ? { color: "white" } : {}}
            className={[
              isActive ? classes.activeDay : null,
              clickableDay === true ? classes.day : classes.inactive_day,
              !day ? "empty" : null,
              isToday ? "today" : null,
              classes.td,
            ]
              .filter((v) => v)
              .join(" ")}
            key={`${year}.${month}.day.${index}`}
            onClick={clickableDay === true ? this.onDayClick(day) : null}
          >
            {day ? day.getDate() : ""}
          </td>
        );
      };

      renderWeek = (days, index) => {
        const { month, year } = this.state;

        return (
          <tr key={`${year}.${month}.week.${index}`}>
            {days.map(this.renderDay)}
          </tr>
        );
      };

      renderDayHeader(dayOfWeek) {
        return (
          <th scope="col" className={[this.props.classes.th]}>
            <abbr
              className={this.props.classes.abbr}
              title={this.longDayName(dayOfWeek)}
            >
              {this.shortDayName(dayOfWeek)}
            </abbr>
          </th>
        );
      }

      render() {
        const { classes, dataCy } = this.props;

        const { month, year, date } = this.state;

        return (
          <div className="dayPicker">
            <Button
              aria-owns={this.state.anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              variant="contained"
              id={this.state.id}
              ref={this.myRef}
              onClick={this.handleClick}
              data-cy={dataCy}
              style={{
                boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
                fontFamily: "Coolvetica Book",
                letterSpacing: "0.5px",
                fontWeight: "normal",
                fontStyle: "normal",
                fontStretch: "normal",
                lineHeight: "normal",
                borderRadius: "5px",
                backgroundColor: "#ffffff",
                color: "#878787",
                fontSize: "11px",
                height: "40px",
                width: "195px",
                textDecoration: "none",
                textTransform: "none",
                border:
                  this.state.open === true
                    ? "1px solid " + palette.primaryYellow200
                    : "1px solid white",
              }}
            >
              <div style={{ position: "absolute", left: "15px", top: "11px" }}>
                {moment([year, month, date]).format("Do")}{" "}
                {this.longMonthName(month)} {year}
              </div>
              <i
                style={{
                  fontSize: "14px",
                  position: "absolute",
                  right: "15px",
                  top: "13px",
                }}
                className="fal fa-calendar"
              ></i>
            </Button>
            <Popper
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              placement={"bottom-start"}
              style={{ zIndex: "10000" }}
            >
              <ClickAwayListener onClickAway={this.handleClickAway}>
                <Paper className={classes.reactDaypickerRoot}>
                  <div className={classes.header}>
                    <div
                      className={classes.iconChangeMonthYear}
                      onClick={this.decrementYear}
                    >
                      ◀◀
                    </div>
                    <div
                      className={classes.iconChangeMonthYear}
                      onClick={this.decrementMonth}
                    >
                      ◀
                    </div>
                    <div className={classes.monthYear}>
                      {this.longMonthName(month)} {year}
                    </div>
                    <div
                      className={classes.iconChangeMonthYear}
                      onClick={this.incrementMonth}
                    >
                      ▶
                    </div>
                    <div
                      className={classes.iconChangeMonthYear}
                      onClick={this.incrementYear}
                    >
                      ▶▶
                    </div>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        {this.renderDayHeader(0)}
                        {this.renderDayHeader(1)}
                        {this.renderDayHeader(2)}
                        {this.renderDayHeader(3)}
                        {this.renderDayHeader(4)}
                        {this.renderDayHeader(5)}
                        {this.renderDayHeader(6)}
                      </tr>
                    </thead>
                    <tbody>{this.weeks.map(this.renderWeek)}</tbody>
                  </table>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </div>
        );
      }
    }
  )
);
