import { useEffect, useState } from "react";
import { Translation } from "../../common/Translation";
import classes from "./Integration.module.scss";

import { MenuCard } from "./MenuCard";
import { MainCmsCard } from "./MainCmsCard";
import { SecondaryCmsCard } from "./SecondaryCmsCard";
import { AccountContextBehaviorSubject$, reloadAccountContext } from "../../../contexts/context";
import { useAuth } from "../../../hooks/useAuth.js";
import { useIntl } from "react-intl";

import { EventSystem } from "../../../eventsystem/EventSystem";


export const Integration = () => {

  const { account } = AccountContextBehaviorSubject$.getValue();
  const [noCms, setNoCms] = useState(false);
  const [isSuspended, setIsSuspended] = useState(null);
  const [isOpenCmsCard, setIsOpenCmsCard] = useState(false);
  const [cmsToOpen, setcmsToOpen] = useState("");
  const [cmsConnected, setCmsConnected] = useState("");
  const [brevoConnected, setBrevoConnected] = useState(false);
  const [mailChimpConnected, setMailChimpConnected] = useState(false);
  const [kilibaConnected, setKilibaConnected] = useState(false);
  const [googleConnected, setGoogleConnected] = useState(false);
  const [secondaryCmsConnected, setCmsSecondaryConnected] = useState([]);
  const [secondaryCmsRevoked, setSecondaryCmsRevoked] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const platforms = ["Magento","Prestashop","Shopify","Woocommerce"];
  //const secondaryPlatforms = ["Brevo","Mailchimp","Google Analytics"];
  const secondaryPlatforms = ["Brevo","Mailchimp"];
  const auth = useAuth();
  const intl = useIntl();

  useEffect(() => {
    if(account.step_register >= 3) {
      auth.fetch("/api/account_management/checkModule", { method: "POST" }).then(response => {
        console.log(response);
        if (!response.success) {
          setIsSuspended(true);
        } else {
          setIsSuspended(false);
        }
      });
    }
  }, [auth, account]);

  useEffect(() => {
    setIsLoading(true);

    const hasCMS = account.step_register >= 3 && account.CMS;
    setNoCms(!hasCMS);
    if (hasCMS) {
      setCmsConnected(account.CMS);
    }

    const secondaryCmsList = [];
    const secondaryCmsRevokedList = [];

    if (account.brevo) {
      secondaryCmsList.push({ name: "brevo", key: account.brevo.apiKey , createdAt: formatISODate(account.brevo.created_at) });

      if (account.brevo.apiKey) {
        auth.fetch("/account_management/checkBrevoApiKey", { method: "GET" })
          .then(response => {
            if (!response) {
              secondaryCmsRevokedList.push("brevo");
              setSecondaryCmsRevoked([...secondaryCmsRevokedList]);
              
              if (!localStorage.getItem("brevoApiKeyErrorDisplayed")) {
                EventSystem.newNotification(
                  "notification.error",
                  intl.messages["integration.apikey.error"]
                );

                localStorage.setItem("brevoApiKeyErrorDisplayed", true);
              }
            }

            setBrevoConnected(true);
          });
      } else {
        secondaryCmsRevokedList.push("brevo");
        setBrevoConnected(true);
      }
    } else {
      setBrevoConnected(false);
    }
    if (account.mailchimp) {
      secondaryCmsList.push({ name: "mailchimp", key: account.mailchimp.apiKey , createdAt: formatISODate(account.mailchimp.created_at) });

      if (account.mailchimp.apiKey) {
        auth.fetch("/account_management/checkMailChimpApiKey", { method: "GET" })
          .then(response => {
            if (!response) {
              secondaryCmsRevokedList.push("mailchimp");
              setSecondaryCmsRevoked([...secondaryCmsRevokedList]);

              if (localStorage.getItem("mailchimpApiKeyErrorDisplayed")) {
                EventSystem.newNotification(
                  "notification.error",
                  intl.messages["integration.apikey.error"]
                );
  
                localStorage.setItem("mailchimpApiKeyErrorDisplayed", true);
              }
            }

            setMailChimpConnected(true);
          });
      } else {
        secondaryCmsRevokedList.push("mailchimp");
        setMailChimpConnected(true);
      }
    } else {
      setMailChimpConnected(false);
    }

    if (account.api_key) {
      secondaryCmsList.push({ name: "Kiliba Connect", key: account.api_key });
      setKilibaConnected(true);
    } else {
      setKilibaConnected(false);
    }
    if (!account.googleAnalytics) {
      secondaryCmsList.push({ name: "google Analytics", key: "4444444444", createdAt:"30/00/1900" });
      setGoogleConnected(true);
    } else {
      setGoogleConnected(false);
    }

    setCmsSecondaryConnected([...secondaryCmsList]);
    setSecondaryCmsRevoked([...secondaryCmsRevokedList]);
    setIsLoading(false);
  }, [auth, account]);

  useEffect(() => {
    const cmsMapping = {
      shopify: "Shopify",
      magento: "Magento",
      prestashop: "Prestashop",
      woocommerce: "Woocommerce",
    };
    if (cmsConnected.toLowerCase() in cmsMapping) {
      setCmsConnected(cmsMapping[cmsConnected.toLowerCase()]);
    }
  }, [cmsConnected]);

  
  function openCmsCard(platform) {
    if( (!noCms && cmsConnected === platform) || (noCms && platforms.includes(platform)) || (!noCms && secondaryPlatforms.includes(platform)) ||(!noCms && platform === "Kiliba Connect") ){
      setcmsToOpen(platform);
      setIsOpenCmsCard(true);
    } 
  }
  function closeCmsCard(platform) {
    setIsOpenCmsCard(false);
  }
  function disconnectSecondaryCms(platform) {
    setCmsSecondaryConnected([]);
  }
  function formatISODate(isoDate) {
    const date = new Date(isoDate);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return date.toLocaleDateString("fr-FR", options);
  }

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.mainTitle}>
          {noCms}
          <Translation id="integration.title" />
        </div>
        <h4 className={classes.mainSubtitle}>
          <Translation id="integration.subtitle" defaultMessage="" />
        </h4>
      </div>

      {
        isOpenCmsCard ?
          (cmsToOpen.includes("Magento") || cmsToOpen.includes("Shopify") || cmsToOpen.includes("Prestashop")) || cmsToOpen.includes("Woocommerce") ? 
            <MainCmsCard 
              platform={cmsToOpen} 
              noCms={noCms} 
              closeCmsCard={closeCmsCard} 
              isSuspended={isSuspended}
              setNoCms={setNoCms}>
            </MainCmsCard>
            :
            <SecondaryCmsCard 
              platform={cmsToOpen} 
              closeCmsCard={closeCmsCard}
              secondaryCmsConnected={secondaryCmsConnected}
              secondaryCmsRevoked={secondaryCmsRevoked}
              disconnectSecondaryCms={disconnectSecondaryCms}
              isLoading={isLoading}
              onUpdate={(closeCard = true) => {
                reloadAccountContext();
                if (closeCard) {
                  closeCmsCard();
                }
              }}
              utm={account.utm_is_enabled}
            >
            </SecondaryCmsCard>
          :
          <div className={classes.content}>
            {
              noCms ?
                /* DIV CMS NOT CONNECTED */
                <div className={classes.applicationBlock}>
                  <h3>
                    <Translation id="integration.cmsNotConnectedText1" />
                  </h3>
                  <span className={`text ${classes.subtitle}`} >
                    <Translation id="integration.cmsNotConnectedText2" />
                  </span>
                  {
                    platforms.map((platform) => (
                      <MenuCard 
                        key={platform} 
                        platform={platform} 
                        mainCms={false} 
                        noCms={noCms} 
                        openCmsCard={openCmsCard} 
                      />
                    ))
                  }
                </div>
                :
                /* DIV CMS CONNECTED */
                <div className={classes.applicationBlock}>
                  <h3>
                    <Translation id="integration.cmsConnectedText1" />
                  </h3>
                  <span className={`text ${classes.subtitle}`} >
                    <Translation id="integration.cmsConnectedText2" />
                  </span>
                  <MenuCard
                    platform={cmsConnected}
                    noCms={noCms}
                    mainCms={true}
                    openCmsCard={openCmsCard}
                    isSuspended={isSuspended} 
                  />
                </div>
            }

            {/* DIV APPLICATION */}
            <div className={classes.applicationBlock}>
              <h3>
                <Translation id="integration.applicationText1" />
              </h3>
              <span className={`text ${classes.subtitle}`} >
                <Translation id="integration.applicationText2" />
              </span>
              {
                !noCms
                  ? platforms.concat(secondaryPlatforms).sort().map((platform) =>
                    cmsConnected !== platform && (
                      <MenuCard 
                        platform={platform} 
                        noCms={noCms} 
                        mainCms={false} 
                        openCmsCard={openCmsCard} 
                        brevoConnected={brevoConnected}
                        mailChimpConnected={mailChimpConnected}
                        kilibaConnected={kilibaConnected}
                        googleConnected={googleConnected}
                        secondaryCmsRevoked={secondaryCmsRevoked}
                      />
                    )
                  )
                  : secondaryPlatforms.sort().map((platform) =>
                    cmsConnected !== platform && (
                      <MenuCard 
                        platform={platform} 
                        noCms={noCms} 
                        mainCms={false} 
                        openCmsCard={openCmsCard} 
                        secondaryCmsRevoked={secondaryCmsRevoked}
                      />
                    )
                  )
              }
            </div>

            {/* DIV API */}
            <div className={classes.applicationBlock}>
              <h3>
                <Translation id="integration.apiText1" />
              </h3>
              <span className={`text ${classes.subtitle}`} >
                <Translation  id="integration.apiText2" />
              </span>
              <MenuCard
                platform="Kiliba Connect"
                noCms={noCms}
                mainCms={false}
                openCmsCard={openCmsCard}
              />
            </div>

          </div>
      }


    </div>
  );
};