const LABEL = "columnSeparator";
const CATEGORY = "columnComponent:separator";

export const columnSeparator = (variables, borderWidth = 0) => ({
  label: `${LABEL}-${borderWidth}`,
  category: CATEGORY,
  block: (overrides) => {
    const { backgroundColor, isDominantColorLight } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-divider data-column-component="true" data-blocklabel="${`${LABEL}-${borderWidth}`}" width="100%" border-width="${borderWidth}px"  border-color="${isDominantColorLight ? "#BEBAB3" : "#F6F4F0"}" container-background-color="${backgroundColor}" padding-left="10px" padding-right="10px" padding-top="25px" padding-bottom="25px"></mj-divider>
    `;

    return {
      id: `${LABEL}-${borderWidth}`,
      label: `${LABEL}-${borderWidth}`,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        editableProperties: [          
        ]
      }
    };
  },
});
