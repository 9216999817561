import { ClickAwayListener, Fade } from "@mui/material";
import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { debounce } from "lodash";
import { KiTextField } from "../common/Input/KiTextField";
import { useAuth } from "../../hooks/useAuth";

import classes from "./NewsletterChooseProductInput.module.scss";
import { Translation } from "../common/Translation";

export const NewsletterChooseProductInput = ({ placeholder, disabled = false, onAddProduct, onClick = () => {} }) => {

  const auth = useAuth();
  const anchor = useRef();
  const intl = useIntl();

  const getTitleFromJson = useCallback((title) => title[intl.locale] || title.fr || title.en || title.es || title.de || title.nl || "", [intl]);

  const [productSearch, setProductSearch] = useState("");  
  const [productSearchResults, setProductSearchResults] = useState([]);
  const [showProductSearchResults, setShowProductSearchResults] = useState(false);

  //eslint-disable-next-line
  const searchUsableProducts = useCallback(debounce((value) => {

    if (value) {
      auth.fetch("/data/searchUsableProducts", {
        method: "POST",
        body: JSON.stringify({
          searchText: value,
        })
      }).then(result => {
        setProductSearchResults(result);
      });
    } else {
      setProductSearchResults([]);
    }

  }, 500), [auth]);

  useEffect(() => {
    searchUsableProducts(productSearch);
  }, [searchUsableProducts, productSearch]);

  const onKeyDown = (event) => {
    if (event.key === "Enter" && productSearchResults.length) {
      const productToAdd = productSearchResults[0];
      onAddProduct(productToAdd);
      const input = anchor.current?.querySelector("input");
      input?.blur();
      setShowProductSearchResults(false);
      setProductSearch("");
    }
  };

  const _onChange = (value, event) => {
    const productToAdd = productSearchResults.find(product => product.id_product === value);
    onAddProduct(productToAdd);
    const input = anchor.current?.querySelector("input");
    input?.blur();
    setShowProductSearchResults(false);
    setProductSearch("");
  };

  const isOpen = useMemo(() => !!productSearchResults.length && showProductSearchResults, [productSearchResults, showProductSearchResults]);

  return (
    <div ref={anchor} className={classes.productInputContainer} onClick={onClick}>
      <KiTextField
        placeholder={placeholder}
        type="whiteBg"
        autoComplete="off"
        name="product_search"
        value={productSearch}
        handleChange={event => {
          setProductSearch(event.target.value);
        }}
        onFocus={() => setShowProductSearchResults(true)}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
      <ClickAwayListener onClickAway={event => !anchor?.current.contains(event?.target) && setShowProductSearchResults(false)}>
        <Fade in={isOpen}>
          <div className={`${classes.dropdownPopper} ${classes.dropdown}`} style={{ position: "absolute", top: anchor.current?.getBoundingClientRect().height + 5, left: 0 }}>
            {productSearchResults.map(product => (
              <div
                key={product.id_product} 
                className={`text ${classes.option} ${classes.option}`}
                onClick={(event) => _onChange(product.id_product, event)}
              >
                <img alt="" src={product.image_url} height={30} className={classes.productImage} />
                <span>{getTitleFromJson(product.title)}</span>
                <div className={classes.statusContainer}>
                  <div className={classes.statusIcon} style={{ backgroundColor: product.mw_is_usable ? "#C1EB8D" : "#FFAC89" }}></div>
                  <Translation id={`products.filters.status.${product.mw_is_usable ? "active" : "unavailable"}`} />
                </div>
              </div>
            ))}
          </div>
        </Fade>
      </ClickAwayListener>
    </div>
  );
};