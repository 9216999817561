import { AuthService } from "../components/AuthService";

const Auth = new AuthService();

/**
 * Wow
 * What a usage
 * Such usefulness
 * Amazing
 */
export function useAuth() {
  return Auth;
}
