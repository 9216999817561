export const setUpMjmlComponent = (editor) => {
  editor.Components.addType("mjml", {
    view: {
      events: {
        click: "clickOnElement"
      },

      clickOnElement(event) {
        editor.trigger("mjmlClick", { event, component: this });
      },
    }
  });
};