import React, { Component } from "react";
// Auth
import { withAuth } from "../withAuth";
import { injectIntl } from "react-intl";
// MaterialUI
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import Paper from '@material-ui/core/Paper'
// import CardMedia from '@material-ui/core/CardMedia'
// import Card from '@material-ui/core/Card'
// import CardActionArea from '@material-ui/core/CardActionArea'
// import Grid from '@material-ui/core/Grid'
// import Switch from '@material-ui/core/Switch'
// import FormButtonTO from '.././FormButtonTO.jsx'
// import IconButton from '@material-ui/core/IconButton'
// import Tooltip from '@material-ui/core/Tooltip'
// import stripe

// import { palette } from '../../styles/palette.js'
import success from "../../public/success.png";

import { styles } from "../../styles/payment/paymentresult.js";
import { Translation } from "../common/Translation";

export const SubscriptionSuccess = withAuth(
  withStyles(styles)(
    injectIntl(
      class SubscriptionSuccess extends Component {
        constructor(props) {
          super(props);
          this.changeUrl = this.changeUrl.bind(this);
          this.state = {
            succesBilling: false,
          };
        }

        changeUrl = (url) => (event) => {
          this.props.history.push(url);
        };

        render() {
          const classes = this.props.classes;
          return (
            <div className={classes.home}>
              <img
                className={classes.imgfield}
                styles={{ textAlign: "center" }}
                alt="bug loading"
                src={success}
              ></img>
              <div className={classes.title}>
                <div>
                  <Translation id="payment.title.result_success" />
                </div>
              </div>
              <div className={classes.sub_title}>
                <div>
                  <Translation id="payment.sub_title.result_success" />
                </div>
              </div>
              <Button
                type="submit"
                onClick={this.changeUrl("/login")}
                color="primary"
                className={classes.button}
              >
                <div>
                  <Translation id="payment.button.result_success" />
                </div>
              </Button>
            </div>
          );
        }
      }
    )
  )
);
