import React, { Component } from "react";

import { NoCmsCta } from "./NoCmsCta.js";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  emptyState: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  description: {
    textAlign: "center",
  },
});

export const NoCms = withRouter(withStyles(styles)(
  class NoCms extends Component {
    constructor(props) {
      super(props);
      this.onCtaClick = this.onCtaClick.bind(this);
    }

    onCtaClick() {
      this.props.history.push("/on_boarding");
    }

    render() {
      const {
        classes,
        children,
        ctaText,
        type
      } = this.props;

      return (
        <div className={classes.emptyState} style={this.props.style}>
          {children}
          <div className={classes.noCmsData}>
            <NoCmsCta onClick={this.onCtaClick} text={ctaText} type={type} />
          </div>
        </div>
      );
    }
  }
));
