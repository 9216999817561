const LABEL = "footer2";

export const footer2 = (logoUrl, blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, isLight, { fontFamily, footerColor }) => ({
  label: LABEL,
  block: () => {

    const mjml = `
      <mj-wrapper full-width="full-width" background-color="${footerColor}" data-gjs="kilibaBlock" padding-top="0" padding-bottom="0">
        
        <mj-section padding-top="15px" padding-bottom="0px">
          <mj-column>
            <mj-social icon-size="25px" mode="horizontal">
                ${blockRsInstagram}
                ${blockRsFacebook}
                ${blockRsTwitter}
                ${blockRsYoutube}
                ${blockRsDiscord}
                ${blockRsLinkedin}
                ${blockRsPinterest}
                ${blockRsTiktok}
            </mj-social>
          </mj-column>
        </mj-section>
        <mj-section padding-top="0px" padding-bottom="20px" padding-left="20px" padding-right="20px">
          <mj-column>
            <mj-text color=${isLight ? "black" : "white"} line-height="16px" font-family="Helvetica" font-weight="400" font-size="11px" align="center">
              {{title}}
            </mj-text>
            <mj-text padding-left="0px" padding-right="0px" padding-top="10px" padding-bottom="0px" font-family="${fontFamily}" color=${isLight ? "black" : "white"} font-weight="400" font-size="11px" align="center">
              {{foot}}
              <a style="color:${isLight ? "black" : "white"}" href="https://app.thatsowl.com/unsubscribe"> {{link}} </a>
            </mj-text>
          </mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    const wordings = {
      title: {
        fr: "Conformément à la réglementation européenne en matière de données personnelles, vous disposez d’un droit d’accès, de rectification, d’effacement, de limitation, et de portabilité de vos données personnelles ainsi que d'un droit de retrait et d'opposition sur les traitements des données personnelles vous concernant. Afin d'exercer ce droit, nous vous remercions de bien vouloir nous contacter.",
        en: "In accordance with European regulations on personal data, you have the right to access, rectify, delete, limit and port your personal data, as well as the right to withdraw and object to the processing of your personal data. To exercise this right, please contact us.",
        es: "De conformidad con la normativa europea sobre datos personales, usted tiene derecho a acceder, rectificar, suprimir, limitar y portar sus datos personales, así como derecho a retirar y oponerse al tratamiento de sus datos personales. Para ejercer este derecho, póngase en contacto con nosotros.",
        de: "Gemäß der EU-Verordnung über personenbezogene Daten haben Sie das Recht auf Zugang, Berichtigung, Löschung, Einschränkung und Übertragbarkeit Ihrer personenbezogenen Daten sowie das Recht auf Widerruf und Einspruch gegen die Verarbeitung Ihrer personenbezogenen Daten. Um dieses Recht auszuüben, bitten wir Sie, sich mit uns in Verbindung zu setzen.",
        nl: "In overeenstemming met de Europese regelgeving inzake persoonsgegevens hebt u het recht op toegang, rectificatie, verwijdering, beperking en overdraagbaarheid van uw persoonsgegevens, evenals het recht op intrekking van en bezwaar tegen de verwerking van uw persoonsgegevens. Om dit recht uit te oefenen, kunt u contact met ons opnemen.",
        it: "In conformità alla normativa europea sui dati personali, avete il diritto di accesso, rettifica, cancellazione, limitazione e portabilità dei vostri dati personali, nonché il diritto di revoca e di opposizione al trattamento dei vostri dati personali. Per esercitare questo diritto, vi preghiamo di contattarci.",
        pt: "Em conformidade com a regulamentação europeia relativa aos dados pessoais, tem o direito de aceder, retificar, apagar, limitar e transferir os seus dados pessoais, bem como o direito de retirar e de se opor ao tratamento dos seus dados pessoais. Para exercer este direito, contacte-nos.",
      },
      foot: {
        fr: "Si vous ne souhaitez plus recevoir nos e-mails, ",
        en: "If you no longer wish to receive our emails, ",
        es: "Si no desea seguir recibiendo nuestros correos electrónicos, ",
        de: "Wenn Sie unsere E-Mails nicht mehr erhalten möchten, ",
        nl: "Als u onze e-mails niet langer wilt ontvangen",
        it: "Se non desiderate più ricevere le nostre e-mail",
        pt: "Se não quiser continuar a receber as nossas mensagens electrónicas, ",
      },
      link: {
        fr: "cliquez ici pour vous désinscrire.",
        en: "click here to unsubscribe.",
        es: "pulse aquí para darse de baja.",
        de: "klicken Sie hier, um sich abzumelden.",
        nl: "klik hier om u af te melden.",
        it: "clicca qui per annullare l'iscrizione.",
        pt: "clique aqui para cancelar a subscrição.",
      },
    };

    return {
      id: LABEL,
      label: LABEL,
      category: "footer:largefooter",
      activate: true,
      content: mjml,
      attributes: {
        wordings,
      }
    };
  },
  style: `
    .footer1-image img {
      margin: 0 auto;
    }
    .inlineSocialIcon {
      width:30px !important;
    }
  `,
});