import { useIntl } from "react-intl";

import { styles } from "./Step.styles";
import { makeStyles } from "@material-ui/core";
import { useCallback } from "react";

import PropTypes from "prop-types";

const useStyles = makeStyles(styles);

export function Step(props) {
  const classes = useStyles();
  const intl = useIntl();

  const selectedStatuses = [];

  if (props.status.disabled) selectedStatuses.push("disabled");
  if (props.status.active) selectedStatuses.push("active");
  if (props.status.done) selectedStatuses.push("done");

  const statusClasses = selectedStatuses.map(selectedStatus => classes[`${selectedStatus}Step`]);

  const onClick = useCallback(() => {
    if (props.onClick) {
      props.onClick();
    }
  }, [props]);

  return (
    <div
      className={`${classes.step} ${statusClasses.join(" ")}`}
      onClick={onClick}
    >
      <div className={classes.circle}>
        {selectedStatuses.find(selectedStatus => selectedStatus === "done") ? <i className="fa-solid fa-check" /> : props.index + 1}
      </div>
      <p className={classes.text}>{intl.messages[`firstSteps.step${props.index}`]}</p>
    </div>
  );
}

Step.propTypes = {
  status: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

Step.defaultProps = {};
