import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../../../hooks/useAuth.js";
import { Dialog } from "../../../common/Dialog.js";
import { TextField } from "../../../common/Input/TextField.js";
import { FormButtonTO } from "../../../FormButtonTO.jsx";
import { PageBodyWrapper } from "../common/PageBodyWrapper/PageBodyWrapper.js";
import { useIntl } from "react-intl";

import { styles } from "./VerifyEmail.styles";
import { CustomButton } from "../../../common/Button/CustomButton.js";

const useStyles = makeStyles(styles);

export function VerifyEmail(props) {
  const classes = useStyles();
  const auth = useAuth();
  const intl = useIntl();
  
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [emailInputError, setEmailInputError] = useState("");
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [codeInputError, setCodeInputError] = useState("");

  const inputRefs = code.map(() => React.createRef());

  const [shouldShowVerifyEmailDialog, setShouldShowVerifyEmailDialog] = useState(true);
  const [shouldShowChangeEmailDialog, setShouldShowChangeEmailDialog] = useState(false);
  const [shouldShowVerifyCodeDialog, setShouldShowVerifyCodeDialog] = useState(false);

  function openChangeEmailDialog() {
    setShouldShowVerifyEmailDialog(false);
    setShouldShowChangeEmailDialog(true);
  }

  function closeChangeEmailDialog() {
    setEmail("");
    setShouldShowVerifyEmailDialog(true);
    setShouldShowChangeEmailDialog(false);
  }

  function openVerifyCodeDialog(sendMail = true) {
    auth.getUser()
      .then((user) => {
        setUser(user);
        if (user.attributes?.["custom:welcome_email_sent"] !== "true" && sendMail) {
          auth.sendVerifyEmail();
        }
      });
    setShouldShowVerifyCodeDialog(true);
  }

  function closeVerifyCodeDialog() {
    setCode(["", "", "", "", "", ""]);
    setShouldShowVerifyCodeDialog(false);
  }

  const handleEmailChange = (e) => {
    setEmailInputError("");
    setEmail(e.target.value);
  };

  async function sendCodeEmailAgain() {
    await auth.sendVerifyEmail()
      .catch(e => {
        setCodeInputError(intl.messages["verifyEmail.error.tooManyRequest"]);
      });
  };

  function logout() {
    auth.logout();
  };

  async function changeUserEmail(e) {
    e.preventDefault();

    if (!email) {
      setEmailInputError(intl.messages["verifyEmail.error.requiredField"]);
      return;
    }

    if (emailInputError) {
      return;
    }

    try {
      await auth.updateUserAttributes({
        email
      });
  
      openVerifyCodeDialog(false);
    } catch(e) {
      switch(e.code) {
        case "InvalidParameterException":
          setEmailInputError(intl.messages["verifyEmail.error.invalidEmail"]);
          break;
        case "AliasExistsException":
          setEmailInputError(intl.messages["verifyEmail.error.alreadyUsedEmail"]);
          break;
        default:
          setEmailInputError(intl.messages["error.main_error"]);
      }
    }
  }

  async function verifyCode(e) {
    e.preventDefault();

    const result = await auth.verifyEmail(code.join(""))
      .catch(e => {
        return "ERROR";
      });
    
    if (result !== "SUCCESS") {
      setCodeInputError(intl.messages["verifyEmail.error.badCode"]);
      return;
    }

    await auth.forceSessionRefresh();
    props.onSubmit();
  }

  const handleInputChange = (e, index) => {
    setCodeInputError("");
    const value = e.target.value;
    if (value.match(/^\d+$/) || value === "") {
      const updatedCode = [...code];
      updatedCode[index] = value;
      setCode(updatedCode);

      if ((index < 5 && value !== "") && code[index+1] === "") {
        document.getElementById(`input-${index + 1}`).focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData("text/plain");
    const pastedDigits = clipboardData.match(/\d/g);

    if (pastedDigits && pastedDigits.length === 6) {
      const updatedCode = [...code];
      pastedDigits.forEach((digit, index) => {
        updatedCode[index] = digit;
      });
      setCode(updatedCode);
    }
  };

  const handleArrowKeyPress = (e, currentIndex) => {
    if (e.key === "ArrowLeft" && currentIndex > 0) {
      inputRefs[currentIndex - 1].current.focus();
    } else if (e.key === "ArrowRight" && currentIndex < 5) {
      inputRefs[currentIndex + 1].current.focus();
    }
  };

  useEffect(() => {
    auth.getUser()
      .then((user) => {
        setUser(user);
      });
  }, [auth]);

  useEffect(() => {
    if (inputRefs[0].current && code.every(digit => digit === "")) {
      inputRefs[0].current.focus();
    }
  }, [code, inputRefs]);

  return (
    <PageBodyWrapper>
      {/* VERIFY EMAIL DIALOG */}
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 20,
            display: "flex",
            width: "60%"
          }
        }}
        style={{ padding: "60px 0px 40px 0px"}}
        close={logout}
        closeStyle="string"
        isOpen={shouldShowVerifyEmailDialog}
      >
        <div className={classes.wrapper}>
          <h4 className={classes.title}>{intl.messages["verifyEmail.mainDialog.title"]}</h4>

          <div className={classes.textWrapper}>
            <p>{intl.messages["verifyEmail.mainDialog.text1"]}</p>
            <p>{intl.messages["verifyEmail.mainDialog.text2"]}</p>
          </div>
          <div className={classes.centeredTextWrapper}>
            <p>{intl.messages["verifyEmail.mainDialog.text3"]}</p>
            <h4 className={classes.emailPresentation}>{ user?.attributes["email"] }</h4>
          </div>

          <div className={classes.buttonContainer}>
            <div className={classes.buttonLeft}>
              <CustomButton 
                onClick={openChangeEmailDialog}
                type="secondary"
                style={{ whiteSpace: "nowrap" }}  
              >
                {intl.messages["verifyEmail.mainDialog.button_no"]}
              </CustomButton>
            </div>
            <div className={classes.buttonRight}>
              <CustomButton 
                onClick={openVerifyCodeDialog}
                classes={classes.button}
                type="primary"
                style={{ whiteSpace: "nowrap" }}  
              >
                {intl.messages["verifyEmail.mainDialog.button_yes"]}
              </CustomButton>
            </div>
          </div>

          <hr className={classes.separator} />

          <div className={classes.bottomWrapper}>
            <p className={classes.lightText}>{intl.messages["verifyEmail.common.bottomDivText1"]}<a className={classes.link}>{intl.messages["verifyEmail.common.bottomDivLink1"]}</a></p>
            <p className={classes.lightText}>{intl.messages["verifyEmail.common.bottomDivText2"]}<a onClick={logout} className={classes.link}>{intl.messages["verifyEmail.common.bottomDivLink2"]}</a></p>
          </div>
        </div>
      </Dialog>
      
      {/* CHANGE EMAIL DIALOG */}
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 20,
            display: "flex",
            width: "50%"
          },
        }}
        style={{ padding: "80px 0px 30px 0px"}}
        close={closeChangeEmailDialog}
        isOpen={shouldShowChangeEmailDialog}
        closeStyle="string"
      >
        <div className={classes.wrapper}>
          <h4 className={classes.title}>{intl.messages["verifyEmail.changeEmailDialog.title"]}</h4>

          <form onSubmit={changeUserEmail}>
            <div className={`${classes.textWrapperMarginTop45}`}>
              <p>{intl.messages["verifyEmail.changeEmailDialog.text1"]}</p>
              <p className={classes.spacedText}>{intl.messages["verifyEmail.changeEmailDialog.text2"]}</p>
              <span className={classes.errorSpan}>{ emailInputError }</span>
              <TextField
                value={email}
                type="email"
                onChange={handleEmailChange}
                poppins
                error={emailInputError}
              />
            </div>
  
            <div className={classes.singleButtonContainerMarginTop20}>
              <FormButtonTO 
                label={intl.messages["verifyEmail.changeEmailDialog.button"]}
              />
            </div>
          </form>

          <div className={classes.bottomWrapperTop50}>
            <p className={classes.lightText}>{intl.messages["verifyEmail.common.bottomDivText1"]}<a className={classes.link}>{intl.messages["verifyEmail.common.bottomDivLink1"]}</a></p>
            <p className={classes.lightText}>{intl.messages["verifyEmail.common.bottomDivText2"]}<a onClick={logout} className={classes.link}>{intl.messages["verifyEmail.common.bottomDivLink2"]}</a></p>
          </div>
        </div>
      </Dialog>

      {/* VERIFY CODE DIALOG */}
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 20,
            display: "flex",
            width: "60%"
          }
        }}
        style={{ padding: "60px 0px 30px 0px"}}
        close={closeVerifyCodeDialog}
        isOpen={shouldShowVerifyCodeDialog}
        closeStyle="string"
      >
        <div className={classes.wrapper}>
          <h4 className={classes.title}>{intl.messages["verifyEmail.verifyCode.title"]}</h4>

          <form className={classes.FormWrapper}>
            <div className={classes.verifyCodeTextWrapper}>
              <p>{intl.messages["verifyEmail.verifyCode.text1"]}</p>
              <p>{intl.messages["verifyEmail.verifyCode.text2"]}</p>
            </div>

            <span className={classes.errorSpanSpaced}>{ codeInputError }</span>
            <div className={classes.FormWrapper}>
              <form onPaste={handlePaste} className={classes.digitForm}>
                {code.map((digit, index) => (
                  <div style={{ marginLeft: index === 3 ? "10px" : "0px", marginRight: index === 2 ? "10px" : "0px"}}>
                    <input
                      className={`${digit !== "" ? classes.FilledDigitInput : classes.EmptyDigitInput}`}
                      ref={inputRefs[index]}
                      key={index}
                      id={`input-${index}`}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleInputChange(e, index)}
                      onKeyDown={(e) => handleArrowKeyPress(e, index)}
                    />
                  </div>
                ))}
              </form>
            </div>

            <div className={classes.singleButtonContainer}>
              <FormButtonTO 
                label={intl.messages["verifyEmail.verifyCode.button"]}
                action={verifyCode}
              />
            </div>
            
            <div className={classes.centeredTextWrapperMarginTop15}>
              <p className={classes.lightText}>{intl.messages["verifyEmail.verifyCode.text3"]}</p>
              <a onClick={sendCodeEmailAgain} className={classes.link}>{intl.messages["verifyEmail.verifyCode.text4"]}</a>
            </div>
          </form>

          <hr className={classes.separator} />

          <div className={classes.bottomWrapper30}>
            <p className={classes.lightText}>{intl.messages["verifyEmail.common.bottomDivText1"]}<a className={classes.link}>{intl.messages["verifyEmail.common.bottomDivLink1"]}</a></p>
            <p className={classes.lightText}>{intl.messages["verifyEmail.common.bottomDivText2"]}<a onClick={logout} className={classes.link}>{intl.messages["verifyEmail.common.bottomDivLink2"]}</a></p>
          </div>
        </div>
      </Dialog>
    </PageBodyWrapper >
  );
}