import React, { useState, useEffect } from "react";
import reactCSS from "reactcss";
import { useIntl } from "react-intl";
import { SketchPicker } from "react-color";
import classes from "../ParametersSection.module.scss";


export const ColorParameters = ({ selectedColors, colorParameters, updateColorParameter }) => {
  const intl = useIntl();
  const [openedIndex, setOpenedIndex] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);

  const handleClick = (index) => {
    setSelectedColor(selectedColors[selectedColors.length-1]);
    if (openedIndex === index) {
      setOpenedIndex(null);
    } else {
      setOpenedIndex(index);
    }
  };

  const handleChange = (name, color, index) => {
    setSelectedColor(color.hex);
    colorParameters[index].value = color.hex;
    updateColorParameter(name, color);
  };

  const handleClose = (index) => {
    setOpenedIndex(null);
  };

  const styles = reactCSS({
    "default": {
      color: {
        width: "18px",
        height: "18px",
        borderRadius: "2px",
        boxShadow:"0px 4px 6px rgba(50, 50, 93, 0.11)",
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
        top: "30px",
        left: "38px",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
      colorSection: {
        position:"relative",
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
      },
      colorPickerLabel: {
        marginLeft: "10px",
        fontFamily: "Poppins",
        fontSize: "14px",
      },
    },
  });


  return (
    <div>
      { 
        colorParameters.map((colorParameter, index) => (
          <div style={styles.colorSection} key={index} className={"color" + index}>
            <div style={styles.swatch} onClick={() => handleClick(index)}>
              <div style={{ ...styles.color, backgroundColor: colorParameter.value }} />
            </div>
    
            {openedIndex === index ? (
              <div style={styles.popover}>
                <div style={styles.cover} onClick={() => handleClose(index)} />
                <SketchPicker
                  color={selectedColor}
                  presetColors={selectedColors}
                  onChange={(color) => handleChange(colorParameter.name, color, index)}
                />
              </div>
            ) : null}
    
            <span style={styles.colorPickerLabel}>
              { intl.messages["templateCustomization." + colorParameter.name]}
            </span>
          </div>
        ))
      }
    </div>
  );
};

