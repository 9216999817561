import React, { useEffect, useState } from "react";

import Snackbar from "@material-ui/core/Snackbar";
import { EventSystem } from "../../eventsystem/EventSystem.js";

import classes from "./ActionNotification.module.scss";
import classNames from "classnames";

export function ActionNotification() {
  const [currentNotification, setCurrentNotification] = useState(null);
  const [queue, setQueue] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const newNotification = (message, type) => {
      setQueue((queue) => [...queue, { message, type, key: Date.now() }]);
    };

    EventSystem.subscribe("notification.action", (message) => newNotification(message, "action"));
    EventSystem.subscribe("notification.error", (message) => newNotification(message, "error"));

    return () => {
      EventSystem.unsubscribe("notification.action", null);
      EventSystem.unsubscribe("notification.error", null);
    };
  }, []);

  const handleClose = (event, reason) => {
    if(reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };

  const handleExited = () => {
    setCurrentNotification(undefined);
  };

  // Take next message if closed of queue filled
  useEffect(() => {
    if(!currentNotification && queue.length) {
      setCurrentNotification(queue[0]);
      setQueue(queue => queue.slice(1));
      setIsOpen(true);
    } else if (currentNotification && queue.length && isOpen) {
      setIsOpen(false);
    }
  }, [currentNotification, queue, isOpen]);

  return (
    <div>
      <Snackbar
        key={currentNotification ? currentNotification.key : undefined}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        classes={{
          root: classNames(classes.notif, currentNotification && classes[`notification-${currentNotification.type}`]),
        }}
        message={ 
          <span className="textMedium" id="message-id">
            {currentNotification ? currentNotification.message : " "}
            <i onClick={handleClose} className={`fa-solid fa-xmark ${classes.closeIcon}`} />
          </span>
        }
      />
    </div>
  );
}