import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { injectIntl, FormattedMessage } from "react-intl";
import Paper from "@material-ui/core/Paper";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withAuth } from "../withAuth.js";
import { EventSystem } from "../../eventsystem/EventSystem.js";
import { ItemPicker } from "../pickers/ItemPicker.js";
import { FormButtonTO } from "../FormButtonTO.jsx";

import { palette } from "../../styles/palette.js";
import { AccountContextBehaviorSubject$, reloadAccountContext } from "../../contexts/context.js";

const styles = (theme) => ({
  root: {
    width: "100%",
    align: "left",
    alignItems: "left",
    marginBottom: "20px",
    marginLeft: "64px",
    paddingTop: "30px",
  },
  title: {
    fontFamily: "Coolvetica",
    fontSize: "40px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "-1px",
    marginBottom: "36px",
    color: palette.black,
  },
  close_button: {
    zIndex: "1",
    position: "absolute",
    right: "32px",
    top: "32px",
    height: "auto",
    width: "auto",
    cursor: "pointer",
  },
  table: {
    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
    width: "50%",
  },
  tableHeaderCell: {
    paddingRight: "0",
    width: "50%",
    fontSize: "14px",
    fontWeight: 600,
    color: palette.black,
    paddingLeft: "12%",
  },
  tableCell: {
    width: "50%",
    fontSize: "14px",
    fontWeight: "normal",
    color: palette.black,
    paddingLeft: "12%",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#f0f2f4 !important",
    },
  },
  bottomText: {
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    marginTop: "10px",
    letterSpacing: "-0.3px",
    color: palette.lightgray,
  },
  formButton: {
    marginTop: "30px",
    width: "150px",
  },
});

export const EmailLang = withAuth(
  withStyles(styles)(
    injectIntl(
      class EmailLang extends Component {
        constructor(props) {
          super(props);
          this.closeEmailLang = this.closeEmailLang.bind(this);
          this.updateLangSetting = this.updateLangSetting.bind(this);
          this.sortAlphabetical = this.sortAlphabetical.bind(this);
          this.save = this.save.bind(this);
          this.state = {
            sortAsc: true,
            emailLangSettings: [],
            idAccount: null,
          };
        }

        UNSAFE_componentWillMount() {
          const { account } = AccountContextBehaviorSubject$.getValue();
          const emailLangSettings = [
            {
              contact_lang: "fr",
              email_lang: "blocked",
            },
            {
              contact_lang: "en",
              email_lang: "blocked",
            },
            {
              contact_lang: "es",
              email_lang: "blocked",
            },
            {
              contact_lang: "it",
              email_lang: "blocked",
            },
            {
              contact_lang: "de",
              email_lang: "blocked",
            },
            {
              contact_lang: "nl",
              email_lang: "blocked",
            },
            {
              contact_lang: "pt",
              email_lang: "blocked",
            },
            {
              contact_lang: "default",
              email_lang: "blocked",
            },
          ];

          /* get value from account and make  */
          for (const emailLangSetting of account.email_lang_settings) {
            for (const index in emailLangSettings) {
              if (emailLangSetting.contact_lang === emailLangSettings[index].contact_lang || 
                emailLangSetting.contact_lang === "non renseigné" && emailLangSettings[index].contact_lang === "default") {
                emailLangSettings[index].email_lang = emailLangSetting.email_lang;
              }
            }
          }
          this.setState({ emailLangSettings, idAccount: account._id });
        }

        closeEmailLang(e) {
          const path = "/customers";
          EventSystem.publish("url_switch", path);
        }

        updateLangSetting = (emailLang, contactLang) => {
          const { emailLangSettings } = this.state;
          for (const i in emailLangSettings) {
            if (contactLang === emailLangSettings[i].contact_lang) {
              emailLangSettings[i].email_lang = emailLang;
              this.setState({ emailLangSettings });
            }
          }
        };

        sortAlphabetical() {
          const { emailLangSettings, sortAsc } = this.state;
          if (sortAsc) {
            emailLangSettings.sort((a, b) =>
              a.email_lang > b.email_lang ? 1 : -1
            );
          } else {
            emailLangSettings.sort((a, b) =>
              a.email_lang > b.email_lang ? -1 : 1
            );
          }
          this.setState({ sortAsc: !sortAsc, emailLangSettings });
        }

        async save() {
          const { intl } = this.props;

          const body = {
            idAccount: this.state.idAccount,
            emailLangSettings: this.state.emailLangSettings.map(langSetting => 
              langSetting.contact_lang === "default" ? { ...langSetting, contact_lang: "non renseigné" } : langSetting),
          };
          const options = {
            method: "POST",
            body: JSON.stringify(body),
          };
          this.props.auth
            .fetch("/api/account_management/editEmailLangSettings", options)
            .then((res) => {
              const message = res.success
                ? intl.messages["emailLangSettings.save.success"]
                : intl.messages["emailLangSettings.save.error"];
              EventSystem.newNotification("notification.action", message);
              this.closeEmailLang();
            });
          await reloadAccountContext();
        }

        render() {
          const { classes, intl } = this.props;
          return (
            <div className={classes.root}>
              <div className={classes.header}>
                <div className={classes.title}>
                  <FormattedMessage
                    id="button.lang_settings"
                    defaultMessage="Gérer les langues"
                  />
                </div>
              </div>
              <div
                onClick={this.closeEmailLang}
                className={"fal fa-times " + classes.close_button}
                style={{ fontSize: "24px" }}
              />
              <Paper className={classes.table}>
                <Table style={{ paddingLeft: "50px" }}>
                  {/* HEADER PART */}
                  <TableHead>
                    <TableRow>
                      <TableCell
                        key="contactLang"
                        className={classes.tableHeaderCell}
                      >
                        <FormattedMessage
                          id="emailLangSettings.tableHeadColumn1"
                          defaultMessage="Langue des contacts"
                        />
                      </TableCell>
                      <TableCell
                        key="emailLang"
                        className={classes.tableHeaderCell}
                      >
                        <FormattedMessage
                          id="emailLangSettings.tableHeadColumn2"
                          defaultMessage="Langue des emails"
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* BODY PART */}
                  <TableBody>
                    {this.state.emailLangSettings.map((setting) => {
                      return (
                        <TableRow key={setting.contact_lang}>
                          <TableCell className={classes.tableCell}>
                            {setting.contact_lang}
                          </TableCell>
                          <TableCell
                            className={[classes.tableCell, "noVerticalPadding"]}
                            style={{ padding: "none !important" }}
                          >
                            <div style={{ width: "110px", paddingleft: "9%" }}>
                              <ItemPicker
                                whithoutBorder
                                width={120}
                                titleKey={setting.email_lang}
                                listName="availableLanguages"
                                onSelect={(emailLang) =>
                                  this.updateLangSetting(
                                    emailLang,
                                    setting.contact_lang
                                  )
                                }
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
              <div className={classes.bottomText}>
                <FormattedMessage
                  id="emailLangSettings.addLang"
                  defaultMessage="Vous voulez ajouter une langue ?"
                />
                &nbsp;
                <FormattedMessage
                  id="emailLangSettings.contactUs"
                  defaultMessage="Contactez-nous via le chat"
                />
              </div>
              <FormButtonTO
                type={"primary"}
                classes={{ button: classes.formButton }}
                label={intl.messages["button.save"]}
                action={this.save}
              />
            </div>
          );
        }
      }
    )
  )
);
