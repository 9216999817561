import {useState, useEffect, useCallback} from "react";
import { Translation } from "./../../../common/Translation";

import { TextField } from "./../../../common/Input/TextField";
import { CustomButton } from "./../../../common/Button/CustomButton";

import classes from "./AddReviewLinkComponent.module.scss";
import { useAuth } from "../../../../hooks/useAuth";
import { EventSystem } from "./../../../../eventsystem/EventSystem";
import { useIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";
import { AccountContextBehaviorSubject$, reloadAccountContext } from "../../../../contexts/context";
import { AuthService } from "../../../AuthService";

export const AddReviewLinkComponent = ({ secondaryCategory, editor, templateId, cancelCategoryEmptyState, language }) => {
  const auth = useAuth();
  const intl = useIntl();
  const categoryToPlatform = {
    "trustpilot": "Trustpilot",
    "avisverifies": "Avis Vérifiés"
  };
  const { account } = AccountContextBehaviorSubject$.getValue();

  const [value, setValue] = useState("");
  const [isManualLink, setIsManualLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAccountReviewLinkByPlatform = useCallback((platform) => {
    switch(secondaryCategory) {
      case "trustpilot":
        return account.trustpilotLink;
      case "avisverifies":
        return account.avisverifiesLink;
    }
  }, [account, secondaryCategory]);

  useEffect(() => {
    const accountReviewLink = getAccountReviewLinkByPlatform(secondaryCategory);

    if (accountReviewLink) {
      setValue(accountReviewLink);
      setIsManualLink(true);
    }
  }, [account, getAccountReviewLinkByPlatform, secondaryCategory]);

  function isValidLink() {
    const regex = new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/);
    return value.match(regex);
  };

  const loadTrustPilotReview = async (options) => {
    const Auth = new AuthService();
    try {
      const result = await Auth.fetch(`/account_management/getReviewsFromTrustpilot?templateId=${options.templateId}`);
      if (!result) {
        return null;
      }
      return { globalRating: result?.globalRating, reviews: result?.reviews.filter(review => review.customerNickname) };
    } catch {}
  };
  
  const loadAvisVerifiesReviews = async (options) => {
    const Auth = new AuthService();
    try {
      const result = await Auth.fetch(`/account_management/getReviewsFromAvisVerifies?templateId=${options.templateId}`);
      if (!result) {
        return null;
      }
      return { globalRating: result?.globalRating, reviews: result?.reviews.filter(review => review.customerNickname) };
    } catch {};
  };

  const getMinimizedName = name => {
    let minimizedName = "";
  
    if (name.includes[" "]) {
      const splitted = name.split(" ");
      minimizedName = splitted[0][0] + " " + splitted[splitted.length - 1][0];
    } else {
      minimizedName = name[0] + " " + name[1];
    }
  
    return minimizedName.toUpperCase();
  };

  function insertLink() {
    setIsLoading(true);
    auth.fetch("/account_management/insertReviewsLink", {
      method: "POST",
      body: JSON.stringify({
        [secondaryCategory]: value
      })
    })
      .then(async () => {
        const trustpilotReviews = await loadTrustPilotReview({ templateId });
        if(trustpilotReviews?.reviews?.length) {
          trustpilotReviews.reviews = trustpilotReviews?.reviews.sort((a, b) => b.rating - a.rating).map(review => ({
            ...review,
            minimizedName: getMinimizedName(review.customerNickname),
          }));
        }
        const avisverifiesReviews = await loadAvisVerifiesReviews({ templateId });
        if(avisverifiesReviews?.reviews?.length) {
          avisverifiesReviews.reviews = avisverifiesReviews?.reviews.sort((a, b) => b.rating - a.rating).map(review => ({
            ...review,
            minimizedName: getMinimizedName(review.customerNickname),
          }));
        }

        editor.runCommand("kiliba:blocks:updateReviewBlocks" , { trustpilotReviews, avisverifiesReviews, language });
        cancelCategoryEmptyState(secondaryCategory);
        setIsLoading(false);
        await reloadAccountContext();
      });
  }

  const displayError = useCallback(() => {
    EventSystem.newNotification("notification.error", intl.messages["error.main_error"]);
    setValue("");
    setIsLoading(false);
  }, [intl]);

  useEffect(() => {
    if (editor) {
      editor.on("loadReviewsError", displayError);
      return () => {
        editor.off("loadReviewsError", displayError);
      };
    }
  }, [editor, displayError]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Translation id="addReviewLinkComponent.title" variables={{ platform: categoryToPlatform[secondaryCategory] }} />
      </div>
      <div className={classes.inputContainer}>
        <TextField
          value={value}
          onChange={event => setValue(event.target.value)}
          placeholder={"https://"}
        />
      </div>

      <CustomButton
        disabled={!isValidLink || !value || value === getAccountReviewLinkByPlatform(secondaryCategory) || isLoading}
        onClick={insertLink}
      >
        { !isLoading ? 
          !isManualLink ? 
            <Translation id="addLinkPopup.button.insert" /> :
            <Translation id="addLinkPopup.button.update" /> 
          : <CircularProgress size={24} className={classes.loading} /> }
      </CustomButton>
    </div>
  );
};