import {processStarsMjml } from "./review.tools";
const LABEL = "globalAvisverifies2";
const CATEGORY = "review:avisverifies";

export const globalAvisverifies2 = ({ rating, fontFamily, textColor, dominantColor }) => ({
  label: LABEL,
  category: CATEGORY,
  block: () => {
    if (!rating) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const starMjml = processStarsMjml("AV", rating, "right");

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${dominantColor}" padding-top="10px" padding-bottom="10px" full-width="full-width">
        <mj-section padding-bottom="0px" padding-top="0px">
          <mj-column width="25%"></mj-column>
          <mj-column>
            <mj-image data-readonly="true" padding-bottom="0px" padding-top="0px" width="85px" align="center" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/avisverifies-logo.png"></mj-image>
          </mj-column>
          <mj-column width="25%"></mj-column>
        </mj-section>
        <mj-section padding-bottom="5px" padding-top="5px">
          <mj-group>
            <mj-column width="55%" css-class="globalAvisverifies2-column-55" vertical-align="middle">
              <mj-raw>
                <tr>
                  <td align="center">
                    <div style="text-align:right;padding-top:0px;padding-bottom:0px">
                      ${starMjml}
                    </div>
                  </td>
                </tr>
              </mj-raw>       
            </mj-column>
            <mj-column width="45%" css-class="globalAvisverifies2-column-45" vertical-align="middle">
              <mj-text data-readonly="true" align="left" font-family="${fontFamily}" color="${textColor}" font-weight="600" padding-left="8px" padding-right="8px" padding-bottom="0px" padding-top="0px" font-size="14px" line-height="14px">${rating}/5</mj-text>
            </mj-column>
          </mj-group>
        </mj-section>
      </mj-wrapper>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: "review:avisverifies",
      activate: true,
      content: mjml,
    };
  },
  style: `
    .globalAvisverifies2-column-55 {
      width: 55% !important;
    }

    .globalAvisverifies2-column-45 {
      width: 45% !important;
    }
  `,
});