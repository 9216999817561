import React, { Component } from "react";
import { SwitchBase } from "./SwitchBase.js";

export const Checkbox = class Checkbox extends Component {
  render() {
    const {
      checked,
      indeterminate,
      disabled,
      disabledExplanation,
      helperText,
      label,
      onChange,
    } = this.props;

    return (
      <SwitchBase
        type="checkbox"
        checked={checked || false} // this component is always controlled
        indeterminate={indeterminate}
        disabled={disabled}
        disabledExplanation={disabledExplanation}
        helperText={helperText}
        label={label}
        onChange={onChange}
      />
    );
  }
};
