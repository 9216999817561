import { useMemo, useState, useEffect } from "react";

import { Translation } from "../../../common/Translation";
import { useAuth } from "../../../../hooks/useAuth";

import classes from "./ImageBentoLibrary.module.scss";
import { ConfirmDeleteImagePopup } from "../../popups/ConfirmDeleteImagePopup";
import { UploadImageInput } from "./UploadImageInput";

export const ImageBentoLibrary = ({ selectedUrl, onSeeMore, onChange }) => {

  const auth = useAuth();

  const [uploadedImages, setUploadedImages] = useState([]);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [savedImages, setSavedImages] = useState([]);
  
  useEffect(() => {
    auth.fetch("/account_management/creatorStudioImages").then(setSavedImages);
  }, [auth]);

  useEffect(() => {
    if (uploadedImages.length) { return; }
    auth.fetch("/account_management/imageAssets?type=images").then(response => {
      setUploadedImages(response.reverse());
    });
  }, [auth, uploadedImages]);

  const onUpload = (fileData) => {
    auth.fetch("/account_management/uploadImageAsset", {
      method: "POST",
      body: JSON.stringify({
        file: {
          filename: fileData.name,
          type: fileData.type,
          content: fileData.content,
        },
        folder: "images",
      })
    }).then(url => {
      setUploadedImages(prev => [url, ...prev]);
      setSavedImages(prev => [...prev, { url, type: "upload" }]);
      auth.fetch(`/account_management/addCreatorStudioImage?type=upload&url=${encodeURIComponent(url)}`, {
        method: "POST",
      });
    });
  };

  const images = useMemo(() => {
    const _savedImages = savedImages?.map(({ url }) => url).reverse() || [];

    const result = [...new Set([..._savedImages, ...uploadedImages])];

    return result;
  }, [savedImages, uploadedImages]);

  const deleteImage = () => {
    if (uploadedImages.includes(imageToDelete)) {
      auth.fetch(`/account_management/deleteImageAsset?imageUrl=${encodeURIComponent(imageToDelete)}`, {
        method: "DELETE",
      });
      setUploadedImages(prev => [...prev].filter(url => url !== imageToDelete));
    }
    if (savedImages.find(elem => elem.url === imageToDelete)) {
      auth.fetch(`/account_management/deleteCreatorStudioImage?urls=${encodeURIComponent(imageToDelete)}`, {
        method: "DELETE",
      });
      setSavedImages(prev => [...prev].filter(elem => elem.url !== imageToDelete));
    }
    setImageToDelete(null);
  };

  const displayedImages = useMemo(() => {
    const result = [...images];
    if (images.length <= 3) {
      return result;
    }

    return [
      images[0],
      images[2],
      images[3],
      images[1],
      ...images.slice(4),
    ];

  }, [images]);

  const _onSeeMore = event => {
    event.stopPropagation();
    onSeeMore();
  };

  return (
    <div className={classes.container}>
      <h4 className={classes.title}><Translation id="imageBentoLibrary.title" /></h4>

      <div className={classes.bentoContainer}>

        <div className={`${classes.imageBentoPart1} ${displayedImages.length === 3 ? classes.imageBentoPart1_3 : ""} ${displayedImages.length > 3 ? classes.imageBentoPart1Multiple : ""}`}>
          <UploadImageInput onUpload={onUpload} />

          {displayedImages.slice(0, 3).map((image, idx) => (
            <div key={idx} className={`${classes.imageContainer} ${image === selectedUrl ? classes.imageContainerSelected : ""}`} onClick={() => onChange(image)}>
              <img 
                alt=""
                src={image}
              />
              <div 
                onClick={event => {
                  event.stopPropagation();
                  setImageToDelete(image);
                }} 
                className={classes.iconDeleteImageContainer}
              >
                <i className="fa-solid fa-trash-can" />
              </div>
            </div>
          ))}
        </div>

        {displayedImages.length > 3 && 
          <div className={`${classes.imageBentoPart2} ${classes.imageBentoPart2_5}`}>
            {displayedImages.slice(3, 5).map((image, idx) => (
              <div key={idx} className={`${classes.imageContainer} ${image === selectedUrl ? classes.imageContainerSelected : ""}`} onClick={() => idx === 2 && images.length > 6 ? null : onChange(image)}>
                <img
                  alt=""
                  src={image}
                />
                {idx === 1 && images.length > 5 ?
                  <div className={classes.imageOverlay} onClick={(event) => _onSeeMore(event)}>
                    <div className="infoMedium"><Translation id="imageBentoLibrary.seeMore" /></div>
                  </div> :
                  <div onClick={() => setImageToDelete(image)} className={classes.iconDeleteImageContainer}>
                    <i className="fa-solid fa-trash-can" />
                  </div>
                }
              </div>
            ))}
          </div>
          
        }

      </div>

      <ConfirmDeleteImagePopup 
        isOpen={!!imageToDelete}
        close={() => setImageToDelete(null)}
        deleteImage={deleteImage}
      />
    </div>
  );
};
