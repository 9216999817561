import { useIntl } from "react-intl";

import { Checkbox } from "../../../../../../common/Input/Checkbox.js";

export const ShowHeadbandPromoInput = ({ promoCodeTemplateWrapper, setPromoCodeTemplateWrapperProp }) => {
  const intl = useIntl();

  return (
    <Checkbox
      checked={promoCodeTemplateWrapper.promoCodeTemplate.showHeadbandPromo}
      onChange={(event) => setPromoCodeTemplateWrapperProp("promoCodeTemplate.showHeadbandPromo", event.target.checked)}
      label={
        intl.messages[
          "usecase.promoCodeTemplateEditor.options.showHeadbandPromo"
        ]
      }
    />
  );
};