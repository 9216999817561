import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { injectIntl } from "react-intl";

import "../../styles/scss/Template.css";
import "../../styles/scss/Popup.css";

import { styles } from "../../styles/usecase.js";

export const UsecaseAdder = withStyles(styles)(
  injectIntl(
    class UsecaseAdder extends Component {
      render() {
        const { classes, intl, onClick } = this.props;

        return (
          <div
            onClick={onClick}
            id={"usecaseCard-usecaseAdder"}
            className={classes.usecaseAdderContainer}
            data-cy="usecaseAdder"
          >
            <CardActionArea>
              <CardMedia>
                <div className={`${classes.usecaseAdder}`}>
                  <i
                    className={`${classes.usecaseAdderIcon} fas fa-plus-circle`}
                  />
                  <h3 className={classes.usecaseAdderTitle}>
                    {intl.messages["usecaseList.usecaseAdder.title"]}
                  </h3>
                </div>
              </CardMedia>
            </CardActionArea>
          </div>
        );
      }
    }
  )
);
