import { Component } from "react";
import { injectIntl } from "react-intl";

import { HtmlCustomizerDropdown } from "../../HtmlCustomizerDropdown/HtmlCustomizerDropdown.js";

export const HtmlCustomizerTooltipInputFontSize = injectIntl(
  class HtmlCustomizerTooltipInputFontSize extends Component {
    render() {
      const { intl, fontSizes, getIsSelected, onSelect } = this.props;

      return (
        <HtmlCustomizerDropdown
          choices={fontSizes.map((fontSize) => ({
            label: fontSize,
            value: `fontsize-${fontSize}`,
          }))}
          getIsSelected={(choice) => getIsSelected(choice.value)}
          onSelect={(choice) => onSelect(choice.value)}
          label={intl.messages["htmlCustomizerTooltip.fontSize"]}
        />
      );
    }
  }
);
