import { forwardRef, useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

import { CustomButton } from "../../../Button/CustomButton";
import { HtmlCustomizerProposalsList } from "../../common/HtmlCustomizerProposalsList/HtmlCustomizerProposalsList";
import { HtmlCustomizerTextField } from "../HtmlCustomizerTextField/HtmlCustomizerTextField";
import { HtmlCustomizerSubject } from "../HtmlCustomizerSubject/HtmlCustomizerSubject";
import { useAuth } from "../../../../../hooks/useAuth";

import { palette } from "../../../../../styles/palette";
import { reloadAccountContext } from "../../../../../contexts/context";

const styles = theme => ({
  container: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
  },
  containerPadding: {
    paddingTop: 20,
    paddingBottom: 25,
  },
  button: {
    zIndex: 2,
    position: "relative",
  },
  proposal: {
    color: palette.anotherlightgray,
    padding: "20px 30px",
    lineHeight: "18px",
    cursor: "pointer",
    "&:hover": {
      background: palette.neutralCarts,
    }
  },
  selected: {
    color: palette.black,
    background: palette.neutralCarts,
  },
  loading: {
    textAlign: "center",
    color: ({ usecaseName }) => usecaseName.startsWith("halloween") ? palette.white : palette.black,
  }
});

const useStyles = makeStyles(styles);

export const HtmlCustomizerWordingProposals = forwardRef((props, ref) => {
  const {
    style = {},
    onResize = () => { },
    usecaseName,
    value,
    setGenerated,
    setValue,
    inputStyle,
    keywords = [],
    isGenerated,
    originalPromptVersion,
    isWordingsLoading,
    partKey,
    language,
  } = props;

  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(0);
  const [originalValue, setOriginaValue] = useState(value);
  const [key, setKey] = useState(1);
  const [closed, setClosed] = useState(false);
  const [proposals, setProposals] = useState(null);
  const [typingQueue, setTypingQueue] = useState([]);

  const scrollingContentRef = useRef();

  const classes = useStyles({ style, usecaseName });
  const auth = useAuth();

  useEffect(() => {
    if (ref) {
      const observer = new MutationObserver(onResize);
      observer.observe(ref.current, { subtree: true, attributes: true, attributeOldValue: true, attributeFilter: ["style"], childList: true });
      return () => observer.disconnect();
    }
  }, [ref, onResize]);

  useEffect(() => {
    scrollingContentRef.current?.scroll({ top: scrollingContentRef.current.scrollHeight, behavior: "smooth" });
  }, [proposals, typingQueue]);

  useEffect(() => {
    if (!isWordingsLoading) {
      setOriginaValue(value);
      setKey(previous => previous + 1);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWordingsLoading, setOriginaValue]);

  useEffect(() => {
    setOriginaValue(value);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const generateWording = async () => {
    if (isWordingsLoading) {
      return;
    }

    if (closed) {
      setClosed(false);
    } else {
      setLoading(true);
      try {
        const response = await auth.fetch("/account_management/generateWordingProposals", {
          method: "POST",
          body: JSON.stringify({
            usecase: usecaseName,
            nbProposals: proposals ? 5 : 4,
            key: partKey,
            language,
          }),
        });

        await reloadAccountContext();
        setTypingQueue(response.proposals[usecaseName]
          .filter(proposal => !proposals || 
            !proposals.proposals[usecaseName].find(elem => elem === proposal)));
        setProposals(previous => ({
          ...response,
          proposals: previous ? previous.proposals : { [usecaseName]: [] },
        }));
        setHasError(false);
        setLoading(false);
      } catch (error) {
        setHasError(true);
        setLoading(false);
      }
    }
  };

  const updateProposal = (idx) => {
    if (selected === idx) {
      return;
    }

    if (idx === 0) {
      setGenerated(null);
    } else {
      if (!isGenerated) {
        setValue(originalValue);
      }
      setGenerated({
        wording: proposals.proposals[usecaseName][idx - 1],
        promptVersion: proposals.prompt_version,
      });
    }
    setSelected(idx);
    setKey(previous => previous + 1);
    setClosed(true);
  };

  const onEdit = (value) => {
    if (!isGenerated) {
      setValue(value);
    } else {
      setGenerated({
        wording: value,
        promptVersion: originalPromptVersion,
      });
    }
  };

  const close = () => {
    setTypingQueue([]);
    setClosed(true);
  };

  const wordingsLoadingText = <div className={classes.loading}><FormattedMessage id="htmlCustomizerWordingProposal.loading1" />...</div>;
  const errorText = <div className={classes.loading}><FormattedMessage id="htmlCustomizerWordingProposal.error" />...</div>;

  const textField = partKey === "subject" ?
    <HtmlCustomizerSubject
      value={value}
      onChange={onEdit}
      onAiGenerate={(!loading && !typingQueue.length) ?
        generateWording : closed ? () => setClosed(false) : null
      }
    /> :
    <HtmlCustomizerTextField
      key={key}
      value={value}
      setValue={onEdit}
      style={inputStyle}
      keywords={keywords}
      paddingBottom={Math.ceil(ref.current?.getBoundingClientRect().height)}
      onAiGenerate={(!loading && !typingQueue.length) ?
        generateWording : closed ? () => setClosed(false) : null
      }
    />;

  const isWordingsReallyLoading = isWordingsLoading && !isGenerated;

  const showProposalList = !closed && (proposals || loading);

  return (
    <>
      <div>
        {
          (hasError || props.hasError) ? errorText :
            isWordingsReallyLoading ? wordingsLoadingText :
              textField
        }
        <div ref={ref} style={style} className={`${classes.container} ${showProposalList || partKey === "title" ? classes.containerPadding : ""}`}>
          {showProposalList ?
            <HtmlCustomizerProposalsList
              close={close}
              proposals={[originalValue, ...(proposals ? proposals.proposals[usecaseName] : [])]}
              selected={selected}
              proposalToWrite={typingQueue.length ? typingQueue[0] : null}
              onProposalWritten={(proposal) => {
                setProposals(previous => ({
                  ...previous,
                  proposals: {
                    [usecaseName]: [...previous.proposals[usecaseName], proposal],
                  }
                }));
                setTypingQueue(previous => [...previous.slice(1)]);
              }}
              loading={loading}
              generateWording={generateWording}
              showStopButton={typingQueue.length > 1}
              onStopGeneration={() => setTypingQueue(previous => [previous[0]])}
              updateProposal={updateProposal}
              partKey={partKey}
            /> :
            <>
              {
                !props.hasError && partKey === "title" &&
                <div className={classes.button}>
                  <CustomButton
                    onClick={(!loading && !typingQueue.length) ?
                      generateWording : closed ? () => setClosed(false) : null
                    }
                    type={"primary"}
                    size={"xs"}
                  >
                    <FormattedMessage id="button.generateWording" />
                  </CustomButton>
                </div>
              }
            </>
          }
        </div>
      </div>
    </>
  );
}
);