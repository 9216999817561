import { useIntl } from "react-intl";
import { FormButtonTO } from "../../FormButtonTO";
import { Dialog } from "../../common/Dialog";
import { Translation } from "../../common/Translation";

import classes from "./CheckNewsletterPopup.module.scss";

export const CheckNewsletterPopup = ({ isOpen, close, onNext }) => {

  const intl = useIntl();

  return (
    <Dialog 
      PaperProps={{ style: { borderRadius: 20 } }}
      isOpen={isOpen}
      close={close}
    >
      <div className={classes.container}>
        <h2 className={classes.title}><Translation id="checkNewsletterPopup.title" /></h2>
        <div className={classes.buttonContainer}>
          <FormButtonTO 
            label={intl.messages["dialog.back"]}
            action={close}
            size="sm"
            type="secondary"
          />
          <FormButtonTO 
            label={intl.messages["checkNewsletterPopup.button"]}
            action={() => {
              close();
              onNext();
            }}
            size="sm"
          />
        </div>
      </div>
    </Dialog>
  );
};