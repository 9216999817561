import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import { injectIntl, FormattedMessage } from "react-intl";
import { AuthService } from "../AuthService";
import { TextField } from "../common/Input/TextField.js";
import { PasswordTextField } from "../common/Input/PasswordTextField";
import { SubmitButton } from "../common/Button/SubmitButton";

import resetPasswordImg from "../../public/password.png";

import { styles } from "./style.js";

export const ResetPassword = withStyles(styles)(
  injectIntl(
    class ResetPassword extends Component {
      constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleHiddenEyes = this.handleHiddenEyes.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.leaveFocus = this.leaveFocus.bind(this);
        this.changeUrl = this.changeUrl.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
        this.Auth = new AuthService();
        this.state = {
          email: "",
          password: "",
          passwordIsValid: false,
          error_email: false,
          error_password: false,
          global_error: false,
          error_text: "",
          clickable: true,
          confirmPassword: "",
          hidden: true,
        };
      }

      handleHiddenEyes = (event) => {
        this.setState({ hidden: !this.state.hidden });
      };

      _checkStatus(response) {
        const status = response.status;
        return response.json().then((result) => {
          return new Promise(function (resolve, reject) {
            // raises an error in case response status is not a success
            if (status >= 200 && status < 300) {
              // Success status lies between 200 to 300
              resolve(result);
            } else {
              reject(result);
            }
          });
        });
      }

      validateEmail(email) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      leaveFocus = (event) => {
        this.setState({ hidden: false });
      };
      handleFocus = (event) => {
        this.setState({ hidden: true });
      };

      handlePasswordChange = (event) => {
        this.setState({
          password: event.target.value,
        });
      };
      handleConfirmPassword = (event) => {
        this.setState({ confirmPassword: event.target.value });
        if (event.target.value !== this.state.password) {
          console.log("error");
        }
      };

      handleChange = (name) => (event) => {
        this.setState({
          [name]: event.target.value,
        });
      };

      changeUrl = (url) => (event) => {
        this.props.history.replace(url);
      };

      handleSubmit(e) {
        e.preventDefault();
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const username = params.get("username");
        const confirmationCode = params.get("confirmation_code");
        const password = this.state.password;

        if (this.state.password !== this.state.confirmPassword) {
          this.setState({
            global_error: true,
            error_text: "error.password_unmatch",
            clickable: true,
          });
          return;
        }
        
        this.Auth.forgotPasswordSubmit(username, confirmationCode, password)
          .then(result => {
            this.props.history.push({
              pathname: "/login",
              isreset: true,
            });
          })
          .catch(error => {
            switch(error.code) {
              case "ExpiredCodeException":
                this.setState({
                  global_error: true,
                  error_text: "error.link_code_expired",
                  clickable: true,
                });
                break;
              case "InvalidPasswordException":
                this.setState({
                  global_error: true,
                  error_text: "error.password_length",
                  clickable: true,
                });
                break;
              default:
                this.setState({
                  global_error: true,
                  error_text: "error.password_update",
                  clickable: true,
                });
                break;
            }
          });
      }

      render() {
        const { classes } = this.props;
        return (
          <div className={classes.root}>
            <Paper className={classes.mainwindow}>
              <Grid
                container
                item
                justify-xs-center="true"
                xs={12}
                sm={12}
                spacing={0}
              >
                <Grid item xs={6}>
                  <div className={classes.confirmModalContainer}>
                    <div className={classes.confirmModalTitle}>
                      <FormattedMessage id="resetPassword.welcome" />
                    </div>

                    <p className={classes.confirmModalTitleContent}>
                      <FormattedMessage id="resetPassword.slogan" />
                    </p>

                    <div className={classes.marginUp}>
                      <div className={classes.error}>
                        {this.state.global_error && (
                          <FormattedMessage id={this.state.error_text} />
                        )}
                        &nbsp;
                      </div>

                      <form
                        className={classes.formGrid}
                        onSubmit={this.handleSubmit}
                      >
                        <div className={classes.resetPasswordLabel}>
                          <FormattedMessage id="resetPassword.label1" />
                        </div>
                        <PasswordTextField
                          value={this.state.password}
                          onChange={this.handlePasswordChange}
                          autoFocus
                          onEyeClick={this.handleHiddenEyes}
                          onPasswordValid={() => {
                            this.setState({
                              passwordIsValid: true,
                              ...(
                                this.state.error_text === "error.password.format" && {
                                  global_error: false,
                                  error_text: "",
                                  clickable: true
                                }
                              )
                            });
                          }}
                          onPasswordInvalid={() => {
                            this.setState({
                              passwordIsValid: false,
                              global_error: true,
                              error_text: "error.password.format",
                              clickable: true,
                            });
                          }}
                        />

                        <div
                          className={`${classes.resetPasswordLabel} ${classes.marginUp}`}
                        >
                          <FormattedMessage id="resetPassword.label2" />
                        </div>
                        <TextField
                          value={this.state.confirmPassword}
                          onChange={(event) =>
                            this.setState({
                              confirmPassword: event.target.value,
                            })
                          }
                          type="password"
                          onEyeClick={this.handleHiddenEyes}
                        />

                        <div>
                          <SubmitButton
                            style={{ fontFamily: "Poppins" }}
                            label="resetPassword.button"
                            disabled={
                              !this.state.passwordIsValid ||
                              this.state.password !== this.state.confirmPassword
                            }
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.confirmModalRightWindow}>
                    <img
                      className={classes.slide}
                      alt=""
                      src={resetPasswordImg}
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </div>
        );
      }
    }
  )
);

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};
