import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import Paper from "@material-ui/core/Paper";
import moment from "moment/min/moment-with-locales";

import { FormButtonTO } from "../../FormButtonTO.jsx";
import { CreateUsecasePopup } from "../CreateUsecasePopup";

import message from "../../../public/message.png";

import { palette } from "../../../styles/palette.js";

const styles = (theme) => ({
  root: {
    padding: "100px 0",
    position: "relative",
  },
  details_title: {
    fontFamily: "Coolvetica Book",
    fontSize: "40px",
    fontWeight: "600",
    textAlign: "center",
    color: palette.black,
  },
  container: {
    display: "flex",

    "&::before": {
      content: "\"\"",
      border: "1px solid #c2c2c2",
      alignSelf: "stretch",
    },

    "& div:first-child": {
      order: -1,
    },
  },
  leftPart: {
    flex: 1,
  },
  item: {
    flex: 1,
  },
  paper: {
    padding: "10% 20%",
    marginLeft: "15%",
    marginRight: "10%",
  },
  rightPart: {
    marginLeft: "20%",
    marginRight: "25%",
    textAlign: "center",

    "& img": {
      marginTop: "70px",
      marginBottom: "10px",
      height: "100px",
    },

    "& button": {
      marginTop: "20px",
    },
  },
  title: {
    fontSize: "18px",
    fontFamily: "Coolvetica",
  },
  text: {
    fontSize: "16px",
    color: "#838580",
  },
  marginUp: {
    marginTop: "50px",
  },
  buttonContainer: {
    marginTop: "100px",
    textAlign: "center",
  },
  rightText1: {
    fontSize: "18px",
  },
  showPromoCodeTemplateButton: {
    marginTop: 16,
    lineHeight: 0.9,

    "& small": {
      color: palette.primaryBlack,
      borderBottom: "1px solid " + palette.primaryBlack,
      cursor: "pointer",
    },
  },
});

export const UsecaseCustom = withStyles(styles)(
  injectIntl(
    class UsecaseCustom extends Component {
      constructor(props) {
        super(props);
        moment.locale(props.intl.locale);

        this.state = {
          createUsecasePopupIsOpen: false,
        };
      }

      render() {
        const {
          classes,
          customName,
          customDate,
          onUpdate,
          showEmailEditor,
          emailExamples,
          showPromoCodeTemplateEditor,
          intl,
        } = this.props;

        return (
          <div className={classes.root}>
            <div
              className={classes.details_title}
              style={{ marginBottom: "70px" }}
            >
              <FormattedMessage id={"details.custom.content.title"} />
            </div>

            <div className={classes.container}>
              <div className={classes.item}>
                <Paper classes={{ root: classes.paper }}>
                  <div className={classes.title}>
                    <FormattedMessage id={"details.custom.content.name"} />
                  </div>
                  <div data-cy="textName" className={classes.text}>
                    {customName}
                  </div>

                  <div className={`${classes.title} ${classes.marginUp}`}>
                    <FormattedMessage id={"details.custom.content.date"} />
                  </div>
                  <div data-cy="textDate" className={classes.text}>
                    {moment(new Date(customDate)).format("Do MMMM YYYY")}
                  </div>

                  <div className={classes.buttonContainer}>
                    <FormButtonTO
                      type={"secondary"}
                      label={
                        intl.messages["details.custom.content.button.edit"]
                      }
                      action={() =>
                        this.setState({ createUsecasePopupIsOpen: true })
                      }
                      dataCy="buttonUpdateUsecase"
                    />
                  </div>
                </Paper>
              </div>

              <div className={classes.item}>
                <div className={classes.rightPart}>
                  <div className={classes.rightText1}>
                    <FormattedMessage
                      id={"details.custom.content.email.description"}
                    />
                  </div>

                  <img alt="" src={message} />

                  <div>
                    <FormattedMessage
                      id={"details.custom.content.email.description2"}
                    />
                  </div>

                  <FormButtonTO
                    type={"primary"}
                    label={intl.messages["details.explanations.editEmail"]}
                    action={() => showEmailEditor(0, "customizer")}
                  />

                  <div className={classes.showPromoCodeTemplateButton}>
                    <small
                      onClick={() => showPromoCodeTemplateEditor(0)}
                      data-cy="showPromoCodeTemplateEditor"
                    >
                      {emailExamples && emailExamples[0].promoCodeTemplate
                        ? intl.messages["details.explanations.promoCode.edit"]
                        : intl.messages["details.explanations.promoCode.add"]}
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <CreateUsecasePopup
              isOpen={this.state.createUsecasePopupIsOpen}
              close={() => this.setState({ createUsecasePopupIsOpen: false })}
              name={customName}
              date={customDate}
              onSave={onUpdate}
            />
          </div>
        );
      }
    }
  )
);
