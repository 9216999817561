
import { CustomButton } from "../../../../common/Button/CustomButton";
import { KiTextField } from "../../../../common/Input/KiTextField.js";
import { AccountContext } from "../../../../../contexts/context";
import { useAuth } from "../../../../../hooks/useAuth";
import { useIntl } from "react-intl";
import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { EventSystem } from "../../../../../eventsystem/EventSystem";
import { HTTPResponseStatusError } from "../../../../AuthService";


import classes from "./WoocommerceDialog.module.scss";

export function WoocommerceForm({ onSubmit = () => { }, loading }) {

  const intl = useIntl();
  const auth = useAuth();
  const { account } = useContext(AccountContext);
  const [shopUrl, setShopUrl] = useState("");
  const [tutoLink, setTutoLink] = useState("");
  const [customerKey, setCustomerKey] = useState("");
  const [isValidCustomerKey, setIsValidCustomerKey] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [isValidSecretKey, setIsValidSecretKey] = useState(false);
  const history = useHistory();

  // SET URL_SHOP
  useEffect(() => {
    setShopUrl(account.url_shop);
    if(intl.locale === "fr") 
      setTutoLink("https://support.kiliba.com/hc/fr/articles/13729586358556-Comment-installer-le-module-Kiliba-sur-Woocommerce");
    if(intl.locale === "en") 
      setTutoLink("https://support.kiliba.com/hc/en-gb/articles/13729586358556-How-do-I-install-the-Kiliba-module-on-Woocommerce");
    if(intl.locale === "es")
      setTutoLink("https://support.kiliba.com/hc/es/articles/13729586358556--C%C3%B3mo-instalo-el-m%C3%B3dulo-Kiliba-en-Woocommerce");
  }, [account, intl]);

  // SET URL_SHOP
  useEffect(() => {
    if (customerKey && customerKey.startsWith("ck_") && customerKey.length > 10) {
      setIsValidCustomerKey(true);
    } else {
      setIsValidCustomerKey(false);
    }
  }, [customerKey]);

  // SET URL_SHOP
  useEffect(() => {
    if (secretKey && secretKey.startsWith("cs_") && secretKey.length > 5) {
      setIsValidSecretKey(true);
    } else {
      setIsValidSecretKey(false);
    }
  }, [secretKey]);


  async function checkApiKey() {
    const formattedUrl = !(shopUrl).startsWith("http") ? `https://${shopUrl}` : shopUrl;

    try {
      const response = await auth.fetch("/api/account_management/checkWoocommerceApiKey", {
        method: "POST",
        body: JSON.stringify({
          woocommerce: { "wc_api_consumer" : customerKey, "wc_api_secret" : secretKey, "wc_url_shop" : formattedUrl }
        })
      });

      EventSystem.newNotification("notification.success", intl.formatMessage({ id: "onBoarding.chooseCms.woocommerce.checkApiKey.success" }));
      onSubmit("woocommerce", { "wc_api_consumer" : customerKey, "wc_api_secret" : secretKey, "wc_url_shop" : formattedUrl });
    } catch(error) {
      if(error instanceof HTTPResponseStatusError) {
        const data = await error.response.json();
        if(data?.code === "ERR_WOOCOMMERCE_CREDENTIALS") {
          EventSystem.newNotification("notification.error", intl.formatMessage({ id: "onBoarding.chooseCms.woocommerce.checkApiKey.error.credentials" }));
        } else if(data?.code === "ERR_WOOCOMMERCE_MODULE") {
          EventSystem.newNotification("notification.error", intl.formatMessage({ id: "onBoarding.chooseCms.woocommerce.checkApiKey.error.module" }));
        } else {
          console.error("Unexpected error while checking WooCommerce API keys", error);
          EventSystem.newNotification("notification.error", intl.formatMessage({ id: "error.main_error" }));
        }
      } else {
        console.error("Unexpected error while checking WooCommerce API keys", error);
        EventSystem.newNotification("notification.error", intl.formatMessage({ id: "error.main_error" }));
      }
    }
  };
    

  return (
    <div className={classes.formContainer}>
      <div className={classes.steps}>
        <div className={classes.step}>
          <div className={`${classes.circle} textBold`}>1</div>
          <span className="text">{intl.messages["onBoarding.chooseCms.woocommerce.step1"]}</span>
        </div>
        <span className={classes.link}>
          <a className="link" target="_blank" rel="noreferrer" href="https://drive.google.com/drive/folders/1cs5KDuOJHvr2IGv9lr4Fxb1-PZDfhJpo?usp=sharing">
            {intl.messages["onBoarding.chooseCms.woocommerce.link"]}
          </a>
        </span>
        <div className={classes.altStep}>
          <div className={`${classes.circle} textBold`}>2</div>

          <div className={classes.step2Content}>
            <span className="text">{intl.messages["onBoarding.chooseCms.woocommerce.step2"]}</span>
            <span>
              <KiTextField
                placeholder={intl.messages["field.url"]}
                value={shopUrl}
                handleChange={event => setShopUrl(event.target.value)}
                textColor="black">
              </KiTextField>
            </span>
          </div>

        </div>
        <div className={classes.step3}>
          <div className={classes.altStep}>
            <div className={`${classes.circle} textBold`}>3</div>
            <span className={classes.step3Content}>
              <span style={{ marginTop: "5px" }} className="text">
                {intl.messages["onBoarding.chooseCms.woocommerce.step3"]}
              </span>
              <span className="infoSmall">
                {intl.messages["onBoarding.chooseCms.woocommerce.step3.subtitle"]}
              </span>
              <span style={{ marginTop: "10px" }} >
                <a className="linkSmall" target="_blank" rel="noreferrer" href={tutoLink}>
                  {intl.messages["onBoarding.chooseCms.woocommerce.step3.how"]}
                </a>
              </span>
              <span style={{ marginTop: "20px" }}>
                <KiTextField
                  placeholder={intl.messages["onBoarding.chooseCms.woocommerce.step3.customerKey"]}
                  value={customerKey}
                  handleChange={event => setCustomerKey(event.target.value)}
                  textColor="black">
                </KiTextField>
              </span>
              <span style={{ marginTop: "20px" }}>
                <KiTextField
                  placeholder={intl.messages["onBoarding.chooseCms.woocommerce.step3.secretKey"]}
                  value={secretKey}
                  handleChange={event => setSecretKey(event.target.value)}
                  textColor="black">
                </KiTextField>
              </span>
            </span>
          </div>
        </div>
      </div>

      <h4>{intl.messages["integration.cmsCard.woocommerce.text4"]}</h4>

      <div className={classes.buttonContainer}>
        <CustomButton
          onClick={() => {
            auth.sendEventToAmplitude("woocommerce_connection.click_start");
            checkApiKey();
          }}
          loading={loading}
          disabled={!isValidCustomerKey || !isValidSecretKey || !shopUrl}
        >
          {intl.messages["button.endform"]}
        </CustomButton>

        { window.location.pathname === "/on_boarding" &&
          <a href className="link" 
            onClick={() => {
              auth.sendEventToAmplitude("choose_cms.click_skip_this_step");
              history.push("/");
            }}
          >
            {intl.messages["onBoarding.chooseCms.skip"]}
          </a>
        }
        
      </div>

    </div>
  );
};
