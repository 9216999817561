import { promoCode1 } from "./promoCode1";
import { promoCode2 } from "./promoCode2";
import { promoCode3 } from "./promoCode3";
import { promoCode4 } from "./promoCode4";

export const getPromoCodeBlocks = ({ promoCode, dominantColor, fontFamily, textColor, isTextColorLight }) => {
  return [
    promoCode1({ promoCode, backgroundColor: dominantColor, fontFamily, textColor }),
    promoCode2({ promoCode, backgroundColor: dominantColor, fontFamily, textColor, buttonColor: textColor, isTextColorLight }),
    promoCode3({ promoCode, backgroundColor: dominantColor, fontFamily, textColor }),
    promoCode4({ promoCode, backgroundColor: dominantColor, fontFamily, textColor, buttonColor: textColor, isTextColorLight })
  ];
};

export const extractContentFromPromoCodeComponent = (component, wordings, language) => {
  const content = {};

  const buttonComponents = component.findType("mj-button");
  const textComponents = [...component.findType("mj-text"), ...component.findType("readonly-mj-text")];

  content.promoCodeId = component.attributes.attributes["data-promo-code-id"];
  content.backgroundColor = component.attributes.attributes["background-color"];
  if (buttonComponents.length) {
    content.buttonColor = buttonComponents[0].attributes.attributes["background-color"];
  }
  if(textComponents.length) {
    content.textColor = textComponents[0].attributes.attributes["color"];
  }

  return content;
};