import { prepareDateText } from "./promoCode.tools";
const LABEL = "promoCode1";
const CATEGORY = "promoCode:headband";

export const promoCode1 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {
    const { promoCode, backgroundColor, fontFamily, textColor } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    if (!promoCode) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const shouldIncludeQuantity = promoCode?.quantity;

    const prepareQuantityMjml = () => {
      return shouldIncludeQuantity ?`
          <mj-text font-family="${fontFamily}" color="${textColor}" data-readonly="true" align="center" padding-top="15px" font-size="14px" font-weight="500" padding-right="0" padding-left="0">{{quantity}}</mj-text>
        ` : "";
    };

    const mjml = `
    <mj-wrapper data-gjs="kilibaBlock" data-promo-code-id="${promoCode._id}" background-color="${backgroundColor}" padding-top="5px" padding-bottom="5px" full-width="full-width">
      <mj-section>
        <mj-column>
          <mj-text font-family="${fontFamily}" color="${textColor}" data-readonly="true" align="center" padding-bottom="10px" line-height="1.2" font-size="14px">{{promoCodeText}}</mj-text>
          <mj-text font-family="${fontFamily}" color="${textColor}" data-readonly="true" padding-top="0" padding-bottom="0" align="center" font-weight="600" font-size="18px" data-promo-code-value="true">{{code}}</mj-text>
          ${prepareQuantityMjml()}
        </mj-column>
      </mj-section>
    </mj-wrapper>
    `;

    const wordings = {
      promoCodeText: prepareDateText(promoCode, true, true, true),
      code: {
        fr: promoCode?.code,
        en: promoCode?.code,
        es: promoCode?.code,
        de: promoCode?.code,
        nl: promoCode?.code,
        it: promoCode?.code,
        pt: promoCode?.code,
      },
      quantity: {
        fr: `${promoCode?.quantity} coupons disponibles !`,
        en: `${promoCode?.quantity} coupons available !`,
        es: `${promoCode?.quantity} cupones disponibles !`,
        de: `${promoCode?.quantity} Gutscheine verfügbar !`,
        nl: `${promoCode?.quantity} coupons beschikbaar !`,
        it: `${promoCode?.quantity} coupon disponibili !`,
        pt: `${promoCode?.quantity} cupões disponíveis !`,
      },
      taxes: {
        fr: promoCode.includesTaxes ? "TTC" : "HT",
        en: promoCode.includesTaxes ? "VAT included" : "VAT excluded",
        es: promoCode.includesTaxes ? "IVA incluido" : "IVA excluido",
        de: promoCode.includesTaxes ? "MwSt. enthalten" : "MwSt. ausgeschlossen",
        nl: promoCode.includesTaxes ? "BTW inbegrepen" : "BTW uitgesloten",
        it: promoCode.includesTaxes ? "IVA inclusa" : "IVA esclusa",
        pt: promoCode.includesTaxes ? "IVA incluída" : "IVA excluída",
      }
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        wordings,
        editableProperties: [
          { key: "promoCode", type: "promoCode" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.color" },
          { key: "textColor", type: "color", label: "newsletter.rightMenu.property.textColor", group: "newsletter.rightMenu.propertyGroup.color" },
        ],
      }
    };
  }
});