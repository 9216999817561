import React, { useState, useEffect } from "react";
import { Translation } from "../common/Translation";
import { Dialog } from "../common/Dialog";
import { palette } from "../../styles/palette.js";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import classes from "./LoadingPopup.module.scss";
import loadingProduct from "../../public/newsletter/loadingProduct.gif";

export const LoadingPopup = ({ isOpen, isLoaded, subject, nbProduct, objective }) => {
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(1);
  const [visibleIndex, setVisibleIndex] = useState(1);
  const [steps, setSteps] = useState([
    require("../../public/newsletter/loading1.gif"),
    require("../../public/newsletter/loading2.gif"),
    require("../../public/newsletter/loading3.gif"),
    require("../../public/newsletter/loading4.gif"),
    require("../../public/newsletter/loading5.gif")
  ]);

  useEffect(() => {
    if (objective === "monoProduct" && steps.length < 6) {
      setSteps([...steps, require("../../public/newsletter/loading3.gif")]);
    }
  }, [objective, steps]);

  const createNewsletterTimerInterval = subject === "createNewsletter" && [3500, 5000, 4500, 3000];

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => Math.min(oldProgress + 1, 100));
    }, subject === "createNewsletter" ? objective === "monoProduct" ? 280 : 200 : 50);

    return () => {
      clearInterval(timer);
    };
  }, [subject]);

  useEffect(() => {
    if (
      (subject === "createNewsletter" && progress === 100 && step === (objective === "monoProduct" ? 6 : 5)) ||
      (subject === "product" && progress === 100 && step === 2)
    ) {
      isLoaded();
    }
  }, [progress, isLoaded, step, subject, objective]);

  // STEP TIMMING GESTION
  useEffect(() => {
    let timer;

    if (subject === "createNewsletter" || subject === "product") {
      timer = setInterval(() => {
        if (subject === "createNewsletter" && step < (objective === "monoProduct" ? 6 : 5)) {
          setStep((oldStep) => oldStep + 1);
          setVisibleIndex((prevIndex) => prevIndex + 1);
        } else if (subject === "product" && step < 2) {
          setStep((oldStep) => oldStep + 1);
        }
      }, createNewsletterTimerInterval[step - 1] || 6000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [step, subject]);

  return (
    <Dialog
      isOpen={isOpen}
      closeStyle="null"
      dialogProps={{ zIndex: 26000 }}
      style={{ padding: "50px 80px 60px 80px", width:"510px", height:"410px", backgroundColor:"#fdfcfc" }}
      PaperProps={{ style: { borderRadius: 30 } }}
    >
      <div className={classes.container}>
        <Box sx={{ width: "450px" }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              '&.MuiLinearProgress-colorPrimary': {
                backgroundColor: "#FFF3DD",
              },
              '& .MuiLinearProgress-bar': {
                backgroundColor: palette.primaryYellow200,
                borderRadius: "10px",
              },
              height: "11px",
              borderRadius: "10px",
              border: "5px solid #FFF3DD",
            }}
          />
        </Box>

        {subject === "createNewsletter" &&
          steps.map((image, index) => (
            <div
              key={index + 1}
              className={classes.content}
              style={{
                opacity: visibleIndex === index + 1 ? 1 : 0,
                visibility: visibleIndex === index + 1 ? "visible" : "hidden",
                transition: "opacity 600ms, visibility 1s",
                position: "absolute",
                top: "21%",
                width: index + 1 === 3 || index + 1 === 4 ? "440px" : "415px",
              }}
            >
              <div className={`display4 ${classes.loadingTitle}`}>
                <Translation id={`newsletter.loadingPopup.title.step${index + 1}`} />
              </div>
              <h4 className={classes.mainText}>
                <Translation id={`newsletter.loadingPopup.text.step${index + 1}`} />
              </h4>
              <img className={classes.loadingImg} alt={`loading${index + 1}`} src={image} />
            </div>
          ))}

        {subject === "product" && (
          <div className={classes.content} style={{ position: "absolute", top: "21%", width: "415px" }}>
            <div className={`display4 ${classes.loadingTitle}`}>
              <Translation id={"newsletter.loadingPopup.title.loadProduct"} variables={{ nbProduct: nbProduct || 1 }} />
            </div>
            <h4 className={classes.mainText}>
              <Translation id={"newsletter.loadingPopup.text.loadProduct"} variables={{ nbProduct: nbProduct || 1 }} />
            </h4>
            <img className={classes.loadingImg} alt={"loadingProduct"} src={loadingProduct} />
          </div>
        )}
      </div>
    </Dialog>
  );
};

