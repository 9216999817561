import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Radio, Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { InformationsIcon } from "../InformationsIcon.js";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { palette } from "../../../styles/palette.js";

const styles = (theme) => ({
  warningText: {
    fontSize: 12,
    color: palette.primaryBlack,
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: 12,
    },
    "& > *:not(.fal):not(button)": {
      fontSize: 14,
      fontFamily: "Poppins",
    },
  },
  label: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "100%",
  },
});

export const SwitchBase = withStyles(styles)(
  class extends Component {
    render() {
      const {
        classes,
        type,
        dataCy,
        value,
        checked,
        indeterminate,
        disabled,
        disabledExplanation,
        warningText,
        helperText,
        label,
        onChange,
        style,
      } = this.props;

      const controlComponents = {
        radio: <Radio />,
        checkbox: <Checkbox checkedIcon={indeterminate ? <IndeterminateCheckBoxIcon /> : <CheckBoxIcon />} />,
      };

      const switchBaseElement = (
        <div
          data-cy={dataCy}
        >
          <FormControlLabel
            value={value}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            control={controlComponents[type]}
            label={(
              <span>
                <span className={`${classes.labelContainer}`}>
                  <span className={classes.label}>
                    <span>
                      {label}
                    </span>
                    <span className={classes.warningText}>
                      {warningText ? <span>{warningText}</span> : null}
                    </span>
                  </span>
                  {helperText ? <InformationsIcon text={helperText} /> : null}
                </span>
              </span>
            )}
            style={style}
          />
        </div>
      );

      return disabled && disabledExplanation ? (
        <Tooltip title={disabledExplanation}>{switchBaseElement}</Tooltip>
      ) : (
        switchBaseElement
      );
    }
  }
);

SwitchBase.propTypes = {
  type: PropTypes.oneOf(["radio", "checkbox"]).isRequired,
  value: PropTypes.any,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledExplanation: PropTypes.string,
  warningText: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  indeterminate: PropTypes.bool,
  style: PropTypes.any,
};

SwitchBase.defaultProps = {
  disabledExplanation: "",
  indeterminate: false,
};
