import { Dialog } from "../../../../common/Dialog";

export const CmsDialog = ({ open, handleClose, customScrollBar, children }) => (
  <Dialog
    isOpen={open}
    close={handleClose}
    PaperProps={{ style: { borderRadius: 50 }, overflow: "hidden" }}
    BackdropProps={{ style: { backgroundColor: "transparent" } }}
    closeStyle='string'
    customScrollBar={customScrollBar}
    style={{
      paddingBottom: customScrollBar ? 40 : 0,
    }}
  >
    {children}
  </Dialog>
);
