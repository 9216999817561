import { useEffect, useCallback, useState, useMemo, useContext, useRef } from "react";
import { cloneDeep } from "lodash";
import { useIntl } from "react-intl";

import { cloneWordings, getUpdatedWordings, TRANSLATABLE_COMPONENT_TYPES } from "./TranslationManager";
import { extractContentFromPreheaderComponent } from "./grapesJsKiliba/blocks/preheader";
import { templateEditorUtils } from "./templateEditorUtils";
import { TemplateEditorContext } from "./TemplateEditorContext";

import classes from "./BlockActions.module.scss";

const CURSOR_WIDTH = 13;
const CURSOR_HEIGHT = 20;
const CURSOR_LEFT_RIGHT_PADDING = 6;
const CURSOR_TOP_BOTTOM_PADDING = 12;
const CURSOR_BORDER_SIZE = 2;

const ACTIONS_CONTAINER_WIDTH = 15;
const ACTIONS_CONTAINER_HEIGHT = 47;
const ACTIONS_CONTAINER_LEFT_RIGHT_PADDING = 10;
const ACTIONS_CONTAINER_TOP_BOTTOM_PADDING = 17;

const ALLOWED_CATEGORY_IN_COLUMN = ["text", "button", "image", "logo", "separator"];

export const BlockActions = ({ editor, setIsBlockActionsOpened, canvasContainerRef, wordings, setWordings, language, isReadOnly, preheaderHeight }) => {

  const intl = useIntl();
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [cursorTop, setCursorTop] = useState(0);
  const [actionsContainerTop, setActionsContainerTop] = useState(0);
  const [actionsContainerBottom, setActionsContainerBottom] = useState(0);
  const [sideMargin, setSideMargin] = useState({ value: 0, withTranstion: true });
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [draggingElement, setDraggingElement] = useState(null);
  const [draggableComponents, setDraggableComponents] = useState([]);
  const [scrollingDirection, setScrollingDirection] = useState(0);
  const [addActionContainerNode, setAddActionContainerNode] = useState(null);
  const timeoutRef = useRef();

  const SUBJECT_CONTAINER_HEIGHT = useMemo(() => 60 + preheaderHeight, [preheaderHeight]);

  const { forceRefreshBlockActionsPosition, isDragging, setIsDragging, draggedCategoryInfo, setDraggedCategoryInfo } = useContext(TemplateEditorContext);

  const onAdd = useCallback(event => {
    event && event.stopPropagation();
    editor.trigger("addPreview");
    templateEditorUtils.deletePreviewBlocks(editor);
    editor.UndoManager.stop();
    const index = selectedComponent.index();
    const parent = selectedComponent.parent();
    const mjml = `
      <mj-wrapper 
        background-color="#F9F9F9" 
        border-radius="10px"         
        css-class="preview-wrapper" data-preview="true" full-width="full-width">
          <mj-section padding-top="28px" padding-bottom="28px">
            <mj-column>
              <mj-raw>
                <div style="font-size:15px; text-align:center; font-family:Arial; color:#7A756E; width:50%; margin:0 auto; letter-spacing:0.6px " >
                ${intl.messages["templateEditorAddBlockPreview.text"]}
                </div>
              </mj-raw>
            </mj-column>
          </mj-section>
        </mj-wrapper>
      `;
    parent.append(mjml, { at: index + 1 });
    editor.UndoManager.start();
  }, [selectedComponent, editor, intl]);


  const calcMarginSide = useCallback((withTranstion = true) => {
    const device = editor?.Devices.getSelected();

    if (canvasContainerRef?.current && device) {
      if (device.attributes.width) {
        const rect = canvasContainerRef.current.getBoundingClientRect();
        setSideMargin({
          value: (rect.width - parseInt(device.attributes.width)) / 2,
          withTranstion,
        });
      } else {
        setSideMargin({
          value: 0,
          withTranstion,
        });
      }
    }
  }, [canvasContainerRef, editor]);

  const calcPositions = useCallback(() => {

    if (!selectedComponent) {
      return;
    }

    const element = selectedComponent.getEl();

    if (!element) {
      editor.selectRemove(editor.getSelectedAll());
      return;
    }

    const rect = element.getBoundingClientRect();
    const totalCursorHeight = CURSOR_HEIGHT + CURSOR_TOP_BOTTOM_PADDING * 2 + CURSOR_BORDER_SIZE * 2;

    setCursorTop(rect.top + (rect.height / 2) - (totalCursorHeight / 2));
    const totalActionsContainerHeight = ACTIONS_CONTAINER_HEIGHT + ACTIONS_CONTAINER_TOP_BOTTOM_PADDING * 2;
    setActionsContainerTop(rect.top + (rect.height / 2) - (totalActionsContainerHeight / 2));
    setActionsContainerBottom(rect.top + (rect.height) - (totalActionsContainerHeight / 2));
  }, [selectedComponent, editor]);

  useEffect(() => {
    if (canvasContainerRef.current) {
      const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          const width = entry.borderBoxSize?.[0].inlineSize;
          if (typeof width === "number" && width !== canvasWidth) {
            setCanvasWidth(width);
            calcMarginSide(false);
            calcPositions();
          }

          const height = entry.borderBoxSize?.[0].blockSize;
          if (typeof height === "number" && height !== canvasHeight) {
            setCanvasHeight(height);
            calcPositions();
          }
        }
      });
      observer.observe(canvasContainerRef.current, { box: "border-box" });
      return () => observer.disconnect();
    }
  }, [canvasContainerRef, calcMarginSide, canvasWidth, canvasHeight, calcPositions]);

  useEffect(() => {
    if (selectedComponent) {
      calcPositions();
      setIsBlockActionsOpened(true);
    } else {
      setIsBlockActionsOpened(false);
    }
  }, [selectedComponent, setIsBlockActionsOpened, calcPositions]);

  const onRemove = event => {
    event.stopPropagation();
    selectedComponent.remove();
    editor.selectRemove(editor.getSelectedAll());
  };

  const onClone = useCallback(event => {
    event.stopPropagation();

    const actualWordings = getUpdatedWordings(editor, cloneDeep(wordings), language);

    const index = selectedComponent.index();
    const parent = selectedComponent.parent();
    const clonedComponent = selectedComponent.clone();
    parent.append(clonedComponent, { at: index + 1 });

    const newWordings = cloneWordings(clonedComponent, actualWordings, language);

    if (Object.keys(newWordings).length) {
      setWordings(prev => ({ ...prev, ...newWordings }));
    }

  }, [setWordings, editor, selectedComponent, wordings, language]);

  const calcPositionsOnDragging = useCallback((pageY, draggingElementHeight, fromEditor = false) => {
    let mouseY = pageY - canvasContainerRef.current.getBoundingClientRect().y;

    //Prevent dragging outside the editor
    if (draggingElementHeight) {
      mouseY = Math.max(mouseY, draggingElementHeight / 2);
      mouseY = Math.min(mouseY, canvasContainerRef.current.getBoundingClientRect().height - (draggingElementHeight / 2));
    }

    const totalCursorHeight = CURSOR_HEIGHT + CURSOR_TOP_BOTTOM_PADDING * 2 + CURSOR_BORDER_SIZE * 2;
    setCursorTop(mouseY - (totalCursorHeight / 2) - (!fromEditor ? SUBJECT_CONTAINER_HEIGHT : 0));

    const totalActionsContainerHeight = ACTIONS_CONTAINER_HEIGHT + ACTIONS_CONTAINER_TOP_BOTTOM_PADDING * 2;

    setActionsContainerTop(mouseY - (totalActionsContainerHeight / 2) - (!fromEditor ? SUBJECT_CONTAINER_HEIGHT : 0));
    setActionsContainerBottom(mouseY - (totalActionsContainerHeight / 2) - (!fromEditor ? SUBJECT_CONTAINER_HEIGHT : 0));

    //set dragging element width
    if (draggingElement && selectedComponent) {
      draggingElement.style.width = `${selectedComponent.getEl().getBoundingClientRect().width}px`;
    }

  }, [canvasContainerRef, draggingElement, selectedComponent, SUBJECT_CONTAINER_HEIGHT]);

  const replaceSelectedPerBlank = useCallback((component) => {

    const componentToDrag = component || selectedComponent;

    const rect = componentToDrag.getEl().getBoundingClientRect();

    const div = document.createElement("div");
    div.style.width = `${rect.width}px`;
    div.style.height = `${rect.height}px`;
    componentToDrag.getEl().style.setProperty("outline", "none", "important");
    componentToDrag.getEl().style.setProperty("border", "none", "important");
    componentToDrag.getEl().innerHTML = "";
    componentToDrag.getEl().appendChild(div);
  }, [selectedComponent]);

  const startDrag = useCallback((event, fromEditor = false, component = null) => {
    const componentToDrag = component || selectedComponent;

    //close rte before dragging
    editor?.DomComponents.getWrapper().onAll(component => {
      if (TRANSLATABLE_COMPONENT_TYPES.includes(component.attributes.type)) {
        if (component.getView().rteEnabled) {
          component.getView().disableEditing();
        }
      }
    });

    setWordings(getUpdatedWordings(editor, wordings, language));

    event.stopPropagation();
    const rect = componentToDrag.getEl().getBoundingClientRect();

    const pageY = event.pageY + (fromEditor ? canvasContainerRef.current.getBoundingClientRect().y : 0);

    calcPositionsOnDragging(pageY, rect.height, true);
    setIsDragging(true);

    //get styles
    let css = "";
    editor.getComponents().at(0).onAll(component => {
      if (component.is("mj-style") && component.attributes.attributes["data-gjs"] !== "global") {
        css += " " + component.getInnerHTML();
      }
    });
    const style = document.createElement("style");

    editor.Css.getAll().forEach(rule => {
      css += " " + rule.toCSS().replace("@media only screen and", "@container");
    });

    css += ` 
      .draggingElement * {
        box-sizing: border-box;
      }
    `;

    style.innerHTML = css;

    //clone element for drag preview
    const clonedElement = componentToDrag.getEl().cloneNode(true);
    //TODO: mettre une classe ?
    clonedElement.classList.add("draggingElement");
    clonedElement.style.position = "absolute";
    clonedElement.style["z-index"] = 2;
    clonedElement.style.border = "2px solid #000000";
    clonedElement.style.background = "#ffffff";
    clonedElement.style.cursor = "grabbing";
    clonedElement.style["container-type"] = "inline-size";
    clonedElement.style.display = "block";
    clonedElement.style.overflow = "hidden";

    let left = 0;
    const device = editor?.Devices.getSelected();
    if (device.attributes.width) {
      left = (canvasContainerRef.current.getBoundingClientRect().width - parseInt(device.attributes.width)) / 2;
    }

    clonedElement.style.top = `${rect.top + SUBJECT_CONTAINER_HEIGHT}px`;
    clonedElement.style.left = `${left}px`;
    clonedElement.style.width = `${rect.width}px`;
    //Remove border
    clonedElement.style.height = `${rect.height - 4}px`;
    clonedElement.prepend(style);
    canvasContainerRef.current.appendChild(clonedElement);
    setDraggingElement(clonedElement);

    replaceSelectedPerBlank(componentToDrag);
  }, [calcPositionsOnDragging, canvasContainerRef, editor, replaceSelectedPerBlank, selectedComponent, language, setWordings, wordings, SUBJECT_CONTAINER_HEIGHT, setIsDragging]);

  const onComponentStartDrag = useCallback(event => {
    if (!isDragging) {
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        const element = event.target.closest("[data-gjs-type^=\"kilibaBlock-\"]");
        editor?.DomComponents.getWrapper().onAll(component => {
          if (component.getId() === element.getAttribute("id")) {
            editor.selectRemove(editor.getSelectedAll());
            editor.select(component);
            startDrag(event, true, component);
          }
        });
      }, 300);
    }
  }, [startDrag, isDragging, editor]);

  useEffect(() => {
    if (isReadOnly) {
      return;
    }

    for (const component of draggableComponents) {
      const element = component.getEl();
      if (element) {
        element.addEventListener("mousedown", onComponentStartDrag);
      }
    }

    return () => {
      for (const component of draggableComponents) {
        const element = component.getEl();
        if (element) {
          element.removeEventListener("mousedown", onComponentStartDrag);
        }
      }
    };
  }, [draggableComponents, onComponentStartDrag, isReadOnly]);

  const onComponentMount = useCallback(component => {
    if (component.attributes.type.startsWith("kilibaBlock-")) {
      setDraggableComponents(prev => [...prev, component]);
    }
  }, []);

  useEffect(() => {
    if (editor) {
      editor.on("component:mount", onComponentMount);
      return () => editor.off("component:mount", onComponentMount);
    }
  }, [editor, onComponentMount]);

  const checkDragPosition = useCallback((draggingRect, onBlockFound, onColumnFound, onSubColumnComponentFound) => {
    const frameRect = document.getElementsByClassName("gjs-frames")[0].getBoundingClientRect();
    let isColumn = false;
    let columnComponentId;

    editor.getComponents().at(0).onAll(component => {
      if (component.attributes.attributes["data-blocklabel"]?.startsWith("columns") && ALLOWED_CATEGORY_IN_COLUMN.includes(draggedCategoryInfo.category)) {
        const nbChildren = component.components()?.length || 0;
        for (let i = 0; i < nbChildren; i++) {
          const columnComponent = component.getChildAt(i);
          const componentRect = columnComponent.getEl().getBoundingClientRect();
          if (
            draggingRect.y + draggingRect.height / 2 > frameRect.y + componentRect.y &&
            draggingRect.y + draggingRect.height / 2 < frameRect.y + componentRect.y + componentRect.height &&
            draggingRect.x + draggingRect.width / 2 > frameRect.x + componentRect.x &&
            draggingRect.x + draggingRect.width / 2 < frameRect.x + componentRect.x + componentRect.width
          ) {
            isColumn = true;
            columnComponentId = columnComponent.getId();
            onColumnFound(columnComponent);
          }
        }
      }

      if (!isColumn && component.attributes.attributes["data-gjs"] === "kilibaBlock" && !component.attributes.attributes["data-blocklabel"].startsWith("column")) {
        const componentRect = component.getEl().getBoundingClientRect();
        if (
          draggingRect.y + draggingRect.height / 2 > frameRect.y + componentRect.y &&
          draggingRect.y + draggingRect.height / 2 < frameRect.y + componentRect.y + componentRect.height
        ) {
          onBlockFound(component);
        }
      }

      if (isColumn && component.attributes.type.startsWith("column-component") && component.parent().getId() === columnComponentId) {
        const componentRect = component.getEl().getBoundingClientRect();
        if (
          draggingRect.y + draggingRect.height / 2 > frameRect.y + componentRect.y &&
          draggingRect.y + draggingRect.height / 2 < frameRect.y + componentRect.y + componentRect.height
        ) {
          onSubColumnComponentFound(component);
        }
      }
    });
  }, [editor, draggedCategoryInfo]);

  const onMouseUp = useCallback(event => {
    clearTimeout(timeoutRef.current);
    if (isDragging) {
      event.stopPropagation();
      setIsDragging(false);
      setDraggingElement(null);
      selectedComponent.getView().render();
      draggingElement?.remove();
      calcPositions();
      editor.trigger("dragEnd");
    }

    if (draggedCategoryInfo) {
      const draggingRect = draggedCategoryInfo.elem.getBoundingClientRect();
      draggedCategoryInfo.elem.remove();
      setDraggedCategoryInfo(null);
      Array.from(editor.getWrapper().getEl().getElementsByClassName("dataPreviewColumnHover")).forEach(e => e.classList.remove("dataPreviewColumnHover"));

      let isColumn = false;
      let columnComponent;

      checkDragPosition(
        draggingRect,
        () => { },
        (component) => {
          isColumn = true;
          columnComponent = component;          
        },
        () => {}
      );

      if (!isColumn) {
        editor.trigger("dragCategoryEnd", { category: draggedCategoryInfo.category });
      } else {
        editor.trigger("dragCategoryEndColumn", { category: draggedCategoryInfo.category, component: columnComponent });
      }
    }

  }, [calcPositions, isDragging, draggingElement, selectedComponent, editor, setIsDragging, draggedCategoryInfo, setDraggedCategoryInfo, checkDragPosition]);

  const onMouseMove = useCallback(event => {
    if (isDragging) {
      const pageY = event.pageY + (event.target.tagName === "IFRAME" ? canvasContainerRef.current.getBoundingClientRect().y : 0);
      const mouseY = pageY - canvasContainerRef.current.getBoundingClientRect().y;
      const draggingRect = draggingElement.getBoundingClientRect();
      let draggingElementTop = mouseY - draggingRect.height / 2;

      const direction = draggingElementTop < selectedComponent.getEl().getBoundingClientRect().top ? -1 : 1;

      //Max top
      if (direction === -1 && draggingElementTop < 0) {
        draggingElementTop = 0;
      }

      //Max bottom
      if (direction === 1 && draggingElementTop + draggingRect.height > canvasContainerRef.current.getBoundingClientRect().height) {
        draggingElementTop = canvasContainerRef.current.getBoundingClientRect().height - draggingRect.height;
      }

      calcPositionsOnDragging(pageY, draggingRect.height, event.target.tagName === "IFRAME");
      draggingElement.style.top = `${draggingElementTop + (event.target.tagName === "IFRAME" ? SUBJECT_CONTAINER_HEIGHT : 0)}px`;

      const parent = selectedComponent.parent();
      let idx = 0;
      let child = parent.getChildAt(idx);
      while (child) {
        if (child.getId() !== selectedComponent.getId() && child.getEl()?.getBoundingClientRect) {
          const childRect = child.getEl().getBoundingClientRect();
          const draggingElementBottom = draggingElementTop + draggingRect.height;

          if (
            (direction === -1 && draggingElementTop < childRect.top + childRect.height / 2 && draggingElementTop > childRect.top) ||
            (direction === 1 && draggingElementBottom > childRect.top + childRect.height / 2 && draggingElementBottom < childRect.bottom)
          ) {
            let index = child.index();
            if (index - 1 === selectedComponent.index()) {
              index++;
            }
            selectedComponent.move(parent, { at: index });
            replaceSelectedPerBlank();
            break;
          }
        }

        idx++;
        child = parent.getChildAt(idx);
      }

      //Auto scroll
      const scrollingContainer = canvasContainerRef.current.parentNode;
      const scrollingContainerRect = scrollingContainer.getBoundingClientRect();
      if (pageY > scrollingContainerRect.bottom - 50) {
        scrollingContainer.scrollTop = scrollingContainer.scrollTop + 10;
        setScrollingDirection(1);
      } else if (pageY < scrollingContainerRect.top + 50) {
        scrollingContainer.scrollTop = scrollingContainer.scrollTop - 10;
        setScrollingDirection(-1);
      } else {
        setScrollingDirection(0);
      }
    }

    if (draggedCategoryInfo) {
      const canvasContainerRect = canvasContainerRef.current.getBoundingClientRect();
      const draggingRect = draggedCategoryInfo.elem.getBoundingClientRect();
      //check if element is on iframe (based on dragging elem center)
      if (
        draggingRect.x + draggingRect.width / 2 > canvasContainerRect.x &&
        draggingRect.x + draggingRect.width / 2 < canvasContainerRect.x + canvasContainerRect.width &&
        draggingRect.y + draggingRect.height / 2 > canvasContainerRect.y &&
        draggingRect.y + draggingRect.height / 2 < canvasContainerRect.y + canvasContainerRect.height
      ) {

        let isColumn = false;

        checkDragPosition(draggingRect,
          (component) => {
            const index = component.index();
            const parent = component.parent();
            const componentRect = component.getEl().getBoundingClientRect();
            const frameRect = document.getElementsByClassName("gjs-frames")[0].getBoundingClientRect();

            const mjml = `
            <mj-wrapper 
              background-color="#F9F9F9" 
              border-radius="10px"         
              css-class="preview-wrapper" data-preview="true" full-width="full-width">
                <mj-section padding-top="28px" padding-bottom="28px">
                  <mj-column>
                    <mj-raw>
                      <div style="font-size:15px; text-align:center; font-family:Arial; color:#7A756E; width:50%; margin:0 auto; letter-spacing:0.6px " >
                      ${intl.messages[`blockManager.category.${draggedCategoryInfo.category}`]}
                      </div>
                    </mj-raw>
                  </mj-column>
                </mj-section>
              </mj-wrapper>
            `;

            const isTop = draggingRect.y + draggingRect.height / 2 < frameRect.y + componentRect.y + componentRect.height / 2;

            const finalIdx = index + (isTop ? 0 : 1);

            const componentAtIndex = parent.getChildAt(finalIdx);
            if (!componentAtIndex?.attributes?.attributes["data-preview"]) {
              editor.UndoManager.stop();

              const appendedBlocks = parent.append(mjml, { at: finalIdx });
              templateEditorUtils.deletePreviewBlocks(editor, appendedBlocks);

              editor.UndoManager.start();
            }
          },
          (component) => {
            isColumn = true;
            Array.from(editor.getWrapper().getEl().getElementsByClassName("dataPreviewColumnHover")).forEach(e => e.classList.remove("dataPreviewColumnHover"));
            component.getEl().classList.add("dataPreviewColumnHover");
          },
          (component) => {
            const index = component.index();
            const parent = component.parent();
            const componentRect = component.getEl().getBoundingClientRect();
            const frameRect = document.getElementsByClassName("gjs-frames")[0].getBoundingClientRect();

            const mjml = `
              <mj-raw data-column-preview="true">
                <div style="height:1.5px;background:#000;margin:0 10px"></div>
              </mj-raw>
            `;

            const isTop = draggingRect.y + draggingRect.height / 2 < frameRect.y + componentRect.y + componentRect.height / 2;

            const finalIdx = index + (isTop ? 0 : 1);

            const componentAtIndex = parent.getChildAt(finalIdx);
            if (!componentAtIndex?.attributes?.attributes["data-column-preview"]) {
              editor.UndoManager.stop();

              const appendedBlocks = parent.append(mjml, { at: finalIdx });
              templateEditorUtils.deleteColumnPreviewBlocks(editor, appendedBlocks);

              editor.UndoManager.start();
            }
          }
        );

        if (isColumn) {
          templateEditorUtils.deletePreviewBlocks(editor);
        } else {
          Array.from(editor.getWrapper().getEl().getElementsByClassName("dataPreviewColumnHover")).forEach(e => e.classList.remove("dataPreviewColumnHover"));
          templateEditorUtils.deleteColumnPreviewBlocks(editor);
        }

      } else {
        templateEditorUtils.deletePreviewBlocks(editor);
        Array.from(editor.getWrapper().getEl().getElementsByClassName("dataPreviewColumnHover")).forEach(e => e.classList.remove("dataPreviewColumnHover"));
        templateEditorUtils.deleteColumnPreviewBlocks(editor);
      }
    }

  }, [isDragging, calcPositionsOnDragging, draggingElement, canvasContainerRef, selectedComponent, replaceSelectedPerBlank, SUBJECT_CONTAINER_HEIGHT, draggedCategoryInfo, editor, intl, checkDragPosition/* , getColumnComponentIndex */]);

  const onScroll = useCallback(() => {
    if (isDragging && scrollingDirection) {
      setTimeout(() => {
        const scrollingContainer = canvasContainerRef.current.parentNode;
        scrollingContainer.scrollTop = scrollingContainer.scrollTop + (10 * scrollingDirection);
      }, 200);
    }
  }, [isDragging, scrollingDirection, canvasContainerRef]);

  useEffect(() => {
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [onMouseMove, onMouseUp]);

  useEffect(() => {

    if (canvasContainerRef?.current) {
      const elem = canvasContainerRef.current.parentNode;
      elem.addEventListener("scroll", onScroll);
      return () => elem.removeEventListener("scroll", onScroll);
    }

  }, [canvasContainerRef, onScroll]);

  const onComponentSelected = useCallback(model => {
    //prevent multiple selection
    if (editor.getSelectedAll().length > 1) {
      editor.selectRemove(editor.getSelectedAll());
      editor.select(model);
    }
    if (model.attributes.type.startsWith("kilibaBlock-")) {
      setSelectedComponent(model);
    }
  }, [editor]);

  const onComponentDeselected = useCallback(component => {
    setSelectedComponent(null);
  }, []);

  useEffect(() => {
    if (editor) {
      editor.on("component:selected", onComponentSelected);
      editor.on("component:deselected", onComponentDeselected);
      return () => {
        editor.off("component:selected", onComponentSelected);
        editor.off("component:deselected", onComponentDeselected);
      };
    }
  }, [editor, onComponentSelected, onComponentDeselected]);

  useEffect(() => {
    calcPositions();
  }, [forceRefreshBlockActionsPosition, calcPositions]);

  /* ======== Move the AddActionContainer to fix z-index issue ======== */

  const onAddActionContainerInit = useCallback((node) => {
    if (node && !addActionContainerNode) {
      const cloned = node.cloneNode(true);
      cloned.style.visibility = "var(--editor-block-actions-visibility)";
      const frame = document.getElementsByClassName("gjs-frames")[0];
      frame.prepend(cloned);
      setAddActionContainerNode(cloned);
    }
  }, [addActionContainerNode]);

  useEffect(() => {
    if (addActionContainerNode) {
      addActionContainerNode.addEventListener("click", onAdd);
      return () => {
        addActionContainerNode.removeEventListener("click", onAdd);
      };
    }
  }, [addActionContainerNode, onAdd]);

  useEffect(() => {
    if (addActionContainerNode) {
      addActionContainerNode.style.top = `${actionsContainerBottom + 27}px`;
      addActionContainerNode.style.display = selectedComponent ? null : "none";
    }
  }, [actionsContainerBottom, addActionContainerNode, selectedComponent]);

  /* ==================== */


  const showBlockAction = useMemo(() => {

    if (!selectedComponent) {
      return false;
    }

    if (selectedComponent.attributes.attributes["data-blocklabel"] === "preheader") {
      const content = extractContentFromPreheaderComponent(selectedComponent, wordings, language);
      return content.isVisible;
    }

    return true;
  }, [selectedComponent, wordings, language]);

  if (!showBlockAction) {
    return null;
  }

  return (
    <>
      <div
        className={`${classes.cursor} ${sideMargin.withTranstion ? classes.cursorTransition : ""} ${isDragging ? classes.dragging : ""}`}
        style={{
          "--cursor-width": `${CURSOR_WIDTH}px`,
          "--cursor-height": `${CURSOR_HEIGHT}px`,
          "--cursor-left-right-padding": `${CURSOR_LEFT_RIGHT_PADDING}px`,
          "--cursor-top-bottom-padding": `${CURSOR_TOP_BOTTOM_PADDING}px`,
          "--cursor-border-size": `${CURSOR_BORDER_SIZE}px`,
          top: `${cursorTop + SUBJECT_CONTAINER_HEIGHT}px`,
          left: `${-(CURSOR_WIDTH + CURSOR_LEFT_RIGHT_PADDING * 2 + CURSOR_BORDER_SIZE) + sideMargin.value}px`
        }}
        onMouseDown={startDrag}
        onClick={event => event.stopPropagation()}
      >
        <div className={classes.dotsLine}>
          <div className={classes.dot} />
          <div className={classes.dot} />
        </div>
        <div className={classes.dotsLine}>
          <div className={classes.dot} />
          <div className={classes.dot} />
        </div>
        <div className={classes.dotsLine}>
          <div className={classes.dot} />
          <div className={classes.dot} />
        </div>
      </div>
      <div
        className={`${classes.actionsContainer} ${sideMargin.withTranstion ? classes.actionsContainerTransition : ""}`}
        style={{
          "--actions-container-width": `${ACTIONS_CONTAINER_WIDTH}px`,
          "--actions-container-height": `${ACTIONS_CONTAINER_HEIGHT}px`,
          "--actions-container-left-right-padding": `${ACTIONS_CONTAINER_LEFT_RIGHT_PADDING}px`,
          "--actions-container-top-bottom-padding": `${ACTIONS_CONTAINER_TOP_BOTTOM_PADDING}px`,
          top: `${actionsContainerTop + SUBJECT_CONTAINER_HEIGHT}px`,
          right: `${-(ACTIONS_CONTAINER_WIDTH + ACTIONS_CONTAINER_LEFT_RIGHT_PADDING * 2 + 4) + sideMargin.value}px`,
        }}
      >
        <div onClick={onClone} className={classes.action}><i className="fa-solid fa-clone" /></div>
        <div onClick={onRemove} className={classes.action}><i className="fa-solid fa-trash-can" /></div>
      </div>
      <div
        ref={onAddActionContainerInit}
        className={`${classes.addActionContainer} ${sideMargin.withTranstion ? classes.actionsContainerTransition : ""}`}
        style={{ top: `${actionsContainerBottom + 27}px`, left: "calc(50% - 15px)" }}
      >
        <div onClick={onAdd} className={`${classes.addAction}`}>
          <i className="fa-solid fa-circle-plus"></i>
        </div>
      </div>
      {/* Div invisible to prevent the mouse from entering the editor iframe when dragging (this makes the event position buggy) */}
      {isDragging && <div className={classes.invisible} />}
    </>
  );
};