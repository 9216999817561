import { useIntl } from "react-intl";
import { Translation } from "../../../../common/Translation";
import { CustomButton } from "../../../../common/Button/CustomButton";
import { useAuth } from "../../../../../hooks/useAuth";

import classes from "./ShopifyDialog.module.scss";


export const ShopifyForm = ({ onSubmit = () => { }, loading = false }) => {
  
  const intl = useIntl();
  const auth = useAuth();

  return (
    <div className={classes.formContainer}>        <div className={classes.content}>
          <p className="text"><Translation id={"onBoarding.chooseCms.shopify.content"} /></p>
        </div>

        <div className={classes.buttonContainer}>
          <CustomButton
            onClick={() => {
              auth.sendEventToAmplitude("shopify_connection.click_install_kiliba_on_my_shop");
              onSubmit("shopify");
              window.open("https://apps.shopify.com/kiliba?locale=fr", "_blank");
            }}
            loading={loading}
          >
            {intl.messages["onBoarding.chooseCms.shopify.button"]}
          </CustomButton>
        </div>
    </div>
  );
};
