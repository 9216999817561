import { useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";

import classes from "./MultipleChoicePicker.module.scss";
import { ClickAwayListener, Paper, Popper } from "@material-ui/core";

export const MultipleChoicePicker = ({ items, selectedItems, setSelectedItems, label }) => {

  const buttonRef = useRef();
  const [isOpened, setIsOpened] = useState(false);

  const onChange = (item, value) => {
    if (value) {
      setSelectedItems(prev => [...prev, item.id]);
    } else {
      setSelectedItems(prev => prev.filter(e => e !== item.id));
    }
  };

  return (
    <div className={classes.container}>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => setIsOpened(true)}
        ref={buttonRef}
      >
        <div className={classes.displayedText}>
          {label}
        </div>
        <i className='fal fa-angle-down'></i>
      </Button>
      <Popper
        anchorEl={buttonRef?.current}
        open={isOpened}
        modifiers={{ flip: { enabled: false } }}
        placement={"bottom-start"}
      >
        <ClickAwayListener onClickAway={() => setIsOpened(false)}>
          <Paper className={classes.paper}>
            {items.map(item => (
              <div key={item.id} className={classes.item} onClick={() => onChange(item, !selectedItems.includes(item.id))}>
                <Checkbox
                  checked={selectedItems.includes(item.id)}
                  onChange={e => onChange(item, e.target.checked)}
                />
                <div>{item.label}</div>
              </div>
            ))}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};
