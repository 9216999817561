import { useCallback, useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { ProductFocusChooseProduct } from "./ProductFocusChooseProduct";
import { Translation } from "../../../common/Translation";
import { ProductFocusPreview } from "./ProductFocusPreview";
import { CustomButton } from "../../../common/Button/CustomButton";
import { useAuth } from "../../../../hooks/useAuth";

import arrowImage from "../../../../public/productFocusSettingsArrow.png";

import classes from "./ProductFocusGenerator.module.scss";

const cleanPreviews = (editor) => {
  editor?.getComponents()?.at(0)?.onAll(component => {
    if (component.is("mj-image")) {
      const elems = component.getEl().getElementsByTagName("img");
      if (elems.length && !elems[0].src.startsWith("http")) {
        component.getView().render();
      }
    }
  });
};

export const useCleanProductFocusPreviewFromRightMenu = (editMenuInfo, navigationItemSelected, isOpened, editor) => {
  useEffect(() => {
    if (editMenuInfo?.type === "image" && navigationItemSelected === "content" && isOpened) {
      return;
    }

    cleanPreviews(editor);
  }, [editMenuInfo, navigationItemSelected, editor, isOpened]);
};

export const useCleanProductFocusPreviewGeneratedImageLibrary = (type, editor) => {
  useEffect(() => {
    if (type === "productShowcaseImages") {
      return;
    }

    cleanPreviews(editor);
  }, [type, editor]);
};

export const useCleanProductFocusPreviewImageGaleryMenu = (subMenu, editor) => {
  useEffect(() => {
    if (subMenu === "generatedImagesLibrary") {
      return;
    }

    cleanPreviews(editor);
  }, [subMenu, editor]);
};

const ColorPicker = ({ value, onChange }) => {
  const [isColorPickerOpened, setIsColorPickerOpened] = useState(false);

  return (
    <div className={classes.colorPickerContainer} onClick={() => setIsColorPickerOpened(true)}>
      <div className={classes.colorPickerColor} style={{ background: value }} />
      <div className="info">{value?.toUpperCase()}</div>
      {isColorPickerOpened &&
        <div className={classes.colorPicker}>
          <ClickAwayListener onClickAway={() => setIsColorPickerOpened(false)}>
            <SketchPicker
              color={value}
              onChange={({ hex }) => onChange(hex)}
            />
          </ClickAwayListener>
        </div>
      }
    </div>
  );
};

export const ProductFocusGenerator = ({ onImageClick, savedImages, component, scrollableContainerClass, outpaintedImages, templateId, selectedUrl, saveImage, deleteImage, setNotif }) => {

  const auth = useAuth();

  const [color, setColor] = useState("#FFFFFF");
  const [secondaryColor, setSecondayColor] = useState("#424242");
  const [selectedSetting, setSelectedSetting] = useState("uniform");
  const [selectedPosition, setSelectedPosition] = useState("center");
  const [transparentImage, setTransparentImage] = useState();
  const [productId, setProductId] = useState();
  const [uploadKey, setUploadKey] = useState();
  const [customPos, setCustomPos] = useState({ x: 50, y: 50 });
  const [enableTemplateRender, setEnableTemplateRender] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [defaultUrl, setDefaultUrl] = useState(null);
  const [lastDataUrl, setLastDataUrl] = useState(null);
  const [applyLoading, setApplyLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [format, setFormat] = useState("landscape");
  const [productImageWidth, setProductImageWidth] = useState(null);

  useEffect(() => {
    if (isLoaded) {
      return ;
    }

    const last = outpaintedImages.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).findLast(elem => elem.type === "uniform_background");
    if (last) {
      auth.fetch("/account_management/getTransparentImage", {
        method: "POST",
        body: JSON.stringify({
          productId: last.productId,
          uploadKey: last.uploadKey,
          isUploadFile: !!last.uploadKey,
        }),
      }).then(result => {
        setTransparentImage(result);
        setProductId(last.productId);
        setUploadKey(last.uploadKey);
        last.manualOutpainting.backgroundType && setSelectedSetting(last.manualOutpainting.backgroundType);
        last.manualOutpainting.backgroundPrimaryColor && setColor(last.manualOutpainting.backgroundPrimaryColor);
        last.manualOutpainting.backgroundSecondaryColor && setSecondayColor(last.manualOutpainting.backgroundSecondaryColor);
        last.manualOutpainting.format && setFormat(last.manualOutpainting.format);
        setSelectedPosition(last.manualOutpainting.positionPreset);
        setCustomPos({ x: last.manualOutpainting.x, y: last.manualOutpainting.y });
        setProductImageWidth(last.manualOutpainting.width);
        setDefaultUrl(last.url);
        setImageUrl(last.url);

        if (last.url === selectedUrl) {
          setEnableTemplateRender(true);
        }
        setIsLoaded(true);
      });
    }
  }, [auth, outpaintedImages, selectedUrl, isLoaded]);

  const getBackgroundStyle = useCallback((type) => {
    switch (type) {
      case "uniform": return color;
      case "linear": return `linear-gradient(92deg, ${color} 23.42%, ${secondaryColor} 100%)`;
      case "radial": return `radial-gradient(92.28% 68.05% at 53.85% 50%, ${color} 3.53%, ${secondaryColor} 91.2%)`;
    }
  }, [color, secondaryColor]);

  const scrollToBottom = () => {
    const container = document.getElementsByClassName(scrollableContainerClass)[0];
    const seperatorElement = document.getElementsByClassName(classes.separator)[0];
    container.scrollTo({
      top: seperatorElement.getBoundingClientRect().top + container.scrollTop - 50,
      behavior: "smooth"
    });
  };

  const scrollToPreview = useCallback(() => {
    const container = document.getElementsByClassName(scrollableContainerClass)[0];
    const containerElement = document.getElementsByClassName(classes.container)[0];
    container.scrollTo({
      top: containerElement.getBoundingClientRect().top + container.scrollTop - 150,
      behavior: "smooth"
    });
  }, [scrollableContainerClass]);

  const saveManualOutpainting = (src, x, y, isApply = false) => {
    setIsUpdated(true);
    setLastDataUrl(src);
    return auth.fetch(`/account_management/saveManualOutpainting?templateId=${templateId}`, {
      method: "POST",
      body: JSON.stringify({
        src,
        x,
        y,
        backgroundType: selectedSetting,
        backgroundPrimaryColor: color,
        backgroundSecondaryColor: secondaryColor,
        productId,
        uploadKey,
        positionPreset: selectedPosition,
        format,
        width: productImageWidth,
      }),
    }).then(result => {
      if (enableTemplateRender && isApply) {
        onImageClick(result.url);
        setIsUpdated(false);
        setApplyLoading(false);
      }
      return result.url;
    });
  };

  const apply = () => {
    setApplyLoading(true);
    saveManualOutpainting(lastDataUrl, customPos.x, customPos.y, true);
  };

  return (
    <div className={classes.container}>

      {transparentImage &&
        <>
          <ProductFocusPreview
            background={getBackgroundStyle(selectedSetting)}
            position={selectedPosition}
            productImage={transparentImage}
            setSelectedPosition={setSelectedPosition}
            onImageClick={onImageClick}
            savedImages={savedImages}
            component={component}
            saveManualOutpainting={saveManualOutpainting}
            customPos={customPos}
            setCustomPos={setCustomPos}
            enableTemplateRender={enableTemplateRender}
            setEnableTemplateRender={setEnableTemplateRender}
            isUpdated={isUpdated}
            defaultUrl={defaultUrl}
            saveImage={saveImage}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            deleteImage={deleteImage}
            format={format}
            scrollToPreview={scrollToPreview}
            productImageWidth={productImageWidth}
            setProductImageWidth={setProductImageWidth}
            color={color}
            secondaryColor={secondaryColor}
            selectedSetting={selectedSetting}
            setColor={setColor}
            setSecondayColor={setSecondayColor}
            setSelectedSetting={setSelectedSetting}
          />

          <div className={classes.changeProductButtonContainer}>
            {enableTemplateRender &&
              <CustomButton
                type="primary"
                size="xs"
                loading={applyLoading}
                disabled={!isUpdated}
                onClick={apply}
              >
                <Translation id="productFocusGenerator.button.apply" />
              </CustomButton>
            }

            <div className={classes.changeProductButton}>
              <CustomButton
                type="tertiary"
                size="xs"
                onClick={scrollToBottom}
              >
                <Translation id="productFocusGenerator.button.changeProduct" />
              </CustomButton>
            </div>
          </div>

          <div className={classes.content}>
            <div className="text"><Translation id="productFocusSettings.background" /></div>
            <div className={classes.backgroundSettingsContainer}>
              <div className={classes.backgroundSettings}>
                {[
                  { value: "uniform", style: { background: getBackgroundStyle("uniform") } },
                  { value: "linear", style: { background: getBackgroundStyle("linear") } },
                  { value: "radial", style: { background: getBackgroundStyle("radial") } },
                ].map(elem => (
                  <div key={elem.value} className={`${classes.backgroundSetting} ${elem.value === selectedSetting ? classes.selectedItem : ""}`} onClick={() => setSelectedSetting(elem.value)}>
                    <div className={classes.backgroundSettingColor} style={elem.style} />
                    <div className="info"><Translation id={`productFocusSettings.setting.${elem.value}`} /></div>
                  </div>
                ))}
              </div>
              <div className="info"><Translation id="productFocusSettings.color" /></div>
              <div className={classes.colorPickersContainer}>
                <ColorPicker value={color} onChange={setColor} />
                {selectedSetting !== "uniform" &&
                  <>
                    <img width={20} src={arrowImage} alt="" />
                    <ColorPicker value={secondaryColor} onChange={setSecondayColor} />
                  </>
                }
              </div>
            </div>
            <div className="text"><Translation id="productFocusSettings.positioning" /></div>
            <div className={classes.positionsContainer}>
              {[
                { value: "left", style: { top: 15, left: 10, background: "#BEBAB3" } },
                { value: "center", style: { top: 15, left: 27.5, background: "#BEBAB3" } },
                { value: "right", style: { top: 15, right: 10, background: "#BEBAB3" } },
                { value: "custom", style: { top: 10, left: 17, outline: "1px dashed #BEBAB3" } },
              ].map(elem => (
                <div key={elem.value} className={`${classes.position} ${elem.value === selectedPosition ? classes.selectedItem : ""}`} onClick={() => setSelectedPosition(elem.value)}>
                  <div className={classes.positionPreview}>
                    <i className={classes.positionPreviewSquare} style={elem.style} />
                  </div>
                  <div className="info"><Translation id={`productFocusSettings.position.${elem.value}`} /></div>
                </div>
              ))}
            </div>
          </div>

          <div className={classes.separator} />

        </>
      }

      <ProductFocusChooseProduct
        setTransparentImage={setTransparentImage}
        setProductId={setProductId}
        productId={productId}
        setUploadKey={setUploadKey}
        setFormat={setFormat}
        scrollToPreview={scrollToPreview}
        onError={() => setNotif({ value: "productFocusChooseProduct.error", type: "error"})}
        transparentImage={transparentImage}
        setSelectedPosition={setSelectedPosition}
        format={format}
        setProductImageWidth={setProductImageWidth}
      />
    </div>
  );
};