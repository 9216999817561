
import { extractVariable, extractWordingsFromMjTextComponent, makeTranslatable } from "../utils";
import { image1 } from "./image1";
import { image2 } from "./image2";
import { image3 } from "./image3";

export const getImageBlocks = ({ imageUrl, shopUrl, textColor, fontFamily, generatedWordings, headerColor }) => {

  return [
    image1({ imageUrl: makeTranslatable(imageUrl), href: makeTranslatable(shopUrl), alt: makeTranslatable(""), backgroundColor: headerColor }),
    image2({ imageUrl: makeTranslatable(imageUrl), href: makeTranslatable(shopUrl), alt: makeTranslatable(""), textColor, fontFamily, title: generatedWordings.title, backgroundColor: headerColor }),
    image3({ imageUrl: makeTranslatable(imageUrl), fontFamily, title: generatedWordings.title, subtitle: generatedWordings.subtitle }), 
  ];
};

export const extractContentFromImageComponent = (component, wordings, language, variables) => {
  const content = {};

  const imageComponents = component.findType("mj-image");
  if (imageComponents.length) {    
    const props = imageComponents[0].get("script-props");
    const translatableAttributes = props[0].translatableAttributes;
    
    content.width = parseInt(imageComponents[0].attributes.attributes.width);
    content.align = imageComponents[0].attributes.attributes.align;

    const srcAttribute = translatableAttributes?.find(attr => attr.key === "src");
    const hrefAttribute = translatableAttributes?.find(attr => attr.key === "href");
    const altAttribute = translatableAttributes?.find(attr => attr.key === "alt");

    content.imageUrl = extractVariable(srcAttribute, imageComponents[0].attributes.attributes.src, variables, language);
    content.href = extractVariable(hrefAttribute, imageComponents[0].attributes.attributes.href, variables, language);
    content.alt = extractVariable(altAttribute, imageComponents[0].attributes.attributes.alt || "", variables, language);

  } else if (component.is("kilibaBlock-mj-hero")) {
    const props = component.get("script-props");
    const translatableAttributes = props[0].translatableAttributes;

    const backgroundAttribute = translatableAttributes?.find(attr => attr.key === "background-url");

    content.imageUrl = extractVariable(backgroundAttribute, component.attributes.attributes["background-url"], variables, language);
  }

  const textComponents = component.findType("mj-text");
  if (textComponents.length) {
    content.title = extractWordingsFromMjTextComponent(textComponents[0], wordings, language);
  }

  if (textComponents.length > 1) {
    content.subtitle = extractWordingsFromMjTextComponent(textComponents[1], wordings, language);
  }

  content.backgroundColor = component.attributes.attributes["background-color"] || "#ffffff";

  return content;
};
