import React, { Component } from "react";
import { withAuth } from "../../../../withAuth";
import classes from "./UsecasePopupEmailEditorPreview.module.scss";

import mobilePreview from "../../../../../public/mobile.png";
import { CircularProgress } from "@material-ui/core";

export const UsecasePopupEmailEditorPreview = withAuth(
  class UsecasePopupEmailEditorPreview extends Component {
    constructor(props) {
      super(props);

      this.state = {
        companyName: undefined,
        subject: "",
        htmlString: "",
        emailPreview: null,
        isLoading: true
      };
    }

    async componentDidMount() {
      await this.props.auth.fetch(
        `/api/account_management/getEmail?templateName=${this.props.templateName}`,
        {
          method: "POST",
          body: JSON.stringify({
            structureSettings: {
              mustRemoveUnusedBlocks: true,
              mustAddFakeData: true,
              mustAddFakeCodeSuffix:
                  this.props.emailExample.promoCodeTemplate &&
                  this.props.emailExample.promoCodeTemplate.isGeneric === false,
            },
            variationSettings: {
              wordings: {
                languages: ["fr", "en", "es", "de", "nl", "it", "pt"],
                numbers: ["plural"],
                tones: [this.props.tone],
              },
              htmls: {
                languages: ["fr", "en", "es", "de", "nl", "it", "pt"],
                numbers: ["plural"],
                tones: [this.props.tone],
              },
            },
            customisationSettings: {
              wordings: this.props.wordings,
              headbandImgUrls: this.props.headbandImgUrls,
              generatedWordings: this.props.generatedWordings,
              headbandLink: this.props.headbandLink,
            },
          }),
        }
      ).then(response => {
        this.setState({ emailPreview: response.email, isLoading: false });
      });
    }

    renderSwitchView() {
      const { selectedDeviceType } = this.props;

      if (!this.state.emailPreview) return null;

      const html =
          this.state.emailPreview.htmls[this.props.language][this.props.number][
            this.props.tone
          ];
      const subject =
          this.state.emailPreview.wordings[this.props.language][
            this.props.number
          ][this.props.tone].subject;

      switch (selectedDeviceType) {
        case "desktop":
          return (
            <div className={classes.containerDesktop}>
              <div className={classes.bar}>
                <div className={classes.addressBtnContainer}>
                  <div className={classes.addressBtn}></div>
                  <div className={classes.addressBtn}></div>
                  <div className={classes.addressBtn}></div>
                </div>
                <div className={classes.addressBar}></div>
              </div>
              <div className={classes.headerEmail}>
                <div className={classes.textHeader}>Objet :</div>
                <div className={classes.subjectContent}>{subject}</div>
              </div>
              <iframe
                className={classes.iframeDesktop}
                srcDoc={html}
                title="previewTemplateEmail"
              ></iframe>
            </div>
          );
        case "mobile":
          return (
            <div className={classes.containerMobile}>
              <iframe
                className={classes.iframeMobile}
                srcDoc={html}
                title="previewTemplateEmail"
              ></iframe>
              <img
                className={classes.mobile}
                src={mobilePreview}
                alt="mobile"
              />
            </div>
          );
        default:
          return null;
      }
    }

    render() {
      const { isLoading } = this.state;

      return (
        <div className={classes.previewContainer}>
          { isLoading ?
            <div className={ classes.previewLoading}>
              <CircularProgress color="primary" size="30px" /> 
            </div>
            : this.renderSwitchView()
          }
        </div>
      );
    }
  }
);
