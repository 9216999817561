const LABEL = "columnButton";
const CATEGORY = "columnComponent:button";

export const columnButton = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {
    const { fontFamily, buttonTextColor, buttonColor, href, buttonText } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-button data-column-component="true" data-blocklabel="${LABEL}" border-radius="7px" href="{{href}}" font-family="${fontFamily}" padding-left="10px" padding-right="10px" color="${buttonTextColor}" font-size="14px" padding-top="10px" padding-bottom="10px" inner-padding="8px 15px" background-color="${buttonColor}">
        {{button}}
      </mj-button>     
    `;

    const wordings = {
      button: buttonText || {
        fr: "Voir la boutique",
        en: "See the store",
        es: "Ver la tienda",
        de: "Zum Shop",
        nl: "Bekijk de winkel",
        it: "Vedere il negozio",
        pt: "Ver a loja",
      },
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        variables: {
          href
        },
        wordings,
        editableProperties: [
          { key: "href", type: "link", label: "newsletter.rightMenu.property.buttonLink", group: "newsletter.rightMenu.propertyGroup.button" },
          { key: "buttonColor", type: "color", label: "newsletter.rightMenu.property.buttonColor", group: "newsletter.rightMenu.propertyGroup.button" },
        ]
      }
    };
  },
});
