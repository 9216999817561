export function transformB64ToFile(base64, extension) {
  const binaryData = window.atob(base64);
  const uint8Array = new Uint8Array(binaryData.length);

  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  return new File([uint8Array], `logo.${extension}`, { type: `image/${extension}` });  
}

/**
 * Read File object and extract data (name and base64 content)
 * @param {File} file 
 * @returns {Promise<{name: string, size: number, type: string, content: string}>}
 */
export async function parseFileInput(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({
      name: file.name,
      size: file.size,
      raw: reader.result,
      type: reader.result.substring(5, reader.result.indexOf(";")), // Extract mime from result (data:image/png;base64,... -> image/png)
      content: reader.result.substring(reader.result.indexOf(",") + 1) // Take everything from result after metadata (data:image/png;base64,... -> ...)
    });
    reader.onerror = reject;
  });
}
