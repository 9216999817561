import { useCallback, useEffect, useState, useContext } from "react";
import mjml2html from "mjml-browser";

import { useIntl } from "react-intl";
import { useAuth } from "../../hooks/useAuth";
import { AccountContext, reloadAccountContext } from "../../contexts/context";
import { KiTextField } from "../common/Input/KiTextField";
import { NewsletterEditPopup } from "./NewsletterEditPopup.jsx";
import { Translation } from "../common/Translation";
import classes from "./NewsletterCard.module.scss";


export const NewsletterCard = ({ statistics, dateLastSync }) => {

  const auth = useAuth();
  const intl = useIntl();
  const [search, setSearch] = useState("");
  const { account } = useContext(AccountContext);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [sortedNewsletter, setSortedNewsletter] = useState([]);
  const [filteredNewsletter, setFilteredNewsletter] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isloaded, setIsLoaded] = useState(false);  
  const [htmlToRender, setHtmlToRender] = useState([]);
  const [htmlRendering, setHtmlRendering] = useState([]);

  const getFilterNumber = (filter) => {
    const totalNewsletters = sortedNewsletter.sent?.length + sortedNewsletter.planned?.length + sortedNewsletter.draft?.length + sortedNewsletter.suspended?.length;
    switch (filter) {
      case "all": return totalNewsletters;
      case "sent": return sortedNewsletter.sent?.length || 0;
      case "planned": return sortedNewsletter.planned?.length || 0;
      case "draft": return sortedNewsletter.draft?.length || 0;
    }
  };

  const getStatusValue = (template) => {
    const statistic = statistics.find(elem => elem.newsletter_id === template.id);
    if (!template.dateSent) {
      if (template.suspendedDate) {
        return <div className={`textMedium ${classes.suspendedStatus} ${classes.cardStatus}`}>{intl.messages["newsletterList.table.status.suspended"]}</div>;
      }
      return <div className={`textMedium ${classes.draftStatus} ${classes.cardStatus}`}>{intl.messages["newsletterList.table.status.draft"]}</div>;
    }
    if (!statistic) {
      const now  = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(now.getDate() - 1);
      tomorrow.setHours(0, 0, 0, 0);
      const dateSent = new Date(template.dateSent);
      dateSent.setHours(0, 0, 0, 0);
      
      if (dateSent <= tomorrow) {
        return <div className={`textMedium ${classes.errorStatus} ${classes.cardStatus}`}>{intl.messages["newsletterList.table.status.error"]}</div>;
      } else {
        return <div className={`textMedium ${classes.plannedStatus} ${classes.cardStatus}`}> {getDateValue(template.dateSent)} </div>;
      }
    } else {
      template.statistic = statistic;
      return <div className={`textMedium ${classes.sentStatus} ${classes.cardStatus}`}>{intl.messages["newsletterList.table.status.sent"]}</div>;
    }
  };


  const getDateValue = useCallback((originalDate) => {
    const tmpDate = new Date(originalDate);
    const day = tmpDate.getUTCDate();
    const month = tmpDate.getUTCMonth() + 1;
    const year = tmpDate.getUTCFullYear();
    const formatedDate = (day < 10 ? "0" : "") + day + "/" + (month < 10 ? "0" : "") + month + "/" + year;
    return formatedDate;
  }, []);



  const getDateContent = useCallback((template, updatedAt, createdAt) => {
    const updatedAtDate = new Date(updatedAt);
    const createdAtDate = new Date(createdAt);
    const updatedDateYear = updatedAtDate.getUTCFullYear();
    const updatedDateMonth = updatedAtDate.getUTCMonth();
    const updatedDateDay = updatedAtDate.getUTCDate();
    const updatedDateHours = updatedAtDate.getUTCHours();
    const updatedDateMinutes = updatedAtDate.getUTCMinutes();
    const createdAtYear = createdAtDate.getFullYear();
    const createdAtMonth = createdAtDate.getMonth();
    const createdAtDay = createdAtDate.getDate();
    const createdAtHours = createdAtDate.getUTCHours();
    const createdAtMinutes = createdAtDate.getUTCMinutes();
    const isSameDateAndTime = updatedDateYear === createdAtYear && updatedDateMonth === createdAtMonth && updatedDateDay === createdAtDay && updatedDateHours === createdAtHours && (updatedDateMinutes === createdAtMinutes || updatedDateMinutes - 1 === createdAtMinutes);
  
    if (template.sendedDate) {
      return (
        <><Translation id={"newsletter.sentOn"} /> {getDateValue(template.sendedDate)}</>
      );
    }
    if (template.plannedDate) {
      return (
        <><Translation id={"newsletter.plannedOn"} /> {getDateValue(template.plannedDate)} </>
      );
    }
    return (
      <><Translation id={isSameDateAndTime ? "newsletter.createdAt" : "newsletter.updatedAt"} /> {getDateValue(updatedAt ? updatedAt : createdAt)} </>
    );
  }, [getDateValue]);


  useEffect(() => {
    setSelectedFilter("all");
    setFilteredNewsletter(account.mjmlTemplates?.filter(row => !search || row.name.toUpperCase().includes(search.toUpperCase())));
  }, [search]);

  const getMjmlTemplate = useCallback(async (templateId) => {
    return auth.fetch(`/api/account_management/mjmlTemplate?templateId=${templateId}`);
  }, [auth]);

  useEffect(() => {
    const load = async () => {
      const sortedNewsletter = { sent: [], planned: [], suspended: [], all: [], draft: [] };
      const allNewslettersArray = [];
      await account.mjmlTemplates.sort(sortByUpdatedAt);
      account.mjmlTemplates.map(async (template) => {
        allNewslettersArray.push(template);
        const statistic = statistics.find(elem => elem.newsletter_id === template.id);
        template.statistics = statistic;

        //SUSPENDED ARRAY
        if (!template.dateSent) {
          if (template.suspendedDate) {
            sortedNewsletter.suspended.push(template);
          }
          //DRAFT ARRAY
          sortedNewsletter.draft.push(template);
        }
        //PLANNED ARRAY
        if (!statistic && template.dateSent) {
          template.plannedDate = template.dateSent;
          sortedNewsletter.planned.push(template);
        } else if (template.dateSent) {
          //SENDED ARRAY
          template.sendedDate = template.dateSent;
          sortedNewsletter.sent.push(template);
        }
      });

      await sortedNewsletter.planned.sort(sortPlannedNewletter);
      await sortedNewsletter.sent.sort(sortSendedNewsletter);

      //ALL ARRAY
      //const allNewslettersArray = [].concat.apply([], Object.values(sortedNewsletter));
      sortedNewsletter.all = allNewslettersArray;

      setSortedNewsletter(sortedNewsletter);
      setHtmlToRender(allNewslettersArray);

      setIsLoaded(true);
    };
    load();
  }, [account, statistics]);

 


  const sortByUpdatedAt = (a, b) => {
    const aDate = a.dateSent ? a.dateSent : a.updatedAt ? a.updatedAt : a.createdAt;
    const bDate = b.dateSent ? b.dateSent : b.updatedAt ? b.updatedAt : b.createdAt;
    if (aDate < bDate) return 1;
    if (aDate > bDate) return -1;
    return 0;
  };

  const sortPlannedNewletter = (a, b) => {
    if (a.dateSent < b.dateSent) return -1;
    if (a.dateSent > b.dateSent) return 1;
    return 0;
  };

  const sortSendedNewsletter = (a, b) => {
    if (a.dateSent < b.dateSent) return 1;
    if (a.dateSent > b.dateSent) return -1;
    return 0;
  };


  const injectHtmlPreview = useCallback(async (template) => {
    const tmp = await getMjmlTemplate(template.id);
    let html = mjml2html(tmp.template, { validationLevel: "skip" }).html;

    Object.entries(tmp.wordings).forEach(([wordingId, wordings]) => {
      if (wordings) {
        html = html.replaceAll(`{{${wordingId}}}`, wordings[intl.locale]);
      }
    });
    Object.entries(tmp.variables).forEach(([key, variable]) => {
      if (variable) {
        html = html.replaceAll(`{{${key}}}`, variable[intl.locale]);
      }
    });
    html = html.replace(/<a([^>]*)>/g, "<a$1 onclick=\"return false;\">");
    const node1 = document.getElementById(template.id);
    if (node1) {
      node1.innerHTML = html;
    } else {
      // console.error(`L'élément avec l'ID ${template.id} n'a pas été trouvé.`);
    }

    setHtmlRendering(prev => prev.filter(elem => elem.id !== template.id));
  }, [getMjmlTemplate, intl]);

  useEffect(() => {
    const MAX_RENDER = 3;
    
    if (htmlRendering.length < MAX_RENDER  && htmlToRender.length) {

      let i = 0;
      while (i < MAX_RENDER && i < htmlToRender.length && htmlRendering.length + i < MAX_RENDER) {
        const template = htmlToRender[i];
        injectHtmlPreview(template);
        setHtmlRendering(prev => [...prev, template]);
        i++;
      }

      setHtmlToRender(prev => prev.slice(i));
    }
  }, [htmlToRender, htmlRendering, injectHtmlPreview]);

  return (
    <div>
      <h2 className={classes.title}>
        <Translation id="newsletterList.title" />
      </h2>

      <div className={classes.filters}>
        {["all", "sent", "planned", "draft"].map(filter => (
          <div className={`${classes.filter} ${selectedFilter === filter ? classes.selectedFilter : ""}`} onClick={() => {
            setSelectedFilter(filter); setSearch("");
          }}>
            <span>
              <Translation id={`newsletter.filter.${filter}`} defaultMessage="" />
              <div className={classes.selectedFilterUnderline} style={{ visibility: selectedFilter === filter ? "" : "hidden" }} />
            </span>
            {filter !== "all" && <span className={classes.filterNumber}>{getFilterNumber(filter)}</span>}
          </div>
        ))}
      </div>

      <div className={classes.mainContent}>

        <div className={classes.search}>
          <KiTextField
            placeholder={intl.messages["products.filters.search.placholder"]}
            type="whiteBg"
            autoComplete="off"
            name="template_search"
            handleChange={event => { setSearch(event.target.value); }}
          />
        </div>

        <div className={classes.separator} />

        { isloaded && 
          <div className={classes.cardList}>
            {(search.length === 0  ? sortedNewsletter[selectedFilter] : filteredNewsletter)?.map(template => (
              <div onClick={() => setSelectedRow(template)} className={classes.card}>
                {getStatusValue(template)}
                <h3>{template.name}</h3>
                <div className={`info ${classes.cardDate}`}>
                  {getDateContent(template, template.updatedAt, template.createdAt, template.dateSent)}
                  <div style={{ overflow: "scroll", height: "127px", marginTop: "20px" }} id={template.id}></div>
                </div>
              </div>
            ))}
          </div>
        }
        

        <NewsletterEditPopup
          isOpen={!!selectedRow}
          row={selectedRow}
          close={() => setSelectedRow(null)}
          reload={() => reloadAccountContext()}
        />

      </div>
    </div>
  );
};