import { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { Dialog } from "./common/Dialog.js";
// import { NoCmsDialog } from "./common/EmptyState/NoCmsDialog.js";
import { FormButtonTO } from "./FormButtonTO.jsx";
import { PrestashopDialog } from "./OnBoarding/OnBoardingPages/ChooseCms/PrestashopDialog/PrestashopDialog.jsx";
import { MagentoDialog } from "./OnBoarding/OnBoardingPages/ChooseCms/MagentoDialog/MagentoDialog.jsx";
import { ShopifyDialog } from "./OnBoarding/OnBoardingPages/ChooseCms/ShopifyDialog/ShopifyDialog.jsx";
import { WoocommerceDialog } from "./OnBoarding/OnBoardingPages/ChooseCms/WoocommerceDialog/WoocommerceDialog.jsx";
import { AccountContext } from "../contexts/context.js";
import { useAuth } from "../hooks/useAuth.js";

import classes from "./DisconnectedModulePopup.module.scss";

export function DisconnectedModulePopup() {
  const intl = useIntl();
  const auth = useAuth();

  const { account } = useContext(AccountContext);
  
  const [openModuleDisconnectedPopup, setOpenModuleDisconnectedPopup] = useState(false);
  const [accountSetupOpened, setAccountSetupOpened] = useState(false);
  
  const hasCheckedOnceRef = useRef(false);
  useEffect(() => {
    if(account.step_register >= 3 && !hasCheckedOnceRef.current) {
      auth.fetch("/api/account_management/checkModule", { method: "POST" }).then(response => {
        hasCheckedOnceRef.current = true;
        if (!response.success) {
          setOpenModuleDisconnectedPopup(true);
        }
      });
    }
  }, [auth, account]);

  function closeOpenModuleDisconnectedPopup() {
    setOpenModuleDisconnectedPopup(false);
  }
  function closeAccountSetupPopup() {
    setAccountSetupOpened(false);
    closeOpenModuleDisconnectedPopup();
  }

  return (
    <>
      <Dialog
        PaperProps={{ style: { borderRadius: 20 } }}
        isOpen={openModuleDisconnectedPopup}
        close={closeOpenModuleDisconnectedPopup}
      >
        <div className={classes.container}>
          <h2 className={classes.title}>{intl.messages["disconnectedModulePopup.title"]}</h2>
          <span>{intl.messages["disconnectedModulePopup.subtitle"]}</span>
          <div className={classes.buttonContainer}>
            <FormButtonTO 
              label={intl.messages["button.later"]}
              action={closeOpenModuleDisconnectedPopup}
              size="sm"
              type="secondary"
            />
            <FormButtonTO 
              label={intl.messages["disconnectedModulePopup.button.reinstall"]}
              action={() => setAccountSetupOpened(true)}
              size="sm"
            />
          </div>
        </div>
      </Dialog>
      {account.CMS === "prestashop" &&
        <PrestashopDialog 
          open={accountSetupOpened}  
          handleClose={closeAccountSetupPopup}
          onSubmit={closeAccountSetupPopup}
        />
      }
      {account.CMS === "magento" &&
        <MagentoDialog 
          open={accountSetupOpened}  
          handleClose={closeAccountSetupPopup}
          onSubmit={closeAccountSetupPopup}
        />
      }
      {account.CMS === "shopify" &&
        <ShopifyDialog 
          open={accountSetupOpened}  
          handleClose={closeAccountSetupPopup}
          onSubmit={closeAccountSetupPopup}
        />
      }
      {account.CMS === "woocommerce" &&
        <WoocommerceDialog 
          open={accountSetupOpened}  
          handleClose={closeAccountSetupPopup}
          onSubmit={closeAccountSetupPopup}
        />
      }
    </>
  );
}