import { makeStyles } from "@material-ui/core";

import { Translation } from "../../common/Translation";
import { CustomButton } from "../../common/Button/CustomButton";

import { palette } from "../../../styles/palette";

const styles = () => ({
  subtitle: {
    marginBottom: 20,
    display: "block",
  },
  card: {
    borderRadius: 30,
    background: palette.primaryWhite,
    display: "flex",
    alignItems: "center",
    padding: "40px 0px",
    flexDirection: "column",
  },
  image: {
    height: 214,
    marginBottom: 40,
  }
});

const useStyles = makeStyles(styles);

export const SubscriptionCardNoOpportunity = ({ image, onClick, buttonLabel, text, title, subtitle }) => {
  
  const classes = useStyles();

  return (
    <>
      <h3><Translation id={title} /></h3>
      <span className={`text ${classes.subtitle}`} ><Translation id={subtitle} /></span>

      <div className={classes.card}>
        <img src={image} alt="" className={classes.image} />
        <CustomButton 
          type={"primary"}
          onClick={onClick}
          style={{ marginBottom: 10 }}
        >
          <Translation id={buttonLabel} />
        </CustomButton>
        
        {!!text && text}
      </div>
    </>
  );
};
