const LABEL = "preheader";
const CATEGORY = "preheader:preheader";

export const preheader = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {

    const { fontFamily, textColor, title, backgroundColor, isVisible = true } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" ${!isVisible ? "css-class=\"hiddenPreheader\"" : "" } background-color="${backgroundColor}" padding-top="0" padding-bottom="0" full-width="full-width">
        <mj-section padding-top="10px" padding-bottom="10px">
          <mj-column>
            <mj-text color="${textColor}" font-family="${fontFamily}" font-weight="400" font-size="10px" align="center" line-height="1.3" data-text-id="title">
              {{title}}
            </mj-text>
          </mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    const wordings = {
      title,
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        wordings,
        editableProperties: [
          { key: "isVisible", type: "preheaderVisibility", label: "", group: "" },
          { key: "texts", type: "generatedTexts", label: "", group: "newsletter.rightMenu.propertyGroup.generatedTexts" },
          ...( isVisible ? [{ key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" }] : []),
        ]
      }
    };
  },
  style: `
    .hiddenPreheader {
      display: none !important;
      visibility:hidden;
      mso-hide:all;
      font-size:1px;
      line-height:1px;
      max-height:0px;
      max-width:0px;
      opacity:0;
      overflow:hidden;
    }
  `,
});