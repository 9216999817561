import { Component } from "react";

import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";

import { palette } from "../../../../../styles/palette.js";
import { htmlCustomizerTooltipStyleValues } from "./htmlCustomizerTooltipStyleValues.js";

const styles = (theme) => {
  const {
    tooltipArrowHeight,
    inputVerticalPadding,
    tooltipBackgroundColor,
    tooltipPadding,
  } = htmlCustomizerTooltipStyleValues;

  return {
    tooltip: {
      position: "absolute",
      zIndex: 1000,
      top: props => props.styles?.top || 0,
      left: "50%",
      transform: `
				translateY(calc(-100% - ${inputVerticalPadding}px - ${tooltipArrowHeight}px))
				translateX(-50%)
			`,
      backgroundColor: tooltipBackgroundColor,
      borderRadius: 4,
      display: "flex",
      padding: tooltipPadding,
      "&::after": {
        content: '""',
        display: "block",
        position: "absolute",
        top: "100%",
        left: `calc(50% - ${tooltipArrowHeight}px)`,
        // trick for creating a triangle
        borderTop: `${tooltipArrowHeight}px solid ${palette.black}`,
        borderLeft: `${tooltipArrowHeight}px solid transparent`,
        borderRight: `${tooltipArrowHeight}px solid transparent`,
      },
    },
  };
};

export const HtmlCustomizerTooltip = withStyles(styles)(
  class HtmlCustomizerTooltip extends Component {
    render() {
      const { children, classes, open } = this.props;

      return (
        <Fade in={open}>
          <div className={classes.tooltip}>{children}</div>
        </Fade>
      );
    }
  }
);
