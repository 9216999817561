import { palette } from "./palette.js";

export const styles = (theme) => ({
  card: {
    backgroundColor: palette.white,
    overflow: "hidden",
    borderRadius: 30,
    width: "240px",
    height: "257px",
    justify: "center",
    position: "relative",
    alignItems: "center",
    marginLeft: "0px",
    flexGrow: 1,
    textAlign: "center",
    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
  },
  green_area: {
    width: "106px",
    height: "30px",
    borderRadius: "14px",
    backgroundColor: "#b8e986",
    marginTop: "14px",
    marginLeft: "82px",
    position: "absolute",
    bottom: "14px",
    border: "0.5px solid #a5adb8",
  },
  green_area_text: {
    paddingTop: "4px",
    fontFamily: "Coolvetica Book",
    fontSize: "14px",
    fontWeight: "600",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
  },
  buttons: {
    bottom: "14px",
    left: "30px",
    width: "220px",
    position: "absolute",
  },
  know_more: {
    fontFamily: "Coolvetica Book",
    fontSize: "12px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: palette.purple,
    cursor: "pointer",
  },
  media: {
    height: 160,
  },
  isComingSoon: {
    zIndex: "2",
    overflowY: "visible",
    overflowX: "hidden",
    position: "relative",
    top: "20px",
    left: "100px",
    width: "138px",
    height: "29px",
    paddingTop: "6px",
    transform: "rotate(45deg)",
    backgroundColor: palette.purple,
    color: palette.black,
    textAlign: "center",
  },
  isComingSoon_text: {
    fontFamily: "Coolvetica Book",
    fontSize: "17px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: palette.white,
    textTransform: "uppercase",
  },
  title: {
    marginTop: "29px",
    fontSize: "16px",
    fontWeight: "600",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    textAlign: "center",
  },
  description: {
    width: "210px",
    marginTop: "9px",
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
  },
  iOSSwitchBase: {
    "&$iOSChecked": {
      color: theme.palette.common.white,
      "& + $iOSBar": {
        backgroundColor: "#b8e986",
      },
    },
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: "translateX(19px)",
    "& + $iOSBar": {
      opacity: 1,
    },
  },
  iOSBar: {
    borderRadius: "8px",
    width: "35px",
    height: "16px",
    marginTop: "-8px",
    marginLeft: "-15px",
    borderColor: "red",
    backgroundColor: "#ed5f55",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  iOSIcon: {
    width: 14,
    height: 14,
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1],
  },
  tooltip: {
    position: "relative",
    top: "25px",
    width: "fit-content",
    maxWidth: "none",
    whiteSpace: "nowrap",
    fontFamily: "Coolvetica Book",
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.3px",
    textAlign: "center",
    color: "#ffffff",
  },
  premiumCrown: {
    position: "absolute",
    top: "10px",
    right: "10px",
    maxWidth: "30px",
  },
  adderContainer: {
    borderRadius: 5,
    overflow: "hidden",
  },
  usecaseAdder: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: "5px",
    width: "270px",
    height: "270px",
    alignItems: "center",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23${palette.primaryBlack.replace(
      "#",
      ""
    )}' stroke-width='2' stroke-dasharray='10%2c10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
  },
  usecaseAdderIcon: {
    color: palette.primaryBlack,
    fontSize: 48,
  },
  usecaseAdderTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: palette.primaryBlack,
    marginTop: 16,
  },
  statusContainer: {
    position: "relative",
    height: 20,
    width: "100%",
  },
  status: {
    position: "absolute",
    right: 0,
    height: 25,
    top: 15,
    width: 100,
    paddingTop: 4,
    textAlign: "center",
    borderTopLeftRadius: 40,
    borderBottomLeftRadius: 40,
  },
  statusInactif: {
    backgroundColor: palette.neutralLightGrey,
    color: palette.neutralDarkGrey,
  },
  statusActif: {
    backgroundColor: palette.accentGreen100,
    color: palette.accentGreen500,
  },
  statusActifDate: {
    backgroundColor: palette.accentPurple100,
    color: palette.accentPurple500,
  }
});
