import { types as kilibaBlockTypes } from "./kilibaBlock";

export const setUpPreviewsComponent = (editor) => {
  //previews management
  kilibaBlockTypes.forEach(key => {
    editor.Components.addType(`preview-${key}`, {
      extend: key,
      isComponent: el => {
        if (el.tagName?.toLowerCase() === key && "data-preview" in el.attributes) {
          return true;
        }
      },
      model: {
        toHTML() {
          return "";
        },
      }
    });
  });

  editor.Components.addType("column-preview-mj-raw", {
    extend: "mj-raw",
    isComponent: el => {
      if (el.tagName?.toLowerCase() === "mj-raw" && "data-column-preview" in el.attributes) {
        return true;
      }
    },
    model: {
      toHTML() {
        return "";
      },
    }
  });
  
};