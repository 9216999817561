import React  from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";


export const KiTooltip= ({ type,title,children,placement }) => {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: type === "light" ? theme.palette.common.white : null,
      color: type === "light" ? "rgba(0, 0, 0, 0.87)" : "white",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      textAlign:"center",
      fontFamily: "Poppins",
      padding:"13px 13px",
      fontWeight: "400",
    },
  }));
  return (
    <LightTooltip title={title} placement={placement}>
      <div style={{ marginTop: placement === "bottom" ?  "15px" : null }} >
        { children }                   
      </div>
    </LightTooltip>
  );
};