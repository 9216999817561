import { useCallback, useEffect, useState, useContext } from "react";
import { TextField } from "../../../common/Input/TextField";
import { Translation } from "../../../common/Translation";
import { TemplateEditorContext } from "../../TemplateEditorContext";
import { Switch } from "../../../common/Input/Switch";

import classes from "./ImageSizeInput.module.scss";

export const WidthHeightInput = ({ width, height, type, disabled, setWidth, setHeight, setMouseDownFunc, icon = "fa-regular fa-lock", onIconClick }) => {
  return (
    <div className={classes.widthAndHeightContainer}>
      <div>
        <div className="text"><Translation id="rightmenu.editContent.width" /></div>
        <div className={classes.widthContainer}>
          <div className={classes.pixelInputContainer}>
            <TextField
              value={width}
              type={type}
              onChange={event => setWidth(+event.target.value)}
              disabled={disabled}
              paddingLeft={0}
            />
          </div>
          <div className={classes.arrowsContainer}>
            <div onMouseDown={() => setMouseDownFunc({ elem: "width", value: 1 })}><i className="fa-solid fa-chevron-up" /></div>
            <div onMouseDown={() => setMouseDownFunc({ elem: "width", value: -1 })}><i className="fa-solid fa-chevron-down" /></div>
          </div>
        </div>
      </div>
      <div className={`${classes.aspectRatioIcon} ${onIconClick ? classes.aspectRatioIconClickable : ""}`} onClick={onIconClick}><i className={`${icon}`} /></div>
      <div>
        <div className="text"><Translation id="rightmenu.editContent.height" /></div>
        <div className={classes.heightContainer}>
          <div className={classes.pixelInputContainer}>
            <TextField
              value={height}
              type={type}
              onChange={event => setHeight(+event.target.value)}
              disabled={disabled}
              paddingLeft={0}
            />
          </div>
          <div className={classes.arrowsContainer}>
            <div onMouseDown={() => setMouseDownFunc({ elem: "height", value: 1 })}><i className="fa-solid fa-chevron-up" /></div>
            <div onMouseDown={() => setMouseDownFunc({ elem: "height", value: -1 })}><i className="fa-solid fa-chevron-down" /></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ImageSizeInput = ({ content, updateContent, component, editor, options = {} }) => {

  const [width, setWidth] = useState(content.width);
  const [height, setHeight] = useState();
  const [isFullWidth, setIsFullWidth] = useState(content.width >= 800);
  const [aspectRatio, setAspectRatio] = useState(null);
  const [mouseDownFunc, setMouseDownFunc] = useState();

  const { refreshBlockActionsPosition } = useContext(TemplateEditorContext);  

  useEffect(() => {
    if (content.width !== width) {
      const timeout = setTimeout(() => {
        updateContent({ width });
      }, 250);
      return () => clearTimeout(timeout);
    }
  }, [width, content, updateContent]);

  const setComponentElementWidth = useCallback(newWidth => {

    const imageComponent = component.is("column-component-mj-image") ? component : component.findType("mj-image")[0];

    const tdElems = imageComponent.getEl()?.getElementsByTagName("td") || [];

    for (const elem of tdElems) {
      if (elem.style.getPropertyValue("width")) {
        elem.style.width = `${newWidth}px`;
      }
    }

    refreshBlockActionsPosition();

  }, [component, refreshBlockActionsPosition]);

  const _setWidth = useCallback((newWidth) => {
    if (newWidth > 800) {
      newWidth = 800;
    }
    if (newWidth < 1) {
      newWidth = 1;
    }
    setWidth(newWidth);
    setComponentElementWidth(newWidth);
    setHeight(Math.round(newWidth / aspectRatio));
  }, [aspectRatio, setComponentElementWidth]);

  const _setHeight = useCallback((newHeight) => {
    const newWidth = Math.round(newHeight * aspectRatio);
    if (newWidth > 800) {
      _setWidth(800);
    } else if (newWidth < 1) {
      _setWidth(1);
    } else {
      setHeight(newHeight);
      setWidth(newWidth);
    }
  }, [aspectRatio, _setWidth]);


  useEffect(() => {
    if (isFullWidth) {
      _setWidth(800);
    }
  }, [isFullWidth, _setWidth]);

  const calcAspectRatio = useCallback(() => {
    if (!aspectRatio) {
      const imgElement = component.getEl().getElementsByTagName("img")[0];

      if (!imgElement) {
        return ;
      }

      const init = () => {
        const _aspectRatio = imgElement.naturalWidth / imgElement.naturalHeight;
        setAspectRatio(imgElement.naturalWidth / imgElement.naturalHeight);
        setHeight(Math.round(width / _aspectRatio));
      };
      
      if (imgElement.complete) {
        init();
      } else {
        imgElement.onload = init;
      }
    }
  }, [aspectRatio, component, width]);

  useEffect(() => {
    calcAspectRatio();
  }, [calcAspectRatio]);

  const onDragEnd = useCallback(() => {
    calcAspectRatio();
  }, [calcAspectRatio]);

  useEffect(() => {
    if (!aspectRatio) {
      editor.on("dragEnd", onDragEnd);
      return () => editor.off("dragEnd", onDragEnd);
    }
  }, [editor, onDragEnd, aspectRatio]);

  const disableFullWidth = useCallback(() => {
    _setWidth(600);
    setIsFullWidth(false);
  }, [_setWidth]);

  useEffect(() => {
    if (mouseDownFunc) {
      const interval = setInterval(() => {

        if (isFullWidth) {
          return disableFullWidth();
        }

        if (width === 800 && mouseDownFunc.value > 0) {
          setMouseDownFunc(null);
        }

        if (mouseDownFunc.elem === "width") {
          _setWidth(width + mouseDownFunc.value);
        }
        if (mouseDownFunc.elem === "height") {
          _setHeight(height + mouseDownFunc.value);
        }

      }, 100);
      return () => clearInterval(interval);
    }
  }, [mouseDownFunc, width, _setWidth, _setHeight, height, isFullWidth, disableFullWidth]);

  const onMouseUp = useCallback(() => {
    if (mouseDownFunc) {
      if (mouseDownFunc.elem === "width") {
        _setWidth(width + mouseDownFunc.value);
      }
      if (mouseDownFunc.elem === "height") {
        _setHeight(height + mouseDownFunc.value);
      }
    }
    setMouseDownFunc(null);
  }, [mouseDownFunc, width, height, _setHeight, _setWidth]);

  useEffect(() => {
    document.addEventListener("mouseup", onMouseUp);
    return () => document.removeEventListener("mouseup", onMouseUp);
  });

  if (!aspectRatio) {
    return null;
  }

  return (
    <div className={classes.imageSizeInputContainer}>
      <WidthHeightInput 
        width={isFullWidth ? "auto" : width}
        height={isFullWidth ? "auto" : height}
        type={isFullWidth ? "text" : "px"}
        disabled={isFullWidth}
        setWidth={_setWidth}
        setHeight={_setHeight}
        setMouseDownFunc={setMouseDownFunc}
      />
      {!options.disableFullSize &&
        <div className={classes.switchContainer}>
          <Switch
            checked={isFullWidth}
            onChange={event => isFullWidth ? disableFullWidth() : setIsFullWidth(event.target.checked)}
          />
          <div className="text"><Translation id="rightmenu.editContent.fullWidth" /></div>
        </div>
      }
    </div>
  );
};