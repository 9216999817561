export const styles = (theme) => ({
  error: {
    width: "100%",
    height: "26px",
    textAlign: "center",
    borderRadius: "5px",
    backgroundColor: "#EFD3D0",
    color: "#DB2419",
    border: "1px solid #DB2419",
    fontFamily: "Poppins",
    fontSize: "13px",
    lineHeight: "25px",
    paddingTop: "5px",
    paddingBottom: "5px",
    position: "absolute",
  },
  success: {
    width: "100%",
    height: "26px",
    textAlign: "center",
    borderRadius: "5px",
    backgroundColor: "#E9F1DE",
    border: "1px solid #6fa728",
    color: "#6fa728",
    fontFamily: "Poppins",
    fontSize: "13px",
    lineHeight: "25px",
    paddingTop: "5px",
    paddingBottom: "5px",
    position: "absolute",
  },
});
