import React, { Component } from "react";

import { injectIntl } from "react-intl";

import { TextField } from "../../../../../../common/Input/TextField.js";

export const CodeInput = injectIntl(
  class extends Component {
    render() {
      const {
        intl,
        promoCodeTemplateWrapper,
        setPromoCodeTemplateWrapperProp,
        getError,
      } = this.props;

      return (
        <TextField
          style={{ width:"200px"}}
          dataCy="codeInput"
          value={promoCodeTemplateWrapper.promoCodeTemplate.code}
          errorMessage={getError("code") && getError("code").message}
          placeholder={
            intl.messages["usecase.promoCodeTemplateEditor.code.placeholder"]
          }
          onChange={(event) =>
            setPromoCodeTemplateWrapperProp(
              "promoCodeTemplate.code",
              event.target.value
            )
          }
          type="code"
          startIconName="barcode-read"
          marginIsEnabled={false}
        />
      );
    }
  }
);
