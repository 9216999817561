import { useState } from "react";
import { makeStyles } from "@material-ui/core";

import { useAuth } from "../../../../hooks/useAuth";
import { Switch } from "../../../common/Input/Switch";
import { CustomButton } from "../../../common/Button/CustomButton";
import { Translation } from "../../../common/Translation";
import { EventSystem } from "../../../../eventsystem/EventSystem";

import { styles } from "./SubscriptionSlider.styles";

const useStyles = makeStyles(styles);

export const SubscriptionSliderRightCard = ({ selectedStep, currency }) => {

  const classes = useStyles();
  const auth = useAuth();

  const [isAnnual, setIsAnnual] = useState(false);

  const price = isAnnual ? Math.ceil(selectedStep.price * 0.85) : selectedStep.price;

  const numberWithSpaces = (nb) => {
    return nb.toLocaleString("fr-FR");
  };

  const onSuscribe = async () => {
    let redirectionUrl = null;

    if (selectedStep.opportunity) {
      if (selectedStep.opportunity.Payment_rule__c === "Spécifique" && !!selectedStep.opportunity.Stripe_link__c) {
        ({ redirectionUrl } = await auth.fetch(`/api/payment/createSubscription?upTo=${selectedStep.upTo}&mode=custom&paymentPlatform=${selectedStep.opportunity.Plateforme_de_paiement__c}`, { method: "POST" }));
      } else if (selectedStep.opportunity.Payment_rule__c === "Standard" && selectedStep.opportunity.Plateforme_de_paiement__c === "Shopify store") {
        ({ redirectionUrl } = await auth.fetch(`/api/payment/createSubscription?upTo=${selectedStep.upTo}&mode=custom&paymentPlatform=${selectedStep.opportunity.Plateforme_de_paiement__c}`, { method: "POST" }));
      } else if (selectedStep.opportunity.Payment_rule__c === "Standard" && selectedStep.opportunity.Plateforme_de_paiement__c === "Stripe") {
        ({ redirectionUrl } = await auth.fetch(`/api/payment/createSubscription?upTo=${selectedStep.upTo}&isAnnual=${selectedStep.opportunity.Invoicing_period__c === "Annuelle"}&paymentPlatform=${selectedStep.opportunity.Plateforme_de_paiement__c}`, { method: "POST" }));
      }

    } else {
      ({ redirectionUrl } = await auth.fetch(`/api/payment/createSubscription?upTo=${selectedStep.upTo}&mode=generic&isAnnual=${isAnnual}`, { method: "POST" }));
    }

    if (redirectionUrl) {
      window.open(redirectionUrl, redirectionUrl.includes("shopify") ? "_blank" : "_self");
    }
  };

  return (
    <div className={classes.rightCard}>
      <div className={classes.switchContainer}>
        {!selectedStep.billingMethod && <>
          <span className={`textMedium ${!isAnnual ? classes.yellowText : ""}`}><Translation id="subscriptionSlider.monthly" /></span>
          <Switch checked={isAnnual} onChange={event => setIsAnnual(event.target.checked)} />
          <span className={`textMedium ${isAnnual ? classes.greenText : ""}`}><Translation id="subscriptionSlider.annual" /></span>
          <div className={`infoSmall ${classes.annualPromo} ${isAnnual ? classes.annualPromoSelected : ""}`}>-10%</div>
        </>}
      </div>

      <h3>
        <Translation id="subscriptionSlider.sendEmails" variables={{ contacts: numberWithSpaces(selectedStep.upTo) }} />
      </h3>

      <div className={classes.priceContainer}>
        <span className={classes.price}>{price}</span>
        <span className={classes.priceCurrency}>
          {currency}
          <span className="display4"><Translation id="subscriptionInfo.package.currency.type"/></span>
        </span>
        <br />      
      </div>

      <span className="textMedium">
        <Translation id={selectedStep.billingMethod ? `subscriptionSlider.billing.${selectedStep.billingMethod}` : isAnnual ? "subscriptionSlider.perYear" : "subscriptionSlider.perMonth"} />
      </span>
      <span className={`textMedium ${isAnnual ? classes.greenText : classes.yellowText}`}>
        <Translation id={isAnnual ? "subscriptionSlider.save15percent" : "subscriptionSlider.noObligation"} />
      </span>
      

      <CustomButton
        type={"primary"}
        onClick={() => onSuscribe()}
        style={{ marginTop: 40, marginBottom: 10 }}
      >
        <Translation id="button.subscribe" />
      </CustomButton>

      <span onClick={() => EventSystem.publish("make_appointment")} className="link"><Translation id="accountSetup.stepModule.help" /></span>
    </div>
  );
};