import { ClickAwayListener, Fade } from "@mui/material";

import classes from "./DropdownPopper.module.scss";

export const DropdownPopper = ({ isOpen, anchor, close, options, value, onChange, style, classNames }) => {

  const rect = anchor?.current?.getBoundingClientRect();

  const _onChange = (value, event) => {
    onChange(value);
    close(event);
  };

  if (!isOpen) {
    return ;
  }

  return (
    <ClickAwayListener onClickAway={close}>
      <Fade in={isOpen}>
        <div className={`${classes.dropdownPopper} ${classNames?.container || ""}`} style={{ top: rect?.bottom + 5, left: rect?.left, ...style }}>
          {options.map(option => (
            <div
              key={option.id} 
              className={`text ${classes.option} ${classNames?.option || ""}`}
              onClick={(event) => _onChange(option.id, event)}
            >
              <span>{option.label}&nbsp;</span>
              {option.id === value &&  <i className="fa-solid fa-check" />}
            </div>
          ))}
        </div>
      </Fade>
    </ClickAwayListener>
  );
};