import { useEffect, useState, useReducer } from "react";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";

import { Translation } from "../../common/Translation";
import { SubscriptionPackageCard } from "./SubscriptionPackageCard";
import { SubscriptionAdaptPackageCustom } from "./SubscriptionAdaptPackageCustom";
import { SubscriptionRecommendedPackages } from "./SubscriptionRecommendedPackages";
import { SubscriptionTips } from "./SubscriptionTips";
import { CustomButton } from "../../common/Button/CustomButton";
import { StripeUpgradePopup } from "./popups/StripeUpgradePopup";
import { useAuth } from "../../../hooks/useAuth";

import { palette } from "../../../styles/palette";
import { EventSystem } from "../../../eventsystem/EventSystem";

const styles = () => ({
  root: {
    width: "100%",
    padding: "0 68px",
    overflow: "none",
  },
  title: {
    fontFamily: "Coolvetica",
    fontSize: "40px",
    paddingTop: 25,
    color: palette.black
  },
  subtitle: {
    marginBottom: 40,
    color: palette.neutralDarkGrey,
  },
  content: {
    marginBottom: 70,
  },
  subscriptionInfos: {
    display: "flex",
    gridGap: 30,
  },
  subscriptionInfosLeft: {
    width: "22%",
    position: "relative",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  subscriptionInfosRight: {
    width: "78%",
    display: "flex",
    flexDirection: "column",
  },
  sectionTitle: {
    marginBottom: 15,
  },
  thermometer: {
    background: palette.primaryWhite,
    borderRadius: 20,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    gridGap: 10,
    marginBottom: 13,
  },
  contactsInfo: {
    background: palette.primaryWhite,
    borderRadius: 20,
    padding: "0px 33px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gridGap: 40,
    flexGrow: 1,
    "& span": {
      display: "block"
    },
    "& i": {
      marginLeft: 7,
    },
  },
  blacklistInfo: {
    cursor: "pointer",
    "&:hover": {
      "& $popoverContainer": {
        display: "block !important",
      }
    }
  },
  popoverContainer: {
    zIndex: 20001,
    display: "none",
    position: "relative",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "normal",
  },
  triangle: {
    position: "absolute",
    bottom: -10,
    right: -2,
    width: 0,
    height: 0,
    borderBottom: `10px solid ${palette.primaryWhite}`,
    borderTop: "10px solid transparent",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
  },
  popover: {
    position: "absolute",
    top: 10,
    right: -125,
    width: 250,
    backgroundColor: palette.primaryWhite,
    fontSize: 12,
    boxShadow: "0px 0px 5px 0px rgba(120, 120, 120, 0.25)",
    borderRadius: 5,
    padding: 10,
  },
  cardsContainer: {
    height: 405,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flexGrow: 1
  },
  sliderBackground: {
    marginTop: 5,
    height: 7,
    width: "100%",
    background: palette.neutralLightGrey,
    borderRadius: 40,
    position: "relative",
  },
  sliderBar: {
    background: palette.accentRed100,
    borderRadius: 40,
    height: 7,
  },
  greyText: {
    color: palette.neutralDarkGrey,
  },
  shopifyCard: {
    background: palette.primaryWhite,
    borderRadius: 15,
    padding: "25px 35px",
    display: "flex",
    gridGap: 25,
    marginBottom: 30,
    alignItems: "center",
    "& button": {
      textWrap: "nowrap",
    }
  },
  stat: {
    marginTop: 5,
  }
});

const useStyles = makeStyles(styles);

export const SubscriptionInfo = () => {
  
  const classes = useStyles();
  const auth = useAuth();
  const intl = useIntl();
  const [updated, forceUpdate] = useReducer(x => x + 1, 0);

  const [contacts, setContacts] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [step, setStep] = useState(null);
  const [steps, setSteps] = useState(null);
  const [nextPlan, setNextPlan] = useState();
  const [stripePackage, setStripePackage] = useState();
  const [upgradeLoading, setUpgradeLoading] = useState(false);

  useEffect(() => {
    auth.fetch("/api/data/getAccountAmountOfContacts").then(data => {
      setContacts(data);
      auth.fetch(`/api/payment/nextPlan?activeCustomerNbr=${data.actifs}&withStep=true`).then(setNextPlan);
    });
    auth.fetch("/api/payment/getCursorSteps").then(setSteps);
    auth.fetch("/api/account_management/sfSubscription").then(data => {
      setSubscription(data);
      auth.fetch(`/api/payment/getCursorStep?upTo=${data.Limite_de_contacts__c}`).then(setStep).catch(() => setStep({ yearNbr: "always" }));
    });
  }, [auth, updated]);

  if (!subscription || !contacts) {
    return ;
  }

  let thermometer = "allGood";

  if (subscription.Price__c === 49 && contacts.actifs * 100 / subscription.Limite_de_contacts__c < 50 && contacts.outOfPackage === 0) {
    thermometer = "antiChurn";
  }

  if (contacts.outOfPackage > 0 && contacts.actifs * 100 / subscription.Limite_de_contacts__c < 90) {
    thermometer = "outOfPackage";
  }

  if (contacts.outOfPackage > 0 && contacts.actifs * 100 / subscription.Limite_de_contacts__c >= 90) {
    thermometer = "packageLimit";
  }

  if (contacts.actifs > subscription.Limite_de_contacts__c) {
    if (contacts.actifs > 200000 ||
      (subscription.interval === "year" && subscription.Price__c > nextPlan.price_annual) ||
      (subscription.interval === "month" && subscription.Price__c > nextPlan.price)) {
      thermometer = "upgradeCustom";
    } else {
      thermometer = "upgrade";
    }
  }

  const onUpgrade = (_package) => {
    if (subscription.Plateforme_de_paiement__c === "Shopify store") {
      auth.fetch(`/payment/upgradeShopifySubscription?upTo=${_package.upTo}`, { method: "POST" }).then(({ redirectionUrl }) => window.open(redirectionUrl, "_blank"));
    } else {
      if (!stripePackage) {
        setStripePackage(_package);
      } else {
        setUpgradeLoading(true);
        auth.fetch(`/payment/upgradeStripeSubscription?upTo=${stripePackage.upTo}`, { method: "POST" }).then(() => {
          setUpgradeLoading(false);
          setStripePackage(null);
          forceUpdate();
          EventSystem.newNotification("notification.action", intl.messages["stripeUpgradePopup.notif"]);
        });
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>
          <Translation id="subscriptionInfo.header.title" />
        </div>
        <h4 className={classes.subtitle}>
          <Translation id="subscriptionInfo.header.subtitle" defaultMessage="" />
        </h4>
      </div>


      {thermometer === "upgrade" && subscription.Plateforme_de_paiement__c === "Shopify store" &&
        <div className={classes.shopifyCard}>
          <div className="infoMedium"><Translation id="subscriptionInfo.shopifyCard.month" /></div>
          <CustomButton 
            size="md"
            onClick={() => auth.fetch(`/payment/upgradeShopifySubscription?upTo=${nextPlan.upTo}`, { method: "POST" }).then(({ redirectionUrl }) => window.open(redirectionUrl, "_blank"))}
          >
            <Translation id="subscriptionInfo.shopifyCard.button" />
          </CustomButton>
        </div>
      }

      <div className={classes.content}>

        <div className={classes.subscriptionInfos}>

          <div className={classes.subscriptionInfosLeft}>
            <h3 className={classes.sectionTitle}><Translation id="subscriptionInfo.section1.title"/></h3>
            <div className={classes.cardsContainer}>
              <div className={classes.thermometer}>
                <span className={thermometer !== "outOfPackage" ? "textMedium" : "text"}>
                  <FormattedMessage 
                    id={`subscriptionInfo.thermometer.${thermometer}.title`} 
                    values={{ 
                      outOfPackageCustomers: <span className="textBold">{contacts.outOfPackage}</span>, 
                      customers: <span className={`textBold ${classes.greyText}`}>/{contacts.actifs + contacts.outOfPackage}</span>,
                    }}
                  />
                </span>
                <span className={thermometer !== "outOfPackage" ? "text" : "infoSmall"}><Translation id={`subscriptionInfo.thermometer.${thermometer}.text`}/></span>
              
                {thermometer === "outOfPackage" && <div className={classes.sliderBackground}>
                  <div className={classes.sliderBar} style={{ width: `${contacts.outOfPackage * 100 / (contacts.actifs + contacts.outOfPackage)}%` }} />
                </div>}
              </div>
              <div className={classes.contactsInfo}>
                <div>
                  <span className="display2">{contacts ? contacts.contacts : ""}</span>
                  <span className={`${classes.stat} text`}><Translation id="subscriptionSlider.stat1" /></span>
                </div>

                <div>
                  <span className="display2">{contacts ? contacts.contacts - contacts.actifs - contacts.outOfPackage : ""}</span>
                  <span className={`${classes.stat} text`}>
                    <Translation id="subscriptionSlider.stat2" />
                    <i className={`fa-solid fa-circle-info ${classes.blacklistInfo}`}>
                      <div className={classes.popoverContainer}>
                        <div className={classes.popover}>
                          <Translation id="subscriptionSlider.blacklist.popover" />
                        </div>
                        <div className={classes.triangle} />
                      </div>
                    </i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.subscriptionInfosRight}>
            <h3 className={classes.sectionTitle}><Translation id="subscriptionInfo.section2.title"/></h3>
            {!!contacts && !!step && <SubscriptionPackageCard subscription={subscription} contacts={contacts} step={step} nextPlan={nextPlan} thermometer={thermometer} />}
          </div>

        </div>

      </div>

      {subscription.interval_count !== 1 ?
        <>
          {["outOfPackage", "packageLimit", "upgrade"].includes(thermometer) && <SubscriptionAdaptPackageCustom />}
        </> :
        <>
          {["allGood", "antiChurn"].includes(thermometer) && <SubscriptionTips tips={thermometer === "antiChurn" ? ["sponsorship", "strategy"] : ["inbound", "strategy"]} /> }
          {!!steps && ["outOfPackage", "packageLimit", "upgrade"].includes(thermometer) && 
          <SubscriptionRecommendedPackages
            steps={steps}
            subscription={subscription}
            nextPlan={thermometer === "upgrade" ? nextPlan : null}
            contacts={contacts}
            onUpgrade={onUpgrade}
          />}
        </>
      }

      <StripeUpgradePopup
        isOpen={!!stripePackage}
        close={() => setStripePackage(null)}
        subscription={subscription}
        _package={stripePackage}
        onUpgrade={onUpgrade}
        upgradeLoading={upgradeLoading}
      />
    </div>
  );
};