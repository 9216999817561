import { useState, useEffect, useContext, useCallback } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { AccountContext } from "../../../contexts/context";

export const useNewsletterPromoCode = (templateId) => {
  const auth = useAuth();
  const {account} = useContext(AccountContext);
  const [promoCode, setPromoCode] = useState(null);

  const loadPromoCode = useCallback(async () => {
    if (templateId) {
      const template = account.mjmlTemplates.find(template => template.id === templateId);
      if (!template?.promoCodeId) {
        return;
      }

      const response =await auth.fetch(
        `/account_management/getPromoCode?promoCodeId=${template.promoCodeId}`,
        {
          method: "GET"
        }
      );
      setPromoCode(response);
      return response;
    }
  }, [templateId, auth, account]);

  useEffect(() => {
    loadPromoCode();
  }, [loadPromoCode]);

  const reloadPromoCode = useCallback(() => {
    return loadPromoCode();
  }, [loadPromoCode]);

  return { promoCode, reloadPromoCode };
};
