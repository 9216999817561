import React, { Component } from "react";
import { injectIntl } from "react-intl";
import withStyles from "@material-ui/core/styles/withStyles";

import { styles } from "../styles/resultlabelto.js";

export const ResultLabelTO = withStyles(styles)(
  injectIntl(
    class ResultLabelTO extends Component {
      render() {
        const {
          intl,
        } = this.props;

        // Define type of label
        let type_class;
        if (this.props.error === true) {
          type_class = this.props.classes.error;
        } else {
          type_class = this.props.classes.success;
        }

        if (this.props.visible === true) {
          return (
            <div style={this.props.style} className={type_class}>
              {intl.messages[this.props.label]}
            </div>
          );
        } else {
          return (
            <div style={this.props.inactive_style} className={type_class}></div>
          );
        }
      }
    }
  )
);
