import { useState, useContext } from "react";

import { UploadImageInput } from "./UploadImageInput";
import { CustomButton } from "../../../common/Button/CustomButton";
import { ConfirmDeleteImagePopup } from "../../popups/ConfirmDeleteImagePopup";
import { Translation } from "../../../common/Translation";
import { TemplateEditorContext } from "../../TemplateEditorContext";
import { useAuth } from "../../../../hooks/useAuth";

import classes from "./ImageList.module.scss";

export const ImageList = ({ images, onUpload, selectedUrl, onImageClick, onDeleteImages, fullSizeImages = false, zoomImage = false, disableDelete = false, loadingImages = 0 }) => {

  const auth = useAuth();
  const [isPopupOpened, setIsPopupOpened] = useState(false);  
  const [selectedImages, setSelectedImages] = useState([]);

  const { templateId, templateInfo, setTemplateInfo } = useContext(TemplateEditorContext);

  const toggleImage = (image) => {
    if (selectedImages.includes(image)) {
      setSelectedImages(prev => prev.filter(elem => elem !== image));
    } else {
      setSelectedImages(prev => [...prev, image]);
    }
  };

  const deleteImages = () => {
    const updatedSelectedImages = templateInfo?.selectedImages.filter(elem => !selectedImages.includes(elem.url));
    auth.fetch("/api/account_management/saveMjmlTemplate", {
      method: "POST",
      body: JSON.stringify({
        templateId: templateId,
        selectedImages: updatedSelectedImages,
      })
    });
    setTemplateInfo(prev => ({
      ...prev, 
      selectedImages: updatedSelectedImages,
    }));
    onDeleteImages && onDeleteImages(selectedImages);

    setSelectedImages([]);
    setIsPopupOpened(false);
  };

  const onTrashClick = (event, image) => {
    event.stopPropagation();
    setSelectedImages([image]);
  };

  return (
    <>
      {!!selectedImages.length && 
        <div className={classes.buttonsContainer}>
          <CustomButton
            type="tertiary"
            size="xs"
            onClick={() => setSelectedImages([])}
          >
            <Translation id="button.anulation" />
          </CustomButton>
          <CustomButton
            size="xs"
            onClick={() => setIsPopupOpened(true)}
          >
            <Translation id="button.remove" />
          </CustomButton>
        </div>
      }
      <div className={classes.imagesContainer}>
        {!!onUpload && <UploadImageInput onUpload={onUpload} />}
        {images.map((image, idx) => (
          <div 
            key={idx} 
            className={`${classes.imageContainer} ${fullSizeImages ? classes.imageContainerFullSize : ""} ${zoomImage ? classes.zoomImage : ""} ${image === selectedUrl ? classes.imageContainerSelected : ""}`}
            onClick={() => !selectedImages.length ? onImageClick(image) : toggleImage(image)}
            style={{ backgroundImage: zoomImage ? `url(${image})` : null }}
          >
            {!zoomImage && 
              <img
                alt=""
                src={image}
              />
            }
            {!disableDelete ?
              !selectedImages.length ?
                <div onClick={event => onTrashClick(event, image)} className={classes.iconDeleteImageContainer}>
                  <i className="fa-solid fa-trash-can" />
                </div> :
                <div className={classes.selectableContainer}>
                  <i className={selectedImages.includes(image) ? "fa-solid fa-circle-check" : "fa-regular fa-circle"} />
                </div>
              : <></>
            }
          </div>
        ))}

        {Array.from({ length: loadingImages}).map((_,index) => (
          <div key={index} className={`${classes.imageContainer} ${classes.loading}`}></div>  
        ))}

        <ConfirmDeleteImagePopup 
          isOpen={isPopupOpened}
          close={() => setIsPopupOpened(false)}
          deleteImage={deleteImages}
          nbImages={selectedImages.length}
        />
      </div>
    </>
  );
};