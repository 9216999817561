import { useEffect, useState } from "react";
import { Translation } from "../../common/Translation";
import { KiTextField } from "../../common/Input/KiTextField.js";
import classes from "./Integration.module.scss";

import kiliba from "../../../public/logo-small.png";
import googleAnalytics from "../../../public/googleAnalyticsLogo.png";
import brevo from "../../../public/brevoLogo.png";
import mailChimp from "../../../public/mailChimpLogo.png";
import { Radio } from "../../common/Input/Radio/Radio.js";
import { RadioGroup } from "../../common/RadioGroup";
import { CmsConnectingStateBadge } from "../../common/CmsConnectingStateBadge";
import { IntegrationPopup } from "./integrationPopup.jsx";
import { CustomButton } from "../../common/Button/CustomButton.js";
import { useAuth } from "../../../hooks/useAuth";
import { useIntl } from "react-intl";
import { getDateDeltaInMinutes } from "../../../tools/date";

import { EventSystem } from "../../../eventsystem/EventSystem";

export const SecondaryCmsCard = ({ platform, closeCmsCard, secondaryCmsConnected, secondaryCmsRevoked, isLoading, disconnectSecondaryCms, onUpdate, utm }) => {

  const auth = useAuth();
  const [logo, setLogo] = useState([]);
  const [link, setLink] = useState([]);
  const [apiLink, setApiLink] = useState([]);
  const [secondaryCmsData, setSecondaryCmsData] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [popupParams, setPopupParams] = useState("");
  const [kilibaKey, setKilibaKey] = useState("");
  const [googleSetting, setGoogleSetting] = useState(utm);

  const intl = useIntl();

  useEffect(() => {
    // SET LOGO 
    const platformLogos = {
      "Kiliba Connect": kiliba,
      "Google Analytics": googleAnalytics,
      Mailchimp: mailChimp,
      Brevo: brevo,
    };
    if (platform in platformLogos) {
      setLogo(platformLogos[platform]);
    }
    // SET LINK
    const platformLink = {
      "Kiliba Connect": kiliba,
      "Google Analytics": googleAnalytics,
      Mailchimp: "https://us1.admin.mailchimp.com/account/api/?_ga=2.210325526.223931880.1694598864-715564033.1693832892",
      Brevo: "https://account-app.brevo.com/account/login?target=http%3A%2F%2Faccount.brevo.com%2Fadvanced%2Fapi",
    };
    const platformApiLink = {
      "Kiliba Connect": "https://support.kiliba.com/hc/fr/articles/11161564765852-Comment-g%C3%A9n%C3%A9rer-une-cl%C3%A9-API-depuis-Kiliba-",
      Mailchimp: "https://support.kiliba.com/hc/fr/articles/11421765855644-Comment-synchroniser-mes-contacts-blacklist%C3%A9s-Mailchimp-dans-Kiliba",
      Brevo: "https://support.kiliba.com/hc/fr/articles/11421521216796-Comment-synchroniser-mes-contacts-blacklist%C3%A9s-Brevo-dans-Kiliba",
    };

    if (platform in platformLink) {
      setLink(platformLink[platform]);
      setApiLink(platformApiLink[platform]);
    }
    if(secondaryCmsConnected.some(item => item.name.toLowerCase() === platform.toLowerCase())){
      const foundItem = secondaryCmsConnected.find(item => item.name.toLowerCase() === platform.toLowerCase());
      setSecondaryCmsData(foundItem);
    }
  }, [platform,secondaryCmsConnected]);


  
  function connectCms(){
    auth.fetch("/api/account_management/upsertIntegration", {
      method: "POST",
      body: JSON.stringify({
        type: platform.toLowerCase(),
        apiKey,
      }),
    }).then(() => onUpdate());
  }
  function disconnectCms(){
    if (platform === "Kiliba Connect") {
      auth.fetch("/api/account_management/kilibaConnectApiKey", {
        method: "DELETE",
      }).then(() => onUpdate());
    } else {
      auth.fetch("/api/account_management/upsertIntegration", {
        method: "POST",
        body: JSON.stringify({
          type: platform.toLowerCase(),
          apiKey: "",
        }),
      }).then(() => {
        onUpdate();
        EventSystem.newNotification(
          "notification.action",
          intl.messages[`integration.disconnect.${platform.toLowerCase()}.success`]
        );
        platform.toLowerCase() === "mailchimp" ? 
          localStorage.removeItem("mailchimpApiKeyErrorDisplayed") :
          localStorage.removeItem("brevoApiKeyErrorDisplayed");
      });
    }
  }
  function generateApiKey(){
    auth.fetch("/api/account_management/generateKilibaConnectApiKey", {
      method: "POST",
    }).then(() => {
      setIsPopupOpen(false);
      onUpdate(false);
    });
  }
  function syncBrevoMailchimp() {
    const lastStoredClickDate = localStorage.getItem("last_synchronize_button_click_date");
    if (!lastStoredClickDate || (lastStoredClickDate && getDateDeltaInMinutes(new Date(), new Date(lastStoredClickDate)) > 10)) {
      auth.fetch("/api/account_management/syncBrevoMailchimp", {
        method: "GET",
      }).then(() => {
        localStorage.setItem("last_synchronize_button_click_date", (new Date()).toISOString());
      });
    }

    EventSystem.newNotification(
      "notification.action",
      intl.messages["integration.cmsCard.synchrone.success"]
    );
  }
  function changeGoogleSetting(e){
    auth.fetch("/api/account_management/editUtm", {
      method: "POST",
      body: JSON.stringify({
        utm: e.target.value
      })
    }).then(() => onUpdate(false));
    
    setGoogleSetting(e.target.value);
  }
  function isSecondaryCmsRevoked(platform) {
    return !!secondaryCmsRevoked.find(x => x === platform.toLowerCase());
  }
  
  
  function closeCmsPopup(){
    setIsPopupOpen(false);
  }
  function openCmsPopup(params){
    setPopupParams(params);
    setIsPopupOpen(true);
  }


  return (

    <div className={classes.cmsCard}>
      {/* HEADER */}
      <div onClick={() => closeCmsCard()} className={classes.header}>
        <i style={{ marginTop:"4px" ,color:"#3e464f",cursor:"pointer" }} className={"fa-solid fa-chevron-left"}></i>
        <span className={`${classes.text} ${classes.goBack}`}>
          <Translation id="integration.cmsCard.back"/>
        </span>
        {
          secondaryCmsConnected.some(item => item.name === platform.toLowerCase()) &&
            !isLoading ? <CmsConnectingStateBadge isConnected={ isSecondaryCmsRevoked(platform) ? false : true } isSecondaryCMS={true}/> : null
        }
      </div>
      
      <div className={classes.content}>
        {/* TITLE */}
        <div className={classes.flexGrid}>
          <img className={classes.cmsLogo} alt={platform} src={logo} />
          <div className={classes.title}>
            {platform}
            <h4 className={classes.cardTextTitle}>
              {
                secondaryCmsConnected.some(item => item.name === platform.toLowerCase()) ?
                  <Translation id={`integration.cmsCard.${platform}.connected`} />
                  :
                  <Translation id={`integration.cmsCard.${platform}`} />
              }
            </h4>
          </div>
        </div>

        { platform === "Mailchimp" || platform === "Brevo" ?
          !secondaryCmsConnected.some(item => item.name === platform.toLowerCase()) ?
            <div>
              <div className={classes.rounded2}>
                <div className={classes.flexGrid}>
                  <div className={classes.badge}> 1 </div>
                  <div className={classes.title}>
                    <h4 className={classes.cardText}>
                      <Translation id={`integration.cmsCard.${platform}.text1`} /> 
                      <a className={classes.link} target="_blank" rel="noreferrer" href={link}>{`${platform}`}</a>      
                      <Translation id={`integration.cmsCard.${platform}.text2`}/>
                    </h4>    
                  </div>
                </div>
                <div className={classes.flexGrid}>
                  <div className={classes.badge}> 2 </div>
                  <div className={classes.title}>
                    <h4 style={{ marginTop:"7px" }} className={classes.cardText}>
                      <Translation id="integration.cmsCard.text3" />
                    </h4>
                  </div>
                </div>
                <div className={`${classes.flexGrid} ${classes.marginT15}`}>
                  <div></div>
                  <div className={classes.maxWidth}>
                    <KiTextField 
                      placeholder="Ta clé API" 
                      value={apiKey} 
                      handleChange={event => setApiKey(event.target.value)} 
                      textColor="black"> 
                    </KiTextField>
                  </div>
                </div>
                <div className={`${classes.flexCenter} ${classes.footer}`}>
                  <a className={classes.secondaryLink} target="_blank" rel="noreferrer" href={apiLink}>
                    <Translation id="integration.cmsCard.noKey" />
                  </a>     
                </div>
              </div>
              <div className={`${classes.flexCenter} ${classes.marginT40}`}>
                <CustomButton type={"primary"} size="sm" onClick={() => connectCms()}>
                  <Translation id="integration.cmsCard.connect" /> {`${platform}`} 
                </CustomButton>
              </div>
            </div>
            :
            <div className={classes.keyContent}>
              <div className={classes.secondaryFlexGrid}>
                <div style={{ gridArea: "a" }}>
                  <Translation id={"integration.cmsCard.yourKey"}/>
                </div>
                <div style={{ gridArea: "b" ,fontSize:"12px" }}>
                  <Translation id={"integration.cmsCard.createdAt"}/> { secondaryCmsData.createdAt }
                </div>
              </div>
              <div className={classes.marginT20}>
                <KiTextField  
                  disabled={true}
                  type={"password"}
                  placeholder="Ta clé API"
                  value={secondaryCmsData.key} >
                </KiTextField>
              </div>
              <div className={classes.marginT30}>
                <CustomButton  type={"primary"} size="sm" onClick={() => openCmsPopup()} >
                  <Translation id={"integration.cmsCard.disconnect"}/>{platform}
                </CustomButton>
              </div>
              <div className={classes.synchronizeBloc}>
                { !isSecondaryCmsRevoked(platform) ? 
                  <>
                    <Translation id={"integration.cmsCard.synchronize_text"} variables={{platform}}/>
                    <div className={classes.spaceT10}>
                      <CustomButton type={"secondary"} size="sm" onClick={() => syncBrevoMailchimp()} >
                        <Translation id={"integration.cmsCard.synchronize"}/>
                      </CustomButton>
                    </div>
                  </> : null }
              </div>
            </div>
           

          : platform === "Kiliba Connect" ?
            !secondaryCmsConnected.some(item => item.name === platform) ?
              <div className={classes.flex}>
                <h4 className={classes.cardText}>
                  <Translation id={`integration.cmsCard.${platform}.text1`} /> 
                </h4>
                <h3 style={{ marginTop : "25px"}}>
                  <Translation id={`integration.cmsCard.${platform}.text2`} /> 
                </h3>
                <div className={classes.divInput}>
                  <KiTextField  
                    value={kilibaKey}
                    placeholder="La clé du succès ? l'API Kiliba connect !" >
                  </KiTextField> 
                </div>
                <div className={classes.marginT20}>
                  <CustomButton type={"primary"} size="sm" onClick={() => generateApiKey()}>
                    <Translation id="integration.cmsCard.generateKey" />
                  </CustomButton>
                </div>
               
              </div>
              :
              <div className={classes.flex}>
                <h4 className={classes.cardText}>
                  <Translation id={`integration.cmsCard.${platform}.warning`} /> 
                </h4>
                <div style={{ marginTop : "25px"}}>
                  <Translation id={"integration.cmsCard.yourKey"} /> 
                </div>
                <div className={classes.divInput}>
                  <KiTextField 
                    disabled={true}
                    type={["copy","password"]}
                    placeholder="La clé du succès ? l'API Kiliba connect !" 
                    value={secondaryCmsData.key} >
                  </KiTextField>
                </div>
                <div className={classes.marginT20}>
                  <CustomButton style={{ marginRight:20 }} type={"secondary"} size="sm" onClick={() => openCmsPopup("revoqueKilibaKey")}>
                    <Translation id={"integration.cmsCard.revoqueKilibaKey"}/>
                  </CustomButton>
                  <CustomButton type={"primary"} size="sm" onClick={() => openCmsPopup("newKilibaKey")}>
                    <Translation id="integration.cmsCard.generateKilibaKey"/> 
                  </CustomButton>
                </div>
              </div>
            

            
            : platform === "Google Analytics" &&
                <>
                  <div className={classes.flex}>
                    <h4 className={classes.cardText}>
                      <Translation id={`integration.cmsCard.${platform}.text1`} /> 
                    </h4>
                    <Translation id={`integration.cmsCard.${platform}.select`} /> 
                    <div >
                      <RadioGroup customStyle={{ flexDirection:"row" }} value={googleSetting} onChange={(e) => changeGoogleSetting(e)} >
                        <Radio
                          label={<Translation id={`integration.cmsCard.${platform}.select1`}/>}
                          value="byUsecase"
                        />
                        <Radio
                          label={<Translation id={`integration.cmsCard.${platform}.select2`}/>}
                          value="globally"
                        />
                      </RadioGroup>
                     
                    </div>
                  </div>
                
                  {
                    !secondaryCmsConnected.some(item => item.name.toLowerCase() === platform.toLowerCase()) ?
                      <>
                        <div className={`${classes.rounded2} ${classes.marginTop}`}>
                          <div className={classes.flexGrid}>
                            <div className={classes.badge}> 1 </div>
                            <div className={classes.title}>
                              <h4  className={classes.cardText}>
                                <Translation id={`integration.cmsCard.${platform}.text2`} /> 
                                <a className={classes.link} target="_blank" rel="noreferrer" href={link}>{`${platform}`}</a>      
                                <Translation id={`integration.cmsCard.${platform}.text3`}/>
                              </h4>
                        
                            </div>
                          </div>
                          <div className={classes.flexGrid}>
                            <div className={classes.badge}> 2 </div>
                            <div className={classes.title}>
                              <h4 className={classes.cardText}>
                                <Translation id={`integration.cmsCard.${platform}.text4`}/>
                              </h4>
                            </div>
                          </div>
                          <div className={classes.flexGrid}>
                            <div className={classes.badge}> 3 </div>
                            <div className={classes.title}>
                              <h4 className={classes.cardText}>
                                <Translation id={`integration.cmsCard.${platform}.text5`}/>
                              </h4>
                            </div>
                          </div>
                          <div className={classes.flexGrid}>
                            <div></div>
                            <div style={{ height:"40px"}} className={classes.title}>
                              <KiTextField 
                                placeholder="Ta clé API"
                                value={apiKey} >
                              </KiTextField>
                            </div>
                          </div>
                          <div className={`${classes.flexCenter} ${classes.footer}`}>
                            <Translation id="integration.cmsCard.noKey" />
                          </div>
                        </div>
                        <div className={`${classes.flexCenter} ${classes.classes.marginT40}`}>
                          <CustomButton type={"primary"} size="sm" onClick={() => connectCms()}>
                            <Translation id="integration.cmsCard.connect" /> {`${platform}`}
                          </CustomButton>
                        </div>
                      </>
                      : 
                      <>
                        <div className={classes.keyContent}>
                          <div className={classes.secondaryFlexGrid}>
                            <div style={{ gridArea: "a" }}>
                              <Translation id={"integration.cmsCard.yourKey"}/>
                            </div>
                            <div style={{ gridArea: "b" ,fontSize:"12px" }}>
                              <Translation id={"integration.cmsCard.createdAt"}/> { secondaryCmsData.createdAt }
                            </div>
                          </div>
                          <div className={classes.divInput}>
                            <KiTextField  
                              disabled={true}
                              type={["password"]}
                              placeholder="Ta clé API"
                              value={secondaryCmsData.key} >
                            </KiTextField>
                          </div>
                          <div className={classes.marginT30}>
                            <CustomButton type={"primary"} size="sm" onClick={() => openCmsPopup()} >
                              <Translation id={"integration.cmsCard.disconnect"}/>{platform}
                            </CustomButton>
                          </div>
                        </div>
                      </>
                  }      
                </>      
        }
        
        {/* FOOTER */}
        <div className={`${classes.flexCenter} ${classes.cardFooter}`}>
          <a className={classes.helpText}  target="_blank" href={apiLink} rel="noreferrer">
            <Translation id="integration.cmsCard.help" />
          </a>
        </div>

      </div>

      { isPopupOpen && 
        <IntegrationPopup 
          platform={platform}
          closeCmsPopup={closeCmsPopup}
          disconnectCms={disconnectCms}
          popupParams={popupParams}
          generateApiKey={generateApiKey}
        >
        </IntegrationPopup>
      }
      
    </div>
   
  );
};