const LABEL = "title";
const CATEGORY = "text:title";

export const title = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {

    const { fontFamily, textColor, title, backgroundColor, fontSize = 18, sectionPadding = 10, fontWeight = 600, productIds = [] } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${backgroundColor}" padding-top="0" padding-bottom="0" full-width="full-width" data-text-product-ids="${productIds.join(",")}">
        <mj-section padding-top="${sectionPadding}px" padding-bottom="${sectionPadding}px">
          <mj-column>
            <mj-text color="${textColor}" font-family="${fontFamily}" font-weight="${fontWeight}" font-size="${fontSize}px" align="center" line-height="1.3" data-text-id="title">
              {{title}}
            </mj-text>
          </mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    const wordings = {
      title,
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        wordings,
        editableProperties: [
          { key: "texts", type: "generatedTexts", label: "", group: "newsletter.rightMenu.propertyGroup.generatedTexts" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ]
      }
    };
  }
});