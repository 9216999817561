import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";

import { useAuth } from "../../../../hooks/useAuth";
import { Translation } from "../../../common/Translation";
import { Switch } from "../../../common/Input/Switch";
import { CustomButton } from "../../../common/Button/CustomButton";
import { TemplateEditorContext } from "../../TemplateEditorContext.jsx";
import { AccountContext } from "../../../../contexts/context";

import classes from "./AmbientImagesPromptForm.module.scss";

import styleImageIllustration from "../../../../public/ambientImagesPromptForm_style_illustration.png";
import styleImagePhotoRealiste from "../../../../public/ambientImagesPromptForm_style_photorealiste.png";

export const AmbientImagesPromptForm = ({ onRegenerate, isLoading, setAbortController, setNotif }) => {

  const intl = useIntl();
  const auth = useAuth();
  const { account } = useContext(AccountContext);
  const { templateInfo, templateId, setTemplateInfo } = useContext(TemplateEditorContext);

  const [promptImproved, setPromptImproved] = useState(true);
  const [selectedStyles, setSelectedStyles] = useState(account.isDemoAccount ? ["illustration", "photorealistic"]: ["illustration"]);
  const [selectedFormat, setSelectedFormat] = useState("landscape");
  const [isHiddenElementOpened, setIsHiddenElementOpened] = useState(false);
  const [betterPrompt, setBetterPrompt] = useState(templateInfo.manualPrompt.betterPrompt);
  const [showBetterPrompt, setShowBetterPrompt] = useState(false);
  const [promptsHistory, setPromptsHistory] = useState([templateInfo.manualPrompt.originalPrompt]);
  const [promptValue, setPromptValue] = useState(templateInfo.manualPrompt.originalPrompt);
  const [nbrWordsInprompt, setNbrWordsInprompt] = useState(templateInfo.manualPrompt.originalPrompt.split(" ").length);
  const [betterPromptLoading, setBetterPromptLoading] = useState(false);
  const [historyIdx, setHistoryIdx] = useState(0);
  const [negativePromptValue, setNegativePromptValue] = useState(templateInfo.manualPrompt.negativePrompt);
  const [lastUsedPrompt, setLastUsedPrompt] = useState(null);

  const onBetterPromptClick = () => {
    if (!promptValue) {
      return;
    }
    setBetterPromptLoading(true);
    auth.fetch(`/betterPrompt/getBetterPrompt?templateId=${templateId}`, {
      method: "POST",
      body: JSON.stringify({
        prompt: promptValue,
        language: intl.locale,
        negativePrompt: negativePromptValue,
      }),
    }, { endpoint: "llm-hub" }).then(result => {
      setBetterPrompt(result);
      setHistoryIdx(promptsHistory.length);
      setPromptsHistory(prev => [...prev, promptValue]);
      setPromptValue(result);
      setNbrWordsInprompt(result.split(" ").length);
      setShowBetterPrompt(true);
      setBetterPromptLoading(false);
    });
  };

  const onGenerate = () => {
    if (!promptValue) {
      return;
    }

    const abortController = new AbortController();
    setAbortController(abortController);
    
    const stylesQuery = selectedStyles.map((value, index) => `styles[${index}]=${value}`).join("&");
    auth.fetch(`/dalle/regenerateImages?templateId=${templateId}&${stylesQuery}`, {
      signal: abortController.signal,
      method: "POST",
      body: JSON.stringify({
        prompt: promptValue,
        language: intl.locale,
        needsBetterPrompt: promptImproved && promptValue !== betterPrompt,
        negativePrompt: negativePromptValue,
        format: selectedFormat,
        isDemoAccount: account.isDemoAccount
      }),
    }, { endpoint: "llm-hub" })
      .then(result => {
        if (!result.success) {
          setNotif({ value: "ambientImagesPromptForm.notif.error", type: "error"});
        }
      });

    const nbImages = selectedStyles.length * 2;

    // setLastUsedPrompt(promptValue);
    setTemplateInfo(prev => ({ ...prev, manualPrompt: { ...prev.manualPrompt, originalPrompt: promptValue, negativePrompt: negativePromptValue }, nbGeneratedDalleImages: nbImages }));
    onRegenerate(nbImages);
  };

  const onStyleClick = (id) => {
    if (selectedStyles.includes(id)) {
      if (selectedStyles.length > 1) {
        setSelectedStyles(prev => prev.filter(value => value !== id));
      }
    } else {
      setSelectedStyles(prev => [...prev, id]);
    }
  };

  const cancelBetterPrompt = () => {
    const prompt = promptsHistory[promptsHistory.length - 1];
    setHistoryIdx(promptsHistory.length - 2);
    setPromptsHistory(prev => prev.slice(0, -1));
    setPromptValue(prompt);
    setNbrWordsInprompt(prompt.split(" ").length);
    setShowBetterPrompt(false);
  };

  const applyBetterPrompt = () => {
    setHistoryIdx(promptsHistory.length);
    setPromptsHistory(prev => [...prev, promptValue]);
    setBetterPrompt(promptValue);
    setShowBetterPrompt(false);
  };

  const onPrevious = () => {
    if (historyIdx > 0) {
      const prompt = promptsHistory[historyIdx - 1];
      setPromptsHistory(prev => {
        const result = [...prev];
        result[historyIdx] = promptValue;
        return result;
      });
      setPromptValue(prompt);
      setNbrWordsInprompt(prompt.split(" ").length);
      setHistoryIdx(prev => prev - 1);
    }
  };

  const onNext = () => {
    const prompt = promptsHistory[historyIdx + 1];
    setPromptsHistory(prev => {
      const result = [...prev];
      result[historyIdx] = promptValue;
      return result;
    });
    setPromptValue(prompt);
    setNbrWordsInprompt(prompt.split(" ").length);
    setHistoryIdx(prev => prev + 1);
  };

  const _setPromptValue = (value) => {
    if (lastUsedPrompt && promptValue === lastUsedPrompt) {
      setHistoryIdx(promptsHistory.length);
      setPromptsHistory(prev => [...prev, promptValue]);
      setLastUsedPrompt(null);
    }
    setPromptValue(value);
    setNbrWordsInprompt(value.split(" ").length);

    if (account.isDemoAccount && promptValue === "") {
      setPromptValue("Un atelier de designer de mode avec des crayons à dessin des planches d'inspiration, des échantillons de tissus, des croquis détaillés et des outils de dessin, évoquant un espace créatif et sophistiqué.");
    }
  };

  return (
    <div className={classes.container}>
      <div className={`display5 ${classes.title}`}>
        <i className="fa-solid fa-arrows-rotate" />
        <Translation id="ambientImagesPromptForm.title" />
      </div>
      <div className={`infoMedium ${classes.subtitle}`}><Translation id="ambientImagesPromptForm.subtitle" /></div>

      <div className="text"><Translation id="ambientImagesPromptForm.description" /></div>

      <div className={classes.promptContainer}>
        <textarea
          spellCheck="false"
          placeholder={intl.messages["ambientImagesPromptForm.textarea.placeholder"]}
          value={promptValue}
          onChange={event => _setPromptValue(event.target.value)}
        />
        {showBetterPrompt ?
        
          <>
            <div className={classes.betterPromptFormFooter}>
              {promptValue === betterPrompt ?
                <div className={classes.betterPromptBackButton} onClick={cancelBetterPrompt}>
                  <i className="fa-solid fa-chevron-left" />
                  <div className="info"><Translation id="dialog.back" /></div>
                </div>
                :
                <div className={classes.betterPromptButtonsContainer}>
                  <CustomButton
                    type="tertiary"
                    size="xxs"
                    onClick={cancelBetterPrompt}
                  >
                    <Translation id="button.anulation" />
                  </CustomButton>
                  <CustomButton
                    type="primary"
                    size="xxs"
                    onClick={applyBetterPrompt}
                  >
                    <Translation id="button.apply" />
                  </CustomButton>
                </div>
              }
            </div>
          </>
          :
          <>
            <div className={classes.separator} />

            <div className={`info ${classes.deleteTextContainer}`}>
              {historyIdx === promptsHistory.length - 1 && promptsHistory.length > 1 ?
                <div className={classes.previousTextButton} onClick={onPrevious}>
                  <i className="fa-solid fa-rotate-left" />
                  <div className="info"><Translation id="button.previous" /></div>
                </div> 
                : promptsHistory.length > 1 ?
                  <div className={classes.undoRedoContainer}>
                    <i className={`fa-solid fa-rotate-left ${historyIdx === 0 ? classes.iconDisabled : ""}`} onClick={onPrevious} />
                    <i className="fa-solid fa-rotate-right" onClick={onNext} />
                  </div>
                  :
                  <div></div>
              }

              <span className={classes.deleteText} onClick={() => setPromptValue("")}>
                <Translation id="ambientImagesPromptForm.delete" />
              </span>
            </div>

            {promptValue !== betterPrompt && nbrWordsInprompt < 50 &&
              <div className={classes.promptImprovedContainer}>
                <div className={classes.promptImprovedSwitch}>
                  <Switch
                    checked={promptImproved}
                    onChange={event => setPromptImproved(event.target.checked)}
                  />
                  <div className="info"><Translation id="ambientImagesPromptForm.promptImproved" /></div>
                </div>

                {promptValue !== betterPrompt && nbrWordsInprompt < 50 &&
                  <>
                    {betterPromptLoading ? 
                      <div className={classes.betterPromptLoader}><CircularProgress size={15} /></div>:
                      <div className={classes.seePrompt} onClick={onBetterPromptClick}>
                        <div className="info"><Translation id="ambientImagesPromptForm.seePrompt" /></div>
                        <i className="fa-solid fa-chevron-right" />
                      </div>
                    }
                  </>
                }
              </div>
            }
          </>}
      </div>

      <div className={classes.styleTitle}><Translation id="ambientImagesPromptForm.style" /></div>
      <div className={classes.stylesContainer}>
        {[
          { id: "illustration", name: "ambientImagesPromptForm.style.illustration", img: styleImageIllustration },
          { id: "photorealistic", name: "ambientImagesPromptForm.style.photorealistic", img: styleImagePhotoRealiste },
        ].map(elem => (
          <div
            key={elem.name}
            className={`${classes.styleContainer} ${selectedStyles.includes(elem.id) ? classes.itemSelected : ""}`}
            onClick={() => onStyleClick(elem.id)}
          >
            <img alt="" src={elem.img} />
            <div className="info"><Translation id={elem.name} /></div>
          </div>
        ))}
      </div>

      <div className={classes.formatTitle}><Translation id="ambientImagesPromptForm.format" /></div>
      <div className={classes.formatsContainer}>
        {[
          { name: "landscape", style: { width: 90, height: 51.42 }  },
          { name: "portrait", style: { width: 51.42, height: 90 } },
          { name: "square", style: { width: 90, height: 90 } },
        ].map(elem => (
          <div
            key={elem.name}
            className={`${classes.formatContainer} ${selectedFormat === elem.name ? classes.itemSelected : ""}`}
            onClick={() => setSelectedFormat(elem.name)}
          >
            <div className={classes.ratio} style={elem.style}>
              <div className="info"><Translation id={`ambientImagesPromptForm.format.${elem.name}`} /></div>
            </div>
          </div>
        ))}
      </div>

      <div className={classes.buttonContainer}>
        <CustomButton
          type="secondary"
          size="medium"
          loading={isLoading}
          onClick={onGenerate}
        >
          <div className={`textMedium ${classes.changeImageButtonTextColor}`}>
            <i className="fa-solid fa-sparkles" />
            <Translation id="ambientImagesPromptForm.button" />
          </div>
        </CustomButton>
      </div>
    </div>
  );
};