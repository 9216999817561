const LABEL = "button1";
const CATEGORY = "button:button";

export const button1 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {

    const { fontFamily, buttonTextColor, buttonColor, href, buttonText, backgroundColor } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-wrapper background-color="${backgroundColor}" data-gjs="kilibaBlock" padding-top="0" padding-bottom="0" full-width="full-width">
        <mj-section>
          <mj-column>
            <mj-button border-radius="7px" href="{{href}}" font-family="${fontFamily}" padding-left="10px" padding-right="10px" color="${buttonTextColor}" font-size="14px" padding-top="0px" padding-bottom="0px" inner-padding="8px 15px" background-color="${buttonColor}">
              {{button}}
            </mj-button>          
          </mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    const wordings = {
      button: buttonText || {
        fr: "Voir la boutique",
        en: "See the store",
        es: "Ver la tienda",
        de: "Zum Shop",
        nl: "Bekijk de winkel",
        it: "Vedere il negozio",
        pt: "Ver a loja",
      },
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        variables: {
          href
        },
        wordings,
        editableProperties: [
          { key: "href", type: "link", label: "newsletter.rightMenu.property.buttonLink", group: "newsletter.rightMenu.propertyGroup.button" },
          { key: "buttonColor", type: "color", label: "newsletter.rightMenu.property.buttonColor", group: "newsletter.rightMenu.propertyGroup.button" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ]
      }
    };
  }
});