import React, { Component } from "react";
import { withAuth } from "../../../../withAuth";
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

import { styles } from "./StepKey.styles";

class StepKey extends Component {
  render() {
    const { classes, value, status } = this.props;

    return (
      <p className={`${classes.stepKey} ${classes[status]}`}>
        {status === "done" ? <i className="fas fa-check" /> : value + 1}
      </p>
    );
  }
}

const _StepKey = withAuth(withStyles(styles)(injectIntl(StepKey)));
export { _StepKey as StepKey };
