import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import { Translation } from "../../common/Translation";

import { palette } from "../../../styles/palette";

const styles = () => ({
  container: {
    textAlign: "center",
  },
  card: {
    width: 278,
    height: 150,
    padding: "25px 5px 20px 25px",
    borderRadius: 20,
    background: palette.primaryWhite,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  cardIcon: {
    color: palette.primaryYellow200,
    fontSize: 35,
    marginBottom: 10,
  },
  cardTitle: {
    marginBottom: 15,
  },
  cardText: {
    color: palette.neutralDarkGrey,
    textAlign: "left",
  },
});

const useStyles = makeStyles(styles);

export const SubscriptionBoostCards = ({ isTier3 }) => {

  const classes = useStyles();

  const cards = [
    {
      icon: "fa-solid fa-circle-play",
      title: "subscriptionBoostCards.card.title1",
      text: "subscriptionBoostCards.card.text1",
    },
    {
      icon: "fa-solid fa-sparkles",
      title: "subscriptionBoostCards.card.title2",
      text: "subscriptionBoostCards.card.text2",
    },
    {
      icon: "fa-solid fa-users",
      title: "subscriptionBoostCards.card.title3",
      text: "subscriptionBoostCards.card.text3",
    },
    {
      icon: "fa-solid fa-split",
      title: "subscriptionBoostCards.card.title4",
      text: "subscriptionBoostCards.card.text4",
    },
    {
      icon: "fa-solid fa-code",
      title: "subscriptionBoostCards.card.title5",
      text: "subscriptionBoostCards.card.text5",
    },
    {
      icon: "fa-solid fa-user",
      title: "subscriptionBoostCards.card.title6",
      text: "subscriptionBoostCards.card.text6",
    },
    {
      icon: "fa-solid fa-star",
      title: "subscriptionBoostCards.card.title7",
      text: "subscriptionBoostCards.card.text7",
    },
    {
      icon: "fa-solid fa-grid-2-plus",
      title: "subscriptionBoostCards.card.title8",
      text: "subscriptionBoostCards.card.text8",
    },
    ...(!isTier3 ? [{
      icon: "fa-solid fa-user-headset",
      title: "subscriptionBoostCards.card.title9",
      text: "subscriptionBoostCards.card.text9",
    }] : []),
  ];

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >

        {cards.map((card, idx) => (
          <Grid item key={idx}>
            <div className={classes.card}>
              <i className={`${card.icon} ${classes.cardIcon}`} />
              <p className={`textMedium ${classes.cardTitle}`}><Translation id={card.title} /></p>
              <p className={`text ${classes.cardText}`}><Translation id={card.text} /></p>
            </div>
          </Grid>
        ))}

      </Grid>
    </div>
  );
};

