import { useCallback, useEffect, useState } from "react";

import { TextField } from "../common/Input/TextField";
import { Translation } from "../common/Translation";
import { Tooltip } from "../Tooltip.js";
import { extractContentFromPreheaderComponent } from "./grapesJsKiliba/blocks/preheader/index.js";
import { cleanString } from "./rightMenu/GeneratedTextMenu.jsx";

import classes from "./TemplateEditorPreheader.module.scss";

export const PREHEADER_HEIGHT = 60;
export const PREHEADER_HEIGHT_EMPTY = 20;

export const TemplateEditorPreheader = ({ editor, isPreheaderHidden, setIsPreheaderHidden, wordings, language, setWordings }) => {

  const [wordingId, setWordingId] = useState(null);
  const [isSelected, setIsSelected] = useState(false);

  const getPreheaderComponent = useCallback(() => {
    let preheaderComponent = null;

    editor.getComponents().at(0).onAll(component => {
      if (component.attributes.attributes["data-blocklabel"] === "preheader") {
        preheaderComponent = component;
      }
    });

    return preheaderComponent;

  }, [editor]);

  const onUpdate = useCallback(() => {

    const preheaderComponent = getPreheaderComponent();
    
    if (!preheaderComponent) {
      setIsPreheaderHidden(false);
    } else {
      const content = extractContentFromPreheaderComponent(preheaderComponent, wordings, language);
      setWordingId(content.wordingId);
      setIsPreheaderHidden(!content.isVisible);
    }

  }, [getPreheaderComponent, setIsPreheaderHidden, language, wordings]);

  useEffect(() => {
    if (!editor) {
      return ;
    }

    editor.on("update", onUpdate);
    return () => editor.off("update", onUpdate);
  }, [editor, onUpdate]);


  const onComponentSelected = useCallback(component => {
    if (component.attributes.attributes["data-blocklabel"] === "preheader") {
      setIsSelected(true);
    }
  }, []);

  const onComponentDeselected = useCallback(component => {
    if (component.attributes.attributes["data-blocklabel"] === "preheader") {
      setIsSelected(false);
    }
  }, []);

  useEffect(() => {
    if (editor) {
      editor.on("component:selected", onComponentSelected);
      editor.on("component:deselected", onComponentDeselected);
      return () => {
        editor.off("component:selected", onComponentSelected);
        editor.off("component:deselected", onComponentDeselected);
      };
    }
  }, [editor, onComponentSelected, onComponentDeselected]);

  if (!isPreheaderHidden || !wordingId) {
    return (
      <div className={classes.empty}></div>
    );
  }

  const setPreHeader = (value) => {
    setWordings(prevWordings => {
      const clone = { ...prevWordings };
      if (!clone[wordingId]) {
        clone[wordingId] = {};
      }
      clone[wordingId][language] = value;
      return clone;
    });
  };

  const savePreheader = () => {
    const preheaderComponent = getPreheaderComponent();
    
    const textComponents = preheaderComponent.findType("mj-text");

    textComponents.forEach(textComponent => {
      if (textComponent.attributes.attributes["data-text-id"] === "title") {
        textComponent.components(wordings?.[wordingId]?.[language]?.trim());
      }
    });
  };

  const selectPreHeader = () => {
    const preheaderComponent = getPreheaderComponent();
    preheaderComponent && editor.select(preheaderComponent);
  };

  const preheader = cleanString(wordings?.[wordingId]?.[language] || "");

  return (
    <div className={classes.container}>
      <div className={`${classes.preheaderContainer} ${isSelected ? classes.preheaderSelected : ""}`} onClick={selectPreHeader}>
        <div className={classes.preheader}>
          <div className="textMedium"><Translation id="tempalteEditor.preheader" /> :</div>
          <Tooltip title={preheader}>
            <div className={classes.inputContainer}>
              <TextField 
                value={preheader}
                onChange={event => setPreHeader(event.target.value)}
                onBlur={savePreheader}
                size="small"
                isAutoSized
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};