import { palette } from "../../styles/palette.js";

export const styles = (theme) => ({
  card: {
    backgroundColor: palette.white,
    overflow: "hidden",
    borderRadius: "5px",
    width: "170px",
    height: "170px",
    justify: "center",
    position: "relative",
    alignItems: "center",
    flexGrow: 1,
    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
  },
  area: {
    position: "absolute",
    right: "0",
    marginTop: "13px",
    width: "61px",
    height: "31px",
    borderRadius: "3px",
    backgroundColor: palette.primaryYellow200,
    color: palette.white,
  },
  area_text: {
    width: "61px",
    height: "17px",
    fontFamily: "Coolvetica Book",
    fontSize: "11px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 1,
    textAlign: "center",
  },
  title: {
    marginTop: "6px",
    textAlign: "left",
    width: "170px",
    height: "33px",
    fontFamily: "Coolvetica Book",
    fontSize: "12px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 1,
    color: "#0E0E0F",
    textOverflow: "ellipsis",
    overflow: "hidden",
    // whiteSpace: "nowrap", //necessary for text overflow property but it one line
  },
  iOSSwitchBase: {
    "&$iOSChecked": {
      color: theme.palette.common.white,
      "& + $iOSBar": {
        backgroundColor: "#b8e986",
      },
    },
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: "translateX(19px)",
    "& + $iOSBar": {
      opacity: 1,
    },
  },
  iOSBar: {
    borderRadius: "8px",
    width: "35px",
    height: "16px",
    marginTop: "-8px",
    marginLeft: "-15px",
    borderColor: "red",
    backgroundColor: "#ed5f55",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  iOSIcon: {
    width: 14,
    height: 14,
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1],
  },
  tooltip: {
    position: "relative",
    top: "25px",
    width: "fit-content",
    maxWidth: "none",
    whiteSpace: "nowrap",
    fontFamily: "Coolvetica Book",
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.3px",
    textAlign: "center",
    color: "#ffffff",
  },
});
