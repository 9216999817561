export const types = ["mj-section", "mj-wrapper", "mj-hero"];

export const setUpkilibaBlockComponents = (editor) => {
  //Kiliba blocks management
  types.forEach(key => {
    editor.Components.addType(`kilibaBlock-${key}`, {
      extend: key,
      isComponent: el => {
        if (el.tagName?.toLowerCase() === key && el.getAttribute("data-gjs") === "kilibaBlock") {
          return true;
        }
      },
      model: {
        defaults: {
          stylable: [],
          traits: [],
          draggable: false,
          hoverable: true,
          selectable: true,
          removable: true,
          editable: true,
        },
      },    
      view : {
        events: {
          click: "clickOnElement",
        },

        clickOnElement(event) {
          const { em } = this;

          if (this.model.getId() === em.getSelected()?.getId()) {
            em && em.trigger("kilibaBlockClick", { model: this.model, event });
          }
        }
      }
    });
  });
};
