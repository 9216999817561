import { useState, useRef, useMemo, useEffect, useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import ReactCrop, { makeAspectCrop, centerCrop } from "react-image-crop";
import { debounce } from "lodash";

import { WidthHeightInput } from "./RightMenuEditContentInputs/ImageSizeInput";
import { Translation } from "../../common/Translation";
import { CustomButton } from "../../common/Button/CustomButton";

import "react-image-crop/dist/ReactCrop.css";
import classes from "./CropMenu.module.scss";
import { TemplateEditorContext } from "../TemplateEditorContext";

const defaultCropValue = {
  unit: "%",
  x: 25,
  y: 25,
  width: 50,
  height: 50
};

export const useCleanCropPreview = (editMenuInfo, navigationItemSelected, isOpened, editor) => {
  useEffect(() => {
    if (editMenuInfo?.type === "crop" && navigationItemSelected === "content" && isOpened) {
      return ;
    }
  
    editor?.getComponents()?.at(0)?.onAll(component => {
      if (component.is("mj-image")) {
        if (component.getEl().getElementsByTagName("img").length > 1) {
          component.getView().render();
        }
      }
    });

  }, [editMenuInfo, navigationItemSelected, editor, isOpened]);
};

export const CropMenu = ({ goBack, component, onChange, imageComponentIndex }) => {

  const imageRef = useRef();
  const intl = useIntl();
  const [crop, setCrop] = useState(defaultCropValue);
  const [ratio, setRatio] = useState(null);
  const [forcedRatio, setForcedRatio] = useState(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [imageDimension, setImageDimension] = useState(null);
  const [mouseDownFunc, setMouseDownFunc] = useState();
  const [imageUrl, setImageUrl] = useState(null);
  const [isCroppedImage, setIsCroppedImage] = useState(false);
  const [isPreviewInitialized, setIsPreviewInitialized] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const { refreshBlockActionsPosition } = useContext(TemplateEditorContext);

  const ratioList = [
    { name: intl.messages["cropImagePopup.custom"], value: null },
    { name: "1 : 1", value: 1 },
    { name: "4 : 3", value: 4 / 3 },
    { name: "16 : 9", value: 16 / 9 },
  ];

  const imageComponent = useMemo(() => {
    if (component.is("column-component-mj-image")) {
      return component;
    }
    
    const images = component.findType("mj-image");

    if (typeof imageComponentIndex === "number" && images.length > imageComponentIndex) {
      return images[imageComponentIndex];
    }

    return images.length ? images[0] : null;
  }, [component, imageComponentIndex]);

  const refreshActionPosition = useMemo(() => debounce(refreshBlockActionsPosition, 300), [refreshBlockActionsPosition]);

  const previewCrop = useCallback(() => {
    const imgElement = imageComponent.getEl().getElementsByTagName("img")[0];
    const tdElems = imageComponent.getEl()?.getElementsByTagName("td") || [];
    let previewElement = [...imgElement.parentNode.children].find(node => node.id === "previewCrop");
    if (!previewElement) {
      previewElement = document.createElement("div");
      const previewImgElement = document.createElement("img");
      previewElement.append(previewImgElement);

      previewElement.id = "previewCrop";
      previewElement.style.position = "absolute";
      previewElement.style.overflow = "hidden";
      imgElement.style.visibility = "hidden";
      imgElement.src = imageUrl;
      
      previewImgElement.src = imageUrl;

      imgElement.after(previewElement);

      for (const elem of tdElems) {
        elem.style.position = "relative";
        elem.style.overflow = "hidden";
      }
      const parent = imgElement.parentNode;
      parent.style.position = "relative";
      parent.style.overflow = "hidden";
      parent.style.display = "inline-block";
    }

    const previewImgElement = previewElement.getElementsByTagName("img")[0];

    let disableScaleUp = true;
    const styleWidth = getComputedStyle(imgElement).getPropertyValue("max-width");
    let maxWidth = styleWidth.endsWith("px") ? parseInt(getComputedStyle(imgElement).getPropertyValue("max-width")) : NaN;
    let maxHeight = parseInt(getComputedStyle(imgElement).getPropertyValue("max-height"));

    imgElement.style.width = "100%";
    imgElement.style.height = "auto";
    const imgRect = imgElement.getBoundingClientRect();

    if (isNaN(maxWidth) || imgRect.width < maxWidth) {
      if (isNaN(maxWidth)) {
        disableScaleUp = false;
      }
      maxWidth = imgRect.width;
    }

    if (isNaN(maxHeight)) {
      maxHeight = maxWidth * height / width;
    }

    let scale = Math.min(maxWidth / width, maxHeight / height);

    if (scale > 1 && disableScaleUp) {
      scale = 1;
    }

    const newWidth = width * scale;
    const newHeight = height * scale;

    const rect = imageRef.current.getBoundingClientRect();
    const previewWidth = Math.round(newWidth * imageDimension.width / width);
    const previewHeight = previewWidth * imageDimension.height / imageDimension.width;

    let previewLeft, previewTop;

    if (crop.unit === "%") {
      previewLeft = Math.round(crop.x * previewWidth / 100);
      previewTop = Math.round(crop.y * previewHeight / 100);
    } else {
      previewLeft = Math.round(crop.x * previewWidth / rect.width);
      previewTop = Math.round(crop.y * previewHeight / rect.height);
    }

    const divWidth = newWidth + previewLeft;
    const divHeight = newHeight + previewTop;

    previewElement.style.width = `${divWidth}px`;
    previewElement.style.height = `${divHeight}px`;
    previewElement.style.left = `-${previewLeft}px`;
    previewElement.style.top = `-${previewTop}px`;

    previewImgElement.width = previewWidth;
    previewImgElement.style.setProperty("width", `${previewWidth}px`, "important");
    previewImgElement.style.setProperty("max-width", "none", "important");
    previewImgElement.style.setProperty("max-height", "none", "important");

    imgElement.style.setProperty("width", `${Math.min(newWidth, maxWidth)}px`, "important");
    imgElement.style.height = `${Math.min(newHeight, maxHeight)}px`;

    refreshActionPosition();

  }, [imageComponent, imageUrl, imageDimension, width, crop, refreshActionPosition, height]);

  useEffect(() => {
    if (imageDimension) {
      previewCrop();
    }
  }, [imageDimension, previewCrop]);

  useEffect(() => {
    if (imageDimension && !isPreviewInitialized) {
      const timeout = setTimeout(() => {
        previewCrop();
        setIsPreviewInitialized(true);
      }, 300);
      return () => clearTimeout(timeout);
    }
  }, [imageDimension, previewCrop, isPreviewInitialized]);

  const _setCrop = (newCropValue) => {
    const rect = imageRef.current.getBoundingClientRect();

    if (newCropValue.unit === "%") {
      setWidth(Math.round(newCropValue.width * imageDimension.width / 100));
      setHeight(Math.round(newCropValue.height * imageDimension.height / 100));
    } else {
      setWidth(Math.round(newCropValue.width * imageDimension.width / rect.width));
      setHeight(Math.round(newCropValue.height * imageDimension.height / rect.height));
    }
    
    setCrop(newCropValue);
  };

  const _setWidth = useCallback((newWidth) => {
    const rect = imageRef.current.getBoundingClientRect();

    const cropX = crop.unit === "%" ? crop.x * rect.width / 100 : crop.x;
    const cropY = crop.unit === "%" ? crop.y * rect.height / 100 : crop.y;
    const _cropX = Math.round(cropX * imageDimension.width / rect.width);    
    const _cropY = Math.round(cropY * imageDimension.height / rect.height);

    if (_cropX + newWidth > imageDimension.width) {
      newWidth = imageDimension.width - _cropX;
    }
    if (newWidth < 1) {
      newWidth = 1;
    }

    const _ratio = ratio || forcedRatio;

    const newHeight = _ratio ? Math.round(newWidth / _ratio) : height;
    if (_ratio && (_cropY + newHeight > imageDimension.height || newHeight < 1)) {
      return ;
    }

    setWidth(newWidth);
    setHeight(newHeight);
    setCrop({
      unit: "px",
      x: cropX,
      y: cropY,
      width: Math.round(newWidth * rect.width / imageDimension.width),
      height: Math.round(newHeight * rect.height / imageDimension.height),
    });

  }, [imageDimension, height, crop, ratio, forcedRatio]);

  const _setHeight = useCallback((newHeight) => {
    const rect = imageRef.current.getBoundingClientRect();

    const cropX = crop.unit === "%" ? crop.x * rect.width / 100 : crop.x;
    const cropY = crop.unit === "%" ? crop.y * rect.height / 100 : crop.y;

    const _cropX = Math.round(cropX * imageDimension.width / rect.width);    
    const _cropY = Math.round(cropY * imageDimension.height / rect.height);

    if (_cropY + newHeight > imageDimension.height) {
      newHeight = imageDimension.height - _cropY;
    }
    if (newHeight < 1) {
      newHeight = 1;
    }

    const _ratio = ratio || forcedRatio;

    const newWidth = _ratio ? Math.round(newHeight * _ratio) : width;
    if (_ratio && (_cropX + newWidth > imageDimension.width || newWidth < 1)) {
      return ;
    }

    setHeight(newHeight);
    setWidth(newWidth);
    setCrop({
      unit: "px",
      x: cropX,
      y: cropY,
      width: Math.round(newWidth * rect.width / imageDimension.width),
      height: Math.round(newHeight * rect.height / imageDimension.height),
    });

  }, [imageDimension, width, crop, ratio, forcedRatio]);

  useEffect(() => {
    if (mouseDownFunc) {
      const interval = setInterval(() => {

        if (mouseDownFunc.elem === "width") {
          _setWidth(width + mouseDownFunc.value);
        }
        if (mouseDownFunc.elem === "height") {
          _setHeight(height + mouseDownFunc.value);
        }

      }, 100);
      return () => clearInterval(interval);
    }
  }, [mouseDownFunc, width, _setWidth, _setHeight, height]);

  const onMouseUp = useCallback(() => {
    if (mouseDownFunc) {
      if (mouseDownFunc.elem === "width") {
        _setWidth(width + mouseDownFunc.value);
      }
      if (mouseDownFunc.elem === "height") {
        _setHeight(height + mouseDownFunc.value);
      }
    }
    setMouseDownFunc(null);
  }, [mouseDownFunc, width, height, _setHeight, _setWidth]);

  useEffect(() => {
    document.addEventListener("mouseup", onMouseUp);
    return () => document.removeEventListener("mouseup", onMouseUp);
  });

  const onRatioChange = (value) => {
    const rect = imageRef.current.getBoundingClientRect();

    if (value) {
      _setCrop(centerCrop(makeAspectCrop({
        unit: "%",
        width: 50,
      }, value, rect.width, rect.height), rect.width, rect.height));
      setIsLocked(true);
    } else {
      setIsLocked(false);
    }

    setRatio(value);
    setForcedRatio(null);    
  };  

  const onConfirm = () => {
    const rect = imageRef.current.getBoundingClientRect();
    const base64Url = btoa(imageUrl);
    
    const finalCropValue = {};
    if (crop.unit === "%") {
      finalCropValue.x = Math.round(crop.x * imageDimension.width / 100);
      finalCropValue.y = Math.round(crop.y * imageDimension.height / 100);
      finalCropValue.width = Math.round(crop.width * imageDimension.width / 100);
      finalCropValue.height = Math.round(crop.height * imageDimension.height / 100);
    } else {
      finalCropValue.x = Math.round(crop.x * imageDimension.width / rect.width);
      finalCropValue.y = Math.round(crop.y * imageDimension.height / rect.height);
      finalCropValue.width = Math.round(crop.width * imageDimension.width / rect.width);
      finalCropValue.height = Math.round(crop.height * imageDimension.height / rect.height);
    }

    const domain = process.env.REACT_APP_ENV === "production" ? "production" : "preprod";
    const finalUrl = `https://${domain}.cdn.kiliba.eu/files/${base64Url}/get/full/${finalCropValue.x}/${finalCropValue.y}/${finalCropValue.width}/${finalCropValue.height}`;
    onChange(finalUrl);
  };

  useEffect(() => {
    if (!imageUrl && imageComponent) {
      let src = imageComponent?.attributes.src;
      const url = new URL(src);
      if (url.host.endsWith("cdn.kiliba.eu")) {
        const splitted = url.pathname.split("/").filter(elem => !!elem);
        if (splitted[0] === "files" && splitted.length === 8) {
          src = atob(splitted[1]);
          setIsCroppedImage(true);
        }
      }

      setImageUrl(src);
    };
  }, [imageComponent, imageUrl]);

  const onLoad = () => {
    setImageDimension({
      width: imageRef.current.naturalWidth,
      height: imageRef.current.naturalHeight,
    });

    if (isCroppedImage) {
      const src = imageComponent?.attributes.src;
      const url = new URL(src);
      const splitted = url.pathname.split("/").filter(elem => !!elem);

      const cropValue = {
        unit: "%",
        x: +splitted[4] * 100 / imageRef.current.naturalWidth,
        y: +splitted[5] * 100 / imageRef.current.naturalHeight,
        width: +splitted[6] * 100 / imageRef.current.naturalWidth,
        height: +splitted[7] * 100 / imageRef.current.naturalHeight,
      };

      setCrop(cropValue);
      setWidth(Math.round(imageRef.current.naturalWidth * cropValue.width / 100));
      setHeight(Math.round(imageRef.current.naturalHeight * cropValue.height / 100));
    } else {
      setWidth(Math.round(imageRef.current.naturalWidth * defaultCropValue.width / 100));
      setHeight(Math.round(imageRef.current.naturalHeight * defaultCropValue.height / 100));
    }
  };

  const onIconClick = () => {
    if (!isLocked) {
      if (crop.unit === "px") {
        setForcedRatio(crop.width / crop.height);
      } else {
        const rect = imageRef.current.getBoundingClientRect();
        setForcedRatio(Math.round(crop.width * rect.width / 100) / Math.round(crop.height * rect.height / 100));
      }
      setIsLocked(true);
    } else {
      setForcedRatio(null);
      setRatio(null);
      setIsLocked(false);
    }
  };

  if (!imageUrl) {
    return ;
  }

  return (
    <div className={classes.container}>
      {!!goBack &&
        <div
          className={classes.closeString}
          onClick={goBack}
          data-cy={"close-string"}
        >
          <i className="fa-solid fa-angle-left"></i>
          <h4><Translation id="dialog.back" /></h4>
        </div>
      }
      <div className={classes.separator} />

      <div className={classes.headPart} >
        <h3 className={classes.title}>
          <Translation id="cropMenu.title" />
        </h3>
        <div className={classes.buttonsContainer}>
          <CustomButton 
            type="secondary"
            size="md"
            onClick={goBack}
          >
            <Translation id="button.anulation" />
          </CustomButton>
          <CustomButton 
            size="md"
            onClick={onConfirm}
          >
            <Translation id="button.confirm" />
          </CustomButton>
        </div>
      </div>

      <div className={`${classes.cropAreaContainer} ${isLocked ? classes.cropAreaContainerLocked : ""}`}>
        <ReactCrop crop={crop} onChange={c => _setCrop(c)} aspect={ratio || forcedRatio} keepSelection>
          <img draggable={false} ref={imageRef} alt="" src={imageUrl} onLoad={onLoad} />
        </ReactCrop>
      </div>
      <div className={classes.centeredContainer}>
        <div className={classes.formContainer}>
          <div className="textMedium"><Translation id="cropImagePopup.formats" /></div>
          <div className={classes.ratiosContainer}>
            {ratioList.map(elem => (
              <div
                key={elem.value}
                className={`${classes.ratio} ${ratio === elem.value ? classes.ratioSelected : ""}`}
                style={{ height: 70 / (elem.value || 1) }} 
                onClick={() => onRatioChange(elem.value)} 
              >
                {elem.name}
              </div>
            ))}
          </div>
          <WidthHeightInput 
            width={width}
            height={height}
            type="px"
            setWidth={_setWidth}
            setHeight={_setHeight}
            setMouseDownFunc={setMouseDownFunc}
            icon={isLocked ? "fa-regular fa-lock" : "fa-regular fa-unlock"}
            onIconClick={onIconClick}
          />
        </div>
      </div>
    </div>
  );
};
