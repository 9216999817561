import { useState, createContext } from "react";
import { useWordingProposals } from "./hooks/useWordingProposals";
import { useNewsletterProducts } from "./hooks/useNewsletterProducts";
import { useNewsletterPromoCode } from "./hooks/useNewsletterPromoCode";
import { useRightMenu } from "./hooks/useRightMenu";
import { useNewsletterBlockActions } from "./hooks/useNewsletterBlockActions";
import { useEditorCssVariables } from "./hooks/useEditorCssVariables";

export const TemplateEditorContext = createContext({});

export const TemplateEditorContextProvider = ({ children, templateId }) => {

  const [language, setLanguage] = useState();
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const wordingProposalsValues = useWordingProposals(templateId, language);
  const productsValues = useNewsletterProducts(templateId);
  const promoCodeValues = useNewsletterPromoCode(templateId);
  const blockActionValues = useNewsletterBlockActions();
  const [variables, setVariables] = useState({});
  const [templateInfo, setTemplateInfo] = useState({});
  const rightMenuValues = useRightMenu();
  const editorCssVariablesValues = useEditorCssVariables();

  return (
    <TemplateEditorContext.Provider value={{
      ...wordingProposalsValues,
      ...productsValues,
      ...promoCodeValues,
      ...rightMenuValues,
      ...editorCssVariablesValues,
      ...blockActionValues,
      isFirstLoading,
      setIsFirstLoading,
      variables,
      setVariables,
      language,
      setLanguage,
      templateInfo,
      setTemplateInfo,
      templateId,
    }}>
      { children }
    </TemplateEditorContext.Provider>
  );
};