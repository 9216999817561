import { useIntl } from "react-intl";
import { TextField } from "../../../../common/Input/TextField";
import { CustomButton } from "../../../../common/Button/CustomButton";
import { useAuth } from "../../../../../hooks/useAuth";
import { EventSystem } from "../../../../../eventsystem/EventSystem";
import arrow from "../../../../../public/presta-arrow.png";


import classes from "./PrestashopDialog.module.scss";

export const PrestashopForm = ({ onSubmit = () => {}, loading = false }) => {
  const intl = useIntl();
  const auth = useAuth();


  const onCopyToClipboard = () => {
    auth.sendEventToAmplitude("prestashop_connection.click_copy_id");
    EventSystem.newNotification("notification.action", intl.messages["editProfile.notif.id_account"]);
  };

  return (

    <div className={classes.formContainer}>
        <div className={classes.steps}>
          <div className={classes.step1}>
            <div className={classes.step}>
              <div className={`${classes.circle} textBold`}>1</div>
              <span className="text">{intl.messages["onBoarding.chooseCms.prestashop.step1"]}</span>
            </div>
            <div className={classes.copy}>
              <TextField
                value={auth.getAccountId()}
                type="copy"
                onCopyClick={onCopyToClipboard}
                onChange={() => { }}
              />
            </div>
            <img alt="" src={arrow} className={classes.arrowImg} />
          </div>

          <div className={classes.step}>
            <div className={`${classes.circle} textBold`}>2</div>
            <span className="text">{intl.messages["onBoarding.chooseCms.prestashop.step2"]}</span>
          </div>

          <div className={classes.step}>
            <div className={`${classes.circle} textBold`}>3</div>
            <span className="text">{intl.messages["onBoarding.chooseCms.prestashop.step3"]}</span>
          </div>

          <span className={classes.link}>
            <a
              onClick={() => auth.sendEventToAmplitude("prestashop_connection.click_download_kiliba_on_my_shop")}
              href="https://addons.prestashop.com/fr/newsletter-sms/47363-kiliba.html"
              rel="noreferrer"
              target="_blank"
              className="link"
            >
              {intl.messages["onBoarding.chooseCms.prestashop.link"]}
            </a>
          </span>
        </div>

        <h4>{intl.messages["onBoarding.chooseCms.prestashop.text"]}</h4>

        <div className={classes.buttonContainer}>
          <CustomButton
            onClick={() => {
              auth.sendEventToAmplitude("prestashop_connection.click_start");
              onSubmit("prestashop");
            }}
            loading={loading}
          >
            {intl.messages["button.endform"]}
          </CustomButton>
        </div>
      </div>
  );
};
