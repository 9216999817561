import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { NoCms } from "./NoCms.js";

import noCmsProductsBackground from "../../../../src/public/background_no_cms_products.png";
import noCmsCustomersBackground from "../../../../src/public/background_customers_no_cms.png";
import noCmsOrdersBackground from "../../../../src/public/background_no_cms_orders.png";
import noCmsStatsBackground from "../../../../src/public/background_noCms.png";
import noDataBackground from "../../../public/no-data.png";
import noUsecasesBackground from "../../../public/emptyStateUsecases.png";
import syncNotDoneCustomersBackground from "../../../../src/public/cmsLoadingContact.png";
import syncNotDoneOrdersBackground from "../../../../src/public/cmsLoadingOrder.jpg";

import { Translation } from "../Translation.js";
import { palette } from "../../../styles/palette.js";
import PropTypes from "prop-types";

const styles = (theme) => ({
  emptyState: {
    fontSize: "12px",
    color: palette.neutralDarkGrey,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
  },
  image: {
    height: 200,
    marginBottom: 25,
  },
  description: {
    whiteSpace: "pre-line",
    textAlign: "center",
    marginBottom: 15,
  },
});

const useStyle = makeStyles(styles);

export function EmptyState(props) {
  const classes = useStyle();

  const emptyStateParametersList = {
    syncActiveCustomers: {
      image: { alt: "Customers are being synchronized", src: syncNotDoneCustomersBackground, },
      description: "syncNotDone.description.loadingCustomers",
    },
    noCmsCustomers: {
      image: { alt: "Background no CMS", src: noCmsCustomersBackground, },
      description: "noCms.description.customers",
      Wrapper: NoCms,
    },
    noCmsLogo: {
      description: "noCms.description.logo",
      Wrapper: NoCms,
    },
    noCmsEmailPreview: {
      description: "noCms.description.emailPreview",
      Wrapper: NoCms,
    },
    syncActiveProducts: {
      image: { alt: "Products are being synchronized", src: syncNotDoneCustomersBackground, },
      description: "syncNotDone.description.loadingProducts",
    },
    noCmsProducts: {
      image: { alt: "No CMS, no product", src: noCmsProductsBackground, },
      description: "noCms.description.products",
      Wrapper: NoCms,
    },
    syncActiveOrders: {
      image: { alt: "No order yet", src: syncNotDoneOrdersBackground, },
      description: "syncNotDone.description.loadingOrders",
    },
    noCmsOrders: {
      image: { alt: "No CMS, no order", src: noCmsOrdersBackground, },
      description: "noCms.description.orders",
      Wrapper: NoCms,
    },
    noCmsStats: {
      image: { alt: "No CMS, no stats", src: noCmsStatsBackground, },
      description: "noCms.description.statistics",
      Wrapper: NoCms,
    },
    noData: {
      image: { alt: "No data yet", src: noDataBackground, },
      description: "table.noData",
    },
    noUsecases: {
      image: { alt: "", src: noUsecasesBackground, },
      description: "noUsecasesBackground.description",
    },
    loading: {
      description: "table.loading",
    },
  };

  const emptyStateParameters = emptyStateParametersList[props.variant];
  const Wrapper = emptyStateParameters.Wrapper || Fragment;
  const emptyStateBody = (
    <Wrapper>
      {
        emptyStateParameters.image
          ? (
            <img
              alt={emptyStateParameters.image.alt}
              src={emptyStateParameters.image.src}
              className={classes.image}
            />
          )
          : null
      }
      <span className={`${classes.description} text`}>
        <Translation id={emptyStateParameters.description} />
      </span>
    </Wrapper>
  );

  return (
    <div className={classes.emptyState}>
      {emptyStateBody}
    </div>
  );
}

EmptyState.propTypes = {
  variant: PropTypes.oneOf([
    "syncActiveCustomers",
    "noCmsCustomers",
    "syncActiveProducts",
    "noCmsProducts",
    "syncActiveOrders",
    "noCmsOrders",
    "noCmsStats",
    "noData",
    "loading",
    "noUsecases"
  ]).isRequired,
};

EmptyState.defaultProps = {};
