import React from "react";
import { SwitchBase } from "../SwitchBase.js";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { styles } from "./Radio.styles.js";

const useStyles = makeStyles(styles);

export function Radio(props) {
	const classes = useStyles();

	return (
		<div className={`${classes.radio} ${props.legacy ? classes.legacy : ""}`}>
			<SwitchBase
				type="radio"
				dataCy={props.dataCy}
				value={props.value}
				disabled={props.disabled}
				disabledExplanation={props.disabledExplanation}
				warningText={props.warningText}
				helperText={props.helperText}
				label={props.label}
				style={props.style}
			/>
		</div>
	);
}

Radio.propTypes = {
	dataCy: PropTypes.string,
	value: PropTypes.any.isRequired,
	disabled: PropTypes.bool,
	disabledExplanation: PropTypes.string,
	warningText: PropTypes.string,
	helperText: PropTypes.string,
	label: PropTypes.string,
	style: PropTypes.object,
	legacy: PropTypes.bool,
};

Radio.defaultProps = {
	value: "",
	disabledExplanation: "",
	warningText: "",
	helperText: "",
	label: "",
	legacy: false,
};
