const LABEL = "logo1";
const CATEGORY = "logo:subLogo";

export const logo1 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {

    const { logoUrl, href, backgroundColor, alt, width = 200 } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${backgroundColor}" padding-top="0" padding-bottom="0" full-width="full-width">
        <mj-section>
          <mj-column>
            <mj-image href="{{href}}" alt="{{alt}}" align="left" src="{{logoUrl}}" width="${width}px"></mj-image>
          </mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        variables: {
          logoUrl,
          href,
          alt,
        },
        editableProperties: [
          { key: "logoUrl", type: "logo", label: "optionalMenu.category.myImages", group: "newsletter.rightMenu.propertyGroup.image" },
          { key: "width", type: "imageSize", label: "newsletter.rightMenu.property.imageSize", options: { disableFullSize: true } },
          { key: "href", type: "link", label: "newsletter.rightMenu.property.imageLink", group: "newsletter.rightMenu.propertyGroup.image" },
          { key: "alt", type: "string", label: "newsletter.rightMenu.property.alt", group: "newsletter.rightMenu.propertyGroup.image" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ]
      }
    };
  }
});