import { setUpComponents } from "./components";
import { setUpBlocks } from "./blocks";
import { setUpCssRules } from "./cssRules";

export const plugin = async (editor, options) => {
  setUpComponents(editor);
  setUpCssRules(editor);
  await setUpBlocks(editor, options);  

  options.onInitialized();
};
