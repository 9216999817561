import { subtitle } from "../texts/subtitle";
import { product4 } from "../products/product4";
import { makeTranslatable } from "../utils";

const LABEL = "body3";

export const body3 = ({ fontFamily, textColor, accentColor, generatedWordings, dominantColor, product, buttonTextColor, currency, priceIsVisible, currencyBefore }) => ({
  label: LABEL,
  block: () => {

    let productHref = product.absolute_url;
    if (!productHref?.startsWith("http")) {
      productHref = `https://${productHref}`;
    }

    const subtitleBlock = subtitle({ fontFamily, textColor, accentColor, backgroundColor: dominantColor, title: generatedWordings.title, subtitle: generatedWordings.subtitle }).block();
    const productBlock = product4({ product, fontFamily, buttonTextColor, buttonColor: accentColor, textColor, backgroundColor: dominantColor, imageUrl: makeTranslatable(product.image_url), imageHref: makeTranslatable(productHref), imageAlt: makeTranslatable(""), buttonHref: makeTranslatable(productHref), currency, priceIsVisible, currencyBefore }).block();

    const mjml = `
      ${subtitleBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${subtitleBlock.label}"`)}
      ${productBlock.content.replaceAll("data-gjs=\"kilibaBlock\"", `data-gjs="kilibaBlock" data-blocklabel="${productBlock.label}"`)}
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: "body:subBody",
      activate: true,
      content: mjml,
      attributes: {
        wordings: {
          ...subtitleBlock.attributes.wordings,
          ...productBlock.attributes.wordings,
        },
        variables: productBlock.attributes.variables
      }
    };
  },
});