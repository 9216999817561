import { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { injectIntl } from "react-intl";

import { TextField } from "../../common/Input/TextField.js";
import { FormButtonTO } from "../../FormButtonTO.jsx";
import { Translation } from "../../common/Translation.js";

const styles = (theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  header: {
    padding: "64px 0",
    display: "flex",
    alignItems: "center",
  },
  headerLogo: {
    display: "block",
    maxWidth: "80%",
    maxHeight: "calc(200px + 10vw)",
    margin: "auto",
  },
  content: {
    padding: "64px 0 32px 0",
    flex: 1,
  },
  contentTitle: {
    fontSize: 32,
  },
  contentPromoCodes: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  contentPromoCode: {
    width: 256,
    marginTop: 32,
    "&:not(&:last-child)": {
      marginRight: "15vw",
      [theme.breakpoints.down("xs")]: {
        marginRight: "initial",
      },
    },
  },
  contentPromoCodeValue: {
    fontSize: 64,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  contentPromoCodeDescription: {
    fontSize: 16,
  },
  contentForm: {
    margin: "32px auto 64px auto",
    maxWidth: 362,
    "& div": {
      marginBottom: 2,
    },
  },
  contentFormErrored: {
    fontSize: 10,
    color: "red",
  },
});

export const SponsorshipLandingContent = withStyles(styles)(
  injectIntl(
    class extends Component {
      render() {
        const {
          classes,
          accountEmailSettings,
          promoCodeTemplates,
          godsonEmailAddresses,
          hasSubmitted,
          hasErrored,
          setGodsonEmail,
          submit,
        } = this.props;

        return (
          <div
            id="sponsorshipLandingContent"
            className={classes.wrapper}
            style={{
              fontFamily: accountEmailSettings.fontFamily,
              color: accountEmailSettings.textColor,
            }}
          >
            <div
              className={classes.header}
              style={{ backgroundColor: accountEmailSettings.headerColor }}
            >
              <img
                className={classes.headerLogo}
                src={accountEmailSettings.logoUrl}
                alt="brand logo"
              />
            </div>

            <div
              className={classes.content}
              style={{ backgroundColor: accountEmailSettings.dominantColor }}
            >
              {hasSubmitted ? (
                <SponsorshipLandingContentSubmitted />
              ) : (
                <SponsorshipLandingContentForm
                  promoCodeTemplates={promoCodeTemplates}
                  hasErrored={hasErrored}
                  godsonEmailAddresses={godsonEmailAddresses}
                  setGodsonEmail={setGodsonEmail}
                  accountEmailSettings={accountEmailSettings}
                  submit={submit}
                />
              )}
            </div>
          </div>
        );
      }
    }
  )
);

const SponsorshipLandingContentSubmitted = withStyles(styles)(
  injectIntl(
    class extends Component {
      render() {
        const { classes, intl } = this.props;

        return (
          <div className={classes.contentSubmitted} id="submitted">
            <h2 className={classes.contentSubmittedTitle}>
              {intl.messages["sponsorshipLanding.submitted"]}
            </h2>
          </div>
        );
      }
    }
  )
);

const SponsorshipLandingContentForm = withStyles(styles)(
  injectIntl(
    class extends Component {
      render() {
        const {
          classes,
          intl,
          promoCodeTemplates,
          hasErrored,
          godsonEmailAddresses,
          setGodsonEmail,
          accountEmailSettings,
          submit,
        } = this.props;

        return (
          <div>
            <h2 className={classes.contentTitle}>
              {
                intl.messages[
                  accountEmailSettings.tone === "decale"
                    ? "sponsorshipLanding.title"
                    : "sponsorshipLanding.titlePolite"
                ]
              }
            </h2>

            <div className={classes.contentPromoCodes}>
              <div className={classes.contentPromoCode}>
                <p
                  className={classes.contentPromoCodeValue}
                  style={{ color: accountEmailSettings.accentColor }}
                >
                  -{promoCodeTemplates[0].value}
                  {promoCodeTemplates[0].type}
                </p>
                <p className={classes.contentPromoCodeDescription}>
                  <Translation
                    id={
                      accountEmailSettings.tone === "decale"
                        ? "sponsorshipLanding.sponsorRewardDescription"
                        : "sponsorshipLanding.sponsorRewardDescriptionPolite"
                    }
                  />
                </p>
              </div>
              <div className={classes.contentPromoCode}>
                <p
                  className={classes.contentPromoCodeValue}
                  style={{ color: accountEmailSettings.accentColor }}
                >
                  -{promoCodeTemplates[1].value}
                  {promoCodeTemplates[1].type}
                </p>
                <p className={classes.contentPromoCodeDescription}>
                  <Translation
                    id={
                      accountEmailSettings.tone === "decale"
                        ? "sponsorshipLanding.godsonRewardDescription"
                        : "sponsorshipLanding.godsonRewardDescriptionPolite"
                    }
                  />
                </p>
              </div>
            </div>
            <div className={classes.contentForm}>
              {hasErrored ? (
                <div className={classes.contentFormErrored} id="errored">
                  <h2 className={classes.contentFormErroredTitle}>
                    {intl.messages["sponsorshipLanding.errored"]}
                  </h2>
                </div>
              ) : null}
              {godsonEmailAddresses.map((godsonEmail, key) => (
                <TextField
                  key={key}
                  value={godsonEmail}
                  type="email"
                  onChange={(event) => setGodsonEmail(event.target.value, key)}
                ></TextField>
              ))}
              <FormButtonTO
                type={"primary"}
                style={{
                  marginTop: 64,
                  backgroundColor: accountEmailSettings.accentColor,
                  color: accountEmailSettings.buttonTextColor
                }}
                label={intl.messages["sponsorshipLanding.submit"]}
                action={submit}
              />
            </div>
          </div>
        );
      }
    }
  )
);
