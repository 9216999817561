import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { RadioGroup } from "../../../common/RadioGroup.js";
import { Radio } from "../../../common/Input/Radio/Radio.js";
import { InputSection } from "../../../common/Input/InputSection.js";


export const UTMParameters = injectIntl(
  class UTMParameters extends Component {
    render() {
      const { intl, UTMIsEnabled, setUTMIsEnabled } = this.props;

      return (
        <InputSection
          title={intl.messages["templateCustomization.google_analytics"]}
          titleStyle={{ fontFamily: "Coolvetica Book", fontSize: 14 }}
          helperText={
            intl.messages["templateCustomization.google_analytics.informations"]
          }
        >
          <RadioGroup
            row
            helperText={
              intl.messages["templateCustomization.google_analytics.informations"]
            }
            value={UTMIsEnabled}
            onChange={event => setUTMIsEnabled(event.target.value)}
          >
            <Radio legacy
              value="false"
              label={intl.messages["templateCustomization.google_analytics.off"]}
            />
            <Radio legacy
              value="globally"
              label={intl.messages["templateCustomization.google_analytics.globally"]}
            />
            <Radio legacy
              value="byUsecase"
              label={intl.messages["templateCustomization.google_analytics.by_usecase"]}
            />
          </RadioGroup>
        </InputSection>
      );
    }
  }
);
