import { useState } from "react";
import { Translation } from "../../common/Translation";
import { Dialog } from "../../common/Dialog.js";
import classes from "./Integration.module.scss";
import { CustomButton } from "../../common/Button/CustomButton.js";


export const IntegrationPopup = ({platform,closeCmsPopup,disconnectCms,popupParams,generateApiKey}) => {

  const [isConnected, setIsConnected] = useState(true);

  function callDisconnectCms(){
    setIsConnected(false);
    callClosePopup();
  }
  function callClosePopup(){
    disconnectCms();
  }

  return (

    <Dialog closeStyle={null} PaperProps={{ style: { width:"660px", borderRadius:"30px"} }} isOpen={true} >
      <div className={classes.flexPopup}>
        {
          platform === "Brevo" || platform === "Mailchimp" ?
            <>
              <span className={classes.popupTitle}>
                <Translation  id={"integration.cmsPopup.title"}/>{platform} ?
              </span>
              <span className={classes.popupText}>
                <Translation id={"integration.cmsPopup.text1"}/>
                {platform},
                <Translation id={"integration.cmsPopup.text2"}/>
              </span>
              <div className={classes.popupButton}>
                <CustomButton style={{ marginRight:"25px" }} type={"secondary"} size="lg" onClick={() => closeCmsPopup()} >
                  <Translation id="button.anulation"/> 
                </CustomButton>
                <CustomButton type={"primary"} size="lg" onClick={() => callDisconnectCms()} >
                  <Translation id="integration.cmsCard.disconnect"/> {platform}
                </CustomButton>
              </div> 
            </>

            : platform === "Kiliba Connect" ?
              popupParams === "revoqueKilibaKey" ?
                <>
                  <span className={classes.popupTitle}>
                    <Translation id={`integration.cmsCard.${platform}.revoqueKeyTitle`}/>
                  </span>
                  <span className={classes.popupText}>
                    <Translation id={`integration.cmsCard.${platform}.revoqueKeyText`}/>
                  </span>
                  <div className={classes.popupButton}>
                    <CustomButton style={{ marginRight:"25px" }} type={"secondary"} size="lg" onClick={() => closeCmsPopup()} >
                      <Translation id="button.anulation"/> 
                    </CustomButton>
                    <CustomButton type={"primary"} size="lg" onClick={() => callDisconnectCms()} >
                      <Translation id={`integration.cmsCard.${platform}.revoqueKeyButton`}/>
                    </CustomButton> 
                  </div> 
                </>
                : popupParams === "newKilibaKey" &&
                <>
                  <span className={classes.popupTitle}>
                    <Translation id={`integration.cmsCard.${platform}.newKeyTitle`}/>
                  </span>
                  <span className={classes.popupText}>
                    <Translation id={`integration.cmsCard.${platform}.newKeyText`}/>
                  </span>
                  <div className={classes.popupButton}>
                    <CustomButton style={{ marginRight:"25px" }} type={"secondary"} size="lg" onClick={() => closeCmsPopup()} >
                      <Translation id="button.anulation"/> 
                    </CustomButton>
                    <CustomButton type={"primary"} size="lg" onClick={() => generateApiKey()} >
                      <Translation id={`integration.cmsCard.${platform}.newKeyButton`}/>
                    </CustomButton> 
                  </div> 
                </>

              : platform === "GoogleAnalytics" &&
                isConnected ?
                <>
                  <span className={classes.popupTitle}>
                    <Translation  id={"integration.cmsPopup.disconnect.text1"}/>
                    {platform} 
                    <Translation  id={"integration.cmsPopup.disconnect.text2"}/>
                  </span>
                  <span className={classes.popupText}>
                    <Translation  id={"integration.cmsPopup.disconnect.text3"}/>
                    {platform} 
                    <Translation  id={"integration.cmsPopup.disconnect.text4"}/>
                  </span>
                  <div className={classes.popupButton}>
                    <CustomButton style={{ marginRight:"25px" }} type={"secondary"} size="lg" onClick={() => closeCmsPopup()} >
                      <Translation id="button.next"/> 
                    </CustomButton>
                    <CustomButton type={"primary"} size="lg" onClick={() => closeCmsPopup()} >
                      <Translation  id={"integration.cmsPopup.disconnect.text5"}/> {platform}
                    </CustomButton> 
                  </div> 
                </>
                :
                <>
                  <span className={classes.popupTitle}>
                    <Translation  id={"integration.cmsPopup.disconnect.text1"}/>
                    {platform} 
                    <Translation  id={"integration.cmsPopup.disconnect.text2"}/>
                  </span>
                  <span className={classes.popupText}>
                    <Translation  id={"integration.cmsPopup.disconnect.text6"}/>
                  </span>
                  <div className={classes.popupButton}>
                    <CustomButton style={{ marginRight:"25px" }} type={"secondary"} size="lg" onClick={() => closeCmsPopup()} >
                      <Translation id="button.next"/> 
                    </CustomButton>
                    <CustomButton type={"primary"} size="lg" onClick={() => closeCmsPopup()} >
                      <Translation  id={"integration.cmsPopup.disconnect.text5"}/> {platform}
                    </CustomButton> 
                  </div> 
                </>
        }
      
      </div>    
    </Dialog>
  );
};
      