import { Translation } from "../common/Translation";
import { CustomButton } from "../common/Button/CustomButton";

import classes from "./NewsletterChooseObjective.module.scss";

import objectiveProductImage from "../../public/newsletterObjectiveProduct.png";
import objectivePromotionImage from "../../public/newsletterObjectivePromotion.png";
import objectiveMonoProductImage from "../../public/newsletterObjectiveMonoProduct.png";

export const NewsletterChooseObjective = ({ setStep, isFirstSmartletter, objective, setObjective }) => {

  return (
    <div className={classes.objectiveFormContainer}>
      <h1><Translation id="newsletter.objective.title" /></h1>
      <div className={`text ${classes.subtitle}`}><Translation id="newsletter.objective.subtitle" /></div>
      <div className={classes.objectives}>

        <div 
          className={`${classes.objectiveCard} ${objective === "product" ? classes.objectiveCardSelected : null}`}
          onClick={() => {
            setObjective("product");
            setStep(prev => prev + 1);
          }}
        >
          <div className={`textMedium ${classes.objectiveName} ${classes.yellowBackgroundText}`}>
            <Translation id="newsletter.objective.product.name1" />
          </div>
          <div className={`textMedium ${classes.objectiveName}`}>
            <Translation id="newsletter.objective.product.name2" />
          </div>
          <div className={classes.objectiveImageContainer}>
            <img alt="" src={objectiveProductImage} width={75} />
          </div>
        </div>

        <div
          className={`${classes.objectiveCard} ${objective === "monoProduct" ? classes.objectiveCardSelected : null}`}
          onClick={() => {
            setObjective("monoProduct");
            setStep(prev => prev + 1);
          }}
        >
          <div className={classes.underlinedWrapper}>
            <div className={`textMedium ${classes.objectiveName} ${classes.purpleBackgroundText} ${classes.alignRight}`}>
              <Translation id="newsletter.objective.monoProduct.name1" />
            </div>
          </div>
          <div className={`textMedium ${classes.objectiveName} ${classes.alignRight}`}>
            <Translation id="newsletter.objective.monoProduct.name2" />
          </div>
          <div className={classes.objectiveMonoProductImageContainer}>
            <img alt="" src={objectiveMonoProductImage} width={75} />
          </div>
        </div>

        <div
          className={`${classes.objectiveCard} ${objective === "promotion" ? classes.objectiveCardSelected : null}`}
          onClick={() => {
            setObjective("promotion");
            setStep(prev => prev + 1);
          }}
        >
          <div className={`textMedium ${classes.objectiveName}`}>
            <Translation id="newsletter.objective.promotion.name1" />
          </div>
          <div className={`textMedium ${classes.objectiveName} ${classes.redBackgroundText}`}>
            <Translation id="newsletter.objective.promotion.name2" />
          </div>
          <div className={classes.objectivePromotionImageContainer}>
            <img alt="" src={objectivePromotionImage} width={105} />
          </div>
        </div>

      </div>
      <div>
        {!isFirstSmartletter && 
          <CustomButton
            type="secondary"
            size="lg"
            onClick={() => setStep(prev => prev - 1)}
            style={{ marginRight: 20 }}
          >
            <Translation id="dialog.back" />
          </CustomButton>
        }
      </div>
    </div>
  );
};