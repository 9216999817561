import { palette } from "../../../../styles/palette";

export const styles = {
	title: {
		paddingBottom: 10,
	},
	subtitle: {
		paddingBottom: 40,
	},
	cmsCards: {
		display: "flex",
		flexDirection: "row",
		gridGap: 50,
	},
	cmsCard: {
		width: 190,
		height: 130,
		background: palette.neutralBCGGrey,
		borderRadius: 30,
		padding: "36px 0",
		cursor: "pointer",
    "&:hover": {
      background: palette.neutralBCGGreyHover,
    },
	},
	cmsCardContent: {
		display: "flex",
		justifyContent: "space-between",
		textAlign: "center",
		flexDirection: "column",
    alignItems: "center",
		height: "100%",
	},
	cmsCardContentImage: {
		height: 80,
	},
	buttonContainer: {
		paddingTop: 40,
		paddingBottom: 59,
	},
};
