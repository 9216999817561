import { column } from "./column";

const LABEL = "columns";
const CATEGORY = "columns:columns";

const COLUMN_PADDING = {
  top: 10,
  bottom: 10,
  left: 10,
  right: 10,
};

export const columns = (variables, nbColumns) => ({
  label: `${LABEL}-${nbColumns}`,
  category: CATEGORY,
  block: (overrides) => {
    const { backgroundColor, columnContents = [], columnsWordings = {}, columnsVariables = {}, columnSizes = [], columnsBackgroundColor = []} = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const finalColumnSizes = columnSizes.length === nbColumns ? columnSizes : [];

    const mjmlColumns = Array.from({ length: nbColumns }).map((e, idx) => {
      const columnBlock = column({
        size: idx < finalColumnSizes.length ? finalColumnSizes[idx] : 100 / nbColumns,
        paddingTop: COLUMN_PADDING.top,
        paddingBottom: COLUMN_PADDING.bottom,
        paddingLeft: COLUMN_PADDING.left,
        paddingRight: COLUMN_PADDING.right,
        content: idx < columnContents.length ? columnContents[idx] : "",
        backgroundColor: idx < columnsBackgroundColor.length ? columnsBackgroundColor[idx] : backgroundColor,
      }).block();
      return columnBlock.content;
    }).join("");

    const mjml = `
      <mj-section data-gjs="kilibaBlock" data-blocklabel="${`${LABEL}-${nbColumns}`}" background-color="${backgroundColor}" full-width="full-width" padding-top="10px" padding-bottom="10px" padding-left="${COLUMN_PADDING.left}px" padding-right="${COLUMN_PADDING.right}px">
        ${mjmlColumns}
      </mj-section>
    `;

    return {
      id: `${LABEL}-${nbColumns}`,
      label: `${LABEL}-${nbColumns}`,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        wordings: columnsWordings,
        variables: columnsVariables,
        editableProperties: [
          { key: "columns", type: "columns", label: "newsletter.rightMenu.propertyGroup.columns" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ],
      }
    };
  },
  style: `
    [data-blocklabel^="columns"] [data-gjs-type="kilibaColumn"] {
      padding-top: ${COLUMN_PADDING.top}px !important;
      padding-bottom: ${COLUMN_PADDING.bottom}px !important;
      padding-left: ${COLUMN_PADDING.left}px !important;
      padding-right: ${COLUMN_PADDING.right}px !important;
    }
  `,
  previewStyle: `
    .column td table {
      height: 70px;
      background: #F0EDEA;
      border-radius: 5px;
    }

    .dataPreviewColumn {
      display: none;
    }

    .column td table tbody {
      display: none;
    }
  `
});
