import React, { Component } from "react";

import { injectIntl } from "react-intl";
import { Dialog } from "./Dialog.js";

export const ConfirmationDialog = injectIntl(
  class ConfirmationDialog extends Component {
    render() {
      const { intl, isOpen, onConfirmation, close, title } = this.props;

      return (
        <Dialog
          isOpen={isOpen}
          close={close}
          title={<center>{title}</center>}
          PaperProps={{ style: { width: "auto" } }}
          buttonsProps={[
            {
              label: intl.messages["confirmationPopup.ctas.yes"],
              onClick: onConfirmation,
              type: "secondary",
              id: "promoCodeTemplateEditorConfirmDeletionPopup",
              dataCy: "confirmationDialogYes",
            },
            {
              label: intl.messages["confirmationPopup.ctas.no"],
              onClick: close,
              type: "primary",
              id: "promoCodeTemplateEditorConfirmDeletionPopup",
              dataCy: "confirmationDialogNo",
            },
          ]}
        ></Dialog>
      );
    }
  }
);
