
export const setUpRawComponent = (editor)  => {
  editor.Components.addType("mj-raw", {
    model: {
      toHTML() {
        //Code from https://github.com/GrapesJS/mjml/blob/master/src/components/index.ts

        const model = this;
        const tag = model.get("tagName");
        const voidTag = model.get("void");
        const attr = this.getAttrToHTML();
        let code = "";
        let strAttr = "";

        for (const prop in attr) {
          const val = attr[prop];
          const hasValue = typeof val !== "undefined" && val !== "";
          strAttr += hasValue ? ` ${prop}="${val}"` : "";
        }

        code += `<${tag}${strAttr}${voidTag ? "/" : ""}><tr><td>` + model.get("content");

        
        model.components().forEach(model => {
          code += model.toHTML();
        });

        code += "</td></tr>";

        if (!voidTag) {
          code += `</${tag}>`;
        }

        return code;
      }
    }
  });
};
