export const setUpImageComponent = (editor) => {
  editor.Components.addType("mj-image", {
    model: {
      getAttrToHTML() {
        //Code from https://github.com/GrapesJS/mjml/blob/master/src/components/index.ts
        const attr = { ...this.get("attributes") };
        const style = { ...this.get("style-default") };
        delete attr.style;
        delete attr.id;
  
        for (const prop in attr) {
          const value = attr[prop];
  
          if (value && value === style[prop]) {
            delete attr[prop];
          }
        }

        const props = this.get("script-props");
        const translatableAttributes = props?.length ? props[0].translatableAttributes : [];
        for (const translatableAttribute of translatableAttributes || []) {
          attr[translatableAttribute.key] = `{{${translatableAttribute.attributeId}}}`;
        }

        return attr;
      }
    },
    view: {
      events: {
        click: "clickOnElement"
      },

      clickOnElement() {
        editor.trigger("imageClick", this.model);
      },
    }
  });
};