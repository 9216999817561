import { extractVariable, extractWordingsFromMjTextComponent, makeTranslatable } from "../utils";
import { button1 } from "./button1";
import { button2 } from "./button2";
import { button3 } from "./button3";
import { button4 } from "./button4";

export const getButtonBlocks = ({ fontFamily, buttonTextColor, accentColor, urlShop, dominantColor }) => {
  return [
    button1({ fontFamily, buttonTextColor, buttonColor: accentColor, href: makeTranslatable(urlShop), backgroundColor: dominantColor }),
    button2({ fontFamily, buttonTextColor, buttonColor: accentColor, href: makeTranslatable(urlShop), backgroundColor: dominantColor }),
    button3({ fontFamily, buttonTextColor, buttonColor: accentColor, href: makeTranslatable(urlShop), backgroundColor: dominantColor }),
    button4({ fontFamily, buttonTextColor, buttonColor: accentColor, href: makeTranslatable(urlShop), backgroundColor: dominantColor }),
  ];
};

export const extractContentFromButtonComponent = (component, wordings, language, variables) => {

  const content = {};

  const buttonComponents = component.findType("mj-button");

  const buttonProps = buttonComponents[0].get("script-props");
  const buttonTranslatableAttributes = buttonProps[0].translatableAttributes;  

  const buttonHrefAttribute = buttonTranslatableAttributes?.find(attr => attr.key === "href");

  content.href = extractVariable(buttonHrefAttribute, buttonComponents[0].attributes.attributes.href, variables, language);

  content.buttonColor = buttonComponents[0].attributes.attributes["background-color"];
  content.backgroundColor = component.attributes.attributes["background-color"];

  content.buttonText = extractWordingsFromMjTextComponent(buttonComponents[0], wordings, language);

  return content;
};