import { setUpBodyComponent } from "./body";
import { setUpTextComponent } from "./text";
import { setUpButtonComponent } from "./button";
import { setUpkilibaBlockComponents } from "./kilibaBlock";
import { setUpRawComponent } from "./raw";
import { setUpImageComponent } from "./image";
import { setUpReadOnlyComponents } from "./readonly";
import { setUpMjmlComponent } from "./mjml";
import { setUpPreviewsComponent } from "./preview";
import { setUpLinkComponent } from "./link";
import { setUpHeroComponent } from "./hero";
import { setUpkilibaColumnComponent } from "./kilibaColumn";
import { setUpkilibaColumnSubComponents } from "./columnSubComponents";

const EDITABLE_COMPONENTS = ["mj-text", "mj-button"];

export const forceReadOnly = (editor) => {
  editor.Components.getTypes().forEach(componentType => {
    editor.Components.addType(componentType.id, {
      model: {
        defaults: {
          stylable: [],
          traits: [],
          draggable: false,
          removable: false,
          copyable: false,
          droppable: false,
          hoverable: false,
          selectable: false,
          resizable: false,
          editable: false,
        }
      }
    });
  });
};

export const setUpComponents = (editor) => {
  editor.Components.getTypes().forEach(componentType => {
    editor.Components.addType(componentType.id, {
      model: {
        defaults: {
          stylable: [],
          traits: [],
          draggable: false,
          removable: false,
          copyable: false,
          droppable: true,
          hoverable: false,
          selectable: false,
          resizable: false,
          editable: EDITABLE_COMPONENTS.includes(componentType.id),
        }
      }
    });
  });

  setUpBodyComponent(editor);
  setUpTextComponent(editor);
  setUpButtonComponent(editor);
  setUpHeroComponent(editor);
  setUpkilibaBlockComponents(editor);
  setUpRawComponent(editor);
  setUpImageComponent(editor);
  setUpReadOnlyComponents(editor);
  setUpMjmlComponent(editor);
  setUpPreviewsComponent(editor);
  setUpLinkComponent(editor);
  setUpkilibaColumnComponent(editor);
  setUpkilibaColumnSubComponents(editor);
};
