import { palette } from "../palette";

const expansionTagHeight = 39;
const expansionTagWidth = 30;
const expansionTagClickZonePadding = 30;

export const styles = {
  leftMenu: {
    width: 105,
    backgroundColor: palette.primaryBlack,
    height: "100vh",
    position: "fixed",
    zIndex: 20000,
    padding: props => props.small ? "45px 0px 30px" : "45px 0px 62px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "0.2s width",
    "&::after, &::before": {
      transition: "0.2s left, 0.2s right",
      content: "\"\"",
      position: "absolute",
      left: 105,
      width: 40,
      height: 40,
    },
    "&::before": {
      top: 0,
      background: `radial-gradient(20px 20px at 100% 100%, transparent 40px, ${palette.primaryBlack} 41px)`,
    },
    "&::after": {
      bottom: 0,
      background: `radial-gradient(20px 20px at 100% 0%, transparent 40px, ${palette.primaryBlack} 41px)`,
    },
    "&:hover": {
      "& $expansionTagExtendedExpansionZone": {
        transform: "initial",
      },
    },
    "&:not(&$expanded)": {
      "& $buttonWrapper": {
        "&:hover": {
          "& $tooltip": {
            opacity: 1,
            visibility: "initial",
          },
        }
      }
    },
  },
  expanded: {
    width: 212,
    "&::after, &::before": {
      left: 212,
    },
  },
  logo: {
    height: 42,
    alignSelf: "center",
    cursor: "pointer",
  },
  buttons: {
    marginTop: props => props.small ? 20 : 40,
  },
  button: {
    alignItems: "center",
    height: props => props.small ? 60 : 75,
    color: palette.neutralDarkGrey,
    fontSize: 14,
    fontFamily: "Poppins-Medium",
    display: "flex",
    cursor: "pointer",
    paddingLeft: 40,
    "&:hover, &$buttonSelected": {
      color: palette.primaryYellow200,
      "& $icon": {
        position: "relative",
        "&::after": {
          content: "\"\"",
          backgroundColor: palette.primaryYellow200,
          height: 2,
          width: 30,
          display: "block",
          position: "absolute",
          left: 0,
          bottom: -8,
          marginLeft: "50%",
          transform: "translateX(-50%)",
          borderRadius: 2,
        },
      }
    },
  },
  buttonWrapper: {
    position: "relative",
  },
  buttonSelected: {},
  buttonLabel: {
    opacity: 0,
    transition: "0.2s opacity, 0.2s width",
    pointerEvents: "none",
    width: 0,
    marginLeft:15,
  },
  buttonLabelExpanded: {
    opacity: 1,
    pointerEvents: "initial",
    width: 130,
  },
  icon: {
    fontSize: 25,
    fontWeight: "bold",
    width: 25,
    display: "flex",
    justifyContent: "center",
    marginRight: 8,
    "&::before": {
      transform: "scale(var(--scale-ratio, 1))",
    },
  },
  expansionTagVisibleZone: {
    width: expansionTagWidth,
    height: expansionTagHeight,
    position: "absolute",
    top: 0,
    right: -(expansionTagClickZonePadding + expansionTagWidth),
    padding: `${expansionTagClickZonePadding}px ${expansionTagClickZonePadding}px ${expansionTagClickZonePadding}px 0`,
    overflow: "hidden",
  },
  expansionTagExtendedExpansionZone: {
    position: "absolute",
    // use negative position coordinates to compensate for the paddings of the click zone
    top: 0,
    right: 0,
    padding: `${expansionTagClickZonePadding}px ${expansionTagClickZonePadding}px ${expansionTagClickZonePadding}px 0`,
    transition: "transform ease-out 0.2s",
    transform: `translateX(calc(-100% + ${expansionTagClickZonePadding}px))`,
  },
  expansionTag: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: palette.primaryWhite,
    backgroundColor: palette.primaryBlack,
    cursor: "pointer",
    fontSize: 15,
    letterSpacing: "-0.25em",
    width: expansionTagWidth,
    height: expansionTagHeight,
    borderTopRightRadius: 7,
    borderBottomRightRadius: 7,
  },
  tooltip: {
    opacity: 0,
    visibility: "hidden",
    transition: "0.1s opacity",
    display: "flex",
    position: "absolute",
    left: 90,
    top: "50%",
    transform: "translateY(-50%)",
    width: 160,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: palette.white,
    borderRadius: 5,
    fontSize: 12,
    textAlign: "center",
    color: palette.primaryBlack,
    "&::before": {
      content: "\"\"",
      borderRight: `8px solid ${palette.white}`,
      borderTop: "6px solid transparent",
      borderBottom: "6px solid transparent",
      width: 0,
      height: 0,
      left: -8,
      top: "calc(32px / 2 - 6px)",
      position: "absolute",
    },
    "&::after": {
      content: "\"\"",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      boxShadow: `2px 4px 4px ${palette.primaryBlack}`,
      opacity: 0.1,
      borderRadius: "inherit",
    }
  },
  wording: {
    color: palette.primaryWhite,
    fontSize: 14,
    paddingBottom: 20,
  },
  bottomPart: {
    paddingLeft: 40,
    paddingRight: 24,
  },
  topPart: {
    display: "flex",
    flexDirection: "column",
  },
  bottomButtonNotExpanded: {
    position: "relative",
    height: 36,
    paddingBottom: 10,
    "& > button": {
      position: "absolute",
      top: 0,
      left: -60,
      width: "159px !important",

      "&:hover": {
        width: "173px !important",
      }
    }
  },
  quote: {
    color: palette.neutralDarkGrey,
    fontSize: 14,
    fontStyle: "italic"
  }
};
