import React, { Component } from "react";
import { injectIntl } from "react-intl";
import classes from "../ParametersSection.module.scss";


import { ItemPicker } from "../../../pickers/ItemPicker.js";
import { InputSection } from "../../../common/Input/InputSection.js";


export const FontParameters = (
  injectIntl(
    class FontParameters extends Component {
      render() {
        const { intl, fontFamily, setFontFamily, popperPlacement, maxHeight } = this.props;

        const formattedFontFamily = fontFamily || "verdana";

        return (
          <InputSection
            title={intl.messages["templateCustomization.sub_title_fontFamily"]}
          >
            <div className={classes.dropdownWrapper}>
              <ItemPicker
                alternativeStyle
                startIcon="fal fa-font"
                listName="availableFontFamilies"
                titleKey={formattedFontFamily}
                selectedItem={formattedFontFamily}
                onSelect={setFontFamily}
                popperPlacement={popperPlacement}
                maxHeight={maxHeight}
              />
            </div>
          </InputSection>
        );
      }
    }
  )
);
