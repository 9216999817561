import React, { Component } from "react";
import { RadioGroup, withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";

const styles = {
  title: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 5,
  }
};

const CustomRadioGroup = class CustomRadioGroup extends Component {
  render() {
    const {
      row = false,
      value,
      onChange,
      title,
      children,
      classes,
      customStyle,
    } = this.props;

    return (
      <RadioGroup
        row={row}
        value={value}
        onChange={onChange}
        style={ customStyle ? customStyle : null}
      >
        {
          title
            ? (
              <div className={classes.title}>{title}</div>
            )
            : null
        }
        {children}
      </RadioGroup>
    );
  }
};

const _RadioGroup = injectIntl(withStyles(styles)(CustomRadioGroup));

export { _RadioGroup as RadioGroup };
