import { useMemo, useState, useEffect } from "react";
import DraggableList from "react-draggable-list";
import { cloneDeep } from "lodash";

import { Translation } from "../../../common/Translation";

import classes from "./ColumnsInput.module.scss";
import { CustomButton } from "../../../common/Button/CustomButton";

const ColumnInput = ({ dragHandleProps, itemSelected, item, editor, content, updateContent, columnComponents }) => {

  const [time, setTime] = useState(0);

  useEffect(() => {
    if (itemSelected !== 0 && time === 0) {
      setTime(Date.now());
    }
    if (itemSelected === 0) {
      setTime(0);
    }
  }, [itemSelected, time]);

  const onMouseUp = () => {
    if (Date.now() - time < 300) {
      editor.select(columnComponents[item.idx]);
    }
    setTime(0);
  };

  const onTrashMouseDown = (event) => {
    event.stopPropagation();
    const newContent = {};
    
    newContent.columnContents = cloneDeep(content.columnContents);
    newContent.columnContents.splice(item.idx, 1);
    newContent.columnSizes = [];

    const block = editor.Blocks.getAll().find(block => block.getLabel() === `columns-${newContent.columnContents.length}`);

    updateContent(newContent, block.getId());
  };

  return (
    <div {...dragHandleProps} onMouseUp={onMouseUp}>
      <div className={`${classes.columnInput} ${itemSelected !== 0 ? classes.columnInputDragged : ""}`}>
        <div className="text"><Translation id="newsletter.editContent.columnInput.name" /> {item.idx + 1}</div>
        <div className={classes.columnInputIcons}>
          {content.columnContents.length > 2 &&  <i className={`fa-solid fa-trash-can ${classes.columnTrash}`} onMouseDown={onTrashMouseDown} />}
          <i className={"fa-solid fa-chevron-right"} />
        </div>
      </div>
    </div>
  );
};

export const ColumnsInput = ({ content, updateContent, editor, component }) => {
  
  const [columnList, setColumnList] = useState(null);

  const onMoveEnd = (newList, movedItem, oldIndex, newIndex) => {
    const moveItemInArray = (arr, oldIndex, newIndex) => {
      const element = arr[oldIndex];
      arr.splice(oldIndex, 1);
      arr.splice(newIndex, 0, element);
    };

    const newContent = {};
    newContent.columnContents = cloneDeep(content.columnContents);
    moveItemInArray(newContent.columnContents, oldIndex, newIndex);
    
    updateContent(newContent);

    setColumnList(newList.map((e, idx) => ({ ...e, idx })));
  };
  
  useEffect(() => {
    if (!columnList && content) {
      setColumnList(content.columnContents.map((e, idx) => ({
        id: idx,
        idx,
      })));
    }
  }, [columnList, content]);

  const onAddColumn = () => {
    const block = editor.Blocks.getAll().find(block => block.getLabel() === `columns-${columnList.length + 1}`);
    updateContent({ columnSizes: [] }, block.getId());
  };

  const columnComponents = useMemo(() => component.findType("kilibaColumn"), [component]);
  
  return (
    <div>
      <div className={`text ${classes.title}`}><Translation id="newsletter.editContent.columnsInput.title" /></div>
      {columnList && 
        <DraggableList
          itemKey="id"
          list={columnList}
          template={(props) => <ColumnInput {...props} editor={editor} content={content} updateContent={updateContent} columnComponents={columnComponents} />}
          onMoveEnd={onMoveEnd}
        />
      }
      {columnList && columnList.length < 4 && 
        <div className={classes.buttonContainer}>
          <CustomButton
            type="secondary"
            size="xs"
            onClick={onAddColumn}
          >
            <Translation id="newsletter.editContent.columnInput.button" />
          </CustomButton>
        </div>
      }
    </div>
  );
};
