export const styles = (theme) => ({
  temporalityLabel: {
    display: "grid",
    alignItems: "center",
    gridAutoFlow: "column",
    gridGap: 12,
  },
  temporalityLabelWithDatePicker: {
    margin: "8px 0",
  },
});
