import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

import { DayPicker } from "../../../../../../pickers/DayPicker.js";
import { Radio } from "../../../../../../common/Input/Radio/Radio.js";
import { InformationsIcon } from "../../../../../../common/InformationsIcon.js";
import { styles } from "./styles.js";

export const UntilDateInput = withStyles(styles)(
  injectIntl(
    class UntilDateInput extends Component {
      render() {
        const { classes, intl, tabKey, promoCodeCanBeEternalInCms, promoCodeTemplateWrapper, setPromoCodeTemplateWrapperProp } = this.props;

        return (
          <Radio
            legacy
            value="untilDate"
            label={
              <span className={`${classes.temporalityLabel} ${classes.temporalityLabelWithDatePicker}`} >
                <span>
                  { intl.messages[ "usecase.promoCodeTemplateEditor.temporality.untilDate"] }
                </span>
                {
                  <Fragment>
                    <DayPicker
                      active={ promoCodeTemplateWrapper.temporalityChoices.eternal.dateFrom }
                      onDayClick={(value) =>
                        setPromoCodeTemplateWrapperProp(
                          "temporalityChoices.untilDate.dateTo",
                          value.toISOString()
                        )
                      }
                      idStartDate="promoCodeTemplateEditorUntilDate"
                    />
                    {tabKey === "automatic" && !promoCodeCanBeEternalInCms ? (
                      <InformationsIcon
                        text={ intl.messages[ "usecase.promoCodeTemplateEditor.temporality.eternal.helper"]}
                        type="information"
                      />
                    ) : null}
                    {tabKey === "automatic" &&
                    !promoCodeTemplateWrapper.promoCodeTemplate.isGeneric ? (
                        <InformationsIcon
                          text={ intl.messages[ "usecase.promoCodeTemplateEditor.temporality.eternal.warning"]}
                          type="warning"
                        />
                      ) : null}
                  </Fragment>
                }
              </span>
            }
          />
        );
      }
    }
  )
);
