import { palette } from "../../../../styles/palette";

export const styles = () => ({
  container: {
    marginTop: 15,
    position: "relative",
    display: "flex",
    gridGap: 40,
    "user-select": "none"
  },
  leftCard: {
    padding: "35px 40px 70px",
    background: palette.primaryWhite,
    borderRadius: 30,
    width: "75%"
  },
  rightCard: {
    padding: "28px 50px",
    background: palette.primaryBlack,
    borderRadius: 30,
    width: "25%",
    maxWidth: 328,
    display: "flex",
    color: palette.primaryWhite,
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  customerStatsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 40,
    paddingBottom: 35,
    marginBottom: 35,
    borderBottom: `1px solid ${palette.neutralLightGrey}`
  },
  totalCustomerText: {
    color: palette.neutralDarkGrey
  },
  sliderContainer: {
    marginTop: 35,
  },
  slider: {
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
    textAlign: "center",
    paddingRight: 20,
  },
  sliderBackground: {
    marginBottom: 8,
    height: 8,
    background: palette.accentRed200,
    borderRadius: 40,
    margin: ({ sliderMargin }) => `0px ${sliderMargin}px 8px`,
    position: "relative",
  }, 
  sliderBar: {
    height: 8,
    background: palette.primaryYellow200,
    borderRadius: 40,
  },
  sliderNumber: {
    display: "inline-block",
    lineHeight: "20px",
    textAlign: "right",
  },
  sliderTop: {
    display: "flex",
    marginBottom: 5,
    position: "relative",
    margin: "0px 10px",
  },
  sliderCustomerNbr: {
    display: "inline-block",
    background: palette.primaryYellow25,
    borderRadius: 7,
    padding: "4px 9px",
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  sliderCustomerBlacklisted: {
    display: "inline-block",
    background: palette.accentRed50,
    borderRadius: 7,
    padding: "4px 9px",
    position: "absolute",
    top: 0,
    right: "50%",
    transform: "translate(50%, 0)",
  },
  sliderContactsInfoContainer: {
    whiteSpace: "nowrap",
    position: "relative",
    height: 34,
  },
  sliderContent: {
    width: "100%"
  },
  sliderStep: {
    position: "absolute",
    top: -2,
    width: 8,
    height: 8,
    borderRadius: 40,
    background: palette.primaryYellow200,
    border: `2px solid ${palette.primaryWhite}`,
    boxShadow: "0px 3px 5px 0px rgba(120, 120, 120, 0.25)",
  },
  sliderStepHitbox: {
    position: "absolute",
    top: -6,
    width: 20,
    height: 20,
    cursor: "pointer",
  },
  sliderStepText: {
    marginTop: 3,
    position: "absolute",
    color: palette.neutralDarkGrey,
    transform: "translate(-50%, 0)",
    cursor: "pointer",
  },
  switchContainer: {
    display: "flex",
    gridGap: 10,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 25,
    position: "relative",
  },
  priceContainer: {
    height: 110,
  },
  price: {
    fontSize: 85,
    fontFamily: "Coolvetica",
  },
  priceCurrency: {
    fontSize: 55,
    fontFamily: "Coolvetica",
  },
  yellowText: {
    color: palette.primaryYellow200,
  },
  greenText: {
    color: palette.accentGreen200
  },
  blackText: {
    color: palette.primaryBlack,
  },
  pinkBackground: {
    background: palette.accentRed50,
  },
  annualPromo: {
    position: "absolute",
    background: palette.neutralDarkGrey,
    borderRadius: 30,
    right: -45,
    padding: "2px 7px",
  },
  annualPromoSelected: {
    background: palette.accentGreen200,
    color: palette.primaryBlack,
  },
  blacklistInfo: {
    cursor: "pointer",
    "&:hover": {
      "& $popoverContainer": {
        display: "block !important",
      }
    }
  },
  popoverContainer: {
    display: "none",
    position: "relative",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "normal",
  },
  triangle: {
    position: "absolute",
    bottom: -10,
    right: -2,
    width: 0,
    height: 0,
    borderBottom: `10px solid ${palette.primaryWhite}`,
    borderTop: "10px solid transparent",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
  },
  popover: {
    position: "absolute",
    top: 10,
    right: -125,
    width: 250,
    backgroundColor: palette.primaryWhite,
    fontSize: 12,
    boxShadow: "0px 0px 5px 0px rgba(120, 120, 120, 0.25)",
    borderRadius: 5,
    padding: 10,
  },
  sliderCursor: {
    zIndex: 10,
    position: "absolute",
    top: -13,
    width: 8,
    height: 37,
    borderRadius: 40,
    background: palette.primaryYellow200,
    border: `2px solid ${palette.primaryWhite}`,
    boxShadow: "0px 3px 5px 0px rgba(120, 120, 120, 0.25)",
    cursor: "grab",
    "&:hover": {
      background: palette.primaryYellow100,
    }
  },
  sliding: {
    cursor: "grabbing !important",
  },
  sliderCursorText: {
    marginTop: 3,
    position: "absolute",
    top: 25,
    color: palette.primaryBlack,
    transform: "translate(-50%, 0)",
    "white-space": "nowrap",
    fontWeight: 500,
  },
  subtitle: {
    fontWeight: 400,
  }
});