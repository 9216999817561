import { palette } from "../../styles/palette";

export const styles = {
  step: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    width: "100%",
    height: 55,
    cursor: "pointer",
    "& > $circle": {
      color: palette.black,
      background: palette.primaryYellow200,
    }
  },
  activeStep: {
    background: palette.primaryYellow15,
    borderRadius: 15,
    fontWeight: 500,
  },
  doneStep: {
    cursor: "initial",
    "& > $circle": {
      color: palette.white,
    }
  },
  disabledStep: {
    cursor: "initial",
    "& > $circle": {
      color: palette.neutralDarkGrey,
      background: palette.neutralLightGrey
    }
  },
  circle: {
    width: 35,
    lineHeight: "35px",
    borderRadius: "50%",
    textAlign: "center",
    marginRight: 15,
    marginLeft: 22,
    fontWeight: 600,
  },
  text: {
    fontSize: 14
  }
};
