import React, { useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "../components/AuthService";

/**
 * Main context with current account, config and additional infos
 * @type {React.Context<{ account: any, config: any, additionalInfos: any }>}
 */
export const AccountContext = React.createContext();

export const isContextLoaded$ = new BehaviorSubject(false);
export const AccountContextBehaviorSubject$ = new BehaviorSubject({ account: null, config: null, additionalInfos: null });

export async function reloadAccountContext() {
  return await new AuthService().getServerProfile()
    .then((result) => {
      AccountContextBehaviorSubject$.next({ account: result.account, config: result.config, additionalInfos: result.additionalInfos });
      isContextLoaded$.next(true);
      return result;
    });
}

export async function emptyAccountContext() {
  AccountContextBehaviorSubject$.next({ account: null, config: null, additionalInfos: null });
  isContextLoaded$.next(true);
  return { account: null, config: null, additionalInfos: null };
}

export function AccountContextProvider({ children }) {
  const [{ account, config, additionalInfos }, setData] = useState(AccountContextBehaviorSubject$.getValue());

  useEffect(() => {
    const subscription = AccountContextBehaviorSubject$.subscribe(result => {
      setData(result);
    });
    return () => subscription.unsubscribe();
  }, []);

  return (
    <AccountContext.Provider
      value={{
        account: account,
        config: config,
        additionalInfos: additionalInfos
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}