import { useEffect, useState } from "react";
import { Translation } from "../../common/Translation";
import classes from "./Integration.module.scss";
import { useAuth } from "../../../hooks/useAuth";
import { HTTPResponseStatusError } from "../../AuthService";
import { EventSystem } from "../../../eventsystem/EventSystem";
import { reloadAccountContext } from "../../../contexts/context";
import { useIntl } from "react-intl";

import { CustomButton } from "../../common/Button/CustomButton.js";
import { CmsConnectingStateBadge } from "../../common/CmsConnectingStateBadge";

import shopify from "../../../public/shopifyLogo.png";
import prestashop from "../../../public/prestashopLogo.png";
import magento from "../../../public/magentoLogo.png";
import woocommerce from "../../../public/woocommerceLogo.png";
import cmsSuspended from "../../../public/cmsSuspendedImg.png";

import { PrestashopForm } from "./../../OnBoarding/OnBoardingPages/ChooseCms/PrestashopDialog/PrestashopForm.jsx";
import { MagentoForm } from "./../../OnBoarding/OnBoardingPages/ChooseCms/MagentoDialog/MagentoForm.jsx";
import { ShopifyForm } from "./../../OnBoarding/OnBoardingPages/ChooseCms/ShopifyDialog/ShopifyForm.jsx";
import { WoocommerceForm } from "./../../OnBoarding/OnBoardingPages/ChooseCms/WoocommerceDialog/WoocommerceForm.jsx";

export const MainCmsCard = ({ platform, noCms, closeCmsCard, isSuspended, setNoCms }) => {

  const [logo, setLogo] = useState([]);
  const [tutoLink, setTutoLink] = useState([]);
  const [loading, setLoading] = useState(false);
  const [originalState] = useState(noCms);


  const auth = useAuth();
  const intl = useIntl();

  useEffect(() => {
    const platformLogos = {
      Prestashop: prestashop,
      Magento: magento,
      Shopify: shopify,
      Woocommerce: woocommerce,
    };
    const platformLinks = {
      Prestashop: "https://support.kiliba.com/hc/fr/articles/10684249392284-Comment-installer-le-module-Kiliba-sur-Prestashop-",
      Shopify: "https://support.kiliba.com/hc/fr/articles/10681069552156-Comment-installer-le-module-Kiliba-sur-Shopify-",
      Woocommerce: "https://support.kiliba.com/hc/fr/articles/13729586358556-Comment-installer-le-module-Kiliba-sur-Woocommerce",
    };

    if (platform in platformLogos) {
      setLogo(platformLogos[platform]);
      setTutoLink(platformLinks[platform]);
    }
  }, [platform]);

  function openTuto() {
    console.log("Open TUTORIAL !!!!");
  }
  
  function redirectToUsecase() {
    window.location = "/usecase";
  }
  function showCmsDialog() {
    setNoCms(true);
  }
  function closeCmsDialog() {
    setNoCms(originalState);
    closeCmsCard();
  }

  const submitForm = async (CMS, payload) => {
    
    setLoading(true);
    if (CMS !== "shopify") {
      try {
        await auth.fetch("/api/account_management/updateAccount", {
          method: "POST",
          body: JSON.stringify({
            step_register: 3,
            CMS: CMS.toLowerCase() !== "shopify" ? CMS.toLowerCase() : null,
            woocommerce: CMS === "woocommerce" ? payload : null
          })
        });
        await reloadAccountContext();
      } catch (error) {
        if(error instanceof HTTPResponseStatusError) {
          const response = await error.response.json();
          if(response.code === "ERR_CONNECT_CMS") {
            EventSystem.newNotification("notification.error", intl.formatMessage({ id: "error.module_not_detected" }, { cms: CMS }));
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      }
    }
    // GET URL_LOGO BY API
    if(CMS === "woocommerce") {
      try {
        await auth.fetch("/api/account_management/getWoocommerceLogo", {
          method: "GET",
        });
      } catch (error) {
        console.log("Error fetching logo");
      }
    }
    setLoading(false);
  };

 
  return (
    <div className={classes.cmsCard}>
      {/* HEADER */}
      <div onClick={() => closeCmsDialog()} className={classes.header}>
        <i style={{ marginTop: "4px", color: "#3e464f", cursor: "pointer" }} className={"fa-solid fa-chevron-left"}></i>
        <span className={`${classes.text} ${classes.goBack}`}>
          <Translation id="integration.cmsCard.back" />
        </span>
        {
          !noCms && !isSuspended ?
            <CmsConnectingStateBadge isConnected={true} />
            : isSuspended &&
            <CmsConnectingStateBadge isConnected={false} />
        }
      </div>

      <div className={classes.content}>
        {/* TITLE */}
        <div className={`${classes.flexGrid} ${classes.marginTop}`}>
          <img className={classes.cmsLogo} alt={`${platform}`} src={logo} />
          <div className={classes.title}>
            {platform}
            <h4 className={classes.cardTextTitle}>
              {
                !noCms ?
                  <Translation id={`integration.cmsCard.${platform}.connectedTitle`} />
                  :
                  <Translation id={`integration.cmsCard.${platform}`} />
              }
            </h4>
          </div>
        </div>

        {/* CONTENT */}
        {
          noCms ?
            <div>
              <div>
                {
                  platform === "Shopify" ? <ShopifyForm onSubmit={submitForm} /> 
                    : platform === "Magento" ? <MagentoForm onSubmit={submitForm} />
                      : platform === "Prestashop" ? <PrestashopForm onSubmit={submitForm} />
                        : platform === "Woocommerce" && <WoocommerceForm  onSubmit={submitForm} />
                }
              </div>

              {/* FOOTER */}
              
              { window.location.pathname === "/on_boarding" &&
                <div className={`${classes.flexCenter} ${classes.cardFooter}`}>
                  <Translation id="integration.cmsCard.help" />
                  <span style={{ marginLeft: "2px" }} className={classes.link} onClick={() => openTuto()}>
                    <Translation id="integration.cmsCard.tuto" />
                  </span>
                </div>
              }
            </div>

            : !noCms && !isSuspended ?
              <div>
                <h4 className={`${classes.connectedCardText} ${classes.marginText}`}>
                  <Translation id="integration.cmsCard.cmsConnected" />
                </h4>
                <div style={{ marginBottom: "40px" }} className={`${classes.flexCenter} ${classes.marginT40}`}>
                  <CustomButton type={"primary"} size="sm" onClick={() => redirectToUsecase()} >
                    <Translation id="integration.cmsCard.showUsecase" />
                  </CustomButton>
                  
                </div>
              </div>

              : isSuspended &&
              <div>
                <h4 className={`${classes.connectedCardText} ${classes.marginText}`}>
                  {
                    platform === "Magento" ?
                      <Translation id="integration.cmsCard.cmsSuspendedMagento" />
                      :
                      <>
                        <Translation id="integration.cmsCard.cmsSuspended1" />
                        <a className={classes.link} style={{ fontSize: "15px" }} href={tutoLink} rel="noreferrer" target="_blank">
                          <Translation id="integration.cmsCard.cmsSuspended2" />
                        </a>
                        <Translation id="integration.cmsCard.cmsSuspended3" />
                        <div className={classes.centeredSpacedDiv}>
                          <CustomButton type={"primary"} size="sm" onClick={() => showCmsDialog()} >
                            <Translation id="disconnectedModulePopup.button.reinstall" />
                          </CustomButton>
                        </div>
                      </>
                  }
                </h4>
                <div className={classes.flexCenter}>
                  <img className={classes.logoSuspended} alt="" src={cmsSuspended} />
                </div>
              </div>
        }

      </div>
    </div>
  );
};