import { useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles, RadioGroup } from "@material-ui/core";

import { Dialog } from "../../../common/Dialog";
import { Radio } from "../../../common/Input/Radio/Radio";
import { Translation } from "../../../common/Translation";
import { CustomButton } from "../../../common/Button/CustomButton";

import { palette } from "../../../../styles/palette";

const styles = theme => ({
  container: {
    padding: "20px 70px 0px",
  },
  title: {
    textAlign: "center",
    fontFamily: "Coolvetica",
    fontWeight: 400,
    fontSize: 20,
    paddingBottom: 32,
    display: "inline-block",
    lineHeight: "24px",
  },
  optionDescription: {
    paddingLeft: 38,
    marginTop: -10,
    color: palette.neutralDarkGrey,
    lineHeight: "18px",
  },
  optionDescriptionPadding: {
    paddingBottom: 15,
  },
  buttonContainer: {
    paddingTop: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 30,
  },
  info: {
    textAlign: "center",
    color: palette.neutralDarkGrey,
    fontSize: 10,
    lineHeight: "15px",
  }
});

const useStyles = makeStyles(styles);

export const UsecasePopupGender = ({ isOpen, onSave, close }) => {
  const [unisex, setUnisex] = useState(false);

  const intl = useIntl();
  const classes = useStyles();
  
  return (
    <Dialog 
      isOpen={isOpen}
      close={close}
      closeStyle={null}
      style={{ padding: "60px 60px 20px 60px" }}
    >
      <div className={classes.container}>
        <span className={classes.title}><Translation id="genderPopup.title" /></span>

        <RadioGroup
          name="gender"
          value={unisex}
          onChange={event => setUnisex(event.target.value === "true")}
        >

          <Radio 
            label={intl.messages["genderPopup.option1"]}
            value={false}
          />
          <p className={`info ${classes.optionDescription} ${classes.optionDescriptionPadding}`}><Translation id="genderPopup.option1.desc" /></p>

          <Radio 
            label={intl.messages["genderPopup.option2"]}
            value={true}
          />
          <p className={`info ${classes.optionDescription}`}><Translation id="genderPopup.option2.desc" /></p>

        </RadioGroup>

        <div className={classes.buttonContainer}>
          <CustomButton onClick={() => onSave(unisex)} >{intl.messages["button.next"]}</CustomButton>
        </div>

        <p className={classes.info}><Translation id="genderPopup.info" /></p>

      </div>

    </Dialog>
  );
};