const LABEL = "image3";
const CATEGORY = "image:headerImage";

export const image3 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {

    const { imageUrl, fontFamily, title, subtitle } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-hero data-gjs="kilibaBlock" css-class="mj-hero-fix-width image3-hero" padding-right="0px" padding-left="0px" padding-top="0px" padding-bottom="0px" vertical-align="middle" background-position="center center" background-url="{{imageUrl}}" mode="fixed-height" height="200px" background-height="200px">
          <mj-text css-class="image3-text" color="#FFFFFF" font-family="${fontFamily}" font-weight="600" font-size="16px" align="center">
            {{title}}
          </mj-text>
          <mj-text css-class="image3-text" color="#FFFFFF" font-family="${fontFamily}" line-height="12px" font-size="12px" align="center">
            {{content}}
          </mj-text>
      </mj-hero>
    `;

    const wordings = {
      title: title,
      content: subtitle,
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        variables: {
          imageUrl,
        },
        wordings,
        editableProperties: [
          { key: "imageUrl", type: "image", label: "newsletter.rightMenu.property.selectedImage" },
        ]
      }
    };
  },
  style: `
    .image3-hero {
      position: relative
    }

    [data-gjs-type="kilibaBlock-mj-hero"] > .image3-hero::before, [data-gjs-type="preview-mj-hero"] > .image3-hero::before {
      background-color: rgba(12, 12, 14, 0.65);
      content: ' ';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .image3-hero .mj-hero-content {
      height: 100%;
      background-color: rgba(12, 12, 14, 0.65);
    }
    
    .image3-hero .mj-hero-content table:first {
      height: 100%;
    }

    .image3-hero .mj-hero-content > table {
      height: 100%;
    }

    .image3-text {
      position: relative;
    } 
  `,
});
