import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";

import { Dialog } from "../../common/Dialog";
import { FormButtonTO } from "../../FormButtonTO.jsx";
import { useAuth } from "../../../hooks/useAuth";
import { reloadAccountContext } from "../../../contexts/context";

const styles = theme => ({
  container: {
    display: "flex",
    maxWidth: 400,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontFamily: "Coolvetica",
    lineHeight: "25px",
    marginBottom: 30,
    textAlign: "center",
    fontSize: 22,
  },
  subtitle: {
    lineHeight: "22px",
    fontFamily: "Poppins",
    textAlign: "left",
    fontSize: 14,
  },
  buttonContainer: {
    marginTop: 50,
    display: "flex",
    gridGap: 30,
  }
});

const useStyles = makeStyles(styles);

export const NeutralizeCaPopup = injectIntl(({
  intl,
  isOpen,
  close, 
  customerIds = [],
  onNeutralize,
}) => {

  const classes = useStyles();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);

  const neutralizeCa = async () => {
    setLoading(true);
    
    await auth.fetch("/api/account_management/neutralizeCustomersCa", {
      method: "POST",
      body: JSON.stringify({ customerIds }),
    });
    await reloadAccountContext();
    
    setLoading(false);
    onNeutralize();
  };

  return (
    <Dialog
      PaperProps={{ style: { borderRadius: 20 } }}
      isOpen={isOpen}
      close={close}
      style={{ padding: "80px 120px"}}
    >
      <div className={classes.container}>
        <h2 className={classes.title}>{intl.messages["neutralizeCaPopup.title"]}</h2>
        <span className={classes.subtitle}>{intl.messages["neutralizeCaPopup.subtitle"]}</span>
        <div className={classes.buttonContainer}>
          <FormButtonTO 
            label={intl.messages["neutralizeCaPopup.no"]}
            action={close}
            type="secondary"
          />
          <FormButtonTO 
            label={intl.messages["neutralizeCaPopup.yes"]}
            action={neutralizeCa}
            loading={loading}
          />
        </div>
      </div>
    </Dialog>
  );
});
