
export const setUpkilibaColumnComponent = editor => {
  //Kiliba columns management
  editor.Components.addType("kilibaColumn", {
    extend: "mj-column",
    isComponent: el => {
      if (el.tagName?.toLowerCase() === "mj-column" && el.getAttribute("data-gjs") === "kilibaColumn") {
        return true;
      }
    },
    model: {
      defaults: {
        stylable: ["background-color"],
        traits: [],
        hoverable: true,
        selectable: true,
        editable: true,
        draggable: false,
      }
    }
  });
};