import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Translation } from "../Translation";

import { Dialog } from "../Dialog.js";
import { CustomButton } from "../Button/CustomButton.js";
import { injectIntl } from "react-intl";
import { withAuth } from "../../withAuth";

const styles = (theme) => ({
  main: {
    width: "500px",
    textAlign:"center"
  },
  head: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    width: "361px",
    textAlign: "center",
    margin: "auto",
    transform: "translate(0%,-10%)",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    width: "350px",
    margin: "auto",
    transform: "translate(0%,30%)",
  },
  title: {
    fontSize: "29px",
    fontFamily: "Coolvetica",
    lineHeight: "1.2",
  },
  text: {
    fontSize: "17px",
    fontFamily: "Poppins",
    textAlign: "center",
    marginTop:"30px",
    marginBottom:"0px"
  },
  link: {
    fontSize: "15px",
    fontFamily: "Coolvetica Book",
    textAlign: "center",
  },
});

export const ExceedingPricePopup = withAuth(
  injectIntl(
    withStyles(styles)(
      injectIntl(
        class ExceedingPricePopup extends Component {
          constructor(props) {
            super(props);
            this.redirectToSubscription = this.redirectToSubscription.bind(this);
          }
          
          redirectToSubscription() {
            if(this.props.paymentPlatform){
              if(this.props.paymentPlatform === "Shopify store") {
                this.props.closeExceedingPopup();
                window.open("https://admin.shopify.com/", '_blank');
              } else {
                this.props.openContactModal();
              }
            }
          }

          render() {
            const { intl, classes } = this.props;
            const titles = {
              1: "exceedingPricePopup.title",
              2: "exceedingPricePopup.title.upgrade",
              3: "exceedingPricePopup.title.upgrade",
            };
            const wordings = {
              1: "exceedingPricePopup.wording",
              2: "exceedingPricePopup.wording.upgrade1",
              3: "exceedingPricePopup.wording.upgrade2",
            };
            const btnWordings = {
              1: "exceedingPriceBanner.buttonWording",
              2: "button.letsgo",
              3: "home.shopifySubscriptionDialog.button",
            };


            const title = this.props.isUpdate ? titles[this.props.exceedingStatus] : titles[1];
            const wording = this.props.isUpdate ? wordings[this.props.exceedingStatus] : wordings[1];
            const btnWording = this.props.isUpdate ? btnWordings[this.props.exceedingStatus] : btnWordings[1];

            return (
              <Dialog PaperProps={{ style: { borderRadius: 20 } }} close={this.props.closeExceedingPopup} isOpen={true} >
                <div className={classes.main}>

                  <div className={classes.head}>
                    <p className={classes.title}>
                      {title && intl.messages[title]}
                    </p>
                    <p className={`${classes.text}`}>
                      {wording && intl.messages[wording]}
                    </p>
                  </div>

                  <div className={classes.footer}>
                    <CustomButton onClick={ this.redirectToSubscription } style={{ marginBottom: "15px", alignSelf: "center" }} type={"primary"}>
                      <Translation id={btnWording && intl.messages[btnWording]}  />
                    </CustomButton>
                  </div>

                </div>
              </Dialog>
            );
          }
        }
      )
    )
  )
);

ExceedingPricePopup.propTypes = {
  classes: PropTypes.object.isRequired,
};