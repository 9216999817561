import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";

import { HtmlCustomizerTooltip } from "../../common/HtmlCustomizerTooltip/HtmlCustomizerTooltip.js";
import { HtmlCustomizerTooltipAiButton } from "../../common/HtmlCustomizerTooltip/HtmlCustomizerTooltipInputs/HtmlCustomizerTooltipAiButton.js";
import { TextField } from "../../../Input/TextField";

import { palette } from "../../../../../styles/palette";
import { Translation } from "../../../Translation.js";

const styles = theme => ({
  subjectContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "16px",
    alignItems: "center",
  },
  grey: {
    fontSize: "14px",
    lineHeight: "22px",
    color: palette.lightgray,
  },
  subjectLabel: {
    whiteSpace: "nowrap",
  },
  inputSubject: {
    position: "relative",
    "&:hover": {
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23${palette.purple.replace(
        "#",
        ""
      )}' stroke-width='2' stroke-dasharray='10%2c10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
    },
  },
});

const useStyles = makeStyles(styles);

export const HtmlCustomizerSubject = injectIntl(({
  intl,
  onChange,
  value,
  onAiGenerate
}) => {

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.subjectContainer}>
      <span className={classes.grey + " " + classes.subjectLabel}>
        <Translation
          id="email.subject"
          defaultMessage="Objet : "
        />
      </span>
      <span className={classes.inputSubject}>
        {onAiGenerate &&
          <HtmlCustomizerTooltip open={tooltipIsOpen} styles={{ top: 20 }}>
            <HtmlCustomizerTooltipAiButton onClick={onAiGenerate} seprator={false} />
          </HtmlCustomizerTooltip>
        }
        <TextField
          value={value}
          isAutoSized
          marginIsEnabled={false}
          hasBorder={false}
          placeholder={intl.messages["emailEditor.subject.placeholder"]}
          onChange={event => onChange(event.target.value)}
          onFocus={() => setTooltipIsOpen(true)}
          onBlur={() => setTooltipIsOpen(false)}
        />
      </span>
    </div>
  );
});
