import { useCallback, useContext, useEffect, useState } from "react";

import { Dialog } from "../../../common/Dialog";
import { Translation } from "../../../common/Translation";

import { useAuth } from "../../../../hooks/useAuth";
import { EventSystem } from "../../../../eventsystem/EventSystem.js";
import { AccountContext, reloadAccountContext } from "../../../../contexts/context";
import { TextField } from "../../../common/Input/TextField";
import { FormButtonTO } from "../../../FormButtonTO";
import { StatusPicker } from "../../../pickers/StatusPicker.js";

import classes from "./UsecaseCrossSellingConfigPopup.module.scss";

export const UsecaseCrossSellingConfigPopup = ({ isOpen, close, usecaseConfig, templateDataKey, usecaseName, setUsecaseConfig }) => {

  const auth = useAuth();
  const { account, config } = useContext(AccountContext);
  const [nbDays, setNbDays] = useState(usecaseConfig?.[templateDataKey]?.value || 14);
  const [defaultValue, setDefaultValue] = useState(account?.mw_custom_options?.cross_selling_order_status);
  const [statusList, setStatusList] = useState(account?.mw_custom_options?.cross_selling_order_status);
  const [defaultStatusList, setDefaultStatusList] = useState(null);
  const [pickerKey, setPickerKey] = useState(1);
  
  const _setNbDays = (value) => {
    if(value !== "0") {
      if (Number.isInteger(+value) && +value >= 0) {
        setNbDays(value);
      }
    } else {
      setNbDays(1);
    }
  };

  const onSubmit = () => {
    auth.fetch("/api/account_management/updateMwOptions", {
      method: "PUT",
      body: JSON.stringify({ 
        cross_selling_order_status: statusList,
      }),
    }).then(() => {
      setDefaultValue(statusList);
      reloadAccountContext();
      EventSystem.newNotification("notification.action", <Translation id="usecase.configPopup.save.confirm" />);
      close();
    });

    const config = usecaseConfig || [];

    for (let i = 0; i <= templateDataKey; i++) {
      if (config.length === i) {
        config.push({});
      }
    }

    config[templateDataKey] = {
      type: "dynamicDate",
      value: nbDays,
    };

    auth.fetch("/api/account_management/setUsecaseConfig", {
      method: "POST",
      body: JSON.stringify({ 
        usecaseName, 
        config,
      }),
    }).then(() => {
      setUsecaseConfig(config);
    });
  };

  const reset = useCallback(() => {
    setNbDays(14);
    setStatusList([...defaultStatusList, "NA"]);
    setPickerKey(prev => prev + 1);
  }, [defaultStatusList]);

  useEffect(() => {
    if (!defaultStatusList?.length) {
      return ;
    }
    if (!defaultValue?.length) {
      setStatusList([...defaultStatusList, "NA"]);
      setDefaultValue([...defaultStatusList, "NA"]);
    } else {
      setStatusList(defaultValue);
    }
    setNbDays(usecaseConfig?.[templateDataKey]?.value || 14);
  }, [isOpen, account, defaultStatusList, usecaseConfig, templateDataKey, defaultValue]);

  useEffect(() => {
    try {

      if (defaultStatusList) {
        return ;
      }

      const accountId = auth.getAccountId();
      auth.fetch(`/data/getDistinctOrderStatuses?accountId=${accountId}`, {
        method: "GET"
      }).then(orderStatuses => {
        setDefaultStatusList(orderStatuses);
        if (!statusList) {
          setStatusList(orderStatuses);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }, [auth, statusList, defaultStatusList]);

  useEffect(() => {
    try {

      if (defaultStatusList) {
        return ;
      }

      const accountId = auth.getAccountId();
      auth.fetch(`/data/getDistinctOrderStatuses?accountId=${accountId}`, {
        method: "GET"
      }).then(orderStatuses => {
        setDefaultStatusList(orderStatuses);
        if (!statusList) {
          setStatusList(orderStatuses);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }, [auth, statusList, defaultStatusList]);

  return (
    <Dialog 
      PaperProps={{ style: { borderRadius: 20 } }}
      isOpen={isOpen}
      close={close}
      padding="64px 64px 40px 64px"
    >
      <div className={classes.container}>
        <h2 className={classes.title}><Translation id="usecaseConfigPopup.title" /></h2>
        
        <div>
          <h3 className={classes.formTitle}><Translation id="usecaseConfigPopup.form.date.title" /></h3>
          <span className={classes.subtitle}><Translation id="usecaseConfigPopup.subtitle" /></span>
          <div className={`${classes.dateOption} ${classes.dateOptionDynamic}`}>
            <TextField 
              value={nbDays}
              onChange={event => {
                _setNbDays(event.target.value);
              }}
            />
            <span><Translation id="usecaseCrossSellingConfigPopup.form.date.label" /></span>
          </div>
        </div>

        {statusList && defaultStatusList?.length > 1 &&
          <div className={classes.statusFormContainer}>
            <h3 className={classes.formTitle}><Translation id="usecaseCrossSellingConfigPopup.form.status.title" /></h3>
            <span className={classes.subtitle}><Translation id="usecaseCrossSellingConfigPopup.form.status.subtitle" /></span>
            <StatusPicker 
              key={pickerKey}
              setStatusList={setStatusList}
              statusCheckedList={statusList}
              statusList={defaultStatusList}
              zIndex={9999}
              placement={"top-start"}
            />
          </div>
        }

        {(!statusList?.length  && config.TurnoverFilteredByOrderStatus) &&
          <div className={`info ${classes.errorContainer}`}>
            <Translation id="usecaseCrossSellingConfigPopup.error.status" />
          </div>
        }
        <div className={classes.buttonsContainer}>
          <FormButtonTO 
            label={<Translation id="usecaseConfigPopup.form.button.save" />}
            action={onSubmit}
            disabled={!statusList?.length && config.TurnoverFilteredByOrderStatus }
          />
          <small className={classes.buttonReset} onClick={reset} >
            <Translation id="usecaseConfigPopup.form.button.reset" />
          </small>
        </div>
      </div>
    </Dialog>
  );
};