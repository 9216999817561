import { makeStyles } from "@material-ui/core";

import { Dialog } from "../common/Dialog";
import { Translation } from "../common/Translation";
import { palette } from "../../styles/palette";

const styles = () => ({
  container: {
    padding: "15px 120px",
    textAlign: "center",
  },
  content: {
    marginTop: 30,
    marginBottom: 50,
    textAlign: "left",
  },
  greyText: {
    color: palette.neutralDarkGrey
  },
  error: {
    color: palette.alertError,
  }
});

const useStyles = makeStyles(styles);

export const ResetPasswordEmailSentPopup = ({ isOpen, close, onResend, error }) => {

  const classes = useStyles();

  return (
    <Dialog 
      isOpen={isOpen}
      close={close}
      closeStyle="string"
    >
      <div className={classes.container}>
        <div className="display3"><Translation id="resetPasswordEmailSentPopup.title"/></div>

        <div className={classes.content}>
          <div className="text"><Translation id="resetPasswordEmailSentPopup.text1"/></div>
          <div className="textMedium"><Translation id="resetPasswordEmailSentPopup.text2"/></div>
        </div>

        <div>
          <span className={`text ${classes.greyText}`}><Translation id="resetPasswordEmailSentPopup.didnt_receive"/> </span>
          <span onClick={onResend} className="link"><Translation id="resetPasswordEmailSentPopup.resend"/></span>
        </div>
        {error && <div className={`info ${classes.error}`}>
          <Translation id="resetPasswordEmailSentPopup.spamError" />
        </div>}
      </div>
    </Dialog>
  );
};
