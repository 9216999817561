import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { KiTable } from "../common/KiTable/KiTable.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { injectIntl } from "react-intl";
import { withAuth } from "../withAuth";
import { EventSystem } from "../../eventsystem/EventSystem.js";
import { Tooltip } from "../Tooltip.js";
import { DayPicker } from "../pickers/DayPicker.js";
import { FormattedMessage } from "react-intl";
import { FormButtonTO } from "../FormButtonTO.jsx";

import CircularProgress from "@material-ui/core/CircularProgress";
import { palette } from "../../styles/palette.js";
import moment from "moment-timezone";
import { getFormattedNumber } from "../../tools/getFormattedNumber";
import { ItemPicker } from "../pickers/ItemPicker";
import { KiUsecasePicker } from "../KiUsecasePicker/KiUsecasePicker.js";

import { AccountContextBehaviorSubject$ } from "../../contexts/context.js";

const styles = (theme) => ({
  root: {
    width: "100%",
    padding: "0 68px",
    overflow: "none",
    boxSizing: "border-box"
  },
  ctaIcon: {
    marginLeft: 8,
    marginTop: 5,
    fontSize: 20,
  },
  title: {
    fontFamily: "Coolvetica",
    fontSize: "40px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    marginBottom: "36px",
    color: palette.black,
  },
  search: {
    position: "relative",
    color: "#a5adb8",
    border: "1px solid #e1e4e7",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      color: "#949494",
      border: "1px solid " + palette.purple,
    },
    "&:hover $searchIcon": {
      color: palette.purple,
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 2,
      width: "auto",
    },
  },
  header: {
    display: "flex",
  },
  select: {
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto",
    marginRight: "0px",
  },
  toolbar: {
    margin: "0px",
    height: "48px",
  },
  highlight: {
    color: palette.black,
    backgroundColor: "#F4D093",
  },
  checkbox: {
    color: palette.anothergray,
    "&:hover": {
      color: palette.purple,
    },
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  paper: {
    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
  },
  table: {
    width: "100%",
    marginTop: "0px",
  },
  tableHeader: {
    fontSize: "14px",
    fontWeight: 600,
    color: palette.black,
  },
  tableCell: {
    fontSize: "14px",
    fontWeight: "normal",
    color: "#838580",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#f0f2f4 !important",
    },
  },
  cardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: 32,
    [theme.breakpoints.down(1250)]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  days: {
    position: "relative",
    fontFamily: "Coolvetica",
    fontSize: 30,
    color: palette.primaryYellow200,
    top: 37,
    left: 28,
    height: 40,
    letterSpacing: 1,
  },
  numberCard: {
    position: "relative",
    fontFamily: "Coolvetica Book",
    fontSize: 36,
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 1,
    color: "#0E0E0F",
    marginLeft: 100,
    marginTop: -20,
    paddingRight: 20,
    whiteSpace: "nowrap"
  },
  titleCard: {
    position: "relative",
    fontFamily: "Coolvetica Book",
    fontSize: 13,
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: 1,
    color: "#878787",
    marginLeft: 102,
    marginTop: 3,
  },
  paperCard: {
    boxShadow: "0 4px 6px 0 rgba(165, 173, 184, 0.3)",
    root: {
      borderRadius: 5,
      backgroundColor: "#ffffff",
      marginRight: 48,
    },
  },
  downloadButton: {
    display: "flex",
    justifyContent: "end",
    marginBottom: 20,
  },
});

export const Orders = withAuth(
  withStyles(styles)(
    injectIntl(
      class Orders extends Component {
        constructor(props) {
          super(props);
          this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
          this.handleDatePeriodSelection = this.handleDatePeriodSelection.bind(this);
          this.changeUsecase = this.changeUsecase.bind(this);
          this.getPagingData = this.getPagingData.bind(this);
          this.loadMoreData = this.loadMoreData.bind(this);
          this.changeDayBegin = this.changeDayBegin.bind(this);
          this.changeDayEnd = this.changeDayEnd.bind(this);
          this.exportOrdersCSV = this.exportOrdersCSV.bind(this);
          this.getTurnovers = this.getTurnovers.bind(this);
          this.resetData = this.resetData.bind(this);
          this.savePagination = this.savePagination.bind(this);

          const now = new Date();
          const init_day_end = new moment(Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59));
          this.state = {
            data: [],
            total: 0,
            update: [],
            page: 0,
            limit: 3000,
            isLoading: true,
            day_begin: new Date(2019, 1, 1),
            day_end: init_day_end,
            noCms: false,
            datePeriod: "fromBeginning",
            emptyStateVariant: "",
            showOrdersState: false,
            columns: [],
            turnovers: [0, 0, 0, 0],
            loading: false,
            account: null,
            componentDidMount: false,
            shouldShowDownloadButton: false,
            pagination: {
              key: 0,
              pageKey: 0,
              rowsPerPage: 10
            },
            exportOrdersCSVLoading: false
          };
        }

        computeColumns(account) {
          const { intl } = this.props;
          const { CMS } = account;

          return [
            CMS === "magento"
              ? { key: "reference", name: intl.messages["orders.table.head.reference"] }
              : { key: "id_order", name: intl.messages["orders.table.head.id"] },
            { key: "ca", name: intl.messages["orders.table.head.amount"], sortProperty: "rawValue", },
            { key: "email", name: intl.messages["orders.table.head.email"] },
            { key: "last_open", name: intl.messages["orders.table.head.openDate"] },
            { key: "date_order", name: intl.messages["orders.table.head.orderDate"] },
            { key: "usecase", name: intl.messages["orders.table.head.usecaseName"] },
            { key: "current_state_name", name: intl.messages["orders.table.head.state"] }
          ];
        }

        async fetchUsecases() {
          const result = await this.props.auth.fetch("/api/usecase/getAll", { method: "GET" });

          const formattedUsecases = result.usecases.map(usecase => ({
            name: usecase.name,
            category: usecase.category,
            checked: true,
          }));

          return formattedUsecases;
        }

        async componentDidMount() {
          const usecases = await this.fetchUsecases();
          const { account } = AccountContextBehaviorSubject$.getValue();

          localStorage.setItem("CMS", account.CMS);

          const date_creation = new Date(account.date_creation);
          date_creation.setHours(0, 0, -1, 0);
          EventSystem.publish("force_date_begin", date_creation);

          usecases.push({
            category: "newsletter",
            checked: true,
            name: "newsletter",
          });

          this.setState({
            usecases,
            showOrdersState: true,
            account: account,
            dateAccountCreation: date_creation,
            columns: this.computeColumns(account)
          }, () => {
            const datePeriodText = localStorage.getItem("datePeriodText");
            if (datePeriodText) {
              this.handleDatePeriodSelection(datePeriodText);
            } else {
              this.getPagingData();
              this.getTurnovers();
            }
  
            this.setState({ componentDidMount: true });
          });
        }

        setEmptyStateVariant() {
          if (this.state.account.step_register > 2) {
            if (!this.state.data?.length) {
              this.setState({ emptyStateVariant: "syncActiveOrders" });
              this.setState({ noCms: true });
            } else {
              this.setState({ emptyStateVariant: null });
              this.setState({ noCms: false });
            }
          } else {
            this.setState({ noCms: true });
            this.setState({ emptyStateVariant: "noCmsOrders" });
          }
        }

        componentWillUnmount() {
          EventSystem.unsubscribe("usecase_search", null);
          EventSystem.publish("loading.stop");
        }

        // CALLBACK DU CHANGEMENT DES USECASES / DATE DEBUT / DATE FIN
        changeUsecase(usecaseKey) {
          const newUsecases = JSON.parse(JSON.stringify(this.state.usecases));

          if (usecaseKey === "all") {
            newUsecases.forEach(usecase => usecase.checked = this.checkedUsecases.length === 0);
          } else {
            const usecaseToCheck = newUsecases.find(usecase => usecase.name === usecaseKey);
            usecaseToCheck.checked = !usecaseToCheck.checked;
          }

          this.setState({ usecases: newUsecases }, () => {
            if (this.state.componentDidMount) { this.resetData(); }
          });
        }

        get checkedUsecases() {
          return this.state.usecases 
            ? Object.keys(this.state.usecases)
              .filter(usecaseKey => this.state.usecases[usecaseKey].checked)
              .map(usecaseKey => this.state.usecases[usecaseKey].name)
            : [];
        }

        changeDayBegin(day) {
          this.setState({ day_begin: day }, () => this.resetData());
          localStorage.setItem("filterDayBegin", day);
        }

        changeDayEnd(day) {
          const temp_day = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 23, 59, 59);
          this.setState({ day_end: temp_day }, () => this.resetData());
          localStorage.setItem("filterDayEnd", temp_day);
        }

        async getPagingData(page) {
          const { intl } = this.props;
          const { account } = AccountContextBehaviorSubject$.getValue();

          const selectedUsecases = JSON.parse(JSON.stringify(this.checkedUsecases));
          if (selectedUsecases.includes("winterSales")) {
            selectedUsecases.push("solde");
          }

          const startDate = this.state.day_begin ? this.state.day_begin.toISOString() : "";
          const endDate = this.state.day_end ? this.state.day_end.toISOString() : "";

          const urlPagingKilibaOrders = `/api/data/getPaginatedOrders?accountId=${account._id}&limit=${this.state.limit}&page=${page || this.state.page}&startDate=${startDate}&endDate=${endDate}&usecases=${selectedUsecases}`;

          const options = { method: "GET" };

          this.props.auth.fetch(urlPagingKilibaOrders, options)
            .then((result) => {
              result.orders?.forEach(order => {
                order.isSelected = false;
                order.last_open = moment.utc(order.last_open).format("DD/MM/YYYY HH:mm:ss");
                order.date_order = moment.utc(order.date_order).format("DD/MM/YYYY HH:mm:ss");
                order.ca = order.ca.replace(".", ",");
              });
              this.setState({ data: [...this.state.data, ...result.orders], total: result.total, isLoading: false }, () => {
                this.setEmptyStateVariant();
              });
            })
            .catch((error) => {
              console.error(error);
              EventSystem.newNotification("notification.action", intl.messages["orders.loading.error"]);
            });
        }

        async loadMoreData(page) {
          this.getPagingData(page);
        }

        resetData() {
          const updatedPagination = Object.assign(this.state.pagination, { key: 0, pageKey: 0 });
          this.setState({
            data: [],
            page: 0,
            turnovers: [0, 0, 0, 0],
            noCms: false,
            emptyStateVariant: "",
            pagination: updatedPagination
          }, () => {
            this.getPagingData();
            this.getTurnovers();
          });
        }

        async exportOrdersCSV() {
          const { auth, intl } = this.props;
          this.setState({ exportOrdersCSVLoading: true });
          try {
            const header = this.state.columns.map(column => ({
              id: column.key,
              title: column.name
            }));

            const selectedUsecases = JSON.parse(JSON.stringify(this.checkedUsecases));
            if (selectedUsecases.includes("winterSales")) {
              selectedUsecases.push("solde");
            }

            const startDate = this.state.day_begin ? this.state.day_begin.toISOString() : "";
            const endDate = this.state.day_end ? this.state.day_end.toISOString() : "";

            const usecaseWordings = {};
            for(const message in intl.messages) {
              if(message.startsWith("templateName.")) {
                usecaseWordings[message.replace("templateName.", "")] = intl.messages[message];
              }
            }

            const sendExportViaEmail = this.state.total >= 10_000;

            const response = await auth.fetch("/api/data/exportOrdersCSV", {
              method: "POST",
              body: JSON.stringify({
                header,
                usecases: selectedUsecases,
                startDate: startDate,
                endDate: endDate,
                usecaseWordings: usecaseWordings,
                sendViaEmail: sendExportViaEmail,
                locale: intl.locale
              }),
            }, {
              rawResponse: true
            });

            if(sendExportViaEmail) {
              const user = await auth.getUser();
              EventSystem.newNotification(
                "notification.action",
                intl.formatMessage({ id: "csv.export.sendViaEmail" }, { email: user.attributes.email })
              );
            } else {
              const blob = await response.blob();
              const fileUrl = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = fileUrl;
              link.download = "orders_export.csv";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          } catch (error) {
            console.error(error);
            EventSystem.newNotification("notification.action", intl.messages["error.main_error"]);
          }

          this.setState({ exportOrdersCSVLoading: false });
        }

        changePage(value) {
          this.setState({ pageKey: value });
          if (this.shouldLoadMoreData()) {
            const nextPage = this.state.page + 1;
            this.setState({ page: nextPage });
            this.loadMoreData(nextPage);
          }
        }

        changeResultPerPage(value) {
          this.setState({ resultPerPage: value });

          if (this.state.CMS === "shopify") {
            this.setState({ page: 0, pageKey: 0, customers: [] });
            this.getPagingData(0);
          }
        }

        shouldLoadMoreData() {
          return (this.state.pageKey + 2) * this.state.resultPerPage >= this.state.data.length;
        }

        handleChangeRowsPerPage = event => {
          this.setState({ rowsPerPage: event.target.value }, () => {
            window.scrollTo(0, 0);
          });
        };

        async getTurnovers() {
          const { intl } = this.props;
          const { account } = AccountContextBehaviorSubject$.getValue();

          const selectedUsecases = JSON.parse(JSON.stringify(this.checkedUsecases));
          if (selectedUsecases.includes("winterSales")) {
            selectedUsecases.push("solde");
          }

          const startDate = this.state.day_begin ? this.state.day_begin.toISOString() : "";
          const endDate = this.state.day_end ? this.state.day_end.toISOString() : "";

          const urlPagingKilibaOrders = `/api/data/getOrderTurnovers?accountId=${account._id}&startDate=${startDate}&endDate=${endDate}&usecases=${selectedUsecases}`;

          const options = { method: "GET" };
          this.props.auth.fetch(urlPagingKilibaOrders, options)
            .then((turnovers) => {
              this.setState({ turnovers });
            })
            .catch((error) => {
              console.error(error);
              EventSystem.newNotification("notification.action", intl.messages["orders.loading.error"]);
            });
        }

        getRows(orders) {
          const {
            intl,
          } = this.props;
          return orders ? orders.map(order => {
            const getColumnKey = (columnIndexKey) => this.state.columns[columnIndexKey].key;
            const getRowValue = (columnIndexKey) => order[this.state.columns[columnIndexKey].key];

            const getCaRawValue = (ca) => {
              if (ca === null) {
                return 0;
              } else if (ca === "ignored") {
                return -1;
              } else {
                return ca;
              }
            };

            const formattedStatus = getRowValue(6) ? getRowValue(6).charAt(0).toUpperCase() + getRowValue(6).slice(1) : "-";
            return {
              id: order.id_order,
              isSelected: order.isSelected,
              values: {
                [getColumnKey(0)]: { displayedValue: getRowValue(0) },
                [getColumnKey(1)]: { 
                  displayedValue: order.ca, 
                  rawValue: getCaRawValue(order.ca) 
                },
                [getColumnKey(2)]: { displayedValue: getRowValue(2) },
                [getColumnKey(3)]: { displayedValue: getRowValue(3).substring(0, 10) },
                [getColumnKey(4)]: { displayedValue: getRowValue(4).substring(0, 10) },
                [getColumnKey(5)]: { displayedValue: intl.messages["templateName." + order.usecase] },
                [getColumnKey(6)]: { displayedValue: formattedStatus },
              }
            };
          }) : undefined;
        }

        savePagination(pagination) {
          if (JSON.stringify(pagination) !== JSON.stringify(this.state.pagination)) {
            this.setState({ pagination });
          }
        };

        handleDatePeriodSelection(datePeriodText) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          let dayBegin = this.state.dateAccountCreation;
          let dayEnd = today;
          switch (datePeriodText) {
            case "fromBeginning":
              dayBegin = this.state.dateAccountCreation;
              break;
            case "thisMonth":
              dayBegin = new Date(today.getFullYear(), today.getMonth(), 1); //first day of month
              break;
            case "previousMonth":
              dayBegin = new Date(today.getFullYear(), today.getMonth() - 1, 1); //first day of previous month
              dayEnd = new Date(today.getFullYear(), today.getMonth(), 1); //last day of previous month
              break;
            case "interval":
              dayBegin = new Date(localStorage.filterDayBegin);
              dayEnd = new Date(localStorage.filterDayEnd);
              break;
            default:
              console.error("Not valid date period");
          }

          this.setState(
            {
              datePeriod: datePeriodText,
              day_begin: dayBegin,
              day_end: dayEnd,
            },
            () => {
              localStorage.setItem("datePeriodText", datePeriodText);
              localStorage.setItem("filterDayBegin", dayBegin);
              localStorage.setItem("filterDayEnd", dayEnd);

              this.resetData();
            }
          );
        }

        render() {
          const {
            classes,
            intl,
          } = this.props;
          const { day_begin, day_end, turnovers } = this.state;

          const rows = this.getRows(this.state.data);

          return (
            <div className={classes.root}>
              <div className={classes.header}>
                <div className={classes.title} style={{ paddingTop: "25px" }}><FormattedMessage id="data.orders" defaultMessage="Vos commandes" /></div>
              </div>
              <div style={{ display: "flex", marginBottom: "13px" }}>
                <KiUsecasePicker
                  usecases={this.state.usecases}
                  onUsecaseClick={this.changeUsecase}
                />
                <div style={{ width: "175px", marginLeft: "30px" }}>
                  <ItemPicker
                    alternativeStyle
                    titleKey={this.state.datePeriod}
                    startIcon="fal fa-calendar-check"
                    listName="datePeriodList"
                    onSelect={this.handleDatePeriodSelection}
                    selectedItem={this.state.datePeriod}
                  />
                </div>
                {this.state.datePeriod === "interval" ? (
                  <Fragment>
                    <div
                      style={{
                        margin: "auto",
                        marginRight: "10px",
                        marginLeft: "10px",
                        fontSize: "11px",
                        fontFamily: "Coolvetica Book",
                        color: "#878787",
                        letterSpacing: 1,
                      }}
                    >
                      {intl.messages["orders.filters.temporality.from"]}
                    </div>
                    <DayPicker
                      active={day_begin}
                      onDayClick={this.changeDayBegin}
                      idStartDate="orders"
                    />
                    <div
                      style={{
                        margin: "auto",
                        marginRight: "10px",
                        marginLeft: "10px",
                        fontSize: "11px",
                        fontFamily: "Coolvetica Book",
                        color: "#878787",
                        letterSpacing: 1,
                      }}
                    >
                      {intl.messages["orders.filters.temporality.to"]}
                    </div>
                    <DayPicker
                      active={day_end}
                      onDayClick={this.changeDayEnd}
                      endDate
                      minDate={this.state.day_begin}
                      idStartDate="orders"
                    />
                  </Fragment>
                ) : null}
              </div >
              <div className={classes.cardContainer} style={{ margin: "80px 0 20px 0" }}>
                <TurnoverCard
                  value={getFormattedNumber(turnovers[0], this.state.account && this.state.account.currency, intl.locale)}
                  text={intl.messages["orders.turnover.0.description"]}
                  day={intl.messages["orders.turnover.0.day"]}
                  tooltipText0={intl.messages["orders.turnover.tooltip0"]}
                  tooltipText1={intl.messages["orders.turnover.tooltip1"]}
                >
                </TurnoverCard>

                <TurnoverCard
                  value={getFormattedNumber(turnovers[1], this.state.account && this.state.account.currency, intl.locale)}
                  text={intl.messages["orders.turnover.1.description"]}
                  day={intl.messages["orders.turnover.1.day"]}
                  tooltipText0={intl.messages["orders.turnover.tooltip0"]}
                  tooltipText1={intl.messages["orders.turnover.tooltip1"]}
                >
                </TurnoverCard>

                <TurnoverCard
                  value={getFormattedNumber(turnovers[2], this.state.account && this.state.account.currency, intl.locale)}
                  text={intl.messages["orders.turnover.2.description"]}
                  day={intl.messages["orders.turnover.2.day"]}
                  tooltipText0={intl.messages["orders.turnover.tooltip0"]}
                  tooltipText1={intl.messages["orders.turnover.tooltip1"]}
                >
                </TurnoverCard>

                <TurnoverCard
                  value={getFormattedNumber(turnovers[3], this.state.account && this.state.account.currency, intl.locale)}
                  text={intl.messages["orders.turnover.3.description"]}
                  day={intl.messages["orders.turnover.3.day"]}
                  tooltipText0={intl.messages["orders.turnover.tooltip0"]}
                  tooltipText1={intl.messages["orders.turnover.tooltip1"]}
                >
                </TurnoverCard>
              </div>

              <div className={classes.downloadButton}>
                {!this.state.noCms
                  ? <>
                    {this.state.exportOrdersCSVLoading ? <CircularProgress size={26} className={classes.loading} /> : null}
                    <FormButtonTO
                      type={"secondary"}
                      size={"xs"}
                      action={this.exportOrdersCSV}
                      label={intl.messages["button.download"]}
                      icon={(<i className={`${classes.ctaIcon} fal fa-arrow-alt-from-top`} style={{ fontSize: "20px" }}/>)} />
                  </>
                  : null
                }
              </div>

              <KiTable
                columns={this.state.columns}
                rows={rows}
                noCms={this.state.noCms}
                loading={this.state.isLoading}
                emptyStateVariant={this.state.emptyStateVariant}
                loadMoreData={this.loadMoreData}
                resetData={this.resetData}
                pageKey={this.state.pageKey}
                total={this.state.total}
                account={this.state.account}
                paginationType="item"
                savePagination={this.savePagination}
                pagination={this.state.pagination}
              ></KiTable>
            </div >
          );
        }
      }
    )
  )
);

Orders.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TurnoverCard = withStyles(styles)(
  class TurnoverCard extends Component {

    render() {
      const {
        classes,
        value,
        text,
        day,
        tooltipText0,
        tooltipText1
      } = this.props;

      return (
        <Tooltip
          title={tooltipText0 + day + tooltipText1} arrow
        >
          <Paper className={classes.paperCard}>
            <div style={{ height: "115px" }}>
              <div className={classes.days}>{day}</div>
              <div className={classes.numberCard}>{value}</div>
              <div className={classes.titleCard}>{text}</div>
            </div>
          </Paper>
        </Tooltip>
      );
    }
  }
);
