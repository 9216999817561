import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useAuth } from "../../hooks/useAuth";
import { reloadAccountContext } from "../../contexts/context";

/**
 * Callback page when user sign in with Google
 * => Login if account exists
 * => Redirect to register (for CGV) otherwise
 */
export function AuthCallback() {
  const auth = useAuth();
  const history = useHistory();

  const handleSuccess = useCallback(async (user) => {
    if(user.attributes?.["custom:admin_account"]) {
      auth.switchAccount(user.attributes["custom:admin_account"]);
      await reloadAccountContext();
      history.push("/");
    } else {
      auth.sendEventToAmplitude("signup.google_connect", { email: user.attributes.email });
      history.push("/register/cgv");
    }
  }, [auth, history]);

  const handleError = useCallback(async () => {
    await auth.logout();
    history.push("/login");
  }, [auth, history]);

  useEffect(() => {
    auth.getUser()
      .then(currentUser => {
        if(currentUser) {
          return handleSuccess(currentUser);
        } else {
          return handleError();
        }
      })
      .catch(error => {
        console.error("User not signed in", error);
        return handleError();
      });
  }, [auth, handleSuccess, handleError]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );
}