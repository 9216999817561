
export const styles = {
	title: {
		textAlign: "center",
		marginBottom: 30,
	},
	subtitle: {
		textAlign: "center",
		marginBottom: 40,
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center"
	}
};