import { prepareDateText, getColorLuminosity } from "./promoCode.tools";
const LABEL = "promoCode2";
const CATEGORY = "promoCode:headband";

export const promoCode2 = (variables, shouldIncludeDate = false, shouldIncludeQuantity = false) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {
    const { promoCode, backgroundColor, fontFamily, textColor, buttonColor, isTextColorLight } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    if (!promoCode) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const shouldIncludeDate = promoCode?.mustShowDates;
    const shouldIncludeQuantity = promoCode?.quantity;
    const tagTextColor = getColorLuminosity(buttonColor) ? "#000000" : "#FFFFFF";

    const prepareValueAndDateMjml = () => {
      return `
          <mj-text align="center" font-family="${fontFamily}" color="${textColor}" data-readonly="true" font-size="14px" font-weight="600" padding-top="0" padding-bottom="5px" padding-left="0" padding-right="0">- {{value}} {{type}} ${promoCode.type !== "%" ? "{{taxes}}" : ""} ${shouldIncludeDate ? "" : `<span style="font-weight:500;font-size:14px;font-family:${fontFamily};color:${textColor}">{{promoCodeText}}<span>`}</mj-text>
          ${shouldIncludeDate ? `<mj-text align="center" font-family="${fontFamily}" color="${textColor}" data-readonly="true" line-height="1" padding-top="0" padding-bottom="0" padding-left="0" padding-right="0" font-size="14px" font-weight="500">{{promoCodeDate}}</mj-text>` : null}
        `;
    };

    const prepareQuantityMjml = () => {
      return shouldIncludeQuantity ?`
          <mj-text align="center" font-family="${fontFamily}" color="${textColor}" data-readonly="true" padding-top="15px" padding-left="0px" padding-right="0px" font-size="14px" font-weight="500">{{quantity}}</mj-text>
        ` : "";
    };

    const mjml = `
    <mj-wrapper data-gjs="kilibaBlock" data-promo-code-id="${promoCode._id}" background-color="${backgroundColor}" padding-top="10px" padding-bottom="10px" full-width="full-width">
      <mj-section>
          <mj-column width="20%" css-class="column20" padding-top="0" padding-bottom="0" padding-left="0" padding-right="0"></mj-column>
          <mj-column width="30%" css-class="column30 desktop-left" padding-top="0" padding-bottom="0" padding-left="0" padding-right="0" vertical-align="middle">
            ${prepareValueAndDateMjml()}
          </mj-column>
          <mj-column width="30%" css-class="column30 mobile-padding-top15" vertical-align="middle" padding-top="0" padding-bottom="0" padding-left="0" padding-right="0">
            <mj-button align="center" font-family="${fontFamily}" background-color="${buttonColor}" data-readonly="true" css-class="newproduct1-tag" border-radius="0" color="${tagTextColor}" background-color="#000000" padding-top="0" padding-bottom="0" padding-left="0" padding-right="0" font-weight="500" font-size="15px" data-promo-code-value="true">{{code}}</mj-button>
          </mj-column>
          <mj-column css-class="column20" width="20%" padding-top="0" padding-bottom="0" padding-left="0" padding-right="0"></mj-column>
      </mj-section>
      <mj-section padding-top="0" padding-bottom="0">
        <mj-column width="100%" padding-top="0" padding-bottom="0" padding-left="0" padding-right="0">
          ${prepareQuantityMjml()}
        </mj-column>
      </mj-section>
    </mj-wrapper>
    `;

    const wordings = {
      promoCodeDate: prepareDateText(promoCode, false, false, true),
      promoCodeText: {
        fr: " avec le code",
        en: " with the code",
        es: " con el código",
        de: " mit dem Code",
        nl: " met code",
        it: " con il codice",
        pt: " com o código",
      },
      code: {
        fr: promoCode?.code,
        en: promoCode?.code,
        es: promoCode?.code,
        de: promoCode?.code,
        nl: promoCode?.code,
        it: promoCode?.code,
        pt: promoCode?.code,
      },
      value: {
        fr: promoCode?.value,
        en: promoCode?.value,
        es: promoCode?.value,
        de: promoCode?.value,
        nl: promoCode?.value,
        it: promoCode?.value,
        pt: promoCode?.value,
      },
      quantity: {
        fr: `${promoCode?.quantity} coupons disponibles !`,
        en: `${promoCode?.quantity} coupons available !`,
        es: `${promoCode?.quantity} cupones disponibles !`,
        de: `${promoCode?.quantity} Gutscheine verfügbar !`,
        nl: `${promoCode?.quantity} coupons beschikbaar !`,
        it: `${promoCode?.quantity} coupon disponibili !`,
        pt: `${promoCode?.quantity} cupões disponíveis !`,
      },
      type: {
        fr: promoCode?.type,
        en: promoCode?.type,
        es: promoCode?.type,
        de: promoCode?.type,
        nl: promoCode?.type,
        it: promoCode?.type,
        pt: promoCode?.type,
      },
      taxes: {
        fr: promoCode.includesTaxes ? "TTC" : "HT",
        en: promoCode.includesTaxes ? "VAT included" : "VAT excluded",
        es: promoCode.includesTaxes ? "IVA incluido" : "IVA excluido",
        de: promoCode.includesTaxes ? "MwSt. enthalten" : "MwSt. ausgeschlossen",
        nl: promoCode.includesTaxes ? "BTW inbegrepen" : "BTW uitgesloten",
        it: promoCode.includesTaxes ? "IVA inclusa" : "IVA esclusa",
        pt: promoCode.includesTaxes ? "IVA incluída" : "IVA excluída",
      }
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        wordings,
        editableProperties: [
          { key: "promoCode", type: "promoCode" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.color" },
          { key: "buttonColor", type: "color", label: "newsletter.rightMenu.property.tagColor", group: "newsletter.rightMenu.propertyGroup.color" },
          { key: "textColor", type: "color", label: "newsletter.rightMenu.property.textColor", group: "newsletter.rightMenu.propertyGroup.color" },
        ],
      }
    };
  },
  mediaQueryStyle: `
  @media only screen and (min-width:480px) {
    .column20 {
      width: 20% !important;
    }
    .column30 {
      width: 30% !important;
    }
    .desktop-left td div {
      text-align: left !important;
    }
    .mobile-padding-top15 {
      padding-top: 0px !important;
    }
  }    
  `,
  style: `
    .column20 {
      width: 0% !important;
    }
    .column30 {
      width: 100% !important;
    }
    .mobile-padding-top15 {
      padding-top: 15px !important;
    }
  `
});