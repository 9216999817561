import React, { Component } from "react";

import { injectIntl } from "react-intl";
import classes from "./ValueSection.module.scss";

import { InputSection } from "../../../../../common/Input/InputSection.js";
import { ValueInput } from "../TemplateEditorInputs/Value/ValueInput.js";
import { TypeInput } from "../TemplateEditorInputs/Value/TypeInput.js";
import { IncludesTaxesInput } from "../TemplateEditorInputs/Code/IncludesTaxesInput.js";

export const ValueSection = 
  injectIntl(
    class ValueSection extends Component {
      render() {
        const {
          intl,
          account,
          promoCodeTemplateWrapper,
          setPromoCodeTemplateWrapperProp,
          getError,
          partNames,
        } = this.props;

        return (
          <InputSection
            title={intl.messages["usecase.promoCodeTemplateEditor.value.title"]}
          >
            <div
              className={classes.inputGroupContainer}
              style={{ alignItems: "flex-start" }}
            >
              {partNames.find((partName) => partName === "typeInput") ? (
                <TypeInput
                  types={["%", account.currency]}
                  promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                  setPromoCodeTemplateWrapperProp={
                    setPromoCodeTemplateWrapperProp
                  }
                />
              ) : null}

              {partNames.find((partName) => partName === "valueInput") ? (
                <ValueInput
                  promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                  setPromoCodeTemplateWrapperProp={
                    setPromoCodeTemplateWrapperProp
                  }
                  getError={getError}
                />
              ) : null}

              {partNames.find(
                (partName) => partName === "includesTaxesInput"
              ) && promoCodeTemplateWrapper.promoCodeTemplate.type !== "%" ? (
                  <IncludesTaxesInput
                    promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                    setPromoCodeTemplateWrapperProp={
                      setPromoCodeTemplateWrapperProp
                    }
                    getError={getError}
                  />
                ) : null}
            </div>
          </InputSection>
        );
      }
    }
  );
