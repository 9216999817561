import { useIntl } from "react-intl";

const defaultValues = {
  br: (chunks) => <br></br>,
  b: (chunks) => <b>{chunks}</b>,
  ul: (chunks) => <ul>{chunks}</ul>,
  li: (chunks) => <li>{chunks}</li>,
  h1: (chunks) => <h1 style={{ display: "inline" }}>{chunks}</h1>
};

export function Translation(props) {
  const intl = useIntl();
  const { id } = props;

	return intl.formatMessage({ id }, { ...defaultValues, ...(props.variables || {}) });
}
