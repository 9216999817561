import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { RadioGroup } from "../../../common/RadioGroup.js";
import { Radio } from "../../../common/Input/Radio/Radio.js";
import { InputSection } from "../../../common/Input/InputSection.js";
import classes from "../ParametersSection.module.scss";

export const StockParameters = (
  injectIntl(
    class StockParameters extends Component {
      render() {
        const { intl, stock, setStock } = this.props;

        return (
          <InputSection>
            <p className={classes.subTitle}>
              {intl.messages["templateCustomization.text_stock"]}
            </p>
            <p className={classes.lowTitle}>
              {intl.messages["templateCustomization.sub_title_stock"]}
            </p>
            <RadioGroup value={stock} onChange={setStock}>
              <Radio legacy
                value="orderable"
                label={intl.messages["templateCustomization.stock_orderable"]}
              />
              <Radio legacy
                value="recommended"
                label={intl.messages["templateCustomization.stock_recommended"]}
              />
              <Radio legacy
                value="notRecommended"
                label={intl.messages["templateCustomization.stock_not_recommended"]}
              />
            </RadioGroup>
          </InputSection>
        );
      }
    }
  )
);
