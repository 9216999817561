import { useIntl } from "react-intl";
import { PrestashopForm } from "./PrestashopForm.jsx";
import { CmsDialog } from "../../common/CmsDialog/CmsDialog.js";
import { useAuth } from "../../../../../hooks/useAuth.js";
import underline from "../../../../../public/underline.png";
import { useEffect } from "react";
import { usePrevious } from "../../../../../hooks/usePrevious.js";

import classes from "./PrestashopDialog.module.scss";


export const PrestashopDialog = ({ open = false, handleClose = () => { }, onSubmit = () => { }, loading = false }) => {
  const intl = useIntl();
  const auth = useAuth();
  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (!prevOpen && open) {
      auth.sendEventToAmplitude("prestashop_connection.see_modale");
    } else if (prevOpen && !open) {
      auth.sendEventToAmplitude("prestashop_connection.leave_modale");
    }
  }, [auth, prevOpen, open]);

  return (
    <CmsDialog open={open} handleClose={handleClose} customScrollBar>
      <div className={classes.prestashopDialog}>
        <div className={classes.title}>
          <p className={"display2"}>{intl.messages["onBoarding.chooseCms.prestashop.title"]}</p>
        </div>
        <h2 className={classes.subtitle}>
          {intl.messages["onBoarding.chooseCms.prestashop.subtitle"]}
        </h2>
        <img alt="" src={underline} className={classes.underlineImg} />
        <PrestashopForm loading={loading} onSubmit={onSubmit} />
      </div>
    </CmsDialog>
  );
};
