const LABEL = "social3";

export const social3 = (blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, footerColor ) => ({
  label: LABEL,
  block: () => {

    const mjml = `
    <mj-wrapper full-width="full-width"  background-color="${footerColor}" data-gjs="kilibaBlock" padding-bottom="5px" padding-top="10px">
        <mj-section css-class="social-block" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="150px">
          <mj-column>
            <mj-social icon-size="25px" mode="horizontal">
              ${blockRsInstagram}
              ${blockRsFacebook}
              ${blockRsTwitter}
              ${blockRsYoutube}
              ${blockRsDiscord}
              ${blockRsLinkedin}
              ${blockRsPinterest}
              ${blockRsTiktok}
            </mj-social>
          </mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: "social:social",
      activate: true,
      content: mjml,
    };
  },
  style: `
    .inlineSocialIcon {
      width:30px !important;
      margin-left:10px !important;
    }
  `,
});