import React, { Component } from "react";
import { PopupTO } from "../../../PopupTO.js";
import { injectIntl } from "react-intl";

export const UsecasePopupFreemium = injectIntl(
  class UsecasePopupFreemium extends Component {
    render() {
      const { intl, isOpen, close } = this.props;
      const buttonStyle = {
        fontWeight: "normal",
        boxShadow: "none",
        width: "210px",
      };

      return (
        <PopupTO
          open={isOpen}
          title="popup.title_freemium"
          label="popup.wording_freemium"
          handleClose={close}
          closingSubLabel={intl.messages["button.check_price"]}
          buttons={[
            {
              onClick: close,
              label: this.props.intl.messages["button.keep_freemium"],
              alternate: true,
              style: buttonStyle,
            },
            {
              onClick: () =>
                window.open("https://calendly.com/kiliba/premium ", "_blank"),
              label: intl.messages["button.make_appointment"],
              alternate: false,
              style: buttonStyle,
            },
          ]}
        />
      );
    }
  }
);
