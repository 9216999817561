import { palette } from "../../styles/palette.js";

export const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: "100%",
    position: "fixed",
    overflowY: "scroll",
    margin: "0",
    display: "flex",
    justifyContent: "center",
    backgroundColor: palette.neutralBCGGrey,
  },

  ////////////////////// CONTAINERS ////////////////////////////////////

  mainwindow: {
    width: "900px",
    position: "relative",
    boxShadow: "0 16px 24px 0 rgba(165, 173, 184, 0.3)",
    borderRadius: "80px",
    textAlign: "center",
    alignSelf: "center",
  },
  rightWindow: {
    height: "calc(100% - 12px * 2)",
    borderRadius: "68px",
    backgroundColor: palette.neutralBCGGrey,
    margin: "12px",
    display: "flex",
    flexFlow: "column",
  },
  confirmModalRightWindow: {
    height: "575px",
  },
  confirmModalTitle: {
    color: palette.black,
    fontFamily: "Coolvetica",
    fontSize: "30px",
    letterSpacing: "0.5px",
    lineHeight: "34px",
    marginBottom: "25px",
  },
  confirmModalTitleContent: {
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  leftWindow: {
    justifyContent: "center",
    display: "flex",
    flexFlow: "column",
    padding: "0 59px",
  },
  confirmModalContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "start",
    marginLeft: "59px",
  },
  confirmModalText: {
    fontFamily: "Poppins",
    fontSize: "12px",
    marginTop: "45px",
    color: palette.anotherlightgray,
  },
  form: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "5px",
    position: "relative",
  },
  footer: {
    alignSelf: "center",
    paddingTop: 10,
    "& button": {
      fontFamily: "Poppins",
      fontSize: "11px",
    },
  },
  logoFooter: {
    alignSelf: "center",
    display: "flex",
    gap: "25px",
    marginTop: "40px",
  },
  formSignupGrid: {
    display: "grid",
    gridTemplate: "'a a a' 'c c d' / 3fr 1fr 2.9fr",
    gridGap: "5px",
  },
  formSigninGrid: {
    display: "grid",
    gridTemplate: "'a' 'b'",
    gridGap: "5px",
  },
  successNotifContainer: {
    width: "100%",
  },
  formGrid: {
    display: "grid",
  },
  checkbox: {
    paddingLeft: 0,
  },

  ////////////////////// MODAL CGV //////////////////////////////////

  mainModal: {
    marginTop: "20px",
  },
  modalWording: {
    color: palette.black,
    fontFamily: "Coolvetica",
    fontSize: "30px",
    letterSpacing: "0.5px",
    lineHeight: "34px",
    textAlign: "center",
    marginTop: "50px",
  },
  modalSubWording: {
    color: "#0c0c0e",
    lineHeight: "20px",
    fontFamily: "Poppins",
    fontSize: "14px",
    marginTop: "20px",
    textAlign: "center",
  },
  cgvModal: {
    width: "400px",
    alignSelf: "center",
    display: "flex",
    flexFlow: "column",
    gap: "20px",
    marginBottom: "50px",
  },
  cgvModalCheckboxContainer: {
    fontSize: "12px !important",
  },
  modalCgvHypertext: {
    fontSize: "12px !important",
  },
  modalButton: {
    fontSize: "40px !important",
  },
  logoModal: {
    marginLeft: "40px",
    marginTop: "40px",
    alignSelf: "center",
    height: "3em",
    zIndex: 1,
    position: "absolute",
    top: "15px",
  },

  ////////////////////// WORDING ////////////////////////////////////

  wording: {
    color: palette.black,
    fontFamily: "Coolvetica",
    fontSize: "30px",
    letterSpacing: "0.5px",
    lineHeight: "34px",
    textAlign: "center",
    marginTop: "25px",
    marginBottom: "6px",
  },
  subWording: {
    color: palette.anotherlightgray,
    fontFamily: "Poppins",
    margin: "20px 0 10px 0",
    fontSize: "10px",
    letterSpacing: "2px",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
  },
  loginWording_hypertext: {
    color: "black",
    fontFamily: "Poppins",
    fontSize: "12px",
    cursor: "pointer",
    letterSpacing: "0px",
    textDecoration: "underline",
  },
  textName: {
    marginTop: "15px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
  },
  textJob: {
    color: palette.lightgray,
    fontSize: "11px",
    fontFamily: "Poppins",
    margin: "0px 100px 0px 100px",
  },
  textQuote: {
    fontFamily: "Poppins",
    textAlign: "left",
    alignSelf: "center",
    width: "80%",
    margin: "40px 0 40px 18px",
  },
  textReview: {
    fontSize: "13px",
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  callResetPasswordHyperText: {
    color: "black",
    fontFamily: "Poppins",
    cursor: "pointer",
    textDecoration: "underline",
    letterSpacing: "0px",
  },
  error: {
    color: palette.errorRed,
    fontFamily: "Poppins",
    fontSize: "12px",
    textAlign: "start",
  },
  signUpError: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  resetPasswordLabel: {
    fontFamily: "Poppins",
    fontSize: "12px",
  },
  loginWording: {
    marginTop: "5px",
  },

  ////////////////////// COMPONENT ////////////////////////////////////

  roundedDiv: {
    width: "65px",
    height: "65px",
    marginTop: "30px",
    borderRadius: "100px",
    zIndex: 0,
    backgroundColor: palette.yellow,
    alignSelf: "center",
  },
  marginUp: {
    marginTop: "15px",
  },
  resetPasswordMarginUp: {
    marginTop: "30px",
    marginBottom: "10px",
  },
  checkCgv: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    alignSelf: "center",
    fontFamily: "Poppins",
    fontSize: "12px",
    color: palette.anotherlightgray,
    letterSpacing: "0px",
  },

  fakeLink: {
    cursor: "pointer",
    textDecoration: "none",
    textTransform: "uppercase",
    color: palette.anotherlightgray,
    marginTop: "10px",
    fontSize: 10,
  },

  leftHr: {
    width: "80px",
    height: "0.2px",
  },
  rightHr: {
    width: "80px",
    height: "0.2px",
  },
  checkboxError: {
    color: palette.alertError,
  },
  loginOr: {
    marginTop: "12px",
  },

  ////////////////////// IMG ////////////////////////////////////

  slide: {
    height: "320px",
    marginTop: "120px",
    alignSelf: "center",
  },
  arrow: {
    height: "50px",
    margin: "20px 14px -13px -60px",
  },
  arrowSignUp: {
    height: "50px",
    position: "absolute",
    left: 25,
    bottom: 30,
  },
  logo: {
    marginLeft: "40px",
    marginTop: "20px",
    alignSelf: "center",
    height: "3em",
    zIndex: 1,
    position: "absolute",
    top: "15px",
  },
  avatar: {
    height: "60px",
    marginTop: "40px",
    width: "60px",
    alignSelf: "center",
  },
  stars: {
    height: "20px",
    right: "140px",
    width: "125px",
    alignSelf: "center",
    marginTop: "10px",
  },
  brandImg: {
    height: "30px",
  },
  buttonContainer: {
    margin: "20px auto",
    display: "flex",
    justifyContent: "center",
    gap: "20px"
  }
});
