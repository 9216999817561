import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import html2canvas from "html2canvas";
import { isEqual } from "lodash";

import classes from "./ProductFocusPreview.module.scss";
import { Translation } from "../../../common/Translation";

export const ProductFocusPreview = ({
  background,
  productImage,
  position, 
  setSelectedPosition, 
  savedImages, 
  deleteImage,
  component,
  saveManualOutpainting,
  customPos,
  setCustomPos,
  enableTemplateRender,
  setEnableTemplateRender,
  isUpdated,
  onImageClick,
  defaultUrl,
  saveImage,
  imageUrl,
  setImageUrl,
  format,
  scrollToPreview,
  productImageWidth,
  setProductImageWidth,
  color,
  secondaryColor,
  selectedSetting,
  setColor,
  setSecondayColor,
  setSelectedSetting,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [draggingDelta, setDraggingDelta] = useState({ x: 0, y: 0});
  const [lastBackground, setLastBackground] = useState(background);
  const [lastPosition, setLastPosition] = useState(position);
  const [lastPositionStyle, setLastPositionStyle] = useState();
  const [lastFormat, setLastFormat] = useState(format);
  const [needSaveImage, setNeedSaveImage] = useState(false);
  const [base64Image, setBase64Image] = useState(null);
  const [resizingInfo, setResizingInfo] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [history, setHistory] = useState([]);
  const [historyIdx, setHistoryIdx] = useState(-1);
  const [savingCounter, setSavingCounter] = useState(0);

  const targetWidth = useMemo(() => {
    switch (format) {
      case "landscape": return 1000;
      default: return 500;
    }
  }, [format]);

  const imageRef = useRef();
  const previewRef = useRef();
  const [cursorStyle, setCursorStyle] = useState({});

  const imageComponent = useMemo(() => {
    const images = component.findType("mj-image");
    return images.length ? images[0] : null;
  }, [component]);  

  const positionStyle = useMemo(() => {

    if (!previewRef.current) {
      return ;
    }

    if (resizingInfo) {
      return resizingInfo.style;
    }

    const previewRect = previewRef.current.getBoundingClientRect();
    const scale = targetWidth / previewRect.width;

    switch (position) {
      case "left": return { left: "10%" };
      case "right": return { right: "10%" };
      case "custom": return { left: customPos.x / scale, top: customPos.y / scale};
      default: return {};
    }
  }, [position, customPos, targetWidth, resizingInfo]);

  const previewWidth = useMemo(() => {

    if (!previewRef.current || !productImageWidth || !isImageLoaded) {
      return null;
    }

    const previewRect = previewRef.current.getBoundingClientRect();
    const scale = targetWidth / previewRect.width;

    return productImageWidth / scale;
  }, [productImageWidth, targetWidth, isImageLoaded]);

  useEffect(() => {
    setBase64Image(null);
    setIsImageLoaded(false);
    fetch(`${productImage}?t=${Date.now()}`).then(response => {
      response.blob().then(blob => {
        const reader = new FileReader();
        reader.onload = () => {
          setBase64Image(reader.result);
          scrollToPreview();
        };
        reader.readAsDataURL(blob);
      });
    });
  }, [productImage, scrollToPreview]);

  const calcCursorStyle = useCallback(() => {
    const CURSOR_WIDTH = 15;
    const CURSOR_HEIGHT = 27;

    if (imageRef.current && previewRef.current) {
      const imageRect = imageRef.current.getBoundingClientRect();
      const previewRect = previewRef.current.getBoundingClientRect();

      const newCursorStyle = {
        left: imageRect.left - previewRect.left - CURSOR_WIDTH,
        top: imageRect.top - previewRect.top + (imageRect.height / 2) - (CURSOR_HEIGHT / 2),
      };

      if (!isEqual(cursorStyle, newCursorStyle)) {
        setCursorStyle({
          left: imageRect.left - previewRect.left - CURSOR_WIDTH,
          top: imageRect.top - previewRect.top + (imageRect.height / 2) - (CURSOR_HEIGHT / 2),
        });
      }
    }
  }, [cursorStyle]);

  useEffect(() => {
    calcCursorStyle();
  }, [positionStyle, calcCursorStyle, format]);

  const updateHistory = useCallback((reset = false) => {

    const toPush = {
      customPos,
      position,
      color,
      secondaryColor,
      selectedSetting,
      productImageWidth
    };

    
    if (isEqual(history[historyIdx], toPush)) {
      return ;
    }    

    setHistory(prev => [...prev.slice(0, reset ? 0 : historyIdx + 1), toPush]);
    setHistoryIdx(prev => prev + 1);
  }, [historyIdx, customPos, position, history, color, secondaryColor, selectedSetting, productImageWidth]);

  const onUndo = (event) => {
    event.stopPropagation();
    const values = history[historyIdx - 1];
    setHistoryIdx(prev => prev - 1);
    setCustomPos(values.customPos);
    setSelectedPosition(values.position);
    setColor(values.color);
    setSecondayColor(values.secondaryColor);
    setSelectedSetting(values.selectedSetting);
    setProductImageWidth(values.productImageWidth);
  };

  const onRedo = (event) => {
    event.stopPropagation();
    const values = history[historyIdx + 1];
    setHistoryIdx(prev => prev + 1);
    setCustomPos(values.customPos);
    setSelectedPosition(values.position);
    setColor(values.color);
    setSecondayColor(values.secondaryColor);
    setSelectedSetting(values.selectedSetting);
    setProductImageWidth(values.productImageWidth);
  };

  const renderPreviewInTemplate = useCallback((forceRender = false) => {
    const element = previewRef.current;
    const imgElement = imageComponent.getEl().getElementsByTagName("img")[0];
    const imageRect = imageRef.current.getBoundingClientRect();
    const previewRect = previewRef.current.getBoundingClientRect();

    setLastBackground(background);
    setLastPosition(position);
    setLastPositionStyle(positionStyle);
    setLastFormat(format);
    setImageUrl(null);
    setSavingCounter(prev => prev + 1);

    const scale = targetWidth / previewRect.width;

    html2canvas(element, {
      useCORS: true,
      allowTaint: true,
      onclone: async (doc) => {
        const previewImageElement = doc.getElementById("previewImage");
        previewImageElement.style.border = "none";
        const previewContainerElement = doc.getElementById("previewContainer");
        previewContainerElement.style.border = "none";
        previewContainerElement.style.borderRadius = 0;

        //prevent reload fonts in html2canvas => https://github.com/niklasvh/html2canvas/issues/1940
        Array.from(
          doc.querySelectorAll("*"),
        ).forEach((e) => {
          const existingStyle = e.getAttribute("style") || "";
          e.setAttribute("style", existingStyle + "; font-family: Helvetica, sans-serif !important");
        });
      },
      scale,
    }).then(canvas => {
      const dataUrl = canvas.toDataURL("image/png");
      if (enableTemplateRender || forceRender) {
        imgElement.src = dataUrl;
      }

      updateHistory();
  
      saveManualOutpainting(dataUrl, (imageRect.left - previewRect.left) * scale, (imageRect.top - previewRect.top) * scale).then(url => {
        setImageUrl(url);
        setSavingCounter(prev => prev - 1);
      });
    });
    
  }, [imageComponent, background, saveManualOutpainting, enableTemplateRender, setImageUrl, targetWidth, position, positionStyle, format, updateHistory]);

  useEffect(() => {
    if (background !== lastBackground || position !== lastPosition || (positionStyle && !isDragging && !isEqual(positionStyle, lastPositionStyle)) || lastFormat !== format) {
      if (!resizingInfo) {
        renderPreviewInTemplate();
      }
    }
  }, [background, lastBackground, renderPreviewInTemplate, position, lastPosition, positionStyle, lastPositionStyle, isDragging, lastFormat, format, resizingInfo]);

  const onMouseMove = useCallback((event) => {
    if (isDragging) {
      setSelectedPosition("custom");
      const imageRect = imageRef.current.getBoundingClientRect();
      const previewRect = previewRef.current.getBoundingClientRect();

      const newPos = {
        x: event.screenX - draggingDelta.x,
        y: event.screenY - draggingDelta.y,
      };

      if (newPos.x < 0) {
        newPos.x = 0;
      }

      if (newPos.x + imageRect.width > previewRect.width) {
        newPos.x = previewRect.width - imageRect.width;
      }

      if (newPos.y < 0) {
        newPos.y = 0;
      }

      if (newPos.y + imageRect.height > previewRect.height) {
        newPos.y = previewRect.height - imageRect.height;
      }

      const scale = targetWidth / previewRect.width;

      setCustomPos({ x: newPos.x * scale, y: newPos.y * scale });
    }

    if (resizingInfo) {
      setSelectedPosition("custom");
      const imageRect = imageRef.current.getBoundingClientRect();
      const previewRect = previewRef.current.getBoundingClientRect();
      const scale = targetWidth / previewRect.width;
      let newWidth;
      switch (resizingInfo.corner) {
        case "nw": 
        case "sw":
          newWidth = imageRect.width + (imageRect.left - event.x); 
          break;
        case "ne": 
        case "se":
          newWidth = imageRect.width + (event.x - imageRect.right); 
          break;
      }

      //limits
      if (resizingInfo.corner.includes("n")) {
        const ratio = imageRect.width / imageRect.height;
        const newHeight = newWidth / ratio;
        if (imageRect.bottom - newHeight < previewRect.top) {
          newWidth = (imageRect.bottom - previewRect.top) * ratio;
        }
      }
      if (resizingInfo.corner.includes("s")) {
        const ratio = imageRect.width / imageRect.height;
        const newHeight = newWidth / ratio;
        if (imageRect.top + newHeight > previewRect.bottom) {
          newWidth = (previewRect.bottom - imageRect.top) * ratio;
        }
      }
      if (resizingInfo.corner.includes("w")) {
        if (imageRect.right - newWidth < previewRect.left) {
          newWidth = imageRect.right - previewRect.left;
        }
      }
      if (resizingInfo.corner.includes("e")) {
        if (imageRect.left + newWidth > previewRect.right) {
          newWidth = previewRect.right - imageRect.left;
        }
      }
      if (newWidth < 50) {
        newWidth = 50;
      }

      if (newWidth) {
        setProductImageWidth(newWidth * scale);
      }
    }

  }, [setSelectedPosition, isDragging, draggingDelta, setCustomPos, targetWidth, resizingInfo, setProductImageWidth]);

  const onMouseUp = useCallback(() => {
    setIsDragging(false);
    
    if (resizingInfo) {
      const imageRect = imageRef.current.getBoundingClientRect();
      const previewRect = previewRef.current.getBoundingClientRect();
      const scale = targetWidth / previewRect.width;

      setCustomPos({
        x: (imageRect.left - previewRect.left) * scale,
        y: (imageRect.top - previewRect.top) * scale,
      });

      setResizingInfo(null);
    }

  }, [resizingInfo, targetWidth, setCustomPos]);

  useEffect(() => {

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [onMouseMove, onMouseUp]);

  const onMouseDown = (event) => {
    event.stopPropagation();
    setIsDragging(true);
    onPreviewClick();
    const imageRect = imageRef.current.getBoundingClientRect();
    const previewRect = previewRef.current.getBoundingClientRect();

    setDraggingDelta({
      x: event.screenX - (imageRect.left - previewRect.left),
      y: event.screenY - (imageRect.top - previewRect.top),
    });

    const scale = targetWidth / previewRect.width;

    setCustomPos({
      x: (imageRect.left - previewRect.left) * scale,
      y: (imageRect.top - previewRect.top) * scale,
    });
  };

  const onLoad = () => {
    calcCursorStyle();
    setLastPositionStyle(null);
    //force reload position and trigger render
    setCustomPos(prev => ({ ...prev }));

    if (!productImageWidth) {
      const imageRect = imageRef.current.getBoundingClientRect();
      const previewRect = previewRef.current.getBoundingClientRect();
      const scale = targetWidth / previewRect.width;
      setProductImageWidth(imageRect.width * scale);
    }

    setIsImageLoaded(true);
    updateHistory(true);
  };

  const onPreviewClick = () => {
    setEnableTemplateRender(true);
    if (!enableTemplateRender) {
      if (isUpdated) {
        renderPreviewInTemplate(true);
      } else {
        onImageClick(defaultUrl);
      }
    }
  };

  useEffect(() => {
    if (needSaveImage && imageUrl && savingCounter === 0) {
      setNeedSaveImage(false);
      saveImage(imageUrl);
    }
  }, [needSaveImage, imageUrl, saveImage, savingCounter]);

  const onResize = (event, corner) => {
    event.stopPropagation();
    const previewRect = previewRef.current.getBoundingClientRect();
    const imageRect = imageRef.current.getBoundingClientRect();

    switch (corner) {
      case "nw": 
        setResizingInfo({
          corner,
          style: { bottom: previewRect.bottom - imageRect.bottom, right: previewRect.right - imageRect.right }
        });
        break;
      case "sw": 
        setResizingInfo({
          corner,
          style: { top: imageRect.top - previewRect.top, right: previewRect.right - imageRect.right }
        });
        break;
      case "ne": 
        setResizingInfo({
          corner,
          style: { bottom: previewRect.bottom - imageRect.bottom, left: imageRect.left - previewRect.left }
        });
        break;
      case "se": 
        setResizingInfo({
          corner,
          style: { top: imageRect.top - previewRect.top, left: imageRect.left - previewRect.left }
        });
        break;
    }
  };

  const isWidthCalculating = useMemo(() => (!previewWidth && productImageWidth), [productImageWidth, previewWidth]);

  if (!base64Image) {
    return ;
  }

  return (
    <div
      id="previewContainer"
      className={`${classes.container} ${classes[`container-${format}`]} ${enableTemplateRender ? classes.selected : "" }`}
      ref={previewRef}
      style={{
        background,
      }}
      onClick={onPreviewClick}
    >
      <div 
        className={classes.gradient}
        data-html2canvas-ignore
      />
        
      <div
        className={`${classes.productImageContainer} ${!productImageWidth ? classes.productImageContainerMaxSize : ""} ${isDragging ? classes.dragging : ""}`}
        style={{ ...positionStyle, visibility: isWidthCalculating ? "hidden" : "visible" }}
        onMouseDown={onMouseDown}
        draggable={false}
        onClick={(event => event.stopPropagation())}
      >
        <img
          id="previewImage"
          className={classes.productImage}
          ref={imageRef}
          src={base64Image}
          alt="Product"
          draggable={false}
          onLoad={onLoad}
          style={previewWidth ? { width: previewWidth } : {}}
        />

        <div className={classes.resizerCorner} onMouseDown={(event) => onResize(event, "nw")} style={{ top: -5, left: -5, cursor: "nw-resize" }} data-html2canvas-ignore />
        <div className={classes.resizerCorner} onMouseDown={(event) => onResize(event, "ne")} style={{ top: -5, right: -5, cursor: "ne-resize" }} data-html2canvas-ignore />
        <div className={classes.resizerCorner} onMouseDown={(event) => onResize(event, "sw")} style={{ bottom: -5, left: -5, cursor: "sw-resize" }} data-html2canvas-ignore />
        <div className={classes.resizerCorner} onMouseDown={(event) => onResize(event, "se")} style={{ bottom: -5, right: -5, cursor: "se-resize" }} data-html2canvas-ignore />
      </div>

      {!resizingInfo &&
        <div 
          data-html2canvas-ignore
          className={`${classes.cursor} ${isDragging ? classes.dragging : ""}`}
          style={{ ...cursorStyle, visibility: isWidthCalculating ? "hidden" : "visible" }}
          onMouseDown={onMouseDown}
          onClick={(event => event.stopPropagation())}
        >
          <div className={classes.dotsLine}>
            <div className={classes.dot} />
            <div className={classes.dot} />
          </div>
          <div className={classes.dotsLine}>
            <div className={classes.dot} />
            <div className={classes.dot} />
          </div>
          <div className={classes.dotsLine}>
            <div className={classes.dot} />
            <div className={classes.dot} />
          </div>
        </div>
      }

      <div data-html2canvas-ignore className={classes.undoRedoContainer}>
        {historyIdx > 0 && <div className={classes.undoContainer} onClick={onUndo}>
          <i className="fa-solid fa-rotate-left" />
          {historyIdx === history.length - 1 && <Translation id="button.previous" />}
        </div>}
        {historyIdx < history.length - 1 &&
          <div className={classes.undoContainer} onClick={onRedo}>
            <i className="fa-solid fa-rotate-right" />
          </div>
        }
      </div>

      <i 
        data-html2canvas-ignore
        className={`${classes.bookmark} ${savedImages.find(({ url }) => url === imageUrl) ? "fa-solid" : "fa-light"} fa-bookmark`}
        onClick={(e) => {
          e.stopPropagation(); 
          if (savedImages.find(({ url }) => url === imageUrl)) {
            deleteImage(imageUrl);
          } else {
            setNeedSaveImage(true);
          }
        }}
      />

    </div>
  );
};