import { useEffect, useCallback, useContext } from "react";

import { TemplateEditorContext } from "../TemplateEditorContext";
import { AccountContext } from "../../../contexts/context";
import { getPriceFromProduct } from "../grapesJsKiliba/blocks/products";

//Update price on template load
export const useUpdatePrice = (editor, isReadOnly) => {

  const { products } = useContext(TemplateEditorContext);
  const { account } = useContext(AccountContext);

  const onComponentMount = useCallback(component => {
    if (component.attributes.attributes["data-product-id"]) {
      const product = products.find(({ id_product }) => id_product === component.attributes.attributes["data-product-id"]);
      if (product) {
        component.components(getPriceFromProduct(product, account.currency, account.email_html_options?.currencyBefore));
      }
    }
    
  }, [products, account]);

  useEffect(() => {
    if (editor && !isReadOnly && products) {
      editor.on("component:mount", onComponentMount);
      return () =>  editor.off("component:mount", onComponentMount);
    }
  }, [editor, onComponentMount, isReadOnly, products]);
};