import { extractWordingsFromMjTextComponent } from "../utils";
import { preheader } from "./preheader";

export const defaultWordings = {
  preheader: {
    fr: "Ceci est un preheader",
    en: "This is a preheader",
    es: "Esto es un preencabezamiento",
    de: "Dies ist ein Preheader",
    nl: "Dit is een voorkop",
    it: "Questo è un preheader",
    pt: "Este é um pré-cabeçalho",
  },
};

export const getPreheaderBlocks = ({ fontFamily, textColor, dominantColor }) => {

  return [
    preheader({ fontFamily, textColor, backgroundColor: dominantColor, title: defaultWordings.preheader })
  ];
};

export const extractContentFromPreheaderComponent = (component, wordings, language) => {

  const content = {};

  const textComponents = component.findType("mj-text");

  textComponents.forEach(textComponent => {
    if (textComponent.attributes.attributes["data-text-id"] === "title") {
      content.title = extractWordingsFromMjTextComponent(textComponent, wordings, language);
      const props = textComponent.get("script-props");
      content.wordingId = props[0].wordingId;
    }
  });

  content.backgroundColor = component.attributes.attributes["background-color"];

  content.isVisible = component.attributes.attributes["css-class"] !== "hiddenPreheader";

  return content;
};
