import React, { Component } from "react";
import { injectIntl } from "react-intl";
import classes from "./ParametersSection.module.scss";


export const ParametersSection = (
  injectIntl(
    class ParametersSection extends Component {
      getSeparatedElements() {
        const { children } = this.props;
        const separatedElements = [];

        children.forEach((child, key) => {
          if (!child) return;
          const isLastChild = key === children.length - 1;
          const separator = (
            <div key={`separator-${key}`} style={{ height: "1px", width: "100%", backgroundColor: "#f0f2f4" }}></div>
          );
          const element = (
            <div key={`element-${key}`} className={classes.cardBlock} style={{ flex: isLastChild ? "auto" : null }}>
              {child}
            </div>
          );
          separatedElements.push(element, !isLastChild ? separator : undefined);
        });

        return separatedElements;
      }

      render() {
        const {  title, status } = this.props;
        const separatedElements = this.getSeparatedElements();

        return (
          <div className={classes.parametersSection} ref={this.props.forwardRef}>
            <div className={classes.card}>
              {title ? <h2 className={classes.cardTitle}>{title}</h2> : null}
              {
                status !== undefined
                  ? (
                    <div className={classes.statusIndicatorContainer}>
                      <div className={`${classes.statusIndicator} ${status}`}></div>
                      {/* <StatusIndicator status={status} /> */}
                    </div>
                  )
                  : null
              }
              <div>
                {separatedElements}
              </div>
            </div>
          </div>
        );
      }
    }
  )
);
