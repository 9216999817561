import { Translation } from "../../common/Translation";
import { ParametersSection } from "../ParametersSections/ParametersSection";
import classes from "../TemplateCustomization.module.scss";
import image from "../../../public/template-customization-checklist.png";

export const CheckList = (props) => {

  return (
    <ParametersSection
      className={classes.e}
      title={<Translation id="templateCustomization.checkList.title" />}
      children={[<CheckListContent value={props.value} key={0} />]}
    />
  );
};

const CheckListContent = (props) => {

  return (
    <div className={classes.checkListContent}>
      <ul className={classes.checkListItems}>
        {
          props.value.map((checkListItem, key) => {
            return (
              <li
                className={classes.checkListItem}
                key={key}
              >
                {/* <StatusIndicator status={checkListItem.status} /> */}
                <div className={`${classes.statusIndicator} ${checkListItem.status}`}></div>

                <p className="text">
                  <Translation id={`templateCustomization.checkList.${checkListItem.key}`} />
                </p>
              </li>
            );
          })
        }
      </ul>
      <img className={classes.checkListImage} src={image} alt="checklist" />
    </div>
  );
};
