import React, { Component } from "react";
import { CustomButton } from "../Button/CustomButton";
import { injectIntl } from "react-intl";

export const NoCmsCta = injectIntl(
  class NoCmsCta extends Component {
    render() {
      const {
        intl,
        onClick,
        text = intl.messages["noCms.cta.default"],
        type,
      } = this.props;

      return (
        <CustomButton
          type={type || "primary"}
          onClick={onClick}
          dataCy="NoCms-cta"
          size="sm"
        >
          {text}
        </CustomButton>
      );
    }
  }
);
