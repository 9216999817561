import { Welcome } from "./Welcome/Welcome";
import { JobsToBeDone } from "./JobsToBeDone/JobsToBeDone";
import { ShopInfo } from "./ShopInfo/ShopInfo";
import { ChooseCms } from "./ChooseCms/ChooseCms";
import { ConnectYourShop } from "./ConnectYourShop/ConnectYourShop";
import { CmsCongrats } from "./CmsCongrats/CmsCongrats";
import { TemplateCustomization } from "./TemplateCustomization/TemplateCustomization";
import { UsecaseSelection } from "./UsecaseSelection/UsecaseSelection";
import { VerifyEmail } from "./VerifyEmail/VerifyEmail";

export const OnBoardingPages = {
  Welcome,
  JobsToBeDone,
  ShopInfo,
  ConnectYourShop,
  ChooseCms,
  CmsCongrats,
  TemplateCustomization,
  UsecaseSelection,
  VerifyEmail
};

