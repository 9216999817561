export const setUpLinkComponent = (editor) => {
  editor.Components.addType("link", {
    view: {
      events: {
        click: "clickOnElement"
      },

      clickOnElement(event) {        
        let isRteEnabled = false;
        this.model.parents().forEach(parent => {
          if (parent.is("mj-text") && parent.getView().rteEnabled) {
            isRteEnabled = true;
          }
        });

        if (isRteEnabled) {
          return ;
        }

        if (this.lastEventTime && Date.now() - this.lastEventTime < 400) {
          clearTimeout(this.clickTimeout);
          this.onActive();
          return ;
        }

        this.lastEventTime = Date.now();
        this.clickTimeout = setTimeout(() => {
          editor.trigger("linkClick", { event, component: this });
        }, 500);
      },
    }
  });
};