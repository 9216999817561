
export const setUpButtonComponent = (editor) => {
  editor.Components.addType("mj-button", {
    model: {
      getAttrToHTML() {
        //Code from https://github.com/GrapesJS/mjml/blob/master/src/components/index.ts
        const attr = { ...this.get("attributes") };
        const style = { ...this.get("style-default") };
        delete attr.style;
        delete attr.id;
  
        for (const prop in attr) {
          const value = attr[prop];
  
          if (value && value === style[prop]) {
            delete attr[prop];
          }
        }

        const props = this.get("script-props");
        const translatableAttributes = props?.length ? props[0].translatableAttributes : [];
        for (const translatableAttribute of translatableAttributes || []) {
          attr[translatableAttribute.key] = `{{${translatableAttribute.attributeId}}}`;
        }

        return attr;
      },
      toHTML() {
        //Code from https://github.com/GrapesJS/mjml/blob/master/src/components/index.ts

        const model = this;
        const tag = model.get("tagName");
        const voidTag = model.get("void");
        const attr = this.getAttrToHTML();
        let code = "";
        let strAttr = "";

        for (const prop in attr) {
          const val = attr[prop];
          const hasValue = typeof val !== "undefined" && val !== "";
          strAttr += hasValue ? ` ${prop}="${val}"` : "";
        }

        code += `<${tag}${strAttr}${voidTag ? "/" : ""}>` + model.get("content");

        const props = model.get("script-props");
        if (props && props?.length && props[0].wordingId) {
          code += `{{${props[0].wordingId}}}`;
        } else {
          model.components().forEach(model => {
            code += model.toHTML();
          });
        }

        if (!voidTag) {
          code += `</${tag}>`;
        }

        return code;
      }
    },
    view: {
      events: {
        click: "clickOnElement",
        input: "onInput",
      },

      clickOnElement(event) {
        if (event.target.tagName !== "A" && 
          event.target.tagName !== "P" &&
          event.target.tagName !== "DIV" && 
          event.target.tagName !== "SPAN" && 
          event.target.tagName !== "B" && 
          event.target.tagName !== "STRIKE" && 
          event.target.tagName !== "U" && 
          event.target.tagName !== "I"
        ) {
          return this.onDisable();
        }

        const { em } = this;
        const parent = this.model.closest("[data-gjs*=\"kilibaBlock\"]");
        
        if (parent && em.getSelected()?.getId() === parent.getId()) {
          this.onActive();
          editor.trigger("buttonClick", this.model);
        }
      },

      onInput() {
        const { em } = this;
        const evPfx = "component";
        const ev = [`${evPfx}:update`, `${evPfx}:input`].join(" ");

        em && em.trigger(ev, this.model);
      }
    }
  });
};