import { useContext, useCallback, useMemo, useState, useEffect } from "react";
import { useIntl } from "react-intl";

import { TemplateEditorContext } from "../TemplateEditorContext";
import { Translation } from "../../common/Translation";

import classes from "./ProductMenu.module.scss";
import { makeTranslatable } from "../grapesJsKiliba/blocks/utils";
import { NewsletterChooseProductInput } from "../../Newsletter/NewsletterChooseProductInput";

export const getContentFromProduct = product => {
  let productHref = product.absolute_url;
  if (!productHref?.startsWith("http")) {
    productHref = `https://${productHref}`;
  }

  return {
    product,
    imageHref: makeTranslatable(productHref),
    buttonHref: makeTranslatable(productHref),
    imageUrl: makeTranslatable(product.image_url),
  };
};

export const ProductMenu = ({ onChange, content }) => {

  const { products, addProduct, productsInTemplate } = useContext(TemplateEditorContext);
  const [productIdToSelect, setProductIdToSelect] = useState(null);
  const [disabledPopover, setDisabledPopover] = useState(null);
  const intl = useIntl();

  const getTitleFromJson = useCallback((title) => title[intl.locale] || title.fr || title.en || title.es || title.de || title.nl || "", [intl]);

  const MAX_PRODUCTS = useMemo(() => {
    return Math.min(6 - productsInTemplate.length, 3);
  }, [productsInTemplate]);

  const selectedProductIds = useMemo(() => {
    if (content?.product) {
      return [content.product.id_product];
    }

    if (content?.products) {
      return content?.products.map(({ id_product }) => id_product);
    }

    return [];
  }, [content]);

  const setProductsContent = useCallback((productIds) => {
    if (productIds.length === 0) {
      onChange({
        product: undefined,
        products: undefined,
        imageHref: undefined,
        imageUrl: undefined,
        buttonHref: undefined,
        imagesHref: undefined,
        imagesUrl: undefined,
        buttonsHref: undefined,
      });
    } else if (productIds.length === 1) {
      onChange({ ...getContentFromProduct(products.find(({ id_product }) => id_product === productIds[0])), products: null });
    } else {
      onChange({
        product: null,
        imageHref: null,
        imageUrl: null,
        buttonHref: null,
        products: productIds.map(id => products.find(({ id_product }) => id_product === id)),
        imagesHref: productIds.map(id => getContentFromProduct(products.find(({ id_product }) => id_product === id)).imageHref),
        imagesUrl: productIds.map(id => getContentFromProduct(products.find(({ id_product }) => id_product === id)).imageUrl),
        buttonsHref: productIds.map(id => getContentFromProduct(products.find(({ id_product }) => id_product === id)).buttonHref),
      });
    }
  }, [products, onChange]);

  const onProductClick = useCallback((productId, event) => {

    let result = [...selectedProductIds];

    if (result.includes(productId)) {
      result = result.filter(id => id !== productId);
    } else {
      result.push(productId);
    }

    if (result.length > MAX_PRODUCTS) {

      const container = document.getElementsByClassName(classes.container)[0];
      const element = document.getElementById(`product-${productId}`);

      if (MAX_PRODUCTS < 3) {
        setDisabledPopover({ top: element?.getBoundingClientRect().bottom - container.getBoundingClientRect().top + 15, translationId: "rightMenu.disabledPopover.product"  });
      } else {
        setDisabledPopover({ top: element?.getBoundingClientRect().bottom - container.getBoundingClientRect().top + 15, translationId: "productMenu.error"  });
      }

      return ;
    }

    setProductsContent(result);
  }, [selectedProductIds, setProductsContent, MAX_PRODUCTS]);

  useEffect(() => {
    if (productIdToSelect && products.find(({ id_product }) => id_product === productIdToSelect)) {
      const result = [...new Set([...selectedProductIds, productIdToSelect])];

      if (result.length > MAX_PRODUCTS) {

        const ordered = result.map(id_product => ({ id_product, index: products.findIndex(product => product.id_product === id_product) })).sort((a, b) => b.index - a.index);
        const toRemove = ordered[0].id_product;

        setProductsContent(result.filter(id => id !== toRemove));
      } else {
        setProductsContent(result);
      }

      setProductIdToSelect(null);
    }
  }, [productIdToSelect, products, selectedProductIds, setProductsContent, MAX_PRODUCTS]);

  const onAddProduct = product => {
    addProduct(product);
    setProductIdToSelect(product.id_product);
  };

  useEffect(() => {
    if (disabledPopover) {
      const timeout = setTimeout(() => setDisabledPopover(null), 4000);
      return () => clearTimeout(timeout);
    }
  }, [disabledPopover]);

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>
        {MAX_PRODUCTS > 1 ?
          <Translation id={"newsletter.rightMenu.content.product.title"} variables={{ nb: MAX_PRODUCTS }} /> :
          < Translation id={"newsletter.rightMenu.content.product.title"} variables={{ nb: MAX_PRODUCTS }} />
        }
      </h2>
      <div className={classes.separator} />
      <NewsletterChooseProductInput 
        placeholder={intl.messages["productMenu.input.placeholder"]}
        onAddProduct={onAddProduct}
      />
      <div className="textMedium"><Translation id="productMenu.title" /></div>
      <div className={classes.productsContainer}>
        {products?.length && products.map(product => (
          <div id={`product-${product.id_product}`} className={`${classes.product} ${selectedProductIds.includes(product.id_product) ? classes.productSelected : ""}`} key={product.id_product} onClick={event => onProductClick(product.id_product, event)}>
            <img className={classes.productImage} height={70} alt="" src={product.image_url} />
            <div className={`text ${classes.productTitle}`}>{getTitleFromJson(product.title)}</div>
          </div>
        ))}
      </div>
      {disabledPopover && 
        <div className={`text ${classes.disabledPopover}`} style={{ top: disabledPopover.top }}>
          <Translation id={disabledPopover.translationId} />
          <i className="fa-solid fa-times" onClick={() => setDisabledPopover(null)}/>
        </div>
      }
    </div>
  );
};
