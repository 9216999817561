import { CustomButton } from "../../common/Button/CustomButton";
import { Dialog } from "../../common/Dialog";
import { Translation } from "../../common/Translation";

import classes from "./ConfirmDeleteImagePopup.module.scss";

export const ConfirmDeleteImagePopup = ({ isOpen, close, deleteImage, nbImages }) => {

  return (
    <Dialog
      isOpen={isOpen}
      close={close}
      style={{ padding: "60px 80px" }}
      PaperProps={{ style: { borderRadius: 15 } }}
    >
      <div className={classes.container}>
        <h4 className={classes.title}>
          {!nbImages || nbImages === 1 ? 
            <Translation id="confirmDeleteImagePopup.title" /> :
            <Translation id="confirmDeleteImagePopup.title.multiple" variables={{ nbImages }} />
          }
        </h4>
        <div className={classes.buttonsContainer}>
          <CustomButton
            type="secondary"
            size="sm"
            onClick={close}
          >
            <Translation id="button.anulation" />
          </CustomButton>
          <CustomButton
            type="primary"
            size="sm"
            onClick={deleteImage}
          >
            <Translation id="button.remove" />
          </CustomButton>
        </div>
      </div>
    </Dialog>
  );
};