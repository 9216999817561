import React, { Component } from "react";

import { injectIntl } from "react-intl";

import { TextField } from "../../../../../../common/Input/TextField.js";

export const ValueInput = injectIntl(
  class extends Component {
    render() {
      const {
        intl,
        promoCodeTemplateWrapper,
        setPromoCodeTemplateWrapperProp,
        getError,
      } = this.props;

      return (
        <TextField
          dataCy="valueInput"
          value={promoCodeTemplateWrapper.promoCodeTemplate.value}
          errorMessage={getError("value") && getError("value").message}
          placeholder={
            intl.messages["usecase.promoCodeTemplateEditor.value.placeholder"]
          }
          onChange={(event) => {
            let value =
              event.target.value === "" ? null : Number(event.target.value);
            if (
              promoCodeTemplateWrapper.promoCodeTemplate.type === "%" &&
              value > 100
            )
              value = 100;
            if (value < 0) value = 0;
            setPromoCodeTemplateWrapperProp("promoCodeTemplate.value", value);
          }}
          type="number"
          style={{ width: 192 }}
          marginIsEnabled={false}
        />
      );
    }
  }
);
