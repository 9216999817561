import React from "react";
import { useAuth } from "../hooks/useAuth";

export function withAuth(AuthComponent) {
  return function AuthWrapped(props) {
    const auth = useAuth();
    return (
      <AuthComponent
        {...props}
        auth={auth}
        id_account={auth.getAccountId()}
      />
    );
  };
}