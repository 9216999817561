import React from "react";
import { CircularProgress } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { styles } from "./CustomButton.styles";
import { Tooltip } from "../../Tooltip.js";
import PropTypes from "prop-types";

const useStyles = makeStyles(styles);

export function CustomButton(props) {
  const classes = useStyles();

  const typeClass = classes[props.type];
  const disabledClasses = (props.disabled || props.loading) ? `${classes.disabled} ${classes[`${props.type}Disabled`]}` : null;
  const sizeClass = classes[props.size];

  const button = (
    <button
      id={props.id}
      onClick={props.onClick}
      style={props.style}
      className={`${classes.button} ${typeClass} ${sizeClass} ${disabledClasses}`}
      data-cy={props.dataCy}
      disabled={props.disabled || props.loading}
    >
      {
        props.loading
          ? (
            <CircularProgress size="20px" />
          )
          : props.children 
      }
     
    </button>
  );

  return (
    props.tooltipMessage
      ? <Tooltip title={props.tooltipMessage}>{button}</Tooltip>
      : button
  );
}

CustomButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]).isRequired,
  size: PropTypes.oneOf(["lg", "md", "sm", "xs", "xxs"]).isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  dataCy: PropTypes.string,
};

CustomButton.defaultProps = {
  type: "primary",
  size: "lg",
  loading: false,
  disabled: false,
};
