import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import { palette } from "../../../styles/palette.js";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  loading: {
    color: palette.yellow,
    marginTop: "30px",
  },
  buttonSubmit: {
    width: "auto",
    padding: "0 25px 0 25px",
    marginTop: "30px",
    color: "black",
    borderRadius: "15px",
    height: "40px",
    border: "none",
    backgroundColor: palette.yellow,
    fontFamily: "Coolvetica book",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#f0c678",
      cursor: "pointer",
    },
    "&:disabled": {
      cursor: "not-allowed",
      backgroundColor: palette.gray,
    }
  },
});

export const SubmitButton = injectIntl(
  withStyles(styles)(
    class extends Component {
      render() {
        const { id, classes, onClick, dataCy, label, style, disabled } = this.props;

        return !this.props.isLoading ? (
          <button
            style={style}
            className={classes.buttonSubmit}
            onClick={onClick}
            id={id}
            data-cy={dataCy}
            label={label}
            disabled={disabled}
          >
            <FormattedMessage id={this.props.label} defaultMessage="cgv" />
          </button>
        ) : (
          <CircularProgress size={34} className={classes.loading} />
        );
      }
    }
  )
);
