import { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import { Translation } from "../common/Translation";
import { CustomButton } from "../common/Button/CustomButton";
import { SelectedProductsExceededPopup } from "./SelectedProductsExceededPopup";
import { NewsletterChooseProduct } from "./NewsletterChooseProduct";
import { NewsletterChooseName } from "./NewsletterChooseName";
import { NewsletterChooseObjective } from "./NewsletterChooseObjective";
import { NewsletterChooseDate } from "./NewsletterChooseDate";
import { useAuth } from "../../hooks/useAuth";
import { AccountContext, reloadAccountContext } from "../../contexts/context";
import { LoadingPopup } from "../Newsletter/LoadingPopup";
import { EventSystem } from "../../eventsystem/EventSystem";
import { NewsletterStats } from "./NewsletterStats";
import { NewsletterCard } from "./NewsletterCard";

import image from "../../public/newsletterForm.png";
import classes from "./Newsletter.module.scss";

const STEPS = {
  objective: 1,
  product: 2,
  name: 3,
  template: 4,
  date: 5
};

export const Newsletter = () => {
  const auth = useAuth();
  const intl = useIntl();

  const history = useHistory();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { account } = useContext(AccountContext);
  const [hasNoCms, setHasNoCms] = useState(false);

  const [step, setStep] = useState(null);
  const [selectedProductsExceededPopupIsOpened, setSelectedProductsExceededPopupIsOpened] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [templateId, setTemplateId] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const [isLoadingPopup, setIsLoadingPopup] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFirstSmartletter, setIsFirstSmartletter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [template, setTemplate] = useState(null);
  const [wordings, setWordings] = useState(null);
  const [variables, setVariables] = useState(null);
  const [language, setLanguage] = useState();
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [defaultProducts, setDefaultProducts] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [dateLastSync, setDateLastSync] = useState(null);


  useEffect(() => {
    auth.fetch("/data/getNewsletterStatistics").then(({ statistics, date_last_sync }) => {
      setStatistics(statistics.length > 0 ? statistics : ["empty"]);
      setDateLastSync(date_last_sync);
    });
  }, [auth]);  

  useEffect(() => {
    setStep(null);
  }, [history.location]);

  const createTemplate = () => {

    setLoading(true);

    auth.fetch("/api/account_management/saveMjmlTemplate", {
      method: "POST",
      body: JSON.stringify({
        templateId: templateId,
        name: formValues.name,
        selectedProductIds: formValues.products.map(product => product.id_product),
        objective: formValues.objective,
        percentTargetedCustomers: Math.floor(Math.random() * (70 - 60 + 1)) + 60,
        ...("isFirstGenerationDone" in formValues ? { isFirstGenerationDone: formValues.isFirstGenerationDone } : {}),
      })
    }).then(async response => {
      reloadAccountContext();
      generateDalleImages(response.templateId);
      generateOutpaintedImages(response.templateId);
      setLoading(false);   
      await reloadAccountContext();

      history.push(`/editor/${response.templateId}?from=newsletter&objective=${formValues.objective}`);
    });
  };

  const updateTemplate = async (payload) => {
    await auth.fetch("/api/account_management/saveMjmlTemplate", {
      method: "POST",
      body: JSON.stringify({
        templateId: templateId,
        ...payload,
      })
    });
    reloadAccountContext();
    setFormValues(prev => ({ ...prev, ...payload }));
  };

  const generateDalleImages = async (templateId) => {
    return await auth.fetch(`/dalle/generateDalleImages?templateId=${templateId}&language=${intl.locale}`, null, { endpoint: "llm-hub" });
  };
  const generateOutpaintedImages = async (templateId) => {
    await auth.fetch(`/account_management/generateOutpaintedImages?templateId=${templateId}`);
  };

  const onCreateNewsletter = () => {
    setFormValues({});
    setStep(1);
  };

  useEffect(() => {
    const load = async () => {
      await reloadAccountContext();
      const queryParameters = new URLSearchParams(search);
      const templateId = queryParameters.get("templateId");

      const stepParam = queryParameters.get("step");
      if (templateId && stepParam) {
        const { name, objective, template, wordings, variables, productIds, isFirstGenerationDone } = await auth.fetch(`/api/account_management/mjmlTemplate?templateId=${templateId}`);
        setTemplate(template);
        setWordings(wordings);
        setVariables(variables);
        const products = await auth.fetch("/api/data/getProductsByIds", {
          method: "POST",
          body: JSON.stringify({
            productIds: productIds,
          })
        });

        setDefaultProducts(products);
        setFormValues({
          name,
          objective,
          products,
          isFirstGenerationDone,
        });
      }
      setTemplateId(templateId);
      setStep(STEPS[stepParam]);
      setIsLoading(false);
    };
    load();
  }, [search, auth]);

  useEffect(() => {
    if(isLoaded){
      setIsLoadingPopup(false);
    }
  }, [isLoaded]);

  useEffect(() => {
    if(account.step_register > 2) {
      setHasNoCms(true);
      if(!account?.mjmlTemplates?.length && !step){
        setIsFirstSmartletter(true);
        setStep(1);
      }
    } else {
      setHasNoCms(false);
    } 
  }, [account]);

  useEffect(() => {
    if (step === 3) {
      setIsLoadingPopup(true);
      setIsLoaded(false);
    }
  }, [step]);

  //Init languages
  useEffect(() => {
    if (!availableLanguages?.length) {
      const _availableLanguages = [...new Set([
        ...account.email_lang_settings.map(({ email_lang}) => email_lang),
      ])];
      setAvailableLanguages(_availableLanguages);
      if (!language) {
        const defaultLanguage = _availableLanguages.includes(intl.locale) ? intl.locale : _availableLanguages[0];
        setLanguage(defaultLanguage);
      }
    }
  }, [intl, account, language, availableLanguages]);


  if (isLoading || !account) {
    return;
  }

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.title}>
          <Translation id="newsletter.title" />
        </div>
        <div className={classes.subtitle}>
          <Translation id="newsletter.subtitle" defaultMessage="" />
        </div>
        {
          isFirstSmartletter &&
          <div className={`display4 ${classes.subtext}`}>
            <Translation id="newsletter.createFirst" />
          </div>
        }
      </div>

      {!step && !showSummary &&
        <>
          {!account?.mjmlTemplates?.length && !hasNoCms ?

            <div className={classes.noCmsContent}>
              <div className="text">
                <Translation id="firstSteps.stepDescription0" />
              </div>
              <div className={classes.stepDescriptionButtonAndLink}>
                <CustomButton size="sm" onClick={() => history.push("/on_boarding")}>
                  <Translation id="button.connectMyShop" />
                </CustomButton>
              </div>
            </div>
            :

            <div>
              <CustomButton
                size="lg"
                onClick={onCreateNewsletter}
              >
                <Translation id="newsletter.empty.button" />
              </CustomButton>

              <NewsletterStats
                mjmlTemplates={account.mjmlTemplates}
                statistics={statistics}
              />
  
              { statistics.length > 0 &&
                <NewsletterCard
                  statistics={statistics}
                  dateLastSync={dateLastSync}
                />
              }
              
            </div>
          }

        </>
      }

      {!!step &&
        <div className={classes.productContainer}>

          <div className={classes.leftPart}>

            {
              <>
                <div className={classes.steps}>
                  {Object.entries(STEPS).map(([key, value]) => (
                    <div key={key} className={`${classes.step} ${step < value ? classes.stepDisabled : ""}`}>
                      <div className={classes.stepBar} />
                      <div className="infoMedium"><Translation id={`newsletter.step.${key}`} /></div>
                    </div>
                  ))}
                </div>

                {step === STEPS.name &&
                  <NewsletterChooseName
                    setStep={setStep}
                    value={formValues.name || ""}
                    createTemplate={createTemplate}
                    setValue={name => setFormValues(prev => ({ ...prev, name }))}
                    loading={loading}
                  />
                }

                {step === STEPS.objective &&
                  <NewsletterChooseObjective
                    setStep={setStep}
                    isFirstSmartletter={isFirstSmartletter}
                    objective={formValues.objective}
                    setObjective={objective => setFormValues(prev => ({ ...prev, objective, products: undefined }))}
                  />
                }

                {step === STEPS.product &&
                  <NewsletterChooseProduct
                    setSelectedProductsExceededPopupIsOpened={setSelectedProductsExceededPopupIsOpened}
                    setStep={setStep}
                    products={formValues.products}
                    setProducts={products => setFormValues(prev => ({ ...prev, products }))}
                    isFirstGenerationDone={formValues.isFirstGenerationDone}
                    defaultProducts={defaultProducts}
                    setIsFirstGenerationDone={value => setFormValues(prev => ({ ...prev, isFirstGenerationDone: value }))}
                    objective={formValues.objective}
                  />
                }

                {step === STEPS.date &&
                  <NewsletterChooseDate
                    templateId={templateId}
                    isLoading={isLoading}
                    template={template}
                    wordings={wordings}
                    variables={variables}
                    language={language}
                    goNext={() => {
                      setIsLoading(true);
                      updateTemplate({ dateSent: date }).then(() => {
                        reloadAccountContext().then(() => {
                          setStep(null);
                          setShowSummary(false);
                          history.replace("/newsletter");
                          EventSystem.newNotification(
                            "notification.action",
                            <Translation id="newsletter.save.confirm"/>
                          );
                          setIsLoading(false);
                        });
                      });
                    }}
                    savedDate={date}
                    setDateForSummary={setDate}
                    account={account}
                    goSaveDraft={() => {
                      history.push("/newsletter");
                      EventSystem.newNotification(
                        "notification.action",
                        <Translation id="templateEditor.saveLater" variables={{ name: formValues.name }} />
                      );
                    }}
                    products={defaultProducts}
                  />
                }

              </>}
          </div>
          
          {step !== STEPS.date &&
            <div className={classes.rightPart}>
              <img alt="" src={image} />
            </div>
          }
        </div>

      }
      <SelectedProductsExceededPopup
        isOpen={selectedProductsExceededPopupIsOpened}
        close={() => setSelectedProductsExceededPopupIsOpened(false)}
        objective={formValues.objective}
      />

      {
        isLoadingPopup &&
          <LoadingPopup
            isOpen={true}
            isLoaded={() => setIsLoaded(true)}
            closePopup={() => setIsLoadingPopup(false)}
            subject="product"
            nbProduct={formValues?.products?.length}
            objective={formValues.objective}
          />
      }
    </div>
  );
};