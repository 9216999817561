import React, { Component } from "react";
import { withAuth } from "../../withAuth";
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import { Step } from "./Step/Step";

import { styles } from "./Stepper.styles.js";

class Stepper extends Component {
  get defaultedProps() {
    const { steps = [], activeStepKey = 0 } = this.props;

    return {
      steps,
      activeStepKey,
    };
  }

  get formattedSteps() {
    const { steps, activeStepKey } = this.defaultedProps;

    const formattedSteps = steps.map((step, key) => {
      const status =
        key === activeStepKey
          ? "active"
          : key < activeStepKey
          ? "done"
          : "inactive";
      const isLast = key === steps.length - 1;

      return {
        key,
        status,
        title: step.title,
        isLast,
      };
    });

    return formattedSteps;
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.stepper}>
        {this.formattedSteps.map((step) => (
          <Step
            title={step.title}
            key={step.key}
            stepKey={step.key}
            status={step.status}
            isLast={step.isLast}
          />
        ))}
      </div>
    );
  }
}

const _Stepper = withAuth(withStyles(styles)(injectIntl(Stepper)));
export { _Stepper as Stepper };
