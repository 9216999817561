import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { List, ListItem } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import Divider from "@material-ui/core/Divider";

import classes from "./DropDownMenu.module.scss";

export const DropDownMenu = 
  injectIntl(
    class DropDownMenu extends Component {
      render() {
        const {
          list,
          selectedKey,
          menuIsOpen,
          handleClickAway,
          style,
        } = this.props;

        return (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.root_submenu}>
              <Fade in={menuIsOpen}>
                <div className={"submenutopbar"} style={style}>
                  <List disablePadding>
                    {list.map((item, i) => {
                      const selectedClassName =
                        selectedKey === item.id
                          ? classes.selected
                          : selectedKey
                            ? classes.notSelected
                            : null;

                      return (
                        <Fragment key={item.id}>
                          <ListItem
                            button
                            onClick={item.action}
                            className={`${classes.submenu_button} ${selectedClassName}`}
                          >
                            {item.name}
                          </ListItem>
                          {i < list.length - 1 && <Divider />}
                        </Fragment>
                      );
                    })}
                  </List>
                </div>
              </Fade>
            </div>
          </ClickAwayListener>
        );
      }
    }
  );
