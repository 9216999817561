import {processStarsMjml} from "./review.tools";
const LABEL = "globalTrustpilot1";
const CATEGORY = "review:trustpilot";

export const globalTrustpilot1 = ({ rating, fontFamily, textColor, dominantColor }) => ({
  label: LABEL,
  category: CATEGORY,
  block: () => {
    if (!rating) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const starMjml = processStarsMjml("TP", rating);  

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${dominantColor}" padding-top="0" padding-bottom="0" full-width="full-width">
        <mj-section padding-bottom="10px" padding-top="10px">
          <mj-group>
            <mj-column css-class="globalTrustpilot1-column-50" vertical-align="middle">
              <mj-image data-readonly="true" align="right" padding-left="15px" padding-right="15px" width="80px" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/trustpilot-logo.png"></mj-image>
            </mj-column>
            <mj-column css-class="globalTrustpilot1-column-50" vertical-align="middle">
              <mj-text data-readonly="true" font-family="${fontFamily}" color="${textColor}" font-weight="600" padding-left="10px" padding-right="10px" padding-bottom="5px" font-size="14px">${rating}/5</mj-text>
              <mj-raw>
                <tr>
                  <td>
                    <div style="padding-left:8px;padding-bottom:10px">
                      ${starMjml}
                    </div>
                  </td>
                </tr>
              </mj-raw>
            </mj-column>
          </mj-group>
        </mj-section>
      </mj-wrapper>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: "review:trustpilot",
      activate: true,
      content: mjml,
    };
  },
  style: `
    .globalTrustpilot1-column-50 {
      width: 50% !important;
    }
  `
});