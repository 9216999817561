import {processStarsMjml } from "./review.tools";
const LABEL = "globalAvisverifies1";
const CATEGORY = "review:avisverifies";

export const globalAvisverifies1 = ({ rating, fontFamily, textColor, dominantColor }) => ({
  label: LABEL,
  category: CATEGORY,
  block: () => {
    if (!rating) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const starMjml = processStarsMjml("AV", rating, "center");
    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${dominantColor}" padding-top="10px" padding-bottom="10px" full-width="full-width">
        <mj-section padding-bottom="0px" padding-top="0px">
          <mj-column css-class="no-padding" width="37%" vertical-align="top">
            <mj-image css-class="centered-wrapper no-padding padding-desktop" data-readonly="true" padding-left="8px" padding-right="8px" width="90px" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/avisverifies-logo.png"></mj-image>
          </mj-column>            
          <mj-column width="26%" vertical-align="middle">
            <mj-raw>
              <tr>
                <td align="center">
                  <div style="text-align:center;padding-top:10px;padding-bottom:0px">
                    ${starMjml}
                  </div>
                </td>
              </tr>
            </mj-raw>
          </mj-column>
          <mj-column width="37%" vertical-align="middle" css-class="centered-text">
            <mj-text data-readonly="true" font-family="${fontFamily}" color="${textColor}" font-weight="600" padding-left="8px" padding-right="8px" padding-bottom="5px" padding-top="16px" line-height="14px" font-size="14px">${rating}/5</mj-text>
          </mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: "review:avisverifies",
      activate: true,
      content: mjml,
    };
  },
  mediaQueryStyle: `
    @media only screen and (min-width:480px) {
      .centered-wrapper {
        display: block;
        float: right;
      }
      .centered-text td div {
        text-align: left !important;
      }
      .padding-desktop {
        padding: 10px 8px !important;
      }
    }    
  `,
  style: `
    .centered-text td div {
      text-align: center !important;
    }
    .no-padding {
      padding: 0px !important;
    }
    `
});