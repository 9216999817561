const LABEL = "image2";
const CATEGORY = "image:headerImage";

export const image2 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {

    const { imageUrl, href, fontFamily, textColor, title, alt = "", width = 800, align="center", backgroundColor } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-section padding-bottom="0" padding-top="0" data-gjs="kilibaBlock" background-color="${backgroundColor}" full-width="full-width">
        <mj-column>
          <mj-image align="${align}" href="{{href}}" alt="{{alt}}" padding-bottom="0" padding-left="0" padding-top="0" padding-right="0" width="${width}px" src="{{imageUrl}}"></mj-image>
          <mj-text color="${textColor}" font-family="${fontFamily}" line-height="12px" font-size="12px" align="center">
            {{text}}
          </mj-text>
        </mj-column>
      </mj-section>
    `;

    const wordings = {
      text: title,
    };

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        variables: {
          imageUrl,
          href,
          alt,
        },
        wordings,
        editableProperties: [
          { key: "imageUrl", type: "image", label: "newsletter.rightMenu.property.selectedImage" },
          { key: "width", type: "imageSize", label: "newsletter.rightMenu.property.imageSize" },
          { key: "align", type: "imageAlign", label: "newsletter.rightMenu.property.imageAlign" },
          { key: "href", type: "link", label: "newsletter.rightMenu.property.imageLink" },
          { key: "alt", type: "string", label: "newsletter.rightMenu.property.alt" },
          ...( width < 800 ? [{ key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" }] : []),
        ]
      }
    };
  }
});