import { palette } from "../../../../styles/palette";

export const styles = {
  
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  textWrapper: {
    margin: "25px auto",
    display: "flex",
    flexDirection: "column"
  },
  textWrapperMarginTop45: {
    margin: "0 auto",
    marginTop: "35px",
    display: "flex",
    flexDirection: "column"
  },
  textWrapperMargin35: {
    margin: "35px auto",
    display: "flex",
    flexDirection: "column"
  },
  verifyCodeTextWrapper: {
    marginTop: "15px",
    marginBottom: "50px",
    display: "flex",
    flexDirection: "column"
  },
  centeredTextWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "-5px",
    marginBottom: "15px",
  },
  centeredTextWrapperMarginTop15: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "15px"
  },
  title: {
    fontSize: "24px",
    paddingBottom: "5px"
  },
  singleButtonContainer: {
    marginTop: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  singleButtonContainerMarginTop20: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  buttonContainer: {
    marginTop: "35px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  bottomWrapper: {
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  bottomWrapperTop50: {
    marginTop: "60px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  emailPresentation: {
    fontSize: "15px",
    fontFamily: "poppins"
  },
  errorSpan: {
    fontSize: "12px",
    color: "#db2419",
    marginBottom: "5px"
  },
  errorSpanSpaced: {
    fontSize: "12px",
    color: "#db2419",
    marginBottom: "15px"
  },
  digitForm: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  buttonLeft: {
    marginRight: "10px"
  },
  buttonRight: {
    marginLeft: "10px"
  },
  link: {
    textDecoration: "underline",
    fontWeight: "500",
    fontSize: "12px",
    fontStyle: "poppins-Medium",
    color: palette.black
  },
  lightText: {
    fontSize: "12px",
    lineHeight: "18px",
    fontStyle: "poppins-Medium",
    color: palette.neutralDarkGrey
  },
  EmptyDigitInput: {
    height: "90px",
    width: "80px",
    boxShadow: "0px 3px 14px -3px rgba(0,0,0,0.25)",
    border: "0",
    borderRadius: "15px",
    fontSize: "40px",
    color: palette.neutralDarkGrey,
    textAlign: "center",
    fontFamily: "coolvetica",
    margin: "0 10px",
    "&:focus": {
      border: "2px",
      borderColor: palette.primaryYellow100,
      borderStyle: "solid",
      outline: "none"
    }
  },
  FilledDigitInput: { 
    height: "90px",
    width: "80px",
    boxShadow: "0px 3px 14px -3px rgba(0,0,0,0.25)",
    border: "3",
    borderStyle: "solid",
    borderColor: palette.neutralMediumGrey,
    borderRadius: "15px",
    fontSize: "40px",
    color: palette.neutralDarkGrey,
    textAlign: "center",
    fontFamily: "coolvetica",
    margin: "0 10px",
    "&:focus": {
      border: "2px",
      borderColor: palette.primaryYellow100,
      borderStyle: "solid",
      outline: "none"
    }
  },
  separator: {
    width: "80%",
    height: "1px",
    marginTop: "50px",
    backgroundColor: palette.neutralMediumGrey,
    border: "0px",
  },
  spacedText: {
    marginBottom: "10px"
  },
  FormWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  spacer: {
    width: "5px"
  },
  bottomWrapper30: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
};