import { Translation } from "../../../common/Translation";
import { imagePreview } from "./ImageGaleryMenu";

import classes from "./SelectedImage.module.scss";

export const SelectedImage = ({ selectedUrl, lastSelectedUrl, onLastImageClick = () => {}, openCropMenu }) => {

  return (
    <div className={classes.container}>
      <div className="textMedium"><Translation id="newsletter.rightMenu.property.selectedImage" /></div>
      <div className={classes.content}>
        <div className={classes.selectedImageContainer}>
          <div className={classes.imageContainer}>
            <img 
              alt=""
              src={selectedUrl}
            />
            {selectedUrl !== imagePreview && 
              <div className={classes.selectedImageOverlay} onClick={openCropMenu}>
                <i className="fa-solid fa-crop-simple" />
                <h4><Translation id="rightmenu.editContent.image.crop" /></h4>
              </div>
            }
          </div>
        </div>

        {lastSelectedUrl &&
          <div className={classes.lastSelecteImageContainer}>
            <img 
              alt=""
              src={lastSelectedUrl}
              onClick={onLastImageClick}
            />
          </div>
        }
      </div>
    </div>
  );
};