import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import { TextField } from "./TextField";
import { palette } from "../../../styles/palette";
import { Translation } from "../Translation";

const styles = (theme) => ({
  tooltip: {
    padding: "15px",
    borderRadius: "10px",
    fontFamily: "Coolvetica Book",
    fontStyle: "normal",
    fontSize: "10px",
    color: "black",
    backgroundColor: "#fff",
    zIndex: "1000000",
    boxShadow: "0px 0px 5px 1px rgba(165, 173, 184, 0.3);"
  },
  passwordCriteria: {
    marginTop: "5px",

    "& span": {
      marginLeft: "5px"
    },

    "& i": {
      color: palette.neutralMediumGrey
    },

    "&.success": {
      "& i": {
        color: palette.accentGreen500
      }
    },
    "&.error": {
      "& span": {
        color: palette.errorRed
      }
    },
  }
});

const useStyles = makeStyles(styles);

/**
 * List of criteria to validate user password
 */
const PASSWORD_CRITERIA = [{
  key: "register.password_criteria.length",
  validation: (value = "") => value.trim().length >= 8,
}, {
  key: "register.password_criteria.upper",
  validation: (value = "") => value.trim().match(/[A-Z]/),
}, {
  key: "register.password_criteria.lower",
  validation: (value = "") => value.trim().match(/[a-z]/),
}, {
  key: "register.password_criteria.digit",
  validation: (value = "") => value.trim().match(/[0-9]/),
}, {
  key: "register.password_criteria.special",
  validation: (value = "") => value.trim().match(/[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/),
}, {
  // Hidden rule, Cognito refuses trailing spaces
  validation: (value = "") => value.match(/^[\S]+.*[\S]+$/)
}];

/**
 * Password tooltip content
 * @param {Object} props 
 * @param {{key: string, validation: (value: string) => boolean}} props.criterion Criterion item
 * @param {string} props.password Password value
 * @param {boolean} props.enableErrorsReporting Enable criteria validation errors reporting
 * @returns 
 */
const PasswordTooltipCriterion = ({
  criterion,
  password = "",
  enableErrorsReporting
}) => {
  const classes = useStyles();
  const criteriaState = criterion.validation(password) ? "success" : (enableErrorsReporting && "error");
  return (
    <li className={classnames(classes.passwordCriteria, criteriaState)}>
      <i className="fas fa-circle-check" />
      <span>
        <Translation id={criterion.key} />
      </span>
    </li>
  );
};

/**
 * Password input wrapped by criteria validation tooltip
 * @param {Object} props 
 * @param {string} props.password Password value
 * @param {boolean} props.showTooltip Display criteria tooltip
 * @param {boolean} props.enableErrorsReporting Enable criteria validation errors reporting
 * @returns 
 */
function PasswordTooltip({
  password,
  showTooltip,
  enableErrorsReporting,
  children
}) {
  const classes = useStyles();
  return (
    <Tooltip 
      classes={{ tooltip: classes.tooltip }}
      placement="bottom-start"
      open={showTooltip}
      title={
        <div>
          <Translation id="register.password_criteria.title" />
          <ul>
            {PASSWORD_CRITERIA
              .filter((criterion) => criterion.key)
              .map((criterion) => 
                <PasswordTooltipCriterion
                  key={criterion.key}
                  criterion={criterion}
                  password={password}
                  enableErrorsReporting={enableErrorsReporting}
                />
              )
            }
          </ul>
        </div>
      } 
    >
      {children}
    </Tooltip>
  );
};

/**
 * A wrapper around TextField that add the password validation tooltip displayed on focus
 * 
 * @typedef {Object} PasswordTextFieldProps PasswordTextField specific props
 * @property {() => void} PasswordTextFieldProps.onPasswordValid Callback when password checks pass
 * @property {() => void} PasswordTextFieldProps.onPasswordInvalid Callback when password checks fails
 * 
 * @param {PasswordTextFieldProps & TextField.propTypes} props
 */
export function PasswordTextField({
  onPasswordValid = () => {},
  onPasswordInvalid = () => {},
  ...textFieldProps
}) {
  const [tooltipVisible, setTooltipVisibility] = useState(false);
  const [enableErrorsReporting, setErrorsReportingEnabled] = useState(false);
  const [passwordHasChangedOnce, setPasswordHasChangedOnce] = useState(false);

  // Detect first password input to register that password has change once
  useEffect(() => {
    if(textFieldProps.value) {
      setPasswordHasChangedOnce(true);
    }
  }, [textFieldProps.value]);

  // Runs all checks on password to return if it's valid or not
  const isPasswordValid = useCallback(() => {
    return PASSWORD_CRITERIA.every(criterion => criterion.validation(textFieldProps.value));
  }, [textFieldProps.value]);

  // Check if password is valid when it changes
  const passwordValid = useMemo(() => {
    if(enableErrorsReporting) {
      return isPasswordValid();
    }
    return true;
  }, [enableErrorsReporting, isPasswordValid]);

  // When user leave password input (on blur)
  function onLeavingPasswordInput() {
    // Hide tooltip
    setTooltipVisibility(false);

    // If password has changed at least once (first character entered at least), enable password errors reporting
    if(passwordHasChangedOnce) {
      setErrorsReportingEnabled(true);

      // Also feedback password validity to parent
      if(isPasswordValid()) {
        onPasswordValid();
      } else {
        onPasswordInvalid();
      }
    }
  }

  return (
    <PasswordTooltip 
      password={textFieldProps.value}
      enableErrorsReporting={enableErrorsReporting}
      showTooltip={tooltipVisible}
    >
      <TextField
        type="password"
        onFocus={() => setTooltipVisibility(true)}
        onBlur={() => onLeavingPasswordInput()}
        error={!passwordValid}
        {...textFieldProps}
      />
    </PasswordTooltip>
  );
};