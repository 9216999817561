import { useState } from "react";
import { makeStyles } from "@material-ui/core";

import { Translation } from "../../common/Translation";
import { palette } from "../../../styles/palette";

const styles = () => ({
  container: {
    marginTop: 90,
    textAlign: "center",
  },
  questionsContainer: {
    marginTop: 45,
    display: "flex",
    flexDirection: "column",
    gridGap: 25,
    maxWidth: 996,
    margin: "auto",
    justifyContent: "center",
  },
  questionBlock: {
    borderRadius: 25,
    background: palette.primaryWhite,
  },
  question: {
    padding: 35,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "user-select": "none",
    cursor: "pointer",
  },
  chevron: {
    cursor: "pointer",
  },
  awnser: {
    padding: "0 35px 35px",
    textAlign: "left",
  }
});

const NB_QUESTION = 6;

const useStyles = makeStyles(styles);

export const SubscriptionFaq = () => {

  const [openedQuestions, setOpenedQuestions] = useState(Array.apply(null, Array(NB_QUESTION)).map(() => false));
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span className="display3"><Translation id="subscriptionFaq.title" /></span>
      <div className={classes.questionsContainer}>

        {Array.apply(null, Array(NB_QUESTION)).map((e,idx) => (
          <div key={idx} className={classes.questionBlock}>
            <div className={classes.question} onClick={() => setOpenedQuestions(prevState => prevState.map((elem, idx2) => idx2 === idx ? !elem : elem))}>
              <span className="display5"><Translation id={`subscriptionFaq.question${idx + 1}`} /></span>
              <i
                className={`fa-solid fa-chevron-${openedQuestions[idx] ? "up" : "down"} ${classes.chevron}`}
              />
            </div>
            {openedQuestions[idx] && 
              <div className={classes.awnser}>
                <span className="text"><Translation id={`subscriptionFaq.awnser${idx + 1}`} /></span>
              </div>
            }
          </div>
        ))}

      </div>
    </div>
  );
};
