import {processStarsMjml } from "./review.tools";
import { formatHtmlIntoShortText } from "../../../../../services/text.service";
const LABEL = "avisverifies4";
const CATEGORY = "review:avisverifies";

export const avisverifies4 = ({ reviews, fontFamily, textColor, buttonTextColor, accentColor, dominantColor }) => ({
  label: LABEL,
  category: CATEGORY,
  block: () => {
    if (!reviews?.length) {
      return {
        id: LABEL,
        label: LABEL,
        category: CATEGORY,
        activate: false,
        content: "",
      };
    }

    const starMjml = processStarsMjml("AV", reviews[0].rating, "right");

    const mjml = `
      <mj-wrapper data-gjs="kilibaBlock" background-color="${dominantColor}" padding-top="0" padding-bottom="0" full-width="full-width">
        <mj-section padding-bottom="0px" padding-top="0px">
          <mj-column width="25%"></mj-column>
          <mj-column width="50%">
            <mj-raw>
              <tr>
                <td align="center">
                  <div style="text-align:center;padding-bottom:15px;padding-top:15px;background-color:${dominantColor}">
                    <span style="color:${buttonTextColor};background-color:${accentColor};display:inline-block;width:26px;height:14px;text-align:center;border-radius:40px;padding:15px 9px;box-sizing:initial;font-size:13px">
                      ${reviews[0].minimizedName}
                    </span>
                  </div>
                </td>
              </tr>
            </mj-raw>

            <mj-image data-readonly="true" padding-bottom="0px" padding-top="0px" width="85px" align="center" src="https://preprod-kiliba-assets.s3.eu-central-1.amazonaws.com/tmp/avisverifies-logo.png"></mj-image>

          </mj-column>
          <mj-column width="25%"></mj-column>
        </mj-section>
        <mj-section padding-bottom="5px" padding-top="5px">
          <mj-group>
            <mj-column width="55%" css-class="avisverifies4-column-55" vertical-align="middle">
              <mj-raw>
                <tr>
                  <td align="center">
                    <div style="text-align:right;padding-top:0px;padding-bottom:0px">
                      ${starMjml}
                    </div>
                  </td>
                </tr>
              </mj-raw>
            </mj-column>
            <mj-column width="45%" css-class="avisverifies4-column-45" vertical-align="middle">
              <mj-text data-readonly="true" align="left" font-family="${fontFamily}" color="${textColor}" font-weight="600" padding-left="8px" padding-right="8px" padding-bottom="0px" padding-top="0px" font-size="14px" line-height="14px">${reviews[0].rating}/5</mj-text>
            </mj-column>
          </mj-group>
        </mj-section>
        <mj-section padding-bottom="0px" padding-top="0px">
          <mj-column width="25%"></mj-column>
          <mj-column width="50%">
            <mj-text data-readonly="true" align="center" font-family="${fontFamily}" color="${textColor}" padding-top="13px" padding-left="8px" padding-right="8px" font-size="12px" line-height="12px">${formatHtmlIntoShortText(reviews[0].text)}</mj-text>
          </mj-column>
          <mj-column width="25%"></mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
    };
  },
  style: `
    .avisverifies4-column-55 {
      width: 55% !important;
    }

    .avisverifies4-column-45 {
      width: 45% !important;
    }
  `,
});