import { Component } from "react";
import { withAuth } from "../../../withAuth";
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";

import { PageBodyWrapper } from "../common/PageBodyWrapper/PageBodyWrapper";
import { PageBody } from "../common/PageBody/PageBody";

import { CustomButton } from "../../../common/Button/CustomButton";

import ovalArrow from "../../../../public/oval_arrow.png";
import illuJobsToBeDone1 from "../../../../public/Illu-jobstobedone-1.png";
import illuJobsToBeDone2 from "../../../../public/Illu-jobstobedone-2.png";
import illuJobsToBeDone3 from "../../../../public/Illu-jobstobedone-3.png";
import illuJobsToBeDone4 from "../../../../public/Illu-jobstobedone-4.png";

import { styles } from "./JobsToBeDone.styles.js";
import { reloadAccountContext } from "../../../../contexts/context";

class JobsToBeDone extends Component {
  constructor() {
    super();
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.auth.sendEventToAmplitude("jtbd.see_page");
  }

  async submit() {
    this.props.auth.sendEventToAmplitude("jtbd.click_lets_go");

    const url = "/api/account_management/updateAccount";
    const options = {
      method: "POST",
      body: JSON.stringify({
        step_register: 1
      }),
    };
    await this.props.auth.fetch(url, options);
    await reloadAccountContext();
    this.props.onSubmit();
  }

  render() {
    const { classes, intl } = this.props;

    return (
      <PageBodyWrapper>
        <PageBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gridGap: 50,
            minWidth: 850,
            width: "90vw",
            maxWidth: 1120,
            minHeight: 600,
            maxHeight: 852,
            padding: "40px 0",
          }}
        >
          <div className={classes.blockTitle}>
            <p className="display2">
              {intl.messages["onBoarding.jobsToBeDone.start"]}
            </p>
            <h3>{intl.messages["onBoarding.jobsToBeDone.subtitle"]}</h3>
            <img
              className={classes.ovalArrow}
              src={ovalArrow}
              alt="decoration"
            />
          </div>

          <div className={classes.cardContainer}>
            <div className={classes.card}>
              <h1 className={classes.cardTitle}>
                {intl.messages["onBoarding.jobsToBeDone.description1.0"]}
                <br />
                <span
                  className={`${classes.highlight} ${classes.highlightCard1}`}
                >
                  {intl.messages["onBoarding.jobsToBeDone.description1.1"]}
                </span>
              </h1>
              <img
                className={`${classes.imageCard} ${classes.imageCard1}`}
                src={illuJobsToBeDone1}
                alt="imageCard1"
              />
            </div>
            <div className={classes.card}>
              <h1 className={classes.cardTitle}>
                {intl.messages["onBoarding.jobsToBeDone.description2.0"]}
                <br />
                <span
                  className={`${classes.highlight} ${classes.highlightCard2}`}
                >
                  {intl.messages["onBoarding.jobsToBeDone.description2.1"]}
                </span>
              </h1>
              <img
                className={`${classes.imageCard} ${classes.imageCard2}`}
                src={illuJobsToBeDone2}
                alt="imageCard2"
              />
            </div>
            <div className={classes.card}>
              <h1 className={classes.cardTitle}>
                <span
                  className={`${classes.highlight} ${classes.highlightCard3}`}
                >
                  {intl.messages["onBoarding.jobsToBeDone.description3.0"]}
                </span>
                <br />
                {intl.messages["onBoarding.jobsToBeDone.description3.1"]}
              </h1>
              <img
                className={`${classes.imageCard} ${classes.imageCard3}`}
                src={illuJobsToBeDone3}
                alt="imageCard3"
              />
            </div>
            <div className={classes.card}>
              <h1 className={classes.cardTitle}>
                <span
                  className={`${classes.highlight} ${classes.highlightCard4}`}
                >
                  {intl.messages["onBoarding.jobsToBeDone.description4.0"]}
                </span>
                <br />
                {intl.messages["onBoarding.jobsToBeDone.description4.1"]}
              </h1>
              <img
                className={`${classes.imageCard} ${classes.imageCard4}`}
                src={illuJobsToBeDone4}
                alt="imageCard4"
              />
            </div>
          </div>
          <CustomButton onClick={this.submit}>
            {intl.messages["button.letsgo"]}
          </CustomButton>
        </PageBody>
      </PageBodyWrapper>
    );
  }
}

const _JobsToBeDone = withAuth(withStyles(styles)(injectIntl(JobsToBeDone)));
export { _JobsToBeDone as JobsToBeDone };
