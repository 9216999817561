import { useState, useRef } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import { Translation } from "../common/Translation";
import { CustomButton } from "../common/Button/CustomButton";
import { DropdownPopper } from "../common/DropdownPopper/DropdownPopper";

import classes from "./TemplateEditorHeader.module.scss";

export const TemplateEditorHeader = ({ language, saving, templateName, templateId, showNextButton, templateInfoLoading, availableLanguages, onNext, translateInProgress, changeLanguage }) => {

  const intl = useIntl();
  const anchor = useRef();
  const history = useHistory();
  const { search } = useLocation();

  const [isLanguageDropdownOpened, setIsLanguageDropdownOpened] = useState(false);  

  return (
    <div className={classes.container}>

      

      <div className={`text ${classes.leftPart}`}>
        {!templateInfoLoading &&
          <CustomButton
            type="secondary"
            size="xs"
            style={{ marginRight: 15 }}
            onClick={() => {
              const queryParameters = new URLSearchParams(search);
              const from = queryParameters.get("from");
              if (from === "newsletterList") {
                history.push("/newsletter");
              } else {
                history.push(`/newsletter?templateId=${templateId}&step=product`);
              }
            }}
          >
            <Translation id="dialog.back" />
          </CustomButton>
        }
        {templateName}
      </div>

      <div className={classes.languagesContainer}>
        <div className="text" onClick={!saving && !translateInProgress ? () => setIsLanguageDropdownOpened(true) : null}>
          <span><Translation id="templateEditorHeader.template" />&nbsp;</span>
          <span ref={anchor} className={classes.language}><u><Translation id={`availableLanguages.${language}`} /></u> <i className="fa-solid fa-chevron-down" /></span>
        </div>
        <DropdownPopper
          isOpen={isLanguageDropdownOpened}
          anchor={anchor}
          close={() => setIsLanguageDropdownOpened(false)}
          value={language}
          onChange={!saving ? changeLanguage : () => {}}
          options={availableLanguages.map(id => ({ id, label: intl.messages[`availableLanguages.${id}`]}))}
        />
      </div>


      <div className={classes.rightPart}>
        <div className={classes.saveStatusContainer}>
          {!saving ? 
            <>
              <span className="text"><Translation id="templateEditorHeader.saved" /></span>
              <i className={`fa-solid fa-circle-check ${classes.savedIcon}`} />
            </> :
            <>
              <span className="text"><Translation id="templateEditorHeader.saving" /></span>
              <div className={classes.dotAnimContainer}>
                <div className={classes.dotAnim}></div>
              </div>
            </>
          }
        </div>

        {(!templateInfoLoading && showNextButton) &&
          <>
            <CustomButton size="xs" onClick={!saving && !translateInProgress ? onNext : null}>
              <Translation id="button.next" />
            </CustomButton>   
          </>
        }
      </div>
    </div>
  );
};
