import { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment/min/moment-with-locales";

import { Translation } from "../../../common/Translation";
import { SubscriptionSliderRightCard } from "./SubscriptionSliderRightCard";

import { styles } from "./SubscriptionSlider.styles";
import { calcStepDate } from "../utils";

const useStyles = makeStyles(styles);

const SLIDER_MARGIN = 10;
const MAGNET_DISTANCE_PEERCENT = 2;

export const SubscriptionSlider = ({ isTier3, steps, opportunity, currency }) => {

  const classes = useStyles({ sliderMargin: SLIDER_MARGIN });
  const intl = useIntl();
  
  const [cursorSteps, setCursorSteps] = useState(null);
  const [selectedStepIdx, setSelectedStepIdx] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [sliderPercent, setSliderPercent] = useState(0);
  const [isAnimRunning, setIsAnimRunning] = useState(null);
  const [animInterval, setAnimInterval] = useState(null);
  const [animEndPercent, setAnimEndPercent] = useState(100);

  const sliderRef = useRef();

  useEffect(() => {
    moment.locale(intl.locale);
  }, [intl]);

  const getStepPercent = useCallback((step, idx, contacts, blacklistedContacts, nbSteps) => {
    if (isTier3) {
      if (step.step === "always") {
        return 100;
      }

      return (100 / nbSteps) * (idx + 1);
    } else {
      return step.customerNbr * 100 / (contacts - blacklistedContacts);
    }
  }, [isTier3]);

  useEffect(() => {
    if (!cursorSteps && steps) {
      setCursorSteps({...steps, steps: steps.steps.map((step, idx) => ({
        ...step,
        price: step.upTo === opportunity?.Membres_Actifs__c ? opportunity.Amount : step.price,
        billingMethod: step.upTo === opportunity?.Membres_Actifs__c ? opportunity.Invoicing_period__c : null,
        percent: getStepPercent(step, idx, steps.contacts, steps.blacklistedContacts, steps.steps.length),
      }))});

      let opportunityStepIdx = opportunity ? steps.steps.findIndex(step => step.upTo === opportunity?.Membres_Actifs__c) : 0;
      opportunityStepIdx = opportunityStepIdx === -1 ? steps.steps.length - 1 : opportunityStepIdx;

      setSelectedStepIdx(opportunity ? opportunityStepIdx : steps.steps.length - 1);
      setAnimEndPercent(opportunity ? getStepPercent(steps.steps[opportunityStepIdx], opportunityStepIdx, steps.contacts, steps.blacklistedContacts, steps.steps.length) : 100);
      //set to step 0 for the animation (tier3)
      const idxSliderPercent = isTier3 ? 0 : opportunityStepIdx;
      setSliderPercent(getStepPercent(steps.steps[idxSliderPercent], idxSliderPercent, steps.contacts, steps.blacklistedContacts, steps.steps.length));
      
    }
  }, [steps, selectedStepIdx, cursorSteps, getStepPercent, isTier3, opportunity]);

  const resetCursor = useCallback(() => {
    setSliderPercent(getStepPercent(cursorSteps.steps[selectedStepIdx], selectedStepIdx, cursorSteps.contacts, cursorSteps.blacklistedContacts, cursorSteps.steps.length));
    setIsSliding(false);
  }, [cursorSteps, selectedStepIdx, getStepPercent]);

  const onMouseUp = useCallback(() => {
    if (isSliding) {
      resetCursor();
    }
  }, [isSliding, resetCursor]);

  const onnMouseMove = useCallback((event) => {
    if (isSliding) {
      const container = document.getElementsByClassName(classes.leftCard)[0];

      // Cancel sliding if mouse is no longer on the card
      if (event.target !== container && !container.contains(event.target)) {
        resetCursor();
      } else {
        const rect = sliderRef.current.getBoundingClientRect(); 
        const mousePos = event.x - rect.x - SLIDER_MARGIN;
        const sliderWidth = rect.width - SLIDER_MARGIN * 2;

        let percent = mousePos * 100 / sliderWidth;
        
        for (const [idx, step] of cursorSteps.steps.entries()) {
          if (Math.abs(step.percent - percent) < MAGNET_DISTANCE_PEERCENT) {
            percent = step.percent;
            if (selectedStepIdx !== idx) {
              setSelectedStepIdx(idx);
            }
            break ;
          }
        }

        if (percent < 0) {
          percent = 0;
        }
        if (percent > 100) {
          percent = 100;
        }
        
        setSliderPercent(percent);
      }
    }
  }, [isSliding, classes, resetCursor, cursorSteps, selectedStepIdx]);

  const onStepClick = (step, idx) => {
    setSelectedStepIdx(idx);
    setSliderPercent(step.percent);
  };

  useEffect(() => {
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mousemove", onnMouseMove);

    return () => {
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onnMouseMove);
    };
  }, [onnMouseMove, onMouseUp]);


  /* ANIMATION PART */

  useEffect(() => {
    if (cursorSteps) {

      if (isAnimRunning === null) {
        setIsAnimRunning(isTier3 ? true : false);
      }

      if (isAnimRunning) {
        if (sliderPercent < animEndPercent) {

          if (!animInterval) {
            const interval = setInterval(() => {
              if (sliderPercent < animEndPercent) {
                setSliderPercent(value => {
                  return Math.min(value + 1, animEndPercent);
                });
              }
            }, 20);
            setAnimInterval(interval);
          }
          
        } else {
          setIsAnimRunning(false);
          clearInterval(animInterval);
        }
      }
    }
  }, [cursorSteps, isAnimRunning, selectedStepIdx, isTier3, sliderPercent, animInterval, animEndPercent]);

  /* ANIMATION PART END */

  const getStepDuration = (step, upTo = null) => {
    if (step !== "always") {
      return (
        <>
          <FormattedMessage id="subscriptionSlider.cursor.text.year" values={{ ...calcStepDate(step), linebreak: upTo ? " " : <br/> }} />
          {upTo && <span><br/>&lt;{upTo} <Translation id="subscriptionSlider.contacts" /></span>}
        </>
      );
    } else {
      return (
        <>
          <FormattedMessage id="subscriptionSlider.cursor.text.always" values={{ linebreak: upTo ? " " : <br/> }} />
          {upTo && <span><br/>&lt;{upTo} <Translation id="subscriptionSlider.contacts" /></span>}
        </>
      );
    }
  };

  if (!cursorSteps) {
    return null;
  }

  const selectedStep = cursorSteps.steps[selectedStepIdx];

  if (selectedStep.upTo === opportunity?.Membres_Actifs__c) {
    selectedStep.opportunity = opportunity;
  }
  
  const stepsToShow = isTier3 ? cursorSteps.steps : [selectedStep];

  return (
    <>
      <h3><Translation id={"subscription.package.title"} /></h3>
      
      <div className={classes.container}>
        
        <div className={`${classes.leftCard} ${isSliding ? classes.sliding : ""}`}>
          <h4><Translation id="subscriptionSlider.title" /></h4>
          
          <div className={classes.customerStatsContainer}>
            <div>
              <span className="display2">{cursorSteps.contacts}</span><br />
              <span className="textMedium"><Translation id="subscriptionSlider.stat1" /></span>
            </div>
            <div>
              <span className="display2">{cursorSteps.blacklistedContacts}</span><br />
              <span className="textMedium">
                <Translation id="subscriptionSlider.stat2" />&nbsp;
                <i className={`fa-solid fa-circle-info ${classes.blacklistInfo}`}>

                  <div className={classes.popoverContainer}>
                    <div className={classes.popover}>
                      <Translation id="subscriptionSlider.blacklist.popover" />
                    </div>
                    <div className={classes.triangle} />
                  </div>

                </i>

              </span>
            </div>
            <div>
              <span className="display2">{selectedStep.customerNbr}</span><span className={`display5 ${classes.totalCustomerText}`}>/{selectedStep.upTo}</span><br />
              <span className="textMedium"><Translation id="subscriptionSlider.stat3" /></span>
            </div>
          </div>
          
          <h4><Translation id="subscriptionSlider.subtitle1" /></h4>
          {isTier3 && selectedStep.step !== "always" ? 
            <h4 className={classes.subtitle}><FormattedMessage id="subscriptionSlider.subtitle3" values={calcStepDate(selectedStep.step)} /></h4> :
            <h4 className={classes.subtitle}><Translation id="subscriptionSlider.subtitle2" /><br /><br /></h4>
          }

          <div className={classes.sliderContainer}>

            <div className={classes.slider}>
              <div><span className={`info ${classes.sliderNumber}`}><Translation id="subscriptionSlider.left"/></span></div>
              
              <div className={classes.sliderContent} ref={sliderRef}>
                
                
                <div className={classes.sliderTop}>
                  <div className={classes.sliderContactsInfoContainer} style={{ width: `${sliderPercent}%`, left: 0 }}>
                    {!isAnimRunning && <div className={`infoSmall ${classes.sliderCustomerNbr}`}>
                      {selectedStep.customerNbr} <Translation id="subscriptionSlider.contacts" />
                    </div>}
                  </div>

                  <div className={classes.sliderContactsInfoContainer} style={{ width: `${100 - sliderPercent}%` }}>
                    {!isAnimRunning && selectedStep.outPackageCustomerNbr > 0 && <div className={`infoSmall ${classes.sliderCustomerBlacklisted}`}>
                      {selectedStep.outPackageCustomerNbr} <Translation id="subscriptionSlider.contactsOut" />
                    </div>}
                  </div>
                </div>

                <div className={classes.sliderBackground}>
                  <div className={classes.sliderBar} style={{ width: `${sliderPercent}%` }} />

                  {isTier3 && <>
                    <div
                      onMouseDown={() => isAnimRunning === false && setIsSliding(true)}
                      className={`${classes.sliderCursor} ${isSliding ? classes.sliding : ""}`}
                      style={{ left: `calc(${sliderPercent}% - 6px)` }}
                    />
                    {(!isSliding || cursorSteps.steps.some(({percent}) => percent === sliderPercent)) && !isAnimRunning &&
                      <div className={`info ${classes.sliderCursorText}`} style={{ left: `${sliderPercent}%` }}>
                        {getStepDuration(selectedStep.step, selectedStep.upTo)}
                      </div>
                    }
                  </>}

                  {stepsToShow.map((step, idx) => {
                    return (
                      <div key={idx}>
                        <div
                          className={`${classes.sliderStep} ${step.percent > sliderPercent ? classes.pinkBackground : ""}`}
                          style={{ left: `calc(${step.percent}% - 6px)` }}
                        />
                        <div
                          className={classes.sliderStepHitbox}
                          style={{ left: `calc(${step.percent}% - 10px)` }}
                          onClick={() => onStepClick(step,idx)}
                        />
                        {(!isTier3 || selectedStepIdx !== idx || (isSliding && step.percent !== sliderPercent) || (isAnimRunning && selectedStepIdx === idx)) && 
                          <div
                            className={`info ${classes.sliderStepText} ${sliderPercent >= step.percent ? classes.blackText : ""}`}
                            style={{ left: `${step.percent}%` }}
                            onClick={() => onStepClick(step,idx)}
                          >
                            {getStepDuration(step.step)}
                          </div>
                        }
                      </div>
                    );
                  })}
                
                </div>
              </div>
              
            </div>
          </div>

        </div>

        <SubscriptionSliderRightCard
          selectedStep={selectedStep}
          currency={currency}
        />

      </div>
    </>
  );
};