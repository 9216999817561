import { Translation } from "../common/Translation";
import { TemplateCustomizationContent } from "./TemplateCustomizationContent.jsx";

import classes from "./TemplateCustomization.module.scss";



export const TemplateCustomization = () => {

  return (
    <TemplateCustomizationContent
      descriptionBlock={<DescriptionBlock />}
      displayedParameters={["logo", "color", "text", "socialMedias"]}
      mustShowCheckList
      padding={"25px 64px 0"}
    />
  );
};

const DescriptionBlock = ({ onSubmit }) => {

  return (
    <>
      <p className="display1">
        <Translation id={"templateCustomization.title"} />
      </p>
      <h4 className={classes.subtitle}>
        <Translation id={"templateCustomization.sub_title"} />
      </h4>
    </>
  );
};
