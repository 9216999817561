import React, { Component } from "react";

import { injectIntl } from "react-intl";

import { Checkbox } from "../../../../../../common/Input/Checkbox.js";

export const MustShowDatesInput = injectIntl(
  class MustShowDatesInput extends Component {
    onChange(value) {
      const { setPromoCodeTemplateWrapperProp } = this.props;

      setPromoCodeTemplateWrapperProp("promoCodeTemplate.mustShowDates", value);
    }

    render() {
      const { intl, disabled, promoCodeTemplateWrapper } = this.props;

      return (
        <Checkbox
          checked={promoCodeTemplateWrapper.promoCodeTemplate.mustShowDates}
          disabled={disabled}
          disabledExplanation={
            intl.messages[
              "usecase.promoCodeTemplateEditor.options.mustShowDates.disabled.explanation"
            ]
          }
          onChange={(event) => this.onChange(event.target.checked)}
          label={
            intl.messages[
              "usecase.promoCodeTemplateEditor.options.mustShowDates"
            ]
          }
        />
      );
    }
  }
);
