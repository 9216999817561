import { palette } from "../../../styles/palette";

const cardPadding = "15px";
const scrollButtonHeight = "50px";
const marginBetweenCards = "15px";

export const styles = {
  carousel: {
    height: "100%",
    gridGap: marginBetweenCards,
    display: "flex",
    flexDirection: "column",
  },
  cards: {
    height: `calc(100% - ${scrollButtonHeight})`,
    display: "flex",
    flexDirection: "column",
  },
  card: {
    flexGrow: 1,
    backgroundColor: palette.primaryWhite,
    borderRadius: 20,
    padding: cardPadding,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "&:not(&:last-child)": {
      marginBottom: marginBetweenCards,
    },
    "& h1": {
      fontSize: 24,
    }
  },
  value: {
  },
  title: {
    color: palette.neutralDarkGrey,
  },
  icon: {
    position: "absolute",
    top: cardPadding,
    right: cardPadding,
    fontSize: 20,
    height: 40,
    width: 40,
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  scrollButton: {
    height: scrollButtonHeight,
    backgroundColor: palette.primaryWhite,
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 24,
    cursor: "pointer",
  },
  hidden: {
    display: "none",
  },
};
