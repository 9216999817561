import React, { Component } from "react";

import { injectIntl } from "react-intl";

import { RadioGroup } from "../../../../../common/RadioGroup.js";
import { InputSection } from "../../../../../common/Input/InputSection.js";
import { FixedInput } from "../TemplateEditorInputs/Temporality/FixedInput.js";
import { LifetimeInput } from "../TemplateEditorInputs/Temporality/LifetimeInput.js";
import { EternalInput } from "../TemplateEditorInputs/Temporality/EternalInput.js";
import { UntilDateInput } from "../TemplateEditorInputs/Temporality/UntilDateInput.js";


export const TemporalitySection = injectIntl(
  class TemporalitySection extends Component {
    onChange(value) {
      const { setPromoCodeTemplateWrapperProp, caller, account } = this.props;

      setPromoCodeTemplateWrapperProp("selectedTemporalityChoiceKey", value);
      if (value === "lifetime") {
        setPromoCodeTemplateWrapperProp(
          "promoCodeTemplate.mustShowDates",
          false
        );
      }

      if (value === "eternal" && caller !== "newsletter") {
        setPromoCodeTemplateWrapperProp(
          "promoCodeTemplate.mustShowDates",
          false
        );
      }
    }

    render() {
      const {
        intl,
        tabKey,
        promoCodeCanBeEternalInCms,
        promoCodeTemplateWrapper,
        setPromoCodeTemplateWrapperProp,
        getError,
        partNames,
        shouldDisplayFromDateOption
      } = this.props;

      return (
        <InputSection
          title={
            intl.messages["usecase.promoCodeTemplateEditor.temporality.title"]
          }
        >
          <RadioGroup
            value={promoCodeTemplateWrapper.selectedTemporalityChoiceKey}
            onChange={(event) => this.onChange(event.target.value)}
          >
            {partNames.find((partName) => partName === "fixedInput") ? (
              <FixedInput
                promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                setPromoCodeTemplateWrapperProp={
                  setPromoCodeTemplateWrapperProp
                }
              />
            ) : null}
            {partNames.find((partName) => partName === "lifetimeInput") && !!shouldDisplayFromDateOption ? (
              <LifetimeInput
                promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                setPromoCodeTemplateWrapperProp={
                  setPromoCodeTemplateWrapperProp
                }
                disabled={promoCodeTemplateWrapper.promoCodeTemplate.isGeneric}
                getError={getError}
              />
            ) : null}
            {partNames.find((partName) => partName === "eternalInput") ? (
              <EternalInput
                tabKey={tabKey}
                promoCodeCanBeEternalInCms={promoCodeCanBeEternalInCms}
                promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                setPromoCodeTemplateWrapperProp={
                  setPromoCodeTemplateWrapperProp
                }
              />
            ) : null}
            {partNames.find((partName) => partName === "untilDateInput") ? (
              <UntilDateInput
                tabKey={tabKey}
                promoCodeCanBeEternalInCms={promoCodeCanBeEternalInCms}
                promoCodeTemplateWrapper={promoCodeTemplateWrapper}
                setPromoCodeTemplateWrapperProp={
                  setPromoCodeTemplateWrapperProp
                }
              />
            ) : null}
          </RadioGroup>
        </InputSection>
      );
    }
  }
);
