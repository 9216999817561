import { useEffect, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment/min/moment-with-locales";

import { Translation } from "../../common/Translation";
import { useAuth } from "../../../hooks/useAuth";
import { UsecasePopupSalesContact } from "../../usecase/Usecase/UsecasePopups/UsecasePopupSalesContact";
import { CustomButton } from "../../common/Button/CustomButton";

import { palette } from "../../../styles/palette";
import { calcStepDate } from "../Subscription/utils";
import image from "../../../public/subscriptionInfo.png";

const styles = () => ({
  container: {
    background: palette.primaryWhite,
    borderRadius: 20,
    padding: "30px 0px 30px 40px",
    position: "relative",
    display: "flex",
    flexGrow: 1,
  },
  leftPart: {
    width: "60%",
    marginRight: "10%",
  },
  price: {
    marginBottom: 40,
    "& .display1": {
      lineHeight: "20px",
    }
  },
  contacts: {
    marginBottom: 20,
  },
  greyText: {
    color: palette.neutralDarkGrey
  },
  slider: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    whiteSpace: "nowrap",
    alignItems: "end",
  },
  sliderContent: {
    width: "100%",
    position: "relative",
  },
  sliderBackground: {
    height: 8,
    background: palette.neutralLightGrey,
    borderRadius: 40,
    position: "relative",
    margin: "0px 10px",
    marginBottom: 6,
  },
  sliderBar: {
    height: 8,
    background: params => params.isExceeded ? palette.accentRed100 : palette.primaryYellow200,
    borderRadius: 40,
  },
  separator: {
    width: 1,
    background: palette.neutralLightGrey,
  },
  sliderContactsInfoContainer: {
    whiteSpace: "nowrap",
    position: "relative",
    height: 34,
  },
  sliderCustomerNbr: {
    display: "inline-block",
    background: params => params.isExceeded ? palette.accentRed50 : palette.primaryYellow25,
    borderRadius: 7,
    padding: "4px 9px",
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  sliderTop: {
    margin: "0px 10px",
    position: "relative",
  },
  seniority: {
    marginTop: props => props.isExceeded || props.sliderPercent >= 90 ? 80 : 50,
    marginBottom: 5,
    lineHeight: "20px",
  },
  rightPart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "30%",
  },
  invoicesLink: {
    marginTop: 35,
    color: palette.primaryBlack
  },
  image: {
    marginTop: 20,
    height: 105,
  },
  nextPrice: {
    marginTop: 20,
  },
  sliderInfo: {
    position: "absolute",
    whiteSpace: "normal",
    textAlign: "left",
    margin: "0px 10px",
    width: "100%",
  },
  prorataInfo: {
    marginLeft: 2,
    cursor: "pointer",
    "&:hover": {
      "& $popoverContainer": {
        display: "block !important",
      }
    }
  },
  popoverContainer: {
    display: "none",
    position: "relative",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "normal",
    color: palette.primaryBlack,
  },
  triangle: {
    position: "absolute",
    bottom: -10,
    right: -2,
    width: 0,
    height: 0,
    borderBottom: `10px solid ${palette.primaryWhite}`,
    borderTop: "10px solid transparent",
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
  },
  popover: {
    position: "absolute",
    top: 10,
    right: -125,
    width: 250,
    backgroundColor: palette.primaryWhite,
    fontSize: 12,
    boxShadow: "0px 0px 5px 0px rgba(120, 120, 120, 0.25)",
    borderRadius: 5,
    padding: 10,
  },
  buttonContainer: {
    marginTop: 25,
  },
  customUpgradeText: {
    padding: "0px 30px"
  },
  priceText: {
    marginBottom: 10,
  }
});

const useStyles = makeStyles(styles);

export const SubscriptionPackageCard = ({ subscription, contacts, step, nextPlan, thermometer }) => {

  const sliderPercent = useMemo(() => Math.min(contacts.actifs * 100 / subscription.Limite_de_contacts__c, 100), [contacts, subscription]);
  const isExceeded = useMemo(() => subscription.Limite_de_contacts__c < contacts.actifs, [subscription, contacts]);
  const classes = useStyles({ isExceeded });
  const auth = useAuth();
  const intl = useIntl();
  const [isMomentInit, setIsMomentInit] = useState(false);
  const [prorata, setProrata] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    moment.locale(intl.locale);
    setIsMomentInit(true);
  }, [intl]);

  useEffect(() => {
    if (isExceeded && subscription.Plateforme_de_paiement__c === "Stripe" && subscription.interval_count === 1 && subscription.interval === "year") {
      auth.fetch(`/payment/prorataPreview?nextUpTo=${nextPlan.upTo}`).then(prorata => {
        setProrata(prorata);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }

  }, [subscription, isExceeded, auth, nextPlan]);

  const getIntervalWording = () => {
    if (subscription.interval_count === 1) {
      return <span className="display4">/<Translation id={`subscriptionInfo.package.interval.${subscription.interval}`} /></span>;
    }
    if (subscription.interval === "month") {
      switch (subscription.interval_count) {
        case 2: return <span className="textMedium">(<Translation id="subscriptionInfo.billing.Bimestrielle" />)</span>;
        case 3: return <span className="textMedium">(<Translation id="subscriptionInfo.billing.Trimestrielle" />)</span>;
        case 6: return <span className="textMedium">(<Translation id="subscriptionInfo.billing.Semestrielle" />)</span>;
      }
    }
    if (subscription.interval === "year") {
      switch (subscription.interval_count) {
        case 2: return <span className="textMedium">(<Translation id="subscriptionInfo.billing.Biannuelle" />)</span>;
        case 3: return <span className="textMedium">(<Translation id="subscriptionInfo.billing.Trisannuelle" />)</span>;
      }
    }
  };

  const getStepDuration = (step) => {
    if (step !== "always") {
      return <FormattedMessage id="subscriptionInfo.package.seniority.value" values={{ ...calcStepDate(step) }} />;
    } else {
      return <FormattedMessage id="subscriptionSlider.cursor.text.always" values={{ linebreak: " " }} />;
    }
  };

  const getWordingUpgrade = () => {
    if (subscription.interval_count !== 1) {
      return <></>;
    }

    if (thermometer === "upgradeCustom") {
      return <Translation id="subscriptionInfo.package.slider.exceed.custom" />;
    }

    if (subscription.interval === "month") {
      return <Translation id="subscriptionInfo.package.slider.exceed.month" />;
    } else {      
      return <FormattedMessage id="subscriptionInfo.package.slider.exceed.year" values={{ date: prorata ? moment(prorata.date).format("L") : "" }} />;
    }
  };

  const getPrice = () => {
    if (nextPlan.upTo !== "custom" && isExceeded && subscription.interval_count === 1) {
      if (subscription.interval === "month") {
        return nextPlan.price;
      }
      if (subscription.interval === "year") {
        return nextPlan.price_annual;
      }
    }

    return subscription.Price__c;
  };

  const currency = subscription.Currency__c === "EUR" ? "€" : "$";

  if (!isMomentInit || isLoading) {
    return ;
  }

  return (
    <div className={classes.container}>
      <div className={classes.leftPart}>
        <div className={classes.price}>
          <div className={`display2 ${classes.priceText}`}>
            {subscription.Price__c}
            {currency}
            <span className="display4">
              <FormattedMessage id="subscriptionInfo.package.currency.type"/> 
            </span>
            {getIntervalWording()}
          </div>
          <div className="text"><FormattedMessage id="subscriptionInfo.package.limit" values={{ contacts: subscription.Limite_de_contacts__c }} /></div>
        </div>
        <div className={classes.contacts}><span className="textMedium"><span style={{ color: isExceeded ? palette.accentRed400 : null }}>{contacts.actifs}</span>/{subscription.Limite_de_contacts__c}</span> <span className="text"><Translation id="subscriptionInfo.package.contactsInPackage"/></span></div>

        <div className={classes.sliderContainer}>
          
          <div className={classes.slider}>
            <span className="info">0</span>

            <div className={classes.sliderContent}>

              <div className={classes.sliderTop}>
                <div className={classes.sliderContactsInfoContainer} style={{ width: `${sliderPercent}%`, left: 0 }}>
                  <div className={`infoSmall ${classes.sliderCustomerNbr}`}>
                    {contacts.actifs} <Translation id="subscriptionSlider.contacts" />
                  </div>
                </div>
              </div>

              <div className={classes.sliderBackground}>
                <div className={classes.sliderBar} style={{ width: `${sliderPercent}%` }} />
              </div>

              <div className={`infoSmall ${classes.sliderInfo}`}>
                {isExceeded ?
                  getWordingUpgrade() :
                  sliderPercent >= 90 ? <Translation id="subscriptionInfo.package.slider.limit" /> : <>&nbsp;</>
                }
              </div>

            </div>

            <span className="info">{ subscription.Limite_de_contacts__c } <Translation id="subscriptionSlider.contacts" /></span>
          </div>
        </div>

        <div className={classes.seniority}><span className="text"><Translation id="subscriptionInfo.package.seniority" /></span> <span className="text">{getStepDuration(step.yearNbr)} </span></div>

        <div className={`info ${classes.greyText}`}>
          {contacts.outOfPackage === 0 || step.yearNbr === "always" ? 
            <Translation id="subscriptionInfo.package.contacts.all" /> :
            <FormattedMessage id="subscriptionInfo.package.contacts.notall" values={{ ...calcStepDate(step.yearNbr) }} />
          }
        </div>
      </div>
      <div className={classes.separator}></div>
      
      <div className={classes.rightPart}>
        {thermometer !== "upgradeCustom" ?
          <>
            <h4><Translation id="subscriptionInfo.body.nextPayment.title" /></h4>
            {prorata && <div className="infoSmall">
              <div className={classes.greyText}><Translation id="subscriptionInfo.package.price.prorata" />
                <i className={`fa-solid fa-circle-info ${classes.prorataInfo}`}>
                  <div className={classes.popoverContainer}>
                    <div className={classes.popover}>
                      <Translation id="subscriptionInfo.prorata.popover" />
                    </div>
                    <div className={classes.triangle} />
                  </div>
                </i>
              </div>
            </div>}
            <div className={`display1 ${classes.nextPrice}`}>
              {nextPlan.upTo !== "custom" && prorata ? prorata.prorata : getPrice()}{currency}
              <span className="display5">
                <FormattedMessage id="subscriptionInfo.package.currency.type"/> 
              </span>
            </div>
            <FormattedMessage id="subscriptionInfo.package.nextBillingDate" values={{ date: moment(prorata ? prorata.date : subscription.billing_on__c).format("LL") }} />
          </> :
          <>
            <div className={`info ${classes.customUpgradeText}`}><Translation id="subscriptionInfo.package.customUpgrade" /></div>
            <div className={classes.buttonContainer}>
              <CustomButton
                size="md"
                onClick={() => setShowPopup(true)}
              >
                <Translation id="subscriptionInfo.body.nextPayment.contactUs" />
              </CustomButton>
              {showPopup ? (
                <UsecasePopupSalesContact
                  variant="adaptPackage"
                  closeSalesContactPopup={() => setShowPopup(false)}
                ></UsecasePopupSalesContact>
              ) : null}
            </div>
          </>
        }
        <a
          className={`${classes.invoicesLink} link`}
          alt=""
          href="https://pay.kiliba.com/p/login/aEU9BQ3uo9tQ81O3cc" 
          target="_blank"
          rel="noreferrer">
          <Translation id="subscriptionInfo.package.seeInvoices"/>
        </a>
        <img className={classes.image} src={image} alt="" />
      </div>
    </div>
  );
};
