import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { Translation } from "../../common/Translation";

import { palette } from "../../../styles/palette";

import { useAuth } from "../../../hooks/useAuth";
import { AccountContext, AccountContextBehaviorSubject$ } from "../../../contexts/context";
import { SubscriptionCardNoOpportunity } from "./SubscriptionCardNoOpportunity";
import { SubscriptionBoostCards } from "./SubscriptionBoostCards";
import { SubscriptionSlider } from "./SubscriptionSlider/SubscriptionSlider";
import { SubscriptionFaq } from "./SubscriptionFaq";
import { EventSystem } from "../../../eventsystem/EventSystem";

import image1 from "../../../public/subscription1.png";
import image2 from "../../../public/subscription2.png";
import image3 from "../../../public/background_noCms.png";

const styles = () => ({
  root: {
    width: "100%",
    padding: "0 68px",
    overflow: "none",
  },
  title: {
    fontFamily: "Coolvetica",
    fontSize: "40px",
    paddingTop: 25,
    color: palette.black
  },
  subtitle: {
    marginBottom: 35,
    color: palette.neutralDarkGrey,
    marginTop: 10,
  },
  title1: {
    textAlign: "center",
    marginTop: 80,
    marginBottom: 40,
    display: "block",
  },
  faq: {
    marginTop: 45,
    color: palette.primaryBlack,
    lineHeight: "18px",
    textAlign: "center",
    "& a": {
      color: palette.primaryBlack,
    }
  }
});

const useStyles = makeStyles(styles);

export const Subscription = () => {

  const classes = useStyles();
  const history = useHistory();
  const auth = useAuth();

  const [page, setPage] = useState();
  const [isTier3, setIsTier3] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cursorSteps, setCursorSteps] = useState(null);
  const [opportunity, setOpportunity] = useState(null);
  const [currency, setCurrency] = useState("€");

  const { account } = useContext(AccountContext);
  if (account.owner === "Client Payant") {
    history.push("/");
  } 

  useEffect(() => {
    const { account } = AccountContextBehaviorSubject$.getValue();

    if (account.CMS === "shopify") {
      auth.fetch("/api/payment/shopifyBillingCurrency").then(result => {
        setCurrency(result === "EUR" ? "€" : "$");
      });
    }

    auth.fetch("/api/account_management/getSfAccount").then(result => {
      const getOpportunity = async () => {
        try {
          return await auth.fetch("/api/account_management/opportunitySf");
        } catch {
          return null;
        }
      };

      getOpportunity().then(_opportunity =>{

        if (_opportunity) {
          setOpportunity(_opportunity);
        }
  
        if (result.Tier__c === "Tier 3" || result.Client_tier_prediction__c === "Tier_3") {
          setIsTier3(true);
          setPage(account.step_register <= 2 ? "noCms" : "subscription");
        } else {
          setIsTier3(false);
          if (account.step_register <= 2) {
            setPage("noCms");
          } else {
            setPage(_opportunity && _opportunity.displayOpportunityInApp ? "subscription" : "noOpportunity");
          }
        }

      });
    });

  }, [auth]);

  const pages = {
    noCms: 
      <>
        <SubscriptionCardNoOpportunity
          title="subscription.package.title"
          subtitle={`subscription.package.subtitle1.${isTier3 ? "tier3" : "tier1_2"}`}
          image={isTier3 ? image3 : image1}
          buttonLabel={isTier3 ? "noCms.cta.default" : "button.make_appointment"}
          onClick={() => isTier3 ? history.push("/on_boarding") : EventSystem.publish("make_appointment")}
          text={isTier3 ? (
            <span onClick={() => EventSystem.publish("make_appointment")} className="link"><Translation id="accountSetup.stepModule.help" /></span>
          ): (
            <span className="text">
              <Translation id="or" />&nbsp;
              <a href onClick={() => history.push("/on_boarding")} className="link">
                <Translation id="button.connectMyShop2" />
              </a>
            </span>
          )}
        />
        <span className={`display3 ${classes.title1}`}><Translation id="subscriptionBoostCards.title" /></span>,
        <SubscriptionBoostCards isTier3={isTier3} />
        <div className={classes.faq}>
          <span className="text"><Translation id="subscriptionBoostCards.faq.text" /></span><br />
          <a className="link" href="https://support.kiliba.com/hc/fr" alt="" target="_blank" rel="noreferrer"><Translation id="subscriptionBoostCards.faq.link" /></a>
        </div>
      </>,
    noOpportunity:
      <>
        <SubscriptionCardNoOpportunity
          title="subscription.package.title"
          subtitle="subscription.package.subtitle1.tier1_2"
          image={image2}
          buttonLabel="button.getQuote"
          onClick={() => EventSystem.publish("make_appointment")}
        />
        <span className={`display3 ${classes.title1}`}><Translation id="subscriptionBoostCards.title" /></span>
        <h3 className={`${classes.title1}`}><Translation id="subscriptionBoostCards.title1" /></h3>
        <SubscriptionBoostCards />
        <div className={classes.faq}>
          <span className="text"><Translation id="subscriptionBoostCards.faq.text" /></span><br />
          <a className="link" href="https://support.kiliba.com/hc/fr" alt="" target="_blank" rel="noreferrer" ><Translation id="subscriptionBoostCards.faq.link" /></a>
        </div>
      </>,
    subscription:
      <>
        <SubscriptionSlider isTier3={isTier3} steps={cursorSteps} opportunity={opportunity} currency={currency} />
        <span className={`display3 ${classes.title1}`}><Translation id="subscriptionBoostCards.title" /></span>
        <h3 className={`${classes.title1}`}><Translation id="subscriptionBoostCards.title1" /></h3>
        <SubscriptionBoostCards />
        <SubscriptionFaq />
        <div className={classes.faq}>
          <a className="link" href="https://support.kiliba.com/hc/fr" alt="" target="_blank" rel="noreferrer" ><Translation id="subscriptionBoostCards.faq.link2" /></a>
        </div>
      </>
  };

  useEffect(() => {
    if (page) {
      if (page === "subscription") {
        auth.fetch("/api/payment/getCursorSteps").then(result => {
          
          if (opportunity && !result.steps.find(step => step.upTo === opportunity.Membres_Actifs__c)) {

            if (opportunity.Membres_Actifs__c > result.steps.find(({ step }) => step === "always").upTo) {
              setCursorSteps({ ...result, steps: result.steps.map(step => step.step === "always" ? {
                ...step,
                upTo: opportunity.Membres_Actifs__c,
                price: opportunity.Amount,
              } : step) });
              setIsLoading(false);
              
            } else {
              auth.fetch(`/api/payment/getCursorStep?upTo=${opportunity.Membres_Actifs__c}`).then(({ yearNbr, customerNbr }) => {
                setCursorSteps({...result, steps: [...result.steps, {
                  upTo: opportunity.Membres_Actifs__c,
                  customerNbr: Math.min(customerNbr, result.contacts - result.blacklistedContacts),
                  outPackageCustomerNbr: Math.max(result.contacts - result.blacklistedContacts - customerNbr, 0),
                  step: yearNbr,
                  price: opportunity.Amount,
                }].sort((a, b) => a.upTo - b.upTo)});
                setIsLoading(false);
              });
            }

          } else {
            setCursorSteps(result);
            setIsLoading(false);
          }
          
        });
      } else {
        setIsLoading(false);
      }
    }
  }, [page, auth, opportunity]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>
          <Translation id="subscription.title" />
        </div>
        <div className={classes.subtitle}>
          <Translation id="subscription.subtitle" defaultMessage="" />
        </div>
      </div>

      {!isLoading && pages[page]}

    </div>
  );
};