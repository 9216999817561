import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { injectIntl } from "react-intl";

import { withAuth } from "../withAuth";

import { Dialog } from "../common/Dialog.js";
import { TextField } from "../common/Input/TextField";
import { DayPicker } from "../pickers/DayPicker";

import "../../styles/scss/Template.css";
import "../../styles/scss/Popup.css";
import { palette } from "../../styles/palette.js";

import { getMidnightDate } from "../../tools/date";
import { reloadAccountContext } from "../../contexts/context";

const styles = (theme) => ({
  container: {
    padding: "0 64px",
  },
  title: {
    fontSize: "20px",
    fontFamily: "Coolvetica",
  },
  marginUp: {
    marginTop: "30px",
  },
  inputLabelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 32,
  },
  inputContainer: {
    marginLeft: "16px",
  },
  error: {
    color: palette.errorRed,
    fontFamily: "Poppins",
    fontSize: "12px",
    textAlign: "start",
  },
});

export const CreateUsecasePopup = withAuth(
  withStyles(styles)(
    injectIntl(
      class CreateUsecasePopup extends Component {
        constructor(props) {
          super(props);

          this.save = this.save.bind(this);
          this.close = this.close.bind(this);

          const defaultDate = new Date();
          defaultDate.setDate(defaultDate.getDate() + 1);

          this.state = {
            name: props.name || "",
            date: props.date || defaultDate,
            error: null,
          };
        }

        close() {
          this.setState({ error: null });
          this.props.close();
        }

        async save() {
          const { intl, auth, onSave } = this.props;
          const { name, date } = this.state;

          if (!name) {
            return this.setState({
              error:
                intl.messages[
                  "usecaseList.popup.usecaseAdder.errors.name.missing"
                ],
            });
          } else if (name.length > 20) {
            return this.setState({
              error:
                intl.messages[
                  "usecaseList.popup.usecaseAdder.errors.name.tooLong"
                ],
            });
          }

          const finalDate = getMidnightDate(date);

          await auth.fetch("/api/usecase/upsertCustomUsecase", {
            method: "POST",
            body: JSON.stringify({
              name,
              date: finalDate.getTime() - finalDate.getTimezoneOffset() * 60000,
            }),
          });
          await reloadAccountContext();

          onSave();
          this.close();
        }

        render() {
          const { intl, classes, isOpen } = this.props;

          return (
            <Dialog
              isOpen={isOpen}
              close={this.close}
              buttonsProps={[
                {
                  id: "usecaseAdderSubmit",
                  type: "primary",
                  onClick: this.save,
                  label: intl.messages["usecaseList.popup.usecaseAdder.submit"],
                  dataCy: "buttonSubmit",
                },
              ]}
            >
              <div className={classes.container}>
                <div className={classes.title}>
                  {intl.messages["usecaseList.popup.usecaseAdder.name.title"]}
                </div>
                <div className={classes.error}>
                  {!!this.state.error && this.state.error}&nbsp;
                </div>
                <TextField
                  value={this.state.name}
                  placeholder={
                    intl.messages[
                      "usecaseList.popup.usecaseAdder.name.placeholder"
                    ]
                  }
                  id="customUsecaseName"
                  onChange={(event) =>
                    this.setState({ name: event.target.value })
                  }
                  error={!!this.state.error}
                  dataCy="inputName"
                />

                <div className={`${classes.title} ${classes.marginUp}`}>
                  {intl.messages["usecaseList.popup.usecaseAdder.date.title"]}
                </div>

                <div className={classes.inputLabelContainer}>
                  {intl.messages["usecaseList.popup.usecaseAdder.date.label"]}
                  <div className={classes.inputContainer}>
                    <DayPicker
                      futureOnly
                      active={this.state.date}
                      onDayClick={(date) => this.setState({ date })}
                      dataCy="inputDate"
                    />
                  </div>
                </div>
              </div>
            </Dialog>
          );
        }
      }
    )
  )
);
