export const palette = {
  primaryYellow15: "#FFF3DD",
  primaryYellow25: "#FFEECE",
  primaryYellow50: "#FFE7B9",
  primaryYellow100: "#FFE1A7",
  primaryYellow200: "#FFD481",
  primaryYellow300: "#F1CF8D",
  primaryYellow400: "#EDC371",
  primaryYellow500: "#E9B44D",
  primaryWhite: "#FFFFFF",
  primaryBlack: "#0C0C0E",
  alertError: "#DB2419",
  alertWarning: "#E9B44D",
  alertSuccess: "#6FA728",
  altertInfo: "#0C0C0E",
  accentRed50: "#FFE2D5",
  accentRed100: "#FFC5AC",
  accentRed200: "#FFAC89",
  accentRed300: "#F3AC8D",
  accentRed400: "#F09771",
  accentRed500: "#EC7D4D",
  accentGreen25: "#EFFAE2",
  accentGreen50: "#E5F7CF",
  accentGreen100: "#D4F1AF",
  accentGreen200: "#C1EB8D",
  accentGreen300: "#BBD798",
  accentGreen400: "#AACD7E",
  accentGreen500: "#86B846",
  accentPurple100: "#D1D3FF",
  accentPurple200: "#BDC0FF",
  accentPurple300: "#ACAFF3",
  accentPurple400: "#979BF0",
  accentPurple500: "#7D82EC",
  neutralInputs: "#FAF6F0",
  neutralCards: "#F9F9F9",
  neutralUltraLightGrey: "#F6F4F0",
  neutralBCGGrey: "#F9F5F0",
  neutralBCGGreyHover: "#EFEAE1",
  neutralLightGrey: "#F0EDEA",
  neutralLightGreyHover: "#DDD6D0",
  neutralDarkGrey: "#7A756E",
  neutralOutlineCards: "#ECEAE7",
  neutralMediumGrey: "#DDDAD5",
  neutralSemiGrey: "#BEBAB3",


  yellow: "#ffd481",
  black: "#0E0E0F",
  white: "#ffffff",
  lightgray: "#B5BDB8",
  gray: "#f0f2f4",
  anothergray: "#d8d8d8",
  graymagic: "#a5adb8",
  darkgray: "#878787",
  darkergray: "#3e464f",
  // orange: "#BDC0FF",
  darkorange: "#EC9F15",
  lightorange: "#FFF7EC",
  darkblue: "#00445F",
  darkerblue: "#003950",
  lightblue: "#577C8F",
  lighterblue: "#b1dde8",
  lightred: "#F6AFAA",
  red: "#ED5F55",
  darkred: "#963939",
  green: "#DCF4C3",
  darkgreen: "#427D07",
  lightgreen: "#C1EB8D",
  purple: "#BDC0FF",
  darkpurple: "#979bf0",
  anotherlightgray: "#838580",
  grayTertiary: "#f9f9f9",
  errorRed: "#db2419",
};
