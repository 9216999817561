/**
 * Return a string with separators, currency, and thousand, million, billion markers.
 * eg : 1.00$, 1,00€, 1,000.00$, 1 000k$, 10 000m$, 100,000M$
 * @param {string} value 
 * @param {string} currency 
 */
export function getFormattedNumber(value, currency, locale = "en", decimalPrecision = 2) {
  if (!value || Number(value) === 0) return "-";

  const decimalSeparator = locale === "fr" ? "," : locale === "en" ? "." : locale === "es" ? "," : ".";
  const thousandSeparator = locale === "fr" ? " " : locale === "en" ? "," : locale === "es" ? " " : ",";

  const [int, decimal] = value.toString().split(".");

  let formattedValue;

  // cut long numbers last digits
  if (Number(int) > 999999999999) formattedValue = String(Math.round(Number(int) / 1000000000));
  else if (Number(int) > 999999999) formattedValue = String(Math.round(Number(int) / 1000000));
  else if (Number(int) > 999999) formattedValue = String(Math.round(Number(int) / 1000));
  else formattedValue = int;

  // add spaces
  formattedValue = formattedValue.split("").reverse().join(""); // reverse
  formattedValue = formattedValue.replace(/(\d{3}(?=\d))/g, `$1${thousandSeparator}`); // add a separator between every 3 characters
  formattedValue = formattedValue.split("").reverse().join(""); // reverse back

  // add thousand, million, billion marker
  if (Number(int) > 999999999999) formattedValue += "M";
  else if (Number(int) > 999999999) formattedValue += "m";
  else if (Number(int) > 999999) formattedValue += "k";
  else if (decimal && decimalPrecision > 0) formattedValue += `${decimalSeparator}${String(decimal).slice(0, decimalPrecision) + (decimal.length === 1 ? "0" : "")}`;

  // add currency
  if (currency) {
    formattedValue += (currency === "CHF" ? " " : "") + currency;
  }

  return formattedValue;
}
