import { useCallback, useEffect, useState, useRef, useMemo, useReducer } from "react";
import { useIntl } from "react-intl";
import { debounce } from "lodash";

import { ParametersSection } from "./ParametersSections/ParametersSection";
import { LogoParameters } from "./ParametersSections/Parameters/LogoParameters";
import { ColorParameters } from "./ParametersSections/Parameters/ColorParameters";
import { PriceParameters } from "./ParametersSections/Parameters/PriceParameters";
import { ToneParameters } from "./ParametersSections/Parameters/ToneParameters";
import { FontParameters } from "./ParametersSections/Parameters/FontParameters";
import { SocialMediasParameters } from "./ParametersSections/Parameters/SocialMediasParameters";

import { useAuth } from "../../hooks/useAuth";
import { EventSystem } from "../../eventsystem/EventSystem";

import classes from "./TemplateCustomization.module.scss";
import { CheckList } from "./CheckList/CheckList";

import { CircularProgress } from "@material-ui/core";
import { parseFileInput } from "../../services/file.service";
import { AccountContextBehaviorSubject$, reloadAccountContext } from "../../contexts/context";



export const TemplateCustomizationContent = ({
  displayedParameters,
  descriptionBlock,
  onPageLoad,
  onColorChange,
  onLogoChange,
  onTextChange,
  mustShowCheckList,
  padding,
}) => {
  const forceUpdate = useReducer((a) => a + 1, 0)[1];

  const [preview, setPreview] = useState();
  const [previewStyle, setPreviewStyle] = useState();
  const [logo, setLogo] = useState({});
  const [isPriceVisible, setIsPriceVisible] = useState();
  const [templateParameters, setTemplateParameters] = useState([]);
  const [tone, setTone] = useState();
  const [initialSeenParameters, setInitialSeenParameters] = useState();
  const [seenParameters, setSeenParameters] = useState();
  const [socialMedias, setSocialMedias] = useState({});
  const [selectedColors, setSelectedColors] = useState([]);
  const [noCms, setNoCms] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const previewRef = useRef();
  const rawPreviewRef = useRef();

  const colorRef = useRef();
  const textRef = useRef();
  const socialMediasRef = useRef();

  const parameterSectionRefs = useMemo(() => ({
    color: colorRef,
    text: textRef,
    socialMedias: socialMediasRef,
  }), [colorRef, textRef, socialMediasRef]);

  const intl = useIntl();
  const auth = useAuth();
  const { account } = AccountContextBehaviorSubject$.getValue();

  const loadHtml = useCallback(async () => {
    await auth.fetch("/api/account_management/getEmail?templateName=postVisits0", {
      method: "POST",
      body: JSON.stringify({
        structureSettings: {
          mustRemoveUnusedBlocks: true,
          mustAddFakeData: true,
        },
        variationSettings: {
          wordings: {
            languages: [intl.locale],
            numbers: ["plural"],
            tones: [tone],
          },
          htmls: {
            languages: [intl.locale],
            numbers: ["plural"],
            tones: [tone],
          },
        }
      }),
    }).then(result => {
      setPreview(result.email.htmls[intl.locale].plural[tone]);
      setIsLoading(false);
    }).catch(error => {
      console.log(error);
    });
  }, [auth, intl, tone]);

  useEffect(() => {
    onPageLoad?.();
  }, [onPageLoad]);


  useEffect(() => {
    loadHtml();
  }, [tone,loadHtml]);


  useEffect(() => {
    setLogo({
      logo: account.url_logo,
      url: account.url_logo,
      file: null,
    });

    auth.fetch("/api/template_management/getAllTemplateParameters")
      .then((parameters) => {
        setTemplateParameters(account.template_parameters.map(param => ({
          name: param.name,
          value: param.value,
          type: parameters.parameters.find(({ name }) => name === param.name)?.type,
        })));
        //// REFACTO FIELD SAVED DEPRECATED 
        if (account.template_parameters[0].saved) {
          setSelectedColors(selectedColors => [...selectedColors, ...account.template_parameters[0].saved]);
        }
        setTone(account.template_parameters.find(({ name }) => name === "tone").value);
        setIsPriceVisible(account.priceIsVisible);
        setInitialSeenParameters(account.templateCustomizationSeenParameters);
        setSeenParameters(account.templateCustomizationSeenParameters);
        setSocialMedias(account.social_medias || {});
        setNoCms(account.step_register < 3);
      });
  }, [auth]);

  const onImageLoaded = () => {
    const images = rawPreviewRef.current.querySelectorAll("img");
    const imagesAreLoaded = Array.from(images).filter(image => !image.complete).length === 0;

    if (imagesAreLoaded) {
      rawPreviewRef.current.style.display = null;

      const rawPreviewScrollWidth = rawPreviewRef.current?.scrollWidth;
      const previewWidth = previewRef.current?.offsetWidth;
      const previewIsTooBigForItsContainer = previewWidth < rawPreviewScrollWidth;

      if (previewIsTooBigForItsContainer) {
        const scaleRatio = previewWidth / rawPreviewScrollWidth;

        const previewHeight = rawPreviewRef.current?.offsetHeight;
        const scaledPreviewHeight = previewHeight * scaleRatio;
        const scaledPreviewOffsetY = Math.round((previewHeight - scaledPreviewHeight) / 2);
        const scaledPreviewOffsetX = Math.round((rawPreviewScrollWidth - previewWidth) / 2);

        setPreviewStyle({
          wrapper: {
            width: previewWidth,
            height: scaledPreviewHeight,
          },
          content: {
            marginTop: `-${scaledPreviewOffsetY}px`,
            marginBottom: `-${scaledPreviewOffsetY}px`,
            marginLeft: `-${scaledPreviewOffsetX}px`,
            marginRight: `-${scaledPreviewOffsetX}px`,
            scale: `${scaleRatio}`,
          },
        });
      }

      rawPreviewRef.current.style.display = "none";
    };
  };

  useEffect(() => {
    const images = rawPreviewRef.current.querySelectorAll("img");
    images.forEach(image => {
      image.onload = onImageLoaded;
      image.onerror = onImageLoaded;
    });

    return () => {
      images.forEach(image => {
        image.onload = null;
        image.onerror = null;
      });
    };
  }, [preview]);


  async function getMainLogoColors(url_logo) {
    const mainLogoColor = await auth.fetch(`/api/account_management/getMainLogoColors?logoUrl=${url_logo}`, {
      method: "GET"
    });
    setIsLoading(true);
    if (mainLogoColor && account.template_parameters) {
      const indicesToUpdate = {
        accentColor: templateParameters.findIndex(item => item.name === "accentColor"),
        secondaryColor: templateParameters.findIndex(item => item.name === "secondaryColor"),
      };

      if (selectedColors.length > 26) {
        setSelectedColors(selectedColors => selectedColors.splice(0, selectedColors.length - 20));
      }
      setSelectedColors(selectedColors => [...selectedColors, mainLogoColor.mainColors.color1]);
      setSelectedColors(selectedColors => [...selectedColors, mainLogoColor.mainColors.color2]);
      

      templateParameters[indicesToUpdate.accentColor] = {
        value: mainLogoColor.mainColors.color2,
        name: "accentColor",
        type: "color",
      };
      templateParameters[indicesToUpdate.secondaryColor] = {
        value: mainLogoColor.mainColors.color1,
        name: "secondaryColor",
        type: "color",
      };
      setTemplateParameters(templateParameters);
      saveColor(templateParameters);
    }
  }

  async function updateLogo() {
    onLogoChange?.();
    const file = document.querySelector("input[type=file]").files[0]; // Récupération du fichier

    if (file && file.type === "image/svg+xml") {
      return EventSystem.newNotification(
        "notification.error",
        intl.messages["templateCustomization.logo.add.error.format"]
      );
    }

    const fileData = await parseFileInput(file);
    if (fileData.size < 150 * 1024) {
      setLogo(prev => ({ ...prev, file, logo: fileData.raw }));

      await auth.fetch("/account_management/editlogo", {
        method: "post",
        body: JSON.stringify({
          filename: fileData.name,
          type: fileData.type,
          content: fileData.content
        })
      }).then(async (response) => {
        await getMainLogoColors(response.url_logo);
      });
      await reloadAccountContext();
      await loadHtml();
      forceUpdate();

      const newSeenParameters = JSON.parse(JSON.stringify(seenParameters));
      newSeenParameters["logo"] = true;
      saveSeenParameters(newSeenParameters);
    } else {
      EventSystem.newNotification(
        "notification.error",
        intl.messages["templateCustomization.logo.add.error"]
      );
    }
  };

  async function deleteLogo() {
    onLogoChange?.();
    setLogo(prev => ({ ...prev, logo: null, file: new File([], "empty") }));
    await auth.fetch("/api/account_management/deletelogo", { method: "DELETE" });
    await reloadAccountContext();
    await loadHtml();
  };

  const saveColor= useCallback((newTemplateParameters) => {
    const formattedNewTemplateParameters = JSON.parse(JSON.stringify(newTemplateParameters));
    for (const param of formattedNewTemplateParameters) {
      param.saved = selectedColors;
    }
    auth.fetch("/api/account_management/editAccount", {
      method: "POST",
      body: JSON.stringify({
        template_parameters: formattedNewTemplateParameters,
      }),
    })
      .then(async () => {
        await reloadAccountContext();
        loadHtml();
      })
      .catch(error => {
        console.log(error);
      });

  }, [auth,loadHtml,selectedColors]);

  //eslint-disable-next-line
  const updateColor = useCallback(debounce((name, value) => {
    setIsLoading(true);
    onColorChange?.();
    setTemplateParameters(previousState => {
      const newValue = value.hex;
      const newTemplateParameters = JSON.parse(JSON.stringify(previousState));
      newTemplateParameters.find(param => param.name === name).value = newValue;

      setSelectedColors(previousSelectedColor => {
        if (previousSelectedColor.length > 26) {
          previousSelectedColor.splice(0, previousSelectedColor.length - 20);
        }
        previousSelectedColor.push(newValue);
        return previousSelectedColor;
      });

      saveColor(newTemplateParameters);
      return newTemplateParameters;
    } );
  }, 800), [saveColor]);


  const updateIsPriceVisible = async (value) => {
    onTextChange?.();
    setIsPriceVisible(value);
    auth.fetch("/api/account_management/editAccount", {
      method: "POST",
      body: JSON.stringify({
        priceIsVisible: value,
      }),
    }).then(async (response) => {
      loadHtml();
      await reloadAccountContext();
    });
  };

  const updateFontFamily = async (value) => {
    onTextChange?.();
    const newTemplateParameters = templateParameters.map(param => param.name === "fontFamily" ? { ...param, value } : param);
    setTemplateParameters(newTemplateParameters);
    auth.fetch("/api/account_management/editAccount", {
      method: "POST",
      body: JSON.stringify({
        template_parameters: newTemplateParameters.map(({ name, value }) => ({ name, value })),
      }),
    }).then(async (response) => {
      loadHtml();
      await reloadAccountContext();
    });
  };

  const saveParameters= async (param) => {
    setTone(param);
    const newTemplateParameters = templateParameters.map(({ name, value }) => ({
      name,
      value: (name === "tone" ? param : value)
    }));
    auth.fetch("/api/account_management/editAccount", {
      method: "POST",
      body: JSON.stringify({
        template_parameters: newTemplateParameters,
      })
    }).then(async () => {
      await reloadAccountContext();
    });
  };


  const saveSocialMedias = useMemo(() => {
    return debounce(async (socialMedias) => {
      await auth.fetch("/api/account_management/editAccount", {
        method: "POST",
        body: JSON.stringify({ socialMedias }),
      });
      await loadHtml();
      await reloadAccountContext();
    }, 250);
  }, [auth, loadHtml]);

  const updateSocialMedias = async (socialMedia, value) => {
    onTextChange?.();

    const newSocialMedias = JSON.parse(JSON.stringify(socialMedias));
    newSocialMedias[socialMedia] = value;

    for (const socialMedia in newSocialMedias) {
      if (!newSocialMedias[socialMedia] || !newSocialMedias[socialMedia].trim()) {
        delete newSocialMedias[socialMedia];
      }
    }

    setSocialMedias(newSocialMedias);
    saveSocialMedias(newSocialMedias);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getCheckListFromDisplayedParameters = useCallback(() => {
    return displayedParameters.map(displayedParameter => {
      const seenParameterKey = seenParameters ? Object.keys(seenParameters).find(seenParameterKey => seenParameterKey === displayedParameter) : null;
      return {
        key: displayedParameter,
        status: seenParameters?.[seenParameterKey] ? "seen" : null,
      };
    });
  }, [displayedParameters, seenParameters]);

  const checkList = useMemo(getCheckListFromDisplayedParameters, [getCheckListFromDisplayedParameters]);

  const saveSeenParameters = useCallback(async newSeenParameters => {
    setSeenParameters(newSeenParameters);

    if (JSON.stringify(seenParameters) !== JSON.stringify(newSeenParameters)) {

      await auth.fetch("/api/account_management/editAccount", {
        method: "POST",
        body: JSON.stringify({
          templateCustomizationSeenParameters: newSeenParameters,
        }),
      });
      await reloadAccountContext();
    }
  }, [auth, seenParameters]);

  useEffect(() => {
    if (!seenParameters) return;

    async function updateSeenParameters() {
      if (!seenParameters) return;

      const parameterSectionRefKeys = Object.keys(parameterSectionRefs);
      for (let i = 0; i < parameterSectionRefKeys.length; i++) {
        const parameterSectionRefKey = parameterSectionRefKeys[i];
        const parameterSectionRef = parameterSectionRefs[parameterSectionRefKey];

        if (!parameterSectionRef.current) continue;

        const bottomOfElement = parameterSectionRef.current.getBoundingClientRect().bottom + window.scrollY;
        const bottomOfScreen = window.innerHeight + window.scrollY;

        const elementWasScrolledPast = bottomOfScreen > bottomOfElement;
        if (elementWasScrolledPast) {
          const newSeenParameters = JSON.parse(JSON.stringify(seenParameters));
          newSeenParameters[parameterSectionRefKey] = true;

          saveSeenParameters(newSeenParameters);
        }
      }
    }
    const debouncedUpdateSeenParameters = debounce(updateSeenParameters, 50); // prevent multiple calls per scroll

    window.addEventListener("scroll", debouncedUpdateSeenParameters);

    return () => window.removeEventListener("scroll", debouncedUpdateSeenParameters);
  }, [auth, parameterSectionRefs, seenParameters, saveSeenParameters]);

  const allParametersHadBeenSeenWhenPageWasLoaded = useMemo(() => {
    if (!initialSeenParameters) return null;

    const initialSeenParameterKeys = Object.keys(initialSeenParameters).filter(initialSeenParameter => displayedParameters.indexOf(initialSeenParameter) > -1);
    const allParametersHadBeenSeenWhenPageWasLoaded = (
      initialSeenParameterKeys.filter(initialSeenParameterKey => initialSeenParameters[initialSeenParameterKey]).length === initialSeenParameterKeys.length
    );

    return allParametersHadBeenSeenWhenPageWasLoaded;
  }, [displayedParameters, initialSeenParameters]);

  const innerMustShowCheckList = useMemo(() => {
    return mustShowCheckList && initialSeenParameters && !allParametersHadBeenSeenWhenPageWasLoaded;
  }, [mustShowCheckList, initialSeenParameters, allParametersHadBeenSeenWhenPageWasLoaded]);

  const getStatus = useCallback((key) => {
    return innerMustShowCheckList
      ? checkList.find(checkListItem => checkListItem.key === key).status
      : undefined;
  }, [innerMustShowCheckList, checkList]);

  return (
    <div className={classes.root}>
      <div
        data-test="rawPreview"
        ref={rawPreviewRef}
        className={classes.rawPreview}
        dangerouslySetInnerHTML={{ __html: preview }}
      />

      <div className={classes.leftPart}>
        {descriptionBlock}

        <div className={classes.parameterSections}>
          {innerMustShowCheckList && <CheckList value={checkList} />}

          {
            displayedParameters.find(displayedParameter => displayedParameter === "logo") &&
            <div>
              <ParametersSection
                forwardRef={parameterSectionRefs.logo}
                status={getStatus("logo")}
                titleStyle="center"
                title={intl.messages["templateCustomization_logo.title"]}
                children={[
                  <LogoParameters
                    key={0}
                    logo={logo?.logo}
                    noCms={noCms}
                    deleteLogo={deleteLogo}
                    updateLogo={updateLogo}
                  />,
                ]}
              />
            </div>
          }

          {
            displayedParameters.find(displayedParameter => displayedParameter === "color") &&
            <ParametersSection
              forwardRef={parameterSectionRefs.color}
              status={getStatus("color")}
              title={intl.messages["templateCustomization.title_color"]}
              children={[
                <ColorParameters
                  key={0}
                  selectedColors={selectedColors}
                  colorParameters={templateParameters.filter(({ type }) => type === "color")}
                  updateColorParameter={updateColor}
                />
              ]}
            />
          }

          {
            displayedParameters.find(displayedParameter => displayedParameter === "text") &&
            <ParametersSection
              forwardRef={parameterSectionRefs.text}
              status={getStatus("text")}
              title={intl.messages["templateCustomization.title_tone"]}
              children={[
                <PriceParameters
                  key={0}
                  priceIsVisible={isPriceVisible}
                  setPriceIsVisible={event => updateIsPriceVisible(event.target.value === "true")}
                />,
                <ToneParameters
                  key={1}
                  tone={tone}
                  setTone={event => saveParameters(event.target.value)}
                />,
                <FontParameters
                  key={2}
                  fontFamily={templateParameters.find((templateParameter) => templateParameter.name === "fontFamily")?.value}
                  setFontFamily={updateFontFamily}
                  popperPlacement="bottom-start"
                  maxHeight="200px"
                />
              ]}
            />
          }

          {
            displayedParameters.find(displayedParameter => displayedParameter === "socialMedias") &&
            <ParametersSection
              forwardRef={parameterSectionRefs.socialMedias}
              status={getStatus("socialMedias")}
              title={intl.messages["templateCustomization.title_socialmedia"]}
              children={[
                <SocialMediasParameters
                  key={1}
                  socialMedias={socialMedias}
                  handleChangeSocialMedias={updateSocialMedias}
                />,
              ]}
            />
          }
        </div>

        <div className={classes.buttonUpContainer}>
          <div onClick={scrollToTop} className={classes.buttonUp} data-test="scrollToTop">
            <i className="fa-solid fa-chevron-up"></i>
          </div>
        </div>

      </div>

      <div className={classes.rightPart}>
        <div className={classes.previewContainer}>
          <div style={previewStyle?.wrapper}>
            {preview && !isLoading ?
              <div
                className={classes.preview}
                data-test="preview"
                style={previewStyle?.content}
                ref={previewRef}
                dangerouslySetInnerHTML={{ __html: preview }}
              /> :
              <div
                className={classes.previewLoading}
              >
                <CircularProgress color="primary" size="30px" />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
