import React, { Component } from "react";
import { injectIntl } from "react-intl";

import { FormControl } from "@material-ui/core";
import { RadioGroup, Box } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import { withAuth } from "../withAuth.js";
import { FormButtonTO } from "../FormButtonTO.jsx";
import { PopupTO } from "../PopupTO.js";
import { EventSystem } from "../../eventsystem/EventSystem.js";
import { ItemPicker } from "../pickers/ItemPicker.js";
import { TextField } from "../common/Input/TextField.js";
import { KiTextField } from "../common/Input/KiTextField.js";
import { KiTooltip } from "../common/KiTooltip/KiTooltip.js";
import { Translation } from "../common/Translation.js";
import { palette } from "../../styles/palette.js";

import { Radio } from "../common/Input/Radio/Radio.js";
import { matchingUnisex } from "../../ressources/matchingUnisex.js";

import doubleCircle from "../../public/double_down.png";
import oval from "../../public/oval.png";
import { StatusPicker } from "../pickers/StatusPicker.js";
import { StockParameters } from "../TemplateCustomization/ParametersSections/Parameters/StockParameters.jsx";
import { UTMParameters } from "../TemplateCustomization/ParametersSections/Parameters/UTMParameters.jsx";
import { AccountContextBehaviorSubject$, reloadAccountContext } from "../../contexts/context.js";
import { EmailAuthentication } from "./sections/EmailAuthentication.jsx";

import classes from "./Settings.module.scss";
import { PromoCodesGroupParameters } from "../TemplateCustomization/ParametersSections/Parameters/PromoCodesGroupParameters.jsx";


export const EditProfile = withAuth(
    injectIntl(
      class EditProfile extends Component {
        constructor(props) {
          super(props);
          this.handleSubmit = this.handleSubmit.bind(this);
          this.handleComplete = this.handleComplete.bind(this);
          this.handleChange = this.handleChange.bind(this);
          this.closeDeleteAccount = this.closeDeleteAccount.bind(this);
          this.savecb = this.savecb.bind(this);
          this.opencbStripe = this.opencbStripe.bind(this);
          this.closecbStripe = this.closecbStripe.bind(this);
          this.deleteAccount = this.deleteAccount.bind(this);
          this.handleBusinessSector = this.handleBusinessSector.bind(this);
          this.handleActivity = this.handleActivity.bind(this);
          this.setStatusList = this.setStatusList.bind(this);
          this.setCustomerGroupPromoCode = this.setCustomerGroupPromoCode.bind(this);

          this.state = {
            firstname: "",
            lastname: "",
            company_name: "",
            sender_name: "",
            activity: this.props.intl.messages["field.activity"],
            business_sector: this.props.intl.messages["field.business_sector"],
            stockGestionForEmail: "",
            UTMIsEnabled: "false",
            displayUTMIsEnabledSetting: false,
            new_cb: "",
            new_month: "",
            new_years: "",
            new_cvc: "",
            error_firstname: false,
            error_lastname: false,
            error_company_name: false,
            error_sender_name: false,
            errorReplyToEmail: false,
            replyToEmail: "",
            useless: "sea",
            file: "",
            deleteAccount: false,
            cbStripe: false, // popup cb stripe
            placeholder_last4: "",
            placeholder_month: "",
            placeholder_years: "",
            placeholder_cvc: "",
            pending: false,
            unisex: true,
            id_account: null,
            owner: "",
            defaultStatusList: [],
            statusCheckedList: null,
            filterStatusIsReady: false,
            shouldShowStatusesFilter: false,
            customerGroupsPromoCode: null,
          };
        }

        async UNSAFE_componentWillMount() {
          // APPEL DE RECUPERATION DES INFOS DU CLIENT
          const user = await this.props.auth.getUser();
          const { account, config } = AccountContextBehaviorSubject$.getValue();
          const { given_name, family_name } = user.attributes;
          let {
            unisex = "True",
          } = account;
          const {
            company_name = "",
            sender_name = "",
            activity = "",
            business_sector = "",
            replyToEmail = "",
            owner,
            _id: id_account,
            CMS
          } = account;

          let firstname = "";
          let lastname = "";

          if (unisex === null || unisex === undefined) {
            unisex = true;
          } else {
            unisex = JSON.parse(unisex.toLowerCase());
          }

          if (given_name !== null && given_name !== undefined) {
            firstname = given_name;
          }
          if (family_name !== null && family_name !== undefined) {
            lastname = family_name;
          }

          this.setState({
            firstname,
            lastname,
            useless: "sea",
            company_name,
            sender_name,
            business_sector,
            activity,
            replyToEmail,
            unisex,
            id_account,
            owner,
            shouldShowStatusesFilter: config?.TurnoverFilteredByOrderStatus || false,
            shouldShowCodePromosGroupConfig: config?.customersGroupManagement || false,
            userIsKilibaAdmin: user.canImpersonateWrite
          });
        }

        async componentDidMount() {
          const { account } = AccountContextBehaviorSubject$.getValue();
          this.getDistinctOrderStatuses();
          this.getAccountOrderStatuses();

          this.setState({
            account,
            stockGestionForEmail: account.stockGestionForEmail,
            UTMIsEnabled: account.utm_is_enabled,
            displayUTMIsEnabledSetting: account.displayUTMIsEnabledSetting,
          });
        }

        handleActivity = (value) => {
          this.setState({ activity: value });
          this.switchUnisex(this.state.business_sector, value);
        };

        handleBusinessSector = (business_sector) => {
          this.setState({ business_sector: business_sector });
          this.switchUnisex(business_sector, this.state.activity);
        };

        // Autoswitch radio button (unisex or gendered) according to business sector and activity
        // on this file https://docs.google.com/spreadsheets/d/1-gHSdSBSFe1xin7JgqXCVoiVMp-cctcnLs4nO39RMW0/edit#gid=1083864468
        switchUnisex = (businessSector, activity) => {
          if (businessSector && activity) {
            this.setState({ unisex: JSON.parse(matchingUnisex[businessSector][activity].toLowerCase()) });
          }
        };

        handleChange = (name) => (event) => {
          this.setState({
            [name]: event.target.value
          });
        };

        handleChangePicker = (value, pickerName) => {
          this.setState({ [pickerName]: value });
        };

        handleChangeCheckbox = (event) => {
          this.setState({ useless: event.target.value });
        };

        handleChangeCheckboxGender = (event) => {
          this.setState({ unisex: !this.state.unisex });
        };

        changeUrl = (url) => (event) => {
          this.props.history.push(url);
        };

        savecb(e) {
          this.setState({ pending: true }, () => {
            EventSystem.publish("change_card", {});
          });
        }

        // Ouverture popup changement de cb
        opencbStripe() {
          this.setState({ cbStripe: true });
        }

        // Fermeture popup changement de cb
        closecbStripe() {
          this.setState({ cbStripe: false });
        }

        // Fermeture popup suppression de compte
        closeDeleteAccount() {
          this.setState({ deleteAccount: false });
        }

        // Validation de suppression de compte
        deleteAccount() {
          const {
            intl,
          } = this.props;

          const self = this;
          this.setState({ pending: true }, () => {
            const url = "/api/account_management/deleteAccount";
            const options = {
              method: "POST",
              body: JSON.stringify({})
            };
            self.props.auth
              .fetch(url, options)
              .then((result) => {
                EventSystem.newNotification("notification.action", intl.messages["editProfile.deleteAccount.success"]);
                self.setState({ pending: false }, () => {
                  self.props.auth.logout();
                });
              })
              .catch((error) => {
                self.setState({ deleteAccount: false, pending: false });
                console.error(error);
                EventSystem.newNotification("notification.action", intl.messages["editProfile.deleteAccount.error"]);
              });
          });
        }

        capitalizeFirstLetter(string) {
          return string.charAt(0).toUpperCase() + string.slice(1);
        }

        // Bouton de sauvegarde du profil
        handleComplete() {
          const {
            intl,
          } = this.props;

          if (this.state.statusCheckedList.length === 0) this.state.statusCheckedList.push("NA");

          const url = "/api/account_management/editprofile";
          const options = {
            method: "POST",
            body: JSON.stringify({
              company_name: this.state.company_name,
              sender_name: this.state.sender_name,
              replyToEmail: this.state.replyToEmail.trim(),
              activity: this.state.activity,
              business_sector: this.state.business_sector,
              stockGestionForEmail: this.state.stockGestionForEmail,
              UTMIsEnabled: this.state.UTMIsEnabled,
              holiday: this.state.useless,
              unisex: this.capitalizeFirstLetter(this.state.unisex.toString()),
              orderStatusesFilterSaved: this.state.statusCheckedList,
              customerGroupsPromoCode: this.state.customerGroupsPromoCode
            })
          };

          this.props.auth.updateUserAttributes({
            family_name: this.state.lastname,
            given_name: this.state.firstname
          }).then(() => {
            return this.props.auth.fetch(url, options);
          }).then(async () => {
            await reloadAccountContext();
            EventSystem.newNotification("notification.action", intl.messages["editProfile.save.success"]);
            EventSystem.publish("updateProfile");
          }).catch((error) => {
            console.error(error);
            EventSystem.newNotification("notification.action", intl.messages["editProfile.save.error"]);
          });
        }

        handleSubmit(e) {
          console.log(e);
          e.preventDefault();
        }

        setStatusList(list) {
          this.setState({ statusCheckedList: list });
        }

        setCustomerGroupPromoCode(value) {
          this.setState({ customerGroupsPromoCode: value });
        }

        onCopyToClipboard() {
          const {
            intl,
          } = this.props;

          EventSystem.newNotification("notification.action", intl.messages["editProfile.notif.id_account"]);
        }

        async getDistinctOrderStatuses() {
          try {
            const accountId = this.props.auth.getAccountId();
            const orderStatuses = await this.props.auth.fetch(`/data/getDistinctOrderStatuses?accountId=${accountId}`, {
              method: "GET"
            });
            this.setState({ defaultStatusList: orderStatuses, filterStatusIsReady: true });
          } catch (err) {
            console.error(err);
          }
        }

        async getAccountOrderStatuses() {
          const urlOrderStatuses = "/account_management/getAccountOrderStatuses";
          const option = { method: "GET" };

          try {
            const accountOrderStatuses = await this.props.auth.fetch(urlOrderStatuses, option);
            this.setState({ statusCheckedList: accountOrderStatuses.statusesSaved });
          } catch (err) {
            console.error(err);
          }
        }

        render() {
          const { intl } = this.props;

          //// CHECK IF MAGENTO PLUG VERSION IS USABLE FOR STOCK GESTION
          let stockIsUsableForMagento = false;
          let versionsIsEqual = true;

          if (this.state.account && this.state.account.CMS === "magento") {
            const magentoCompatibleVersion = "2.0.4";
            const plugedVersion = this.state.account.plug_version;
            const v1Numbers = magentoCompatibleVersion.split(".").map(Number); // ["1", "12", "4"] => [1, 12, 4]
            const v2Numbers = plugedVersion ? plugedVersion.split(".").map(Number) : []; // ["2", "0", "4"] => [2, 0, 4]

            for (let i = 0; i < v1Numbers.length; i++) {
              if (v1Numbers[i] < v2Numbers[i]) {
                stockIsUsableForMagento = true;
                versionsIsEqual = false;
                break;
              } else if (v1Numbers[i] > v2Numbers[i]) {
                versionsIsEqual = false;
                break;
              }
            }
          }

          const stocksManagementIsSupported =
            (this.state.account && this.state.account.CMS === "prestashop") ||
            (this.state.account && this.state.account.CMS === "shopify") ||
            (this.state.account && this.state.account.CMS === "magento" && this.state.account.plug_version && (stockIsUsableForMagento || versionsIsEqual));


          return (
            <div className={classes.root}>
              <Hidden only={["xs", "sm"]}>
                <div className={classes.toparea}>
                  <div className={classes.title} style={{ paddingTop: "41px" }}>
                    {this.props.intl.messages["editprofile.title.know_more"]}
                  </div>
                  <div className={classes.text} style={{ marginTop: "18px" }}>
                    {this.props.intl.messages["editprofile.content.know_more"]}
                    <br />
                    {this.props.intl.messages["editprofile.content.know_more2"]}
                  </div>
                  <img
                    src={doubleCircle}
                    className={classes.doubleCircle}
                    alt='decoration'
                  />
                  <img
                    src={oval}
                    className={classes.oval}
                    alt='decoration'
                  />
                </div>
              </Hidden>
              <div className={classes.content}>
                <Grid container item xs={12} sm={12} lg={12} style={{ display:"flex", alignItems:"flex-start"}}  alignItems='center'>
                  {/* SHOP INFOS */}
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12} key={1} style={{ marginBottom: "23px" }}>
                    <div className={classes.title_myprofile} style={{ marginBottom: "20px", maxWidth: "588px" }}>
                      {this.props.intl.messages["editprofile.title.shop_infos"]}
                    </div>
                    <Paper className={classes.paper + " " + classes.shopInfos}>
                      <form
                        style={{
                          padding: 35,
                        }}
                        onSubmit={this.handleSubmit}>
                        <ItemPicker
                          titleKey={this.state.activity}
                          placeholder={this.props.intl.messages["field.activity"]}
                          listName='activityList'
                          onSelect={this.handleActivity}
                          startIcon='fal fa-briefcase'
                          selectedItem={this.state.activity}
                          errorMessage={null}
                        />
                        <ItemPicker
                          titleKey={this.state.business_sector}
                          placeholder={this.props.intl.messages["field.business_sector"]}
                          listName='businessSectorList'
                          onSelect={this.handleBusinessSector}
                          startIcon='fal fa-gift-card'
                          selectedItem={this.state.business_sector}
                          errorMessage={null}
                          style={{ marginBottom: 10 }}
                        />

                        <div className={classes.shopInfosNameContainer}>
                          <TextField
                            value={this.state.company_name}
                            type='companyName'
                            errorMessage={this.state.error_company_name && intl.messages["field.mandatory"]}
                            onChange={this.handleChange("company_name")}
                          />
                          <Hidden only={["xs"]}>
                            <div
                              className={classes.textinput}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "12px",
                              }}
                            >
                              {this.props.intl.messages["field.senderName.tooltip"]}
                            </div>
                          </Hidden>
                        </div>
                        <div className={classes.shopInfosNameContainer}>
                          <TextField
                            value={this.state.sender_name}
                            type="senderName"
                            errorMessage={
                              this.state.error_sender_name &&
                              intl.messages["field.mandatory"]
                            }
                            onChange={this.handleChange("sender_name")}
                          />
                          <Hidden only={["xs"]}>
                            <div
                              className={classes.textinput}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "12px",
                              }}
                            >
                              {
                                this.props.intl.messages[
                                  "field.companyName.tooltip"
                                ]
                              }
                            </div>
                          </Hidden>
                        </div>
                        <div className={classes.shopInfosNameContainer}>
                          <TextField value={this.state.replyToEmail} type='replyToEmail' errorMessage={this.state.errorReplyToEmail} onChange={this.handleChange("replyToEmail")} />
                          <Hidden only={["xs"]}>
                            <div
                              className={classes.textinput}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "12px",
                              }}
                            >
                              {this.props.intl.messages["field.replyToEmail.tooltip"]}
                            </div>
                          </Hidden>
                        </div>


                      </form>
                    </Paper><br />

                    <Paper className={classes.paper + " " + classes.shopInfos}>
                      <form
                        style={{
                          padding: 35,
                        }}
                        onSubmit={this.handleSubmit}
                      >
                        <div className={classes.textinput} style={{ position: "relative" }}>
                          {this.props.intl.messages["field.shop"]}
                        </div>
                        <FormControl component='fieldset' className={classes.textField}>

                          <RadioGroup name='gender' aria-label='gender_1' className={classes.group} value={this.state.unisex} onChange={this.handleChangeCheckboxGender}>
                            <Box
                              style={{
                                position: "relative",
                                display: "flex",
                                width: "100%",
                                flexDirection: "column",
                              }}
                              sx={{ display: "flex" }}>
                              <Radio label={this.props.intl.messages["field.unisex"]} value={true} style={{ color: palette.black, width: "100%" }} />
                              <span className={`${classes.subText} ${classes.subTextMargin}`}> {this.props.intl.messages["field.subtextunisex"]} </span>
                              <Radio label={this.props.intl.messages["field.gendered"]} value={false} style={{ color: palette.black }} />
                              <span className={`${classes.subText} ${classes.subTextMargin}`}> {this.props.intl.messages["field.subtextgendered"]} </span>

                            </Box>
                          </RadioGroup>
                        </FormControl>
                      </form>
                    </Paper>
                    <br />
                    {
                      stocksManagementIsSupported
                        ? (
                          <div>
                            <Paper className={classes.paper}>
                              <div style={{ padding: 35 }}>
                                <StockParameters
                                  key={3}
                                  stock={this.state.stockGestionForEmail}
                                  setStock={(event) =>
                                    this.setState({ stockGestionForEmail: event.target.value })}
                                />
                              </div>
                            </Paper>
                          </div>
                        )
                        : null
                    }
                    <br />
                    {
                      this.state.userIsKilibaAdmin
                        ? <EmailAuthentication className={classes.paper} />
                        : null
                    }
                  </Grid>

                  {/* USER INFOS */}
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12} style={{ marginBottom: "23px" }}>
                    <div className={classes.title_myprofile} style={{ marginBottom: "20px" }}>
                      {this.props.intl.messages["editprofile.title.myprofile"]}
                    </div>
                    <Paper className={classes.paper}>
                      <form
                        style={{
                          padding: 35,
                        }}
                        onSubmit={this.handleSubmit}
                      >
                        <TextField value={this.state.firstname} type='firstname' errorMessage={this.state.error_firstname && intl.messages["field.mandatory"]} onChange={this.handleChange("firstname")} />
                        <TextField value={this.state.lastname} type='lastname' style={{ marginTop: "15px" }} errorMessage={this.state.error_lastname && intl.messages["field.mandatory"]} onChange={this.handleChange("lastname")} />
                        <div className={`${classes.textinput} ${classes.idAccountContainer}`}>
                          {this.props.intl.messages["editProfile.content.id_account"]}
                        </div>

                        <KiTooltip 
                          type="light"
                          title={
                            <>
                              <Translation id="editprofile.warningId" /> 
                              <a className={classes.link} target="_blank" href="https://support.kiliba.com/hc/fr" rel="noreferrer">
                                <Translation id="editprofile.warningId2" />
                              </a>
                            </>
                          } 
                          children={<KiTextField value={this.state.id_account} type="password"/>}
                          placement={"bottom"}>
                        </KiTooltip>

                      </form >
                    </Paper >
                    {
                      this.state.filterStatusIsReady && this.state.shouldShowStatusesFilter ?
                        <div>
                          <div className={classes.title_myprofile} style={{ marginBottom: "20px", marginTop: "35px" }}>
                            {this.props.intl.messages["editprofile.title.turnover_infos"]}
                          </div>
                          <Paper className={classes.paper}>
                            <form
                              className={classes.orderForm}
                              onSubmit={this.handleSubmit}
                            >

                              <div className={classes.textinput} style={{ position: "relative" }}>
                                {this.props.intl.messages["editprofile.content.turnover_description"]}<br />
                                <span className={classes.subText}> {this.props.intl.messages["editprofile.content.turnover_subtext"]} </span>
                              </div>
                              {!!this.state.statusCheckedList && <StatusPicker setStatusList={this.setStatusList} statusCheckedList={this.state.statusCheckedList} statusList={this.state.defaultStatusList} />}

                            </form>
                          </Paper>
                        </div> : null
                    }
                    < br />

                   
                        <div>
                          <div className={classes.title_myprofile} style={{ marginBottom: "20px" }}>
                            {this.props.intl.messages["templateCustomization.analytics"]}
                          </div>
                          <Paper className={classes.paper}>
                            <form className={classes.orderForm} onSubmit={this.handleSubmit} >

                              <UTMParameters
                                key={0}
                                UTMIsEnabled={this.state.UTMIsEnabled}
                                setUTMIsEnabled={(value) => this.setState({ UTMIsEnabled: value })}
                              />
                            </form>
                          </Paper>
                        </div> 

                        {this.state.shouldShowCodePromosGroupConfig &&
                          <>
                            < br />
                            <div>
                              <div className={classes.title_myprofile} style={{ marginBottom: "20px" }}>
                                {this.props.intl.messages["templateCustomization.promoCode"]}
                              </div>
                              <Paper className={classes.paper}>
                                <PromoCodesGroupParameters setCustomerGroupPromoCode={this.setCustomerGroupPromoCode} />
                              </Paper>
                            </div>
                          </>
                        }
                      
                   
                  </Grid >

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} key={4}>
                    <div
                      style={{ display:"flex", justifyContent: "center", width: "100%" }}>
                      <FormButtonTO type={"primary"} classes={{ button: classes.formButton }} label={intl.messages["button.save"]} action={this.handleComplete} />
                    </div>
                  </Grid>

                  {
                    this.state.owner !== "Client Payant" &&
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} key={5}>
                      <div className={classes.texttt} style={{ marginTop: "21px",display:"flex", justifyContent: "center", width: "100%" }}>
                        {intl.messages["editProfile.delete.description"]}
                        <button
                          style={{
                            background: "none",
                            outline: "none",
                            border: "none",
                            color: palette.primaryBlack,
                            cursor: "pointer",
                            textDecoration: "underline"
                          }}
                          onClick={() => {
                            this.setState({ deleteAccount: true });
                          }}>
                          {intl.messages["editProfile.delete.button"]}
                        </button>
                      </div>
                    </Grid>
                  }

                </Grid >
              </div >
              <PopupTO
                open={this.state.deleteAccount}
                label={"popup.wording_delete_account"}
                title='popup.title_delete_account'
                styleWording={{ marginTop: "20px" }}
                handleClose={this.closeDeleteAccount}
                close
                buttons={[
                  {
                    onClick: this.deleteAccount,
                    label: this.props.intl.messages["button.delete_account"],
                    alternate: false,
                    style: { boxShadow: "none" },
                    loading: this.state.pending
                  }
                ]}
              />
            </div >
          );
        }
      }
    )
);
