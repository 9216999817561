const LABEL = "logo2";
const CATEGORY = "logo:subLogo";

export const logo2 = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {

    const { logoUrl, href, backgroundColor, alt, width = 200 } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-section padding-bottom="0" padding-top="0" data-gjs="kilibaBlock" background-color="${backgroundColor}" full-width="full-width">
        <mj-column>
          <mj-image padding-left="0" padding-right="0" href="{{href}}" alt="{{alt}}" align="center" src="{{logoUrl}}" width="${width}px"></mj-image>
        </mj-column>
      </mj-section>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        variables: {
          logoUrl,
          href,
          alt,
        },
        editableProperties: [
          { key: "logoUrl", type: "logo", label: "optionalMenu.category.myImages", group: "newsletter.rightMenu.propertyGroup.image" },
          { key: "width", type: "imageSize", label: "newsletter.rightMenu.property.imageSize" },
          { key: "href", type: "link", label: "newsletter.rightMenu.property.imageLink", group: "newsletter.rightMenu.propertyGroup.image" },
          { key: "alt", type: "string", label: "newsletter.rightMenu.property.alt", group: "newsletter.rightMenu.propertyGroup.image" },
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ]
      }
    };
  }
});