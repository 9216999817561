import { createTheme, } from "@material-ui/core/styles";
import "./index.css";
import "./styles/scss/App.css";

import { palette } from "./styles/palette.js";
import { lighten } from "@material-ui/core/styles/colorManipulator";

// this theme works with @material-ui/core up to V4, which is deprecated.
// prefer using a newer version of material ui instead for new components.
export const themeV4 = createTheme({
  props: {
    MuiModal: {
      disableEnforceFocus: true,
    },
  },
  palette: {
    primary: {
      main: palette.black,
    },
    secondary: {
      main: palette.white,
    },
  },
  components: {
    MuiColorPicker: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          border: "1px solid grey",
          padding: "8px",
          width: "34px",
        },
        thumb: {
          boxShadow: "none",
        },
        colorPreview: {
          borderRadius: "4px",
          border: "1px solid grey",
          width: "36px",
          height: "36px",
        },
      },
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        lineHeight: "initial",
        padding: "8px 0",
        fontSize: 12,
        fontFamily: "Poppins",
        display: "initial",
        color: palette.red,
        "&$error": {
          color: palette.alertError
        }
      }
    },
    MuiStepLabel: {
      label: {
        color: palette.primaryBlack,
      },
    },
    MuiDialog: {
      container: {
        backgroundColor: `${palette.black}A0`,
      },
    },
    MuiInputBase: {
      root: {
        lineHeight: "inherit",
        fontSize: 14,
      },
    },
    MuiFormControl: {
      root: {
        height: "100%",
      }
    },
    MuiFormGroup: {
      root: {
        alignItems: "flex-start",
      }
    },

    MuiTableRow: {
      root: {
        padding: "0 32px",
      }
    },
    MuiTextField: {
      root: {
        "&-noBorder .MuiInput-root::before": {
          display: "none",
        },
        "& .MuiFormHelperText-root.Mui-disabled": {
          opacity: 0.5,
        },
        "& .MuiInput-root": {
          display: "grid",
          "&.Mui-disabled": {
            "& .MuiInputAdornment-root": {
              opacity: 0.5,
            }
          }
        },
        "& .MuiInput-input": {
          padding: "0 20px",
          fontSize: 11,
          fontFamily: "Poppins",
          width: "auto",
          color: palette.black,
          "&.Mui-disabled": {
            color: "#ced2d7",
          },
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 30px #fceed2 inset;",
            borderRadius: "10px",
          },
        },
        "& .MuiInput-underline:before": {
          border: "none !important",
        },
        "& .MuiInputAdornment-root": {
          height: "100%",
          cursor: "auto",
          fontWeight: 500,
          "&:before": {
            fontSize: 14,
            margin: "auto",
            color: palette.lightgray
          },
          "&.fa-exclamation-circle": {
            "&::before": {
              color: palette.red
            }
          },
          "&.fa-eye, &.fa-eye-slash, &.fa-copy": {
            cursor: "pointer",
            pointerEvents: "all",
            "&:hover::before": {
              filter: "brightness(1.2)"
            }
          },
        },
        "& .MuiInputAdornment-positionStart": {
          marginRight: 0,
          maxHeight: "40px",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          backgroundColor: "#dbdbdb",
          paddingLeft: "10px",
        },
        "& .MuiInputAdornment-positionEnd": {
          marginLeft: 0
        },
        "& .MuiOutlinedInput-root": {
        },
        "& .MuiOutlinedInput-input": {
          textIndent: "initial",
        },
      },
    },
    MuiSelect: {
      root: {
        padding: "8px 16px",
        lineHeight: 1,
        height: 16,
        minHeight: "initial",
        fontSize: 14,
        paddingBottom: 12,
        paddingTop: 12,
      },
      selectMenu: {
        color: palette.black,
        minHeight: "initial",
        fontSize: 14,
      }
    },
    MuiMenu: {
      list: {
        padding: 0,
      }
    },
    MuiMenuItem: {
      root: {
        color: palette.black,
        fontSize: 14,
        "@media (min-width: 0px)": {
          minHeight: 40,
        }
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: palette.black,
        fontSize: 12,
        padding: 8,
        letterSpacing: 0.5,
      }
    },
    MuiStepper: {
      root: {
        padding: 0
      }
    },
    MuiStepIcon: {
      root: {
        color: palette.primaryBlack,
        "&$active": {
          color: palette.primaryYellow200
        },
        "&$completed": {
          color: palette.primaryBlack
        }
      }
    },
    MuiCheckbox: {
      root: {
        width: 16,
        height: 16,
        color: palette.primaryYellow200,
        "& .MuiSvgIcon-root": {
          fontSize: 20,
        }
      },
      colorSecondary: {
        "&.Mui-checked": {
          color: palette.primaryYellow200,
        },
        "&.Mui-disabled": {
          color: palette.primaryYellow200,
        },
      }
    },

    MuiIconButton: {
      colorSecondary: {
        "&:hover": {
          backgroundColor: `${lighten(palette.primaryYellow200, 0.93)}`,
        },
        "&.Mui-checked": {
          "&:hover": {
            backgroundColor: `${lighten(palette.primaryYellow200, 0.93)} !important`,
          },
        },
      }
    },
  }
});
