import { processSocialNetworkBlocks } from "./footer.tools";
const LABEL = "footer5";

export const footer5 = (logoUrl, blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, replyToEmail, isLight, { fontFamily, footerColor }, socialsNetworkTab) => ({
  label: LABEL, 
  block: () => {
    const mjml = `
    <mj-wrapper full-width="full-width" background-color="${footerColor}" data-gjs="kilibaBlock" padding-top="0" padding-bottom="0">
      <mj-section padding-bottom="5px" padding-top="5px">
        <mj-column padding-top="0px" padding-bottom="0px" vertical-align="middle" width="30%">
          <mj-image align="center" width="150px" padding-left="0px" padding-right="0px" padding-top="20px" padding-bottom="20px" css-class="footer5-image" src="${logoUrl}"></mj-image>
        </mj-column>
          
        <mj-column padding-left="5px" vertical-align="bottom" width="39%">
          <mj-text align="center" padding-left="0px" padding-right="0px" padding-top="0px" padding-bottom="10px" css-class="footer5-text1" font-family="${fontFamily}" font-size="12px">
            <a style="color:${ isLight ? "black" : "white"}; margin-right:5px" href="https://app.thatsowl.com/unsubscribe"> {{link1}} </a>
            <a style="color:${ isLight ? "black" : "white"}" href="mailto:${replyToEmail}?subject=Contact"> {{link2}} </a>
          </mj-text>     
        </mj-column>
      
        ${processSocialNetworkBlocks(socialsNetworkTab, 30, 15)}
 
      </mj-section>
    </mj-wrapper>
  
    `;

    const wordings = {
      link1: {
        fr: "Se désinscrire",
        en: "Unsubscribe",
        es: "Cancelar suscripcióne",
        de: "Abmelden",
        nl: "Afmelden",
        it: "Annullamento dell'iscrizione",
        pt: "Cancelar a subscrição",
      },
      link2: {
        fr: "Nous contacter",
        en: "Contact us",
        es: "Póngase en contacto con nosotros",
        de: "Kontaktieren Sie uns",
        nl: "Neem contact met ons op",
        it: "Contattateci",
        pt: "Contactar-nos",
      },
    };

    return {
      id: LABEL,
      label: LABEL,
      category: "footer:bandfooter",
      activate: true,
      content: mjml,
      attributes: {
        wordings,
      }
    };
  },
  mediaQueryStyle: `
    @media only screen and (min-width:480px) {
      .footer5-image{
        display: flex !important;
        padding-bottom:40px !important;
      }
      .footer5-text1{
        padding-top:65px !important;
      }
      .inlineSocialIcon {
        padding-bottom:25px !important;
      }
    }
  `,
  style: `
    .inlineSocialIcon {
      width:30px !important;
    }
  `,
});