import React, { Component } from "react";

import { injectIntl } from "react-intl";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export const IncludesTaxesInput = injectIntl(
  class IncludesTaxesInput extends Component {
    render() {
      const {
        intl,
        promoCodeTemplateWrapper,
        setPromoCodeTemplateWrapperProp,
      } = this.props;

      return (
        <Select
          value={promoCodeTemplateWrapper.promoCodeTemplate.includesTaxes}
          onChange={(event) =>
            setPromoCodeTemplateWrapperProp(
              "promoCodeTemplate.includesTaxes",
              Boolean(event.target.value)
            )
          }
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          variant="outlined"
        >
          <MenuItem value={true}>
            {
              intl.messages[
                "usecase.promoCodeTemplateEditor.value.taxes.includesTaxes"
              ]
            }
          </MenuItem>
          <MenuItem value={false}>
            {
              intl.messages[
                "usecase.promoCodeTemplateEditor.value.taxes.doesntIncludeTaxes"
              ]
            }
          </MenuItem>
        </Select>
      );
    }
  }
);
