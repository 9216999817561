import { palette } from "../../../styles/palette";

export const styles = {
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 15,
    fontFamily: "Poppins",
  },
  page: {
    fontSize: 12,
    marginRight: 16,
  },
  pageSelectors: {
    display: "grid",
    gridGap: 7,
    gridAutoFlow: "column",
  },
  pageSelector: {
    width: 25,
    height: 25,
    border: `1px solid ${palette.neutralBCGGrey}`,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&$selected": {
      backgroundColor: palette.primaryBlack,
      color: palette.primaryWhite
    },
    "&$disabled": {
      color: palette.neutralLightGrey,
      cursor: "initial",
    },
    "&:hover&:not(&$selected):not(&$disabled)": {
      backgroundColor: palette.neutralUltraLightGrey,
    }
  },
  pageSelectorKey: {
    fontSize: 12,
  },
  pageSelectorArrow: {
    color: palette.neutralDarkGrey,
    fontSize: 10,
  },
  selected: {},
  disabled: {},
};
