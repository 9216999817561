import { useState, useMemo, useCallback, useEffect } from "react";
import { Translation } from "../../common/Translation";
import { Dialog } from "../../common/Dialog";
import { TextField } from "../../common/Input/TextField";
import { CustomButton } from "../../common/Button/CustomButton";

import classes from "./AddLinkPopup.module.scss";

export const AddLinkPopup = ({ editor }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [component, setComponent] = useState();
  const [value, setValue] = useState("");

  const closePopup = () => {
    setTimeout(() => setIsOpen(false), 200);
  };

  const isValidLink = useMemo(() => {
    const regex = new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/);
    return value.match(regex);
  }, [value]);

  const isValidDomain = useMemo(() => {
    const regex = new RegExp(/^(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/);
    return value.match(regex);
  }, [value]);

  const onRteLink = useCallback((props) => {
    const selection = props.rte.doc.getSelection();
    
    const { anchorNode, focusNode } = selection || {};
    const parentAnchor = anchorNode?.parentNode;
    const parentFocus = focusNode?.parentNode;

    if (parentAnchor?.nodeName === "A" || parentFocus?.nodeName === "A") {
      const node = parentAnchor?.nodeName === "A" ? parentAnchor : parentFocus;

      editor?.DomComponents.getWrapper().onAll(component => {
        if (component.getId() === node.id) {
          setComponent(component);
          setValue(node.href);
          setIsOpen(true);
        }
      });

      return ;
    }
    
    const text = selection.toString();

    if (selection.rangeCount && text.length) {
      const range = selection.getRangeAt(0);

      const a = document.createElement("a");
      a.appendChild(document.createTextNode(text));
      a.href = "";
      a.alt = "";

      range.deleteContents();
      range.insertNode(a);
    }

    editor.getSelected().forEachChild(model => {
      if (model.is("mj-text")) {
        model.view.syncContent();
      }
    });

    setValue("");
    setIsOpen(true);
  }, [editor]);

  const onLinkClick = useCallback((props) => {
    setComponent(props.component.model);
    setValue(props.component.model.attributes.attributes.href || "");
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (editor) {
      editor.on("rteLink", onRteLink);
      editor.on("linkClick", onLinkClick);
      return () => {
        editor.off("rteLink", onRteLink);
        editor.off("linkClick", onLinkClick);
      };
    }
  }, [editor, onRteLink, onLinkClick]);

  const onComponentMount = useCallback(component => {
    if (component.is("link") && !component.attributes.attributes.href) {
      setComponent(component);
    }
  }, []);

  useEffect(() => {
    if (editor) {
      editor.on("component:mount", onComponentMount);
      return () => editor.off("component:mount", onComponentMount);
    }
  }, [editor, onComponentMount]);

  const insertLink = () => {
    setTimeout(() => {
      let url = value;
      if (!isValidLink) {
        url = `https://${value}`;
        setValue(url);
      }
      component.addAttributes({ href: url });
    }, 250);
    closePopup();
  };

  const deleteLink = () => {
    if (component.is("link")) {
      component.replaceWith(component.getInnerHTML());
    } else {
      component.addAttributes({ href: "" });
    }
    closePopup();
  };

  const onClose = () => {
    if (component && component.is("link") && !component.attributes.attributes.href) {
      deleteLink();
    }
    closePopup();
  };

  return (
    <Dialog
      isOpen={isOpen}
      close={onClose}
      style={{ padding: "40px 50px" }}
      PaperProps={{ style: { borderRadius: 10 } }}
    >
      <div className={classes.container}>
        <div className="text">
          <Translation id="addLinkPopup.title" />
        </div>
        <div className={classes.inputContainer}>
          <TextField
            value={value}
            onChange={event => setValue(event.target.value)}
            placeholder={"https://"}
          />
        </div>
        {component?.attributes?.attributes?.href ?
          <div className={classes.buttonContainer}>
            <CustomButton
              type={"secondary"}
              size="xs"
              onClick={deleteLink}
            >
              <Translation id="button.remove" />
            </CustomButton>
            <CustomButton
              type={"primary"}
              size="xs"
              disabled={!isValidLink && !isValidDomain}
              onClick={insertLink}
            >
              <Translation id="button.resetpass" />
            </CustomButton>
          </div>
          :
          <CustomButton
            type={"primary"}
            size="xs"
            disabled={!isValidLink && !isValidDomain}
            onClick={insertLink}
          >
            <Translation id="addLinkPopup.button.insert" />
          </CustomButton>
        }
      </div>
    </Dialog>
  );
};
