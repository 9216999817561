import { makeStyles, darken } from "@material-ui/core";

import { htmlCustomizerTooltipStyleValues } from "../htmlCustomizerTooltipStyleValues.js";

const styles = theme => ({
  button: {
    fontSize: 16,
    width: "2em",
    height: "2em",
    padding: 0,
    backgroundColor: htmlCustomizerTooltipStyleValues.tooltipBackgroundColor,
    border: "none",
    borderRadius: 4,
    color: "white",
    cursor: "pointer",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: darken(htmlCustomizerTooltipStyleValues.tooltipBackgroundColor, 0.15),
    },
  },
  paper: {
    backgroundColor: "transparent",
  }
});

const useStyles = makeStyles(styles);

export const HtmlCustomizerTooltipInputLink = ({ onClick }) => {
  
  const classes = useStyles();

  return (
    <>
      <button 
        className={classes.button}
        onMouseDown={(event) => event.preventDefault()}
        onClick={onClick}
      >
        <i className="fa-regular fa-link" />
      </button> 
    </>
  );
};

