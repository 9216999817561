import { useCallback } from "react";
import { confetti } from "@tsparticles/confetti";

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const useConfetti = () => {
  const showConfetti = useCallback(() => {
    const colors = [ "#0c0c0e", "#ffd481"];
    const confettiNumber = 2;

    for (let i = 0; i < confettiNumber; i++) {
      setTimeout(() => {
        for (let j = 0; j < confettiNumber ; j++) {
          confetti({
            colors: colors[i],
            shapes: ["circle"],
            shapeOptions: {
              polygon: {
                sides: 6
              }
            },
            scalar: 1.5,
            position: {
              x: randomIntFromInterval(20, 80),
              y: randomIntFromInterval(40, 60),
            }
          });
        }
      }, i * 600);
    }
  }, []);

  return showConfetti;
};