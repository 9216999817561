const LABEL = "social4";

export const social4 = (blockRsInstagram, blockRsFacebook, blockRsTwitter, blockRsYoutube, blockRsDiscord, blockRsLinkedin, blockRsPinterest, blockRsTiktok, footerColor, isLight, fontFamily ) => ({
  label: LABEL,
  block: () => {

    const mjml = `
    <mj-wrapper full-width="full-width"  background-color="${footerColor}" data-gjs="kilibaBlock" padding-bottom="5px" padding-top="5px" >

        <mj-section padding-top="20px" padding-bottom="5px">
          <mj-column padding-left="0px" padding-right="0px" padding-top="0px" padding-bottom="0px">
              <mj-text padding-left="0px" padding-right="0px" color=${ isLight ? "black" : "white"} padding-top="0px" padding-bottom="0px" font-family="${fontFamily}" font-size="14px" align="center">
                {{title1}}
              </mj-text>
          </mj-column>
        </mj-section>

        <mj-section css-class="social-block" padding-top="10px" padding-bottom="10px">
          <mj-column>
            <mj-social icon-size="25px" mode="horizontal">
              ${blockRsInstagram}
              ${blockRsFacebook}
              ${blockRsTwitter}
              ${blockRsYoutube}
              ${blockRsDiscord}
              ${blockRsLinkedin}
              ${blockRsPinterest}
              ${blockRsTiktok}
            </mj-social>
          </mj-column>
        </mj-section>
      </mj-wrapper>
    `;

    const wordings = {
      title1: {
        fr: "Retrouvez-nous sur vos réseaux préférés",
        en: "Follow us on your favourite networks",
        es: "Síguenos en tus redes favoritas",
        de: "Finden Sie uns auf Ihren bevorzugten Netzwerken",
        nl: "Volg ons op je favoriete netwerken",
        it: "Seguiteci sulle vostre reti preferite",
        pt: "Siga-nos nas suas redes preferidas",
      },
    };

    return {
      id: LABEL,
      label: LABEL,
      category: "social:social",
      activate: true,
      content: mjml,
      attributes: {
        wordings,
      }
    };
  },
  style: `
    .inlineSocialIcon {
      width:30px !important;
      margin-left:10px !important;
    }
  `,
});