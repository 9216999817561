import { useState, useCallback, useEffect } from "react";

export const useNewsletterBlockActions = () => {
  const [forceRefreshBlockActionsPosition, setForceRefreshBlockActionsPosition] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedCategoryInfo, setDraggedCategoryInfo] = useState(null);

  const refreshBlockActionsPosition = useCallback(() => {
    setForceRefreshBlockActionsPosition(prev => prev + 1);
  }, []);

  const onCategoryDragStart = useCallback(event => {
    event.preventDefault();

    const category = event.target.id.replace("category.", "");
    const draggedItem = event.target.cloneNode(true);
    draggedItem.id += "-dragging";
    draggedItem.style.background = "#F9F9F9";
    draggedItem.style["z-index"] = 2;

    const dx = event.screenX - event.target.getBoundingClientRect().x;
    const dy = event.screenY - event.target.getBoundingClientRect().y;

    setDraggedCategoryInfo({
      elem: draggedItem,
      category,
      dx, dy,
    });
    
    draggedItem.style.position = "absolute";
    document.body.appendChild(draggedItem);
  }, []);

  const onDrag = useCallback(event => {
    if (draggedCategoryInfo) {
      draggedCategoryInfo.elem.style.left = `${event.screenX - draggedCategoryInfo.dx}px`;
      draggedCategoryInfo.elem.style.top = `${event.screenY - draggedCategoryInfo.dy}px`;
    }
  }, [draggedCategoryInfo]);

  useEffect(() => {
    document.addEventListener("mousemove", onDrag);
    return () => {
      document.removeEventListener("mousemove", onDrag);
    };
  }, [onDrag]);

  return {
    forceRefreshBlockActionsPosition,
    refreshBlockActionsPosition,
    isDragging,
    setIsDragging,
    onCategoryDragStart,
    draggedCategoryInfo,
    setDraggedCategoryInfo,
  };
};