import { useState, useEffect, useContext, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import mjml2html from "mjml-browser";
import moment from "moment-timezone";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

import { Radio } from "../common/Input/Radio/Radio";
import { RadioGroup } from "../common/RadioGroup";
import { Translation } from "../common/Translation";
import { KiDatePicker } from "../common/KiDatePicker/KiDatePicker";
import { CustomButton } from "../common/Button/CustomButton";
import { useAuth } from "../../hooks/useAuth";
import { CircularProgress } from "@material-ui/core";
import { AccountContext } from "../../contexts/context";
import { ConfirmPlanNewsletterPopup } from "./ConfirmPlanNewsletterPopup";

import classes from "./NewsletterChooseDate.module.scss";
import { TemplateEditorSendMailPopup } from "../TemplateEditor/TemplateEditorSendMailPopup";


const isToday = (someDate) => {
  const today = new Date();
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear();
};

export const getMinNewsletterDate = (account, statistics, dateLastSync) => {
  let result = null;

  const setDate = newDate => {
    if (!result) {
      result = newDate;
    } else if (newDate.getTime() > result.getTime()) {
      result = newDate;
    }
  };

  for (const template of account.mjmlTemplates) {
    if (template.dateSent) {
      const statistic = statistics.find(elem => elem.newsletter_id === template.id);

      if (statistic || ((new Date(template.dateSent).getTime() < Date.now() || isToday(new Date(template.dateSent))) && (!dateLastSync || new Date(template.dateSent).getTime() > new Date(dateLastSync).getTime()))) {
        setDate(new Date(template.dateSent));
      }
    }
  }

  if (result) {
    result.setDate(result.getDate() + 7);
  }

  return result;
};

export const getNewsletterInSameRange = (account, date, currentTemplateId) => {
  const minDate = new Date(date.getTime());
  minDate.setDate(minDate.getDate() - 7);

  const maxDate = new Date(date.getTime());
  maxDate.setDate(maxDate.getDate() + 7);

  const result = [];

  for (const template of account.mjmlTemplates) {
    if (template.dateSent && template.id !== currentTemplateId) {
      const _date = new Date(template.dateSent);
      if (new Date(template.dateSent).getTime() > Date.now() && !isToday(new Date(template.dateSent)) && _date.getTime() > minDate.getTime() && _date.getTime() < maxDate.getTime()) {
        result.push(template);
      }
    }
  }

  return result;
};

const CustomDay = (props) => {
  const { plannifiedNewsletterDates = [], day, outsideCurrentMonth, ...other } = props;

  const dateRendering = day.toDate();

  const isSelected =
    !props.outsideCurrentMonth && !!plannifiedNewsletterDates.find(date => date.getDate() === dateRendering.getDate() && date.getMonth() === dateRendering.getMonth() && date.getFullYear() === dateRendering.getFullYear());

  return (
    <div className={isSelected ? classes.customDaySelected : null}>
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </div>
  );
};

export const NewsletterChooseDate = ({ templateId, template, isLoading, wordings, goNext, setDateForSummary, goSaveDraft, language, variables, savedDate, products }) => {

  const intl = useIntl();
  const auth = useAuth();
  const history = useHistory();
  const { account } = useContext(AccountContext);

  const [templatesConflict, setTemplatesConflict] = useState(null);
  const [minDate, setMinDate] = useState(undefined);
  const [radioValue, setRadioValue] = useState("tomorrow");
  const [nbrTargetedCustomer, setNbrTargetedCustomer] = useState("");
  const [sendMailIsOpened, setSendMailIsOpened] = useState(false);
  const [html, setHtml] = useState("");
  const [plannifiedNewsletterDates, setPlannifiedNewsletterDates] = useState([]);

  const [isNbrTargetedCustomerLoading, setIsNbrTargetedCustomerLoading] = useState(false);
  const [date, setDate] = useState(() => {
    
    const currentTemplate = account.mjmlTemplates.find(elem => elem.id === templateId);
    if (currentTemplate.dateSent) {
      return new Date(currentTemplate.dateSent);
    }
    
    const defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() + 1);
    return defaultDate;
  });

  useEffect(() => {
    const result = [];
      
    for (const template of account.mjmlTemplates) {
      if (template.dateSent && template.id !== templateId) {
        const _date = new Date(template.dateSent);
        if (new Date(template.dateSent).getTime() > Date.now() && !isToday(new Date(template.dateSent))) {
          result.push(_date);
        }
      }
    }

    setPlannifiedNewsletterDates(result);
    
  }, [account, templateId]);


  useEffect(() => {
    let value;
    switch (radioValue) {
      case "tomorrow": {
        const defaultDate = new Date();
        defaultDate.setDate(defaultDate.getDate() + 1);
        value = defaultDate;
        break;
      }
      case "date":
        value = date;
        break;
    }
    setDateForSummary(value);
  }, [radioValue, date, setDateForSummary]);

  const onNext = () => {
    const templateInSameRange = getNewsletterInSameRange(account, savedDate, templateId);
    if (templateInSameRange.length) {
      setTemplatesConflict(templateInSameRange);
    } else {
      goNext();
    }
  };

  useEffect(() => {
    if (!sessionStorage.getItem(`nl_template_nbr_customer_loaded_${templateId}`)) {
      setIsNbrTargetedCustomerLoading(true);
    }

    auth.fetch(`/data/getNbrNewsletterTargetedCustomer?templateId=${templateId}`)
      .then((response) => {
        setNbrTargetedCustomer(response);
        if (!sessionStorage.getItem(`nl_template_nbr_customer_loaded_${templateId}`)) {
          setTimeout(() => {
            setIsNbrTargetedCustomerLoading(false);
            sessionStorage.setItem(`nl_template_nbr_customer_loaded_${templateId}`, "true");
          }, 3000);
        } else {
          setIsNbrTargetedCustomerLoading(false);
        }
      });
  }, [auth, templateId]);

  useEffect(() => {
    if (account && minDate === undefined) {
      auth.fetch("/data/getNewsletterStatistics").then(({ statistics, date_last_sync }) => {
        const _minDate = getMinNewsletterDate(account, statistics, date_last_sync);
        setMinDate(_minDate);

        const currentTemplate = account.mjmlTemplates.find(elem => elem.id === templateId);
        if (currentTemplate.dateSent && (!_minDate || new Date(currentTemplate.dateSent).getTime() > _minDate.getTime())) {
          setRadioValue("date");
          setDate(new Date(currentTemplate.dateSent));
        } else if (_minDate && _minDate.getTime() > Date.now() && !isToday(_minDate)) {
          setRadioValue("date");
          setDate(_minDate);
        }
      });
    }
  }, [auth, account, minDate, templateId]);

  const onConfirm = async () => {
    for (const template of templatesConflict) {
      await auth.fetch("/api/account_management/saveMjmlTemplate", {
        method: "POST",
        body: JSON.stringify({
          templateId: template.id,
          dateSent: null
        })
      });
    }

    goNext();
  };

  useEffect(() => {
    if (template) {
      let html = mjml2html(template, { validationLevel: "skip" }).html;
      Object.entries(wordings).forEach(([wordingId, wordings]) => {
        if (wordings) {
          html = html.replaceAll(`{{${wordingId}}}`, wordings[intl.locale]);
        }
      });
      html = html.replaceAll(/href="[^"]*"/g, "href=\"\" onclick=\"return false;\"");

      Object.entries(variables).forEach(([key, variable]) => {
        if (wordings) {
          html = html.replaceAll(`{{${key}}}`, variable[language]);
        }
      });

      setHtml(html);
    } else {
      setHtml("");
    }
  }, [template, intl, variables, language, wordings]);

  const tomorrowDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  }, []);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.dateFormContainer}>
        <h1><Translation id="newsletter.date.title" /></h1>
        {!products?.length ?
          <div>
            <h4><Translation id="newsletter.date.noproducts" /></h4>
          </div> :
          <>
            <div className={classes.inputContainer}>
              <div className="text"><Translation id="newsletter.date.form.label" /></div>
              {minDate && <div className={`info ${classes.subtitle}`}><Translation id="newsletter.date.subtitle" variables={{ date: moment(minDate).format("DD/MM") }} /></div>}
              <div className={classes.radioGroup}>
                <RadioGroup value={radioValue} onChange={event => setRadioValue(event.target.value)}>
                  <Radio legacy
                    value={"tomorrow"}
                    label={intl.messages["checkbox.asap"]}
                    disabled={minDate && minDate.getTime() > tomorrowDate.getTime()}
                  />
                  <div className={classes.dateInput}>
                    <Radio legacy
                      value={"date"}
                      label={intl.messages["checkbox.date"]}
                    />
                    {minDate !== undefined && <KiDatePicker
                      value={date}
                      onChange={value => {
                        setDate(value);
                        setRadioValue("date");
                      }}
                      disablePast
                      disableToday
                      minDate={minDate}
                      slots={{
                        day: CustomDay,
                      }}
                      slotProps={{
                        day: {
                          plannifiedNewsletterDates,
                        },
                      }}
                    />}
                  </div>
                </RadioGroup>
              </div>
            </div>

            {!isNbrTargetedCustomerLoading ?
              (
                <div>
                  <Translation id="newsletter.date.sendImpact.text1" />
                  <span className={classes.boldtext}><FormattedMessage id="newsletter.date.sendImpact.text2" values={{ nbrCustomer: nbrTargetedCustomer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} /></span>
                  <div className={classes.rawWrapper}>
                    <div><Translation id="newsletter.date.sendImpact.text3" /></div>
                  </div>
                </div>
              ) :
              (
                <div className={classes.nbrCustomerAnalyzeWrapper}>
                  <div>
                    <CircularProgress color="tertiary" className={classes.loading} size="20px" />
                  </div>
                  <div className={classes.rawWrapper}>
                    <div><Translation id="newsletter.date.sendImpact.loading.text1" /></div>
                    <div><Translation id="newsletter.date.sendImpact.loading.text2" /></div>
                  </div>
                </div>
              )
            }
          </>
        }

        <div className={classes.inputFooter}>
          <div className={classes.footerBtn}>
            <CustomButton
              type="secondary"
              size="lg"
              onClick={() => history.push(`/editor/${templateId}`)}
              style={{ marginRight: 20 }}
            >
              <Translation id="dialog.back" />
            </CustomButton>
            <CustomButton
              size="lg"
              loading={isLoading}
              disabled={products.length && isNbrTargetedCustomerLoading}
              onClick={!products?.length ? () => history.push(`/editor/${templateId}?open=product`) : onNext}
            >
              {!products?.length ? <Translation id="newsletter.generatedTextProductMenu.addproduct" /> : <Translation id="button.schedule" />}
            </CustomButton>
          </div>
         
          <div className={classes.footerText}>
            <span onClick={goSaveDraft} className="link"><Translation id="newsletter.date.saveLater" /></span>
          </div>

        </div>

      </div>
      <div className={classes.previewContainer}>
        <CustomButton
          type="tertiary"
          size="sm"
          onClick={() => setSendMailIsOpened(true)}
          style={{ marginRight: 20 }}
        >
          <Translation id="templateEditorToolbar.emailText" />
        </CustomButton>
        <iframe
          className={classes.previewIframe}
          onLoad={event => event.target.contentDocument.body.style["overscroll-behavior"] = "contain"}
          srcDoc={html}
          title="previewTemplateEmail"
        ></iframe>
      </div>

      <TemplateEditorSendMailPopup
        template={template}
        wordings={wordings}
        isOpen={sendMailIsOpened}
        language={language}
        close={() => setSendMailIsOpened(false)}
        variables={variables}
      />

      <ConfirmPlanNewsletterPopup
        isOpen={!!templatesConflict}
        close={() => setTemplatesConflict(null)}
        onConfirm={onConfirm}
      />
    </div>
  );
};