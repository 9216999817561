import { Translation } from "../../../common/Translation";
import { TemplateCustomizationContent } from "../../../TemplateCustomization/TemplateCustomizationContent.jsx";

import { styles } from "./TemplateCustomization.styles";
import { makeStyles } from "@material-ui/core";
import { CustomButton } from "../../../common/Button/CustomButton";
import { debounce } from "lodash";
import { useAuth } from "../../../../hooks/useAuth";

import underline from "../../../../public/underline.png";

import arrow from "../../../../public/arrow2.png";
import { reloadAccountContext } from "../../../../contexts/context";

const useStyles = makeStyles(styles);

export const TemplateCustomization = ({ onSubmit }) => {
  const auth = useAuth();

  return (
    <TemplateCustomizationContent
      descriptionBlock={<DescriptionBlock onSubmit={onSubmit} />}
      displayedParameters={["logo", "color", "text"]}
      onPageLoad={() => auth.sendEventToAmplitude("onboarding_template_perso.see_page")}
      onColorChange={debounce(() => auth.sendEventToAmplitude("onboarding_template_perso.change_value_color"), 100)}
      onLogoChange={() => auth.sendEventToAmplitude("onboarding_template_perso.change_value_logo")}
      onTextChange={() => auth.sendEventToAmplitude("onboarding_template_perso.change_value_text")}
    />
  );
};

const DescriptionBlock = ({ onSubmit }) => {
  const classes = useStyles();
  const auth = useAuth();

  return (
    <>
      <div className={classes.title}>
        <p className={"display2"}>
          <Translation id="onBoarding.templateCustomization.title" />
        </p>
      </div>
      <h4 className={classes.subtitle}>
        <Translation id={"onBoarding.templateCustomization.subtitle"} />
        <img src={arrow} alt="" className={classes.arrowImg} />
      </h4>
      <img alt="" src={underline} className={classes.underlineImg} />

      <div className={classes.buttonContainer}>
        <CustomButton
          onClick={async () => {
            auth.sendEventToAmplitude("onboarding_template_perso.click_move_on");
            onSubmit();
            await auth.fetch("/api/account_management/updateAccount", { method: "POST", body: JSON.stringify({ step_register: 4 }) });
            await reloadAccountContext();
          }}
        >
          <Translation id="onBoarding.templateCustomization.button" />
        </CustomButton>
      </div>
    </>
  );
};
