import { useIntl } from "react-intl";

import { TextField } from "../../../../common/Input/TextField";
import { CustomButton } from "../../../../common/Button/CustomButton";
import { useAuth } from "../../../../../hooks/useAuth";
import { EventSystem } from "../../../../../eventsystem/EventSystem";

import arrow from "../../../../../public/presta-arrow.png";
import classes from "./MagentoDialog.module.scss";


export const MagentoForm = ({ onSubmit = () => { }, loading = false }) => {
  const intl = useIntl();
  const auth = useAuth();

  const onCopyToClipboard = () => {
    auth.sendEventToAmplitude("magento_connection.click_copy_id");
    EventSystem.newNotification("notification.action", intl.messages["editProfile.notif.id_account"]);
  };

  return (
    
      <div className={classes.formContainer}>
        <div className={classes.steps}>
          <div className={classes.step}>
            <div className={`${classes.circle} textBold`}>1</div>
            <span className="text">{intl.messages["onBoarding.chooseCms.magento.step1"]}</span>
          </div>
          <div className={classes.step}>
            <div className={`${classes.circle} textBold`}>2</div>
            <span className="text">{intl.messages["onBoarding.chooseCms.magento.step2"]}</span>
          </div>
          <div className={classes.step3}>
            <div className={classes.step}>
              <div className={`${classes.circle} textBold`}>3</div>
              <span className="text">{intl.messages["onBoarding.chooseCms.magento.step3"]}</span>
            </div>
            <div className={classes.copy}>
              <TextField
                value={auth.getAccountId()}
                type="copy"
                onCopyClick={onCopyToClipboard}
                onChange={() => { }}
              />
            </div>
            <img alt="arrow" src={arrow} className={classes.arrowImg} />
          </div>
        </div>

        <h4>{intl.messages["onBoarding.chooseCms.magento.text"]}</h4>

        <div className={classes.buttonContainer}>
          <CustomButton
            onClick={() => {
              auth.sendEventToAmplitude("magento_connection.click_start");
              onSubmit("magento");
            }}
            loading={loading}
          >
            {intl.messages["button.endform"]}
          </CustomButton>
        </div>

      </div>
  );
};
