import { lighten } from "@material-ui/core";
import { palette } from "../../../styles/palette";

export const styles = {
  button: {
    border: 0,
    borderRadius: 15,
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 500,
    cursor: "pointer",
    width: "fit-content",
    padding: "0 40px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  primary: {
    backgroundColor: palette.primaryYellow200,
    color: palette.primaryBlack,
    "&:hover": {
      backgroundColor: palette.primaryYellow400,
    },
  },
  secondary: {
    backgroundColor: palette.primaryBlack,
    color: palette.white,
    "&:hover": {
      backgroundColor: palette.neutralDarkGrey,
    },
  },
  tertiary: {
    backgroundColor: palette.neutralLightGrey,
    color: palette.primaryBlack,
    "&:hover": {
      backgroundColor: palette.neutralLightGreyHover,
    },
  },
  lg: {
    padding: "0px 40px",
    minWidth:"135px",
    minHeight:"55px",
  },
  md: {
    borderRadius: 10,
    padding: "12px 30px",
  },
  sm: {
    borderRadius: 10,
    padding: "12px 20px",
  },
  xs: {
    borderRadius: 7,
    padding: "8px 15px",
  },
  xxs: {
    borderRadius: 5,
    padding: "5px 9px",
    fontSize: 13,
  },
  disabled: {
    pointerEvents: "none",
  },
  primaryDisabled: {
    backgroundColor: palette.neutralLightGrey,
    color: palette.neutralDarkGrey,
  },
  secondaryDisabled: {
    backgroundColor: lighten(palette.primaryBlack, 0.5),
  },
  tertiaryDisabled: {
    backgroundColor: palette.neutralLightGrey,
    color: lighten(palette.black, 0.5),
  },

};
