import React, { Fragment, Component } from "react";
import { convertArrayToCSV } from "convert-array-to-csv";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { withAuth } from "../withAuth";
import Fade from "@material-ui/core/Fade";
import { ExceedingPrice } from "../common/ExceedingPrice/exceedingPrice.js";

import { EventSystem } from "../../eventsystem/EventSystem.js";
import { KiTable } from "../common/KiTable/KiTable.js";
import { getFormattedNumber } from "../../tools/getFormattedNumber.js";
import { styles } from "./style.js";


import { FormButtonTO } from "../FormButtonTO.jsx";
import { Translation } from "../common/Translation";
import { FirstSteps } from "../FirstSteps/FirstSteps";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Dialog } from "../common/Dialog";
import { CustomButton } from "../common/Button/CustomButton";
import { KiDropdown } from "../common/Input/KiDropdown";
import { KiDatePicker } from "../common/KiDatePicker/KiDatePicker";
import { StatsCarousel } from "./StatsCarousel/StatsCarousel";
import { KiUsecasePicker } from "../KiUsecasePicker/KiUsecasePicker";

import { AccountContextBehaviorSubject$ } from "../../contexts/context";

import arrow from "../../public/arrow.png";


export const Home = withAuth(
  withStyles(styles)(
    injectIntl(
      class Home extends Component {
        constructor(props) {
          super(props);
          this.goToUsecaseList = this.goToUsecaseList.bind(this);
          this.goToTemplateCustomization = this.goToTemplateCustomization.bind(this);
          this.changeDayBegin = this.changeDayBegin.bind(this);
          this.changeDayEnd = this.changeDayEnd.bind(this);
          this.getStatisticsData = this.getStatisticsData.bind(this);
          this.numberWithSpaces = this.numberWithSpaces.bind(this);
          this.handleDatePeriodSelection = this.handleDatePeriodSelection.bind(this);
          this.downloadStatistics = this.downloadStatistics.bind(this);
          this.setNoDataFront = this.setNoDataFront.bind(this);
          this.closeShopifyImportLogoModal = this.closeShopifyImportLogoModal.bind(this);
          this.redirectToCustomMail = this.redirectToCustomMail.bind(this);
          this.openZendesk = this.openZendesk.bind(this);
          this.changeUsecase = this.changeUsecase.bind(this);
          this.savePagination = this.savePagination.bind(this);

          const now = new Date();
          const init_day_end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

          function getColumnName(key) {
            return props.intl.messages[`statistics.table.head.${key}`];
          }

          this.state = {
            firstname: "",
            dateAccountCreation: new Date(),
            num_scenarios: 0,
            day_begin: new Date(2019, 1, 1),
            day_end: init_day_end,
            statisticsRows: undefined,
            columns: [
              {
                key: "usecase",
                name: getColumnName("usecase"),
                size: "1.5fr"
              },
              {
                key: "ca",
                name: getColumnName("ca"),
                sortProperty: "rawValue"
              },
              {
                key: "sentCount",
                name: getColumnName("sentCount"),
                sortProperty: "rawValue"
              },
              {
                key: "openedPercentage",
                name: getColumnName("openedPercentage"),
                sortProperty: "rawValue"
              },
              {
                key: "clickedPercentage",
                name: getColumnName("clickedPercentage"),
                sortProperty: "rawValue"
              },
              {
                key: "ordersCount",
                name: getColumnName("ordersCount"),
                sortProperty: "rawValue"
              },
              {
                key: "unsubscribedPercentage",
                name: getColumnName("unsubscribedPercentage"),
                sortProperty: "rawValue"
              },
            ],
            send: "-",
            open: "-",
            click: "-",
            unsubscribe: "-",
            nb_order: "-",
            conversion_rate: "-",
            turnover: "-",
            avg_order: "-",
            total_num_usecases: "-",
            blacklist_status: "TO_DO",
            customMailDone: false,
            datePeriod: "thisMonth",
            noCms: true,
            cmsModalIsOpen: false,
            shouldShowShopifySubscription: false,
            subscriptionIsLoading: true,
            subscriptionCheck: null,
            subscriptionFailed: false,
            shouldShowShopifyImportLogo: false,
            logoSuggestionIsLoading: true,
            statusCheckedList: [],
            statusList: [],
            turnoversByUsecases: {},
            logoSuggestion: null,
            account: AccountContextBehaviorSubject$.getValue().account,
            config: AccountContextBehaviorSubject$.getValue().config,
            loading: true,
            pagination: {
              key: 0,
              pageKey: 0,
              rowsPerPage: 10
            }
          };
        }

        downloadStatistics() {
          const statisticsRowsCopy = JSON.parse(JSON.stringify(this.state.statisticsRows));

          if (statisticsRowsCopy.length !== 0) {
            const formattedStatisticsRowsCopy = statisticsRowsCopy.map(statisticsRowsCopyItem => {
              return {
                usecase: statisticsRowsCopyItem.values[this.state.columns[0].key].displayedValue,
                ca: statisticsRowsCopyItem.values[this.state.columns[1].key].rawValue,
                sentCount: statisticsRowsCopyItem.values[this.state.columns[2].key].rawValue,
                openedPercentage: statisticsRowsCopyItem.values[this.state.columns[3].key].rawValue,
                clickedCount: statisticsRowsCopyItem.values[this.state.columns[4].key].rawValue,
                ordersCount: statisticsRowsCopyItem.values[this.state.columns[5].key].rawValue,
                unsubscribedCount: statisticsRowsCopyItem.values[this.state.columns[6].key].rawValue,
              };
            });
            const header = this.state.columns.map(column => column.name);
            const csv = "data:text/csv;charset=utf-8," + convertArrayToCSV(formattedStatisticsRowsCopy, { header, separator: ";" });
            const downloadData = encodeURI(csv.replace(/&|#/g, ""));
            const link = document.createElement("a");
            link.setAttribute("href", downloadData);
            link.setAttribute("download", "orders_export.csv");
            link.click();
          }
        }

        changeUsecase(usecaseKey) {
          const newUsecases = JSON.parse(JSON.stringify(this.state.usecases));

          if (usecaseKey === "all") {
            newUsecases.forEach(usecase => usecase.checked = this.checkedUsecases.length === 0);
          } else {
            const usecaseToCheck = newUsecases.find(usecase => usecase.name === usecaseKey);
            usecaseToCheck.checked = !usecaseToCheck.checked;
          }

          this.setState({
            usecases: newUsecases,
            statisticsRows: undefined
          }, () => {
            this.getStatisticsData();
            this.getOrderGeneralStatistics();
          });
        }

        savePagination(pagination) {
          if (JSON.stringify(pagination) !== JSON.stringify(this.state.pagination)) {
            this.setState({ pagination });
          }
        };

        get checkedUsecases() {
          return this.state.usecases && Object.keys(this.state.usecases)
            .filter(usecaseKey => this.state.usecases[usecaseKey].checked)
            .map(usecaseKey => this.state.usecases[usecaseKey].name);
        }

        goToUsecaseList(e) {
          e.preventDefault();
          EventSystem.publish("url_switch", "/usecase");
        }
        goToTemplateCustomization(e) {
          e.preventDefault();
          EventSystem.publish("url_switch", "/template_customization");
        }

        setNoDataFront() {
          this.setState({
            send: "-",
            open: "-",
            click: "-",
            unsubscribe: "-",
            turnover: "-",
            nb_order: "-",
            conversion_rate: "-",
            avg_order: "-",
          });
        }

        /*
        * Statistics
        */
        async getStatisticsData() {
          const { intl, auth } = this.props;
          const { account, noCms } = this.state;
          
          if (this.checkedUsecases?.length === 0 || noCms === true) {
            return this.setNoDataFront();
          }
          
          try {
            const selectedUsecases = JSON.parse(JSON.stringify(this.checkedUsecases));
            if (selectedUsecases.includes("winterSales")) {
              selectedUsecases.push("solde");
            }

            const result = await auth.fetch(`/api/data/getUsecasesStatistics?accountId=${account._id}&usecases=${selectedUsecases}&startDate=${this.state.day_begin}&endDate=${this.state.day_end}`, {
              method: "GET",
            });

            const statsByUsecase = await this.getFilteredTurnoversGroupByUsecase();

            this.setState({
              statisticsRows: result.statistics
                .map(statistic => {
                  const usecaseStat = statsByUsecase
                    .find(stat => stat.usecase === statistic.usecase);

                  return {
                    values: {
                      [this.state.columns[0].key]: { rawValue: null, displayedValue: intl.messages[`usecase.${statistic.usecase}`], },
                      [this.state.columns[1].key]: { rawValue: usecaseStat?.turnover || 0, displayedValue: usecaseStat?.turnover || 0 },
                      [this.state.columns[2].key]: { rawValue: statistic.send, displayedValue: getFormattedNumber(statistic.send, "", intl.locale), },
                      [this.state.columns[3].key]: { rawValue: statistic.open, displayedValue: getFormattedNumber(statistic.open, "%", intl.locale), },
                      [this.state.columns[4].key]: { rawValue: statistic.click, displayedValue: getFormattedNumber(statistic.click, "%", intl.locale), },
                      [this.state.columns[5].key]: { rawValue: usecaseStat?.nb_order || 0, displayedValue: getFormattedNumber(usecaseStat?.nb_order || 0, "", intl.locale), },
                      [this.state.columns[6].key]: { rawValue: statistic.unsubscribe, displayedValue: getFormattedNumber(statistic.unsubscribe, "%", intl.locale), },
                    }
                  };
                })
            });

            const generalStatistics = await auth.fetch(`/api/data/getGeneralStatistics?accountId=${this.state.account._id}&usecases=${selectedUsecases}&startDate=${this.state.day_begin}&endDate=${this.state.day_end}`, {
              method: "GET",
            });

            this.setState({
              send: getFormattedNumber(generalStatistics.send, "", intl.locale),
              open: getFormattedNumber(generalStatistics.open, "%", intl.locale),
              click: getFormattedNumber(generalStatistics.click, "%", intl.locale),
              unsubscribe: getFormattedNumber(generalStatistics.unsubscribe, "%", intl.locale),
              conversion_rate: getFormattedNumber(generalStatistics.nb_conversion, "%", intl.locale)
            });
          } catch (error) {
            this.setState({
              send: "-",
              open: "-",
              click: "-",
              unsubscribe: "-",
              nb_order: "-",
              conversion_rate: "-",
              products: [],
            });
          }
        }

        async getOrderGeneralStatistics() {
          const { auth, intl } = this.props;

          if (!this.checkedUsecases) return;

          const selectedUsecases = JSON.parse(JSON.stringify(this.checkedUsecases));
          if (selectedUsecases.includes("winterSales")) {
            selectedUsecases.push("solde");
          }

          const { turnover, avgOrder, nbOrder } = await auth.fetch(`/api/data/getOrderGeneralStatistics?accountId=${this.state.account._id}&usecases=${selectedUsecases}&startDate=${this.state.day_begin}&endDate=${this.state.day_end}`, {
            method: "GET",
          });

          this.setState({
            turnover: getFormattedNumber(turnover, this.state.account.currency, intl.locale),
            avg_order: getFormattedNumber(avgOrder, this.state.account.currency, intl.locale),
            nb_order: getFormattedNumber(nbOrder, "", intl.locale),
          });
        }

        async getFilteredTurnoversGroupByUsecase() {
          const { auth } = this.props;

          const selectedUsecases = JSON.parse(JSON.stringify(this.checkedUsecases));
          if (selectedUsecases.includes("winterSales")) {
            selectedUsecases.push("solde");
          }

          return await auth.fetch(`/api/data/getFilteredOrderStatsGroupByUsecase?accountId=${this.state.account._id}&usecases=${selectedUsecases}&startDate=${this.state.day_begin}&endDate=${this.state.day_end}`, {
            method: "GET",
          });
        }

        async fetchUsecases() {
          const result = await this.props.auth.fetch("/api/usecase/getAll", { method: "GET" });

          const formattedUsecases = result.usecases.map(usecase => ({
            name: usecase.name,
            category: usecase.category,
            checked: true,
          }));

          return formattedUsecases;
        }

        async componentDidMount() {
          try {
            const { intl } = this.props;
            const user = await this.props.auth.getUser();
            const usecases = await this.fetchUsecases();

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            if(sessionStorage.getItem("shopifyAlreadyConnected")) {
              EventSystem.newNotification(
                "notification.error", 
                intl.messages["home.error.shopify_already_connected"]
              );

              sessionStorage.removeItem("shopifyAlreadyConnected");
            }

            this.setState({ account: this.state.account });
            const hasCms = this.state.account.step_register > 2 || !!this.state.account.shopify;

            if (localStorage.getItem("connectionType") === "fromShopifyModule") {
              await this.suggestShopifyLogo(this.state.account.shopify.session.shopUrl);
            }

            if (hasCms && this.state.account.CMS === "shopify" && !this.state.account?.url_logo) {
              this.setState({ shouldShowShopifyImportLogo: true, logoSuggestionIsLoading: false });
            }

            // Needed to request back with the right CMS (since shopify)
            localStorage.setItem("CMS", this.state.account.CMS);

            // Shopify subscription handle
            if (
              this.state.account.owner !== "Client Payant" &&
              urlParams.get("charge_id") &&
              urlParams.get("charge_id") !== ""
            ) {
              this.setState({ shouldShowShopifySubscription: true }, () => {
                // check if account is activated
                let subscriptionCheckTries = 0;
                const subscriptionCheck = setInterval(async () => {
                  const result = await this.updateAccountBilling();

                  const NB_RETRY = 40;

                  subscriptionCheckTries++;
                  if (result.success || subscriptionCheckTries > NB_RETRY) {
                    clearInterval(this.state.subscriptionCheck);
                    this.setState({
                      subscriptionIsLoading: false,
                      subscriptionFailed: subscriptionCheckTries > NB_RETRY
                    });
                  }
                }, 3000);
                this.setState({ subscriptionCheck });
              });
            }

            const date_creation = new Date(this.state.account.date_creation);
            date_creation.setHours(0, 0, -1, 0);

            const synchronize_percentage = this.state.account.synchronize_percentage || 0;
            const synchronize_done = synchronize_percentage === 100;

            const active_scenario = this.state.account.usecases.filter((usecase) => {
              return usecase.launch_date !== null;
            });
            const customMailDone = !!this.state.account.is_custom_mail;


            EventSystem.publish("force_date_begin", date_creation);

            usecases.push({
              category: "newsletter",
              checked: true,
              name: "newsletter",
            });

            this.setState({
              usecases,
              firstname: user.attributes.given_name,
              num_scenarios: active_scenario.length,
              total_num_usecases: this.state.account.usecases.length,
              customMailDone,
              dateAccountCreation: date_creation,
              synchronize_done,
              synchronize_percentage,
              noCms: !hasCms,
              emptyStateVariant: hasCms ? undefined : "noCmsStats",
              loading: false
            }, () => {
              const datePeriodText = localStorage.getItem("datePeriodText");
              if (datePeriodText) {
                this.handleDatePeriodSelection(datePeriodText);
              } else {
                this.getStatisticsData();
                this.getOrderGeneralStatistics();
              }
            });
          } catch (error) {
            console.error("Error while mounting Home", error);
            this.setState({ loading: false });
          }
        }

        async suggestShopifyLogo(shopUrl) {
          const environment = process.env.REACT_APP_ENV === "production" ? "production" : "preprod";
          try {
            const { url, data, extension } = await fetch(`https://logo-manager.${environment}.kiliba.eu/suggestLogo`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                shopUrl
              })
            }).then(response => response.json());
            
            const logoSuggestion = url && data && extension ? {
              url,
              data,
              extension
            } : null;

            this.setState({
              logoSuggestion,
              logoSuggestionIsLoading: false
            });
          } catch (error) {
            this.setState({
              logoSuggestion: null,
              logoSuggestionIsLoading: false
            });
          }
        }

        async updateAccountBilling() {
          const response = await this.props.auth.fetch("/api/payment/updateAccountBilling", {
            method: "PATCH"
          }).then(response => response);
          return response;
        }

        closeShopifyImportLogoModal() {
          this.setState({ shouldShowShopifyImportLogo: false });
        }

        async redirectToCustomMail(accepts = false) {
          if (accepts && this.state.logoSuggestion?.url && this.state.logoSuggestion?.data) {
            sessionStorage.setItem("shopifyLogoUrl", this.state.logoSuggestion.url);
            sessionStorage.setItem("shopifyLogoExtension", this.state.logoSuggestion.extension);
            sessionStorage.setItem("shopifyLogoBuffer", this.state.logoSuggestion.data);
          }

          this.props.auth.sendEventToAmplitude("import_suggested_logo", { accepts: accepts });

          EventSystem.publish("url_switch", "/template_customization");
        }

        closeShopifySubscriptionModal() {
          this.setState({ shouldShowShopifySubscription: false }, () => {
            EventSystem.publish("hide_subscription_bar");
          });
        }

        openZendesk() {
          this.setState({ shouldShowShopifySubscription: false });
          window.zE("messenger", "open");
        }

        componentWillUnmount() {
          if (this.state.subscriptionCheck) {
            clearInterval(this.state.subscriptionCheck);
          }

          EventSystem.unsubscribe("usecase_search", null);
          EventSystem.publish("loading.stop");
        }

        changeDayBegin(day) {
          this.setState({ day_begin: day }, () => {
            this.getStatisticsData();
            this.getOrderGeneralStatistics();
          });
          localStorage.setItem("filterDayBegin", day);
        }

        changeDayEnd(day) {
          const maxDate = new Date();
          maxDate.setHours(0, 0, 0, 0);
          const dayEnd = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 23, 59, 59);
          this.setState({ day_end: dayEnd > maxDate ? maxDate : dayEnd }, () => {
            this.getStatisticsData();
            this.getOrderGeneralStatistics();
          });
          localStorage.setItem("filterDayEnd", dayEnd);
        }

        numberWithSpaces(x) {
          // add space in numbers
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        handleDatePeriodSelection(datePeriodText) {
          const today = new Date();
          let dayBegin = this.state.dateAccountCreation;
          let dayEnd = today;
          const thirtyDaysAgo = new Date(today);
          thirtyDaysAgo.setDate(today.getDate() - 30);
          switch (datePeriodText) {
            case "fromBeginning":
              dayBegin = this.state.dateAccountCreation;
              break;
            case "thisMonth":
              dayBegin = new Date(today.getFullYear(), today.getMonth(), 1); //first day of month
              break;
            case "previousMonth":
              dayBegin = new Date(today.getFullYear(), today.getMonth() - 1, 1); //first day of previous month
              dayEnd = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, -1); //last day of previous month
              break;
            case "interval":
              dayBegin = localStorage.filterDayBegin ? new Date(localStorage.filterDayBegin) : thirtyDaysAgo;
              dayEnd = localStorage.filterDayEnd ? new Date(localStorage.filterDayEnd) : today;
              break;
            default:
              console.error("Not valid date period");
          }
          this.setState({
            datePeriod: datePeriodText,
            day_begin: dayBegin,
            day_end: dayEnd
          }, () => {
            this.getStatisticsData();
            this.getOrderGeneralStatistics();
          });

          localStorage.setItem("datePeriodText", datePeriodText);
          localStorage.setItem("filterDayBegin", dayBegin);
          localStorage.setItem("filterDayEnd", dayEnd);
        }

        render() {
          const { classes, intl } = this.props;
          const { statisticsRows } = this.state;

          if (this.state.loading) {
            return null;
          }

          return (
            <div className={classes.home}>
              <div data-cy="welcome-title" className={classes.welcome}>
                <img src={arrow} alt="logo" className={classes.arrow} />
                {intl.messages["accountSetup.stepUser.welcome"] + " "}
                {this.state.firstname}
              </div>

              <ExceedingPrice from="Home" />
              
              <FirstSteps />

              <Fade in>
                <div>
                  <div className={classes.container}></div>
                  <div style={{ display: "flex", marginTop: "35px", justifyContent: "space-between" }}>
                    <div style={{ display: "flex" }}>
                      <KiUsecasePicker
                        usecases={this.state.usecases}
                        onUsecaseClick={this.changeUsecase}
                      />
                      <div style={{ marginLeft: "20px" }}>
                        <KiDropdown
                          value={this.state.datePeriod}
                          options={[
                            { value: "fromBeginning", label: intl.messages["datePeriodList.fromBeginning"], },
                            { value: "thisMonth", label: intl.messages["datePeriodList.thisMonth"], },
                            { value: "previousMonth", label: intl.messages["datePeriodList.previousMonth"], },
                            { value: "interval", label: intl.messages["datePeriodList.interval"], },
                          ]}
                          onChange={this.handleDatePeriodSelection}
                          variant="table"
                        />
                      </div>
                      {
                        this.state.datePeriod === "interval" ? (
                          <Fragment>
                            <div
                              style={{
                                margin: "auto",
                                marginRight: "10px",
                                marginLeft: "20px",
                                fontSize: "12px",
                                color: "#878787",
                              }}
                            >
                              {intl.messages["home.statistics.from"]}
                            </div>
                            <KiDatePicker
                              value={this.state.day_begin}
                              onChange={this.changeDayBegin}
                            />
                            <div
                              style={{
                                margin: "auto",
                                marginRight: "10px",
                                marginLeft: "10px",
                                fontSize: "12px",
                                color: "#878787",
                              }}
                            >
                              {intl.messages["home.statistics.to"]}
                            </div>
                            <KiDatePicker
                              value={this.state.day_end}
                              onChange={this.changeDayEnd}
                              minDate={this.state.day_begin}
                            />
                          </Fragment>
                        ) : null
                      }
                    </div>
                    {!this.state.noCms
                      ?
                      <FormButtonTO
                        action={this.downloadStatistics}
                        className={classes.cta}
                        type={"secondary"}
                        size={"xs"}
                        label={intl.messages["button.download"]}
                        icon={(<i className={`${classes.ctaIcon} fal fa-arrow-alt-from-top`} />)}
                      /> : null
                    }
                  </div>

                  <div style={{ height: 20 }}></div>

                  <div className={classes.stats}>
                    <div className={classes.statsCarousel}>
                      <StatsCarousel
                        stats={{
                          turnover: this.state.turnover,
                          nb_order: this.state.nb_order,
                          avg_order: this.numberWithSpaces(this.state.avg_order),
                          send: this.numberWithSpaces(this.state.send),
                          open: this.numberWithSpaces(this.state.open),
                          click: this.numberWithSpaces(this.state.click),
                          conversion_rate: this.numberWithSpaces(this.state.conversion_rate),
                          unsubscribe: this.numberWithSpaces(this.state.unsubscribe),
                        }}
                      />
                    </div>
                    <div className={classes.statsTable}>
                      <KiTable
                        columns={this.state.columns}
                        rows={statisticsRows}
                        order={{ columnKey: "ca", type: "desc" }}
                        enablePagination
                        total={statisticsRows?.length}
                        emptyStateVariant={this.state.emptyStateVariant}
                        account={this.state.account}
                        paginationType="item"
                        pagination={this.state.pagination}
                        savePagination={this.savePagination}
                      />
                    </div>
                  </div>
                </div>
              </Fade>

              <Dialog
                PaperProps={{
                  style: {
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: this.state.logoSuggestion ? "60%" :
                      this.state.logoSuggestionIsLoading ? "40%" : "unset",
                    height: this.state.logoSuggestionIsLoading ? "40%" : "unset"
                  }
                }}
                isOpen={this.state.shouldShowShopifyImportLogo}
                closeStyle={null}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {this.state.logoSuggestionIsLoading ? (
                    <Fragment>
                      <div
                        className={classes.logoSuggestionBody}
                      >
                        <CircularProgress size={26} className={classes.loading} />
                        <Translation id="home.shopifyImportLogoDialog.loading_1" />
                        <Translation id="home.shopifyImportLogoDialog.loading_2" />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <h2>{intl.messages["home.shopifyImportLogoDialog.title"]}</h2>
                      <div
                        className={classes.shopifyCmsCelebrationDialogDescription}
                      >
                        {this.state.logoSuggestion ? (
                          <div className={classes.logoSuggestionBody}>
                            <img
                              className={classes.logoPickerImage}
                              alt={
                                intl.messages[
                                  "templateCustomization.logoParameters.unableToLoad"
                                ]
                              }
                              src={this.state.logoSuggestion.url}
                            />
                            <Translation id="home.shopifyImportLogoDialog.logo_found" />
                            <Translation id="home.shopifyImportLogoDialog.logo_found_2" />
                          </div >
                        ) : <Translation id="home.shopifyImportLogoDialog.text" />}
                      </div >
                      <div>
                        {this.state.logoSuggestion ? (
                          <span className={classes.shopifyLogoSuggestionButtons}>
                            <CustomButton
                              onClick={() => this.redirectToCustomMail()}
                              type={"secondary"}
                            >
                              <Translation id="home.shopifyImportLogoDialog.secondary_button" />
                            </CustomButton>
                          </span>
                        ) : null}
                        <span className={classes.shopifyLogoSuggestionButtons}>
                          <CustomButton
                            onClick={() => this.redirectToCustomMail(true)}
                            type={"primary"}
                          >
                            {this.state.logoSuggestion ? (
                              <Translation id="home.shopifyImportLogoDialog.logo_found_primary_button" />
                            ) : <Translation id="home.shopifyImportLogoDialog.primary_button" />}
                          </CustomButton>
                        </span>
                      </div>
                    </Fragment>)}
                </div>
              </Dialog>

              <Dialog
                PaperProps={{
                  style: {
                    borderRadius: 25,
                    display: "flex",
                    width: this.state.subscriptionIsLoading ? "560px" : "unset",
                    height: this.state.subscriptionIsLoading ? "500px" : "unset"
                  }
                }}
                isOpen={this.state.shouldShowShopifySubscription}
                shouldShowCloseButton={false}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  { this.state.subscriptionIsLoading ? (
                    <Fragment>
                      <div  className={classes.logoSuggestionBody} >
                        <div className={classes.popupText} >
                          <Translation id="home.shopifySubscriptionDialog.loading1" /><br></br>
                          <Translation id="home.shopifySubscriptionDialog.loading2" />
                        </div>
                        <div className={ classes.popupGifContentMask }></div>
                        <div className={ classes.popupGifContent }>
                          <img className={ classes.giphy} src="https://production-kiliba-assets.s3.eu-central-1.amazonaws.com/kiliba/gifs/cat-computer-working.gif"></img>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <h2 className={classes.subscriptionTitle}>
                        {this.state.subscriptionFailed ?
                          intl.messages["home.shopifySubscriptionDialog.error"] :
                          intl.messages["home.shopifySubscriptionDialog.title"]
                        }
                      </h2>
                      {
                        this.state.subscriptionFailed ? (
                          <CustomButton
                            onClick={() => this.openZendesk()}
                            type={"primary"}
                          >
                            <Translation id="home.shopifySubscriptionDialog.error_button" />
                          </CustomButton>
                        ) :
                          <CustomButton
                            onClick={() => this.closeShopifySubscriptionModal()}
                            type={"primary"}
                          >
                            <Translation id="home.shopifySubscriptionDialog.button" />
                          </CustomButton>
                      }
                    </Fragment>)}
                </div>
              </Dialog>
            </div >
          );
        }
      }
    )
  )
);

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};
