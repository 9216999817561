import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from "@material-ui/core";
import { injectIntl } from "react-intl";
//import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Checkbox from "@material-ui/core/Checkbox";
import { KiTextField } from "./Input/KiTextField";

import { palette } from "../../styles/palette.js";

const styles = (theme) => ({
  button: {
    fontFamily: "Poppins",
    color: palette.primaryBlack,
    fontSize: "14px",
    letterSpacing: "1px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    borderRadius: 10,
    backgroundColor: palette.white,
    height: "40px",
    width: "180px",
    textDecoration: "none",
    textTransform: "none",
    boxShadow: "0px 1px 7px 0px rgba(120, 120, 120, 0.25)",
    "&:hover": {
      backgroundColor: palette.neutralBCGGreyHover,
    },
  },
  checkbox: {
    color: "gray",
  },
  checked: {
    "&$checked": {
      color: palette.primaryYellow200,
    },
  },
  root: {
    width: "100%",
    borderRadius: "5px",
    padding:0,
    maxWidth: "360px",
    "& .MuiListItem-container": {
      "&:hover": {
        backgroundColor: palette.neutralBCGGreyHover,
      },
    },
    maxHeight: "50vh",
    overflowY: "scroll"
  },
  reactDaypickerRoot: {
    width: "fit-content",
    borderRadius: "5px",
    textDecoration: "none",
    marginTop: "5px"
  },
 
});

export const ScrollSelectPicker = withStyles(styles)(
  injectIntl(
    class ScrollSelectPicker extends Component {
      constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickAway = this.handleClickAway.bind(this);
        this.handleListScroll = this.handleListScroll.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.state = {
          anchorEl: null,
          open: false,
          scrollIndex : 0,
          selectList: [],
          rawDataList: [],
          search: "",
          isLoading: true
        };
      }

      componentDidMount() {
        this.setState({ 
          selectList: JSON.parse(JSON.stringify(this.props.selectList)).splice(this.state.scrollIndex, this.props.itemNbToDisplay || 10) ,
          rawDataList: this.props.selectList
        });
      }

      handleClick(event) {
        this.setState({
          anchorEl: event.currentTarget,
          open: !this.state.open,
        });
      }

      handleClose = function () {
        this.setState({ anchorEl: null });
      };

      handleClickAway(event) {
        this.setState({ open: false });
      }

      handleToggle = (value) => () => {
        this.props.action(value);
      };

      handleListScroll(event) {
        if (!this.state.search) {
          const element = event.target;

          if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
            const dataClone = JSON.parse(JSON.stringify(this.props.selectList));
            const scrollIndex = this.state.scrollIndex + (this.props.itemNbToDisplay - 1 || 9);
            const selectList = this.state.selectList.concat(dataClone.splice(scrollIndex, this.props.itemNbToDisplay || 10));
            this.setState({ selectList, scrollIndex });
          }
        }
      };

      handleSearch(event) {
        const selectList = this.state.rawDataList
          .filter(option => option.name.includes(event.target.value) || option.name.toLowerCase().includes(event.target.value))
          .splice(0, 100);
        this.setState({ selectList, search: event.target.value });
      }

      render() {
        const checked = this.props.selected;
        const classes = this.props.classes;
        const wording = this.props.displayName;
        const { intl } = this.props;

        return (
          <div>
            <Button
              aria-owns={this.state.anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
              className={classes.button}
              style={{
                border:
                  this.state.open === true
                    ? "1px solid " + palette.primaryBlack
                    : "",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                backgroundColor: this.state.open === true ? "white" : "",
              }}
            >
              <div
                style={{
                  textAlign: "justify",
                  position: "absolute",
                  left: "34px",
                  letterSpacing: 1,
                  maxWidth: "60%",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                { checked.length ? 
                  checked.length === 1 ?
                    checked[0].name : 
                    intl.messages["categories.select.multiple_selected_categorie"]
                  : wording
                }
              </div>
              <i
                style={{
                  fontSize: "14px",
                  top: "13px",
                  position: "absolute",
                  right: "14px",
                }}
                className="fal fa-angle-down"
              ></i>
            </Button>

            <Popper
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              placement={"bottom-start"}
              style={{zIndex: "1000000"}}
            >
              <ClickAwayListener onClickAway={this.handleClickAway}>
                <Paper className={classes.reactDaypickerRoot}>
                  <List dense className={classes.root} onScroll={this.handleListScroll}>
                    {this.props.shouldIncludeSearch ? (
                      <ListItem key="search-input" style={{marginTop: "10px", position: "sticky", top: 10, zIndex: 3}}>
                        <KiTextField 
                          placeholder="Recherche catégorie"
                          type="whiteBg"
                          autoComplete="off"
                          name="search"
                          value={this.state.search}
                          handleChange={this.handleSearch} 
                        >
                        </KiTextField>
                      </ListItem>
                    ) : null }
                    {checked.map((item, index) => (
                      <ListItem
                        button
                        onClick={this.handleToggle(item.id)}
                        style={{borderBottom: index === checked.length - 1 ?  "2px solid black" : "unset"}}
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              paddingTop: 3,
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              letterSpacing: "0.5px",
                              fontWeight: "normal",
                              fontStyle: "normal",
                              fontStretch: "normal",
                              lineHeight: "normal",
                              borderRadius: 5,
                              color: palette.primaryBlack,
                              marginLeft: "19px",
                              height: "22px",
                              "&:hover": {
                                background: "blue",
                                cursor: "pointer"
                              }
                            },
                          }}
                          primary={item.name}
                        />
                        <ListItemSecondaryAction>
                          <Checkbox
                            classes={{
                              root: classes.checkbox,
                              checked: classes.checked,
                            }}
                            checked={checked.findIndex(x => x.id === item.id) !== -1}
                            tabIndex={-1}
                            onClick={this.handleToggle(item.id)}
                            disableRipple
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    {this.state.selectList.map((item) => (
                      <ListItem
                        button
                        onClick={this.handleToggle(item.id)}
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              paddingTop: 3,
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              letterSpacing: "0.5px",
                              fontWeight: "normal",
                              fontStyle: "normal",
                              fontStretch: "normal",
                              lineHeight: "normal",
                              borderRadius: 5,
                              color: palette.primaryBlack,
                              marginLeft: "19px",
                              height: "22px",
                              "&:hover": {
                                background: "blue",
                                cursor: "pointer"
                              }
                            },
                          }}
                          primary={item.name}
                        />
                        <ListItemSecondaryAction>
                          <Checkbox
                            classes={{
                              root: classes.checkbox,
                              checked: classes.checked,
                            }}
                            checked={checked.findIndex(x => x.id === item.id) !== -1}
                            tabIndex={-1}
                            onClick={this.handleToggle(item.id)}
                            disableRipple
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </div>
        );
      }
    }
  )
);
