export const styles = {
  title: {
    paddingBottom: 30,
  },
  subtitle: {
    position: "relative",
  },
  underlineImg: {
    height: 18,
    width: 100,
  },
  buttonContainer: {
    paddingTop: 40,
    paddingBottom: 60,
  },
  arrowImg: {
    position: "absolute",
    bottom: -150,
    right: 60,
    height: 140,
  },
  "@media (max-width: 1200px)": {
    arrowImg: {
      display: "none",
    }
  },
};
