import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";

import { TargetSection } from "./TemplateEditorSections/TargetSection.js";
import { CodeSection } from "./TemplateEditorSections/CodeSection.js";
import { ValueSection } from "./TemplateEditorSections/ValueSection.js";
import { TemporalitySection } from "./TemplateEditorSections/TemporalitySection.js";
import { OptionsSection } from "./TemplateEditorSections/OptionsSection.js";

const styles = (theme) => ({
  body: {
    "& > div:not(:last-child)": {
      marginBottom: 32,
    },
  },
});

export const TemplateEditorAutomatic = withStyles(styles)(
  injectIntl(
    class extends Component {
      render() {
        const {
          classes,
          tabKey,
          account,
          promoCodeTemplateWrapper,
          setPromoCodeTemplateWrapperProp,
          mustRemoveObsoletePromoCodes,
          setMustRemoveObsoletePromoCodes,
          getError,
          usecaseName,
          shouldDisplayFromDateOption,
          caller
        } = this.props;

        const cmsWhichSupportEternalPromoCodes = ["magento"];

        return (
          <div className={classes.body}>
            <TargetSection
              promoCodeTemplateWrapper={promoCodeTemplateWrapper}
              cms={account.CMS}
              setPromoCodeTemplateWrapperProp={setPromoCodeTemplateWrapperProp}
            />

            <CodeSection
              promoCodeTemplateWrapper={promoCodeTemplateWrapper}
              setPromoCodeTemplateWrapperProp={setPromoCodeTemplateWrapperProp}
              getError={getError}
              partNames={["codeInput", "codeSuffix"]}
            />

            <ValueSection
              account={account}
              promoCodeTemplateWrapper={promoCodeTemplateWrapper}
              setPromoCodeTemplateWrapperProp={setPromoCodeTemplateWrapperProp}
              getError={getError}
              partNames={["typeInput", "valueInput", "includesTaxesInput"]}
            />

            <TemporalitySection
              tabKey={tabKey}
              promoCodeCanBeEternalInCms={
                cmsWhichSupportEternalPromoCodes.indexOf(account.CMS) > -1
              }
              promoCodeTemplateWrapper={promoCodeTemplateWrapper}
              setPromoCodeTemplateWrapperProp={setPromoCodeTemplateWrapperProp}
              getError={getError}
              partNames={ account.CMS === "woocommerce" ? ["lifetimeInput", "untilDateInput"] : ["fixedInput", "lifetimeInput", "eternalInput"]}
              shouldDisplayFromDateOption={shouldDisplayFromDateOption}
              caller={caller}
              account={account}
            />

            <OptionsSection
              promoCodeTemplateWrapper={promoCodeTemplateWrapper}
              setPromoCodeTemplateWrapperProp={setPromoCodeTemplateWrapperProp}
              mustRemoveObsoletePromoCodes={mustRemoveObsoletePromoCodes}
              setMustRemoveObsoletePromoCodes={setMustRemoveObsoletePromoCodes}
              getError={getError}
              partNames={[
                "minimumAmountInput",
                "quantityInput",
                "includesFreeShipping",
                "mustRemoveObsoletePromoCodesInput",
                "mustShowDatesInput",
                ...(!["sponsorship", "welcomeAi"].includes(usecaseName) ? ["showHeadbandPromo"] : [])
              ]}
              caller={caller}
            />
          </div>
        );
      }
    }
  )
);
