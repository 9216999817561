import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { injectIntl, FormattedMessage } from "react-intl";
import { styles } from "./style.js";
import logo from "../../public/logo_black.png";
import { CustomButton } from "../common/Button/CustomButton";

export const ModalCgv = withStyles(styles)(
  injectIntl(
    class ModalCgv extends Component {
      render() {
        const { classes } = this.props;

        return (
          <Grid
            className={classes.mainModal}
            container
            item
            direction="column"
            alignItems="center"
            xs={12}
          >
            <img className={classes.logoModal} src={logo} alt="logo" />
            <div className={classes.roundedDiv}></div>

            <div className={classes.cgvModal}>
              <div className={classes.modalWording}>
                <FormattedMessage
                  id="register.lastStep"
                  defaultMessage="LastStep"
                />
              </div>
              <div className={classes.modalSubWording}>
                <FormattedMessage
                  id="register.lastStepCgv"
                  defaultMessage="LastStepCgv"
                />
              </div>
              <div className={classes.error}>
                {this.props.globalError && (
                  <FormattedMessage id="error.cgv.accept" />
                )}
                &nbsp;
              </div>
              <div
                className={`${classes.checkCgv} ${classes.cgvModalCheckboxContainer}`}
              >
                <Checkbox
                  checked={this.props.checked}
                  classes={{
                    root: this.props.globalError ? classes.checkboxError : null,
                  }}
                  onClick={this.props.handleClick}
                />
                <div>
                  <FormattedMessage
                    id="register.cgv"
                    defaultMessage="cgv"
                  />
                  <a
                    href="https://www.kiliba.com/cgu"
                    rel="noreferrer"
                    target="_blank"
                    className={classes.loginWording_hypertext}
                  >
                    <FormattedMessage
                      id="register.cgv2"
                      defaultMessage="cgv"
                    />
                  </a>
                  <FormattedMessage
                    id="register.cgv3"
                    defaultMessage="cgv"
                  />
                  <a
                    href="https://www.kiliba.com/politique-de-confidentialite-app"
                    rel="noreferrer"
                    target="_blank"
                    className={classes.loginWording_hypertext}
                  >
                    <FormattedMessage
                      id="register.cgv4"
                      defaultMessage="Welcome"
                    />
                  </a>
                  <FormattedMessage
                    id="register.cgv5"
                    defaultMessage="cgv"
                  />
                  <a
                    href="https://www.kiliba.com/mentions-legales-app"
                    rel="noreferrer"
                    target="_blank"
                    className={classes.loginWording_hypertext}
                  >
                    <FormattedMessage
                      id="register.cgv6"
                      defaultMessage="cgv"
                    />
                  </a>
                  <FormattedMessage
                    id="register.cgv7"
                    defaultMessage="cgv"
                  />
                </div>
              </div>
              <div>
                <CustomButton
                  style={{ fontFamily: "Poppins" }}
                  onClick={this.props.handleSubmit}
                  loading={this.props.isLoading}
                >
                  <FormattedMessage id="button.validSign_in" />
                </CustomButton>
              </div>
            </div>
          </Grid>
        );
      }
    }
  )
);

ModalCgv.propTypes = {
  classes: PropTypes.object.isRequired,
};
