import React, { Component } from "react";
import classes from "../ParametersSection.module.scss";

import { TextField } from "../../../common/Input/TextField.js";

import facebook from "../../../../public/socialMedias/facebook.png";
import twitter from "../../../../public/socialMedias/twitter.png";
import instagram from "../../../../public/socialMedias/instagram.png";
import pinterest from "../../../../public/socialMedias/pinterest.png";
import linkedin from "../../../../public/socialMedias/linkedin.png";
import discord from "../../../../public/socialMedias/discord.png";
import tiktok from "../../../../public/socialMedias/tiktok.png";
import youtube from "../../../../public/socialMedias/youtube.png";
import { injectIntl } from "react-intl";

const socialMediaImages = {
  discord,
  facebook,
  instagram,
  linkedin,
  pinterest,
  tiktok,
  twitter,
  youtube,
};


export const SocialMediasParameters = injectIntl((
  class SocialMediasParameters extends Component {
    constructor(props) {
      super(props);
      this.renderSocialMediaTextField =
          this.renderSocialMediaTextField.bind(this);
    }

    renderSocialMediaTextField(socialMediaName) {
      const { intl, socialMedias, handleChangeSocialMedias } = this.props;
      const placeholder = intl.messages[`templateCustomization.socialMediasParameters.socialMediaUrl.${socialMediaName}.placeholder`];

      return (
        <div className={classes.socialMedia} key={socialMediaName}>
          <img
            src={socialMediaImages[socialMediaName]}
            className={classes.socialMediaImage}
            alt={socialMediaName}
          ></img>
          <TextField
            value={socialMedias[socialMediaName] || ""}
            placeholder={placeholder}
            id={socialMediaName}
            marginIsEnabled={false}
            onChange={(e) =>
              handleChangeSocialMedias(e.target.id, e.target.value)
            }
          />
        </div>
      );
    }

    render() {
      return (
        <div>
          {this.renderSocialMediaTextField("discord")}
          {this.renderSocialMediaTextField("facebook")}
          {this.renderSocialMediaTextField("instagram")}
          {this.renderSocialMediaTextField("linkedin")}
          {this.renderSocialMediaTextField("pinterest")}
          {this.renderSocialMediaTextField("tiktok")}
          {this.renderSocialMediaTextField("twitter")}
          {this.renderSocialMediaTextField("youtube")}
        </div>
      );
    }
  }
)
);
