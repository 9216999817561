// returns date @date at 0:00 A.M. (local timezone)
export function getMidnightDate(date) {
  const midnightDate = new Date(date);

  midnightDate.setHours(0, 0, 0, 0);

  return midnightDate;
}

// add @days days to date @date and return the result
export function getIncrementedDate(date, days) {
  const formattedDate = new Date(date);
  const incrementedDay = formattedDate.getDate() + days;
  const incrementedDateTimestamp = formattedDate.setDate(incrementedDay);
  return new Date(incrementedDateTimestamp);
}

// add @days days to date @date and return the result
export function getDecrementedDate(date, days) {
  const formattedDate = new Date(date);
  const incrementedDay = formattedDate.getDate() - days;
  const incrementedDateTimestamp = formattedDate.setDate(incrementedDay);
  return new Date(incrementedDateTimestamp);
}

// know if date is older than x day from today
export function getDateIsOlderThan(date, days) {
  const today = new Date();
  const incrementedDate = getIncrementedDate(date, days);
  const timeElapsed = today.getTime() - incrementedDate.getTime();
  return timeElapsed > 0;
}

export function getDateDeltaInMinutes(youngestDate, olderDate) {
  return (youngestDate.getTime() - olderDate.getTime()) / (1000 * 60);
}
