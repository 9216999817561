import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import Checkbox from "@material-ui/core/Checkbox";
import { Tooltip } from "../../Tooltip.js";
import PropTypes from "prop-types";

import { EmptyState } from "../EmptyState/EmptyState.js";

import { getFormattedNumber } from "../../../tools/getFormattedNumber";
import { KiPaper } from "../KiPaper/KiPaper";
import { KiPagination } from "../KiPagination/KiPagination";
import { KiPaginationItem } from "../KiPagination/KiPaginationItem";

import { styles } from "./KiTable.styles";
import { makeStyles } from "@material-ui/core";
import { CustomButton } from "../Button/CustomButton.js";

const useStyles = makeStyles(styles);

export function KiTable(props) {
  const {
    columns,
    rows,
    enablePagination,
    total,
    emptyStateVariant,
    account,
    onSelect,
    onSelectAll,
    loading,
    loadMoreData,
    onOrder,
    paginationType,
    onClickRow,
    viewAllProductFromBlacklistCategorieMode,
    onBackButtonClick,
    savePagination,
    onLineClick
  } = props;

  const intl = useIntl();

  const tableRef = useRef();

  const [order, setOrder] = useState(
    props.order ||
    {
      columnKey: null,
      type: null,
    }
  );
  const [pagination, setPagination] = useState(
    props.pagination ||
    {
      rowsPerPage: 10,
      pageKey: 0,
      page: 0
    }
  );

  const hasNoData = useMemo(() => rows === null || rows?.length === 0, [rows]);
  const innerLoading = useMemo(() => rows === undefined || loading, [rows, loading]);
  const innerEmptyStateVariant = useMemo(() => {
    if (emptyStateVariant) return emptyStateVariant;
    if (innerLoading) return "loading";
    if (hasNoData) return "noData";
    return null;
  }, [emptyStateVariant, innerLoading, hasNoData]);

  const classes = useStyles({
    ...props,
    emptyStateVariant: innerEmptyStateVariant,
  });

  useEffect(() => {
    setPagination(props.pagination);
  }, [props.pagination]);

  const updateOrder = useCallback((columnKey) => {    
    const newOrder = {
      columnKey: columnKey,
      type: "asc",
    };

    if (order.columnKey === columnKey && order.type === "asc") {
      newOrder.type = "desc";
    }

    onOrder && onOrder(newOrder);

    setOrder(newOrder);
  }, [order, onOrder]);

  // get order between 2 values
  const sort = useCallback((prevValue, nextValue, orderType) => {
    let formattedPrevValue = (prevValue === null || prevValue === undefined) ? "" : prevValue;
    let formattedNextValue = (nextValue === null || nextValue === undefined) ? "" : nextValue;

    let isDate = false;
    let splittedPrevValue = "";
    let splittedNextValue = "";

    if (isNaN(+prevValue) && isNaN(+nextValue)) {
      splittedPrevValue = formattedPrevValue.split("/");
      splittedNextValue = formattedNextValue.split("/");
      isDate =
        !isNaN(Date.parse(`${+splittedPrevValue[2]}//${splittedPrevValue[1] - 1}//${+splittedPrevValue[0]}`)) &&
        !isNaN(Date.parse(`${+splittedNextValue[2]}//${splittedNextValue[1] - 1}//${+splittedNextValue[0]}`));
    }

    if (orderType === "asc") {
      if (isDate) {
        formattedPrevValue = Date.parse(`${+splittedPrevValue[2]}//${splittedPrevValue[1] - 1}//${+splittedPrevValue[0]}`);
        formattedNextValue = Date.parse(`${+splittedNextValue[2]}//${splittedNextValue[1] - 1}//${+splittedNextValue[0]}`);

        return (
          formattedPrevValue > formattedNextValue ? 1 :
            formattedPrevValue < formattedNextValue ? -1 :
              0
        );
      }
      else if (typeof prevValue === "string") {
        return formattedPrevValue.localeCompare(formattedNextValue, undefined, { numeric: true, sensitivity: "base" });
      } else if (typeof prevValue === "number") {
        return (
          formattedPrevValue > formattedNextValue ? 1 :
            formattedPrevValue < formattedNextValue ? -1 :
              0
        );
      }
    }
    if (orderType === "desc") {
      if (isDate) {
        formattedPrevValue = Date.parse(`${+splittedPrevValue[2]}//${splittedPrevValue[1] - 1}//${+splittedPrevValue[0]}`);
        formattedNextValue = Date.parse(`${+splittedNextValue[2]}//${splittedNextValue[1] - 1}//${+splittedNextValue[0]}`);

        return (
          formattedPrevValue < formattedNextValue ? 1 :
            formattedPrevValue > formattedNextValue ? -1 :
              0
        );
      }
      else if (typeof prevValue === "string") {
        return -(formattedPrevValue.localeCompare(formattedNextValue, undefined, { numeric: true, sensitivity: "base" }));
      } else if (typeof prevValue === "number") {
        return (
          formattedPrevValue < formattedNextValue ? 1 :
            formattedPrevValue > formattedNextValue ? -1 :
              0
        );
      }
    }
  }, []);

  // extract the sortable values from their rows and get their order
  const getRowsOrder = useCallback((prevRow, nextRow) => {
    if (!order.columnKey) return 0;

    const targettedColumn = columns.find(column => column.key === order.columnKey);

    const prevRowItem = prevRow.values[order.columnKey];
    const nextRowItem = nextRow.values[order.columnKey];

    const sortProperty = targettedColumn.sortProperty || "displayedValue";
    const prevValue = prevRowItem[sortProperty];
    const nextValue = nextRowItem[sortProperty];

    const customSortFunction = targettedColumn.sortFunction;
    const defaultSortFunction = sort;
    const sortFunction = customSortFunction || defaultSortFunction;

    return sortFunction(prevValue, nextValue, order.type);
  }, [columns, order, sort]);

  const renderSelectCell = useCallback((isSelected, isIndeterminate, onChange, type, viewAllProductFromBlacklistCategorieMode) => {
    return (
      viewAllProductFromBlacklistCategorieMode && type === "header" ?
        <div style={{height: "72px"}}></div> :
        <div
          className={classes.selectColumn}
        >
          <Checkbox
            checked={isSelected}
            indeterminate={isIndeterminate}
            onChange={onChange}
          />
        </div>
    );
  }, [classes]);

  const renderRow = useCallback((row, rowKey) => {
    const formattedOnSelect = (row) => {
      onSelect(row.id);
    };

    return (
      <div
        key={rowKey}
        className={`${classes.row} ${row?.customClass || ""}`}
        onClick={onLineClick ? () => onLineClick(row) : () => {}}
      >
        {
          onSelect
            ? renderSelectCell(row.isSelected, null, () => formattedOnSelect(row), "row")
            : null
        }
        {
          Object.keys(row.values).map((valueKey, key) => (
            <div
              key={key}
              className={`tableContent ${classes[valueKey]} ${classes.cell} ${rowKey > 0 ? classes.withBorder : ""}`}
              onClick={onClickRow ? () => (onClickRow(row)) : null}
            > 
              { row.values[valueKey].displayedValue && row.values[valueKey].displayedValue.length > 25 ? 
                <Tooltip title={row.values[valueKey].displayedValue}>
                  <div className={classes.cellContent}>
                    {row.values[valueKey].startAdornment ? row.values[valueKey].startAdornment : null}
                    {row.values[valueKey].htmlContent ? row.values[valueKey].htmlContent : null}
                    <div className={classes.cellInnerContent}>
                      { valueKey === "ca" ? 
                        row.values[valueKey].displayedValue === "ignored" ? intl.messages["customers.ca.ignored"] :
                          getFormattedNumber(
                            row.values[valueKey].displayedValue,
                            account.currency, intl.locale
                          ) : 
                        row.values[valueKey].displayedValue
                      }
                    </div>
                    {row.values[valueKey].endAdornment ? row.values[valueKey].endAdornment : null}
                  </div>
                </Tooltip> :
                <div className={classes.cellContent}>
                  {row.values[valueKey].startAdornment ? row.values[valueKey].startAdornment : null}
                  {row.values[valueKey].htmlContent ? row.values[valueKey].htmlContent : null}
                  {valueKey === "ca" ? 
                    row.values[valueKey].displayedValue === "ignored" ? intl.messages["customers.ca.ignored"] :
                      getFormattedNumber(
                        row.values[valueKey].displayedValue,
                        account.currency, intl.locale
                      ) : 
                    row.values[valueKey].displayedValue
                  }
                  {row.values[valueKey].endAdornment ? row.values[valueKey].endAdornment : null}
                </div>
              }
            </div>
          ))
        }
      </div>
    );
  }, [classes, intl, rows, onSelect, account, renderSelectCell, onLineClick]);

  const [tableStyle, setTableStyle] = useState({});

  useEffect(() => {
    setTableStyle({
      gridTemplateColumns: (onSelect ? "64px " : "") + columns.map(column => "max-content").join(" "),
    });

    function resizeCells() {
      if (!tableRef?.current) return;

      const headCells = [...tableRef.current.querySelectorAll("th")];
      const headCellsWidths = headCells.map(headCell => {
        const style = getComputedStyle(headCell);

        return (
          Number(style.width.replace("px", "")) +
          Number(style.paddingLeft.replace("px", "")) +
          Number(style.paddingRight.replace("px", ""))
        );
      });
      const rowsWidth = headCellsWidths.reduce((a, b) => a + b, 0);
      const headCellsPercentages = headCellsWidths.map(headCellWidth => headCellWidth / rowsWidth);

      setTableStyle({
        gridTemplateColumns: (onSelect ? "64px " : "") + headCellsPercentages.map(headCellPercentage => `minmax(max-content, ${headCellPercentage * 100}%)`).join(" "),
      });
    }

    document.fonts.ready.then(resizeCells);
  }, [columns, onSelect]);

  const shouldLoadMoreData = useCallback(() => {
    // the number of rows in the table up to the current page. If current page is 2 and rows per page is 10, then the total is 20 rows.
    const currentPageTotal = (pagination.pageKey + 1) * pagination.rowsPerPage;
    const nextPageWasAlreadyLoaded = rows.length > currentPageTotal;

    const nextPageHasData = currentPageTotal < total;

    return nextPageHasData && !nextPageWasAlreadyLoaded;
  }, [pagination, rows, total]);

  const changePage = useCallback(async (newPageKey) => {
    const newPagination = JSON.parse(JSON.stringify(pagination));

    newPagination.pageKey = newPageKey;

    if (shouldLoadMoreData()) {
      newPagination.page = (newPagination.page ?? 0) + 1;
      loadMoreData(newPagination.page);
    }

    savePagination(newPagination);
  }, [pagination, loadMoreData, shouldLoadMoreData, savePagination]);

  const changeRowsPerPage = (value) => {
    const newPagination = JSON.parse(JSON.stringify(pagination));

    newPagination.rowsPerPage = value;
    savePagination(newPagination);
  };

  const filterRows = useCallback((row, rowKey) => {
    if (enablePagination === false) return true;
    return (
      rowKey >= (pagination.pageKey * pagination.rowsPerPage) &&
      rowKey < ((pagination.pageKey + 1) * pagination.rowsPerPage)
    );
  }, [enablePagination, pagination]);

  const renderHeaders = useCallback(() => {
    const selectedIds = rows ? rows.filter(row => row.isSelected).map(row => row.id) : [];
    const isSelected = rows ? selectedIds.length === rows.length && selectedIds.length > 0 : false;
    const isIndeterminate = rows ? selectedIds.length !== rows.length && !!selectedIds.length : false;

    const headerOnSelect = () => {
      if (!rows) return [];
      const newSelectedIds = isIndeterminate || !isSelected ? rows.map(row => row.id) : [];
      onSelectAll(newSelectedIds);
    };

    return (
      <Fragment>
        { onSelect ? renderSelectCell(isSelected, isIndeterminate, headerOnSelect, "header", viewAllProductFromBlacklistCategorieMode) : null }
        { 
          !viewAllProductFromBlacklistCategorieMode ?
            columns.map((column, key) => {
              const columnContent = (
                <Fragment>
                  {column.startAdornment}
                  <p>{column.name}</p>
                  {
                    order.columnKey === column.key ? (
                      <span className={`${classes.sortIcon} fal fa-long-arrow-${order.type === "asc" ? "up" : "down"}`}></span>
                    ) : null
                  }
                  {column.endAdornment}
                </Fragment>
              );
              return (
                <th
                  key={key}
                  className={`tableTitle ${classes.cell} ${classes.headCell} ${classes.maxTdSize}`}
                  onClick={() => updateOrder(column.key)}
                >
                  {
                    column.tooltipContent
                      ? <Tooltip title={column.tooltipContent}>{<div className={classes.columnContentContainer}>{columnContent}</div>}</Tooltip>
                      : columnContent
                  }
                </th>
              );
            }) : renderBlackHeader()
        }
      </Fragment >
    );
  }, [classes, rows, columns, onSelect, order, renderSelectCell, updateOrder]);

  const renderBody = useCallback(() => {
    return innerEmptyStateVariant ?
      null :
      [...rows] // shallow copy since the rows may contain circular references, but needs to be sorted without mutating the original object
        .sort((prevRow, nextRow) => getRowsOrder(prevRow, nextRow))
        .filter(filterRows)
        .map(renderRow);
  }, [innerEmptyStateVariant, rows, filterRows, getRowsOrder, renderRow]);

  const renderBlackHeader = () => {
    return (
      columns.map((value, index) => {
        return <th><div style={{ height: "42px", display: "flex", paddingTop: "15px", paddingBottom: "15px"}}>
          { index === 0 ? <CustomButton size={"md"} type={"secondary"} onClick={onBackButtonClick}>Retour</CustomButton>
            : null
          }
        </div></th>;
      })
    );
  };

  // Check total props as number
  if(total && typeof total !== "number") {
    throw new Error(`Prop "total" must be a number and sent as ${typeof total}`);
  }

  return (
    <KiPaper>
      <div className={!viewAllProductFromBlacklistCategorieMode ? classes.tableWrapper : classes.tableWrapperWithoutPinkBand}>
        <table
          ref={tableRef}
          className={classes.table}
          border="0"
          cellPadding="0"
          cellSpacing="0"
          style={tableStyle}
        >
          { renderHeaders() }
          {/* { viewAllProductFromBlacklistCategorieMode ? renderBlackHeader() : null } */}
          {!innerEmptyStateVariant ? renderBody() : null}
        </table>

        <div className={classes.emptyStateContainer}>
          {innerEmptyStateVariant ? <EmptyState variant={innerEmptyStateVariant} /> : null}
        </div>
      </div>

      {
        !!enablePagination && (
          <>
            <div className={classes.separator}></div>
            {paginationType === "page" ?
              <KiPagination
                count={total}
                pageKey={pagination.pageKey}
                rowsPerPage={pagination.rowsPerPage}
                onPageChange={changePage}
              /> :
              <KiPaginationItem
                count={total}
                pageKey={pagination.pageKey}
                rowsPerPage={pagination.rowsPerPage}
                onPageChange={changePage}
                onRowsPerPageChange={value => changeRowsPerPage(value)}
              />
            }
          </>
        )
      }
    </KiPaper>
  );
}

KiTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.string,
    sortProperty: PropTypes.string,
    startAdornment: PropTypes.element,
    endAdornment: PropTypes.element,
    tooltipContent: PropTypes.string
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    isSelected: PropTypes.bool,
    values: PropTypes.objectOf(PropTypes.shape({
      rawValues: PropTypes.element,
      displayedValues: PropTypes.element,
      startAdornment: PropTypes.string,
    }))
  })).isRequired,
  order: PropTypes.shape({
    columnKey: PropTypes.string,
    type: PropTypes.oneOf(["asc", "desc"]),
  }),
  enablePagination: PropTypes.bool,
  total: PropTypes.number,
  emptyStateVariant: PropTypes.oneOf([
    "syncActiveCustomers",
    "noCmsCustomers",
    "syncActiveProducts",
    "noCmsProducts",
    "syncActiveOrders",
    "noCmsOrders",
    "noCmsStats",
    "noData",
    "",
  ]),
  account: PropTypes.object,
  onSelect: PropTypes.func,
  loading: PropTypes.bool,
  loadMoreData: PropTypes.func,
  filters: PropTypes.any,
};

KiTable.defaultProps = {
  enablePagination: true,
  paginationType: "page"
};
