const LABEL = "column";
const CATEGORY = "column:column";

export const column = (variables) => ({
  label: LABEL,
  category: CATEGORY,
  block: (overrides) => {
    const { size = 100, paddingTop = 10, paddingBottom = 10, paddingLeft = 10, paddingRight = 10, content = "", backgroundColor, columnWordings = {}, columnVariables = {} } = {
      ...variables,
      ...(overrides ? overrides : {}),
    };

    const mjml = `
      <mj-column data-gjs="kilibaColumn" data-blocklabel="${LABEL}" css-class="column" width="${size}%" padding-top="${paddingTop}px" padding-bottom="${paddingBottom}px" padding-left="${paddingLeft}px" padding-right="${paddingRight}px" background-color="${backgroundColor}">
        ${content}
      </mj-column>
    `;

    return {
      id: LABEL,
      label: LABEL,
      category: CATEGORY,
      activate: true,
      content: mjml,
      attributes: {
        wordings: columnWordings,
        variables: columnVariables,
        editableProperties: [
          { key: "backgroundColor", type: "color", label: "newsletter.rightMenu.property.backgroundColor", group: "newsletter.rightMenu.propertyGroup.block" },
        ]
      }
    };
  }
});