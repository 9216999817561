import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withAuth } from "../../../withAuth.js";

import { Dialog } from "@material-ui/core";

import { palette } from "../../../../styles/palette.js";

const styles = (theme) => {
  const iframeContainerPadding = 48;
  return {
    iframeContainer: {
      borderRadius: 5,
      minWidth: 800,
      width: "80vw",
      padding: iframeContainerPadding,
      minHeight: `calc(100% - ${iframeContainerPadding * 2}px)`,
      position: "relative",
    },
    iframe: {
      borderRadius: 5,
      position: "absolute",
      width: `calc(100% - ${iframeContainerPadding * 2}px)`,
      height: `calc(100% - ${iframeContainerPadding * 2}px)`,
      top: iframeContainerPadding,
      right: iframeContainerPadding,
      bottom: iframeContainerPadding,
      left: iframeContainerPadding,
      pointerEvents: "none",
    },
  };
};

export const UsecasePopupLandingPageExample = withAuth(
  withStyles(styles)(
    class UsecasePopupLandingPageExample extends Component {
      render() {
        const { classes, auth, close } = this.props;

        const src =
          window.location.origin +
          `/sponsorship_landing?accountId=${auth.getAccountId()}&sponsorId=null`;

        return (
          <Dialog
            open={true}
            onClose={close}
            PaperProps={{
              style: {
                height: "calc(100% - 64px)",
                maxWidth: "90%",
                display: "block",
                overflow: "overlay",
              },
            }}
            BackdropProps={{ style: { opacity: 0.8 } }}
          >
            {
              <i
                onClick={close}
                className="fal fa-times"
                style={{
                  fontSize: 20,
                  zIndex: 10000,
                  position: "absolute",
                  right: 24,
                  top: 24,
                  cursor: "pointer",
                  color: palette.lightgray,
                }}
              ></i>
            }
            <div className={classes.iframeContainer}>
              <iframe
                frameborder="0"
                className={classes.iframe}
                src={src}
                title="previewTemplateEmail"
              ></iframe>
            </div>
          </Dialog>
        );
      }
    }
  )
);
