import { useEffect, useMemo, useState } from "react";
import { Translation } from "../../common/Translation";

import { CATEGORIES } from "../blockCategoriesConfig";

import classes from "./RightMenuForm.module.scss";

const CATEGORIES_WITHOUT_HEADER = ["promoCode"];
export const RightMenuForm = ({ selectedCategory, blocks, selectedBlockId, onChange, isEdit, scrollableContainerClass, allowScroll = true, editor, templateId, updateContent, content, language }) => {

  const [selectedSecondayCategory, setSelectedSecondayCategory] = useState("all");
  const [shouldScroll, setShouldScroll] = useState(allowScroll);
  const [forcedCategoriesEmptyState, setForcedCategoriesEmptyState] = useState([]);

  const secondaryCategories = useMemo(() => CATEGORIES.filter(
    category => category.parent === selectedCategory && 
    ((blocks.filter(block => block.getCategoryLabel() === `${selectedCategory}:${category.name}` && block.attributes.activate).length) || category.emptyComponent )),
  [selectedCategory, blocks]);

  const showSecondaryCategories = !!secondaryCategories.filter(category => category.show !== false).length;

  useEffect(() => {
    CATEGORIES.find(category => category.name === selectedSecondayCategory);
    if (shouldScroll && selectedBlockId) {
      const element = document.getElementById(`block-${selectedBlockId}`);
      if (element) {
        const container = document.getElementsByClassName(scrollableContainerClass);
        container[0].scrollTo({
          top: element.getBoundingClientRect().top - container[0].getBoundingClientRect().top - 300,
          behavior:  "smooth",
        });
        setShouldScroll(false);
      }
    }
  }, [shouldScroll, selectedBlockId, scrollableContainerClass, selectedSecondayCategory]);

  useEffect(() => {
    //force secondary category for columnComponent
    if (selectedCategory.startsWith("columnComponent")) {
      const block = blocks.find(b => b.getId() === selectedBlockId);
      setSelectedSecondayCategory(block.getCategoryLabel().split(":")[1]);
    }
  }, [selectedCategory, secondaryCategories, blocks, selectedBlockId]);

  const forceCategoryEmptyState = (category) => {
    setForcedCategoriesEmptyState(prev => [...prev, category]);
  };

  const cancelCategoryEmptyState = (category) => {
    setForcedCategoriesEmptyState(prev => prev.filter(cat => cat !== category));
  };

  const getUsableBlocks = (blocks, category) => {
    return blocks
      .filter(block => block.getCategoryLabel() === `${selectedCategory}:${category.name}` && !forcedCategoriesEmptyState.includes(category.name))
      .filter(block => block.attributes.activate);
  }; 

  const getHtmlFromBlock = (block) => {
    //replace breakpoints to prevent the preview being in mobile mode
    return block.getMedia().replaceAll("(min-width:480px)", "(min-width:300px)");
  };

  const title = useMemo(() => {

    // if (selectedCategory.startsW)
    if (selectedCategory.startsWith("columnComponent")) {
      if (selectedCategory !== "all") {
        return `newsletter.category.columnComponent.${selectedCategory}`;
      }
      return "";
    }

    return !isEdit ? `newsletter.rightMenu.form.${selectedCategory}.title` : `blockManager.category.${selectedCategory}`;
  }, [isEdit, selectedCategory]);

  return (
    <div className={classes.container} style={isEdit ? { paddingTop: 0 } : {}}>
      <h2>{title && <Translation id={title} />}</h2>
      {showSecondaryCategories &&
        <>
          <div className={classes.secondaryCategories}>
            {
              !CATEGORIES_WITHOUT_HEADER.includes(selectedCategory) ? [{ name: "all" }, ...secondaryCategories].map(category => (
                <span
                  key={category.name}
                  className={`infoMedium ${classes.categoryTag} ${selectedSecondayCategory === category.name ? classes.categoryTagSelected : ""}`}
                  onClick={() => setSelectedSecondayCategory(category.name)}
                >
                  <Translation id={`blockManager.category.${category.name}`} />
                </span>
              )) : null
            }
          </div>          
        </>
      }
      <div className={classes.separator} />
      {[...(selectedSecondayCategory === "all" ? secondaryCategories : [CATEGORIES.find(category => category.name === selectedSecondayCategory)])].map(category => (
        <div className={classes.subCategories} key={category.name}>
          
          {showSecondaryCategories && <div className={`textMedium ${classes.tertiaryCategoryName}`}>
            { !CATEGORIES_WITHOUT_HEADER.includes(selectedCategory) ? <Translation id={`blockManager.category.${category.name}`} /> : null }
            { 
              getUsableBlocks(blocks, category).length && category.parent === "review" ? 
                forcedCategoriesEmptyState.includes(category.name) ? 
                  <span className={classes.spanButton} onClick={() => cancelCategoryEmptyState(category.name)}><Translation id={"blockManager.category.cancel"} /></span> :
                  <span className={classes.spanButton} onClick={() => forceCategoryEmptyState(category.name)}><Translation id={"blockManager.category.modify"} /></span> 
                : forcedCategoriesEmptyState.includes(category.name) ?  <span className={classes.spanButton} onClick={() => cancelCategoryEmptyState(category.name)}><Translation id={"blockManager.category.cancel"} /></span> : null
            }
          </div>}
          {
            getUsableBlocks(blocks, category)
              .map((block, idx) => (
                <div key={block.id} id={`block-${block.id}`}>
                  <div
                    className={`${classes.block} ${block.getId() === selectedBlockId ? classes.selected : ""}`}
                    onClick={() => onChange(block.getId())}
                  >
                    <iframe
                      onLoad={(event) => { event.target.style.height = event.target.contentWindow.document.body.scrollHeight + "px"; }}
                      title="block"                      
                      srcDoc={`${getHtmlFromBlock(block)}`}
                      width="383px"
                      scrolling="no"
                    />
                  </div>
                </div>
              ))}

          { !getUsableBlocks(blocks, category).length && (category.parent === "review" || category.parent === "promoCode") ?
            <category.emptyComponent 
              secondaryCategory={category.name}
              editor={editor}
              templateId={templateId}
              cancelCategoryEmptyState={cancelCategoryEmptyState}
              blocks={blocks}
              updateContent={updateContent}
              content={content}
              language={language}
            ></category.emptyComponent> : null }
        </div>
      ))}
    </div>
  );
};