
export const openAiUsecaseKeys = {
  subtitle2: [
    "birthday",
    "anniversaryRegistration",
    "welcome",
    "booster",
    "bestClients",
    "postVisits",
    "mothersDay",
    "fathersDay",
    "christmas",
    "newYear",
    "backToSchool",
    "valentinesDay",
    "summerSales",
    "winterSales",
    "promotions",
    "newProducts",
    "halloween",
  ]
};
